import {call, put, select, takeEvery} from 'redux-saga/effects'
import axios, {AxiosResponse} from "axios";
import {handleShopitApi, handleShopitApiRequestError} from "./helper"
import {
    CONFIG_REQUEST_LOCALIZATION,
    RequestLocalizationAction,
    triggerLoadingLocalization,
    triggerResponseLocalizationCountries,
    triggerResponseLocalizationCurrencies,
    triggerResponseLocalizationLanguage
} from "../Action/config.localization";
import {
    REQUEST_CATEGORY_STRUCTURE,
    RequestCategoryStructureAction,
    triggerResponseCategoryStructureNotFound,
    triggerResponseCategoryStructureSuccess
} from "../Action/cache.categoryTree";
import {getAxiosApiConfigs, serializePostParams} from "../../Helpers/ApiHelper";
import {triggerUrlContextAdd} from "../Action/urlContext";

function* localizationRequest(action: RequestLocalizationAction) {
    if (action.localizationType == "countries") {
        const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

        const req = call(
            axios.post,
            `/api/locale`,
            serializePostParams<IApiLocaleRequest>({
                type: "countries",
                _logPageToken: logToken || null,
            }),
            getAxiosApiConfigs(undefined, "SSS_COUNTRIES_TIMEOUT, notify developers"))

        yield put(triggerLoadingLocalization(action.localizationType))

        let res: AxiosResponse<IApiResponse> | null = null;
        try {
            res = yield req
        } catch (error) {
            const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
            if (isDebug) {
                yield* handleShopitApiRequestError(error)
            }
        }

        if (res) {
            const response = yield* handleShopitApi<IApiLocaleResponseCountries>(res)
            if (response) {
                yield put(triggerResponseLocalizationCountries(response.countries));
            }
        }
    } else if (action.localizationType == "currencies") {
        const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

        const req = call(
            axios.post,
            `/api/locale`,
            serializePostParams<IApiLocaleRequest>({
                type: "currencies",
                _logPageToken: logToken || null,
            }),
            getAxiosApiConfigs(undefined, "SSS_CURRENCIES_TIMEOUT, notify developers"))

        yield put(triggerLoadingLocalization(action.localizationType))

        let res: AxiosResponse<IApiResponse> | null = null;
        try {
            res = yield req
        } catch (error) {
            const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
            if (isDebug) {
                yield* handleShopitApiRequestError(error)
            }
        }

        if (res) {
            const response = yield* handleShopitApi<IApiLocaleResponseCurrencies>(res)
            if (response) {
                yield put(triggerResponseLocalizationCurrencies(response.currencies));
            }
        }
    } else if (action.localizationType == "languages") {
        const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

        const req = call(
            axios.post,
            `/api/locale`,
            serializePostParams<IApiLocaleRequest>({
                type: "languages",
                _logPageToken: logToken || null,
            }),
            getAxiosApiConfigs(undefined, "SSS_LANGUAGES_TIMEOUT, notify developers"))

        yield put(triggerLoadingLocalization(action.localizationType))

        let res: AxiosResponse<IApiResponse> | null = null;
        try {
            res = yield req
        } catch (error) {
            const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
            if (isDebug) {
                yield* handleShopitApiRequestError(error)
            }
        }

        if (res) {
            const response = yield* handleShopitApi<IApiLocaleResponseLanguages>(res)
            if (response) {
                yield put(triggerResponseLocalizationLanguage(response.languages));
            }
        }
    }
}

function* categoryStructureRequest(action: RequestCategoryStructureAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axios.post,
        `/api/category`,
        serializePostParams<IApiCategoryRequest>(
            action.categoryId ? {
                lang: action.lang,
                mode: 's',
                categoryId: action.categoryId,
                _logPageToken: logToken || null,
            } : {
                lang: action.lang,
                mode: 's',
                _logPageToken: logToken || null,
            }),
        getAxiosApiConfigs(undefined, "SSS_CATEGORY_STRUCTURE_TIMEOUT, notify developers"))

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiCategoryStructureResponse>(res)
        if (response) {
            yield put(triggerUrlContextAdd(response.urlContext))
            yield put(triggerResponseCategoryStructureSuccess(action.categoryId, action.lang, response.data.children));
        } else {
            yield put(triggerResponseCategoryStructureNotFound(action.categoryId, action.lang));
        }
    }
}

export function* watchLocalizationAction() {
    yield takeEvery(CONFIG_REQUEST_LOCALIZATION, localizationRequest);
    yield takeEvery(REQUEST_CATEGORY_STRUCTURE, categoryStructureRequest);
}

