import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Dialog} from "@headlessui/react";
import {useTranslation} from "react-i18next";
import {fetchSearchDebugData} from "../../Helpers/ApiHelper";
import {TranslationSet} from "../../Localization/i18n.constants";
import {getPageParamsFromSearchUrl} from "../../Helpers/SearchHelper";
import {SearchRouteContext} from "../../Store/Context/SearchRouteContext";

const ComponentTranslationKey = "TopBar"

export default function ModalDebug(props: { isModalOpen: boolean, onClose: () => void }) {

    // globals
    const searchContext = useContext(SearchRouteContext);
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    const [debugData, setDebugData] = useState<string | undefined>(undefined)
    const searchParams = useSelector((state: IAppState) => {
        return searchContext.cacheKey ? getPageParamsFromSearchUrl(searchContext.cacheKey) : undefined;
    });
    const apiTail: IApiTail = useSelector((state: IAppState) => ({
        _logPageToken: state.session.tracking.pageToken || null
    }));

    const onClose = function () {
        props.onClose()
    }

    // lifecycle functions
    useEffect(() => {
        if (debugData === undefined && searchParams) {
            fetchSearchDebugData(searchParams, apiTail)
                .then((debugData) => {
                    if (debugData && debugData?.log) {
                        setDebugData(debugData.log)
                    }
                })
                .catch((error) => {/*nothing to do*/
                })
        }
    }, []);

    // render
    return <Dialog as="div" className="relative z-50"
                   open={props.isModalOpen}
                   onClose={onClose}>
        <div className="modal-shade-overlay"/>
        <div className="modal-wrapper-space">
            <div className="modal-wrapper-vertical-align">
                <div className={`modal-verification-wrapper`}>

                    <Dialog.Panel className="modal-title-content-wrapper">

                        <div className="modal-title">
                            {getTranslation('list_debug_info')}
                            <div className={`modal-title-closer`} onClick={onClose}>
                                <img src={`/resources/icon-remove-white.png`} alt={`Close`}/>
                            </div>
                        </div>

                        <div className={`modal-content modal-verification-content`}>
                            <div
                                className={`max-w-screen-lg h-[690px] flex flex-col whitespace-pre overflow-y-scroll`}>
                                {debugData}
                            </div>
                        </div>
                    </Dialog.Panel>
                </div>
            </div>
        </div>
    </Dialog>
}
