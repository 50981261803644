export const getConfirmPageParamsFromUrl = function (search: string): { code: string | undefined, userId: number | undefined } {
    if (search.indexOf('?') !== -1) {
        search = search.substring(search.indexOf('?'));
    }

    const searchParams = new URLSearchParams(search);
    let confirmationParam: { code: string | undefined, userId: number | undefined } = {
        code: undefined,
        userId: undefined,
    };

    searchParams.forEach(function (v, k) {
        switch (k) {
            case 'c':
                confirmationParam.code = v;
                break;
            case 'u':
                confirmationParam.userId = parseInt(v);
                break;
            default:
                console.log('Unknown URL param: ' + k + ': ' + v);
                break;
        }
    })

    return confirmationParam;
}