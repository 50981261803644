import React, {Fragment, useContext} from "react";
import {useSelector} from 'react-redux';
import {
    convertSearchParamsToUrlResolveParams,
    detachSearchParamUniqueNamePathFilter,
} from "../../../Helpers/SearchHelper";
import {selectSearchCache} from "../../../Store/StoreHelper";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import SearchRouteParamLink from "../../App/Links/SearchRouteParamLink";
import {SearchRouteContext} from "../../../Store/Context/SearchRouteContext";

export default function FilterBarUniqueNamePathButton(props: { filterParam: IProductListSearch_UniqueNamePathFilter }) {

    const searchContext = useContext(SearchRouteContext);

    const {t} = useTranslation("TopBar");
    const getTranslation = function (key: keyof TranslationSet["TopBar"]) {
        return t(key);
    }

    const searchData = useSelector((state: IAppState) => selectSearchCache(state, searchContext.cacheKey));

    const searchQuery = searchData && searchData.object ? searchData.object.searchQuery : undefined
    const filterParam = props.filterParam;

    return <Fragment>
        {filterParam.data.map((option: IProductListSearch_NumericFilterData, index: number) => {

            const name = filterParam.visualDetails
                ? filterParam.visualDetails.name
                    ? filterParam.visualDetails.name
                    : filterParam.visualDetails.dataName
                        ? filterParam.visualDetails.dataName[option.id.toString()]
                        : filterParam.filter_var
                : filterParam.filter_var;

            const currentFilters: IProductListSearch_Filter[] = searchQuery && searchQuery.filter ? [...searchQuery.filter] : [];
            const optionFilters = detachSearchParamUniqueNamePathFilter(currentFilters, option.id, IProductListSearch_FilterType.UniqueNamePath)

            return <SearchRouteParamLink
                key={"o" + index}
                className="btn btn-active-filter ml-1"
                title={getTranslation("hint_remove_filter")}
                urlPageParams={convertSearchParamsToUrlResolveParams({
                    ...searchQuery,
                    filter: optionFilters,
                })}
            >
                {name} <img src="/resources/icon-remove-white.png" className="inline-block" alt="Remove Filter"/>
            </SearchRouteParamLink>
        })}
    </Fragment>
}

