import React, {useEffect} from "react";
import {getConfirmPageParamsFromUrl} from "../../../Helpers/RegistrationHelper";
import {InteractiveDelayApiResponseActionValue, InteractiveDelayType} from "../../../Store/Action/interactive";
import {useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    triggerUserLoginRecoverCheck,
    triggerUserLoginRecoverDecline,
    triggerUserRegisterConfirm
} from "../../../Store/Action/session.login";

/** @see DelayApiCallMessageDemo */
export default function DelayApiCallMessage(props: { messageType: string }) {

    // globals
    const dispatch = useDispatch()
    const location = useLocation()

    // extract global state
    let propsExtension: {
        messageType: string | undefined,
        messageParams: { code: string, userId: number } | undefined
        messageAnswer: InteractiveDelayApiResponseActionValue | undefined
    } = (() => {
        if (location) {
            const searchQuery = getConfirmPageParamsFromUrl(location.search);

            const apiResult = useSelector((state: IAppState) => state.interactive.delayedApiResult[props.messageType])
            if (apiResult) {
                return  {
                    messageType: undefined,
                    messageParams: undefined,
                    messageAnswer: apiResult,
                }
            } else {
                switch (props.messageType) {
                    case InteractiveDelayType.RecoverCheck:
                    case InteractiveDelayType.RecoverDecline:
                    case InteractiveDelayType.RegisterConfirm:
                        return {
                            messageType: props.messageType,
                            messageParams: {code: searchQuery.code ?? "", userId: searchQuery.userId ?? 0},
                            messageAnswer: undefined,
                        }
                }
            }
        }
        return {
            messageType: undefined,
            messageParams: undefined,
            messageAnswer: undefined,
        }
    })()

    // lifecycle functions
    useEffect(() => {
        if (!propsExtension.messageAnswer && propsExtension.messageType) {
            const param = propsExtension.messageParams as { code: string, userId: number };
            switch (propsExtension.messageType) {
                case InteractiveDelayType.RecoverCheck:
                    dispatch(triggerUserLoginRecoverCheck(param.userId, param.code));
                    break;
                case InteractiveDelayType.RecoverDecline:
                    dispatch(triggerUserLoginRecoverDecline(param.userId, param.code));
                    break;
                case InteractiveDelayType.RegisterConfirm:
                    dispatch(triggerUserRegisterConfirm(param.userId, param.code));
                    break;
            }
        }
    }, []);

    // render
    if (propsExtension.messageAnswer) {
        if (propsExtension.messageAnswer.redirect) {
            return <div className="delay-api-call-message mt-8">
                <a href={propsExtension.messageAnswer.redirect}>
                    {propsExtension.messageAnswer.message}
                </a>
            </div>;
        } else {
            return <div className="delay-api-call-message mt-8">
                {propsExtension.messageAnswer.message}
            </div>;
        }
    } else {
        return <div className="delay-api-call-message mt-8">
            Delayed API Message Call... Please wait...
        </div>;
    }
}