// based on example: https://github.com/Lemoncode/i18next-example-typescript

import i18next, {i18n as i18nInstance} from "i18next";
import {initReactI18next} from "react-i18next";
import {localizationLanguages} from "./i18n.constants";

import {enTranslation} from "./translation.en";
import {svTranslation} from "./translation.sv";
import {getDefaultLang} from "../Store/Action/interactive";

const createI18n = (language: string): i18nInstance => {
    const i18n = i18next.createInstance().use(initReactI18next);

    i18n.init({
        lng: language,
        fallbackLng: localizationLanguages.sv,
        resources: {
            [localizationLanguages.en]: enTranslation,
            [localizationLanguages.sv]: svTranslation,
        },
    });

    return i18n;
};

export const i18n = createI18n(getDefaultLang());
