import React, {useEffect, useLayoutEffect, useRef} from "react";
import {findDOMNode} from "react-dom";

type CategoryStructurePartProps = {
    categoryClass: { id: number, className: string } | undefined
    category: CategoryStructure_Category
    selectedCategoryId: number,
    path: CategoryStructure_Category[],
    scrollToSelectedCategory: (pos: number) => void
    onIClick: (category: CategoryStructure_Category) => void
    onLiClick: (category: CategoryStructure_Category) => void
}

export default function CategoryStructurePart(props: CategoryStructurePartProps) {

    const ref = useRef<HTMLDivElement>(null)

    useLayoutEffect(() => {
        if (ref && ref.current) {
            if (props.category.categoryId == props.selectedCategoryId) {
                const node = findDOMNode(ref.current) as HTMLElement
                if (node && node.offsetTop) {
                    props.scrollToSelectedCategory(node.offsetTop)
                }
            }
        }
    }, []);

    const category = props.category;
    const categoryClass = props.categoryClass;
    const selectedCategoryId = props.selectedCategoryId;

    const commonObject: CategoryStructure_Category = {
        ...category,
        path: props.path
    }

    const classes = []
    if (category.isOpen) {
        classes.push('opened')
    }
    if (category.categoryId == selectedCategoryId) {
        classes.push('selected')
    }
    if (category.isCollection) {
        classes.push('collection')
    }
    if (categoryClass && categoryClass.id == category.categoryId) {
        classes.push(categoryClass.className)
    }
    return (
        <li className={classes.join(" ")}
            onClick={
                (e) => {
                    e.stopPropagation()
                    props.onLiClick(commonObject)
                }}>
            {category.isCollection && <i onClick={
                (e) => {
                    e.stopPropagation()
                    return props.onIClick(commonObject)
                }}/>}
            <div className="category-content">
                <div className="category-background"/>
                <div className="category-title">
                    <span>{category.name}</span>
                </div>
            </div>
            {category.isOpen &&
                <ul> {category.children.map((category) => <CategoryStructurePart key={category.categoryId}
                                                                                 category={category}
                                                                                 selectedCategoryId={selectedCategoryId}
                                                                                 path={[...props.path, commonObject]}
                                                                                 onIClick={props.onIClick}
                                                                                 onLiClick={props.onLiClick}
                                                                                 scrollToSelectedCategory={props.scrollToSelectedCategory}
                                                                                 categoryClass={categoryClass}/>)}</ul>}
        </li>
    )
}
