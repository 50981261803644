import React, {useContext} from "react";
import {useSelector} from "react-redux";
import {
    getPageParamsFromSearchUrl,
} from "../../../Helpers/SearchHelper";
import SearchRouteParamLink from "../../App/Links/SearchRouteParamLink";
import {SearchRouteContext} from "../../../Store/Context/SearchRouteContext";

export default function ListToggleButton(props: {}) {

    const searchContext = useContext(SearchRouteContext);

    const searchParams = useSelector((state: IAppState) => {
        return searchContext.serializedParams ? getPageParamsFromSearchUrl(searchContext.serializedParams) : undefined;
    });

    if (!searchParams) return null;

    // warning: this fallback use on other places too
    const size = (searchParams?.bridgeParams.size) ? (searchParams?.bridgeParams.size) : "medium";

    return <div className={`flex flex-row`}>
        <SearchRouteParamLink
            className={`btn btn-toggle cursor-pointer ${size == "spec" ? `btn-toggle-active` : ``}`}
            urlPageParams={{
                ...searchParams,
                bridgeParams: {
                    ...searchParams?.bridgeParams,
                    size: "spec"
                }
            }}
        >
            Spec
        </SearchRouteParamLink>

        <SearchRouteParamLink
            className={`btn btn-toggle cursor-pointer ${size == "small" ? `btn-toggle-active` : ``}`}
            urlPageParams={{
                ...searchParams,
                bridgeParams: {
                    ...searchParams?.bridgeParams,
                    size: "small"
                }
            }}
        >
            <img src="/resources/small-view.png" alt="" className="w-4"/>
        </SearchRouteParamLink>

        <SearchRouteParamLink
            className={`btn btn-toggle cursor-pointer ${size == "medium" ? `btn-toggle-active` : ``}`}
            urlPageParams={{
                ...searchParams,
                bridgeParams: {
                    ...searchParams?.bridgeParams,
                    size: "medium"
                }
            }}
        >
            <img src="/resources/medium-view.png" alt="" className="w-4"/>
        </SearchRouteParamLink>

        <SearchRouteParamLink
            className={`btn btn-toggle cursor-pointer ${size == "large" ? `btn-toggle-active` : ``}`}
            urlPageParams={{
                ...searchParams,
                bridgeParams: {
                    ...searchParams?.bridgeParams,
                    size: "large"
                }
            }}
        >
            <img src="/resources/large-view.png" alt="" className="w-4"/>
        </SearchRouteParamLink>
    </div>
}