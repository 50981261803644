import {call, put, select} from 'redux-saga/effects'
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {handleShopitApi, handleShopitApiRequestError, isAbortedError} from "./helper"
import {
    LOAD_SAYT, LOAD_SAYT_FIRST_URL,
    SaytAction, SaytFirstUrlAction,
    triggerLoadSaytCleanup,
    triggerLoadSaytFail,
    triggerLoadSaytSuccess
} from "../Action/sayt";
import {takeLatest} from "@redux-saga/core/effects";
import {CANCEL} from "redux-saga";
import {getAxiosApiConfigs, serializePostParams} from "../../Helpers/ApiHelper";

const axiosPostCancelable = (url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse> => {
    const cancelTokenSource = axios.CancelToken.source();
    const promise = axios.post(url, data, {...config, cancelToken: cancelTokenSource.token});

    (promise as any)[CANCEL] = () => cancelTokenSource.cancel();
    return promise;
}

function* loadSayt(action: SaytAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axiosPostCancelable,
        `/api/sayt?search=${action.search}`,
        serializePostParams<IApiSaytRequestPostParams>({
            lang: action.lang,
            detailed: !!action.detailed,
            _logPageToken: logToken || null
        }),
        getAxiosApiConfigs(undefined, "SAYT_TIMEOUT, notify developers"))

    if (!action.callback) {
        yield put(triggerLoadSaytCleanup())
    }

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        if (!isAbortedError(error)) {
            if (action.isDebug) {
                yield* handleShopitApiRequestError(error)
            }
            if (!action.callback) {
                yield put(triggerLoadSaytFail())
            }
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiSaytResponse>(res)
        if (response) {
            if (!action.callback) {
                yield put(triggerLoadSaytSuccess(response.data));
            } else {
                action.callback(response.data)
            }
        } else {
            if (!action.callback) {
                yield put(triggerLoadSaytFail())
            }
        }
    }
}

function* loadSaytFirstUrl(action: SaytFirstUrlAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axiosPostCancelable,
        `/api/sayt-fast?search=${action.search}`,
        serializePostParams<IApiSaytRequestPostParams>({
            lang: action.lang,
            detailed : false,
            _logPageToken: logToken || null
        }),
        getAxiosApiConfigs(undefined, "SAYT_FIRST_URL_TIMEOUT, notify developers"))

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        if (!isAbortedError(error)) {
            if (action.isDebug) {
                yield* handleShopitApiRequestError(error)
            }
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiSaytFastUrlResponse>(res)
        if (response) {
            action.callback(response.url)
        }
    }
}

export function* watchSaytAction() {
    yield takeLatest(LOAD_SAYT, loadSayt);
    yield takeLatest(LOAD_SAYT_FIRST_URL, loadSaytFirstUrl);
}

