export const INTERACTIVE_TOGGLE_FILTERS = "TOGGLE_FILTERS";
export const INTERACTIVE_TOGGLE_CATEGORIES = "TOGGLE_CATEGORIES";
export const INTERACTIVE_FILTER_OPTION_TOGGLE = "TOGGLE_OPTION";
export const INTERACTIVE_EMAIL_RECOVERY_SEND = "RECOVERY_SEND"
export const INTERACTIVE_DELAY_API_RESPONSE = "DELAY_API_RESPONSE"

export const NOTIFICATION_THROW_ERROR = "ERROR";
export const NOTIFICATION_THROW_MESSAGE = "MESSAGE";
export const NOTIFICATION_THROW_DEBUG = "DEBUG";
export const NOTIFICATION_CLOSE = "CLOSE_ERROR";

export const START_LOADING_OVERLAY = "START_LOADING_OVERLAY";
export const END_LOADING_OVERLAY = "END_LOADING_OVERLAY";

// ----

export function getDefaultLang() {
    return 'sv'
}

export function getLanguageCodeToLang(lang: string | number): string {
    if (lang === 1) {
        return 'sv';
    } else if (lang === 2 /* || lang == 101 */) {       // mathias said to remove
        return 'en';
    } else if (lang === 'sv' || lang === 'en') {
        return lang;
    }
    return getDefaultLang();
}

export function getLangToLanguageCode(lang: string): number {
    if (lang == 'sv') {
        return 1
    } else if (lang == 'en') {
        return 2
    // } else if (lang == 'int') {      // mathias said to remove
    //     return 101
    }
    return 1;
}

// ----

export type MessageAction = {
    type: string,
    code: string | undefined,
    message: string,
}

export const triggerMessage = (code: string | undefined, message: string): MessageAction => ({
    type: NOTIFICATION_THROW_MESSAGE,
    code,
    message,
})

export type DebugAction = {
    type: string,
    message: string,
    trace: string|undefined
}

export const triggerDebug = (message: string, trace: string|undefined): DebugAction => ({
    type: NOTIFICATION_THROW_DEBUG,
    message,
    trace
})

// ----

export type ErrorAction = {
    type: string,
    code: string | undefined,
    name: string,
    message: string,
}

export const triggerError = (code: string | undefined, name: string, message: string): ErrorAction => ({
    type: NOTIFICATION_THROW_ERROR,
    code,
    name,
    message,
})

// ----

export type ErrorCloseAction = {
    type: string,
    key: string
}

export const triggerErrorClose = (key: string): ErrorCloseAction => ({
    type: NOTIFICATION_CLOSE,
    key,
})

// ----

export type InteractiveAction = {
    type: string,
    toggleOption: string | undefined
}
export const triggerToggleFilters = (): InteractiveAction => ({
    type: INTERACTIVE_TOGGLE_FILTERS,
    toggleOption: undefined
})

export const triggerToggleCategories = (): InteractiveAction => ({
    type: INTERACTIVE_TOGGLE_CATEGORIES,
    toggleOption: undefined
})

export const triggerToggleFilterOption = (optionId: string | undefined): InteractiveAction => ({
    type: INTERACTIVE_FILTER_OPTION_TOGGLE,
    toggleOption: optionId
})

// ----

export const enum InteractiveDelayType {
    RecoverCheck = 'RC',
    RecoverDecline = 'RD',
    RegisterConfirm = 'RGC'
}

export type InteractiveDelayApiResponseActionValue = {
    message: string | undefined,
    redirect: string | undefined
}

export type InteractiveDelayApiResponseAction = {
    type: string,
    key: string,
    value: InteractiveDelayApiResponseActionValue | undefined
}

export const triggerDelayedApiResponse = (key: string, response: InteractiveDelayApiResponseActionValue): InteractiveDelayApiResponseAction => ({
    type: INTERACTIVE_DELAY_API_RESPONSE,
    key: key,
    value: response
})

// ----

export type InteractiveRecoverySendAction = {
    type: string,
    emailSend: boolean
}

export const triggerRecoverySendFlag = (emailSend: boolean): InteractiveRecoverySendAction => ({
    type: INTERACTIVE_EMAIL_RECOVERY_SEND,
    emailSend: emailSend,
})

// ---

export type InteractiveLoadingKeyAction = {
    type: string,
}

export const triggerStartLoadingOverlay = (): InteractiveLoadingKeyAction => ({
    type: START_LOADING_OVERLAY
})

export const triggerEndLoadingOverlay = (): InteractiveLoadingKeyAction => ({
    type: END_LOADING_OVERLAY
})