import {put, select, takeEvery} from 'redux-saga/effects'
import {DebugAction, NOTIFICATION_THROW_DEBUG, triggerError, triggerMessage} from "../Action/interactive";

function* showMessage(action: DebugAction) {
    const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
    if (isDebug && false) {
        yield put(triggerMessage(undefined, "Debug: \n" + action.message + "\n" + action.trace))
    }
    // console.log(code + ':' + message);
}

export function* watchErrorAction() {
    yield takeEvery(NOTIFICATION_THROW_DEBUG, showMessage);
}

