export const COOKIE_LOG_DEVICE_ID = 'log_device_id';
export const COOKIE_LOG_SESSION_ID = 'log_session_id';
export const COOKIE_USER_SESSION_ID = 'user_session_id';

export const COOKIE_DEBUG = 'debug_enable';

export const COOKIE_COOKIE_CONSENT = 'CookieConsent';

export const COOKIE_DEMO_LANG = 'demo_lang';
export const COOKIE_DEMO_SHOW_CASHBACK = 'demo_show_cashback';

//attribute
export const FILTER_ATTRIBUTE = 'attribute'
//attribute group
export const FILTER_ATTRIBUTE_GROUP = 'attribute_group'
export const FILTER_SECTION = 'section'
//options
export const FILTER_OPTION = 'option'
//color
export const FILTER_COLOR = 'color'
//code
export const FILTER_CODE = 'code'
//static

export const SECTION_TYPE_UNP = 'unp'
export const SECTION_TYPE_TOP = 'top'


export const FILTER_VENDOR = 'vendor'
export const FILTER_MANUFACTURER = 'manufacturer'
export const FILTER_BRAND = 'brand'
export const FILTER_UNIQUE_NAME_PATH = 'unique_name_path'
export const FILTER_RELATED_UNIQUE_NAME_PATH = 'related_unique_name_path'
export const FILTER_RELATED_UNIQUE_NAME_PATH_COLLECTION = 'related_unique_name_path_collection'
export const FILTER_IMAGE = 'image'
export const FILTER_VIDEO = 'video'
export const FILTER_COMMENT = 'comment'
export const FILTER_SHARE = 'share'
export const FILTER_FAMILY = 'family'
export const FILTER_SYNSET = 'synset'
export const FILTER_TEXT = 'text'

//url_info numeric
export const FILTER_PRICE = 'price'
export const FILTER_PRICE_FIXED = 'numeric_price_fixed'

export const FILTER_NUMERIC = 'numeric'
//filter panel numeric
export const FILTER_NUMERIC_RANGE = 'numeric_range'
export const FILTER_NUMERIC_FIXED = 'numeric_fixed'
//double range numeric
export const FILTER_NUMERIC_DIMENSION = 'numeric_dimension'
export const FILTER_NUMERIC_RATIO = 'numeric_ratio'

//static keys
export const FILTER_PRICE_KEY = 's1'
export const FILTER_VENDOR_KEY = 's2'
export const FILTER_MANUFACTURER_KEY = 's3'
export const FILTER_BRAND_KEY = 's4'
export const FILTER_IMAGE_KEY = 's5'
export const FILTER_VIDEO_KEY = 's6'
export const FILTER_COMMENT_KEY = 's7'
export const FILTER_SHARE_KEY = 's8'
export const FILTER_KEY_FAMILY = 's11'
export const FILTER_KEY_SYNSET = 's17'
export const FILTER_KEY_TEXT = 'text'
export const FILTER_RELATED_UNIQUE_NAME_PATH_KEY = 's22'
export const FILTER_UNIQUE_NAME_PATH_KEY = 's23'
export const FILTER_RELATED_UNIQUE_NAME_PATH_COLLECTION_KEY = 's24'

//sub types
//filter by object id a1:o1|o2
export const FILTER_SUB_TYPE_ID = 'id'
//filter by range a1:1..2
export const FILTER_SUB_TYPE_NUMERIC = 'numeric'

export const STATIC_FILTERS = {
    FILTER_PRICE,
    FILTER_PRICE_FIXED,
    FILTER_VENDOR,
    FILTER_MANUFACTURER,
    FILTER_UNIQUE_NAME_PATH,
    FILTER_RELATED_UNIQUE_NAME_PATH,
    FILTER_RELATED_UNIQUE_NAME_PATH_COLLECTION,
    FILTER_BRAND,
    FILTER_IMAGE,
    FILTER_VIDEO,
    FILTER_COMMENT,
    FILTER_SHARE,
    FILTER_FAMILY,
    FILTER_SYNSET,
    FILTER_TEXT
}

export const STATIC_FILTER_KEYS = {
    FILTER_PRICE_KEY,
    FILTER_VENDOR_KEY,
    FILTER_MANUFACTURER_KEY,
    FILTER_BRAND_KEY,
    FILTER_UNIQUE_NAME_PATH_KEY,
    FILTER_RELATED_UNIQUE_NAME_PATH_KEY,
    FILTER_RELATED_UNIQUE_NAME_PATH_COLLECTION_KEY,
    FILTER_IMAGE_KEY,
    FILTER_VIDEO_KEY,
    FILTER_COMMENT_KEY,
    FILTER_SHARE_KEY,
    FILTER_KEY_FAMILY,
    FILTER_KEY_SYNSET,
    FILTER_KEY_TEXT
}

export const FILTERS = [
    FILTER_SECTION,
    FILTER_ATTRIBUTE,
    FILTER_ATTRIBUTE_GROUP,
    FILTER_OPTION,
    FILTER_COLOR,
    FILTER_CODE,
    FILTER_PRICE,
    FILTER_PRICE_FIXED,
    FILTER_VENDOR,
    FILTER_MANUFACTURER,
    FILTER_BRAND,
    FILTER_IMAGE,
    FILTER_VIDEO,
    FILTER_COMMENT,
    FILTER_SHARE,
    FILTER_FAMILY,
    FILTER_SYNSET,
    FILTER_TEXT,
    FILTER_NUMERIC,
    FILTER_NUMERIC_RANGE,
    FILTER_NUMERIC_FIXED,
    FILTER_NUMERIC_DIMENSION,
    FILTER_NUMERIC_RATIO
]

export const STATIC_FILTER_MAP: { [key: string]: { key: string, subType: string } } = {
    [STATIC_FILTERS.FILTER_PRICE]: {
        key: STATIC_FILTER_KEYS.FILTER_PRICE_KEY,
        subType: FILTER_SUB_TYPE_NUMERIC
    },
    [STATIC_FILTERS.FILTER_VENDOR]: {
        key: STATIC_FILTER_KEYS.FILTER_VENDOR_KEY,
        subType: FILTER_SUB_TYPE_ID
    },
    [STATIC_FILTERS.FILTER_MANUFACTURER]: {
        key: STATIC_FILTER_KEYS.FILTER_MANUFACTURER_KEY,
        subType: FILTER_SUB_TYPE_ID
    },
    [STATIC_FILTERS.FILTER_BRAND]: {
        key: STATIC_FILTER_KEYS.FILTER_BRAND_KEY,
        subType: FILTER_SUB_TYPE_ID
    },
    [STATIC_FILTERS.FILTER_UNIQUE_NAME_PATH]: {
        key: STATIC_FILTER_KEYS.FILTER_UNIQUE_NAME_PATH_KEY,
        subType: FILTER_SUB_TYPE_ID
    },
    [STATIC_FILTERS.FILTER_RELATED_UNIQUE_NAME_PATH]: {
        key: STATIC_FILTER_KEYS.FILTER_RELATED_UNIQUE_NAME_PATH_KEY,
        subType: FILTER_SUB_TYPE_ID
    },
    [STATIC_FILTERS.FILTER_RELATED_UNIQUE_NAME_PATH_COLLECTION]: {
        key: STATIC_FILTER_KEYS.FILTER_RELATED_UNIQUE_NAME_PATH_COLLECTION_KEY,
        subType: FILTER_SUB_TYPE_ID
    },
    [STATIC_FILTERS.FILTER_IMAGE]: {
        key: STATIC_FILTER_KEYS.FILTER_IMAGE_KEY,
        subType: FILTER_SUB_TYPE_NUMERIC
    },
    [STATIC_FILTERS.FILTER_VIDEO]: {
        key: STATIC_FILTER_KEYS.FILTER_VIDEO_KEY,
        subType: FILTER_SUB_TYPE_NUMERIC
    },
    [STATIC_FILTERS.FILTER_COMMENT]: {
        key: STATIC_FILTER_KEYS.FILTER_COMMENT_KEY,
        subType: FILTER_SUB_TYPE_NUMERIC
    },
    [STATIC_FILTERS.FILTER_SHARE]: {
        key: STATIC_FILTER_KEYS.FILTER_SHARE_KEY,
        subType: FILTER_SUB_TYPE_NUMERIC
    },
    [STATIC_FILTERS.FILTER_FAMILY]: {
        key: STATIC_FILTER_KEYS.FILTER_KEY_FAMILY,
        subType: FILTER_SUB_TYPE_ID
    },
    [STATIC_FILTERS.FILTER_SYNSET]: {
        key: STATIC_FILTER_KEYS.FILTER_KEY_SYNSET,
        subType: FILTER_SUB_TYPE_ID
    }
}