import React, {useEffect, useState} from "react";
import {triggerContact} from "../../Store/Action/session.login";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";
import {MetaTags} from "../../Components/App/Tags/Meta";

// ---

type ContactRouteState = {
    isMessageSend: boolean,
    name: string,
    email: string,
    message: string
}

const ComponentTranslationKey = "Meta"

export default function ContactRoute(props: {}) {

    // globals
    const dispatch = useDispatch()
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    const [state, setState] = useState<ContactRouteState>({
        isMessageSend: false,
        name: "",
        email: "",
        message: ""
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const onSubmit = function (e: React.SyntheticEvent) {
        e.preventDefault();

        const target = e.target as typeof e.target & {
            name: { value: string }
            email: { value: string }
            message: { value: string }
        };

        const name = target.name.value;           // do typecheck?
        const email = target.email.value;           // do typecheck?
        const message = target.message.value;

        dispatch(triggerContact(name, email, message));

        setState({
            isMessageSend: true,
            name: name,
            email: email,
            message: message,
        })
    }

    return (
        <div className={`service-page-container pt-16`}>

            <MetaTags title={getTranslation('contactTitle')}
                      canonicalPageUrl={`default`}
                      robots={undefined}/>

            <h1 className={`service-page-h1 pl-4`}>Kontakt</h1>

            {state.isMessageSend

                ? (<div className={`mt-8 border w-[40rem] px-4 py-8 bg-slate-50`}>
                    <div className={`text-2xl`}>Tack för ditt meddelande!</div>
                    <div className={`mt-8 text-xl`}>Vi kommer att återkomma till dig så fort som möjligt.</div>
                </div>)

                : (<div>
                    <div className={`pl-4`}>
                        Använd formuläret nedan för att kontakta oss på Shopit.
                    </div>
                    <form onSubmit={onSubmit}
                          className={`mt-8 border w-[40rem] p-4 bg-slate-50`}>
                        <div className={`pb-4`}>
                            <div>Namn</div>
                            <input name={`name`} className={`border w-96 px-4 py-2`} type="text"/>
                        </div>

                        <div className={`pb-4`}>
                            <div>E-post</div>
                            <input name={`email`} className={`border w-96 px-4 py-2`} type="email"/>
                        </div>

                        <div className={`pb-4`}>
                            <div>Meddelande</div>
                            <textarea name={`message`} className={`border w-[32rem] px-4 py-2`}></textarea>
                        </div>

                        <div>
                            <button type="submit" className={`btn btn-green w-36`}>SKICKA</button>
                        </div>
                    </form>
                </div>)}

            <div className={`mt-16 text-lg pl-4`}>
                <h4 className={``}>Försäljning</h4>

                <div className={`mt-2`}>Henrik Kjellberg</div>
                <div>
                    <a href={`tel:+46706150541`}>+46 (0)70 615 05 41</a>
                </div>
                <div>
                    <a href={`mailto:sales@shopit.se`} className={`text-shopit-green`}>sales@shopit.se</a>
                </div>

                <h4 className={`mt-16`}>Företagsuppgifter</h4>

                <div className={`mt-2`}>Shopit Online Europe AB</div>
                <div>V Esplanaden 5</div>
                <div>352 31 Växjö</div>

                <div className={`mt-2`}>Org.nr 556894-2832</div>
            </div>

        </div>
    );
}
