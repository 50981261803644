import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";
import {MetaTags} from "../../Components/App/Tags/Meta";

// ---

const ComponentTranslationKey = "Meta"

export default function CookieRoute(props: {}) {

    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={`service-page-container pt-16`}>

            <MetaTags title={getTranslation('cookiePolicyTitle')}
                      canonicalPageUrl={`default`}
                      robots={undefined}/>

            <h1 className={`service-page-h1`}>Cookiepolicy</h1>
            <h2 className={`service-page-h2`}>Vad är cookies?</h2>

            <div className={`service-page-block`}>
                Cookies är en textfil som skickas från vår webbserver och sparas på din webbläsare eller enhet. Shopit
                använder cookies för att lagra information om användarfunktioner som t.ex. sorteringsalternativ, hur
                många
                produkter som ska visas och möjligheten att låta dig som medlem att vara inloggad på sajten.
            </div>

            <div className={`service-page-block`}>
                Vi använder även cookies för att samla icke namngiven information om vilka sidor på sajten du besöker så
                att vi kan visa annonser som är mer relevanta för dig. Cookies används också för att analysera trafiken
                på sajten.
            </div>

            <div className={`service-page-block`}>
                På Shopit.se använder vi följande cookies:

                <ul className={`list-disc ml-8 pt-4`}>
                    <li>Sessionscookies (en tillfällig cookie som upphör när du stänger din webbläsare eller enhet).
                    </li>
                    <li>Varaktiga cookies (cookies som ligger kvar på din dator tills du tar bort dem eller de går
                        ut).
                    </li>
                    <li>Förstapartscookies (cookies som sätts av webbplatsen du besöker).</li>
                    <li>Tredjepartscookies (cookies som satts av en tredjeparts webbplats).</li>
                    <li>Liknande tekniker (tekniker som lagrar information i din webbläsare eller i din enhet på ett
                        sätt som liknar cookies).
                    </li>
                </ul>
            </div>

            <div className={`service-page-block`}>
                Om du inte vill att vi lagrar cookies på din dator har du möjligheten att blockera användandet av
                cookies
                genom att ändra en inställning i din webbläsare. Var vänlig besök din webbläsares webbplats för
                information
                om hur du går tillväga. Om du väljer att inaktivera cookies kan dock delar av sajten inte fungera.
            </div>

            <h3 className={`service-page-h3`}>Kan jag ångra mitt godkännande?</h3>
            <div className={`service-page-block`}>
                Du kan närsomhelst, välja att ångra ditt godkännande av cookies. Du behöver ta bort de kakor som
                satts i
                din
                webbläsare eller gå till sidan för inställningar och ändra dina val.
            </div>

            <div className={`service-page-block`}>
                Vänligen, notera att tredjepartskakor som redan satts i din webbläsare innan du ångrat ditt
                godkännande
                inte
                automatiskt kommer att tas bort.
            </div>

            <h3 className={`service-page-h3`}>Så blockerar du eller tar bort kakor</h3>
            <div className={`service-page-block`}>
                De flesta webbläsare tillåter att du tar bort, blockerar eller frågar innan en kaka sätts. Beroende
                på
                vilken webbläsare du använder så hittar du information avseende hantering och lagring av kakor under
                avdelningen för inställningar i webbläsaren.
            </div>

            <h2 className={`service-page-h2`}>Cookies från tredje part</h2>
            <h3 className={`service-page-h3`}>Undersökningar</h3>
            <div className={`service-page-block`}>
                Via Google Analytics analyserar vi hur vår tjänst används. Google Analytics är en tjänst som
                tillhandahålls
                av Google som spårar och rapporterar trafik på hemsidor. Den data som samlas in delas med andra
                Google
                tjänster. Datan används bland annat för personalisering och mätbarhet av annonser i Googles
                annonsnätverk.
            </div>

            {/*<script id="CookieDeclaration"*/}
            {/*        src="https://consent.cookiebot.com/e797fc46-8cd7-482e-b7bf-63aa6c179d9d/cd.js"*/}
            {/*        type="text/javascript" async></script>*/}

        </div>
    );
}
