export const URL_CONTEXT_RESET = "URL_CONTEXT_RESET";
export const URL_CONTEXT_ADD = "URL_CONTEXT_ADD";

export type UrlContextResetAction = {
    type: string,
}

export const triggerUrlContextReset = (): UrlContextResetAction => ({
    type: URL_CONTEXT_RESET,
})


export type UrlContextAddAction = {
    type: string,
    urlContext: Partial<IUrlInfoContext>
}

export const triggerUrlContextAdd = (urlContext: Partial<IUrlInfoContext>): UrlContextAddAction => ({
    type: URL_CONTEXT_ADD,
    urlContext
})
