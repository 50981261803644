import {convertSearchParamsToUrlResolveParams, getUrlFromPageParams} from "../../../Helpers/SearchHelper";
import {triggerCacheAccessSearchWithCallback} from "../../../Store/Action/cache.search";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {Listbox} from "@headlessui/react";
import {IconChevronDown, IconRadioButton, IconRadioButtonOff} from "../../App/Icons";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {selectSearchCache} from "../../../Store/StoreHelper";
import {useNavigate} from "react-router-dom";
import {getDefaultLang, triggerDebug} from "../../../Store/Action/interactive";
import {SearchRouteContext} from "../../../Store/Context/SearchRouteContext";
import {buildSearchUrl} from "../../../Helpers/UrlFormatter.Search";

const ComponentTranslationKey = "Grouping"            // this is placeholder key. replace

export default function FilterBarGroupSortButton() {

    // globals
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const searchContext = useContext(SearchRouteContext);

    const {t} = useTranslation(ComponentTranslationKey)
    const urlContext = useSelector((state: IAppState) => state.urlContext)

    // internal functions
    const getTranslation = (key: keyof TranslationSet[typeof ComponentTranslationKey]) => {
        return t(key);
    }

    const getTranslationGroupManufacture = (key: keyof TranslationSet["Grouping"]["manufacturer"]) => {
        // i18next have problem with process '.' separated path to key as default
        // so we force a path with ':'
        // i tried to debug, but not understand how it works... but works
        // (copy comment for copy+paste multilevel translations functions)
        return t('Grouping:manufacturer:' + key)
    }

    const getTranslationGroupBrand = (key: keyof TranslationSet["Grouping"]["branding"]) => {
        return t('Grouping:branding:' + key)
    }

    const getTranslationGroupRetail = (key: keyof TranslationSet["Grouping"]["retailer"]) => {
        return t('Grouping:retailer:' + key)
    }

    const getTranslationGroupPrice = (key: keyof TranslationSet["Grouping"]["price"]) => {
        return t('Grouping:price:' + key)
    }

    //
    const searchData = useSelector((state: IAppState) => selectSearchCache(state, searchContext.cacheKey));
    const searchQuery = searchData && searchData.object ? searchData.object.searchQuery : undefined
    const isDebugEnabled = useSelector((state: IAppState) => state.config.debug)

    // render
    const onGroupChange = (val: string) => {
        if (searchQuery) {
            const valObj: { group: number, sort: number } = JSON.parse(val);

            const urlPageParams = convertSearchParamsToUrlResolveParams({
                ...searchQuery,
                sort: valObj.sort,
                group: valObj.group
            })

            dispatch(triggerCacheAccessSearchWithCallback(urlPageParams, false, isDebugEnabled, () => {
                    const [toPathName, toSearch, isValidLink] = buildSearchUrl(urlPageParams, urlContext);

                    if (!isValidLink) {
                        console.log("Search link error");
                        console.log(toPathName, toSearch, urlPageParams, urlContext)
                        const trace = new Error().stack
                        console.log(trace)
                        dispatch(triggerDebug("Search link error (console)", trace))
                    }

                    navigate({
                        pathname: toPathName,
                        search: toSearch
                    })
                }
            ))
        }
    }

    const GROUP = {
        RETAILER: 1,
        MANUFACTURER: 2,
        BRANDING: 3,
        PRICE: 7
    }

    const SORT = {
        RANK: 1,
        PRICE_LOWEST: 2,
        PRICE_HIGHEST: 3
    }

    const options = [
        {group: GROUP.MANUFACTURER, sort: SORT.RANK, text: getTranslationGroupManufacture("rank")},
        {
            group: GROUP.MANUFACTURER,
            sort: SORT.PRICE_LOWEST,
            text: getTranslationGroupManufacture("price_lowest")
        },
        {
            group: GROUP.MANUFACTURER,
            sort: SORT.PRICE_HIGHEST,
            text: getTranslationGroupManufacture("price_highest")
        },
        {separator: true},

        {group: GROUP.BRANDING, sort: SORT.RANK, text: getTranslationGroupBrand("rank")},
        {group: GROUP.BRANDING, sort: SORT.PRICE_LOWEST, text: getTranslationGroupBrand("price_lowest")},
        {group: GROUP.BRANDING, sort: SORT.PRICE_HIGHEST, text: getTranslationGroupBrand("price_highest")},
        {separator: true},

        {group: GROUP.RETAILER, sort: SORT.RANK, text: getTranslationGroupRetail("rank")},
        {group: GROUP.RETAILER, sort: SORT.PRICE_LOWEST, text: getTranslationGroupRetail("price_lowest")},
        {group: GROUP.RETAILER, sort: SORT.PRICE_HIGHEST, text: getTranslationGroupRetail("price_highest")},
        {separator: true},

        {group: GROUP.PRICE, sort: SORT.PRICE_LOWEST, text: getTranslationGroupPrice("lowest")},
        {group: GROUP.PRICE, sort: SORT.PRICE_HIGHEST, text: getTranslationGroupPrice("highest")}
    ]

    const selected = options.find((i) => (
        i.group && i.sort &&
        i.group == searchQuery?.group &&
        i.sort == searchQuery?.sort)) || options[0];

    const selectedValue = JSON.stringify({
        group: selected?.group || 0,
        sort: selected?.sort || 0
    })

    return <Listbox value={selectedValue} onChange={onGroupChange}>
        <div className="filter-bar-group-sort">
            <Listbox.Button className="filter-bar-group-sort-button">
                    <span
                        className="filter-bar-group-sort-button-text">{getTranslation('sort_by')}: {selected?.text}</span>
                <span className="filter-bar-group-sort-button-chevron">
                        <IconChevronDown style={{width: '1.5em', display: 'inline'}}/>
                    </span>
            </Listbox.Button>
            <Listbox.Options className="filter-bar-group-sort-panel">
                {options.map((option, key) => (
                    option.separator
                        ? (<div key={key} className={`filter-bar-group-sort-option-separator`}></div>)
                        : (<Listbox.Option
                            key={key}
                            className={({active, selected}) =>
                                `filter-bar-group-sort-option ${active ? 'active' : ''} ${selected ? 'selected' : ''}`
                            }
                            value={JSON.stringify({
                                group: option?.group || 0,
                                sort: option?.sort || 0
                            })}>
                            {({active, selected}) => (
                                <span className={`option-text`}>
                                        {selected
                                            ? <IconRadioButton
                                                style={{
                                                    width: '2em',
                                                    display: 'inline-block',
                                                    marginBottom: '-0.5em',
                                                    marginRight: '0.5em',
                                                }}
                                                color={`white`}/>
                                            : <IconRadioButtonOff
                                                style={{
                                                    width: '2em',
                                                    display: 'inline-block',
                                                    marginBottom: '-0.5em',
                                                    marginRight: '0.5em',
                                                }}
                                                color={`#5f644d`}/>
                                        }
                                    {option.text}
                                    </span>
                            )}
                        </Listbox.Option>)
                ))}
            </Listbox.Options>
        </div>
    </Listbox>
}