import React, {useContext} from "react";
import {useSelector} from 'react-redux';
import FiltersWrapper from "../FiltersWrapper";
import {selectSearchCache} from "../../../Store/StoreHelper";
import {SearchRouteContext} from "../../../Store/Context/SearchRouteContext";

export default function FiltersPanel() {

    // globals
    const searchContext = useContext(SearchRouteContext);

    // extract
    const filters = useSelector((state: IAppState) => {
        const searchData = selectSearchCache(state, searchContext.cacheKey);
        return searchData && searchData.object ? searchData.object.filters : undefined
    })

    // render
    return (
        <div className="js-sidebar">
            <div className="filters-panel-content">
                {(
                    filters ? filters.map((i, key) =>
                        <FiltersWrapper key={("filter-" + key)}
                                        item={i}
                                        level={0}
                                        demonstrationMode={false}/>
                    ) : null
                )}
            </div>
        </div>
    );
}
