import {getLangToLanguageCode} from "../Store/Action/interactive";

export const getUserSettingsPlaceholderParams = function (): IUserSettingsParams {
    return {
        firstName: undefined,
        lastName: undefined,
        oldPassword: undefined,
        newPassword: undefined,
        newPasswordRepeated: undefined,
        year: undefined,
        month: undefined,
        day: undefined,
        address: undefined,
        zipCode: undefined,
        language: undefined,
        city: undefined,
        email: undefined,
        gender: undefined,
        country: undefined,
        currency: undefined,
        description: undefined,
        avatar: undefined,
        background: undefined,
    }
}

export const getUserSettingsParams = function (user: IUser): IUserSettingsParams {
    return {
        firstName: user.firstName,
        lastName: user.lastName,
        oldPassword: undefined,
        newPassword: undefined,
        newPasswordRepeated: undefined,
        year: user.details.year,
        month: user.details.month,
        day: user.details.day,
        address: user.delivery.postAddress,
        zipCode: user.delivery.postCode,
        language: getLangToLanguageCode(user.localization.language),
        city: user.delivery.postCity,
        email: user.details.email,
        gender: user.details.gender,
        country: user.delivery.countryIndex,
        currency: user.localization.currencyIndex,
        description: undefined,
        avatar: undefined,
        background: undefined,
    }
}