export function getDebugProductUrl(productId: any): string {
    return 'https://backend.shopit.com/application/Backend/ContentTools/?route=pt&mode=FRONTEND&tab=5&pid=' + productId;
}

export function getDebugCategoryUrl(categoryId: any): string {
    return 'https://backend.shopit.com/application/Backend/ContentTools/?route=category_editor&cid=' + categoryId;
}

export function getDebugCategoryDescription(categoryId: any): string {
    return 'https://backend.shopit.com/application/Backend/ContentTools/?route=rCommonTool&selectedComponentId=ai-text-tabs&aipTextCategoryId=' + categoryId + '&defaultEngine=FG&aiTextTab=1&aipMpPage=1';
}

export function getDebugProductToolUrl(urlInfo: IUrlResolvedParams, group: any, sort: any, categoryId: any, brandId: any, text: any, fold: any, manualCfs: any, brandCfs: any, title: any, expand: any, page: number | undefined = 0, feedId: number | string | undefined = undefined): string {
    const tab = brandId ? 8 : 5

    let url = null

    if (urlInfo.bridgeParams.categoryId) {
        url = `https://backend.shopit.com/application/Backend/ContentTools/?route=pt&tab=${tab}&cid=${categoryId}&cleanShowAll=true&pageSizeClean=60`

        const cfs = urlInfo.bridgeParams.filters

        if (cfs && !manualCfs) {
            url += `&cleanFilterString=${cfs}`
        }

        if (!cfs && brandCfs) {
            url += `&cleanFilterString=${brandCfs}`
        }

        if (urlInfo.bridgeParams.showSecondary) {
            url += `&cleanShowSecondary=1`
        }

        if (text) {
            url += `&cleanText=${text}`
        }

    } else {
        url = `http://backend.shopit.se/application/Backend/ContentTools/?route=pt&tab=${tab}&cid=${categoryId}&cleanShowAll=true&pageSizeClean=60`
    }

    if (fold) {
        url += `&cleanFilterFolding=${fold}`
    }

    if (expand) {
        url += `&cleanFilterExpand=${expand}`
    }

    if (manualCfs) {
        url += `&cleanFilterString=${manualCfs}`
    }

    if (brandCfs) {
        url += `&cleanFilterString=${brandCfs}`
    }

    if (brandId) {
        url += `&bBrandId=${brandId}`
    }

    if (page && page > 0) {
        url += `&pageClean=${page}`
    }

    if (group > 0) {
        url += `&cleanGroup=${group}`
    }

    if (sort > 0) {
        url += `&cleanSort=${sort}`
    }

    if (feedId !== undefined) {
        url += `&filterFeedId=${feedId}`
    }

    return url;
}