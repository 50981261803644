import {
    NOTIFICATION_CLOSE,
    END_LOADING_OVERLAY,
    ErrorAction,
    ErrorCloseAction,
    INTERACTIVE_DELAY_API_RESPONSE,
    INTERACTIVE_EMAIL_RECOVERY_SEND,
    INTERACTIVE_FILTER_OPTION_TOGGLE,
    INTERACTIVE_TOGGLE_CATEGORIES,
    INTERACTIVE_TOGGLE_FILTERS,
    InteractiveAction,
    InteractiveDelayApiResponseAction,
    InteractiveRecoverySendAction,
    MessageAction,
    START_LOADING_OVERLAY,
    NOTIFICATION_THROW_ERROR,
    NOTIFICATION_THROW_MESSAGE
} from "../Action/interactive";
import {getSearchCacheKey} from "../Action/cache.search";

export const interactiveInitialState: IAppInteractiveState = {
    messages: undefined,

    showFilters: false,
    showCategories: false,
    expandFilterOptions: [],

    showLoadingOverlay: false,

    emailRecoverySend: false,
    delayedApiResult: {}
}

const interactiveReducer = (state: IAppInteractiveState = interactiveInitialState,
                            action: InteractiveAction
                                | InteractiveRecoverySendAction
                                | InteractiveDelayApiResponseAction
                                | ErrorAction
                                | ErrorCloseAction): IAppInteractiveState => {
    switch (action.type) {

        case NOTIFICATION_THROW_ERROR:
            const errorAction = action as ErrorAction;

            if (state.messages) {
                return {
                    ...state,
                    messages: [...state.messages,
                        {
                            type: INotificationMessage_Type.Error,
                            key: 'error-' + state.messages.length,
                            code: errorAction.code,
                            title: errorAction.name,
                            message: errorAction.message
                        }]
                };
            } else {
                return {
                    ...state,
                    messages: [{
                        type: INotificationMessage_Type.Error,
                        key: 'error-0',
                        code: errorAction.code,
                        title: errorAction.name,
                        message: errorAction.message
                    }]
                };
            }
        case NOTIFICATION_THROW_MESSAGE:
            const messageAction = action as MessageAction;

            if (state.messages) {
                return {
                    ...state,
                    messages: [...state.messages,
                        {
                            type: INotificationMessage_Type.Information,
                            key: 'error-' + state.messages.length,
                            code: messageAction.code,
                            title: undefined,
                            message: messageAction.message
                        }]
                };
            } else {
                return {
                    ...state,
                    messages: [{
                        type: INotificationMessage_Type.Information,
                        key: 'error-0',
                        code: messageAction.code,
                        title: undefined,
                        message: messageAction.message
                    }]
                };
            }
        case NOTIFICATION_CLOSE:
            const errorCloseAction = action as ErrorCloseAction;
            return {
                ...state,
                messages: state.messages?.filter(i => i.key != errorCloseAction.key)
            };

        case INTERACTIVE_TOGGLE_FILTERS:

            return {
                ...state,
                showFilters: !state.showFilters,
                showCategories: false
            };

        case INTERACTIVE_TOGGLE_CATEGORIES:
            return {
                ...state,
                showCategories: !state.showCategories,
                showFilters: false
            };

        case INTERACTIVE_FILTER_OPTION_TOGGLE: {
            const toggleAction = action as InteractiveAction;
            const optionIndex = toggleAction.toggleOption ? state.expandFilterOptions.indexOf(toggleAction.toggleOption) : -1;
            const nextExpandOptions = [...state.expandFilterOptions];
            if (optionIndex !== -1) {
                nextExpandOptions.splice(optionIndex);
            } else {
                if (toggleAction.toggleOption) {
                    nextExpandOptions.push(toggleAction.toggleOption)
                }
            }
            return {
                ...state,
                expandFilterOptions: nextExpandOptions
            }
        }

        case INTERACTIVE_EMAIL_RECOVERY_SEND: {
            const responseAction = action as InteractiveRecoverySendAction;
            return {
                ...state,
                emailRecoverySend: responseAction.emailSend
            };
        }

        case INTERACTIVE_DELAY_API_RESPONSE: {
            const responseAction = action as InteractiveDelayApiResponseAction;
            const delayedApiResults = {...state.delayedApiResult, [responseAction.key]: responseAction.value};
            return {
                ...state,
                delayedApiResult: delayedApiResults
            };
        }

        case START_LOADING_OVERLAY: {
            return {
                ...state,
                showLoadingOverlay: true
            };
        }

        case END_LOADING_OVERLAY: {
            return {
                ...state,
                showLoadingOverlay: false
            };
        }
    }
    return state;
}

export default interactiveReducer;