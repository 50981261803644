import {call, put, select, takeEvery} from 'redux-saga/effects'
import axios, {AxiosResponse} from "axios";
import {handleShopitApi, handleShopitApiRequestError} from "./helper"

import {
    LOAD_PRODUCT_OFFER_DATA, LoadProductOfferAction,
} from "../Action/external";
import {selectProductRequestOptions} from "../StoreHelper";
import {triggerEndLoadingOverlay} from "../Action/interactive";
import {
    CACHE_REQUEST_PRODUCT_WITH_CALLBACK, CACHE_REQUEST_PRODUCTS_WITH_CALLBACK,
    CacheRequestProductAction,
    CacheRequestProductsAction,
    triggerCacheSaveProductLoading,
    triggerCacheSaveProductNotFound,
    triggerCacheSaveProductFound
} from "../Action/cache.product";
import {getAxiosApiConfigs, serializePostParams} from "../../Helpers/ApiHelper";
import {triggerUrlContextAdd} from "../Action/urlContext";

function* requestProduct(action: CacheRequestProductAction) {

    const productOptions: IApiProductRequestPostParams = yield select(selectProductRequestOptions)
    const req = call(
        axios.post,
        `/api/product/${action.productId}`,
        serializePostParams<IApiProductRequestPostParams>(productOptions),
        getAxiosApiConfigs(undefined, "PS_PRODUCT_TIMEOUT, notify developers"));

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        yield put(triggerCacheSaveProductLoading([action.productId], productOptions));

        const response = yield* handleShopitApi<IApiProductResponse>(res)
        if (response) {
            yield put(triggerUrlContextAdd(response.urlContext))
            yield put(triggerCacheSaveProductFound(response.data.product, response.data.category, response.data.data.variants, response.data.data.debug, productOptions));
        } else {
            yield put(triggerCacheSaveProductNotFound([action.productId], productOptions));
        }

        yield put(triggerEndLoadingOverlay())
        if (action.callback) action.callback();
    }
}

function* requestProducts(action: CacheRequestProductsAction) {

    const productOptions: IApiProductRequestPostParams = yield select(selectProductRequestOptions)
    const req = call(
        axios.post,
        `/api/products/${action.productIds.join(',')}`,
        serializePostParams<IApiProductRequestPostParams>(productOptions),
        getAxiosApiConfigs(undefined, "PS_PRODUCT_LIST_TIMEOUT, notify developers"));

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        yield put(triggerCacheSaveProductLoading(action.productIds, productOptions));

        let missingProducts = [...action.productIds]
        const response = yield* handleShopitApi<IApiProductsResponse>(res)

        if (response) {
            yield put(triggerUrlContextAdd(response.urlContext))

            for (let i = 0; i < response.data.products.length; i++) {
                yield put(triggerCacheSaveProductFound(response.data.products[i], response.data.categories[response.data.products[i].category_id], undefined, response.data.data[response.data.products[i].id].debug, productOptions));
                missingProducts = missingProducts.filter(a => a != response.data.products[i].id)
            }
        }

        if (missingProducts.length) {
            yield put(triggerCacheSaveProductNotFound(missingProducts, productOptions));
        }

        yield put(triggerEndLoadingOverlay())
        if (action.callback) action.callback();
    }
}

function* requestProductOffer(action: LoadProductOfferAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axios.post,
        `/api/data/product_offer/`,
        serializePostParams<IApiProductOfferRequest>({
            productIds: [action.productId],
            returnLanguageId: "1", //TODO why string? Why 1 default?
            returnCurrencyId: 1,
            _logPageToken: logToken || null,
        }),
        getAxiosApiConfigs(undefined, "PS_PRODUCT_OFFER_TIMEOUT, notify developers"))

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiProductOfferResponse>(res)

        if (response) {
            if (action.callback) {
                let productUrl: string = ""
                let cashback: number | undefined = undefined
                if (response.productOffers && response.productOffers.length > 0) {
                    const productOffer = response.productOffers[0]
                    if (productOffer.productUrl) {
                        productUrl = productOffer.productUrl
                    }
                    if (productOffer.websiteStore && productOffer.websiteStore.commissionOrderPercentage) {
                        const price = productOffer.priceSaleInclVat ? productOffer.priceSaleInclVat : productOffer.priceInclVat
                        let cashbackPrice = price * (1 - (productOffer.websiteStore.commissionOrderPercentage / 100))
                        cashback = (price - cashbackPrice) / 2.0
                    }
                }
                action.callback(productUrl, cashback);
            }
        }
    }
}

export function* watchProductAction() {
    yield takeEvery(CACHE_REQUEST_PRODUCT_WITH_CALLBACK, requestProduct);
    yield takeEvery(CACHE_REQUEST_PRODUCTS_WITH_CALLBACK, requestProducts);
    yield takeEvery(LOAD_PRODUCT_OFFER_DATA, requestProductOffer);
}

