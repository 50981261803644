import React from "react";
import {SpecificationRowHeader, SpecificationRowValue} from "./SpecificationRow";
import ProductHeaderBody from "./ProductHeaderBody";

interface Specifications {
    [specificationHeader: string]: {
        [attributeName: string]: {
            [key: string]: {
                [key: number]: string
            }
        }
    }
}

export default function SpecificationsTable(props: {
    products: (IProduct | IMissingProduct)[],
    debugData: { [productId: number]: IProductDebugData } | undefined,
    includeProductImage: boolean,
}) {

    const products = props.products;
    if (!products) return null;

    let specifications: Specifications = {};
    products.forEach((productCandidate, index) => {
        const product = productCandidate as Partial<IProduct>
        if (product?.specifications) {
            for (const specification of product?.specifications) {

                if (!specifications.hasOwnProperty(specification.header)) {
                    specifications[specification.header] = {};
                }
                for (const attributeValue of specification.attributeValues) {
                    if (!specifications[specification.header].hasOwnProperty(attributeValue.attribute)) {
                        specifications[specification.header][attributeValue.attribute] = {};
                    }
                    if (!specifications[specification.header][attributeValue.attribute].hasOwnProperty(attributeValue.indent)) {
                        specifications[specification.header][attributeValue.attribute][attributeValue.indent] = {};
                    }
                    specifications[specification.header][attributeValue.attribute][attributeValue.indent][index] = attributeValue.value;
                }
            }
        }
    });

    //render, we have empty values to preserve header order, and for future use suggestion, we need to skip them
    let rows = []
    let counter = 0;
    for (const header of Object.keys(specifications)) {
        let addHeader = true;
        for (const attribute of Object.keys(specifications[header])) {
            for (const indent of Object.keys(specifications[header][attribute])) {
                let values = [];
                let anyNotEmpty = false;
                for (let i = 0; i < products.length; i++) {
                    let value = specifications[header][attribute][indent].hasOwnProperty(i)
                        ? specifications[header][attribute][indent][i]
                        : '';
                    if (value !== '') {
                        anyNotEmpty = true;
                    }
                    values.push(value);
                }
                if (anyNotEmpty) {
                    if (addHeader) {
                        rows.push(<SpecificationRowHeader key={`row-${header}-${counter}`}
                                                          name={header}
                                                          level={0}
                                                          productCount={products.length}/>);
                        addHeader = false;
                        counter++;
                    }
                    rows.push(
                        <SpecificationRowValue key={`row-${header}-${attribute}-${counter}`}
                                               name={attribute}
                                               value={values}
                                               level={parseInt(indent) + 1}/>
                    )
                    counter++;
                }
            }
        }
    }

    return <table className="specification-table">
        {props.includeProductImage
            ? <ProductHeaderBody products={products} debugData={props.debugData}/>
            : null}
        <tbody>{rows}</tbody>
    </table>
}
