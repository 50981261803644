import React from "react"
import FilterAttributeSection from "../FilterAttributeSection"
import FilterAttributeGroup from "../FilterAttributeGroup"
import FilterAttribute from "../FilterAttribute"
import FilterOption from "../FilterOption"
import FilterManufacturer from "../FilterManufacturer"
import FilterNumeric from "../FilterNumeric"
import FilterStatic from "../FilterStatic"
import FilterBranding from "../FilterBranding"
import FilterColor from "../FilterColor"
import FilterRelatedUniqueNamePathSection from "../FilterRelatedUniqueNamePathSection";
import FilterRelatedUniqueNamePath from "../FilterRelatedUniqueNamePath";

type FiltersWrapperProps = {
    item?: IFilterPanel_ObjectWrapper,
    detachSearchParamAttributeFilterChildren?: Function,
    demonstrationMode: boolean,
    level: number
}

function FiltersWrapper(props: FiltersWrapperProps) {
    const item = props.item

    if (!item) return null

    if (item.objectType == IFilterPanel_ObjectType.Section) {
        return <FilterAttributeSection section={item as IFilterPanel_Section}
                                       level={props.level}
                                       demonstrationMode={props.demonstrationMode}/>
    } else if (item.objectType == IFilterPanel_ObjectType.AttributeGroup) {
        return <FilterAttributeGroup group={item as IFilterPanel_AttributeGroup}
                                     level={props.level}
                                     demonstrationMode={props.demonstrationMode}/>
    } else if (item.objectType == IFilterPanel_ObjectType.Attribute) {
        return <FilterAttribute attribute={item as IFilterPanel_Attribute}
                                level={props.level}
                                demonstrationMode={props.demonstrationMode}/>
    } else if (item.objectType == IFilterPanel_ObjectType.Option) {
        return <FilterOption option={item as IFilterPanel_Option}
                             level={props.level}
                             detachSearchParamAttributeFilterChildren={props.detachSearchParamAttributeFilterChildren}
                             demonstrationMode={props.demonstrationMode}/>
    } else if (item.objectType == IFilterPanel_ObjectType.Manufacturer) {
        return <FilterManufacturer manufacturer={item as IFilterPanel_Manufacturer}
                                   level={props.level}
                                   demonstrationMode={props.demonstrationMode}/>
    } else if (item.objectType == IFilterPanel_ObjectType.Branding) {
        return <FilterBranding branding={item as IFilterPanel_Branding}
                               level={props.level}
                               demonstrationMode={props.demonstrationMode}/>
    } else if (item.objectType == IFilterPanel_ObjectType.Numeric) {
        return <FilterNumeric numeric={item as IFilterPanel_Numeric}
                              level={props.level}
                              demonstrationMode={props.demonstrationMode}/>
    } else if (item.objectType == IFilterPanel_ObjectType.Static) {
        return <FilterStatic static={item as IFilterPanel_Static}
                             demonstrationMode={props.demonstrationMode}/>
    } else if (item.objectType == IFilterPanel_ObjectType.Color) {
        return <FilterColor color={item as IFilterPanel_Color}
                            demonstrationMode={props.demonstrationMode}/>
    } else if (item.objectType == IFilterPanel_ObjectType.Unknown) {
        return (<div>{item.objectType} {(item as IFilterItem)._secondaryType}</div>)
    } else if (item.objectType == IFilterPanel_ObjectType.SectionUniqueNamePath) {
        return <FilterRelatedUniqueNamePathSection section={item as IFilterPanel_SectionUniqueNamePath}
                                            level={props.level}
                                            demonstrationMode={props.demonstrationMode}/>
    } else if (item.objectType == IFilterPanel_ObjectType.RelatedUniqueNamePath) {
        return <FilterRelatedUniqueNamePath uniqueNamePath={item as IFilterPanel_RelatedUniqueNamePath}
                                     level={props.level}
                                     demonstrationMode={props.demonstrationMode}/>
    } else {
        return (<div>{item.objectType} / unknown type </div>)
    }
}

export default FiltersWrapper
