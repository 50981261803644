export const LOAD_USER = "LOAD_USER";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";

// ----

export type UserAction = {
    type: string,
    userId: number,
}

export const triggerLoadUser = (userId: number): UserAction => ({
    type: LOAD_USER,
    userId,
});

// ----

export type UserLoadSuccessAction = {
    type: string,
    user: IUser
}

export const triggerLoadUserSuccess = (user: IUser): UserLoadSuccessAction => ({
    type: LOAD_USER_SUCCESS,
    user
});