import React from "react";

export function SpecificationRowValue(props: { name: string, value: (string | JSX.Element | null)[], level?: number }) {
    return <tr>
        <td className={`specification-row-label ${props.level ? `cell-level-${props.level}` : null}`} key={-1}>
            {props.name}
        </td>
        {props.value.map((value, index) =>
            <td className="specification-row-value" key={index}>{value}</td>
        )}
    </tr>
}

export function SpecificationRowHeader(props: { name: string, level?: number, productCount: number }) {
    return <tr>
        <td colSpan={props.productCount + 1}
            className={`specification-row-header ${props.level ? `cell-level-${props.level}` : null}`}>{props.name}</td>
    </tr>;
}