import {
    getDomainImageUrl,
    getImageUrl, translateContentImageType, translateImageOverlayType,
    translateImageType, translateImageWatermarkType,
    translateProductImageType
} from "../../../Helpers/ImageHelper";
import React from "react";

const productImageOnErrorHandler = function (e: React.SyntheticEvent) {
    (e.currentTarget as any).src = getImageUrl(undefined, '');
}

const domainImageOnErrorHandler = function (e: React.SyntheticEvent) {
    (e.currentTarget as any).src = getDomainImageUrl(undefined, '');
}

/** @see ProductImageXsDemo
 *  @see ProductImageSDemo
 *  @see ProductImageMDemo
 *  @see ProductImageLDemo
 *  */
export function ProductImage(props: {
    imageId: number,
    alt: string,
    size: 'xs' | 's' | 'm' | 'l' | 'xl',
    imageClass?: string
}) {

    const sizeClass =
        props.size == "xs" ? "w-image-width-xs h-image-height-xs"
            : props.size == "s" ? "w-image-width-s h-image-height-s"
                : props.size == "m" ? "w-image-width-m h-image-height-m"
                    : props.size == "l" ? "w-image-width-l h-image-height-l"
                        : props.size == "xl" ? "w-full aspect-4/3" : undefined;

    return <img className={`${sizeClass} ${props.imageClass} object-scale-down`}
                src={getImageUrl(props.imageId, props.size)}
                alt={props.alt}
                onError={productImageOnErrorHandler}/>
}

export function DomainImage(props: {
    domainId: number | undefined,
    alt: string,
    size: 'xs' | 's' | 'm' | 'l',
}) {

    const sizeClass =
        props.size == "xs" ? "w-image-width-xs h-image-height-xs"
            : props.size == "s" ? "w-image-width-s h-image-height-s"
                : props.size == "m" ? "w-image-width-m h-image-height-m"
                    : props.size == "l" ? "w-image-width-l h-image-height-l" : undefined;

    return <img className={sizeClass + " object-scale-down"}
                src={getDomainImageUrl(props.domainId, props.size)}
                alt={props.alt}
                onError={domainImageOnErrorHandler}/>
}

export function FourProductImages(props: {
    imageIds: number[],
    alt: string,
    size: 'xs' | 's' | 'm' | 'l',
    squareSize?: boolean,
    imageSize: 'xs' | 's' | 'm' | 'l',
    imageClass?: string
}) {

    if (props.imageIds.length > 0 && props.imageIds.length < 4) {
        return <ProductImage size={props.size}
                             imageId={props.imageIds[0]}
                             alt={props.alt}
                             imageClass={props.imageClass}/>
    }

    const sizeClass =
        props.squareSize
            ? undefined
            : (props.size == "xs" ? "h-image-height-xs"
                : props.size == "s" ? "h-image-height-s"
                    : props.size == "m" ? "h-image-height-m"
                        : props.size == "l" ? "h-image-height-l" : undefined);

    return <div className={`p-2 flex-none grid gap-2 grid-cols-2 grid-rows-2 ${sizeClass}`}>
        {props.imageIds.map((imageId, index) => (
            <div key={'k' + index}>
                <img className={`w-full aspect-4/3 object-contain ${props.imageClass}`}
                     src={getImageUrl(imageId, props.imageSize)}
                     alt={`${props.alt} / #${index}`}/>
            </div>
        ))}
    </div>
}

export function ProductImageStateOverlay(props: { verification: IShophunterImageData[] }) {

    let statuses: string[] = [];
    let pendingStatuses: string[] = [];

    for (let i = 0; i < props.verification.length; i++) {
        const verification = props.verification[i];
        switch (verification.verificationType) {
            case VERIFICATION_VERIFICATION_TYPE.STATUS:
                if (verification.value.imageStatus) {
                    statuses.push(translateProductImageType(verification.value.imageStatus))
                }
                if (verification.pending.imageStatus) {
                    pendingStatuses.push(translateProductImageType(verification.pending.imageStatus))
                }
                break;
            case VERIFICATION_VERIFICATION_TYPE.TYPE:
                if (verification.value.imageType) {
                    statuses.push(translateImageType(verification.value.imageType))
                }
                if (verification.pending.imageType) {
                    pendingStatuses.push(translateImageType(verification.pending.imageType))
                }
                break;
            case VERIFICATION_VERIFICATION_TYPE.CONTENT_TYPE:
                if (verification.value.imageContentType) {
                    statuses.push(translateContentImageType(verification.value.imageContentType))
                }
                if (verification.pending.imageContentType) {
                    pendingStatuses.push(translateContentImageType(verification.pending.imageContentType))
                }
                break;
            case VERIFICATION_VERIFICATION_TYPE.WATERMARK:
                if (verification.value.imageWatermark) {
                    statuses.push(translateImageWatermarkType(verification.value.imageWatermark))
                }
                if (verification.pending.imageWatermark) {
                    pendingStatuses.push(translateImageWatermarkType(verification.pending.imageWatermark))
                }
                break;
            case VERIFICATION_VERIFICATION_TYPE.OVERLAY:
                if (verification.value.imageOverlay) {
                    statuses.push(translateImageOverlayType(verification.value.imageOverlay))
                }
                if (verification.pending.imageOverlay) {
                    pendingStatuses.push(translateImageOverlayType(verification.pending.imageOverlay))
                }
                break;
            case VERIFICATION_VERIFICATION_TYPE.VARIANTS:
                // unused
                break;
        }
    }

    for (let i = 0; i < pendingStatuses.length; i++) {
        if (statuses.includes(pendingStatuses[i])) {
            statuses = statuses.filter(a => a != pendingStatuses[i])
        }
    }

    if (statuses.length > 0 || pendingStatuses.length > 0) {
        return <div className={`absolute top-0 left-0 flex`}>
            {pendingStatuses.map((s, index) => (
                <div key={index} className={`opacity-50 bg-green-400 px-0.5 mr-0.5 text-xs text-white`}>{s}</div>))}
            {statuses.map((s, index) => (
                <div key={index} className={`bg-green-400 px-0.5 mr-0.5 text-xs text-white`}>{s}</div>))}
        </div>
    }
    return null;
}

export function ProductOfferImage(props: {
    imageId: number,
    alt: string,
    imageClass?: string
}) {
    return <img className={`w-image-width-offer h-image-height-offer object-scale-down`}
                src={getImageUrl(props.imageId, "xs")}
                alt={props.alt}
                onError={productImageOnErrorHandler}/>
}