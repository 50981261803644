import React from "react";
import {FourProductImages, ProductImage} from "../../App/Common/Image";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import SearchSilentRouteParamLink from "../../App/Links/SearchSilentRouteParamLink";
import {Filters} from "../../ProductList/Filters";

type ShowMoreSecondaryProps = {
    count: number,
    title: string,
    exampleImages: { image_id: number }[],
    urlParams: IUrlResolvedParams,
    filters: IProductListSearch_Filter[],

    className?: string,
};

const ComponentTranslationKey = "ShowMore"

/** @see ShowMoreSecondaryCardDemo */
export default function ShowMoreSecondaryCard(props: ShowMoreSecondaryProps) {
    // globals
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    return (
        <SearchSilentRouteParamLink
            urlPageParams={props.urlParams}
            className={`category-item-card category-item-card-secondary category-item-card-secondary-round ${props.className}`}>

            <div className="p-2 card-section-rigid">
                <div className="card-section-image">
                    {props.exampleImages.length == 1
                        ? <ProductImage imageId={props.exampleImages[0].image_id}
                                        alt={props.title}
                                        size="m"
                                        imageClass={`image-aspect-ratio-square mix-blend-darken`}/>
                        : <FourProductImages imageIds={props.exampleImages.map((i) => i.image_id)}
                                             alt={props.title}
                                             size="m" squareSize={true}
                                             imageSize="s" imageClass="image-aspect-ratio-square mix-blend-darken"/>
                    }
                </div>
            </div>

            <div className="card-section-stretch flex flex-col justify-between">
                <div className="px-4 flex-none text-gray-500 mt-[10px] text-center">
                    {getTranslation("show_additional")}
                    {' '}{props.count}{' '}
                    {getTranslation("products")}{' '}
                    {getTranslation("from")}
                    <div>{props.title}</div>

                    {/* issue #38060 - ship filters on product list. this card only on product list */}
                    {/*{props.filters.length*/}
                    {/*    ? <div><Filters filters={props.filters}/></div>*/}
                    {/*    : null}*/}
                </div>
                <div className="px-4 flex-none text-gray-500 pb-4">
                </div>
            </div>

        </SearchSilentRouteParamLink>
    );
}
