const toFixedFix = (n: number, prec: number) => {
    const k = Math.pow(10, prec);
    return '' + (Math.round(n * k) / k).toFixed(prec)
}

export const number_format = (number: number | string, decimals: number, dec_point?: string, thousands_sep?: string): string => {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '')

    const n = !isFinite(+number) ? 0 : +number;
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    const sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep;
    const dec = typeof dec_point === 'undefined' ? '.' : dec_point;

    const s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')

    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }

    if ((s[1] || '').length < prec) {
        s[1] = s[1] || ''
        s[1] += new Array(prec - s[1].length + 1).join('0')
    }

    return s.join(dec)
}

export const formatNumber = (
    number: number | string,
    decimals: number = 2,
    locale: string = 'sv',
    decSeparator: string | undefined = undefined,
    thousandsSeparator: string | undefined = undefined) => (

    locale === 'sv'
        ? number_format(number, decimals, decSeparator ? decSeparator : ',', thousandsSeparator ? thousandsSeparator : ' ')
        : number_format(number, decimals, decSeparator ? decSeparator : '.', thousandsSeparator ? thousandsSeparator : ',')
)

export const formatNumberCustomDecimals = (
    number: number | string,
    locale: string = 'sv',
    decSeparator: string | undefined = undefined,
    thousandsSeparator: string | undefined = undefined) => {

    let ret = formatNumber(number, 6, locale, decSeparator, thousandsSeparator);
    let retLen = ret.length;

    if (retLen > 0) {
        for (let i = 0; i < 6; i++) {
            if (retLen > 0 && (ret[retLen - 1] == '0')) {
                ret = ret.substring(0, retLen - 1)
                retLen--
            }
        }

        if (retLen > 0 && (ret[retLen - 1] == ',' || ret[retLen - 1] == '.')) {
            ret = ret.substring(0, retLen - 1)
        }
    }

    return ret
}