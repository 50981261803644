import React from "react";

type VendorSeparatorProps = {
    name: string
};

export default function VendorSeparator(props: VendorSeparatorProps) {
    return (
        <div
            className="category-item-separator flex flex-col">
            <div className="whitespace-nowrap card-separator-text-rotation flex-none flex justify-center">
                <div className="inline-block">
                    {props.name}
                </div>
            </div>
        </div>
    );
}
