import {call, put, select, takeEvery} from 'redux-saga/effects'
import axios, {AxiosResponse} from "axios";
import {handleShopitApi, handleShopitApiRequestError} from "./helper";

import {
    ProductStateAction,
    SET_PRODUCT_STATE,
    triggerProductStatePending,
    triggerProductStateSuccess
} from "../Action/session.product";

import {
    getDefaultLang,
    InteractiveDelayType,
    triggerDelayedApiResponse, triggerError,
    triggerMessage,
    triggerRecoverySendFlag
} from "../Action/interactive";

import {
    RegistrationConfirmAction,
    RestoreApplyAction,
    SESSION_SET_USER_SETTINGS,
    triggerResponseUserLogin,
    triggerUserLogoutResponse,
    SESSION_USER_LOGIN_RECOVER,
    SESSION_USER_LOGIN_RECOVER_ACCEPT,
    SESSION_USER_LOGIN_RECOVER_CHECK,
    SESSION_USER_LOGIN_RECOVER_DECLINE,
    SESSION_USER_LOGIN_SHOPIT,
    SESSION_USER_LOGOUT,
    SESSION_USER_REGISTER_CONFIRM,
    UserLoginRecoverAction,
    UserLoginShopitAction,
    UserSettingsAction, RegisterAction, SESSION_USER_REGISTER, SESSION_CONTACT, ContactAction
} from "../Action/session.login";
import {i18n} from "../../Localization/i18n";
import {LOAD_USER, triggerLoadUserSuccess, UserAction} from "../Action/cache.user";
import {triggerConfigLanguageSet} from "../Action/config.localization";
import {getAxiosApiConfigs, serializePostParams} from "../../Helpers/ApiHelper";

function* loadUser(action: UserAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axios.post,
        `/api/user/data`,
        serializePostParams<IApiUserDataRequest>({
            userId: action.userId.toString(),
            imageIds: null,
            productIds: null,

            _logPageToken: logToken || null,
        }),
        getAxiosApiConfigs(undefined, "US_USER_TIMEOUT, notify developers"));

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiUserDataResponse>(res)
        if (response) {
            yield put(triggerLoadUserSuccess(response.login.user));
        }
    }
}

function* doLoginShopit(action: UserLoginShopitAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axios.post,
        `/api/user/login/shopit`,
        serializePostParams<IApiUserLoginShopitRequest>({
            email: action.email,
            password: action.password,

            _logPageToken: logToken || null,
        }),
        getAxiosApiConfigs(undefined, "US_LOGIN_SHOPIT_TIMEOUT, notify developers"))

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        const isCritical: boolean = ("code" in (error as any)) && (error as any).code == 'ECONNABORTED';
        if (isDebug || isCritical) {
            yield* handleShopitApiRequestError(error, action.messageCallback)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiUserLoginShopitResponse>(res, action.messageCallback)
        if (response) {
            yield put(triggerResponseUserLogin(response.login.userSession, response.login.user));
            yield put(triggerConfigLanguageSet(response.login.user.localization.language));
        }
    }
}

function* doLogout() {

    const req = call(
        axios.post,
        `/api/user/logout`,
        undefined,
        getAxiosApiConfigs(undefined, "US_LOGOUT_TIMEOUT, notify developers"))

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        const isCritical: boolean = ("code" in (error as any)) && (error as any).code == 'ECONNABORTED';
        if (isDebug || isCritical) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiUserLogoutResponse>(res)
        if (response) {
            yield put(triggerUserLogoutResponse());
            yield put(triggerConfigLanguageSet(getDefaultLang()));
        }
    }
}

function* doLoginRecover({email}: UserLoginRecoverAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axios.post,
        `/api/user/recovery`,
        serializePostParams<IApiUserRecoveryRequest>({
            email: email,

            _logPageToken: logToken || null,
        }),
        getAxiosApiConfigs(undefined, "US_RECOVERY_TIMEOUT, notify developers"))

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        const isCritical: boolean = ("code" in (error as any)) && (error as any).code == 'ECONNABORTED';
        if (isDebug || isCritical) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiUserRecoveryResponse>(res)
        if (response) {
            yield put(triggerRecoverySendFlag(response.emailSend))

            if (response.mailerError) {
                yield put(triggerMessage('LOG_1', 'Mailer error, contact developers team'));
            }
        }
    }
}

function* doLoginRecoverCheck({userId, code}: RegistrationConfirmAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axios.post,
        `/api/user/recovery/check`,
        serializePostParams<IApiUserRecoveryCheckRequest>({
            userId: userId,
            code: code,

            _logPageToken: logToken || null,
        }),
        getAxiosApiConfigs(undefined, "US_RECOVERY_CHECK_TIMEOUT, notify developers"))

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiUserRecoveryCheckResponse>(res)
        if (response) {
            if (response.success) {
                yield put(triggerDelayedApiResponse(InteractiveDelayType.RecoverCheck, {
                    message: 'Recover link is valid. Click here for enter new password.',
                    redirect: '/recover/accept/?u=' + userId + '&c=' + code,
                }))
            } else {
                yield put(triggerDelayedApiResponse(InteractiveDelayType.RecoverCheck, {
                    message: 'No valid recover link',
                    redirect: undefined,
                }))
            }
        }
    }
}

function* doLoginRecoverAccept({userId, code, password1, password2}: RestoreApplyAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axios.post,
        `/api/user/recovery/accept`,
        serializePostParams<IApiUserRecoverApplyRequest>({
            userId: userId,
            code: code,
            password1: password1,
            password2: password2,

            _logPageToken: logToken || null,
        }),
        getAxiosApiConfigs(undefined, "US_RECOVERY_ACCEPT_TIMEOUT, notify developers"))

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiUserRecoverApplyResponse>(res)
        if (response) {
            yield put(triggerResponseUserLogin(response.login.userSession, response.login.user));
            yield put(triggerMessage('0', 'Password Set'));
            yield put(triggerConfigLanguageSet(response.login.user.localization.language));
            // todo:
            // yield put(triggerRedirect('/'));
        }
    }
}

function* doLoginRecoverDecline({userId, code}: RegistrationConfirmAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axios.post,
        `/api/user/recovery/decline`,
        serializePostParams<IApiUserRecoveryDeclineRequest>({
            userId: userId,
            code: code,

            _logPageToken: logToken || null,
        }),
        getAxiosApiConfigs(undefined, "US_RECOVERY_DECLINE_TIMEOUT, notify developers"))

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiUserRecoveryDeclineResponse>(res)
        if (response) {
            if (response.feedback_message) {
                yield put(triggerDelayedApiResponse(InteractiveDelayType.RecoverDecline, {
                    message: response.feedback_message,
                    redirect: undefined
                }))
            }
        }
    }
}

function* doLoginRegister(action: RegisterAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axios.post,
        `/api/user/register`,
        serializePostParams<IApiUserRegisterRequest>({
            firstName: action.firstName,
            lastName: action.lastName,
            email: action.email,
            email2: action.email2,
            password: action.password,

            _logPageToken: logToken || null,
        }),
        getAxiosApiConfigs(undefined, "US_REGISTER_TIMEOUT, notify developers"))

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiUserRegisterResponse>(res)
        if (response) {

            yield put(triggerResponseUserLogin(response.login.userSession, response.login.user));
            yield put(triggerConfigLanguageSet(response.login.user.localization.language));

            yield put(triggerMessage('REG_0', 'Registration success. Verify your email.'));

            if (response.mailerError) {
                yield put(triggerMessage('REG_1', 'Mailer error, contact developers team'));
            }
        }
    }
}

function* doLoginRegisterConfirm({userId, code}: RegistrationConfirmAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axios.post,
        `/api/user/register/confirm`,
        serializePostParams<IApiUserRegisterConfirmRequest>({
            userId: userId,
            code: code,

            _logPageToken: logToken || null
        }),
        getAxiosApiConfigs(undefined, "US_REGISTER_CONFIRM_TIMEOUT, notify developers"))

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiUserRegisterConfirmResponse>(res)
        if (response) {
            if (response.feedback_message) {
                yield put(triggerDelayedApiResponse(InteractiveDelayType.RegisterConfirm, {
                    message: response.feedback_message,
                    redirect: undefined
                }))
            }
        }
    }
}

function* doProductState({productId, state, oldState}: ProductStateAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axios.post,
        `/api/user/product`,
        serializePostParams<IApiUserProductRequest>({
            productId: productId,
            state: state,

            _logPageToken: logToken || null
        }),
        getAxiosApiConfigs(undefined, "US_PRODUCT_STATE_TIMEOUT, notify developers"));

    yield put(triggerProductStatePending(productId))

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiUserProductResponse>(res)
        if (response) {
            if (response.success) {
                yield put(triggerProductStateSuccess(
                    productId,
                    state == "like",
                    oldState == "like",
                    state == "own",
                    oldState == "own"));
            }
        }
    }
}

const cleanupObject = function (obj: any) {
    var p;
    var filter: any = {};
    for (p in obj) {
        if (obj.hasOwnProperty(p) && obj[p] !== undefined) {
            filter[p] = obj[p];
        }
    }
    return filter;
}

function* doSettings({params}: UserSettingsAction) {
    const req = call(
        axios.post,
        `/api/user/settings`,
        serializePostParams<IApiUserSettingsRequest>(cleanupObject(params)),
        getAxiosApiConfigs(undefined, "US_USER_SETTINGS_TIMEOUT, notify developers"));

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiUserSettingsResponse>(res)
        if (response) {
            if (response.success) {

                yield put(triggerResponseUserLogin(response.login.userSession, response.login.user));
                yield put(triggerConfigLanguageSet(response.login.user.localization.language));

                i18n.changeLanguage(response.login.user.localization.language)

                // yield put(triggerMessage('0', 'Update success'));

            } else {
                yield put(triggerError('0', 'Error', 'Update Failed'));
                // not ok
            }
        }
    }
}

function* doContact(action: ContactAction){
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axios.post,
        `/api/contact`,
        serializePostParams<IApiContactRequest>({
            name: action.name,
            email: action.email,
            message: action.message,

            _logPageToken: logToken || null
        }),
        getAxiosApiConfigs(undefined, "US_CONTACT_TIMEOUT, notify developers"));

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiContactResponse>(res)
        if (response) {
            if (response.success) {
                console.log(response)
                // nothing
            } else {
                // nothing too
            }
        }
    }
}

export function* watchUserAction() {
    yield takeEvery(LOAD_USER, loadUser);
    yield takeEvery(SESSION_USER_LOGIN_SHOPIT, doLoginShopit);
    yield takeEvery(SESSION_USER_LOGIN_RECOVER, doLoginRecover);
    yield takeEvery(SESSION_USER_LOGIN_RECOVER_CHECK, doLoginRecoverCheck);
    yield takeEvery(SESSION_USER_LOGIN_RECOVER_ACCEPT, doLoginRecoverAccept);
    yield takeEvery(SESSION_USER_LOGIN_RECOVER_DECLINE, doLoginRecoverDecline);
    yield takeEvery(SESSION_USER_REGISTER, doLoginRegister);
    yield takeEvery(SESSION_USER_REGISTER_CONFIRM, doLoginRegisterConfirm);
    yield takeEvery(SESSION_SET_USER_SETTINGS, doSettings);
    yield takeEvery(SESSION_USER_LOGOUT, doLogout);
    yield takeEvery(SET_PRODUCT_STATE, doProductState);

    yield takeEvery(SESSION_CONTACT, doContact);
}

