import React, {CSSProperties, Key, useContext, useState} from "react";
import Slider from "./Slider";
import FilterOptions from "../FilterOptions";
import {
    convertSearchParamsToUrlResolveParams,
    toggleStaticNumericFilter,
    toggleStaticPriceFilter
} from "../../../Helpers/SearchHelper";
import {triggerCacheAccessSearchWithCallback} from "../../../Store/Action/cache.search";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {selectSearchCache} from "../../../Store/StoreHelper";
import {getDefaultLang, triggerDebug} from "../../../Store/Action/interactive";
import {SearchRouteContext} from "../../../Store/Context/SearchRouteContext";
import {formatNumber, formatNumberCustomDecimals} from "../../../Helpers/StringFormatter.Number";
import {buildSearchUrl} from "../../../Helpers/UrlFormatter.Search";

export default function FilterNumericProps(props: {
    numeric: IFilterPanel_Numeric,
    level: number,
    demonstrationMode: boolean
}) {

    // globals
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const searchContext = useContext(SearchRouteContext);

    const urlContext = useSelector((state: IAppState) => state.urlContext)

    // internal state
    const [sliderState, changeSliderState] = useState((props.numeric && props.numeric.slider) ? {
        selectedMin: props.numeric.slider.selectedMin,
        selectedMax: props.numeric.slider.selectedMax,
    } : undefined)

    // extract global state
    const searchData = useSelector((state: IAppState) => selectSearchCache(state, searchContext.cacheKey))
    const searchQuery = searchData && searchData.object ? searchData.object.searchQuery : undefined
    const isDebugEnabled = useSelector((state: IAppState) => state.config.debug)

    // internal functions
    const onChange = (values: number | number[]) => {
        if (Array.isArray(values)) {
            const sliderModify = {
                selectedMin: values[0],
                selectedMax: values[1]
            }
            changeSliderState(sliderModify)
        }
    }

    const onAfterChange = (values: number | number[]) => {
        if (props.demonstrationMode)
            return;

        if (Array.isArray(values)) {

            const dataStart = props.numeric?.slider?.dataStart;
            const dataEnd = props.numeric?.slider?.dataEnd;
            const steps = props.numeric?.slider?.steps;

            if (dataStart !== undefined && dataEnd !== undefined && steps) {
                const low = steps[values[0]].value > steps[dataStart].value ? steps[values[0]].value : undefined
                const high = steps[values[1]].value < steps[dataEnd].value ? steps[values[1]].value : undefined

                const currentFilters: IProductListSearch_Filter[] = searchQuery && searchQuery.filter ? [...searchQuery.filter] : [];

                const type = props.numeric?.type;
                const nextFilters = type === IFilterPanel_ObjectType.NumericPriceFixed || type === IFilterPanel_ObjectType.NumericPrice
                    ? toggleStaticPriceFilter(currentFilters, IProductListSearch_FilterType.Price, low, high)
                    : toggleStaticNumericFilter(currentFilters, IProductListSearch_FilterType.Numeric, props.numeric?.id, low, high)

                const urlPageParams = convertSearchParamsToUrlResolveParams({
                    ...searchQuery,
                    filter: nextFilters,
                })

                dispatch(triggerCacheAccessSearchWithCallback(urlPageParams, false, isDebugEnabled, () => {
                        const [toPathName, toSearch, isValidLink] = buildSearchUrl(urlPageParams, urlContext);
                        if (!isValidLink) {
                            console.log("Search link error");
                            console.log(toPathName, toSearch, urlPageParams, urlContext)
                            const trace = new Error().stack
                            console.log(trace)
                            dispatch(triggerDebug("Search link error (console)", trace))
                        }

                        navigate({
                            pathname: toPathName,
                            search: toSearch
                        })
                    }
                ))

            }
        }

    }

    const renderLabels = () => {
        if (props.numeric && sliderState) {
            const slider = props.numeric.slider;

            if (slider && slider.steps) {
                const decimals = props.numeric.decimals;

                let min: string | number = '';
                let max: string | number = '';

                if (typeof slider.steps[sliderState.selectedMin] !== 'undefined') {
                    min = decimals > 0
                        ? formatNumber(slider.steps[sliderState.selectedMin].value, decimals, 'sv', undefined, ' ')         // using thin space symbol here and below
                        : formatNumber(slider.steps[sliderState.selectedMin].value, 0, 'sv', undefined, ' ')
                }
                if (typeof slider.steps[sliderState.selectedMax] !== 'undefined') {
                    max = decimals > 0
                        ? formatNumber(slider.steps[sliderState.selectedMax].value, decimals, 'sv', undefined, ' ')
                        : formatNumber(slider.steps[sliderState.selectedMax].value, 0, 'sv', undefined, ' ')
                }

                return <div className="filter-panel-numeric-label-min-max">
                    {(min ?
                        <div
                            className="filter-panel-numeric-label-min">{min}</div> :
                        <div></div>)}
                    {(max ?
                        <div
                            className="filter-panel-numeric-label-max">{max}</div> :
                        <div></div>)}
                </div>;
            }
        }

        return null;
    }

    const renderHistogram = () => {
        if (props.numeric) {
            const slider = props.numeric.slider;
            const selectedMin = sliderState?.selectedMin;
            const selectedMax = sliderState?.selectedMax;

            if (slider && slider.histograms && selectedMin !== undefined && selectedMax !== undefined) {
                if (slider.histograms.length <= 0) return null;

                let highestPrimary: number | null = null
                let highestSecondary: number | null = null

                for (let i = 0; i < slider.histograms.length; i++) {
                    const primary = slider.histograms[i].primary;
                    const secondary = slider.histograms[i].secondary;

                    if (primary) {
                        if (highestPrimary === null || primary > highestPrimary)
                            highestPrimary = primary
                    }

                    if (secondary) {
                        if (highestSecondary === null || secondary > highestSecondary)
                            highestSecondary = secondary
                    }
                }

                if (highestSecondary === null && highestPrimary === null) return null

                if (highestPrimary === null) highestPrimary = 0;
                if (highestSecondary === null) highestSecondary = 0;

                const percentSecondary = highestSecondary / 100
                const percentPrimary = highestPrimary / 100
                const decimals = typeof props.numeric.decimals !== 'undefined' ? props.numeric.decimals : 0
                const isFixed = props.numeric.isFixed

                return slider.histograms
                    .filter((i: IFilterPanel_Numeric_SliderHistogram) => i.primary || i.secondary)
                    .map((i: IFilterPanel_Numeric_SliderHistogram, key: Key) => {
                        const result: JSX.Element[] = []

                        const getOpacity = function (coverage: number[], range: number[]) {
                            const coveragePercent = (coverage.filter(c => c >= range[0] && c <= range[1]).length / coverage.length)
                            if (coveragePercent === 1) {
                                return 1;
                            }
                            if (coveragePercent > 0.80) {
                                return 0.25 + (0.5 * coveragePercent)
                            } else if (coveragePercent > 0.60) {
                                return 0.25 + (0.45 * coveragePercent)
                            } else if (coveragePercent > 0.40) {
                                return 0.25 + (0.40 * coveragePercent)
                            } else if (coveragePercent > 0.20) {
                                return 0.25 + (0.35 * coveragePercent)
                            }
                            return 0.25 + (0.30 * coveragePercent)
                        }

                        const opacity = getOpacity(i.coverage, [selectedMin, selectedMax])

                        if (i.secondary) {
                            const barHeight = i.secondary / percentSecondary;
                            const barStart = (100 - barHeight).toString();

                            let width = i.width * 100
                            let offset = i.offset * 100
                            if (isFixed) {
                                if (width > 2) {
                                    width *= 0.8
                                }
                                if (width > 10) {
                                    width = 10
                                }
                                if (width > 1) {
                                    offset -= width / 2
                                }
                            }

                            const position: CSSProperties = {
                                top: barStart + "%",
                                left: offset + "%",
                                width: width + "%",
                                height: barHeight + "%",
                                opacity: opacity,
                            }
                            result.push(
                                <div className="filter-panel-numeric-histogram-primary-bar"
                                     key={'ss' + key}
                                     style={position}>
                                    <div className="filter-panel-numeric-histogram-primary-bar-secondary-value"></div>
                                </div>)
                        }

                        if (i.primary) {
                            const barHeight = i.primary / percentPrimary;
                            const barStart = (100 - barHeight).toString();

                            let width = i.width * 100
                            let offset = i.offset * 100
                            if (isFixed) {
                                if (width > 2) {
                                    width *= 0.8
                                }
                                if (width > 10) {
                                    width = 10
                                }
                                if (width > 1) {
                                    offset -= width / 2
                                }
                            }

                            const position: CSSProperties = {
                                top: barStart + "%",
                                left: offset + "%",
                                width: width + "%",
                                height: barHeight + "%",
                                opacity: opacity,
                            }
                            result.push(
                                <div className="filter-panel-numeric-histogram-primary-bar"
                                     key={'sp' + key}
                                     style={position}>
                                    <div className="filter-panel-numeric-histogram-primary-bar-value"></div>
                                </div>)
                        }

                        return result;
                    })
            }
        }

        return null;
    }

    const renderTicks = () => {
        if (props.numeric && props.numeric.slider) {
            const ticks = props.numeric.slider.ticks;
            if (ticks) {
                return <div className="filter-panel-numeric-ticks">
                    {ticks.map((i: IFilterPanel_Numeric_SliderTick, key: Key) => {
                        return <div className="filter-panel-numeric-tick-wrapper" key={'k' + key}>
                            <div>|</div>
                            <div className="filter-panel-numeric-tick-value">
                                {formatNumberCustomDecimals(i.valuePresentation, 'sv', undefined, ' ')}
                            </div>
                        </div>
                    })}
                </div>
            }
        }
        return null;
    }

    const calcOffset = (index: number) => {
        if (props.numeric && props.numeric.slider) {
            if (typeof props.numeric.slider.steps[index] !== 'undefined') {
                return props.numeric.slider.steps[index].offset;
            }
        }
        return 0;
    }

    const renderBars = () => {
        if (props.numeric && props.numeric && sliderState) {
            const slider = props.numeric.slider;
            if (slider) {
                const dataStart = slider.dataStart;
                const dataEnd = slider.dataEnd;
                const selectedMin = sliderState.selectedMin;
                const selectedMax = sliderState.selectedMax;

                const result: React.ReactElement[] = []
                result.push(<div className="filter-panel-numeric-active-bar" key="bar"
                                 style={{
                                     top: "0%",
                                     left: (calcOffset(dataStart) * 100) + "%",
                                     width: ((calcOffset(dataEnd) - calcOffset(dataStart)) * 100) + "%",
                                     height: "100%",
                                 }}></div>);

                if (dataStart < selectedMin) {
                    result.push(<div className="filter-panel-numeric-exclude-bar" key="preBar"
                                     style={{
                                         top: "0%",
                                         left: (calcOffset(dataStart) * 100) + "%",
                                         width: ((calcOffset(selectedMin) - calcOffset(dataStart)) * 100) + "%",
                                         height: "100%",
                                     }}></div>);
                }
                if (dataEnd > selectedMax) {
                    result.push(<div className="filter-panel-numeric-exclude-bar" key="postBar"
                                     style={{
                                         top: "0%",
                                         left: (calcOffset(selectedMax) * 100) + "%",
                                         width: ((calcOffset(dataEnd) - calcOffset(selectedMax)) * 100) + "%",
                                         height: "100%",
                                     }}></div>);
                }

                return result;
            }
        }
        return null;
    }

    // render
    const numeric = props.numeric
    if (!numeric) return null

    const hasSlider = numeric.slider && numeric.slider.ticks && numeric.slider.ticks.length > 0
    const hasValues = numeric.filterValues && numeric.filterValues.length > 0

    if (!hasSlider && !hasValues) {
        return null
    }

    const level = props.level

    const html: React.ReactElement[] = []
    html.push(
        <div key={`header`} className={`filter-panel-numeric-header level-` + level}>
            {numeric.name}{isDebugEnabled ? numeric.nameDebug : null}{numeric.unit ? ` (${numeric.unit})` : ``}
        </div>
    )
    if (hasSlider) {
        html.push(
            <div key={`slider`} className={`filter-panel-numeric-content level-` + level}>

                {renderLabels()}

                <div className="filter-panel-numeric-histogram-wrapper">
                    <div className="filter-panel-numeric-histogram">
                        {renderHistogram()}
                    </div>
                </div>

                <div className="filter-panel-numeric-histogram-bars-wrapper">
                    <div className="filter-panel-numeric-histogram-bars">
                        {renderBars()}
                    </div>

                    <div className="filter-panel-numeric-slider">
                        {(
                            numeric.slider ?
                                <Slider original={false}
                                        min={0}
                                        max={numeric.slider.steps.length - 1}
                                        minDistance={numeric.isFixed ? 0 : 1}
                                        steps={numeric.slider.steps}
                                        defaultValue={[numeric.slider.selectedMin, numeric.slider.selectedMax]}
                                        onChange={onChange}
                                        onAfterChange={onAfterChange}/> : null
                        )}
                    </div>
                </div>

                <div className="filter-panel-numeric-ticks-wrapper">
                    {renderTicks()}
                </div>
            </div>
        )
    }
    if (hasValues) {
        html.push(
            <FilterOptions key={`options`} attribute={numeric}
                           level={level + 1}
                           demonstrationMode={props.demonstrationMode}/>
        )
    }

    return <div className={`filter-panel-attribute-spacing`}>{html}</div>
}