import React from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";
import {getSearchApiFromPageParams} from "../../Helpers/SearchHelper";
import {getDefaultLang} from "../../Store/Action/interactive";
import {getSearchCacheKey} from "../../Store/Action/cache.search";
import {selectSearchCache} from "../../Store/StoreHelper";
import {getDebugCategoryUrl, getDebugProductToolUrl} from "../../Helpers/BackendHelper";
import {CategoryPath} from "../../Components/ProductList/CategoryPath";
import {formatNumber} from "../../Helpers/StringFormatter.Number";
import {formatTimeToNow} from "../../Helpers/StringFormatter.Time";

/** @type TranslationKeys, keep this variable without TS type, for keep type check magic works*/
const ComponentTranslationKey = "TitleBar"

export default function SearchBreadcrumbs(props: {
    urlPageParams: IUrlResolvedParams
}) {

    // globals
    const {t} = useTranslation(ComponentTranslationKey)
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key)
    }

    const propsExtend = useSelector((state: IAppState) => {
        const urlPageParams = props.urlPageParams;
        const urlRequestKey = getSearchApiFromPageParams(urlPageParams);

        const searchKey = getSearchCacheKey(
            urlRequestKey,
            state.config.lang ? state.config.lang : getDefaultLang(),
            state.config.debug)

        const searchData = selectSearchCache(state, searchKey);

        let title: IProductList_Title | undefined = undefined
        if (searchData && searchData.object) {
            if (!searchData.object.uniqueNamePathRelatedCategories || searchData.object.uniqueNamePathRelatedCategories.length == 0) {
                title = searchData.object.items.title
            }
        }

        return {
            responseDetails: searchData && searchData.object ? searchData.object.responseDetails : undefined,
            title: title,
            urlPageParams: urlPageParams,

            visibleFilters: state.interactive.showFilters,
            visibleCategories: state.interactive.showCategories,

            lang: state.config.lang ? state.config.lang : getDefaultLang(),
            isDebugEnabled: state.config.debug,
        };
    })

    const category = propsExtend.title?.details.category;

    return (
        <div>
            <div className="mt-8 flex justify-between pb-4">

                {propsExtend.title?.titleType == IProductList_TitleType.Category
                    ? <div className="">

                        {category ? <h2 className={`inline-block`}><CategoryPath category={category} uncategorized={false}/></h2> : null}
                        {propsExtend.isDebugEnabled
                            ? <a href={getDebugProductToolUrl(
                                propsExtend.urlPageParams,
                                propsExtend.urlPageParams.bridgeParams.group,
                                propsExtend.urlPageParams.bridgeParams.sort,
                                propsExtend.urlPageParams.bridgeParams.categoryId,
                                undefined,
                                propsExtend.urlPageParams.bridgeParams.text,
                                propsExtend.urlPageParams.bridgeParams.fold,
                                undefined,
                                undefined,
                                undefined,
                                propsExtend.urlPageParams.bridgeParams.expand,
                                propsExtend.urlPageParams.bridgeParams.page,
                                'active')
                            } target='backend' className="backend-btn cursor-pointer ml-2">CAT+FILTER</a>
                            : null}
                        {propsExtend.isDebugEnabled && category
                            ? <a href={getDebugCategoryUrl(category.id)} target='backend'
                                 className="backend-btn cursor-pointer ml-2">CAT TOOL</a>
                            : null}
                    </div> : null
                }

                {propsExtend.title?.titleType == IProductList_TitleType.Vendor
                    ? <div className="text-xl font-bold text-shopit-green">
                        {getTranslation("vendor")}{' \''}{propsExtend.title.name}{'\' '}{getTranslation("has_products_in_category")}
                    </div>
                    : null
                }

                {propsExtend.title?.titleType == IProductList_TitleType.Brand
                    ? <div className="text-xl font-bold text-shopit-green">
                        {getTranslation("branding")}{' \''}{propsExtend.title.name}{'\' '}{getTranslation("has_products_in_category")}
                    </div>
                    : null
                }

                {propsExtend.title?.titleType == IProductList_TitleType.Manufacturer
                    ? <div className="text-xl font-bold text-shopit-green">
                        {getTranslation("manufacturer")}{' \''}{propsExtend.title.name}{'\' '}{getTranslation("has_products_in_category")}
                    </div>
                    : null
                }

                {propsExtend.title?.titleType == IProductList_TitleType.Text
                    ? <div className="text-xl font-bold text-shopit-green">
                        {getTranslation("folowing_categories_contains_products_with_text")}{' \''}{propsExtend.title.name}{'\''}
                    </div>
                    : null
                }

                {propsExtend.title?.titleType == IProductList_TitleType.Other && propsExtend.title.details.searchString
                    ? <div className="text-xl font-bold text-shopit-green">
                        {getTranslation("search_results_for")}{' \''}{propsExtend.title.details.searchString}{'\''}
                    </div>
                    : <div className="text-xl font-bold text-shopit-green"></div>
                }

                <div>
                    {propsExtend.responseDetails
                        ? <div className="text-gray-400 text-right -mb-2 pr-[5px] leading-3">
                            {propsExtend.isDebugEnabled
                                ? <div>
                                    {formatNumber(propsExtend.responseDetails.primaryProducts, 0)}{` `}{getTranslation("active")}
                                    {` `}({getTranslation("of")} {formatNumber(propsExtend.responseDetails.secondaryProducts, 0)} {getTranslation("results")})
                                </div>
                                : <div>
                                    {formatNumber(propsExtend.responseDetails.primaryProducts, 0)}{` `}{getTranslation("results")}
                                </div>
                            }
                            <div>
                                ({formatNumber(propsExtend.responseDetails.requestDuration || -1, 3, 'en')} {getTranslation("seconds")})
                                {propsExtend.isDebugEnabled ? ` ${formatTimeToNow(propsExtend.responseDetails.datafileCreateTime)}` : null}
                            </div>
                        </div>
                        : null}
                </div>
            </div>

        </div>
    );
}
