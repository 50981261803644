export const SHOPHUNTER_OPEN_VERIFY_PRODUCT = 'SHOPHUNTER_OPEN_VERIFY_PRODUCT';
export const SHOPHUNTER_CLOSE_VERIFY_PRODUCT = 'SHOPHUNTER_CLOSE_VERIFY_PRODUCT';

export const SET_PRODUCT_VERIFY_SAVE = "SET_PRODUCT_VERIFY_SAVE";
export const SET_PRODUCT_VERIFY_DELETE = "SET_PRODUCT_VERIFY_DELETE";

export const SET_PRODUCT_VERIFY_ADD_PRODUCT = "SET_PRODUCT_VERIFY_ADD_PRODUCT";
export const SET_PRODUCT_VERIFY_REMOVE_PRODUCT = "SET_PRODUCT_VERIFY_REMOVE_PRODUCT";
export const SET_PRODUCT_VERIFY_ADD_DATA = "SET_PRODUCT_VERIFY_ADD_DATA";

// ----

export type OpenVerifyProductAction = {
    type: string,
    product: IProduct
}

export const triggerOpenVerifyProduct = (product: IProduct): OpenVerifyProductAction => ({
    type: SHOPHUNTER_OPEN_VERIFY_PRODUCT,
    product
});

export type CloseVerifyProductAction = {
    type: string,
}

export const triggerCloseVerifyProduct = (): CloseVerifyProductAction => ({
    type: SHOPHUNTER_CLOSE_VERIFY_PRODUCT,
});

// ----

export type ProductVerifyAction = {
    type: string,
    userSessionId: string,
    productId: number,
    categoryId: number,
}

export const triggerProductVerifyCategorySave = (userSession: string, productId: number, categoryId: number): ProductVerifyAction => ({
    type: SET_PRODUCT_VERIFY_SAVE,
    userSessionId: userSession,
    productId,
    categoryId,
});

export const triggerProductVerifyCategoryDelete = (userSession: string, productId: number, categoryId: number): ProductVerifyAction => ({
    type: SET_PRODUCT_VERIFY_DELETE,
    userSessionId: userSession,
    productId,
    categoryId,
});

export type ProductVerifyAddAction = {
    type: string,
    userSessionId: string,
    productId: number,
    categoryId: number,
    noCategoryMatch: boolean,
    notRegenerated: boolean,
}

export const triggerProductVerifyAddProduct = (userSession: string, productId: number, categoryId: number, noCategoryMatch: boolean, notRegenerated: boolean): ProductVerifyAddAction => ({
    type: SET_PRODUCT_VERIFY_ADD_PRODUCT,
    userSessionId: userSession,
    productId,
    categoryId,
    noCategoryMatch,
    notRegenerated
});

export const triggerProductVerifyRemoveProduct = (userSession: string, productId: number, categoryId: number): ProductVerifyAction => ({
    type: SET_PRODUCT_VERIFY_REMOVE_PRODUCT,
    userSessionId: userSession,
    productId,
    categoryId,
});

export type ProductVerifyAddData = {
    type: string,
    shophunterData: IShophunterData,
}

export const triggerProductVerifyAddData = (shophunterData: IShophunterData): ProductVerifyAddData => ({
    type: SET_PRODUCT_VERIFY_ADD_DATA,
    shophunterData,
});
