import React from "react";

type FilterStaticProps = {
    static?: IFilterPanel_Static,
    demonstrationMode: boolean
} ;

export default function FilterStatic(props: FilterStaticProps) {
    const prop = props.static;
    if (!prop) return null;

    return (
        <div className="border-y p-2 bg-gray-100">
            {prop.type} [Stat Filter]
        </div>
    );
}
