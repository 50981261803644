import React from "react";
import {useSelector} from "react-redux";

import {getDefaultLang} from "../../../Store/Action/interactive";
import {
    DemonstrationGroup,
    DemonstrationMenu
} from "../../../Components/Typography/Demostration/Demo.DemonstrationGroup";
import {MetaTags} from "../../../Components/App/Tags/Meta";

// ---

export default function Typography(props: {}) {

    const propsExtend = useSelector((state: IAppState) => ({
        lang: state.config.lang ? state.config.lang : getDefaultLang(),
        isDebugEnabled: state.config.debug,
    }))

    return (
        <div>

            <MetaTags title={`Shopit`}
                      canonicalPageUrl={`default`}
                      robots={`noindex`}/>

            <DemonstrationMenu/>

            <DemonstrationGroup header={`Typography`}>
                <div className={`overflow-y-scroll h-96`}>
                    <table className={`example-table`}>
                        <tr className={`header-row`}>
                            <td className={`w-32`}>Class</td>
                            <td className={`w-64`}>Properties</td>
                            <td>Example</td>
                        </tr>

                        <tr>
                            <td>text-xs</td>
                            <td>
                                <div>font-size: 9px</div>
                                <div>line-height: 12px</div>
                            </td>
                            <td className={`text-xs`}>With over 88.000 structured and balanced Product Categories
                                the Shopit product taxonomy is multiple...
                            </td>
                        </tr>

                        <tr>
                            <td>text-sm</td>
                            <td>
                                <div>font-size: 10.5px</div>
                                <div>line-height: 15px</div>
                            </td>
                            <td className={`text-sm`}>With over 88.000 structured and balanced Product Categories
                                the Shopit product taxonomy is multiple...
                            </td>
                        </tr>

                        <tr>
                            <td>text-base</td>
                            <td>
                                <div> font-size: 12px</div>
                                <div>line-height: 18px</div>
                            </td>
                            <td className={`text-base`}>With over 88.000 structured and balanced Product Categories
                                the Shopit product taxonomy is multiple...
                            </td>
                        </tr>

                        <tr>
                            <td>text-lg</td>
                            <td>
                                <div> font-size: 13.5px</div>
                                <div> line-height: 21px</div>
                            </td>
                            <td className={`text-lg`}>With over 88.000 structured and balanced Product Categories
                                the Shopit product taxonomy is multiple...
                            </td>
                        </tr>

                        <tr>
                            <td>text-xl</td>
                            <td>
                                <div> font-size: 15px</div>
                                <div> line-height: 21px</div>
                            </td>
                            <td className={`text-xl`}>With over 88.000 structured and balanced Product Categories
                                the Shopit product taxonomy is multiple...
                            </td>
                        </tr>

                        <tr>
                            <td>text-2xl</td>
                            <td>
                                <div> font-size: 18px</div>
                                <div> line-height: 24px</div>
                            </td>
                            <td className={`text-2xl`}>With over 88.000 structured and balanced Product Categories
                                the Shopit product taxonomy is multiple...
                            </td>
                        </tr>

                        <tr>
                            <td>text-3xl</td>
                            <td>
                                <div> font-size: 22.5px</div>
                                <div> line-height: 27px</div>
                            </td>
                            <td className={`text-3xl`}>With over 88.000 structured and balanced Product Categories
                                the Shopit product taxonomy is multiple...
                            </td>
                        </tr>

                    </table>
                </div>
            </DemonstrationGroup>
            <DemonstrationGroup header={`Headings`}>
                <div className={`overflow-y-scroll h-96`}>
                    <table className={`example-table`}>
                        <tr className={`header-row`}>
                            <td className={`w-32`}>Tag</td>
                            <td className={`w-64`}>Usage</td>
                            <td>Example</td>
                        </tr>

                        <tr>
                            <td>{`h1`}</td>
                            <td>Product Title</td>
                            <td>
                                <h1>Category name</h1>
                            </td>
                        </tr>

                        <tr>
                            <td>{`h2`}</td>
                            <td>Product vendors/statistics headers</td>
                            <td>
                                <h2>Category name</h2>
                            </td>
                        </tr>

                        <tr>
                            <td>{`h3`}</td>
                            <td>Product manu+family+codes on top of description</td>
                            <td>
                                <h3>Category name</h3>
                            </td>
                        </tr>

                        <tr>
                            <td>{`h4`}</td>
                            <td></td>
                            <td>
                                <h4>Category name</h4>
                            </td>
                        </tr>

                        <tr>
                            <td>{`h5`}</td>
                            <td></td>
                            <td>
                                <h5>Category name</h5>
                            </td>
                        </tr>
                    </table>
                </div>
            </DemonstrationGroup>
            <DemonstrationGroup header={`Colors`}>
                <div className={``}>
                    <table className={`example-table`}>
                        <tr className={`header-row`}>
                            <td className={`w-12`}>Example</td>
                            <td className={`w-12`}></td>
                            <td className={`w-12`}></td>
                            <td className={`w-24`}>Hex</td>
                            <td className={`w-48`}>Name</td>
                            <td>Frequency</td>
                            <td>Usage</td>
                        </tr>

                        {[
                            {
                                color: 'shopit-green',
                                bg: 'bg-shopit-green',
                                border: 'border-shopit-green',
                                text: 'text-shopit-green',
                                hex: '#99c23b',
                                frequency: 'Often',
                                details: 'Thematic green. For buttons, links, many others'
                            },
                            {
                                color: 'shopit-green-light',
                                bg: 'bg-shopit-green-light',
                                border: 'border-shopit-green-light',
                                text: 'text-shopit-green-light',
                                hex: '#c9e38f',
                                frequency: 'Rare',
                                details: 'Background of selected variant on product page'
                            },
                            {
                                color: 'histogram-blue',
                                bg: 'bg-histogram-blue',
                                border: 'border-histogram-blue',
                                text: 'text-histogram-blue',
                                hex: '#4693b6',
                                frequency: 'Rare',
                                details: 'On numeric filter'
                            },
                            {
                                color: 'histogram-blue-light',
                                bg: 'bg-histogram-blue-light',
                                border: 'border-histogram-blue-light',
                                text: 'text-histogram-blue-light',
                                hex: '#4693B63F / 0.25 ',
                                frequency: 'Rare',
                                details: ''
                            },
                            {
                                color: 'histogram-bar-exclusion',
                                bg: 'bg-histogram-bar-exclusion',
                                border: 'border-histogram-bar-exclusion',
                                text: 'text-histogram-bar-exclusion',
                                hex: '#e2e2e2',
                                frequency: 'Rare',
                                details: ''
                            },
                            {
                                color: 'manufacturer-blue',
                                bg: 'bg-manufacturer-blue',
                                border: 'border-manufacturer-blue',
                                text: 'text-manufacturer-blue',
                                hex: '#5195e2',
                                frequency: 'Rare',
                                details: 'Manufacturer card text'
                            },
                            {
                                color: 'manufacturer-blue-light',
                                bg: 'bg-manufacturer-blue-light',
                                border: 'border-manufacturer-blue-light',
                                text: 'text-manufacturer-blue-light',
                                hex: '#b4cedc',
                                frequency: 'Rare',
                                details: 'Manufacturer card bg'
                            },
                            {
                                color: 'manufacturer-blue-light2',
                                bg: 'bg-manufacturer-blue-light2',
                                border: 'border-manufacturer-blue-light2',
                                text: 'text-manufacturer-blue-light2',
                                hex: '#b4cedc',
                                frequency: 'Rare',
                                details: 'Manufacturer card border'
                            },
                            {
                                color: '',
                                bg: 'bg-[#ff5cb5]',
                                border: 'border-[#ff5cb5]',
                                text: 'text-[#ff5cb5]',
                                hex: '#ff5cb5',
                                frequency: 'Common',
                                details: 'Product card badge'
                            },
                            {
                                color: '',
                                bg: 'bg-[#95c8ee]',
                                border: 'border-[#95c8ee]',
                                text: 'text-[#95c8ee]',
                                hex: '#95c8ee',
                                frequency: 'Common',
                                details: 'Product card badge'
                            },
                            {
                                color: '',
                                bg: 'bg-[#ff9c30]',
                                border: 'border-[#ff9c30]',
                                text: 'text-[#ff9c30]',
                                hex: '#ff9c30',
                                frequency: 'Common',
                                details: 'Product card badge'
                            },
                            {
                                color: 'branding-purple',
                                bg: 'bg-branding-purple',
                                border: 'border-branding-purple',
                                text: 'text-branding-purple',
                                hex: '#6573cd',
                                frequency: 'Rare',
                                details: ''
                            },
                            {
                                color: 'branding-purple-light',
                                bg: 'bg-branding-purple-light',
                                border: 'border-branding-purple-light',
                                text: 'text-branding-purple-light',
                                hex: '#e9ecf7',
                                frequency: 'Rare',
                                details: 'Branding card bg'
                            },
                            {
                                color: 'branding-purple-light2',
                                bg: 'bg-branding-purple-light2',
                                border: 'border-branding-purple-light2',
                                text: 'text-branding-purple-light2',
                                hex: '#bec4d0',
                                frequency: 'Rare',
                                details: 'Branding card border'
                            },
                            {
                                color: 'family-blue',
                                bg: 'bg-family-blue',
                                border: 'border-family-blue',
                                text: 'text-family-blue',
                                hex: '#84b4eb',
                                frequency: 'Rare',
                                details: 'Family blue, on SAYT'
                            },
                            {
                                color: 'input-placeholder',
                                bg: 'bg-input-placeholder',
                                border: 'border-input-placeholder',
                                text: 'text-input-placeholder',
                                hex: '#a3aab5',
                                frequency: 'Very rare',
                                details: 'Input placeholder'
                            },
                            {
                                color: 'shopit-blue',
                                bg: 'bg-shopit-blue',
                                border: 'border-shopit-blue',
                                text: 'text-shopit-blue',
                                hex: '#3b7ec2',
                                frequency: 'Common',
                                details: 'Separators text on Search Grid'
                            },
                            {
                                color: 'shopit-darkest-blue',
                                bg: 'bg-shopit-darkest-blue',
                                border: 'border-shopit-darkest-blue',
                                text: 'text-shopit-darkest-blue',
                                hex: '#2d779a',
                                frequency: 'Rare',
                                details: 'UNP details'
                            },
                            {
                                color: 'facebook-blue',
                                bg: 'bg-facebook-blue',
                                border: 'border-facebook-blue',
                                text: 'text-facebook-blue',
                                hex: '#5176bf',
                                frequency: 'Never',
                                details: 'On login modal'
                            },
                            {
                                color: 'gray-100',
                                bg: 'bg-gray-100', border: 'border-gray-100', text: 'text-gray-100',
                                hex: '#f3f4f6', frequency: 'Common', details: 'Backgrounds'
                            },
                            {
                                color: 'gray-200',
                                bg: 'bg-gray-200', border: 'border-gray-200', text: 'text-gray-200',
                                hex: '#e5e7eb', frequency: 'Rare', details: 'Borders'
                            },
                            {
                                color: 'gray-300',
                                bg: 'bg-gray-300', border: 'border-gray-300', text: 'text-gray-300',
                                hex: '#d1d5db', frequency: 'Rare', details: 'Service texts, for example on 404'
                            },
                            {
                                color: 'gray-400',
                                bg: 'bg-gray-400', border: 'border-gray-400', text: 'text-gray-400',
                                hex: '#9ca3af', frequency: 'Common', details: 'Borders'
                            },
                            {
                                color: 'gray-500',
                                bg: 'bg-gray-500', border: 'border-gray-500', text: 'text-gray-500',
                                hex: '#6b7280', frequency: 'Often', details: 'Gray text'
                            },
                            {
                                color: 'gray-600',
                                bg: 'bg-gray-600', border: 'border-gray-600', text: 'text-gray-600',
                                hex: '#4b5563', frequency: 'Rare', details: ''
                            },
                            {
                                color: 'gray-700',
                                bg: 'bg-gray-700', border: 'border-gray-700', text: 'text-gray-700',
                                hex: '#374151', frequency: 'Rare', details: 'Gray text'
                            },
                            {
                                color: 'gray-800',
                                bg: 'bg-gray-800', border: 'border-gray-800', text: 'text-gray-800',
                                hex: '#1f2937', frequency: 'Rare', details: 'Filter labels'
                            },
                            {
                                color: 'gray-900',
                                bg: 'bg-gray-900', border: 'border-gray-900', text: 'text-gray-900',
                                hex: '#111827', frequency: 'Rare', details: 'Dark gray text'
                            },
                            {
                                color: 'neutral-300',
                                bg: 'bg-neutral-300', border: 'border-neutral-300', text: 'text-neutral-300',
                                hex: '#d4d4d4', frequency: 'Rare', details: 'User settings header'
                            },
                            {
                                color: 'neutral-500',
                                bg: 'bg-neutral-500', border: 'border-neutral-500', text: 'text-neutral-500',
                                hex: '#737373', frequency: 'Rare', details: 'Footer text'
                            },
                            {
                                color: 'neutral-600',
                                bg: 'bg-neutral-600', border: 'border-neutral-600', text: 'text-neutral-600',
                                hex: '#525252', frequency: 'Rare', details: 'Service texts'
                            },
                            {
                                color: 'slate-50',
                                bg: 'bg-slate-50', border: 'border-slate-50', text: 'text-slate-50',
                                hex: '#f8fafc', frequency: 'Common', details: 'Background'
                            },
                        ].map((a, index) => {
                            const bgColor = a.bg ? a.bg : `bg-${a.color}`;
                            const borderColor = a.border ? a.border : `border-${a.color}`;
                            const textColor = a.text ? a.text : `text-${a.color}`;

                            return <tr key={`${index}-r`}>
                                <td>
                                    <div className={`h-10 w-10 rounded ${bgColor}`}></div>
                                </td>
                                <td>
                                    <div className={`h-10 w-10 rounded border ${borderColor}`}></div>
                                </td>
                                <td>
                                    <div className={`${textColor} text-center`}>Text</div>
                                </td>
                                <td>{a.hex}</td>
                                <td>{a.color}</td>
                                <td>{a.frequency}</td>
                                <td>{a.details}</td>
                            </tr>
                        })}

                    </table>
                </div>
            </DemonstrationGroup>
            <DemonstrationGroup header={`Sizing / Spacing`}>
                <div className={`border p-4 mt-2`}>
                    {[
                        {
                            name: 'space',
                            details: '0.23rem, common size of space symbol. using for force a separation space',
                        },
                        {name: 'side-panel', details: '382px, a width of Category or Filter panel',},
                        {
                            name: 'side-panel-compensate',
                            details: '400px, a shift size for content when Category or Filter panel is active',
                        },
                        {
                            name: 'image-width-xs',
                            details: '84px, this a below use for force an image size',
                        },
                        {name: 'image-width-s', details: '124px',},
                        {name: 'image-width-m', details: '242px',},
                        {name: 'image-width-l', details: '320px',},
                        {name: 'image-height-xs', details: '64px',},
                        {name: 'image-height-s', details: '94px',},
                        {name: 'image-height-m', details: '182px',},
                        {name: 'image-height-l', details: '240px',},
                    ].map(({name, details}, key) => {
                        return <div className={`flex`} key={`k${key}`}>
                            <div className={`flex-none mr-4 w-36`}>{name}</div>
                            <div className={`flex-none`}>{details}</div>
                        </div>
                    })}
                </div>
            </DemonstrationGroup>
        </div>
    );
}
