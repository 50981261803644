import latinize from "./LatinizeHelper";

export function splitRequestUrlToPathAndSearch(url: string) {
    const separatorIndex = url.indexOf('?')
    if (separatorIndex !== -1) {
        return [url.substring(0, separatorIndex), url.substring(separatorIndex)]
    }
    return [url, ""]
}

export function latinizeAndSimplify(name: string | undefined): string | undefined {
    if (!name) return name;
    return latinize(name).replace(/[^\d\w]/gi, '-').replace(/-+-/gi, '-').toLowerCase()
}

export function absoluteLink(relativeLink: string): string {
    if (relativeLink.startsWith("http")) return relativeLink;
    if (relativeLink.startsWith("/")) return "https://shopit.se" + relativeLink;
    return "https://shopit.se/" + relativeLink;
}