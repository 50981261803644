import React, {useEffect, useState} from "react";
import {ProductImage} from "../../../App/Common/Image";
import {fetchCategoryData} from "../../../../Helpers/ApiHelper";
import {triggerError,} from "../../../../Store/Action/interactive";
import {useDispatch, useSelector} from "react-redux";

type CategoryListState = {
    categories: CategoryStructure_Category[] | undefined
    categoriesHash: string
    isLoading: boolean
}

export default function CategoryList(props: {
    categoryClass: { id: number, className: string } | undefined
    categoryIds: number[] | undefined,
    categories: CategoryStructure_Category[] | undefined,
    onClick: (category: CategoryStructure_Category) => void
    className?: string
    imageSize: 'xs' | 's'
    imageCount: number
}) {

    // globals
    const dispatch = useDispatch()

    const [state, setState] = useState<CategoryListState>({
        categories: props.categories,
        categoriesHash:
            props.categoryIds
                ? props.categoryIds.join(',')
                : props.categories
                    ? props.categories.map(c => c.categoryId).join(',')
                    : "",
        isLoading: false
    })

    const apiTail = useSelector((state: IAppState) => ({
        _logPageToken: state.session.tracking.pageToken || null
    }))

    const _convertCategoryStructure = (categoryResponse: IApiDataCategory[]): CategoryStructure_Category[] => {
        return categoryResponse.map(c => ({
            categoryId: c.id,
            name: c.name,
            isOpen: c.isOpened,
            isCollection: c.isCollection,
            imageIds: c.imageIds,
            path: c.path !== undefined ? _convertCategoryStructure(c.path) : [],
            children: c.categories !== undefined ? _convertCategoryStructure(c.categories) : []
        }));
    }

    const _fetchCategoryData = function (categoryIds: number[]) {
        setState({
            ...state,
            categoriesHash: categoryIds.join(','),
            isLoading: true
        })

        fetchCategoryData(categoryIds, apiTail)
            .then((categoriesApiResponse) => {
                if (categoriesApiResponse) {
                    const categories = _convertCategoryStructure(categoriesApiResponse)
                    setState({
                        ...state,
                        categories: categories,
                        isLoading: false
                    })
                }
            })
            .catch((error) => {
                dispatch(triggerError(
                    error.code,
                    error.name,
                    error.message));

                setState({
                    ...state,
                    isLoading: false
                })
            })
    }

    useEffect(() => {
        if (!state.categories && props.categoryIds && props.categoryIds.length > 0) {
            _fetchCategoryData(props.categoryIds)
        }
    }, [state.categoriesHash]);

    const {categories} = state;
    const {onClick, className, categoryClass, imageSize, imageCount} = props
    return (
        <div className={`category-list ${className ? className : ''}`}>
            {categories ? categories.map((category, i) => (
                <div key={category.categoryId}
                     className={`category-list-category${category.isCollection ? ' collection' : ''} ${categoryClass && categoryClass.id == category.categoryId ? categoryClass.className : ''}`}
                     onClick={() => onClick(categories[i])}>
                    <p className="category-list-category-title">
                        {category.name}
                        {category.path && category.path.length > 0 && (
                            <span className="category-list-category-path">
                                    {category.path.map(({name}) => name).join(" » ")}
                                </span>)}
                    </p>

                    <div className="category-list-category-images" key={category.categoryId}>
                        {category.imageIds && category.imageIds.concat([0, 0, 0, 0]).slice(0, imageCount).map((image, j) => (
                            image !== 0
                                ? <div className={`img-wrapper`}>
                                    <ProductImage key={j} size={imageSize} alt={category.name} imageId={image}/>
                                </div>
                                : <div key={j} className={`img-wrapper`}>
                                    <div
                                        className={`inline-block w-image-width-${imageSize} h-image-height-${imageSize}`}/>
                                </div>
                        ))}
                    </div>
                </div>
            )) : null}
        </div>
    )
}
