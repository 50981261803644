export const LOAD_PRODUCT_OFFER_DATA = "LOAD_PRODUCT_OFFER_DATA";

// ----

export type LoadProductOfferAction = {
    type: string,
    productId: number,
    callback: (url: string, cashback: number | undefined) => void
}

export const triggerLoadProductOfferData = (productId: number, callback: (url: string, cashback: number | undefined) => void): LoadProductOfferAction => ({
    type: LOAD_PRODUCT_OFFER_DATA,
    productId: productId,
    callback,
});

