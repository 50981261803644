import React, {Fragment, useContext} from "react";
import {useSelector} from 'react-redux';
import {
    convertSearchParamsToUrlResolveParams,
    detachSearchParamTextFilter,
} from "../../../Helpers/SearchHelper";
import {selectSearchCache} from "../../../Store/StoreHelper";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import SearchRouteParamLink from "../../App/Links/SearchRouteParamLink";
import {SearchRouteContext} from "../../../Store/Context/SearchRouteContext";

export default function FilterBarTextButton(props: { filterParam: IProductListSearch_TextFilter }) {

    const searchContext = useContext(SearchRouteContext);

    const {t} = useTranslation("TopBar");
    const getTranslation = function (key: keyof TranslationSet["TopBar"]) {
        return t(key);
    }

    const searchData = useSelector((state: IAppState) => selectSearchCache(state, searchContext.cacheKey));

    const searchQuery = searchData && searchData.object ? searchData.object.searchQuery : undefined
    const filterParam = props.filterParam;
    const name = filterParam.data.map((i)=>i.id).join(',');

    return <Fragment>
        {filterParam.data.map((option: IProductListSearch_StringFilterData, index: number) => {
            const currentFilters: IProductListSearch_Filter[] = searchQuery && searchQuery.filter ? [...searchQuery.filter] : [];
            const optionFilters = detachSearchParamTextFilter(currentFilters, IProductListSearch_FilterType.Text, option.id)

            return <SearchRouteParamLink
                key={"o" + index}
                className="btn btn-active-filter ml-1"
                title={getTranslation("hint_remove_filter")}
                urlPageParams={convertSearchParamsToUrlResolveParams({
                    ...searchQuery,
                    filter: optionFilters,
                })}
            >
                {`"${name}"`} <img src="/resources/icon-remove-white.png" className="inline-block" alt="Remove Filter"/>
            </SearchRouteParamLink>
        })}
    </Fragment>
}
