import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";

const SearchListServerNotResponse: FunctionComponent = function () {
    const {t} = useTranslation("Messages");

    return <div className={`no-items-message-wrapper`}>
        <div className={`no-items-message`}>
            <img className={`no-items-filter-icon`} src="/resources/sad.svg" alt={``}/>
            <div className={`no-items-message-first`}>{t('sorry_title')}</div>
            <div className={`no-items-message-second`}>{t('unknown_error')}</div>
        </div>
    </div>
}

export default SearchListServerNotResponse;