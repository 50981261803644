import React, {useEffect, useRef} from "react";
import {
    triggerImageVerification_Delete,
    triggerImageVerification_Save_ImageContentType,
    triggerImageVerification_Save_ImageType,
    triggerImageVerification_Save_ImageOverlay,
    triggerImageVerification_Save_Status,
    triggerImageVerification_Save_ImageVariants,
    triggerImageVerification_Save_ImageWatermark
} from "../../Store/Action/session.verifyImage";
import {useDispatch} from "react-redux";
import {getMenuPositionForPoint} from "../App/Layout/MenuContainer";
import {checkNodeParent, EVENT_MENU_CLOSE_CHECK} from "../../Helpers/EventHelper";

type MenuImageVerificationProps = {
    productId?: number,
    imageId: number,
    userSessionId?: string,
    verifications?: { [imageKey: number]: IShophunterImageData[] },
    x: number,
    y: number,
    closeCallback: () => void | undefined
}

export function MenuImageVerification(props: MenuImageVerificationProps) {

    const dispatch = useDispatch();

    const ref = useRef<HTMLDivElement>(null)

    const onContextMenuCloseCheck = function (e: Event) {
        const clickNode: HTMLElement | undefined = (e as CustomEvent).detail;
        const menuNode: HTMLElement | null = ref.current;
        if (clickNode && menuNode) {
            if (!checkNodeParent(clickNode, menuNode)) {
                if (props.closeCallback !== undefined) {
                    props.closeCallback();
                }
            }
        }
    }

    useEffect(() => {
        // attach menu closer
        window.addEventListener(EVENT_MENU_CLOSE_CHECK, onContextMenuCloseCheck)

        return () => {
            // detach menu closer when component delete
            window.removeEventListener(EVENT_MENU_CLOSE_CHECK, onContextMenuCloseCheck)
        }
    }, [])

    const estimateMenuHeight = 618
    const estimateMenuWidth = 634
    const menuLocation = getMenuPositionForPoint(props.x, props.y, estimateMenuWidth, estimateMenuHeight)

    const imageVerifications = props.verifications ? props.verifications[props.imageId] : [];

    // copied from ImageVerificationList
    const triggerVerificationChange = function (verificationType: string) {
        if (!props.userSessionId) return;

        switch (verificationType) {
            case VERIFICATION_IMAGE_STATUS.MAIN:
            case VERIFICATION_IMAGE_STATUS.UNRELATED:
            case VERIFICATION_IMAGE_STATUS.ACCESSORY:
            case VERIFICATION_IMAGE_STATUS.OK_IMAGE:
                if (props.productId) {
                    dispatch(triggerImageVerification_Save_Status(props.userSessionId, props.imageId, props.productId, verificationType));
                }
                break;
            case VERIFICATION_IMAGE_TYPE.ISOLATED:
            case VERIFICATION_IMAGE_TYPE.GRADIENT:
            case VERIFICATION_IMAGE_TYPE.SOLID:
            case VERIFICATION_IMAGE_TYPE.DRAWING:
            case VERIFICATION_IMAGE_TYPE.HOMOGEN:
            case VERIFICATION_IMAGE_TYPE.LIGHT_GRADIENT:
            case VERIFICATION_IMAGE_TYPE.BAD_SOFT_PIXELS:
            case VERIFICATION_IMAGE_TYPE.BAD_UNCROPPED:
                dispatch(triggerImageVerification_Save_ImageType(props.userSessionId, props.imageId, verificationType));
                break;
            case VERIFICATION_IMAGE_CONTENT_TYPE.IMAGE_CONTENT_TYPE_ICON_NO_IMAGE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.ICON_NO_IMAGE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.ICON_CAMPAIGN:
            case VERIFICATION_IMAGE_CONTENT_TYPE.ICON_SYMBOL_OR_FUNCTION:
            case VERIFICATION_IMAGE_CONTENT_TYPE.ICON_WEBSITE_DESIGN:
            case VERIFICATION_IMAGE_CONTENT_TYPE.BAD_UNRELATED:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_CLOSEUP:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_SIDE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_TOP:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_BOTTOM:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_BACK:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_MULTIPLE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_ENVIRONMENT:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_FRONT:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_OPEN:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_CLOSED:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_ONE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_IN_USAGE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_MULTIPLE_VARIANTS:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_MULTIPLE_SAME:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_ONE_ACCESSORY_OR_PART:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_SET_OR_COLLECTION:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_SCREENSHOT:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_AND_COVER:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_COVER_ONLY:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_AND_PRODUCT:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_UNIQUE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_BACK:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_GENERIC:
            case VERIFICATION_IMAGE_CONTENT_TYPE.BOOK_MEDIA_COVER:
            case VERIFICATION_IMAGE_CONTENT_TYPE.BOOK_MEDIA_COVER_BACK:
            case VERIFICATION_IMAGE_CONTENT_TYPE.DRAWING_BLUEPRINT:
            case VERIFICATION_IMAGE_CONTENT_TYPE.DRAWING_3D:
            case VERIFICATION_IMAGE_CONTENT_TYPE.DRAWING_ILLUSTRATION:
            case VERIFICATION_IMAGE_CONTENT_TYPE.TEXT:
            case VERIFICATION_IMAGE_CONTENT_TYPE.COLLAGE_TEXT_IMAGE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.CHART_GRAPH:
            case VERIFICATION_IMAGE_CONTENT_TYPE.CHART_SIZE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.CHART_ENERGY_CERTIFICATE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.EXAMPLE_PALETTE_COLOR_MATERIAL:
            case VERIFICATION_IMAGE_CONTENT_TYPE.EXAMPLE_ONE_MATERIAL_COLOR:
            case VERIFICATION_IMAGE_CONTENT_TYPE.EXAMPLE_ONE_PATTERN:
            case VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_BRAND:
            case VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_VENDOR:
            case VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_PRODUCT:
            case VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_TECH:
            case VERIFICATION_IMAGE_CONTENT_TYPE.MULTIPLE_LOGOS:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FOCUS_TOP:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FOCUS_BOTTOM:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_CLOSEUP:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FULL:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_SIDE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_BACK:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_HEAD:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_HANDS:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FEET:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_SITTING:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_MULTI_PERSPECTIVE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_MULTI_VARIANTS:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_ENVIRONMENT:
                dispatch(triggerImageVerification_Save_ImageContentType(props.userSessionId, props.imageId, verificationType));
                break;
            case "VARIANT_0":
                dispatch(triggerImageVerification_Save_ImageVariants(props.userSessionId, props.imageId, 0));
                break;
            case "VARIANT_1":
                dispatch(triggerImageVerification_Save_ImageVariants(props.userSessionId, props.imageId, 1));
                break;
            case "VARIANT_2":
                dispatch(triggerImageVerification_Save_ImageVariants(props.userSessionId, props.imageId, 2));
                break;
            case "VARIANT_3":
                dispatch(triggerImageVerification_Save_ImageVariants(props.userSessionId, props.imageId, 3));
                break;
            case "VARIANT_4":
                dispatch(triggerImageVerification_Save_ImageVariants(props.userSessionId, props.imageId, 4));
                break;
            case "VARIANT_5":
                dispatch(triggerImageVerification_Save_ImageVariants(props.userSessionId, props.imageId, 5));
                break;
            case VERIFICATION_IMAGE_OVERLAY.OVERLAY_TEXT:
            case VERIFICATION_IMAGE_OVERLAY.OVERLAY_SYMBOL:
            case VERIFICATION_IMAGE_OVERLAY.OVERLAY_LOGO:
            case VERIFICATION_IMAGE_OVERLAY.OVERLAY_CAMPAIGN:
            case VERIFICATION_IMAGE_OVERLAY.OVERLAY_CLOSEUP:
            case VERIFICATION_IMAGE_OVERLAY.OVERLAY_GRAPHICS:
            case VERIFICATION_IMAGE_OVERLAY.OVERLAY_FALSE:
                dispatch(triggerImageVerification_Save_ImageOverlay(props.userSessionId, props.imageId, verificationType));
                break;
            case VERIFICATION_IMAGE_WATERMARK.WATERMARK_FALSE:
            case VERIFICATION_IMAGE_WATERMARK.WATERMARK_TRUE:
                dispatch(triggerImageVerification_Save_ImageWatermark(props.userSessionId, props.imageId, verificationType));
                break;
            case "RESET":
                dispatch(triggerImageVerification_Delete(props.userSessionId, props.imageId, props.productId));
                break;
            default:
                break;
        }
    }

    const createButton = (verificationType: string, text: string, _class: string) => {
        let isActive = false;
        let isPending = false;

        if (imageVerifications) {
            isActive = !!imageVerifications.find(v => v.verificationType == VERIFICATION_VERIFICATION_TYPE.TYPE && v.value.imageType == verificationType)
                || !!imageVerifications.find(v => v.verificationType == VERIFICATION_VERIFICATION_TYPE.CONTENT_TYPE && v.value.imageContentType == verificationType)
                || !!imageVerifications.find(v => v.verificationType == VERIFICATION_VERIFICATION_TYPE.STATUS && v.value.imageStatus == verificationType)
                || !!imageVerifications.find(v => v.verificationType == VERIFICATION_VERIFICATION_TYPE.WATERMARK && v.value.imageWatermark == verificationType)
                || !!imageVerifications.find(v => v.verificationType == VERIFICATION_VERIFICATION_TYPE.OVERLAY && v.value.imageOverlay == verificationType)

            isPending = !!imageVerifications.find(v => v.verificationType == VERIFICATION_VERIFICATION_TYPE.TYPE && v.pending.imageType == verificationType)
                || !!imageVerifications.find(v => v.verificationType == VERIFICATION_VERIFICATION_TYPE.CONTENT_TYPE && v.pending.imageContentType == verificationType)
                || !!imageVerifications.find(v => v.verificationType == VERIFICATION_VERIFICATION_TYPE.STATUS && v.pending.imageStatus == verificationType)
                || !!imageVerifications.find(v => v.verificationType == VERIFICATION_VERIFICATION_TYPE.WATERMARK && v.pending.imageWatermark == verificationType)
                || !!imageVerifications.find(v => v.verificationType == VERIFICATION_VERIFICATION_TYPE.OVERLAY && v.pending.imageOverlay == verificationType)
        }

        return <div className={`image-verification-button ${_class} ${isPending ? 'pending' : isActive ? `active` : ``}`}
                    onClick={(e) => {
                        e.stopPropagation()
                        triggerVerificationChange(verificationType)
                        if (props.closeCallback)
                            props.closeCallback()
                    }}>{text}</div>
    }
    const createNotImplementButton = (text: string) => {
        return <div className={`image-verification-button not-implement`}>{text}</div>
    }

    return <div className={`bg-white p-4 js-verification-menu border border-gray-400 shadow-lg`}
                ref={ref}
                style={{
                    position: "fixed",
                    top: `${menuLocation.y}px`,
                    left: `${menuLocation.x}px`,
                    zIndex: 50
                }}>
        <div className={`flex`}>
            <div className={`image-verification-column`}>
                <div className={`image-verification-group`}>
                    <div className={`image-verification-group-label`}>This product</div>
                    {createButton(VERIFICATION_IMAGE_STATUS.MAIN, "Main Product Image", "button-product-main")}
                    {createButton(VERIFICATION_IMAGE_STATUS.OK_IMAGE, "OK for Product", "button-product-ok")}
                    {createButton(VERIFICATION_IMAGE_STATUS.ACCESSORY, "Accessory for Product", "button-product-accessory")}
                    {createButton(VERIFICATION_IMAGE_STATUS.UNRELATED, "Unrelated for Product", "button-product-unrelated")}
                </div>
                <div className={`image-verification-group`}>
                    <div className={`image-verification-group-label`}>Type</div>
                    {createButton(VERIFICATION_IMAGE_TYPE.ISOLATED, "Isolated", "button-products")}
                    {createButton(VERIFICATION_IMAGE_TYPE.LIGHT_GRADIENT, "Light Gradient", "button-products")}
                    {createButton(VERIFICATION_IMAGE_TYPE.GRADIENT, "Gradient", "button-products")}
                    {createButton(VERIFICATION_IMAGE_TYPE.SOLID, "Solid", "button-products")}
                    {createButton(VERIFICATION_IMAGE_TYPE.HOMOGEN, "Homogen", "button-products")}
                    {createButton(VERIFICATION_IMAGE_TYPE.DRAWING, "B&W Drawing", "button-specs")}
                    {createButton(VERIFICATION_IMAGE_TYPE.BAD_SOFT_PIXELS, "Bad Soft Pixels", "button-red")}
                    {createButton(VERIFICATION_IMAGE_TYPE.BAD_UNCROPPED, "Bad Uncropped", "button-red")}
                </div>
                <div className={`image-verification-group`}>
                    <div className={`image-verification-group-label`}>Overlay</div>
                    {createButton(VERIFICATION_IMAGE_OVERLAY.OVERLAY_TEXT, "Overlay Text", "button-no")}
                    {createButton(VERIFICATION_IMAGE_OVERLAY.OVERLAY_SYMBOL, "Overlay Symbol", "button-no")}
                    {createButton(VERIFICATION_IMAGE_OVERLAY.OVERLAY_LOGO, "Overlay Logo", "button-no")}
                    {createButton(VERIFICATION_IMAGE_OVERLAY.OVERLAY_CAMPAIGN, "Overlay Campaign", "button-no")}
                    {createButton(VERIFICATION_IMAGE_OVERLAY.OVERLAY_MEASUREMENT, "Overlay Measurement", "button-no")}
                    {createButton(VERIFICATION_IMAGE_OVERLAY.OVERLAY_CLOSEUP, "Overlay Closeup", "button-no")}
                    {createButton(VERIFICATION_IMAGE_OVERLAY.OVERLAY_GRAPHICS, "Overlay Graphics", "button-no")}
                    {createButton(VERIFICATION_IMAGE_OVERLAY.OVERLAY_FALSE, "No Overlay", "button-no")}
                </div>
                <div className={`image-verification-group`}>
                    <div className={`image-verification-group-label`}>Watermarks</div>
                    {createButton(VERIFICATION_IMAGE_WATERMARK.WATERMARK_TRUE, "Watermark", "button-no-3")}
                    {createButton(VERIFICATION_IMAGE_WATERMARK.WATERMARK_FALSE, "Not Watermark", "button-no-3")}
                </div>
            </div>

            <div className={`image-verification-column`}>
                <div className={`image-verification-group`}>
                    <div className={`image-verification-group-label`}>Product</div>
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_ONE, "Product (One)", "button-illustration")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_IN_USAGE, "Product in Use", "button-product-ok")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_MULTIPLE_VARIANTS, "Multiple Variants", "button-product-ok")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_MULTIPLE_SAME, "Multiple Same", "button-product-ok")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_ONE_ACCESSORY_OR_PART, "One Accessory / Part", "button-product-ok")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_SET_OR_COLLECTION, "Set or Collection", "button-product-ok")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_SCREENSHOT, "Screenshot", "button-product-ok")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_AND_COVER, "Product with Cover", "button-product-ok")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_COVER_ONLY, "Product Cover Only", "button-product-ok")}
                </div>
                <div className={`image-verification-group`}>
                    <div className={`image-verification-group-label`}>Package</div>
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_UNIQUE, "Package (Unique)", "button-product-ok")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_BACK, "Package Back", "button-product-ok")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_GENERIC, "Package Generic", "button-product-ok")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_AND_PRODUCT, "Package and Product", "button-product-ok")}
                </div>
                <div className={`image-verification-group`}>
                    <div className={`image-verification-group-label`}>Perspective</div>
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_CLOSED, "Persp. Closeup", "button-brightyellow")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_FRONT, "Persp. Front", "button-brightyellow")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_SIDE, "Persp. Side", "button-brightyellow")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_TOP, "Persp. Top", "button-brightyellow")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_BOTTOM, "Persp. Bottom", "button-brightyellow")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_BACK, "Persp. Back", "button-brightyellow")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_OPEN, "Persp. Inside / Open", "button-brightyellow")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_CLOSED, "Persp. Folded / Closed", "button-brightyellow")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_MULTIPLE, "Persp. Multiple", "button-brightyellow")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_ENVIRONMENT, "Persp. Environment", "button-brightyellow")}
                </div>
            </div>

            <div className={`image-verification-column`}>
                <div className={`image-verification-group`}>
                    <div className={`image-verification-group-label`}>Icon</div>
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.ICON_NO_IMAGE, "Icon No Image", "button-product-unrelated")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.ICON_CAMPAIGN, "Icon Campaign", "button-no")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.ICON_SYMBOL_OR_FUNCTION, "Symbol / Function", "button-no")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.ICON_WEBSITE_DESIGN, "Website Design", "button-no")}
                </div>
                <div className={`image-verification-group`}>
                    <div className={`image-verification-group-label`}>Logo</div>
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_BRAND, "Brand Logo", "button-logo")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_VENDOR, "Vendor Logo", "button-logo")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_PRODUCT, "Product Logo", "button-logo")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_TECH, "Tech Logo", "button-logo")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.MULTIPLE_LOGOS, "Multiple Logos", "button-logo")}
                </div>
                <div className={`image-verification-group`}>
                    <div className={`image-verification-group-label`}>Text</div>
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.TEXT, "Text", "button-specs")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.COLLAGE_TEXT_IMAGE, "Collage (Text / Image)", "button-specs")}
                </div>
                <div className={`image-verification-group`}>
                    <div className={`image-verification-group-label`}>Drawing</div>
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.DRAWING_BLUEPRINT, "Drawing Blueprint", "button-specs")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.DRAWING_3D, "Drawing 3D", "button-specs")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.DRAWING_ILLUSTRATION, "Drawing Illustration", "button-specs")}
                </div>
                <div className={`image-verification-group`}>
                    <div className={`image-verification-group-label`}>Chart</div>
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.CHART_GRAPH, "Chart Graph", "button-specs")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.CHART_SIZE, "Chart Sizes", "button-specs")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.CHART_ENERGY_CERTIFICATE, "Chart Energy Certificate", "button-specs")}
                </div>
            </div>

            <div className={`image-verification-column`}>
                <div className={`image-verification-group`}>
                    <div className={`image-verification-group-label`}>Human model</div>
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FOCUS_TOP, "Human Focus Top", "button-no")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FOCUS_BOTTOM, "Human Focus Bottom", "button-no")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FULL, "Human Full", "button-no")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_CLOSEUP, "Human Closeup", "button-no")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_SIDE, "Human Side", "button-no")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_BACK, "Human Back", "button-no")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_HEAD, "Human Head", "button-no")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_HANDS, "Human Hands", "button-no")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FEET, "Human Feet/Legs", "button-no")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_SITTING, "Human Sitting", "button-no")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_MULTI_PERSPECTIVE, "Human Multi-Persp.", "button-no")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_MULTI_VARIANTS, "Human Multi-Variant", "button-no")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_ENVIRONMENT, "Human Environment", "button-no")}
                </div>
                <div className={`image-verification-group`}>
                    <div className={`image-verification-group-label`}>Book / Media</div>
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.BOOK_MEDIA_COVER, "Book / Media Cover", "button-product-ok")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.BOOK_MEDIA_COVER_BACK, "Book / Media Cover Back", "button-product-ok")}
                </div>
                <div className={`image-verification-group`}>
                    <div className={`image-verification-group-label`}>Example</div>
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.EXAMPLE_PALETTE_COLOR_MATERIAL, "Multi Colors / Materials", "button-brightyellow")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.EXAMPLE_ONE_MATERIAL_COLOR, "One Color / Material", "button-brightyellow")}
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.EXAMPLE_ONE_PATTERN, "One Pattern", "button-brightyellow")}
                </div>
                <div className={`image-verification-group`}>
                    <div className={`image-verification-group-label`}>Bad</div>
                    {createButton(VERIFICATION_IMAGE_CONTENT_TYPE.BAD_UNRELATED, "Unrelated", "button-red")}
                </div>
                <div className={`image-verification-group`}>
                    <div className={`image-verification-group-label`}>&nbsp;</div>
                    {createButton('RESET', "Reset", "button-products")}
                </div>
            </div>
        </div>
    </div>
}