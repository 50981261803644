import React, {MouseEventHandler, ReactElement, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDomainImageUrl, getLogoImageUrl} from "../../../Helpers/ImageHelper";
import {Link} from "react-router-dom";
import {Price} from "../Price";
import {ProductOfferImage} from "../../App/Common/Image";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {getDebugProductUrl} from "../../../Helpers/BackendHelper";
import {replaceSpecialUnicodeCharacters} from "../../../Helpers/StringFormatter.Text";
import {IconChevronRight} from "../../App/Icons";


/** @type TranslationKeys, keep this variable without TS type, for keep type check magic works*/
const ComponentTranslationKey = "Product.Tab.ProductOffers"

/** @see ProductVendorsDemo */
export default function ProductOffersTable(props: { product: IProduct, scrollTop : any }) {
    // globals
    const dispatch = useDispatch()
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet["Product"]["Tab"]["ProductOffers"]) {
        // i18next have problem with process '.' separated path to key as default
        // so we force a path with ':'
        // i tried to debug, but not understand how it works... but works
        // (copy comment for copy+paste multilevel translations functions)
        return t('Product:Tab:ProductOffers:' + key)
    }

    const [state, setState] = useState({unfolded: false})

    const isDebugEnabled = useSelector((state: IAppState) => state.config.debug)
    const showCashback = useSelector((state: IAppState) => state.config.showCashback)

    const imageOnErrorHandler = function (e: React.SyntheticEvent) {
        (e.currentTarget as any).src = getDomainImageUrl(undefined, '');
    }

    const toggleFolded: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        props.scrollTop()
        setState({unfolded: !state.unfolded})
    }

    const renderList = function (productOffers: IProductOffer[]) {

        let activeCount = 0
        for (const productOffer of productOffers) {
            if (productOffer.priceInclVat && productOffer.productUrl && productOffer.productUrl.length > 0) {
                activeCount++
            }
        }
        let bottom: ReactElement | null = null
        if (activeCount < productOffers.length) {
            bottom = <div className={`vendors-instance bottom-row`} key={`b`}>{
                getTranslation('stats')
                    .replace("<total_offers_count>", '' + productOffers.length)
                    .replace("<active_offers_count>", '' + activeCount)
            }</div>
        }

        return <div className="p-2">
            <div className={`vendors-instance`} key={`h`}>
                <div
                    className="vendors-instance-image-column header-row hidden sm:block">{getTranslation('product')}</div>
                <div className="vendors-instance-details-wrapper">
                    <div
                        className="vendors-instance-domain-column header-row hidden sm:block">{getTranslation('store')}</div>
                    <div
                        className="vendors-instance-status-column header-row hidden sm:block">{getTranslation('availability')}</div>
                    <div
                        className="vendors-instance-price-column header-row hidden sm:block">{getTranslation('price')}</div>
                    {showCashback &&
                        <div className="vendors-instance-cashback-column header-row hidden sm:block">{getTranslation('with_cashback')}</div>}
                    <div
                        className="vendors-instance-buttons-column header-row hidden sm:block">{getTranslation('link')}</div>
                </div>
            </div>

            {productOffers.map((productOffer, index) => {
                if (productOffer.foldable && !state.unfolded) {
                    return null
                }

                let cashback: ReactElement | null = null
                if (showCashback && productOffer.websiteStore.commissionOrderPercentage) {
                    const price = productOffer.priceSaleInclVat ? productOffer.priceSaleInclVat : productOffer.priceInclVat
                    let cashbackPrice = price * (1 - (productOffer.websiteStore.commissionOrderPercentage / 100))
                    let cashbackReturn = price - cashbackPrice
                    cashback = <div className={`cashback`}>
                        <div className={`font-bold`}><Price price={cashbackPrice} currency={productOffer.currency} unbuyable={false}/></div>
                        <div>{productOffer.websiteStore.commissionOrderPercentage}% (<Price price={cashbackReturn} currency={productOffer.currency} unbuyable={false}/>)</div>
                    </div>
                }

                const hasUrl = productOffer.productUrl && productOffer.productUrl.length > 0
                let shippingTime = null;
                if (productOffer.shipping) {
                    if (productOffer.shipping.shippingDaysMin !== undefined || productOffer.shipping.shippingDaysMax) {
                        if (productOffer.shipping.shippingDaysMin !== undefined && productOffer.shipping.shippingDaysMax) {
                            shippingTime = productOffer.shipping.shippingDaysMin + "-" + productOffer.shipping.shippingDaysMax + " " + getTranslation('days')
                        } else if (productOffer.shipping.shippingDaysMin !== undefined) {
                            shippingTime = getTranslation('from') + " " + productOffer.shipping.shippingDaysMin
                            if (productOffer.shipping.shippingDaysMin === 1) {
                                shippingTime += " " + getTranslation('day')
                            } else {
                                shippingTime += " " + getTranslation('days')
                            }
                        } else {
                            shippingTime = getTranslation('up_too') + " " + productOffer.shipping.shippingDaysMax
                            if (productOffer.shipping.shippingDaysMax === 1) {
                                shippingTime += " " + getTranslation('day')
                            } else {
                                shippingTime += " " + getTranslation('days')
                            }
                        }
                        shippingTime = <div>{shippingTime}</div>
                    }
                }
                return <div className={`vendors-instance${hasUrl ? '' : ' no-url'}`}
                            key={'index' + index}
                            title={hasUrl ? '' : getTranslation('not_paid')}>
                    <div className="vendors-instance-image-column">
                        <div className="vendors-instance-image-wrapper">
                            <ProductOfferImage imageId={productOffer.productMainImageId || 0}
                                               alt={productOffer.productTitle}/>
                        </div>
                    </div>
                    <div className="vendors-instance-details-wrapper">
                        <div className="vendors-instance-domain-column">
                            <div className="vendors-instance-domain-wrapper">
                                <div className="vendors-instance-domain-logo">
                                    <img alt={`${productOffer.websiteStore.name} logo`}
                                         src={getLogoImageUrl(productOffer.websiteStore.id, "M")}/>
                                </div>
                                <div className="vendors-instance-domain-instance-column">
                                    <span className={`font-bold`}>{productOffer.websiteStore.name}</span>
                                    {productOffer.marketplaceMerchant
                                        ? <span className={`text-gray-400`}>
                                        {' / '}{productOffer.marketplaceMerchant.name}
                                    </span>
                                        : null}
                                    {isDebugEnabled && productOffer.marketplaceMerchant
                                        ? <span
                                            className="text-gray-500">{' '}(merchant_id={productOffer.marketplaceMerchant.id})</span>
                                        : null}
                                    {isDebugEnabled
                                        ? <div>
                                            {productOffer.websiteStore.websiteUrl}
                                        </div>
                                        : null}
                                    <div className="text-gray-700 vendors-instance-product-title">{isDebugEnabled
                                        ? productOffer.productTitle
                                        : replaceSpecialUnicodeCharacters(productOffer.productTitle)}
                                    </div>
                                    {isDebugEnabled && productOffer.scoreDebug ?
                                        <div className="text-gray-400">Score: {productOffer.score}<br/>{productOffer.scoreDebug}</div> : null}
                                </div>
                            </div>
                        </div>
                        <div className="vendors-instance-status-column">
                            {productOffer.statusFound !== 'FOUND' ? getTranslation("discontinued") :
                                productOffer.stockStatus === 'IN_STOCK' ? getTranslation("in_stock") :
                                    productOffer.stockStatus === 'OUT_OF_STOCK' ? getTranslation("out_of_stock") :
                                        productOffer.stockStatus === 'BACKORDER' ? getTranslation("backorder") :
                                            productOffer.stockStatus === 'PREORDER' ? getTranslation("pre_order") :
                                                getTranslation("stock_unknown")
                            }
                            {isDebugEnabled && productOffer.stockQuantity && productOffer.stockQuantity > 0
                                ? (' (' + productOffer.stockQuantity + ')') : null}
                            {shippingTime}
                            {isDebugEnabled && productOffer.condition
                                ? <div>{productOffer.condition}</div>
                                : null}
                        </div>
                        <div className="vendors-instance-price-column">
                            {productOffer.priceSaleInclVat ? <div className={`font-bold`}>
                                <Price price={productOffer.priceSaleInclVat}
                                       currency={productOffer.currency}
                                       unbuyable={false}/>
                            </div> : null}
                            {(!productOffer.priceSaleInclVat || isDebugEnabled) && productOffer.priceInclVat
                                ? <div className={!productOffer.priceSaleInclVat ? `font-bold` : `line-through`}>
                                    <Price price={productOffer.priceInclVat}
                                           currency={productOffer.currency}
                                           unbuyable={false}/>
                                </div> : null}
                        </div>
                        {showCashback && <div className="vendors-instance-cashback-column">{cashback}</div>}
                        <div className="vendors-instance-buttons-column">

                            {hasUrl
                                && <Link className="btn btn-origin-blue"
                                         target={`_blank`}
                                         rel={`nofollow`}
                                         to={{
                                             pathname: '/go-to-store',
                                             search: 'id=' + productOffer.potentialProductId +
                                                 '&mi=' + productOffer.productMainImageId
                                         }}>
                                    {/*<img*/}
                                    {/*    className="vendors-instance-buttons-country"*/}
                                    {/*    src={getCountryFlagImageUrl(toCountry)} alt="preview"/>*/}
                                    <div className="inline-block align-bottom">{getTranslation("visit_the_store").toUpperCase()}
                                        <IconChevronRight style={{width: '2em', display: 'inline', marginTop: -2}} fill={`#fff`}/>
                                    </div>
                                </Link>
                            }

                            {isDebugEnabled
                                ? <a href={getDebugProductUrl(productOffer.potentialProductId)} target='backend'
                                     className="backend-btn cursor-pointer">FIX</a>
                                : null}
                        </div>
                    </div>
                </div>
            })}

            {bottom}
        </div>
    }

    const product = props.product;

    let foldableCount = 0
    if (product.productOffers && product.productOffers.length > 0) {
        for (const productOffer of product.productOffers) {
            if (productOffer.foldable) {
                foldableCount++
            }
        }
    }

    let unfoldElement: ReactElement | null = null

    if (foldableCount > 0) {
        if (state.unfolded) {
            unfoldElement =
                <div className="vendors-wrapper-hidden">
                    <button className="btn btn-grey"
                            onClick={toggleFolded}>
                        {getTranslation("hide_offers").replace("<count>", "" + foldableCount)}
                    </button>
                </div>
        } else {
            unfoldElement =
                <div className="vendors-wrapper-hidden">
                    <button className="btn btn-grey"
                            onClick={toggleFolded}>{getTranslation("show_hidden_offers")}</button>
                    <div className="mt-2">
                        {getTranslation("we_have_hidden_offers").replace("<count>", "" + foldableCount)}
                    </div>
                </div>
        }
    }

    return <div>
        <div className="vendors-wrapper">
            {product.productOffers && product.productOffers.length > 0
                ? <div className={`vendors-new-instances`}>
                    <h2 className="vendors-instances-header">{getTranslation("retailers")}</h2>
                    {renderList(product.productOffers)}
                    {unfoldElement}
                </div>
                :
                <div className={`vendors-new-instances`}>
                    <h2 className="vendors-instances-header">{getTranslation("retailers")}</h2>

                    <div className={`text-center text-gray-600 pb-10`}>
                        <span className={`bg-slate-50 p-4 border rounded-md`}>
                            {getTranslation('no_available_primary_products')}
                        </span>
                    </div>
                </div>
            }
        </div>
    </div>
}
