export const CONFIG_REQUEST_LOCALIZATION = "CONFIG_REQUEST_LOCALIZATION";
export const CONFIG_LOADING_LOCALIZATION = "CONFIG_LOADING_LOCALIZATION";

export const CONFIG_RESPONSE_LOCALIZATION_LANGUAGES = "CONFIG_RESPONSE_LOCALIZATION_LANGUAGES";
export const CONFIG_RESPONSE_LOCALIZATION_CURRENCIES = "CONFIG_RESPONSE_LOCALIZATION_CURRENCIES";
export const CONFIG_RESPONSE_LOCALIZATION_COUNTRIES = "CONFIG_RESPONSE_LOCALIZATION_COUNTRIES";

export const CONFIG_LANGUAGE_SET = "CHANGE_LANGUAGE"

export type RequestLocalizationAction = {
    type: string,
    localizationType: "countries" | "currencies" | "languages"
}

export const triggerRequestLocalization = (localizationType: RequestLocalizationAction["localizationType"]): RequestLocalizationAction => ({
    type: CONFIG_REQUEST_LOCALIZATION,
    localizationType: localizationType
})

export const triggerLoadingLocalization = (localizationType: RequestLocalizationAction["localizationType"]): RequestLocalizationAction => ({
    type: CONFIG_LOADING_LOCALIZATION,
    localizationType: localizationType
})

export type ResponseLocalizationLanguagesAction = {
    type: string,
    languages: Array<{ id: number, code: string, supported: number }>
}

export const triggerResponseLocalizationLanguage = (data: ResponseLocalizationLanguagesAction["languages"]): ResponseLocalizationLanguagesAction => ({
    type: CONFIG_RESPONSE_LOCALIZATION_LANGUAGES,
    languages: data
})

export type ResponseLocalizationCurrenciesAction = {
    type: string,
    currencies: Array<{ id: number, code: string, usage: string }>
}

export const triggerResponseLocalizationCurrencies = (data: ResponseLocalizationCurrenciesAction["currencies"]): ResponseLocalizationCurrenciesAction => ({
    type: CONFIG_RESPONSE_LOCALIZATION_CURRENCIES,
    currencies: data
})

export type ResponseLocalizationCountriesAction = {
    type: string,
    countries: Array<{ id: number, country_code: string, text: string }>
}

export const triggerResponseLocalizationCountries = (data: ResponseLocalizationCountriesAction["countries"]): ResponseLocalizationCountriesAction => ({
    type: CONFIG_RESPONSE_LOCALIZATION_COUNTRIES,
    countries: data
})

// ----

export type ConfigLanguageSetAction = {
    type: string,
    lang: string | undefined
}

export const triggerConfigLanguageSet = (lang: string | undefined): ConfigLanguageSetAction => ({
    type: CONFIG_LANGUAGE_SET,
    lang: lang
})

