import {Popover} from "@headlessui/react";
import Slider from "../FilterNumeric/Slider";
import React, {MouseEventHandler, useContext} from "react";
import {
    convertSearchParamsToUrlResolveParams,
    toggleColorFilter
} from "../../../Helpers/SearchHelper";
import {triggerCacheAccessSearchWithCallback} from "../../../Store/Action/cache.search";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {selectSearchCache} from "../../../Store/StoreHelper";
import {getDefaultLang, triggerDebug} from "../../../Store/Action/interactive";
import {SearchRouteContext} from "../../../Store/Context/SearchRouteContext";
import {buildSearchUrl} from "../../../Helpers/UrlFormatter.Search";

const ComponentTranslationKey = "FilterColor"

export default function FilterColor(props: {
    color: IFilterPanel_Color,
    demonstrationMode: boolean
}) {

    // globals
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const searchContext = useContext(SearchRouteContext);

    const {t} = useTranslation(ComponentTranslationKey)
    const urlContext = useSelector((state: IAppState) => state.urlContext)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    // extract global state
    const searchData = useSelector((state: IAppState) => selectSearchCache(state, searchContext.cacheKey))
    const searchQuery = searchData && searchData.object ? searchData.object.searchQuery : undefined
    const isDebugEnabled = useSelector((state: IAppState) => state.config.debug)

    let hl: number | undefined = props.color.hl;
    let hh: number | undefined = props.color.hh;
    let sl: number | undefined = props.color.sl;
    let sh: number | undefined = props.color.sh;
    let ll: number | undefined = props.color.ll;
    let lh: number | undefined = props.color.lh;

    //TODO remove this?
    if (searchQuery) {
        if (hl == undefined) {
            for (let i = 0; i < searchQuery.filter.length; i++) {
                if (searchQuery.filter[i]._type == IProductListSearch_FilterType.Color) {
                    const filter = searchQuery.filter[i] as IProductListSearch_ColorFilter;
                    hl = filter.hl;
                    hh = filter.hh;
                    sl = filter.sl;
                    sh = filter.sh;
                    ll = filter.ll;
                    lh = filter.lh;
                }
            }
        }
    }

    const propsExtension = {
        hl: hl !== undefined ? hl : -40,
        hh: hh !== undefined ? hh : 320,
        sl: sl !== undefined ? sl : 0,
        sh: sh !== undefined ? sh : 100,
        ll: ll !== undefined ? ll : 0,
        lh: lh !== undefined ? lh : 100,
    }

    const colorChanged = (hl: number, hh: number, sl: number, sh: number, ll: number, lh: number) => {

        if (props.demonstrationMode)
            return;

        const currentFilters: IProductListSearch_Filter[] = searchQuery && searchQuery.filter ? [...searchQuery.filter] : [];

        const h = ((hh - hl) / 2) + hl
        const s = ((sh - sl) / 2) + sl
        const l = ((lh - ll) / 2) + ll

        const nextFilters = (h == 140 && s == 50 && l == 50) ?
            toggleColorFilter(currentFilters, undefined, undefined, undefined, undefined, undefined, undefined) :
            toggleColorFilter(currentFilters, hl, hh, sl, sh, ll, lh)

        const urlPageParams = convertSearchParamsToUrlResolveParams({
            ...searchQuery,
            filter: nextFilters,
        })

        dispatch(triggerCacheAccessSearchWithCallback(urlPageParams, false, isDebugEnabled, () => {
                const [toPathName, toSearch, isValidLink] = buildSearchUrl(urlPageParams, urlContext);
                if (!isValidLink) {
                    console.log("Search link error");
                    console.log(toPathName, toSearch, urlPageParams, urlContext)
                    const trace = new Error().stack
                    console.log(trace)
                    dispatch(triggerDebug("Search link error (console)", trace))
                }

                navigate({
                    pathname: toPathName,
                    search: toSearch
                })
            }
        ))
    }

    const onHueAfterChange = (val: number[] | number) => {
        if (Array.isArray(val)) {
            colorChanged(val[0], val[1], propsExtension.sl, propsExtension.sh, propsExtension.ll, propsExtension.lh);
        }
    }

    const onSaturateAfterChange = (val: number[] | number) => {
        if (Array.isArray(val)) {
            colorChanged(propsExtension.hl, propsExtension.hh, val[0], val[1], propsExtension.ll, propsExtension.lh);
        }
    }

    const onLightnessAfterChange = (val: number[] | number) => {
        if (Array.isArray(val)) {
            colorChanged(propsExtension.hl, propsExtension.hh, propsExtension.sl, propsExtension.sh, val[0], val[1]);
        }
    }

    const onColorBlockClick: MouseEventHandler<HTMLElement> = (e) => {
        const target = e.target as HTMLElement
        if (!target.dataset || !target.dataset.values)
            return

        const val = target.dataset.values.split(',')
        const hl = parseInt(val[0])
        const hh = parseInt(val[1])
        const sl = parseInt(val[2])
        const sh = parseInt(val[3])
        const ll = parseInt(val[4])
        const lh = parseInt(val[5])

        colorChanged(hl, hh, sl, sh, ll, lh);
    }

    // render
    const h = ((propsExtension.hh - propsExtension.hl) / 2) + propsExtension.hl
    const s = ((propsExtension.sh - propsExtension.sl) / 2) + propsExtension.sl
    const l = ((propsExtension.lh - propsExtension.ll) / 2) + propsExtension.ll

    return (
        <div className={`filter-panel-attribute-spacing`}>
            <div className="filter-panel-color-block">
                <div className="filter-panel-color-select">
                    <Popover as="div" className="relative">
                        <Popover.Button>
                            <div className="filter-color-trigger"
                                 style={h != 140 || s != 50 || l != 50 ? {background: `hsl(${h}, ${s}%, ${l}%)`} : {}}></div>
                        </Popover.Button>
                        <Popover.Panel className="filter-color-popup">
                            <div>

                                <div className="filter-color-select-grid">
                                    {
                                        [{hex: '#FFF', values: '-40,340,0,10,80,100'},
                                            {hex: '#FF5E63', values: '-40,17,5,75,60,90'},
                                            {hex: '#FF995E', values: '10,53,5,75,60,90'},
                                            {hex: '#FDFFAD', values: '40,89,5,75,60,90'},
                                            {hex: '#6DF46B', values: '80,169,5,75,60,90'},
                                            {hex: '#599BFF', values: '160,245,5,75,60,90'},
                                            {hex: '#AB5CD6', values: '234,320,5,75,60,90'},
                                            {hex: '#7B7B7B', values: '-40,340,0,10,20,80'},
                                            {hex: '#FE000B', values: '-40,17,10,100,30,70'},
                                            {hex: '#FF5E00', values: '10,53,10,100,30,70'},
                                            {hex: '#FAFF02', values: '40,89,10,100,30,70'},
                                            {hex: '#03D102', values: '80,169,10,100,30,70'},
                                            {hex: '#0065FF', values: '160,245,10,100,30,70'},
                                            {hex: '#8B00D5', values: '234,320,10,100,30,70'},
                                            {hex: '#000', values: '-40,340,0,10,0,25'},
                                            {hex: '#BF0006', values: '-40,17,10,100,5,40'},
                                            {hex: '#BA4400', values: '10,53,10,100,5,40'},
                                            {hex: '#A5A800', values: '40,89,10,100,5,40'},
                                            {hex: '#028400', values: '80,169,10,100,5,40'},
                                            {hex: '#0042A5', values: '160,245,10,100,5,40'},
                                            {hex: '#4D0077', values: '234,320,10,100,5,40'}].map((color, i) => {
                                            return <div key={`color-${color.hex}`}
                                                        className="filter-color-select-grid-cell"
                                                        data-values={color.values}
                                                        style={{"background": color.hex}}
                                                        onClick={onColorBlockClick}>
                                            </div>
                                        })
                                    }
                                </div>

                                <div className="color-slider">
                                    <div className="bar bar-hue"></div>
                                    <Slider min={-40}
                                            max={320}
                                            value={[propsExtension.hl, propsExtension.hh]}
                                            onAfterChange={onHueAfterChange}/>
                                </div>

                                <div className="color-slider">
                                    <div className="bar bar-saturation"
                                         style={{backgroundColor: `hsl(${h}, 100%, 50%)`}}></div>
                                    <Slider min={0}
                                            max={100}
                                            value={[propsExtension.sl, propsExtension.sh]}
                                            onAfterChange={onSaturateAfterChange}/>
                                </div>

                                <div className="color-slider">
                                    <div className="bar bar-lightness"
                                         style={{backgroundColor: `hsl(${h}, ${s}%, 50%)`}}></div>
                                    <Slider min={0}
                                            max={100}
                                            value={[propsExtension.ll, propsExtension.lh]}
                                            onAfterChange={onLightnessAfterChange}/>
                                </div>

                            </div>
                        </Popover.Panel>
                    </Popover>
                </div>

                <div className="filter-panel-color-label pl-2">
                    {getTranslation('selectColor')}
                    <span>Beta</span>
                </div>

            </div>
        </div>
    );
}