import React, {PropsWithChildren} from "react";
import ProductCard from "../../ProductsGrid/Card_Product";
import FoldedManufacturerCard from "../../ProductsGrid/Card_FoldedManufacturer";
import FoldedBrandingCard from "../../ProductsGrid/Card_FoldedBranding";
import ShowSecondaryCard from "../../ProductsGrid/Card_ShowSecondary";
import ShowMorePrimaryCard from "../../ProductsGrid/Card_ShowMorePrimary";
import ShowMoreSecondaryCard from "../../ProductsGrid/Card_ShowMoreSecondary";
import CollectionCard from "../../ProductsGrid/Card_Collection";
import CategoryCard from "../../ProductsGrid/Card_Category";
import ManufacturerCard from "../../ProductsGrid/Card_Manufacturer";
import BrandingCard from "../../ProductsGrid/Card_Branding";
import UnknownCard from "../../ProductsGrid/Card_Unknown";
import PlaceholderCard from "../../ProductsGrid/Card_Placeholder";
import BrandingSeparator from "../../ProductsGrid/Separator_Branding";
import FamilyChainSeparator from "../../ProductsGrid/Separator_FamilyChain";
import FoldedBrandingSeparator from "../../ProductsGrid/Separator_FoldedBranding";
import FoldedManufacturerSeparator from "../../ProductsGrid/Separator_FoldedManufacturer";
import ManufacturerSeparator from "../../ProductsGrid/Separator_Manufacturer";
import TopLevelSeparator from "../../ProductsGrid/Separator_TopLevel";
import {getDefaultLang} from "../../../Store/Action/interactive";

const exampleProductImageIds = [
    {image_id: 287775905}, {image_id: 201936645}, {image_id: 286370885}, {image_id: 284638745}
];

/** @see ProductCard */
export function ProductCardDemo(props: { className: string }) {
    return <ProductCard className={props.className}
                        debug={undefined}
                        product={{
                            id: 0,
                            gtinId: undefined,
                            name: "Product Card",
                            description: "Product Description",
                            image_id: 287775905,
                            image_ids: [],
                            category_name: undefined,
                            category_id: 0,
                            featureAttributeMaxCount: 0,
                            category_not_listed: false,
                            productOffersCountTotal: 1,
                            productOffersCountBuyable: 1,
                            count_videos: 1,
                            count_images: 1,
                            priceMinInclVat: 123,
                            priceMaxInclVat: 999,
                            priceCurrency: "SEK"
                        }}/>
}

/** @see FoldedManufacturerCard */
export function FoldedManufacturerCardDemo(props: { className: string }) {
    return <FoldedManufacturerCard className={props.className}
                                   name={`Manufacturer Name`}
                                   exampleImages={exampleProductImageIds}
                                   count={123}
                                   urlPageParams={{
                                       bridgeParams: {
                                           categoryId: undefined,
                                           language: undefined,
                                           color: undefined,
                                           filters: undefined,
                                           expand: undefined,
                                           expandedBrands: undefined,
                                           secondaryExpand: undefined,
                                           showSecondary: undefined,
                                           fold: undefined,
                                           foldedBrands: undefined,
                                           page: undefined,
                                           size: undefined,
                                           text: undefined,
                                           code: undefined,
                                           group: undefined,
                                           sort: undefined,
                                       },
                                       showFilters: undefined,
                                       openFilterOption: undefined,
                                       openUnpOptions: undefined,
                                   }}
                                   fold={``}
    />
}

/** @see FoldedBrandingCard */
export function FoldedBrandingCardDemo(props: { className: string }) {
    return <FoldedBrandingCard className={props.className}
                               name={`Brand Name`}
                               exampleImages={exampleProductImageIds}
                               count={123}
                               urlPageParams={{
                                   bridgeParams: {
                                       categoryId: undefined,
                                       language: undefined,
                                       color: undefined,
                                       filters: undefined,
                                       expand: undefined,
                                       expandedBrands: undefined,
                                       secondaryExpand: undefined,
                                       showSecondary: undefined,
                                       fold: undefined,
                                       foldedBrands: undefined,
                                       page: undefined,
                                       size: undefined,
                                       text: undefined,
                                       code: undefined,
                                       sort: undefined,
                                       group: undefined,
                                   },
                                   showFilters: undefined,
                                   openFilterOption: undefined,
                                   openUnpOptions: undefined,
                               }}
                               fold={``}
    />
}

/** @see ShowSecondaryCard */
export function ShowSecondaryCardDemo(props: { className: string }) {
    return <ShowSecondaryCard className={props.className}
                              count={123}
                              exampleImages={exampleProductImageIds}
                              filters={[]}
                              urlParams={{
                                  bridgeParams: {
                                      categoryId: 10342,
                                      language: undefined,
                                      color: undefined,
                                      filters: undefined,
                                      expand: undefined,
                                      expandedBrands: undefined,
                                      secondaryExpand: undefined,
                                      showSecondary: undefined,
                                      fold: undefined,
                                      foldedBrands: undefined,
                                      page: undefined,
                                      size: undefined,
                                      text: undefined,
                                      code: undefined,
                                      sort: undefined,
                                      group: undefined,
                                  },
                                  showFilters: undefined,
                                  openFilterOption: [],
                                  openUnpOptions: [],
                              }}/>
}

/** @see ShowMorePrimaryCard */
export function ShowMorePrimaryCardDemo(props: { className: string }) {
    return <ShowMorePrimaryCard className={props.className}
                                count={123}
                                title={'Some Text'}
                                exampleImages={exampleProductImageIds}
                                filters={[]}
                                urlParams={{
                                    bridgeParams: {
                                        categoryId: 10342,
                                        language: undefined,
                                        color: undefined,
                                        filters: undefined,
                                        expand: undefined,
                                        expandedBrands: undefined,
                                        secondaryExpand: undefined,
                                        showSecondary: undefined,
                                        fold: undefined,
                                        foldedBrands: undefined,
                                        page: undefined,
                                        size: undefined,
                                        text: undefined,
                                        code: undefined,
                                        sort: undefined,
                                        group: undefined,
                                    },
                                    showFilters: undefined,
                                    openFilterOption: [],
                                    openUnpOptions: [],
                                }}/>
}

/** @see ShowMoreSecondaryCard */
export function ShowMoreSecondaryCardDemo(props: { className: string }) {
    return <ShowMoreSecondaryCard className={props.className}
                                  count={123}
                                  title={'Some Text'}
                                  exampleImages={exampleProductImageIds}
                                  filters={[]}
                                  urlParams={{
                                      bridgeParams: {
                                          categoryId: 10342,
                                          language: undefined,
                                          color: undefined,
                                          filters: undefined,
                                          expand: undefined,
                                          expandedBrands: undefined,
                                          secondaryExpand: undefined,
                                          showSecondary: undefined,
                                          fold: undefined,
                                          foldedBrands: undefined,
                                          page: undefined,
                                          size: undefined,
                                          text: undefined,
                                          code: undefined,
                                          sort: undefined,
                                          group: undefined,
                                      },
                                      showFilters: undefined,
                                      openFilterOption: [],
                                      openUnpOptions: [],
                                  }}/>
}

/** @see CollectionCard */
export function CollectionCardDemo(props: { className: string }) {
    return <CollectionCard className={props.className}
                           category={{
                               id: 0,
                               name: `Collection Name`,
                               path: [{id: 0, name: 'Path1'}, {id: 0, name: 'Path2'}, {
                                   id: 0,
                                   name: 'Path3'
                               }],
                               exampleImages: exampleProductImageIds,
                               count: 123,
                               countSecondary: 124
                           }}
                           appearance={{
                               filters: [],
                               showPath: true
                           }}
                           context={{
                               debug: true,
                               lang: getDefaultLang()
                           }}
                           link={{
                               urlParams: {
                                   bridgeParams: {
                                       categoryId: 10342,
                                       language: undefined,
                                       color: undefined,
                                       filters: undefined,
                                       expand: undefined,
                                       expandedBrands: undefined,
                                       secondaryExpand: undefined,
                                       showSecondary: undefined,
                                       fold: undefined,
                                       foldedBrands: undefined,
                                       page: undefined,
                                       size: undefined,
                                       text: undefined,
                                       code: undefined,
                                       sort: undefined,
                                       group: undefined,
                                   },
                                   showFilters: undefined,
                                   openFilterOption: [],
                                   openUnpOptions: [],
                               }
                           }}/>
}

/** @see CategoryCard */
export function CategoryCardDemo(props: { className: string }) {
    return <CategoryCard className={props.className}
                         category={{
                             id: 0,
                             name: `Category Name`,
                             path: [{id: 0, name: 'Path1'}, {id: 0, name: 'Path2'}, {
                                 id: 0,
                                 name: 'Path3'
                             }],
                             exampleImages: exampleProductImageIds,
                             count: 123,
                             countSecondary: 124
                         }}
                         appearance={{
                             filters: [],
                             showPath: true
                         }}
                         context={{
                             debug: true,
                             lang: getDefaultLang()
                         }}
                         link={{
                             urlParams: {
                                 bridgeParams: {
                                     categoryId: 10342,
                                     language: undefined,
                                     color: undefined,
                                     filters: undefined,
                                     expand: undefined,
                                     expandedBrands: undefined,
                                     secondaryExpand: undefined,
                                     showSecondary: undefined,
                                     fold: undefined,
                                     foldedBrands: undefined,
                                     page: undefined,
                                     size: undefined,
                                     text: undefined,
                                     code: undefined,
                                     sort: undefined,
                                     group: undefined,
                                 },
                                 showFilters: undefined,
                                 openFilterOption: [],
                                 openUnpOptions: [],
                             }
                         }}/>
}

/** @see ManufacturerCard */
export function ManufacturerCardDemo(props: { className: string }) {
    return <ManufacturerCard className={props.className}
                             name={`Manufacturer Name`}
                             imageId={0}/>
}

/** @see BrandingCard */
export function BrandingCardDemo(props: { className: string }) {
    return <BrandingCard className={props.className}
                         name={`Brand Name`}
                         imageId={0}/>
}

/** @see UnknownCard */
export function UnknownCardDemo(props: { className: string }) {
    return <UnknownCard className={props.className}
                        type={`Unknown Card Type`}
                        subType={`sub-type`}/>
}

/** @see PlaceholderCard */
export function PlaceholderCardDemo(props: { className: string }) {
    return <PlaceholderCard className={props.className}
                            message={"Placeholder Card"}/>
}

// ---

export function PlaceholderCardWrapperDemo(props: PropsWithChildren<{ className: string }>) {
    return <div className={`${props.className} category-item-decorable-card`}>
        <div className="category-item-separator-wrapper">
            <div className="flex-1"></div>
            {props.children}
            <div className="flex-1"></div>
        </div>
        <div className="category-item-card-wrapper" key="card">
            <PlaceholderCard message={"Placeholder Card"}/></div>
    </div>
}

/** @see BrandingSeparator */
export function BrandingSeparatorDemo() {
    return <BrandingSeparator id={0}
                              withLink={false}
                              name={`Branding`}
                              urlPageParams={{
                                  bridgeParams: {
                                      categoryId: undefined,
                                      language: undefined,
                                      color: undefined,
                                      filters: undefined,
                                      expand: undefined,
                                      expandedBrands: undefined,
                                      secondaryExpand: undefined,
                                      showSecondary: undefined,
                                      fold: undefined,
                                      foldedBrands: undefined,
                                      page: undefined,
                                      size: undefined,
                                      text: undefined,
                                      code: undefined,
                                      group: undefined,
                                      sort: undefined,
                                  },
                                  showFilters: undefined,
                                  openFilterOption: undefined,
                                  openUnpOptions: undefined,
                              }}
                              fold={``}
                              isDebugEnabled={false}
    />
}

/** @see FamilyChainSeparator */
export function FamilyChainSeparatorDemo() {
    return <FamilyChainSeparator chain={[{name: "Family", id: 0}]}
                                 isDebugEnabled={false}
                                 urlPageParams={{
                                     bridgeParams: {
                                         categoryId: undefined,
                                         language: undefined,
                                         color: undefined,
                                         filters: undefined,
                                         expand: undefined,
                                         expandedBrands: undefined,
                                         secondaryExpand: undefined,
                                         showSecondary: undefined,
                                         fold: undefined,
                                         foldedBrands: undefined,
                                         page: undefined,
                                         size: undefined,
                                         text: undefined,
                                         code: undefined,
                                         sort: undefined,
                                         group: undefined,
                                     },
                                     showFilters: undefined,
                                     openFilterOption: undefined,
                                     openUnpOptions: undefined,
                                 }}/>
}

/** @see FoldedBrandingSeparator */
export function FoldedBrandingSeparatorDemo() {
    return <FoldedBrandingSeparator name={`Branding`}
                                    withLink={false}
                                    id={-1}
                                    urlPageParams={{
                                        bridgeParams: {
                                            categoryId: undefined,
                                            language: undefined,
                                            color: undefined,
                                            filters: undefined,
                                            expand: undefined,
                                            expandedBrands: undefined,
                                            secondaryExpand: undefined,
                                            showSecondary: undefined,
                                            fold: undefined,
                                            foldedBrands: undefined,
                                            page: undefined,
                                            size: undefined,
                                            text: undefined,
                                            code: undefined,
                                            sort: undefined,
                                            group: undefined,
                                        },
                                        showFilters: undefined,
                                        openFilterOption: undefined,
                                        openUnpOptions: undefined,
                                    }}
                                    fold={``}/>
}

/** @see FoldedManufacturerSeparator */
export function FoldedManufacturerSeparatorDemo() {
    return <FoldedManufacturerSeparator name={`Manufacturer`}
                                        withLink={false}
                                        id={-1}
                                        urlPageParams={{
                                            bridgeParams: {
                                                categoryId: undefined,
                                                language: undefined,
                                                color: undefined,
                                                filters: undefined,
                                                expand: undefined,
                                                expandedBrands: undefined,
                                                secondaryExpand: undefined,
                                                showSecondary: undefined,
                                                fold: undefined,
                                                foldedBrands: undefined,
                                                page: undefined,
                                                size: undefined,
                                                text: undefined,
                                                code: undefined,
                                                group: undefined,
                                                sort: undefined
                                            },
                                            showFilters: undefined,
                                            openFilterOption: undefined,
                                            openUnpOptions: undefined,
                                        }}
                                        fold={``}/>
}

/** @see ManufacturerSeparator */
export function ManufacturerSeparatorDemo() {
    return <ManufacturerSeparator id={0}
                                  name={`Manufacturer`}
                                  withLink={false}
                                  urlPageParams={{
                                      bridgeParams: {
                                          categoryId: undefined,
                                          language: undefined,
                                          color: undefined,
                                          filters: undefined,
                                          expand: undefined,
                                          expandedBrands: undefined,
                                          secondaryExpand: undefined,
                                          showSecondary: undefined,
                                          fold: undefined,
                                          foldedBrands: undefined,
                                          page: undefined,
                                          size: undefined,
                                          text: undefined,
                                          code: undefined,
                                          sort: undefined,
                                          group: undefined
                                      },
                                      showFilters: undefined,
                                      openFilterOption: undefined,
                                      openUnpOptions: undefined,
                                  }}
                                  fold={``}
                                  isDebugEnabled={false}/>
}

/** @see TopLevelSeparator */
export function TopLevelSeparatorDemo() {
    return <TopLevelSeparator
        categoryId={0}
        categoryName={`Top Level`}
        urlPageParams={{
            bridgeParams: {
                categoryId: undefined,
                language: undefined,
                color: undefined,
                filters: undefined,
                expand: undefined,
                expandedBrands: undefined,
                secondaryExpand: undefined,
                showSecondary: undefined,
                fold: undefined,
                foldedBrands: undefined,
                page: undefined,
                size: undefined,
                text: undefined,
                code: undefined,
                group: undefined,
                sort: undefined,
            },
            showFilters: undefined,
            openFilterOption: undefined,
            openUnpOptions: undefined,
        }}
        fold={``}/>
}

// ---

/** @see ProductCard */
export function ProductCardDemo2(props: { className: string }) {
    return <div></div>
}