import React, {
    BaseSyntheticEvent,
    CSSProperties,
    MouseEvent,
    MouseEventHandler,
    ReactNode, RefCallback,
    RefObject,
    TouchEvent,
    TouchEventHandler
} from "react";

/**
 * To prevent text selection while dragging.
 * http://stackoverflow.com/questions/5429827/how-can-i-prevent-text-element-selection-with-cursor-drag
 */
const pauseEvent = (e: BaseSyntheticEvent) => {
    if (e.stopPropagation) e.stopPropagation();
    if (e.preventDefault) e.preventDefault();
    (e as any).cancelBubble = true;
    (e as any).returnValue = false;
    return false;
}

const stopPropagation = (e: BaseSyntheticEvent) => {
    if (e.stopPropagation) e.stopPropagation();
    (e as any).cancelBubble = true;
}

/**
 * Spreads `count` values equally between `min` and `max`.
 */
const linspace = (min: number, max: number, count: number): number[] => {
    var range = (max - min) / (count - 1);
    var res = [];
    for (var i = 0; i < count; i++) {
        res.push(min + range * i);
    }
    return res;
}

const ensureArray = (x: number | number[]): number[] => {
    return x == null ? [] : Array.isArray(x) ? x : [x];
}

const undoEnsureArray = (x: number | number[]): number | number[] => {
    return x != null && Array.isArray(x) && x.length === 1 ? x[0] : x;
}

type SliderProps = {
    min: number,
    max: number,
    step: number,
    minDistance: number,
    defaultValue: number | number[],
    value: number | number[],
    orientation: 'horizontal' | 'vertical',
    className: string,
    handleClassName: string,
    handleActiveClassName: string,
    withBars: boolean,
    barClassName: string,
    pearling: boolean,
    disabled: boolean,
    snapDragDisabled: boolean,
    invert: boolean,
    onBeforeChange: (val: number | number[]) => void,
    onChange: (val: number | number[]) => void,
    onAfterChange: (val: number | number[]) => void,
    onSliderClick: (val: number) => void,
    steps: { offset: number }[],
    original: boolean
}

type SliderState = {
    index: number,
    upperBound: number,
    sliderLength: number,
    value: number[]
    zIndices: number[],

    sliderStart: number,
    startPosition: number,
    handleSize: number,
    startValue: number,
}

class Slider extends React.Component<SliderProps, SliderState> {

    public static defaultProps: Partial<SliderProps> = {
        min: 0,
        max: 100,
        step: 1,
        minDistance: 0,
        defaultValue: 0,
        orientation: 'horizontal',
        className: 'slider',
        handleClassName: 'slider-handle',
        handleActiveClassName: 'active',
        barClassName: 'bar',
        withBars: false,
        pearling: false,
        disabled: false,
        snapDragDisabled: false,
        invert: false,
        steps: [],
        original: true
    };

    tempArray: any[]
    pendingResizeTimeouts: any[]
    hasMoved: boolean
    startPosition: number[]
    isScrolling: boolean | undefined

    refSlider: HTMLElement | undefined
    refHandlers: (HTMLElement | undefined)[]

    constructor(props: SliderProps) {
        super(props);

        let value = this._or(ensureArray(this.props.value), ensureArray(this.props.defaultValue));
        // reused throughout the component to store results of iterations over `value`
        this.tempArray = value.slice();

        // array for storing resize timeouts ids
        this.pendingResizeTimeouts = [];

        var zIndices = [];
        for (var i = 0; i < value.length; i++) {
            value[i] = this._trimAlignValue(value[i], this.props);
            zIndices.push(i);
        }

        this.state = {
            index: -1,
            upperBound: 0,
            sliderLength: 0,
            value: value,
            zIndices: zIndices,

            sliderStart: 0,
            startPosition: 0,
            handleSize: 0,
            startValue: 0,
        };

        this.isScrolling = undefined
        this.hasMoved = false
        this.startPosition = []

        this.refSlider = undefined
        this.refHandlers = []
    }

    // dunno how to process self reference on static
    // static getDerivedStateFromProps = function (newProps: SliderProps, lastState: SliderState): Partial<SliderState> | null {
    //     var value = this._or(ensureArray(newProps.value), lastState.value);
    //
    //     let nextValue = [...lastState.value];
    //
    //     // ensure the array keeps the same size as `value`
    //     this.tempArray = value.slice();
    //
    //     for (var i = 0; i < value.length; i++) {
    //         nextValue[i] = this._trimAlignValue(value[i], newProps);
    //     }
    //     if (nextValue.length > value.length)
    //         nextValue.length = value.length;
    //
    //     // If an upperBound has not yet been determined (due to the component being hidden
    //     // during the mount event, or during the last resize), then calculate it now
    //     if (lastState.upperBound === 0) {
    //         this._handleResize();
    //     }
    //
    //     return {
    //         value: nextValue
    //     }
    // }

    _setRefSlider: RefCallback<HTMLElement> = (i: HTMLElement) => {
        this.refSlider = i
    }

    // Check if the arity of `value` or `defaultValue` matches the number of children (= number of custom handles).
    // If no custom handles are provided, just returns `value` if present and `defaultValue` otherwise.
    // If custom handles are present but neither `value` nor `defaultValue` are applicable the handles are spread out
    // equally.
    // TODO: better name? better solution?
    _or = (value: number[], defaultValue: number[]) => {
        var count = React.Children.count(this.props.children);
        switch (count) {
            case 0:
                return value.length > 0 ? value : defaultValue;
            case value.length:
                return value;
            case defaultValue.length:
                return defaultValue;
            default:
                if (value.length !== count || defaultValue.length !== count) {
                    console.warn("ReactSlider: Number of values does not match number of children.");
                }
                return linspace(this.props.min, this.props.max, count);
        }
    }

    componentDidMount() {
        window.addEventListener('resize', this._handleResize);
        this._handleResize();
    }

    componentWillUnmount() {
        this._clearPendingResizeTimeouts();
        window.removeEventListener('resize', this._handleResize);
    }

    getValue = () => {
        return undoEnsureArray(this.state.value);
    }

    _handleResize = () => {

        // setTimeout of 0 gives element enough time to have assumed its new size if it is being resized
        var resizeTimeout = window.setTimeout(function (this: Slider) {

            // drop this timeout from pendingResizeTimeouts to reduce memory usage
            this.pendingResizeTimeouts.shift();

            const slider = this.refSlider;
            if (slider) {
                var rect: DOMRect = slider.getBoundingClientRect();

                var size = 0;
                var sliderMax = 0;
                var sliderMin = 0;

                if (this.props.orientation === 'horizontal') {
                    sliderMax = this.props.invert ? rect.left : rect.right;
                    sliderMin = this.props.invert ? rect.right : rect.left;
                    size = slider.clientWidth;

                } else if (this.props.orientation === 'vertical') {
                    sliderMax = this.props.invert ? rect.top : rect.bottom;
                    sliderMin = this.props.invert ? rect.bottom : rect.top;
                    size = slider.clientHeight;
                }

                this.setState({
                    upperBound: size /*- handle[size]*/,
                    sliderLength: Math.abs(sliderMax - sliderMin),
                    handleSize: 0/*handle[size]*/,
                    sliderStart: this.props.invert ? sliderMax : sliderMin
                });
            }
        }.bind(this), 0);

        this.pendingResizeTimeouts.push(resizeTimeout);
    }

    // clear all pending timeouts to avoid error messages after unmounting
    _clearPendingResizeTimeouts = () => {
        do {
            var nextTimeout = this.pendingResizeTimeouts.shift();
            clearTimeout(nextTimeout);
        } while (this.pendingResizeTimeouts.length);
    }

    // calculates the offset of a handle in pixels based on its value.
    _calcOffset = (value: number): number => {
        //CHANGED: use steps
        if (this.props.original) {
            return (value - this.props.min) / (this.props.max - this.props.min);
        } else {
            if (typeof this.props.steps[value] != 'undefined') {
                return this.props.steps[value].offset;
            }
            return 0;
        }
    }

    // calculates the value corresponding to a given pixel offset, i.e. the inverse of `_calcOffset`.
    _calcValue = (offset: number): number => {
        //CHANGED: use steps
        if (this.props.original) {
            var ratio = offset / this.state.upperBound;
            return ratio * (this.props.max - this.props.min) + this.props.min;
        } else {
            let index = 0
            const stepCount = this.props.steps.length
            if (stepCount > 0) {
                var diff = Math.abs(offset - this.props.steps[0].offset)
                for (let i = 0; i < stepCount; i++) {
                    let newDiff = Math.abs(offset - this.props.steps[i].offset)
                    if (newDiff < diff) {
                        diff = newDiff
                        index = i
                    }
                }
            }
            return index
        }
    }

    _buildHandleStyle = (offset: number, i: number): CSSProperties => {

        var style: CSSProperties = {
            position: 'absolute',
            zIndex: this.state.zIndices.indexOf(i) + 1
        };

        if (this.props.orientation == 'horizontal') {
            if (this.props.invert) {
                style.willChange = this.state.index >= 0 ? 'right' : '';
                style.right = (offset * 100) + '%';
            } else {
                style.willChange = this.state.index >= 0 ? 'left' : '';
                style.left = (offset * 100) + '%';
            }
        } else if (this.props.orientation == 'vertical') {
            if (this.props.invert) {
                style.willChange = this.state.index >= 0 ? 'bottom' : '';
                style.bottom = (offset * 100) + '%';
            } else {
                style.willChange = this.state.index >= 0 ? 'top' : '';
                style.top = (offset * 100) + '%';
            }
        }

        return style;
    }

    _buildBarStyle = (min: string | number, max: string | number): CSSProperties => {
        // CHANGED: willChange breaks border radius
        var obj: CSSProperties = {
            position: 'absolute',
            // willChange: this.state.index >= 0 ? this._posMinKey() + ',' + this._posMaxKey() : ''
        };

        if (this.props.orientation === 'horizontal') {
            if (this.props.invert) {
                obj.right = min;
                obj.left = max;
            } else {
                obj.left = min;
                obj.right = max;
            }
        } else if (this.props.orientation === 'vertical') {
            if (this.props.invert) {
                obj.bottom = min;
                obj.top = max;
            } else {
                obj.top = min;
                obj.bottom = max;
            }
        }

        return obj;
    }

    _getClosestIndex = (pixelOffset: number): number => {
        var minDist = Number.MAX_VALUE;
        var closestIndex = -1;

        var value = this.state.value;
        var l = value.length;

        for (var i = 0; i < l; i++) {
            var offset = this._calcOffset(value[i]);
            var dist = Math.abs(pixelOffset - offset);
            if (dist < minDist) {
                minDist = dist;
                closestIndex = i;
            }
        }

        return closestIndex;
    }

    _calcOffsetFromPosition = (position: number): number => {
        var pixelOffset = position - this.state.sliderStart;
        if (this.props.invert) pixelOffset = this.state.sliderLength - pixelOffset;
        pixelOffset -= (this.state.handleSize / 2);
        return pixelOffset;
    }

    // Snaps the nearest handle to the value corresponding to `position` and calls `callback` with that handle's index.
    _forceValueFromPosition = (position: number, callback: (i?: number) => void) => {
        var pixelOffset = this._calcOffsetFromPosition(position);
        var closestIndex = this._getClosestIndex(pixelOffset);
        var nextValue = this._trimAlignValue(this._calcValue(pixelOffset));

        var value = this.state.value.slice(); // Clone this.state.value since we'll modify it temporarily
        value[closestIndex] = nextValue;

        // Prevents the slider from shrinking below `props.minDistance`
        for (var i = 0; i < value.length - 1; i += 1) {
            if (value[i + 1] - value[i] < this.props.minDistance) return;
        }

        this.setState({value: value}, callback.bind(this, closestIndex));
    }

    _getMousePosition = (e: MouseEvent): number[] => {
        return [
            this.props.orientation == 'vertical' ? e.pageY : e.pageX,
            this.props.orientation == 'vertical' ? e.pageX : e.pageY,
        ];
    }

    _getTouchPosition = (e: TouchEvent): number[] => {
        var touch = e.touches[0];
        return [
            this.props.orientation == 'vertical' ? touch.pageY : touch.pageX,
            this.props.orientation == 'vertical' ? touch.pageX : touch.pageY,
        ];
    }

    _getMouseEventMap = (): { [index: string]: (e: MouseEvent) => void } => {
        return {
            'mousemove': this._onMouseMove,
            'mouseup': this._onMouseUp
        }
    }

    _getTouchEventMap = (): { [index: string]: (e: TouchEvent) => void } => {
        return {
            'touchmove': this._onTouchMove,
            'touchend': this._onTouchEnd
        }
    }

    // create the `mousedown` handler for the i-th handle
    _createOnMouseDown = (i: number): MouseEventHandler<HTMLElement> => {
        return function (this: Slider, e: MouseEvent) {
            if (this.props.disabled) return;

            // for make sure we use actual slider global position
            this._handleResize();

            var position = this._getMousePosition(e);
            this._start(i, position[0]);
            this._addHandlers(this._getMouseEventMap());
            pauseEvent(e);
        }.bind(this);
    }

    // create the `touchstart` handler for the i-th handle
    _createOnTouchStart = (i: number): TouchEventHandler<HTMLElement> => {
        return function (this: Slider, e: TouchEvent) {
            if (this.props.disabled || e.touches.length > 1) return;

            // for make sure we use actual slider global position
            this._handleResize();

            var position = this._getTouchPosition(e);
            this.startPosition = position;
            this.isScrolling = undefined; // don't know yet if the user is trying to scroll
            this._start(i, position[0]);
            this._addHandlers(this._getTouchEventMap());
            stopPropagation(e);
        }.bind(this);
    }

    _addHandlers = (eventMap: { [index: string]: (e: MouseEvent) => void } | { [index: string]: (e: TouchEvent) => void }) => {
        for (var key in eventMap) {
            // @ts-ignore
            document.addEventListener(key, eventMap[key], false);
        }
    }

    _removeHandlers = (eventMap: { [index: string]: (e: MouseEvent) => void } | { [index: string]: (e: TouchEvent) => void }) => {
        for (var key in eventMap) {
            // @ts-ignore
            document.removeEventListener(key, eventMap[key], false);
        }
    }

    _start = (i: number, position: number) => {
        // if activeElement is body window will lost focus in IE9
        if (document.activeElement && document.activeElement != document.body) {
            // @ts-ignore
            document.activeElement.blur();
        }

        this.hasMoved = false;

        this._fireChangeEvent('onBeforeChange');

        var zIndices = this.state.zIndices;
        zIndices.splice(zIndices.indexOf(i), 1); // remove wherever the element is
        zIndices.push(i); // add to end

        this.setState({
            startValue: this.state.value[i],
            startPosition: position,
            index: i,
            zIndices: zIndices
        });
    }

    _onMouseUp = (e: MouseEvent) => {
        this._onEnd(this._getMouseEventMap());
    }

    _onTouchEnd = (e: TouchEvent) => {
        this._onEnd(this._getTouchEventMap());
    }

    _onEnd = (eventMap: { [index: string]: (e: MouseEvent) => void } | { [index: string]: (e: TouchEvent) => void }) => {
        this._removeHandlers(eventMap);
        this.setState({index: -1}, this._fireChangeEvent.bind(this, 'onAfterChange'));
    }

    _onMouseMove = (e: MouseEvent) => {
        var position = this._getMousePosition(e);
        this._move(position[0]);
    }

    _onTouchMove = (e: TouchEvent) => {
        if (e.touches.length > 1) return;

        var position = this._getTouchPosition(e);

        if (typeof this.isScrolling === 'undefined') {
            var diffMainDir = position[0] - this.startPosition[0];
            var diffScrollDir = position[1] - this.startPosition[1];
            this.isScrolling = Math.abs(diffScrollDir) > Math.abs(diffMainDir);
        }

        if (this.isScrolling) {
            this.setState({index: -1});
            return;
        }

        pauseEvent(e);

        this._move(position[0]);
    }

    _move = (position: number) => {

        this.hasMoved = true;

        var props = this.props;
        var state = this.state;
        var index = state.index;

        var value = state.value;
        var length = value.length;
        var oldValue = value[index];

        //CHANGED: add support for variable step width
        let newValue
        if (this.props.original) {
            var diffPosition = position - state.startPosition;
            if (props.invert) diffPosition *= -1;

            var diffValue = diffPosition / (state.sliderLength - state.handleSize) * (props.max - props.min);
            newValue = this._trimAlignValue(state.startValue + diffValue);
        } else {
            const offset = this._calcOffsetFromPosition(position);
            const relativeOffset = offset / (state.sliderLength - state.handleSize);
            newValue = this._calcValue(relativeOffset)
        }

        var minDistance = props.minDistance;

        // if "pearling" (= handles pushing each other) is disabled,
        // prevent the handle from getting closer than `minDistance` to the previous or next handle.
        if (!props.pearling) {
            if (index > 0) {
                var valueBefore = value[index - 1];
                if (newValue < valueBefore + minDistance) {
                    newValue = valueBefore + minDistance;
                }
            }

            if (index < length - 1) {
                var valueAfter = value[index + 1];
                if (newValue > valueAfter - minDistance) {
                    newValue = valueAfter - minDistance;
                }
            }
        }

        value[index] = newValue;

        // if "pearling" is enabled, let the current handle push the pre- and succeeding handles.
        if (props.pearling && length > 1) {
            if (newValue > oldValue) {
                this._pushSucceeding(value, minDistance, index);
                this._trimSucceeding(length, value, minDistance, props.max);
            } else if (newValue < oldValue) {
                this._pushPreceding(value, minDistance, index);
                this._trimPreceding(length, value, minDistance, props.min);
            }
        }

        // Normally you would use `shouldComponentUpdate`, but since the slider is a low-level component,
        // the extra complexity might be worth the extra performance.
        if (newValue !== oldValue) {
            this.setState({value: value}, this._fireChangeEvent.bind(this, 'onChange'));
        }
    }

    _pushSucceeding = (value: number[], minDistance: number, index: number) => {
        var i, padding;
        for (i = index, padding = value[i] + minDistance;
             value[i + 1] != null && padding > value[i + 1];
             i++, padding = value[i] + minDistance) {
            value[i + 1] = this._alignValue(padding);
        }
    }

    _trimSucceeding = (length: number, nextValue: number[], minDistance: number, max: number) => {
        for (var i = 0; i < length; i++) {
            var padding = max - i * minDistance;
            if (nextValue[length - 1 - i] > padding) {
                nextValue[length - 1 - i] = padding;
            }
        }
    }

    _pushPreceding = (value: number[], minDistance: number, index: number) => {
        var i, padding;
        for (i = index, padding = value[i] - minDistance;
             value[i - 1] != null && padding < value[i - 1];
             i--, padding = value[i] - minDistance) {
            value[i - 1] = this._alignValue(padding);
        }
    }

    _trimPreceding = (length: number, nextValue: number[], minDistance: number, min: number) => {
        for (var i = 0; i < length; i++) {
            var padding = min + i * minDistance;
            if (nextValue[i] < padding) {
                nextValue[i] = padding;
            }
        }
    }

    _posMinKey = () => {
        var orientation = this.props.orientation;
        if (orientation === 'horizontal') return this.props.invert ? 'right' : 'left';
        if (orientation === 'vertical') return this.props.invert ? 'bottom' : 'top';
    }

    _posMaxKey = () => {
        var orientation = this.props.orientation;
        if (orientation === 'horizontal') return this.props.invert ? 'left' : 'right';
        if (orientation === 'vertical') return this.props.invert ? 'top' : 'bottom';
    }

    _sizeKey = () => {
        var orientation = this.props.orientation;
        if (orientation === 'horizontal') return 'clientWidth';
        if (orientation === 'vertical') return 'clientHeight';
    }

    _trimAlignValue = (val: number, props?: SliderProps): number => {
        return this._alignValue(this._trimValue(val, props), props);
    }

    _trimValue = (val: number, props?: SliderProps): number => {
        props = props || this.props;

        if (val <= props.min) val = props.min;
        if (val >= props.max) val = props.max;

        return val;
    }

    _alignValue = (val: number, props?: SliderProps): number => {
        props = props || this.props;

        var valModStep = (val - props.min) % props.step;
        var alignValue = val - valModStep;

        if (Math.abs(valModStep) * 2 >= props.step) {
            alignValue += (valModStep > 0) ? props.step : (-props.step);
        }

        return parseFloat(alignValue.toFixed(5));
    }

    _renderHandle = (style: CSSProperties, child: ReactNode, i: number) => {
        var className = this.props.handleClassName + ' ' +
            (this.props.handleClassName + '-' + i) + ' ' +
            (this.state.index === i ? this.props.handleActiveClassName : '');

        return <div ref={"handle" + i}
                    key={"handle" + i}
                    className={className}
                    style={style}
                    onMouseDown={this._createOnMouseDown(i)}
                    onTouchStart={this._createOnTouchStart(i)}>
            {child}
        </div>;
    }

    _renderHandles = (offset: number[]) => {
        var length = offset.length;

        var styles: CSSProperties[] = this.tempArray;
        for (var i = 0; i < length; i++) {
            styles[i] = this._buildHandleStyle(offset[i], i);
        }

        var res: JSX.Element[] = [];
        var renderHandle = this._renderHandle;
        if (React.Children.count(this.props.children) > 0) {
            React.Children.forEach(this.props.children, function (child: ReactNode, i) {
                res[i] = renderHandle(styles[i], child, i);
            });
        } else {
            for (i = 0; i < length; i++) {
                res[i] = renderHandle(styles[i], null, i);
            }
        }
        return res;
    }

    _renderBar = (i: number, offsetFrom: number, offsetTo: number): JSX.Element => {
        return <div ref={"bar" + i}
                    key={"bar" + i}
                    style={this._buildBarStyle(offsetFrom, this.state.upperBound - offsetTo)}
                    className={this.props.barClassName + ' ' + this.props.barClassName + '-' + i}></div>;
    }

    _renderBars = (offset: number[]): JSX.Element[] => {
        var bars = [];
        var lastIndex = offset.length - 1;

        bars.push(this._renderBar(0, 0, offset[0]));

        for (var i = 0; i < lastIndex; i++) {
            bars.push(this._renderBar(i + 1, offset[i], offset[i + 1]));
        }

        bars.push(this._renderBar(lastIndex + 1, offset[lastIndex], this.state.upperBound));

        return bars;
    }

    _onSliderMouseDown = (e: MouseEvent) => {
        if (this.props.disabled) return;
        this.hasMoved = false;
        if (!this.props.snapDragDisabled) {
            var position = this._getMousePosition(e);
            this._forceValueFromPosition(position[0], function (this: Slider, i?: number) {
                this._fireChangeEvent('onChange');
                this._start(i || 0, position[0]);
                this._addHandlers(this._getMouseEventMap());
            }.bind(this));
        }

        pauseEvent(e);
    }

    _onSliderClick = (e: MouseEvent) => {
        if (this.props.disabled) return;

        if (this.props.onSliderClick && !this.hasMoved) {
            var position = this._getMousePosition(e);
            var valueAtPos = this._trimAlignValue(this._calcValue(this._calcOffsetFromPosition(position[0])));
            this.props.onSliderClick(valueAtPos);
        }
    }

    _fireChangeEvent = (event: 'onBeforeChange' | 'onChange' | 'onAfterChange') => {
        if (event == 'onBeforeChange') {
            if (this.props.onBeforeChange) {
                this.props.onBeforeChange(undoEnsureArray(this.state.value));
            }
        } else if (event == 'onChange') {
            if (this.props.onChange) {
                this.props.onChange(undoEnsureArray(this.state.value));
            }
        } else if (event == 'onAfterChange') {
            if (this.props.onAfterChange) {
                this.props.onAfterChange(undoEnsureArray(this.state.value));
            }
        }
    }

    render() {
        var state = this.state;
        var props = this.props;
        var offset = this.tempArray;
        var value = state.value;
        var l = value.length;
        for (var i = 0; i < l; i++) {
            offset[i] = this._calcOffset(value[i]);
        }

        var bars = props.withBars ? this._renderBars(offset) : null;
        var handles = this._renderHandles(offset);

        return <div ref={this._setRefSlider}
                    style={{position: 'relative'}}
                    className={props.className + (props.disabled ? ' disabled' : '')}
                    onMouseDown={this._onSliderMouseDown}
                    onClick={this._onSliderClick}>
            {bars}{handles}
        </div>;
    }
}

export default Slider;