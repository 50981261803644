import React from "react";
import {connect, DispatchProp, useDispatch, useSelector} from 'react-redux';
import {useTranslation, WithTranslation, withTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {Dialog} from "@headlessui/react";
import {triggerUserLoginRecover} from "../../../Store/Action/session.login";
import {triggerRecoverySendFlag} from "../../../Store/Action/interactive";

/** @type TranslationKeys, keep this variable without TS type, for keep type check magic works*/
const ComponentTranslationKey = "TopBar"            // this is placeholder key. replace

export default function ModalPasswordReset(props: {
    isModalOpen: boolean
    onModalClose: () => void
    onSignInClick: () => void
    onSignUpClick: () => void
}) {

    // globals
    const dispatch = useDispatch()
    const {t} = useTranslation(ComponentTranslationKey)
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key)
    }

    const interactiveEmailSend = useSelector((state: IAppState) => state.interactive.emailRecoverySend)

    const onPasswordRecoverySubmit = function (e: React.SyntheticEvent) {
        e.preventDefault();

        const target = e.target as typeof e.target & {
            email: { value: string };
        };

        const email = target.email.value;           // do typecheck?

        dispatch(triggerUserLoginRecover(email));
    }

    const onPasswordRecoveryBack = function () {
        dispatch(triggerRecoverySendFlag(false));    // remove a message on next time modal open
        props.onSignInClick();
    }

    return <Dialog as="div" className="relative z-50"
                   open={props.isModalOpen}
                   onClose={props.onModalClose}>
        <div className="modal-shade-overlay"/>
        <div className="modal-wrapper-space">
            <div className="modal-wrapper-vertical-align text-center">

                <Dialog.Panel className="modal-password-recovery-wrapper">

                    <div className="flex-1 p-6 modal-password-recovery-image-bg relative">
                        <div className="absolute top-0 right-0 left-0 bottom-0 bg-shopit-green/50">
                            <div className="p-6">
                                <div className="text-2xl text-white font-bold mt-[8.5rem] tracking-wide">
                                    Back in a moment!
                                </div>
                                <div className="text-lg text-white italic mt-2">
                                    Nice you see you with the Shopit
                                </div>
                            </div>
                        </div>
                        <div className="absolute left-6 bottom-4 text-gray-700">
                            <a href="https://unsplash.com/@khloephoto" target="_blank">Photo by Khloe Arledge</a>
                        </div>
                    </div>

                    <div className="flex-1 p-6 flex items-center relative">
                        <div className={`absolute top-4 right-4 cursor-pointer`} onClick={props.onModalClose}>
                            <img src={`/resources/close-icon.png`} alt={`Close`}/>
                        </div>

                        <div className="w-full">
                            <Dialog.Title as="h3" className="text-xl font-medium text-gray-900">
                                Password Recovery
                            </Dialog.Title>

                            <div className="text-base mt-8">
                                {interactiveEmailSend ? (
                                    <div>

                                        <div className="">Recovery email was send</div>

                                        <div className="mt-2 flex justify-between items-baseline">
                                            <div className="text-sm text-shopit-green cursor-pointer"
                                                 onClick={onPasswordRecoveryBack}>Sign in
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <form onSubmit={onPasswordRecoverySubmit}>
                                        <div>
                                            <div className="text-sm">E-mail</div>
                                            <input type="email" name="email" placeholder="name@company.com"
                                                   className="p-1 mt-1 w-full border focus:ring-shopit-green focus:border-shopit-green focus:ring-1 focus:outline-none"/>
                                        </div>

                                        <div className="mt-8">
                                            <button className="btn btn-green text-sm w-full py-2"
                                                    type="submit">Recovery
                                                Password
                                            </button>
                                        </div>

                                        <div className="mt-2 flex justify-between items-baseline">
                                            <div className="text-sm text-shopit-green cursor-pointer"
                                                 onClick={props.onSignInClick}>Sign in
                                            </div>
                                            <div className="text-sm text-shopit-green cursor-pointer"
                                                 onClick={props.onSignUpClick}>Sign up
                                            </div>
                                        </div>
                                    </form>
                                )}

                            </div>
                        </div>
                    </div>

                </Dialog.Panel>
            </div>
        </div>
    </Dialog>
}
