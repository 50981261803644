import React, {useEffect} from "react";
import {ProductImage} from "../../App/Common/Image";
import {Filters} from "../../ProductList/Filters";
import SearchRouteParamLink from "../../App/Links/SearchRouteParamLink";
import {formatNumber} from "../../../Helpers/StringFormatter.Number";

type CollectionCardProps = {
    category: {
        id: number,
        name: string,
        path: Array<{ id: number, name: string }>,
        exampleImages: { image_id: number }[],
        count: number,
        countSecondary: number,
    },
    link: {
        urlParams: IUrlResolvedParams,
    },
    appearance: {
        showPath: boolean,
        filters: IProductListSearch_Filter[],
    },
    className?: string,
    context: {
        lang: string,
        debug: boolean
    }
};

/** @see CollectionCardDemo */
export default function CollectionCard(props: CollectionCardProps) {

    return (
        <div className={`category-item-card category-item-card-category ${props.className}`}>

            <SearchRouteParamLink
                urlPageParams={props.link.urlParams}
                className="card-section-rigid p-2">
                <div className="card-section-image">
                    {props.category.exampleImages.length == 1
                        ? <ProductImage imageId={props.category.exampleImages[0].image_id}
                                        alt={props.category.name}
                                        size="m"
                                        imageClass={`image-aspect-ratio-square mix-blend-darken`}/>
                        :
                        // test for only one image always
                        <ProductImage
                            imageId={props.category.exampleImages.length > 0 ? props.category.exampleImages[0].image_id : 0}
                            alt={props.category.name}
                            size="m"
                            imageClass={`image-aspect-ratio-square mix-blend-darken`}/>
                        // <FourProductImages imageIds={props.category.exampleImages.map((i) => i.image_id)}
                        //                      size="m" squareSize={true}
                        //                      imageSize="s" imageClass="image-aspect-ratio-square mix-blend-darken"/>
                    }
                </div>
            </SearchRouteParamLink>

            <SearchRouteParamLink
                urlPageParams={props.link.urlParams}
                className="card-section-rigid flex flex-col">
                <div className="px-4 flex-none text-gray-500 text-center">
                    {props.appearance.filters.length
                        ? <Filters filters={props.appearance.filters}/>
                        : null}
                    <span className={`text-gray-400`}>
                        ({props.category.count ? formatNumber(props.category.count, 0) : '0'}{props.context.debug && props.category.countSecondary ? ' / ' + formatNumber(props.category.countSecondary, 0) : ''})
                    </span>
                </div>
                <div className="px-4 flex-none font-bold text-shopit-green text-center italic">
                    {props.category.name}
                </div>
            </SearchRouteParamLink>

            {props.appearance.showPath
                ? <div className="card-section-stretch px-4 text-gray-500 pb-2 text-center">
                    {props.category.path.slice(-3, -1).map((i, n) => {
                        if (n > 1) return undefined;
                        return <div className="inline-block" key={`p` + n}>
                            {n != 0 ? (<span className="px-space">»</span>) : undefined}
                            <SearchRouteParamLink
                                urlPageParams={{
                                    bridgeParams: {
                                        categoryId: i.id,
                                        fold: undefined,
                                        foldedBrands: undefined,
                                        color: undefined,
                                        expand: undefined,
                                        expandedBrands: undefined,
                                        filters: undefined,
                                        page: undefined,
                                        language: undefined,
                                        size: undefined,
                                        secondaryExpand: undefined,
                                        showSecondary: undefined,
                                        text: undefined,
                                        code: undefined,
                                        group: undefined,
                                        sort: undefined,
                                    },
                                    showFilters: undefined,
                                    openFilterOption: undefined,
                                    openUnpOptions: undefined
                                }}
                                className="text-shopit-theme-darker-grey">{i.name}</SearchRouteParamLink>
                        </div>
                    })}</div>
                : null}
        </div>
    );
}

