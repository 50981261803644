import React, {useEffect} from "react";
import {connect, DispatchProp, useDispatch, useSelector} from 'react-redux';
import {
    triggerLoadProductsComparePreview,
    triggerLoadProductsLikePreview,
    triggerLoadProductsOwnPreview
} from "../../../Store/Action/session.product";
import {getDefaultLang} from "../../../Store/Action/interactive";
import {useTranslation, WithTranslation, withTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {ProductImage} from "../../App/Common/Image";
import {getCompareProductIds} from "../../../Helpers/LocalStorageHelper";

type ProductBoardProps = {
    productIds: number[],
    listType: "like" | "own" | "compare",
    list: IProductList_ItemWrapper[] | undefined,
    lang: string
} & DispatchProp & WithTranslation;

const ComponentTranslationKey = "User.Page.Boards"

export default function ProductBoard(props: {
    productIds: number[],
    listType: "like" | "own" | "compare"
}) {

    // globals
    const dispatch = useDispatch()
    const {t} = useTranslation(ComponentTranslationKey)
    const getTranslation = function (key: keyof TranslationSet["User"]["Page"]["Boards"]) {
        return t('User:Page:Boards:' + key)
    }

    const propsExtend = useSelector((state: IAppState) => {
        const list =
            props.listType == "like"
                ? state.cache.personalProducts.likeProductsPreview.object
                : props.listType == "own"
                    ? state.cache.personalProducts.ownProductsPreview.object
                    : state.cache.personalProducts.compareProductsPreview.object;

        const listState =
            props.listType == "like"
                ? state.cache.personalProducts.likeProductsPreview.cacheState
                : props.listType == "own"
                    ? state.cache.personalProducts.ownProductsPreview.cacheState
                    : state.cache.personalProducts.compareProductsPreview.cacheState;

        const listCache =
            props.listType == "like"
                ? state.cache.personalProducts.likeProductsKey
                : props.listType == "own"
                    ? state.cache.personalProducts.ownProductsKey
                    : state.cache.personalProducts.compareProductsKey;

        return {
            listType: props.listType,
            listState: listCache == props.productIds.join(',') ?listState : CacheState.Unknown,

            list: listCache == props.productIds.join(',') ? list : undefined,
            productIds: props.productIds,
            lang: state.config.lang ? state.config.lang : getDefaultLang()
        }
    })

    const propsHash = propsExtend.list === undefined && propsExtend.productIds.length > 0 ? propsExtend.productIds.join(',') : '';
    useEffect(() => {
        if (propsExtend.list === undefined && propsExtend.productIds.length > 0 && propsExtend.listState == CacheState.Unknown) {
            if (propsExtend.listType == "like") {
                dispatch(triggerLoadProductsLikePreview(propsExtend.productIds, propsExtend.lang));
            } else if (propsExtend.listType == "own") {
                dispatch(triggerLoadProductsOwnPreview(propsExtend.productIds, propsExtend.lang));
            } else if (propsExtend.listType == "compare") {
                dispatch(triggerLoadProductsComparePreview(propsExtend.productIds, propsExtend.lang));
            }
        }
    }, [propsHash]);

    let imagePreviews: number[] = [];
    let productsCount = 0;
    if (propsExtend.list) {
        for (let i = 0; i < propsExtend.list.length; i++) {
            switch (propsExtend.list[i].itemType) {
                case IProductList_ItemType.Product: {
                    const productCardData = propsExtend.list[i] as IProductListItem_Product;
                    const productImage = productCardData.product.image_id;
                    imagePreviews.push(productImage);
                    productsCount++;
                    break;
                }
            }
        }
    }

    return (
        <div className={`product-board`}>
            <div className="product-board-title">
                <div>
                    {propsExtend.listType == "like" ? <div className={`icon-like`}></div> : null}
                    {propsExtend.listType == "own" ? <div className={`icon-own`}></div> : null}
                    {propsExtend.listType == "compare" ? <div className={`icon-compare`}></div> : null}
                </div>
                <div>
                    {propsExtend.listType == "like" ? getTranslation('my_liked_products') : null}
                    {propsExtend.listType == "own" ? getTranslation('my_owned_products') : null}
                    {propsExtend.listType == "compare" ? getTranslation('my_compare_products') : null}
                </div>
                <div>
                    <div className={`icon-private-lock`}></div>
                </div>
            </div>
            {propsExtend.listState == CacheState.Loading
                ? <div>Loading...</div>
                : null}
            <div>
                {imagePreviews.length > 0 ? imagePreviews.slice(0, 1).map((imageId: number, index: number) =>
                    <div key={index} className={`h-image-height-l`}>
                        <ProductImage size={'l'} alt={`Product preview`} imageId={imageId}/>
                    </div>
                ) : null}
            </div>
            <div className="flex w-full mt-2 gap-5">
                {imagePreviews.length > 0 ? imagePreviews.slice(1, 4).map((imageId: number, index: number) =>
                    <div key={index} className={`flex-none`}>
                        <ProductImage size={'xs'} alt={`Product preview`} imageId={imageId}/>
                    </div>
                ) : null}
            </div>
        </div>
    );
}
