import React from "react";
import FilterAttribute from "../../ProductList_FiltersPanel/FilterAttribute";
import FilterAttributeGroup from "../../ProductList_FiltersPanel/FilterAttributeGroup";
import FilterAttributeSection from "../../ProductList_FiltersPanel/FilterAttributeSection";
import FilterBranding from "../../ProductList_FiltersPanel/FilterBranding";
import FilterColor from "../../ProductList_FiltersPanel/FilterColor";
import FilterManufacturer from "../../ProductList_FiltersPanel/FilterManufacturer";
import FilterNumeric from "../../ProductList_FiltersPanel/FilterNumeric";
import FilterStatic from "../../ProductList_FiltersPanel/FilterStatic";


const attributeExample: IFilterPanel_Attribute = {
    hasChecked: true,
    objectType: IFilterPanel_ObjectType.Attribute,
    type: "example_type",
    id: "0",
    name: "Attribute Filter",
    filterVar: 'a1',
    filterValues: [
        {
            objectType: IFilterPanel_ObjectType.Option,
            id: "0",
            name: "Example Option 1",
            filterVar: 'a1',
            count: 30,
            countTrue: 20,
            countFalse: 10,
            primaryCount: 20,
            primaryCountTrue: 20,
            primaryCountFalse: 20,
            primaryCountTotal: 20,
            attributeId: "0",
            attributeType: "example_type",
            checked: true,
            structures: [],
            hasChecked: false,
        } as IFilterPanel_Option,
        {
            hasChecked: false,
            objectType: IFilterPanel_ObjectType.Option,
            id: "0",
            name: "Example Option 2",
            filterVar: 'a1',
            count: 30,
            countTrue: 20,
            countFalse: 10,
            primaryCount: 20,
            primaryCountTrue: 20,
            primaryCountFalse: 20,
            primaryCountTotal: 20,
            attributeId: "0",
            attributeType: "example_type",
            checked: false,
            structures: []
        } as IFilterPanel_Option]
}

/** @see FilterAttribute */
export function FilterAttributeDemo() {
    return <FilterAttribute
        demonstrationMode={true}
        level={1}
        attribute={attributeExample}/>
}

/** @see FilterAttributeGroup */
export function FilterAttributeGroupDemo() {
    return <FilterAttributeGroup
        demonstrationMode={true}
        level={1}
        group={{
            hasChecked: true,
            objectType: IFilterPanel_ObjectType.AttributeGroup,
            name: "Attribute Group",
            id: "0",
            structures: [attributeExample]
        }}/>
}

/** @see FilterAttributeSection */
export function FilterAttributeSectionDemo() {
    return <FilterAttributeSection
        demonstrationMode={true}
        level={1}
        section={{
            hasChecked: true,
            objectType: IFilterPanel_ObjectType.Section,
            id: 0,
            structures: [attributeExample],
            name: "Attribute Section",
            defaultFold: false
        }}/>
}

/** @see FilterBranding */
export function FilterBrandingDemo() {
    return <FilterBranding
        demonstrationMode={true}
        level={1}
        branding={{
            hasChecked: false,
            objectType: IFilterPanel_ObjectType.Branding,
            filterVar: "s3",
            filterValues: [
                {
                    id: 0,
                    name: "Example Brand",
                    checked: false,
                    count: 30,
                    primaryCount: 20,
                    countTotal: 30,
                    primaryCountTotal: 20
                },
                {
                    id: 0,
                    name: "Example Brand 2",
                    checked: false,
                    count: 30,
                    primaryCount: 20,
                    countTotal: 30,
                    primaryCountTotal: 20
                },
                {
                    id: 0,
                    name: "Example Brand 3",
                    checked: false,
                    count: 30,
                    primaryCount: 20,
                    countTotal: 30,
                    primaryCountTotal: 20
                }]
        }}/>
}

/** @see FilterColor */
export function FilterColorDemo() {
    return <FilterColor
        color={{
            objectType: IFilterPanel_ObjectType.Color,
            hasChecked: false
        }}
        demonstrationMode={true}/>
}

/** @see FilterStatic */
export function FilterStaticDemo() {
    return <FilterStatic
        demonstrationMode={true}
        static={{
            hasChecked: false,
            objectType: IFilterPanel_ObjectType.Static,
            filterValues: [attributeExample],
            type: "static",
        }}/>
}

/** @see FilterManufacturer */
export function FilterManufacturerDemo() {
    return <FilterManufacturer
        demonstrationMode={true}
        level={1}
        manufacturer={{
            hasChecked: false,
            objectType: IFilterPanel_ObjectType.Manufacturer,
            filterVar: "s4",
            filterValues: [
                {
                    id: 0,
                    name: "Example Manufacturer",
                    checked: false,
                    count: 30,
                    primaryCount: 20,
                    countTotal: 30,
                    primaryCountTotal: 20
                },
                {
                    id: 0,
                    name: "Example Manufacturer 2",
                    checked: false,
                    count: 30,
                    primaryCount: 20,
                    countTotal: 30,
                    primaryCountTotal: 20
                },
                {
                    id: 0,
                    name: "Example Manufacturer 3",
                    checked: false,
                    count: 30,
                    primaryCount: 20,
                    countTotal: 30,
                    primaryCountTotal: 20
                }]
        }}/>
}

/** @see FilterNumeric */
export function FilterNumericDemo() {
    return <FilterNumeric
        demonstrationMode={true}
        level={1}
        numeric={{
            hasChecked: false,
            objectType: IFilterPanel_ObjectType.Numeric,
            name: "Numeric Attribute",
            nameDebug: "",
            filterValues: [],
            filterVar: "a1",
            id: "0",
            decimals: 2,
            isFixed: false,
            type: "numeric_range",
            slider: {
                dataStart: 0,
                dataEnd: 10,
                selectedMin: 0,
                selectedMax: 10,
                steps: [
                    {value: 2, offset: 0},
                    {value: 3, offset: 0.1},
                    {value: 2, offset: 0.2},
                    {value: 5, offset: 0.3},
                    {value: 4, offset: 0.4},
                    {value: 6, offset: 0.5},
                    {value: 4, offset: 0.6},
                    {value: 3, offset: 0.7},
                    {value: 1, offset: 0.8},
                    {value: 1, offset: 0.9},
                ],
                ticks: [
                    {value: 1, valuePresentation: "1.0", offset: 0, width: 1},
                    {value: 2, valuePresentation: "2.0", offset: 0.1, width: 1},
                    {value: 3, valuePresentation: "3.0", offset: 0.2, width: 1},
                    {value: 4, valuePresentation: "4.0", offset: 0.3, width: 1},
                    {value: 5, valuePresentation: "5.0", offset: 0.4, width: 1},
                    {value: 6, valuePresentation: "6.0", offset: 0.5, width: 1},
                    {value: 7, valuePresentation: "7.0", offset: 0.6, width: 1},
                    {value: 8, valuePresentation: "8.0", offset: 0.7, width: 1},
                    {value: 9, valuePresentation: "9.0", offset: 0.8, width: 1},
                    {value: 10, valuePresentation: "10.0", offset: 0.9, width: 1},
                ],
                histograms: [
                    {offset: 0, width: 0.1, primary: 2, secondary: 2, coverage: [0]},
                    {offset: 0.1, width: 0.1, primary: 3, secondary: 2, coverage: [1]},
                    {offset: 0.2, width: 0.1, primary: 2, secondary: 2, coverage: [2]},
                    {offset: 0.3, width: 0.1, primary: 5, secondary: 2, coverage: [3]},
                    {offset: 0.4, width: 0.1, primary: 4, secondary: 2, coverage: [4]},
                    {offset: 0.5, width: 0.1, primary: 6, secondary: 2, coverage: [5]},
                    {offset: 0.6, width: 0.1, primary: 5, secondary: 2, coverage: [6]},
                    {offset: 0.7, width: 0.1, primary: 3, secondary: 2, coverage: [7]},
                    {offset: 0.8, width: 0.1, primary: 1, secondary: 2, coverage: [8]},
                    {offset: 0.9, width: 0.1, primary: 1, secondary: 2, coverage: [9]},
                ]
            },
            unit: "unit"
        }}/>
}
