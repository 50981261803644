import React, {
    ChangeEventHandler,
    ClipboardEventHandler,
    FocusEventHandler,
    MouseEventHandler,
    useContext,
    useState
} from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectSearchCache} from "../../../Store/StoreHelper";
import {
    convertSearchParamsToUrlResolveParams, getUrlFromPageParams,
    toggleSearchParamsFamilyOrBrandFilter
} from "../../../Helpers/SearchHelper";
import {triggerCacheAccessSearchWithCallback} from "../../../Store/Action/cache.search";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {getDefaultLang, triggerDebug} from "../../../Store/Action/interactive";
import {SearchRouteContext} from "../../../Store/Context/SearchRouteContext";
import {formatNumber} from "../../../Helpers/StringFormatter.Number";
import {buildSearchUrl} from "../../../Helpers/UrlFormatter.Search";

const ComponentTranslationKey = "FilterStaticManufacturer"

export default function FilterManufacturer(props: {
    manufacturer: IFilterPanel_Manufacturer,
    level: number,
    demonstrationMode: boolean,
}) {

    // globals
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const searchContext = useContext(SearchRouteContext);

    const {t} = useTranslation(ComponentTranslationKey)
    const urlContext = useSelector((state: IAppState) => state.urlContext)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    // internal state
    const [state, stateChange] = useState({
        optionsExpand: false,
        inputAutocompleteShow: false,
        inputAutocompleteText: ""
    })

    // extract global state
    const searchData = useSelector((state: IAppState) => selectSearchCache(state, searchContext.cacheKey))
    const searchQuery = searchData && searchData.object ? searchData.object.searchQuery : undefined
    const isDebugEnabled = useSelector((state: IAppState) => state.config.debug)

    // render
    const expandOptions: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        stateChange({...state, optionsExpand: true});
    }

    const compressOptions: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        stateChange({...state, optionsExpand: false});
    }

    const onSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        stateChange({
            ...state,
            inputAutocompleteShow: true,
            inputAutocompleteText: e.target.value.toLowerCase()
        });
    }

    const onSearchPaste: ClipboardEventHandler<HTMLInputElement> = (e) => {
        stateChange({
            ...state,
            inputAutocompleteShow: true,
            inputAutocompleteText: e.currentTarget.value.toLowerCase()
        });
    }

    const onSearchFocus: ChangeEventHandler<HTMLInputElement> = (e) => {
        stateChange({
            ...state,
            inputAutocompleteShow: true,
            inputAutocompleteText: e.target.value.toLowerCase()
        });
    }

    const onSearchBlur: FocusEventHandler<HTMLInputElement> = (e) => {
        setTimeout(function (stateChange) {
            stateChange({...state, inputAutocompleteShow: false});
        }, 150, stateChange)
    }

    const toggleFilter = (brandId: number) => {

        if (props.demonstrationMode)
            return;

        const currentFilters: IProductListSearch_Filter[] = searchQuery && searchQuery.filter ? [...searchQuery.filter] : [];

        const urlPageParams = convertSearchParamsToUrlResolveParams({
            ...searchQuery,
            filter: toggleSearchParamsFamilyOrBrandFilter(currentFilters, IProductListSearch_FilterType.Manufacturer, brandId),
        })

        dispatch(triggerCacheAccessSearchWithCallback(urlPageParams, false, isDebugEnabled, () => {
                const [toPathName, toSearch, isValidLink] = buildSearchUrl(urlPageParams, urlContext)
                if (!isValidLink) {
                    console.log("Search link error");
                    console.log(toPathName, toSearch, urlPageParams, urlContext)
                    const trace = new Error().stack
                    console.log(trace)
                    dispatch(triggerDebug("Search link error (console)", trace))
                }

                navigate({
                    pathname: toPathName,
                    search: toSearch
                })
            }
        ))

        stateChange({...state, inputAutocompleteShow: false});
    }

    const renderItem = (brand: IFilterPanel_BrandFilterValue, currentFilters: IProductListSearch_Filter[]) => {
        return <div className={`filter-panel-brand-name-count-pair ${brand.checked ? 'checked' : ''}`}>
            <div className={`filter-panel-brand-name-align`}>

                <div className={`filter-panel-brand-header`}
                     onClick={() => {
                         toggleFilter(brand.id)
                     }}>

                    <div>
                        <div className={`filter-panel-brand-checkbox`}>
                            {brand.checked
                                ? <img className="inline-block"
                                       src="/resources/boolean-yes.png"
                                       alt={`Selected Manufacturer`}></img>
                                : null}
                        </div>
                    </div>

                    <div>{brand.name}</div>
                </div>
            </div>

            <div className={`filter-panel-primary-secondary-count-align`}>
                <span
                    className={`filter-panel-primary-count`}>{formatNumber(brand.primaryCount, 0, 'sv', undefined, ' ')}</span>
                {isDebugEnabled
                    ? <span
                        className={`filter-panel-secondary-count`}>({formatNumber(brand.count - brand.primaryCount, 0, 'sv', undefined, ' ')})</span>
                    : <span className={`filter-panel-secondary-count-placeholder`}></span>}
            </div>
        </div>
    }

    const manufacturer = props.manufacturer;
    if (!manufacturer) return null;

    const actualOptions = manufacturer.filterValues.length <= 15 || state.optionsExpand
        ? manufacturer.filterValues
        : manufacturer.filterValues.filter((m: IFilterPanel_BrandFilterValue, i: number) => i < 15 || m.checked)

    const autocompleteOptions = state.inputAutocompleteShow && state.inputAutocompleteText.length > 0
        ? manufacturer.filterValues
            .filter((i: IFilterPanel_BrandFilterValue) => i.name.toLowerCase().indexOf(state.inputAutocompleteText) !== -1)
            .sort((a: IFilterPanel_BrandFilterValue, b: IFilterPanel_BrandFilterValue) => b.primaryCount - a.primaryCount)
            .slice(0, 15)
            .sort((a: IFilterPanel_BrandFilterValue, b: IFilterPanel_BrandFilterValue) => a.name.localeCompare(b.name))
        : []

    const currentFilters: IProductListSearch_Filter[] = searchQuery && searchQuery.filter ? [...searchQuery.filter] : [];

    const showExpand = manufacturer.filterValues.length > actualOptions.length && !state.optionsExpand
    const showCompress = manufacturer.filterValues.length > actualOptions.length && state.optionsExpand

    return (
        <div className={`filter-panel-attribute-spacing`}>
            {manufacturer.filterValues.length > 10
                ? <div className={`filter-panel-brand-search-block`}>
                    <input className={`filter-panel-brand-search-input`}
                           onChange={onSearchChange}
                           onPaste={onSearchPaste}
                           onFocus={onSearchFocus}
                           onBlur={onSearchBlur}
                           placeholder={getTranslation('search')}/>

                    {state.inputAutocompleteShow && state.inputAutocompleteText.length > 0 && autocompleteOptions.length == 0
                        ? <div className={`filter-panel-brand-search-no-result-wrapper`}>
                            <div className={`filter-panel-brand-search-no-result`}>
                                <div>{getTranslation('no_result')}</div>
                            </div>
                        </div>
                        : null}

                    {state.inputAutocompleteShow && autocompleteOptions.length > 0
                        ? <div className={`filter-panel-brand-search-result-wrapper`}>
                            <div className={`filter-panel-brand-search-result`}>
                                {autocompleteOptions.map((brand, key) => (
                                    <div key={`manu-${key}`} className={`filter-panel-brand-option`}>
                                        {renderItem(brand, currentFilters)}
                                    </div>
                                ))}
                            </div>
                        </div>
                        : null}
                </div>
                : null}

            <div className={`filter-panel-brand-options`}>
                {actualOptions.map((brand, key) => (
                    <div key={`manu-${key}`} className={`filter-panel-brand-option`}>
                        {renderItem(brand, currentFilters)}
                    </div>
                ))}

                {showExpand
                    ? <div className="filter-panel-toggle-options"
                           onClick={expandOptions}>
                        {getTranslation('show')}{' '}{manufacturer.filterValues.length - actualOptions.length}{' '}{getTranslation('more_options')}
                    </div>
                    : null}
                {showCompress
                    ? <div className="filter-panel-toggle-options"
                           onClick={compressOptions}>{getTranslation('show_less_options')}</div>
                    : null}
            </div>
        </div>
    );
}