import React from "react";
import {DomainImage} from "../../App/Common/Image";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {useTranslation} from "react-i18next";

type BrandingCardProps = {
    name: string
    imageId: number | undefined,
    className?: string,
};

const ComponentTranslationKey = "Item"

/** @see BrandingCardDemo */

export default function BrandingCard(props: BrandingCardProps) {
    // globals
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    return (
        <div className={`category-item-card category-item-card-branding ${props.className}`}>
            <div className="card-section-rigid p-2 text-gray-500">
                <div className="flex justify-between text-branding-blue">
                    <div>{props.name}</div>
                    <div>{getTranslation('branding')}</div>
                </div>
            </div>

            <div className="card-section-rigid p-2">
                <div className="card-section-image">
                    <DomainImage size="m" alt={props.name} domainId={props.imageId}/>
                </div>
            </div>

            <div className="card-section-stretch px-4">
            </div>
        </div>
    );
}