export const timeToNow = function (date: string) {
    const startTime = Date.parse(date)
    const endTime = Date.now()
    const diff = Math.floor((endTime - startTime) / 1000);

    return {
        days: Math.floor(diff / 3600 / 24),
        hours: Math.floor(diff / 3600) % 24,
        minutes: Math.floor(diff / 60) % 60,
        seconds: diff % 60
    }
}

export const formatTimeToNow = function (date: string) {
    const diff = timeToNow(date);
    return (
        (diff.days > 0 ? diff.days + 'd ' : '') +
        (diff.hours > 0 ? diff.hours + 'h ' : '0h ') +
        (diff.minutes > 0 ? diff.minutes + 'm ' : '0m '));
}