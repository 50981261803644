import React, {MouseEventHandler} from "react";
import {triggerErrorClose} from "../../../Store/Action/interactive";
import {useDispatch} from "react-redux";

function messageToHtml(text: string): React.JSX.Element[] {
    return text.split("\n").map((part, index) => (<div key={`m-${index}`}>{part}</div>))
}

export function convertApiMessageToNotificationMessage(e: IApiResponseError | IApiResponseMessage, key: string): INotificationMessage {

    if ("name" in e) {
        return {
            type: INotificationMessage_Type.Error,
            key: key,
            code: e.code,
            title: e.name,
            message: e.message
        }
    }

    return {
        type: INotificationMessage_Type.Information,
        key: key,
        code: e.code,
        title: undefined,
        message: e.message
    }
}

export function NotificationMessage(props: { message: INotificationMessage }) {
    const message = props.message
    const dispatch = useDispatch()

    const onErrorClose: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        dispatch(triggerErrorClose(e.currentTarget.dataset['errorKey'] as string));
    }

    return <div className="notification-global flex mb-2">
        <div className="flex-none">
            {(
                message.type == INotificationMessage_Type.Error ?
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                         strokeWidth={1.5} stroke="currentColor"
                         className="w-6 h-6 stroke-red-600">
                        <path strokeLinecap="round" strokeLinejoin="round"
                              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                    </svg> :
                    message.type == INotificationMessage_Type.Warning ?
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                             strokeWidth={1.5} stroke="currentColor"
                             className="w-6 h-6 stroke-orange-600">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                  d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"/>
                        </svg> :
                        message.type == INotificationMessage_Type.Information ?
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                 viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor"
                                 className="w-6 h-6 stroke-neutral-400">
                                <path strokeLinecap="round" strokeLinejoin="round"
                                      d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"/>
                            </svg> :
                            null
            )}
        </div>
        <div className="flex-1 pl-4">
            <div className="text-lg font-medium">
                {(
                    message.title
                        ? message.title
                        : message.type == INotificationMessage_Type.Error ?
                            'Error' :
                            message.type == INotificationMessage_Type.Warning ?
                                'Warning' :
                                message.type == INotificationMessage_Type.Information ?
                                    'Information' :
                                    null
                )}
            </div>
            <div className="text-base">{messageToHtml(message.message)}</div>
        </div>
        <div className="flex-none cursor-pointer absolute top-4 right-4" data-error-key={message.key}
             onClick={onErrorClose}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                 className="w-5 h-5">
                <path
                    d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z"/>
            </svg>
        </div>
    </div>
}