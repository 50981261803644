import React, {useEffect} from "react";
import SearchInput from "../../Components/SearchInput/SearchInput";
import CategoryPanel from "../../Components/ProductList_CategoryPanel/CategoryPanel";
import {useDispatch, useSelector} from "react-redux";
import {getCategoryStructureCacheKey, triggerRequestCategoryStructure} from "../../Store/Action/cache.categoryTree";
import {getDefaultLang} from "../../Store/Action/interactive";
import SearchRouteParamLink from "../../Components/App/Links/SearchRouteParamLink";
import PopularWords from "./IndexRoutePopularWords";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";
import {MetaTags} from "../../Components/App/Tags/Meta";
import {useLocation} from "react-router-dom";
import StoreLogo from "../../Components/App/Store/StoreLogo";

/** @type TranslationKeys, keep this variable without TS type, for keep type check magic works*/
const ComponentTranslationKey = "Meta"

export default function IndexRoute() {

    // globals
    const dispatch = useDispatch()
    const {t} = useTranslation(ComponentTranslationKey)
    const location = useLocation();

    // internal functions
    const getTranslation = (key: keyof TranslationSet[typeof ComponentTranslationKey]) => {
        return t(key);
    }

    // extract global state
    const state = useSelector((state: IAppState) => {
        const categoryStructureKey = getCategoryStructureCacheKey(0, state.config.lang ? state.config.lang : getDefaultLang())

        return {
            visibleCategories: state.interactive.showCategories,
            categoryTree: state.cache.categoryTree[categoryStructureKey]
                ? state.cache.categoryTree[categoryStructureKey].object
                : undefined,
            cacheState: state.cache.categoryTree[categoryStructureKey]
                ? state.cache.categoryTree[categoryStructureKey].cacheState
                : undefined,
            lang: state.config.lang ? state.config.lang : getDefaultLang(),
            isDebug: state.config.debug && !!state.session.userSessionId
        };
    })

    useEffect(() => {
        if (state.categoryTree === undefined && (state.cacheState == undefined || state.cacheState == CacheState.Unknown || state.cacheState == CacheState.ServerNotResponse)) {
            dispatch(triggerRequestCategoryStructure(undefined, state.lang));
        }
    }, []);

    const urlParams = new URLSearchParams(location.search);
    const googleQuery = urlParams.get('google_search');

    return <div>

        <StoreLogo/>

        <div className={`index-wrapper mt-24`}>

            <MetaTags title={getTranslation('indexTitle')}
                      description={getTranslation('indexDescription')}
                      canonicalPageUrl={`default`}
                      robots={undefined}/>

            <div className="index-wrapper-main bg-white relative">
                <div className={
                    `filter-overlay-base filter-overlay ${state.visibleCategories ? "filter-overlay-visible" : ""} bg-gray-100`}>
                    {state.visibleCategories ? <CategoryPanel key="categories"/> : null}
                </div>

                <div>
                    <div className="mb-8">
                        <img className="mx-auto max-w-[100vw]" src="/resources/shopit_startpage2.webp" alt="Shopit"/>
                    </div>
                    <div className={`index-sayt-wrapper hidden search-three-card:block`}>
                        <SearchInput bigHeightSearch={false} preload={googleQuery || undefined}/>
                    </div>
                    <div className={`max-w-6xl mx-auto text-center`}>
                        <h1 className={`font-light mt-3 text-lg text-gray-500 max-w-[100vw]`}>
                            {getTranslation('indexSearchHint')}
                        </h1>
                    </div>

                    <div className={`max-w-6xl mx-auto mt-16 mb-16`}>
                        <div className={`flex flex-wrap justify-center gap-[18px]`} style={{maxWidth: '100vw'}}>
                            {state.categoryTree && state.categoryTree.children
                                ? state.categoryTree.children.map((category, index) => {

                                    const urlPageParams: IUrlResolvedParams = {
                                        bridgeParams: {
                                            categoryId: category.categoryId,
                                            language: undefined,
                                            color: undefined,
                                            filters: undefined,
                                            expand: undefined,
                                            expandedBrands: undefined,
                                            secondaryExpand: undefined,
                                            showSecondary: undefined,
                                            fold: undefined,
                                            foldedBrands: undefined,
                                            page: undefined,
                                            size: undefined,
                                            text: undefined,
                                            code: undefined,
                                            group: undefined,
                                            sort: undefined,
                                        },
                                        showFilters: undefined,
                                        openFilterOption: [],
                                        openUnpOptions: [],
                                    }

                                    return <SearchRouteParamLink
                                        key={index}
                                        className={`flex-0 w-24 hover:underline`}
                                        urlPageParams={urlPageParams}
                                    >
                                        <div className={`bg-gray-100 w-14 h-14 rounded-full mx-auto`}>
                                            <img className={`mix-blend-darken opacity-50`}
                                                 src={`/resources/categories/${category.categoryId}.webp`}
                                                 alt={category.name}/>
                                        </div>
                                        <div
                                            className={`text-center text-gray-500 font-light mt-1 text-sm`}>{category.name}</div>
                                    </SearchRouteParamLink>
                                })
                                : <div className={`h-32`}></div>}
                        </div>
                    </div>

                    <PopularWords/>
                </div>
            </div>
        </div>
    </div>
}
