import React, {useState} from "react";
import {connect, DispatchProp, useDispatch, useSelector} from 'react-redux';
import {useTranslation, WithTranslation, withTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {triggerUserSettings} from "../../../Store/Action/session.login";
import {getUserSettingsPlaceholderParams} from "../../../Helpers/UserHelper";

type ModalUserSettingsProps = {
    user: IUser | undefined
} & DispatchProp & WithTranslation;

type ModalUserSettingsState = {
    yearOverride: number | undefined
    monthOverride: number | undefined
    dayOverride: number | undefined
    genderOverride: string | undefined
}

const getYears = () => {
    let years = []

    const start = 1900
    const end = new Date().getFullYear()

    for (let i = end; i >= start; --i) {
        years.push(i)
    }

    return years
}

const getMonths = () => {
    let months = []

    const start = 1
    const end = 12

    for (let i = start; i <= end; ++i) {
        months.push(i)
    }

    return months
}

const getDays = () => {
    let days = []

    const start = 1
    const end = 31

    for (let i = start; i <= end; ++i) {
        days.push(i)
    }

    return days
}


/** @type TranslationKeys, keep this variable without TS type, for keep type check magic works*/
const ComponentTranslationKey = "User.Settings.Tab.User"

export default function UserSettings() {

    // globals
    const dispatch = useDispatch()
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet["User"]["Settings"]["Tab"]["User"]) {
        // i18next have problem with process '.' separated path to key as default
        // so we force a path with ':'
        // i tried to debug, but not understand how it works... but works
        // (copy comment for copy+paste multilevel translations functions)
        return t('User:Settings:Tab:User:' + key)
    }

    const getFormTranslation = function (key: keyof TranslationSet["Form"]) {
        return t('Form:' + key)
    }

    // get state
    const [state, setState] = useState<ModalUserSettingsState>({
        yearOverride: undefined,
        dayOverride: undefined,
        genderOverride: undefined,
        monthOverride: undefined,
    });

    // extract global state
    const user = useSelector((state: IAppState) => state.session.user)

    const onSelectYearChange = function (e: React.SyntheticEvent) {
        const target = e.target as typeof e.target & { value: number }
        setState({
            ...state,
            yearOverride: target.value
        })
    }

    const onSelectDayChange = function (e: React.SyntheticEvent) {
        const target = e.target as typeof e.target & { value: number }
        setState({
            ...state,
            dayOverride: target.value
        })
    }

    const onSelectMonthChange = function (e: React.SyntheticEvent) {
        const target = e.target as typeof e.target & { value: number }
        setState({
            ...state,
            monthOverride: target.value
        })
    }

    const onSelectGenderChange = function (e: React.SyntheticEvent) {
        const target = e.target as typeof e.target & { value: string }
        setState({
            ...state,
            genderOverride: target.value
        })
    }

    const onSaveSubmit = function (e: React.SyntheticEvent) {
        e.preventDefault();

        if (!user) return;

        const target = e.target as typeof e.target & {
            firstName: { value: string }
            lastName: { value: string }
            email: { value: string }
            old_password: { value: string }
            new_password: { value: string }
            repeat_new_password: { value: string }
            year: { value: string }
            month: { value: string }
            day: { value: string }
            gender: { value: string }
        };

        const firstName = target.firstName.value
        const lastName = target.lastName.value
        const email = target.email.value
        const old_password = target.old_password.value
        const new_password = target.new_password.value
        const repeat_new_password = target.repeat_new_password.value
        const year = target.year.value
        const month = target.month.value
        const day = target.day.value
        const gender = target.gender.value

        dispatch(triggerUserSettings({
            ...getUserSettingsPlaceholderParams(),

            firstName: firstName !== "" ? firstName : undefined,
            lastName: lastName !== "" ? lastName : undefined,
            email: email !== "" ? email : undefined,
            oldPassword: old_password !== "" ? old_password : undefined,
            newPassword: new_password !== "" ? new_password : undefined,
            newPasswordRepeated: repeat_new_password !== "" ? repeat_new_password : undefined,
            year: year !== "" ? parseInt(year) : undefined,
            month: month !== "" ? parseInt(month) : undefined,
            day: day !== "" ? parseInt(day) : undefined,
            gender: gender !== "" ? gender : undefined,
        }))
    }

    const selectedYear = state.yearOverride
        ? state.yearOverride
        : user?.details.year
            ? user?.details.year
            : "";

    const selectedMonth = state.monthOverride
        ? state.monthOverride
        : user?.details.month
            ? user?.details.month
            : "";

    const selectedDay = state.dayOverride
        ? state.dayOverride
        : user?.details.day
            ? user?.details.day
            : "";

    const selectedGender = state.genderOverride
        ? state.genderOverride
        : user?.details.gender
            ? user?.details.gender
            : "";

    return <div>
        <div className="header">{getTranslation("title")}</div>
        <div className="sub-header">{getTranslation("sub_title")}</div>

        <form onSubmit={onSaveSubmit}>
            <div className="settings-row mt-8">
                <div className="settings-row-label">{getTranslation("first_name")}</div>
                <div className="settings-row-input"><input name="firstName" type="text"
                                                           value={user?.firstName ? user?.firstName : ""}/>
                </div>
            </div>
            <div className="settings-row">
                <div className="settings-row-label">{getTranslation("last_name")}</div>
                <div className="settings-row-input"><input name="lastName" type="text"
                                                           value={user?.lastName ? user?.lastName : ""}/>
                </div>
            </div>

            <div className="settings-row mt-4">
                <div className="settings-row-label">{getTranslation("email")}</div>
                <div className="settings-row-input"><input name="email" type="text"
                                                           value={user?.details.email ? user?.details.email : ""}/>
                </div>
            </div>

            <div className="settings-row mt-4">
                <div className="settings-row-label">{getTranslation("old_password")}</div>
                <div className="settings-row-input"><input name="old_password" type="password"/>
                </div>
            </div>
            <div className="settings-row">
                <div className="settings-row-label">{getTranslation("new_password")}</div>
                <div className="settings-row-input"><input name="new_password" type="password"/>
                </div>
            </div>
            <div className="settings-row">
                <div className="settings-row-label">{getTranslation("repeat_new_password")}</div>
                <div className="settings-row-input"><input name="repeat_new_password" type="password"/>
                </div>
            </div>

            <div className="settings-row mt-4">
                <div className="settings-row-label"></div>
                <div className="settings-row-input">
                    <div className="flex">
                        <div className="flex-initial">
                            <div className="select-2 gray-label">{getTranslation("year")}</div>
                        </div>
                        <div className="flex-initial">
                            <div className="select-4 gray-label">{getTranslation("month")}</div>
                        </div>
                        <div className="flex-initial">
                            <div className="select-4 gray-label">{getTranslation("day")}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="settings-row">
                <div className="settings-row-label">{getTranslation("born")}</div>
                <div className="settings-row-input">
                    <div className="flex">
                        <div className="flex-initial">
                            <select name="year" className="select-2" value={selectedYear}
                                    onSelect={onSelectYearChange}>
                                <option value='' disabled/>
                                {getYears().map((y, i) => <option key={`${y}.${i}`} value={y}>{y}</option>)}
                            </select>
                        </div>
                        <div className="flex-initial">
                            <select name="month" className="select-4" value={selectedMonth}
                                    onSelect={onSelectMonthChange}>
                                <option value='' disabled/>
                                {getMonths().map((m, i) => <option key={`${m}.${i}`}
                                                                   value={m}>{m < 10 ? `0${m}` : m}</option>)}
                            </select>
                        </div>
                        <div className="flex-initial">
                            <select name="day" className="select-4" value={selectedDay}
                                    onSelect={onSelectDayChange}>
                                <option value='' disabled/>
                                {getDays().map((d, i) => <option key={`${d}.${i}`}
                                                                 value={d}>{d < 10 ? `0${d}` : d}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="settings-row">
                <div className="settings-row-label">{getTranslation("gender")}</div>
                <div className="settings-row-input">
                    <select name="gender" onSelect={onSelectGenderChange}
                            value={selectedGender}>
                        <option value='' disabled/>
                        <option value='MALE'>{getTranslation("male")}</option>
                        <option value='FEMALE'>{getTranslation("female")}</option>
                    </select>
                </div>
            </div>

            <div className="mt-4">
                <button className="btn btn-green text-sm w-full py-2 uppercase" type="submit">{getFormTranslation('save')}</button>
            </div>

        </form>
    </div>
}
