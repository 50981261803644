import React from "react";
import FiltersWrapper from "../FiltersWrapper";

type FilterAttributeGroupProps = {
    group?: IFilterPanel_AttributeGroup,
    level: number,
    demonstrationMode: boolean
};

/** @see FilterAttributeGroupDemo */
export default function FilterAttributeGroup(props: FilterAttributeGroupProps) {

    const group = props.group;
    if (!group) return null;

    return (
        <div>
            <div className="filter-panel-attribute-group-header">
                <div>
                    <img src="/resources/arrow-down.png" className="inline-block mr-1" alt="(Expanded)"/>
                    {group.name}
                </div>
                <div> (group)</div>
            </div>

            {(group.structures ? <div className="py-2 pl-4 border-l">{(
                group.structures.map((i, key) =>
                    <FiltersWrapper key={key} item={i} level={props.level}
                                    demonstrationMode={props.demonstrationMode}/>)
            )}</div> : null)}
        </div>
    );
}
