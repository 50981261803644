import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import React from "react";
import {getProductCacheKey, triggerCacheRequestProductWithCallback} from "../../Store/Action/cache.product";
import Product from "../../Components/Product/Product";
import {useLocation, useNavigate} from "react-router-dom";
import {getDefaultLang, triggerDebug} from "../../Store/Action/interactive";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";
import {getCategoryCacheKey} from "../../Store/StoreHelper";
import {formatNumber} from "../../Helpers/StringFormatter.Number";
import {buildProductUrl, parseProductUrl} from "../../Helpers/UrlFormatter.Product";
import {convertCategoryPathToJsonLd, convertProductToJsonLd} from "../../Helpers/JsonLdHelper";
import {MetaTags} from "../../Components/App/Tags/Meta";

const ComponentTranslationKey = "Meta"

export default function ProductRoute() {

    // globals
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    // extract global state
    const [productIdVariant, actualFormat] = parseProductUrl(location.pathname, location.search);
    const productId = productIdVariant || 0

    const globalState = useSelector((state: IAppState) => {
        const productKey = getProductCacheKey(
            productId,
            state.config.lang ? state.config.lang : getDefaultLang(),
            state.config.debug)

        const cacheObject = state.cache.product[productKey] ? state.cache.product[productKey] : undefined;

        const categoryCacheKey = cacheObject && cacheObject.object
            ? getCategoryCacheKey(
                cacheObject.object.product.category_id,
                state.config.lang ? state.config.lang : getDefaultLang(),
                state.config.debug)
            : "";

        const category = cacheObject && state.cache.category[categoryCacheKey] ? state.cache.category[categoryCacheKey].object : undefined

        return {
            productData: cacheObject && cacheObject.object ? cacheObject.object : undefined,
            productCacheState: cacheObject ? cacheObject.cacheState : undefined,

            category: category,

            lang: state.config.lang ? state.config.lang : getDefaultLang(),
            isDebugEnabled: state.config.debug,
        }
    })

    // internal functions
    const checkRedirect = () => {
        if (!globalState.productData) return

        const product = globalState.productData.product
        if (!product.category_name) return

        const [productUrl, isValidLink] = product.gtinId
            ? buildProductUrl(product.gtinId, product.name, product.category_name)
            : buildProductUrl(product.id, product.name, product.category_name)

        if (!isValidLink) {
            console.log("Product link error");
            console.log([product.name, product.category_name])
            const trace = new Error().stack
            console.log(trace)
            dispatch(triggerDebug("Product link error (console)", trace))
        }

        if (productUrl != location.pathname) {
            if (globalState.isDebugEnabled) {
                if (confirm("Debug: You will be redirected now. Current link: " + location.pathname + ", Redirect link: " + productUrl)) {
                    navigate({
                        pathname: productUrl
                    })
                }
            } else {
                navigate({
                    pathname: productUrl
                })
            }
        }
    }

    // lifecycle functions
    useEffect(() => {
        const productData = globalState.productData;
        const productCacheState = globalState.productCacheState;

        if (productData === undefined && (productCacheState == undefined || productCacheState == CacheState.Unknown || productCacheState == CacheState.ServerNotResponse)) {
            dispatch(triggerCacheRequestProductWithCallback(productId));
        }
    }, [globalState.productData, globalState.productCacheState]);

    useEffect(() => {
        window.scrollTo(0, 0)
        checkRedirect()
    }, [location.pathname]);

    const renderMeta = () => {

        if (globalState.productCacheState && globalState.productCacheState == CacheState.NotFound) {
            return <MetaTags title={`Shopit`}
                             canonicalPageUrl={`default`}
                             robots={`noindex`}/>
        }

        const unp = globalState.productData?.product.uniqueNamePath?.map(u => u.name).join(' ')
        const categoryName = globalState.category?.name
        const features = globalState.productData?.product.features?.map(f => f.value).join(' · ')

        let canonicalId: number | undefined = undefined
        let canonicalName: string | undefined = undefined
        let canonicalCategoryName: string | undefined = undefined
        if (globalState.productData?.product?.mainVariantProduct) {
            const mainVariantProduct = globalState.productData.product.mainVariantProduct
            canonicalId = mainVariantProduct.gtinId ? mainVariantProduct.gtinId : mainVariantProduct.id
            canonicalName = mainVariantProduct.name
            canonicalCategoryName = mainVariantProduct.categoryName
        } else if (globalState.productData?.product) {
            const product = globalState.productData?.product
            canonicalId = product.gtinId ? product.gtinId : product.id
            canonicalName = product.name
            canonicalCategoryName = categoryName ? categoryName : product.category_name
        }

        const [canonical, isValidLink] = canonicalId
            ? buildProductUrl(canonicalId, canonicalName, canonicalCategoryName)
            : [undefined, false]

        if (!isValidLink && canonicalId) {
            console.log("Product link error");
            console.log([canonicalName, categoryName])
            const trace = new Error().stack
            console.log(trace)
            dispatch(triggerDebug("Product link error (console)", trace))
        }

        const productSchema = convertProductToJsonLd(globalState.productData?.product);
        const productBreadcrumbsSchema = convertCategoryPathToJsonLd(
            globalState.category?.path,
            isValidLink && globalState.productData && canonical ? {
                name: globalState.productData.product.name,
                url: canonical
            } : undefined)

        const structureData = [productSchema, productBreadcrumbsSchema].filter(a => a)

        return <MetaTags title={
            globalState.productData?.product.name +
            getTranslation('productIn') +
            globalState.category?.name +
            getTranslation("productSuffix")
        }
                         description={
                             (unp ? unp + ' · ' : '') +
                             (categoryName ? categoryName + ' · ' : '') +
                             (features ? features + ' · ' : '') +
                             (
                                 globalState.productData?.product.priceMinInclVat
                                     ? (getTranslation('productFrom') + formatNumber(globalState.productData?.product.priceMinInclVat) + globalState.productData?.product.priceCurrency)
                                     : ''
                             )
                         }
                         canonicalPageUrl={canonical || `default`}
                         structuredData={structureData.length ? structureData : undefined}
                         robots={undefined}
                         imageId={globalState.productData?.product.image_id}
        />
    }

    // render
    return (<div className="max-w-[100vw]">

        {renderMeta()}

        <div className="product-page-container">
            <Product productId={productId}/>
        </div>
    </div>);
}