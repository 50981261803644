import React, {} from "react";
import {useSelector} from "react-redux";
import {NotificationMessage} from "./NotificationMessage";

/** @see NotificationPanelDemo */
export default function NotificationPanel(props: {
    messages?: INotificationMessage[],
    relative?: boolean
}) {

    const propsExtend = useSelector((state: IAppState) => ({
        messages: props.messages
            ? props.messages
            : state.interactive
                ? state.interactive.messages
                : undefined,
        relative: props.relative
    }))

    if (propsExtend.messages) {
        return (
            <div
                className={`notification-global-wrapper ${propsExtend.relative ? `notification-relative-wrapper` : ``}`}>
                {(
                    propsExtend.messages.map((message, index) =>
                        <NotificationMessage message={message} key={`k${index}`}/>
                    )
                )}
            </div>
        );
    }
    return null;
}
