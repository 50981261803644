import SearchDebugButton from "./DebugButton";
import ProductsCompareButton from "./CompareButton";
import ListToggleButton from "./ListToggleButton";
import FilterBarGroupSortButton from "../FilterBarGroupSortButton";
import React, {MouseEventHandler, useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    getCategoryCacheKey,
    getCompareCategoryId,
    isCategoryPanelAvailable,
    isFilterPanelAvailable,
    isOrderDropdownAvailable,
    selectSearchCache
} from "../../../Store/StoreHelper";
import {getDefaultLang, triggerToggleCategories, triggerToggleFilters} from "../../../Store/Action/interactive";
import {useLocation} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import FiltersBarFiltersList from "./FiltersBarFiltersList";
import {SearchRouteContext} from "../../../Store/Context/SearchRouteContext";

const ComponentTranslationKey = "TopBar"

export default function FiltersBar(props: { onSearchDebugClick: () => void }) {

    // globals
    const dispatch = useDispatch()
    const location = useLocation()
    const searchContext = useContext(SearchRouteContext);

    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    const propsExtended = useSelector((state: IAppState) => {
        const searchData = selectSearchCache(state, searchContext?.cacheKey);

        const compareCategoryId = getCompareCategoryId(state, location.pathname, searchContext);
        const categoryCacheKey = compareCategoryId
            ? getCategoryCacheKey(
                compareCategoryId,
                state.config.lang ? state.config.lang : getDefaultLang(),
                state.config.debug)
            : undefined;
        const category = categoryCacheKey && state.cache.category[categoryCacheKey] ? state.cache.category[categoryCacheKey].object : undefined;

        const searchQuery = searchData && searchData.object;
        const skipFilterPanel = searchQuery ? searchQuery.items.title.titleType == IProductList_TitleType.Category : false

        return {
            searchQuery: searchData && searchData.object ? searchData.object.searchQuery : undefined,

            showCategoryPanel: isCategoryPanelAvailable(state, location.pathname),
            showFilterPanel: isFilterPanelAvailable(state, location.pathname) && skipFilterPanel,
            showSearchOrder: isOrderDropdownAvailable(state, location.pathname),
            compareCategoryId: compareCategoryId,

            filterIsDisabled: (category && category.isCollection) || (!category && true),

            compareProductIds: compareCategoryId
                ? state.session.compareProducts[compareCategoryId]
                    ? state.session.compareProducts[compareCategoryId]
                    : []
                : [],
            isDebugEnabled: state.config.debug
        }
    })

    // internal functions
    const onCategoryClick: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        dispatch(triggerToggleCategories());
    }

    const onFiltersClick: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        dispatch(triggerToggleFilters());
    }

    // render
    return (
        <div className={`filter-bar-panel`}>

            <div className={`filter-bar-panel-part pr-2`}>
                {propsExtended.showCategoryPanel ? (
                    <div className="btn btn-green cursor-pointer uppercase" onClick={onCategoryClick}
                         title={getTranslation('hint_categories')}
                         key="categories">
                        <img src="/resources/icon-category.png" alt="" className="mr-1 inline w-4"/>
                        <div className={`hidden search-three-card:inline`}>
                            {getTranslation('categories')}
                        </div>
                    </div>) : null}

                {propsExtended.showFilterPanel ? (
                    <>
                        <div className="inline-block ml-2 text-gray-300" key={`s`}>|</div>
                        <div
                            className={`btn btn-blue cursor-pointer uppercase ml-2 ${propsExtended.filterIsDisabled ? `btn-disabled` : ``}`}
                            onClick={onFiltersClick}
                            title={getTranslation('hint_filters')}
                            key="filters">
                            <img src="/resources/icon-filter.png" alt="" className="mr-1 inline w-4"/>
                            <div className={`hidden search-three-card:inline`}>
                                {getTranslation('filters')}
                            </div>
                        </div>

                        {propsExtended.searchQuery && propsExtended.searchQuery.filter
                            ?
                            <div className={`hidden search-three-card:inline`}>
                                <FiltersBarFiltersList filters={propsExtended.searchQuery.filter}/>
                            </div>
                            : null}
                    </>) : null}
            </div>

            <div className={`filter-bar-panel-part`}>
                <div className={`flex gap-2`}>
                    {propsExtended.isDebugEnabled && propsExtended.showSearchOrder
                        ? <div className={`hidden search-three-card:block`}>
                            <SearchDebugButton onClick={props.onSearchDebugClick}/>
                        </div>
                        : null}
                    {propsExtended.compareCategoryId
                        ? <ProductsCompareButton productIds={propsExtended.compareProductIds}/>
                        : null}
                    {propsExtended.isDebugEnabled && propsExtended.showSearchOrder
                        ? <div className={`hidden search-three-card:block`}>
                            <ListToggleButton/>
                        </div>
                        : null}
                    {propsExtended.showSearchOrder
                        ? <FilterBarGroupSortButton/>
                        : null}
                </div>
            </div>
        </div>
    );
}