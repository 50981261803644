import React from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";
import {getSearchApiFromPageParams} from "../../Helpers/SearchHelper";
import {getSearchCacheKey} from "../../Store/Action/cache.search";
import {getDefaultLang} from "../../Store/Action/interactive";
import {getCategoryCacheKey, selectSearchCache} from "../../Store/StoreHelper";
import {getDebugCategoryDescription} from "../../Helpers/BackendHelper";
import {PlaceholderText} from "../../Components/App/Common/Placeholder";

/** @type TranslationKeys, keep this variable without TS type, for keep type check magic works*/
const ComponentTranslationKey = "TitleBar"

/** @see ProductCardListHeaderDemo */
export default function SearchTitle(props: {
    urlPageParams: IUrlResolvedParams
}) {

    // globals
    const {t} = useTranslation(ComponentTranslationKey)
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key)
    }

    const propsExtend = useSelector((state: IAppState) => {
        const urlPageParams = props.urlPageParams;
        const urlRequestKey = getSearchApiFromPageParams(urlPageParams);

        const searchKey = getSearchCacheKey(
            urlRequestKey,
            state.config.lang ? state.config.lang : getDefaultLang(),
            state.config.debug)

        const categoryCacheKey = urlPageParams.bridgeParams.categoryId
            ? getCategoryCacheKey(
                urlPageParams.bridgeParams.categoryId,
                state.config.lang ? state.config.lang : getDefaultLang(),
                state.config.debug)
            : "";

        const searchData = selectSearchCache(state, searchKey);
        const categoryData = urlPageParams.bridgeParams.categoryId ? state.cache.category[categoryCacheKey] : undefined

        return {
            category: categoryData && categoryData.object ? categoryData.object : undefined,
            responseDetails: searchData && searchData.object ? searchData.object.responseDetails : undefined,
            title: searchData && searchData.object ? searchData.object.items.title : undefined,
            urlPageParams: urlPageParams,

            visibleFilters: state.interactive.showFilters,
            visibleCategories: state.interactive.showCategories,

            lang: state.config.lang ? state.config.lang : getDefaultLang(),
            isDebugEnabled: state.config.debug,
        };
    })

    const category = propsExtend.category;

    return (
        <div className="product-list-header-wrapper">
            {propsExtend.title?.titleType == IProductList_TitleType.Category && category
                ? <div className={`product-list-header-columns`}>
                    <h1 className="product-list-header-column-left">
                        {/*<div className="product-list-header-column-left-icon-wrapper">*/}
                        {/*    <IconShoppingCard style={{*/}
                        {/*        width: '2rem',*/}
                        {/*        display: 'inline',*/}
                        {/*        marginRight: '6px',*/}
                        {/*        marginTop: '-8px',*/}
                        {/*    }}/>*/}
                        {/*</div>*/}
                        {category.name}
                    </h1>
                    <div className="product-list-header-column-right pr-[5px] relative">
                        {category.description
                            ? <div>
                                {category.description}
                                {propsExtend.isDebugEnabled
                                    ?
                                    <a href={getDebugCategoryDescription(propsExtend.urlPageParams.bridgeParams.categoryId)}
                                       target='backend'
                                       className="backend-btn cursor-pointer ml-2 text-base absolute top-0 right-2">Edit</a>
                                    : null}
                            </div>
                            : <div>
                                <PlaceholderText wordsCount={96} class={`bg-category-header`}
                                                 seed={propsExtend.category?.id || 1234}/>
                            </div>}
                    </div>
                </div> : null}
        </div>
    );
}
