import React from "react";
import SearchRouteParamLink from "../../App/Links/SearchRouteParamLink";

type TopLevelSeparatorProps = {
    categoryId: number,
    categoryName: string
    urlPageParams: IUrlResolvedParams | undefined
    fold: string | undefined
};

/** @see TopLevelSeparatorDemo */
export default function TopLevelSeparator(props: TopLevelSeparatorProps) {

    if (props.urlPageParams) {
        <SearchRouteParamLink
            className="category-item-separator flex flex-col text-shopit-green"
            urlPageParams={{
                ...props.urlPageParams,
                bridgeParams: {
                    ...props.urlPageParams.bridgeParams,
                    fold: props.fold
                }
            }}
        >
            <div className="whitespace-nowrap card-separator-text-rotation flex-none flex justify-center">
                <div className="inline-block">{props.categoryName}</div>
            </div>
        </SearchRouteParamLink>
    }

    return (
        <div className="category-item-separator flex flex-col">
            <div className="whitespace-nowrap card-separator-text-rotation flex-none flex justify-center">
                <div className="inline-block">{props.categoryName}</div>
            </div>
        </div>
    );
}
