export const SET_MERGED_PRODUCT_IMAGE_VERIFICATION_SAVE = "SET_MERGED_PRODUCT_IMAGE_VERIFICATION_SAVE";
export const SET_MERGED_PRODUCT_IMAGE_VERIFICATION_LOAD = "SET_MERGED_PRODUCT_IMAGE_VERIFICATION_LOAD";
export const SET_MERGED_PRODUCT_IMAGE_VERIFICATION_APPLY = "SET_MERGED_PRODUCT_IMAGE_VERIFICATION_APPLY";
export const SET_MERGED_PRODUCT_IMAGE_VERIFICATION_DELETE = "SET_MERGED_PRODUCT_IMAGE_VERIFICATION_DELETE";

export const SET_MERGED_PRODUCT_IMAGE_VERIFICATION_SET_PENDING = "SET_MERGED_PRODUCT_IMAGE_VERIFICATION_SET_PENDING";
export const SET_MERGED_PRODUCT_IMAGE_VERIFICATION_REMOVE_PENDING = "SET_MERGED_PRODUCT_IMAGE_VERIFICATION_REMOVE_PENDING";

export type MergedProductImageVerificationSaveAction = {
    type: string,
    userSessionId: string,
    mergedProductId: number,
    imageId: number,
    imageType: (keyof typeof VERIFICATION_MERGED_PRODUCT_IMAGE_TYPE),
    isMainImage: boolean,
    listCategoryId: number | null
}

export const triggerMergedProductImageVerification_Save = (userSession: string,
                                                           mergedProductId: number,
                                                           imageId: number,
                                                           imageType: keyof typeof VERIFICATION_MERGED_PRODUCT_IMAGE_TYPE,
                                                           isMainImage: boolean,
                                                           listCategoryId: number | null): MergedProductImageVerificationSaveAction => ({
    type: SET_MERGED_PRODUCT_IMAGE_VERIFICATION_SAVE,
    userSessionId: userSession,
    mergedProductId: mergedProductId,
    imageId: imageId,
    imageType: imageType,
    isMainImage: isMainImage,
    listCategoryId: listCategoryId
});

// ---

export type MergedProductImageVerificationDeleteAction = {
    type: string,
    userSessionId: string,
    mergedProductId: number,
    imageId: number,
    prevImageType: (keyof typeof VERIFICATION_MERGED_PRODUCT_IMAGE_TYPE),
    prevIsMainImage: boolean,
}

export const triggerMergedProductImageVerification_Delete = (userSession: string,
                                                             mergedProductId: number,
                                                             imageId: number,
                                                             prevImageType: keyof typeof VERIFICATION_MERGED_PRODUCT_IMAGE_TYPE,
                                                             prevIsMainImage: boolean): MergedProductImageVerificationDeleteAction => ({
    type: SET_MERGED_PRODUCT_IMAGE_VERIFICATION_DELETE,
    userSessionId: userSession,
    mergedProductId: mergedProductId,
    imageId: imageId,
    prevImageType: prevImageType,
    prevIsMainImage: prevIsMainImage
});

// ---

export type MergedProductImageVerificationApplyAction = {
    type: string,
    mergedProductIds: number[],
    verifications: {
        [key: number]: IShophunterMergedProductImageData[]
    }
}

export const triggerMergedProductImageVerification_Apply = (mergedProductIds: number[], verifications: {
    [key: number]: IShophunterMergedProductImageData[]
}): MergedProductImageVerificationApplyAction => ({
    type: SET_MERGED_PRODUCT_IMAGE_VERIFICATION_APPLY,
    mergedProductIds: mergedProductIds,
    verifications: verifications
})

// ---

export type MergedProductImageVerificationLoadAction = {
    type: string,
    mergedProductIds: number[],
}

export const triggerMergedProductImageVerification_Load = (mergedProductIds: number[]): MergedProductImageVerificationLoadAction => ({
    type: SET_MERGED_PRODUCT_IMAGE_VERIFICATION_LOAD,
    mergedProductIds: mergedProductIds,
})

// ---

export type MergedProductImageVerificationSetPendingAction = {
    type: string,
    mergedProductId: number,
    imageId: number,
    imageType: (keyof typeof VERIFICATION_MERGED_PRODUCT_IMAGE_TYPE),
    isMainImage: boolean,
}

export const triggerMergedProductImageVerification_SetPending = (mergedProductId: number,
                                                                 imageId: number,
                                                                 imageType: keyof typeof VERIFICATION_MERGED_PRODUCT_IMAGE_TYPE,
                                                                 isMainImage: boolean): MergedProductImageVerificationSetPendingAction => ({
    type: SET_MERGED_PRODUCT_IMAGE_VERIFICATION_SET_PENDING,
    mergedProductId: mergedProductId,
    imageId: imageId,
    imageType: imageType,
    isMainImage: isMainImage,
});

// ---

export type MergedProductImageVerificationRemovePendingAction = {
    type: string,
    mergedProductId: number,
    imageId: number,
}

export const triggerMergedProductImageVerification_RemovePending = (mergedProductId: number,
                                                                    imageId: number): MergedProductImageVerificationRemovePendingAction => ({
    type: SET_MERGED_PRODUCT_IMAGE_VERIFICATION_REMOVE_PENDING,
    mergedProductId: mergedProductId,
    imageId: imageId,
});

