export const EVENT_MENU_CLOSE = 'shopitVerifyMenuClose'
export const EVENT_MENU_CLOSE_CHECK = 'shopitVerifyMenuCloseCheck'

export function getEventMenuClose() {
    return new Event(EVENT_MENU_CLOSE)
}

export function getEventMenuCloseCheck(node: HTMLElement|null) {
    return new CustomEvent(EVENT_MENU_CLOSE_CHECK, {detail: node})
}

export function checkNodeParent(clickNode: HTMLElement, menuNode: HTMLElement) {
    let element: HTMLElement | null = clickNode as HTMLElement;
    while (element) {
        if (element == menuNode) return true;
        element = element.parentElement;
    }
    return false;
}