import React, {MouseEventHandler} from "react";
import {LinkProps, useHref, useLinkClickHandler} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {triggerCacheAccessSearchWithCallback} from "../../../Store/Action/cache.search";
import {parseUrlToSearchParams} from "../../../Helpers/UrlFormatter.Search";

const SearchRouteLink = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {

    // extract <Link/> component props, for pass rest to <a/> tag
    const {
        replace,
        state,
        target,
        to,
        className,
        ...rest
    } = props;

    const isDebugEnabled = useSelector((state: IAppState) => state.config.debug)
    const dispatch = useDispatch();
    const href = useHref(to);
    const navigate = useLinkClickHandler(to, {
        replace,
        state,
        target,
    });

    const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault();

        const urlSeparator = href.indexOf('?');
        const [linkPathname, linkSearch] = urlSeparator !== -1 ? [href.substring(0, urlSeparator), href.substring(urlSeparator)] : [href, undefined]
        const urlPageParams = parseUrlToSearchParams(linkPathname, linkSearch || "")

        dispatch(triggerCacheAccessSearchWithCallback(urlPageParams, false, isDebugEnabled, () => {
            navigate(e);
        }))
    }

    return (
        <a href={href}
           ref={ref}
           className={className + ` cursor-pointer`}
           {...rest}
           onClick={handleClick}> {props.children}</a>
    )
})

export default SearchRouteLink;