import React from "react";
import {MetaTags} from "../../Components/App/Tags/Meta";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";

// ---

const ComponentTranslationKey = "Meta"

export default function PersonalPolicyRoute(props: {}) {

    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    return (
        <div className={`service-page-container pt-16`}>

            <MetaTags title={getTranslation('personalPolicyTitle')}
                      canonicalPageUrl={`default`}
                      robots={`noindex`}/>

            <h1 className={`service-page-h1`}>Under Construction</h1>
        </div>
    );
}
