import React from "react";
import {FourProductImages, ProductImage} from "../../App/Common/Image";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import SearchRouteParamLink from "../../App/Links/SearchRouteParamLink";

type FoldedManufacturerCardProps = {
    name: string
    urlPageParams: IUrlResolvedParams
    exampleImages: { image_id: number }[],
    count: number,
    fold: string | undefined,

    className?: string,
};

const ComponentTranslationKey = "Item"

/** @see FoldedManufacturerCardDemo */
export default function FoldedManufacturerCard(props: FoldedManufacturerCardProps) {
    // globals
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }
    return (
        <SearchRouteParamLink
            className={`category-item-card category-item-card-secondary ${props.className}`}
            urlPageParams={{
                ...props.urlPageParams,
                bridgeParams: {
                    ...props.urlPageParams.bridgeParams,
                    fold: props.fold
                }
            }}
        >

            <div className="card-section-rigid p-2">
                <div className="card-section-image">
                    {props.exampleImages.length == 1
                        ? <ProductImage imageId={props.exampleImages[0].image_id}
                                        alt={props.name}
                                        size="m"
                                        imageClass={`image-aspect-ratio-height`}/>
                        : <FourProductImages imageIds={props.exampleImages.map((i) => i.image_id)}
                                             alt={props.name}
                                             size="m"
                                             imageSize="s"/>
                    }
                </div>
            </div>

            <div className="card-section-stretch flex flex-col justify-between">
                <div className="px-4 flex-none text-gray-500">
                    {props.name}
                </div>
                <div className="px-4 flex-none text-gray-500 pb-4">
                    {getTranslation("show")}{' '}{props.count}{' '}{getTranslation("products")}
                </div>
            </div>
        </SearchRouteParamLink>
    );
}