import {call, put, select, takeEvery} from 'redux-saga/effects'
import axios, {AxiosResponse} from "axios";
import {handleShopitApi, handleShopitApiRequestError} from "./helper"
import {
    LOAD_PRODUCT_LIKE_PREVIEW,
    LOAD_PRODUCT_OWN_PREVIEW,
    LOAD_PRODUCT_COMPARE_PREVIEW,
    ProductsAction,
    triggerLoadProductsLikePreviewSuccess,
    triggerLoadProductsOwnPreviewSuccess,
    triggerLoadProductsComparePreviewSuccess,
    triggerLoadProductsLikePreviewFailed,
    triggerLoadProductsOwnPreviewFailed,
    triggerLoadProductsComparePreviewFailed,
    triggerLoadProductsComparePreviewLoading,
    triggerLoadProductsOwnPreviewLoading, triggerLoadProductsLikePreviewLoading
} from "../Action/session.product";
import {getAxiosApiConfigs, serializePostParams} from "../../Helpers/ApiHelper";

function* loadProductsLikePreview(action: ProductsAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    yield put(triggerLoadProductsLikePreviewLoading(action.productIds.join(',')))

    const req = call(
        axios.post,
        `/api/like-list`,
        serializePostParams<IApiLikeListRequest>({
            productIds: action.productIds.join(','),
            lang: action.lang,
            _logPageToken: logToken || null,
        }),
        getAxiosApiConfigs(undefined, "PPS_LIKE_LIST_TIMEOUT, notify developers"));

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
            yield put(triggerLoadProductsLikePreviewFailed(action.productIds.join(',')))
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiLikeListResponse>(res)
        if (response) {
            yield put(triggerLoadProductsLikePreviewSuccess(response.list, action.productIds.join(',')));
        } else {
            yield put(triggerLoadProductsLikePreviewFailed(action.productIds.join(',')))
        }
    }
}

function* loadProductsOwnPreview(action: ProductsAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    yield put(triggerLoadProductsOwnPreviewLoading(action.productIds.join(',')))

    const req = call(
        axios.post,
        `/api/like-list`,
        serializePostParams<IApiLikeListRequest>({
            productIds: action.productIds.join(','),
            lang: action.lang,
            _logPageToken: logToken || null,
        }),
        getAxiosApiConfigs(undefined, "PPS_OWN_LIST_TIMEOUT, notify developers"));

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
            yield put(triggerLoadProductsOwnPreviewFailed(action.productIds.join(',')))
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiLikeListResponse>(res)
        if (response) {
            yield put(triggerLoadProductsOwnPreviewSuccess(response.list, action.productIds.join(',')));
        } else {
            yield put(triggerLoadProductsOwnPreviewFailed(action.productIds.join(',')))
        }
    }
}

function* loadProductsComparePreview(action: ProductsAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    yield put(triggerLoadProductsComparePreviewLoading(action.productIds.join(',')))

    const req = call(
        axios.post,
        `/api/like-list`,
        serializePostParams<IApiLikeListRequest>({
            productIds: action.productIds.join(','),
            lang: action.lang,
            _logPageToken: logToken || null,
        }),
        getAxiosApiConfigs(undefined, "PPS_COMPARE_LIST_TIMEOUT, notify developers"));

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
            yield put(triggerLoadProductsComparePreviewFailed(action.productIds.join(',')))
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiLikeListResponse>(res)
        if (response) {
            yield put(triggerLoadProductsComparePreviewSuccess(response.list, action.productIds.join(',')));
        } else {
            yield put(triggerLoadProductsComparePreviewFailed(action.productIds.join(',')))
        }
    }
}

export function* watchLikeListAction() {
    yield takeEvery(LOAD_PRODUCT_LIKE_PREVIEW, loadProductsLikePreview);
    yield takeEvery(LOAD_PRODUCT_OWN_PREVIEW, loadProductsOwnPreview);
    yield takeEvery(LOAD_PRODUCT_COMPARE_PREVIEW, loadProductsComparePreview);
}

