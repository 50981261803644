import {connect, DispatchProp, useDispatch, useSelector} from "react-redux";
import React, {useEffect} from "react";
import {getProductCacheKey, triggerCacheRequestProductsWithCallback} from "../../Store/Action/cache.product";
import CategoryBreadcrumbs from "../ProductList/ProductList_Breadcrumbs/CategoryBreadcrumbs";
import SpecificationsTable from "../Product/Specifications";
import {getDefaultLang} from "../../Store/Action/interactive";
import {getCategoryCacheKey} from "../../Store/StoreHelper";
import ProductCard from "../ProductsGrid/Card_Product";
import ProductSecondaryCard from "../ProductsGrid/Card_ProductSecondary";
import {EVENT_MENU_CLOSE_CHECK} from "../../Helpers/EventHelper";

export default function ProductsTable(props: {
    categoryItems?: IProductList,
    urlPageParams: IUrlResolvedParams,
}) {

    // globals
    const dispatch = useDispatch()

    const propsExtend = useSelector((state: IAppState) => {
        let productIds: number[] = [];

        let products: (IProduct | IMissingProduct)[] = []
        let productsCacheState: (CacheState | undefined)[] = []
        let debugData: { [productId: number]: IProductDebugData } = {}

        if (props.categoryItems) {
            for (let i = 0; i < props.categoryItems.list.length; i++) {
                const item = props.categoryItems.list[i];

                switch (item.itemType) {
                    case IProductList_ItemType.Product: {
                        const productCardData = item as IProductListItem_Product;
                        productIds.push(productCardData.product.id)
                        products.push(productCardData.product)
                        break
                    }
                    case IProductList_ItemType.ProductSecondary: {
                        const productCardData = item as IProductListItem_ProductSecondary;
                        productIds.push(productCardData.product.id)
                        products.push(productCardData.product)
                        break
                    }
                }
            }
        }

        const productKeys = productIds.map(a => {
            return getProductCacheKey(
                a,
                state.config.lang ? state.config.lang : getDefaultLang(),
                state.config.debug)
        })

        for (let i = 0; i < productKeys.length; i++) {
            const cacheObject = state.cache.product[productKeys[i]] ? state.cache.product[productKeys[i]] : undefined;

            if (cacheObject && cacheObject.object) {
                products[i] = cacheObject.object.product
            }

            if (cacheObject) {
                productsCacheState.push(cacheObject.cacheState)
            } else {
                productsCacheState.push(undefined)
            }

            const productDebugData = cacheObject?.object?.productDetails.object?.debugData;
            if (productDebugData) {
                debugData[parseInt(productKeys[i])] = productDebugData;
            }
        }

        return {
            categoryItems: props.categoryItems,
            urlPageParams: props.urlPageParams,

            productIds: productIds,
            productsCacheState: productsCacheState,
            products: products,
            debugData: debugData,
        }
    })

    useEffect(() => {
        const products = propsExtend.products
        const productsCacheState = propsExtend.productsCacheState

        const missingProductIds = propsExtend.productIds
            .filter((id, index) => ((productsCacheState[index] == undefined || productsCacheState[index] == CacheState.Unknown || productsCacheState[index] == CacheState.ServerNotResponse)))
        if (missingProductIds.length > 0) {
            dispatch(triggerCacheRequestProductsWithCallback(missingProductIds));
        }
        window.scrollTo(0, 0)
    }, [propsExtend.productIds.join(',')])

    return <div className="compare-page-container">
        <SpecificationsTable products={propsExtend.products}
                             debugData={propsExtend.debugData}
                             includeProductImage={true}/>
    </div>
}
