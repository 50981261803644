import {call, put, select, takeEvery} from 'redux-saga/effects'
import axios, {AxiosResponse} from "axios";
import {handleShopitApi, handleShopitApiRequestError} from "./helper"

import {triggerEndLoadingOverlay, triggerStartLoadingOverlay} from "../Action/interactive";
import {getAxiosApiConfigs} from "../../Helpers/ApiHelper";
import {
    REQUEST_STORES,
    RequestStoresAction,
    triggerResponseStoresFailed,
    triggerResponseStoresSuccess
} from "../Action/cache.stores";

function* requestStores(action: RequestStoresAction) {

    const req = call(
        axios.post,
        `/api/stores`,
        {},
        getAxiosApiConfigs(undefined, "STORES_TIMEOUT, notify developers"));

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        yield put(triggerStartLoadingOverlay())

        const response = yield* handleShopitApi<IApiStoresResponse>(res)
        if (response) {
            yield put(triggerResponseStoresSuccess(response.stores));
        } else {
            yield put(triggerResponseStoresFailed());
        }

        yield put(triggerEndLoadingOverlay())
    }
}

export function* watchStoresAction() {
    yield takeEvery(REQUEST_STORES, requestStores);
}

