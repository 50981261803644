import React, {MouseEventHandler, useState} from "react"
import FiltersWrapper from "../FiltersWrapper"
import {useTranslation} from "react-i18next"
import {TranslationSet} from "../../../Localization/i18n.constants"

type FilterOptionsProps = {
    attribute?: IFilterPanel_Attribute | IFilterPanel_Numeric,
    level: number,
    detachSearchParamAttributeFilterChildren?: Function,
    demonstrationMode: boolean
}

const ComponentTranslationKey = "Attribute"

/** @see FilterOptionsDemo */
export default function FilterOptions(props: FilterOptionsProps) {

    // globals
    const {t} = useTranslation(ComponentTranslationKey)
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key)
    }

    const [state, setState] = useState({
        optionsExpand: false
    })

    const expandOptions: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        setState({optionsExpand: true})
    }

    const collapseOptions: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        setState({optionsExpand: false})
    }

    const attribute = props.attribute

    if (!attribute || !attribute.filterValues || attribute.filterValues.length == 0) {
        return null
    }

    const level = props.level

    const options = attribute.filterValues.filter((i) =>
        (i.objectType == IFilterPanel_ObjectType.Option))
    const nonOptions = attribute.filterValues.filter((i) =>
        (i.objectType != IFilterPanel_ObjectType.Option))

    const actualOptions = options.length <= 15 || state.optionsExpand
        ? options
        : options.slice(0, 15 + options.filter(i => (i as IFilterPanel_Option).checked).length)

    const showExpand = options.length > 15 && !state.optionsExpand
    const showCollapse = options.length > 15 && state.optionsExpand

    let html: React.ReactElement[] = actualOptions.map((i, key) =>
        (<FiltersWrapper key={key}
                         item={i}
                         level={level}
                         detachSearchParamAttributeFilterChildren={props.detachSearchParamAttributeFilterChildren}
                         demonstrationMode={props.demonstrationMode}/>))
    if (showExpand) {
        html.push(
            <div key={`a-expand-${attribute.id}`}
                 className={`filter-panel-toggle-options level-${level}`}
                 onClick={expandOptions}>
                {getTranslation('show')}{' '}{options.length - actualOptions.length}{' '}{getTranslation('more_options')}
            </div>
        )
    }
    if (showCollapse) {
        html.push(
            <div key={`a-collapse-${attribute.id}`}
                 className={`filter-panel-toggle-options level-${level}`}
                 onClick={collapseOptions}>
                {getTranslation('show_less_options')}
            </div>
        )
    }
    if (nonOptions.length > 0) {
        html.push(
            ...
                nonOptions.map((i, key) =>
                    (<FiltersWrapper key={key}
                                     item={i}
                                     level={level}
                                     detachSearchParamAttributeFilterChildren={props.detachSearchParamAttributeFilterChildren}
                                     demonstrationMode={props.demonstrationMode}/>))
        )
    }

    if (attribute.type == "PART" || attribute.type == "BOOLEAN") {
        for (let i = 0; i < options.length; i++) {
            const option = options[i] as IFilterPanel_Option;
            if (option.structures && option.structures.length > 0) {
                html.push(
                    ...
                        option.structures.map((i, key) =>
                            (<div className={`border-t mt-2`}>
                                <FiltersWrapper key={key}
                                                item={i}
                                                level={level}
                                                detachSearchParamAttributeFilterChildren={props.detachSearchParamAttributeFilterChildren}
                                                demonstrationMode={props.demonstrationMode}/>
                            </div>))
                )
            }
        }
    }

    return <>{html}</>
}
