import {URL_CONTEXT_ADD, URL_CONTEXT_RESET, UrlContextAddAction, UrlContextResetAction} from "../Action/urlContext";

export const urlConfigInitialState: IUrlInfoContext = {
    category: {},
    brand: {},
    manufacturer: {},
    family: {},
    unp: {},
};

const urlContextReducer = (state: IUrlInfoContext = urlConfigInitialState,
                           action: UrlContextResetAction | UrlContextAddAction): IUrlInfoContext => {
    switch (action.type) {
        case URL_CONTEXT_RESET:
            return {
                category: {},
                brand: {},
                manufacturer: {},
                family: {},
                unp: {},
            }
        case URL_CONTEXT_ADD: {
            const addContext = (action as UrlContextAddAction).urlContext
            return {
                category: {...state.category, ...addContext.category},
                brand: {...state.brand, ...addContext.brand},
                manufacturer: {...state.manufacturer, ...addContext.manufacturer},
                family: {...state.family, ...addContext.family},
                unp: {...state.unp, ...addContext.unp},
            }
        }
    }
    return state;
}

export default urlContextReducer;