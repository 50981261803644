import React, {MouseEventHandler} from "react";
import {connect, DispatchProp} from 'react-redux';
import {IconTriChevronLeft} from "../../App/Icons";
import {FILTER_MANUFACTURER_KEY} from "../../../Helpers/Constants";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {useTranslation, withTranslation, WithTranslation} from "react-i18next";
import SearchRouteParamLink from "../../App/Links/SearchRouteParamLink";
import {getDebugProductToolUrl} from "../../../Helpers/BackendHelper";
import SeparatorLink from "../SeparatorLink";

type ManufacturerSeparatorProps = {
    id: number
    name: string
    withLink: boolean
    urlPageParams: IUrlResolvedParams
    fold: string | undefined
    isDebugEnabled: boolean
};

const ComponentTranslationKey = "Separator"

/** @see ManufacturerSeparatorDemo */
export default function ManufacturerSeparator(props: ManufacturerSeparatorProps) {
    // globals
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    const onBackendClick: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const manualCfs = FILTER_MANUFACTURER_KEY + ':' + props.id

        window.open(getDebugProductToolUrl(
            props.urlPageParams,
            undefined,
            undefined,
            props.urlPageParams.bridgeParams.categoryId,
            undefined,
            props.urlPageParams.bridgeParams.text,
            undefined,
            manualCfs,
            undefined,
            undefined,
            undefined,
            undefined), 'backend');
    }

    return <SeparatorLink urlPageParams={props.withLink ? {
        ...props.urlPageParams,
        bridgeParams: {
            ...props.urlPageParams.bridgeParams,
            fold: props.fold
        }
    } : undefined}>
        <div className="whitespace-nowrap card-separator-text-rotation flex-none flex justify-center">
            <div className="inline-block">
                {props.withLink
                    ? <IconTriChevronLeft
                        style={{width: '1.3em', display: 'inline-block', marginRight: '6px', marginBottom: '3px',}}
                        color={`#3b7ec2`}/>
                    : null}
                {props.id
                    ? props.name
                    : getTranslation('other_manufacturers')}
            </div>
        </div>
        {props.isDebugEnabled
            ? <div className="whitespace-nowrap flex-none">
                <div onClick={onBackendClick} className="backend-btn backend-btn-separator">M
                </div>
            </div>
            : null}
    </SeparatorLink>
}
