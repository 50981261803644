import React from "react";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";
import {useDispatch} from "react-redux";
import {
    triggerImageVerification_Delete,
    triggerImageVerification_Save_ImageContentType,
    triggerImageVerification_Save_ImageType,
    triggerImageVerification_Save_ImageOverlay,
    triggerImageVerification_Save_Status,
    triggerImageVerification_Save_ImageVariants,
    triggerImageVerification_Save_ImageWatermark
} from "../../Store/Action/session.verifyImage";

type ImageVerificationListProps = {
    productId?: number,
    imageId: number,
    userSessionId?: string,
    verifications?: { [imageKey: number]: IShophunterImageData[] }
}

export function ImageVerificationList(props: ImageVerificationListProps) {

    const {t} = useTranslation("ImageVerificationMenu");
    const dispatch = useDispatch();
    const getTranslation = function (key: keyof TranslationSet["ImageVerificationMenu"]) {
        return t(key);
    }

    const triggerVerificationChange = function (verificationType: string) {
        if (!props.userSessionId) return;

        switch (verificationType) {
            case VERIFICATION_IMAGE_STATUS.MAIN:
            case VERIFICATION_IMAGE_STATUS.UNRELATED:
            case VERIFICATION_IMAGE_STATUS.ACCESSORY:
            case VERIFICATION_IMAGE_STATUS.OK_IMAGE:
                if (props.productId) {
                    dispatch(triggerImageVerification_Save_Status(props.userSessionId, props.imageId, props.productId, verificationType));
                }
                break;
            case VERIFICATION_IMAGE_TYPE.ISOLATED:
            case VERIFICATION_IMAGE_TYPE.GRADIENT:
            case VERIFICATION_IMAGE_TYPE.SOLID:
            case VERIFICATION_IMAGE_TYPE.DRAWING:
            case VERIFICATION_IMAGE_TYPE.HOMOGEN:
            case VERIFICATION_IMAGE_TYPE.LIGHT_GRADIENT:
            case VERIFICATION_IMAGE_TYPE.BAD_SOFT_PIXELS:
            case VERIFICATION_IMAGE_TYPE.BAD_UNCROPPED:
                dispatch(triggerImageVerification_Save_ImageType(props.userSessionId, props.imageId, verificationType));
                break;
            case VERIFICATION_IMAGE_CONTENT_TYPE.IMAGE_CONTENT_TYPE_ICON_NO_IMAGE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.ICON_NO_IMAGE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.ICON_CAMPAIGN:
            case VERIFICATION_IMAGE_CONTENT_TYPE.ICON_SYMBOL_OR_FUNCTION:
            case VERIFICATION_IMAGE_CONTENT_TYPE.ICON_WEBSITE_DESIGN:
            case VERIFICATION_IMAGE_CONTENT_TYPE.BAD_UNRELATED:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_CLOSEUP:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_SIDE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_TOP:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_BOTTOM:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_BACK:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_MULTIPLE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_ENVIRONMENT:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_FRONT:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_OPEN:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_CLOSED:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_ONE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_IN_USAGE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_MULTIPLE_VARIANTS:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_MULTIPLE_SAME:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_ONE_ACCESSORY_OR_PART:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_SET_OR_COLLECTION:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_SCREENSHOT:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_AND_COVER:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_COVER_ONLY:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_AND_PRODUCT:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_UNIQUE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_BACK:
            case VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_GENERIC:
            case VERIFICATION_IMAGE_CONTENT_TYPE.BOOK_MEDIA_COVER:
            case VERIFICATION_IMAGE_CONTENT_TYPE.BOOK_MEDIA_COVER_BACK:
            case VERIFICATION_IMAGE_CONTENT_TYPE.DRAWING_BLUEPRINT:
            case VERIFICATION_IMAGE_CONTENT_TYPE.DRAWING_3D:
            case VERIFICATION_IMAGE_CONTENT_TYPE.DRAWING_ILLUSTRATION:
            case VERIFICATION_IMAGE_CONTENT_TYPE.TEXT:
            case VERIFICATION_IMAGE_CONTENT_TYPE.COLLAGE_TEXT_IMAGE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.CHART_GRAPH:
            case VERIFICATION_IMAGE_CONTENT_TYPE.CHART_SIZE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.CHART_ENERGY_CERTIFICATE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.EXAMPLE_PALETTE_COLOR_MATERIAL:
            case VERIFICATION_IMAGE_CONTENT_TYPE.EXAMPLE_ONE_MATERIAL_COLOR:
            case VERIFICATION_IMAGE_CONTENT_TYPE.EXAMPLE_ONE_PATTERN:
            case VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_BRAND:
            case VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_VENDOR:
            case VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_PRODUCT:
            case VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_TECH:
            case VERIFICATION_IMAGE_CONTENT_TYPE.MULTIPLE_LOGOS:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FOCUS_TOP:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FOCUS_BOTTOM:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_CLOSEUP:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FULL:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_SIDE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_BACK:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_HEAD:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_HANDS:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FEET:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_SITTING:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_MULTI_PERSPECTIVE:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_MULTI_VARIANTS:
            case VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_ENVIRONMENT:
                dispatch(triggerImageVerification_Save_ImageContentType(props.userSessionId, props.imageId, verificationType));
                break;
            case "VARIANT_0":
                dispatch(triggerImageVerification_Save_ImageVariants(props.userSessionId, props.imageId, 0));
                break;
            case "VARIANT_1":
                dispatch(triggerImageVerification_Save_ImageVariants(props.userSessionId, props.imageId, 1));
                break;
            case "VARIANT_2":
                dispatch(triggerImageVerification_Save_ImageVariants(props.userSessionId, props.imageId, 2));
                break;
            case "VARIANT_3":
                dispatch(triggerImageVerification_Save_ImageVariants(props.userSessionId, props.imageId, 3));
                break;
            case "VARIANT_4":
                dispatch(triggerImageVerification_Save_ImageVariants(props.userSessionId, props.imageId, 4));
                break;
            case "VARIANT_5":
                dispatch(triggerImageVerification_Save_ImageVariants(props.userSessionId, props.imageId, 5));
                break;
            case VERIFICATION_IMAGE_OVERLAY.OVERLAY_TEXT:
            case VERIFICATION_IMAGE_OVERLAY.OVERLAY_SYMBOL:
            case VERIFICATION_IMAGE_OVERLAY.OVERLAY_LOGO:
            case VERIFICATION_IMAGE_OVERLAY.OVERLAY_CAMPAIGN:
            case VERIFICATION_IMAGE_OVERLAY.OVERLAY_CLOSEUP:
            case VERIFICATION_IMAGE_OVERLAY.OVERLAY_GRAPHICS:
            case VERIFICATION_IMAGE_OVERLAY.OVERLAY_FALSE:
                dispatch(triggerImageVerification_Save_ImageOverlay(props.userSessionId, props.imageId, verificationType));
                break;
            case VERIFICATION_IMAGE_WATERMARK.WATERMARK_FALSE:
            case VERIFICATION_IMAGE_WATERMARK.WATERMARK_TRUE:
                dispatch(triggerImageVerification_Save_ImageWatermark(props.userSessionId, props.imageId, verificationType));
                break;
            case "DELETE":
                dispatch(triggerImageVerification_Delete(props.userSessionId, props.imageId, props.productId));
                break;
            default:
                break;
        }
    }

    const getVerificationButton = function (label: string, verificationType: string) {
        return <div className={`image-verification-item`} onClick={(e) => triggerVerificationChange(verificationType)}>
            {label}
        </div>
    }

    return <div className={``}>
        <div>{getTranslation('edit_image_type')}</div>

        {getVerificationButton(getTranslation('status_main'), VERIFICATION_IMAGE_STATUS.MAIN)}
        {getVerificationButton(getTranslation('status_unrelated'), VERIFICATION_IMAGE_STATUS.UNRELATED)}
        {getVerificationButton(getTranslation('status_accessory'), VERIFICATION_IMAGE_STATUS.ACCESSORY)}
        {getVerificationButton(getTranslation('status_ok_image'), VERIFICATION_IMAGE_STATUS.OK_IMAGE)}

        {getVerificationButton(getTranslation('type_isolated'), VERIFICATION_IMAGE_TYPE.ISOLATED)}
        {getVerificationButton(getTranslation('type_light_gradient'), VERIFICATION_IMAGE_TYPE.LIGHT_GRADIENT)}
        {getVerificationButton(getTranslation('type_gradient'), VERIFICATION_IMAGE_TYPE.GRADIENT)}
        {getVerificationButton(getTranslation('type_solid'), VERIFICATION_IMAGE_TYPE.SOLID)}
        {getVerificationButton(getTranslation('type_homogen'), VERIFICATION_IMAGE_TYPE.HOMOGEN)}
        {getVerificationButton(getTranslation('type_bad_soft_pixels'), VERIFICATION_IMAGE_TYPE.BAD_SOFT_PIXELS)}
        {getVerificationButton(getTranslation('type_bad_uncropped'), VERIFICATION_IMAGE_TYPE.BAD_UNCROPPED)}

        {getVerificationButton(getTranslation('content_type_product_one'), VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_ONE)}
        {getVerificationButton(getTranslation('content_type_product_in_usage'), VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_IN_USAGE)}
        {getVerificationButton(getTranslation('content_type_product_multiple_variants'), VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_MULTIPLE_VARIANTS)}
        {getVerificationButton(getTranslation('content_type_product_multiple_same'), VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_MULTIPLE_SAME)}
        {getVerificationButton(getTranslation('content_type_product_one_accessory_or_part'), VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_ONE_ACCESSORY_OR_PART)}
        {getVerificationButton(getTranslation('content_type_product_set_or_collection'), VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_SET_OR_COLLECTION)}
        {getVerificationButton(getTranslation('content_type_product_screenshot'), VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_SCREENSHOT)}
        {getVerificationButton(getTranslation('content_type_product_and_cover'), VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_AND_COVER)}
        {getVerificationButton(getTranslation('content_type_product_cover_only'), VERIFICATION_IMAGE_CONTENT_TYPE.PRODUCT_COVER_ONLY)}
        {getVerificationButton(getTranslation('content_type_package_unique'), VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_UNIQUE)}
        {getVerificationButton(getTranslation('content_type_package_back'), VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_BACK)}
        {getVerificationButton(getTranslation('content_type_package_generic'), VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_GENERIC)}
        {getVerificationButton(getTranslation('content_type_package_and_product'), VERIFICATION_IMAGE_CONTENT_TYPE.PACKAGE_AND_PRODUCT)}
        {getVerificationButton(getTranslation('content_type_perspective_closed'), VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_CLOSED)}
        {getVerificationButton(getTranslation('content_type_perspective_front'), VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_FRONT)}
        {getVerificationButton(getTranslation('content_type_perspective_side'), VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_SIDE)}
        {getVerificationButton(getTranslation('content_type_perspective_top'), VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_TOP)}
        {getVerificationButton(getTranslation('content_type_perspective_bottom'), VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_BOTTOM)}
        {getVerificationButton(getTranslation('content_type_perspective_back'), VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_BACK)}
        {getVerificationButton(getTranslation('content_type_perspective_open'), VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_OPEN)}
        {getVerificationButton(getTranslation('content_type_perspective_closed'), VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_CLOSED)}
        {getVerificationButton(getTranslation('content_type_perspective_multiple'), VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_MULTIPLE)}
        {getVerificationButton(getTranslation('content_type_perspective_environment'), VERIFICATION_IMAGE_CONTENT_TYPE.PERSPECTIVE_ENVIRONMENT)}
        {getVerificationButton(getTranslation('content_type_icon_no_image'), VERIFICATION_IMAGE_CONTENT_TYPE.ICON_NO_IMAGE)}
        {getVerificationButton(getTranslation('content_type_icon_campaign'), VERIFICATION_IMAGE_CONTENT_TYPE.ICON_CAMPAIGN)}
        {getVerificationButton(getTranslation('content_type_icon_symbol_or_function'), VERIFICATION_IMAGE_CONTENT_TYPE.ICON_SYMBOL_OR_FUNCTION)}
        {getVerificationButton(getTranslation('content_type_icon_website_design'), VERIFICATION_IMAGE_CONTENT_TYPE.ICON_WEBSITE_DESIGN)}
        {getVerificationButton(getTranslation('content_type_logo_brand'), VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_BRAND)}
        {getVerificationButton(getTranslation('content_type_logo_vendor'), VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_VENDOR)}
        {getVerificationButton(getTranslation('content_type_logo_product'), VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_PRODUCT)}
        {getVerificationButton(getTranslation('content_type_logo_tech'), VERIFICATION_IMAGE_CONTENT_TYPE.LOGO_TECH)}
        {getVerificationButton(getTranslation('content_type_multiple_logos'), VERIFICATION_IMAGE_CONTENT_TYPE.MULTIPLE_LOGOS)}
        {getVerificationButton(getTranslation('content_type_text'), VERIFICATION_IMAGE_CONTENT_TYPE.TEXT)}
        {getVerificationButton(getTranslation('content_type_collage_text_image'), VERIFICATION_IMAGE_CONTENT_TYPE.COLLAGE_TEXT_IMAGE)}
        {getVerificationButton(getTranslation('content_type_drawing_blueprint'), VERIFICATION_IMAGE_CONTENT_TYPE.DRAWING_BLUEPRINT)}
        {getVerificationButton(getTranslation('content_type_drawing_3d'), VERIFICATION_IMAGE_CONTENT_TYPE.DRAWING_3D)}
        {getVerificationButton(getTranslation('content_type_drawing_illustration'), VERIFICATION_IMAGE_CONTENT_TYPE.DRAWING_ILLUSTRATION)}
        {getVerificationButton(getTranslation('content_type_chart_graph'), VERIFICATION_IMAGE_CONTENT_TYPE.CHART_GRAPH)}
        {getVerificationButton(getTranslation('content_type_chart_size'), VERIFICATION_IMAGE_CONTENT_TYPE.CHART_SIZE)}
        {getVerificationButton(getTranslation('content_type_chart_energy_certificate'), VERIFICATION_IMAGE_CONTENT_TYPE.CHART_ENERGY_CERTIFICATE)}
        {getVerificationButton(getTranslation('content_type_human_model_focus_top'), VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FOCUS_TOP)}
        {getVerificationButton(getTranslation('content_type_human_model_focus_bottom'), VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FOCUS_BOTTOM)}
        {getVerificationButton(getTranslation('content_type_human_model_full'), VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FULL)}
        {getVerificationButton(getTranslation('content_type_human_model_closeup'), VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_CLOSEUP)}
        {getVerificationButton(getTranslation('content_type_human_model_side'), VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_SIDE)}
        {getVerificationButton(getTranslation('content_type_human_model_back'), VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_BACK)}
        {getVerificationButton(getTranslation('content_type_human_model_head'), VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_HEAD)}
        {getVerificationButton(getTranslation('content_type_human_model_hands'), VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_HANDS)}
        {getVerificationButton(getTranslation('content_type_human_model_feet'), VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_FEET)}
        {getVerificationButton(getTranslation('content_type_human_model_sitting'), VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_SITTING)}
        {getVerificationButton(getTranslation('content_type_human_model_multi_perspective'), VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_MULTI_PERSPECTIVE)}
        {getVerificationButton(getTranslation('content_type_human_model_multi_variants'), VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_MULTI_VARIANTS)}
        {getVerificationButton(getTranslation('content_type_human_model_environment'), VERIFICATION_IMAGE_CONTENT_TYPE.HUMAN_MODEL_ENVIRONMENT)}
        {getVerificationButton(getTranslation('content_type_book_media_cover'), VERIFICATION_IMAGE_CONTENT_TYPE.BOOK_MEDIA_COVER)}
        {getVerificationButton(getTranslation('content_type_book_media_cover_back'), VERIFICATION_IMAGE_CONTENT_TYPE.BOOK_MEDIA_COVER_BACK)}
        {getVerificationButton(getTranslation('content_type_example_palette_color_material'), VERIFICATION_IMAGE_CONTENT_TYPE.EXAMPLE_PALETTE_COLOR_MATERIAL)}
        {getVerificationButton(getTranslation('content_type_example_one_material_color'), VERIFICATION_IMAGE_CONTENT_TYPE.EXAMPLE_ONE_MATERIAL_COLOR)}
        {getVerificationButton(getTranslation('content_type_example_one_pattern'), VERIFICATION_IMAGE_CONTENT_TYPE.EXAMPLE_ONE_PATTERN)}
        {getVerificationButton(getTranslation('content_type_bad_unrelated'), VERIFICATION_IMAGE_CONTENT_TYPE.BAD_UNRELATED)}
        {getVerificationButton(getTranslation('variants_0'), 'VARIANT_0')}
        {getVerificationButton(getTranslation('variants_1'), 'VARIANT_1')}
        {getVerificationButton(getTranslation('variants_2'), 'VARIANT_2')}
        {getVerificationButton(getTranslation('variants_3'), 'VARIANT_3')}
        {getVerificationButton(getTranslation('variants_4'), 'VARIANT_4')}
        {getVerificationButton(getTranslation('variants_5'), 'VARIANT_5')}

        {getVerificationButton(getTranslation('overlay_overlay_text'), VERIFICATION_IMAGE_OVERLAY.OVERLAY_TEXT)}
        {getVerificationButton(getTranslation('overlay_overlay_symbol'), VERIFICATION_IMAGE_OVERLAY.OVERLAY_SYMBOL)}
        {getVerificationButton(getTranslation('overlay_overlay_logo'), VERIFICATION_IMAGE_OVERLAY.OVERLAY_LOGO)}
        {getVerificationButton(getTranslation('overlay_overlay_campaign'), VERIFICATION_IMAGE_OVERLAY.OVERLAY_CAMPAIGN)}
        {getVerificationButton(getTranslation('overlay_overlay_measurement'), VERIFICATION_IMAGE_OVERLAY.OVERLAY_MEASUREMENT)}
        {getVerificationButton(getTranslation('overlay_overlay_closeup'), VERIFICATION_IMAGE_OVERLAY.OVERLAY_CLOSEUP)}
        {getVerificationButton(getTranslation('overlay_overlay_graphics'), VERIFICATION_IMAGE_OVERLAY.OVERLAY_GRAPHICS)}
        {getVerificationButton(getTranslation('overlay_overlay_false'), VERIFICATION_IMAGE_OVERLAY.OVERLAY_FALSE)}

        {getVerificationButton(getTranslation('watermark_watermark_true'), VERIFICATION_IMAGE_WATERMARK.WATERMARK_TRUE)}
        {getVerificationButton(getTranslation('watermark_watermark_false'), VERIFICATION_IMAGE_WATERMARK.WATERMARK_FALSE)}

        {getVerificationButton(getTranslation('delete'), 'DELETE')}
    </div>
}
