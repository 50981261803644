import React, {MouseEventHandler, useState} from "react"
import FiltersWrapper from "../FiltersWrapper"
import {TranslationSet} from "../../../Localization/i18n.constants";
import {useTranslation} from "react-i18next";

type FilterAttributeSectionProps = {
    section?: IFilterPanel_Section,
    level: number,
    demonstrationMode: boolean
}

/** @type TranslationKeys, keep this variable without TS type, for keep type check magic works*/
const ComponentTranslationKey = "FilterPanel"

/** @see FilterAttributeSectionDemo */
export default function FilterAttributeSection(props: FilterAttributeSectionProps) {

    // globals
    const {t} = useTranslation(ComponentTranslationKey)
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key)
    }

    const [state, setState] = useState({
        sectionFolded: (props.section && props.section.defaultFold) ? true : false,
    })

    const expandSectionFold: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        setState({sectionFolded: !state.sectionFolded})
    }

    const section = props.section
    if (!section) return null

    const html: React.ReactElement[] = []
    html.push(
        <div key={`head`} className="filter-panel-attribute-section-header"
             onClick={expandSectionFold}>
            {state.sectionFolded
                ? <img src="/resources/arrow-right.png" className="filter-panel-toggle-section" alt="(Expand)"/>
                : <img src="/resources/arrow-down.png" className="filter-panel-toggle-section open"
                       alt="(Collapse)"/>}
            {section.name}
        </div>
    )
    if (section.structures && !state.sectionFolded) {
        const subHtml: React.ReactElement[] = []
        for (let i = 0; i < section.structures.length; i++) {
            if (i > 0 && section.structures[i]) subHtml.push(<div key={`sep-${i}`}
                                                                  className={`filter-panel-attribute-separator`}></div>);
            subHtml.push(<FiltersWrapper key={i}
                                         item={section.structures[i]}
                                         level={props.level}
                                         demonstrationMode={props.demonstrationMode}/>)
        }
        html.push(
            <div key={`content`} className="filter-panel-attribute-section-content">
                {subHtml}
            </div>
        )
    }

    return <>{html}</>
}
