import React from "react";
import {useSelector} from 'react-redux';
import ProductCard from "./Card_Product";
import ManufacturerSeparator from "./Separator_Manufacturer";
import FamilyChainSeparator from "./Separator_FamilyChain";
import ShowSecondaryCard from "./Card_ShowSecondary";
import ProductSecondaryCard from "./Card_ProductSecondary";
import UnknownCard from "./Card_Unknown";
import ShowMoreSecondaryCard from "./Card_ShowMoreSecondary";
import CollectionCard from "./Card_Collection";
import CategoryCard from "./Card_Category";
import FoldedManufacturerSeparator from "./Separator_FoldedManufacturer";
import FoldedManufacturerCard from "./Card_FoldedManufacturer";
import ShowMorePrimaryCard from "./Card_ShowMorePrimary";
import ManufacturerCard from "./Card_Manufacturer";
import TopLevelSeparator from "./Separator_TopLevel";
import BrandingSeparator from "./Separator_Branding";
import FoldedBrandingSeparator from "./Separator_FoldedBranding";
import FoldedBrandingCard from "./Card_FoldedBranding";
import BrandingCard from "./Card_Branding";
import ErrorCard from "./Card_Error";
import CategorySeparator from "./Separator_Category";
import TopLevelBreadcrumbs from "./Separator_TopLevel/TopLevelBreadcrumbs";
import {convertSearchParamsToUrlResolveParams} from "../../Helpers/SearchHelper";
import SearchListNoFilterResult from "../ProductList/ProductList_NoFilterResults";
import {getDefaultLang} from "../../Store/Action/interactive";
import VendorSeparator from "./Separator_Vendor";
import ManufacturerContextSeparator from "./Separator_ManufacturerContext";
import {
    ExpandManufacturerSeparatorNew,
    FamilyChainSeparatorNew,
    FoldedManufacturerSeparatorNew,
    ManufacturerSeparatorNew
} from "./Separator_New/ManufacturerSeparatorNew";
import SearchListNoDataResult from "../ProductList/ProductList_NoDataResults";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";
import {buildSearchCategoryUrl} from "../../Helpers/UrlFormatter.Search";

const ComponentTranslationKey = "ProductsGrid"

export default function CardsList(props: {
    urlPageParams: IUrlResolvedParams,
    categoryItems: IProductList | undefined,
    topLevelAsBreadcrumbs: boolean,

    showEmptyMessage: boolean,

    useCardStyleBorders: boolean,
}) {

    const propsExtend = useSelector((state: IAppState) => ({
        categoryItems: props.categoryItems,
        topLevelAsBreadcrumbs: props.topLevelAsBreadcrumbs,

        urlPageParams: props.urlPageParams,
        lang: state.config.lang ? state.config.lang : getDefaultLang(),
        isDebugEnabled: state.config.debug,
        showEmptyMessage: props.showEmptyMessage,

        urlPageAdditionalParams: {
            page: props.urlPageParams.bridgeParams.page
        },

        useCardStyleBorders: props.useCardStyleBorders
    }))

    const {t} = useTranslation(ComponentTranslationKey)
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    const categoryItems = propsExtend.categoryItems;

    let previousSeparatorBrands: number[] = [];

    let previousSeparators: React.ReactElement[] = [];
    let currentCard: React.ReactElement | undefined = undefined;

    let listCards: React.ReactElement[] = [];
    if (categoryItems) {
        for (let i = 0; i < categoryItems.list.length; i++) {
            const item = categoryItems.list[i];
            currentCard = undefined;

            switch (item.itemType) {
                case IProductList_ItemType.Product: {
                    const productCardData = item as IProductListItem_Product;
                    currentCard = <ProductCard key={i + '-p' + productCardData.product.id}
                                               product={productCardData.product}
                                               debug={productCardData.debug}/>
                    break
                }
                case IProductList_ItemType.ProductSecondary: {
                    const productCardData = item as IProductListItem_ProductSecondary;
                    currentCard =
                        <ProductSecondaryCard key={i + '-ps' + productCardData.product.id}
                                              product={productCardData.product}/>
                    break
                }

                case IProductList_ItemType.ManufacturerSeparator: {
                    const manufacturerCardData = item as IProductListItem_ManufacturerSeparator;
                    previousSeparators.push(<ManufacturerSeparator key={i + '-m' + manufacturerCardData.id}
                                                                   withLink={false}
                                                                   name={manufacturerCardData.name}
                                                                   urlPageParams={propsExtend.urlPageParams}
                                                                   fold={manufacturerCardData.fold}
                                                                   isDebugEnabled={propsExtend.isDebugEnabled}
                                                                   id={manufacturerCardData.id}
                    />)
                    previousSeparatorBrands.push(manufacturerCardData.id)
                    break
                }
                case IProductList_ItemType.BrandingSeparator: {
                    const brandingCardData = item as IProductListItem_BrandingSeparator;
                    previousSeparators.push(<BrandingSeparator key={i + '-m' + brandingCardData.id}
                                                               withLink={false}
                                                               name={brandingCardData.name}
                                                               urlPageParams={propsExtend.urlPageParams}
                                                               fold={brandingCardData.fold}
                                                               isDebugEnabled={propsExtend.isDebugEnabled}
                                                               id={brandingCardData.id}
                    />)
                    previousSeparatorBrands.push(brandingCardData.id)
                    break
                }
                case IProductList_ItemType.FoldedManufacturerSeparator: {
                    const manufacturerCardData = item as IProductListItem_FoldedManufacturerSeparator;
                    previousSeparators.push(<FoldedManufacturerSeparator key={i + '-mf' + manufacturerCardData.id}
                                                                         withLink={false}
                                                                         id={manufacturerCardData.id}
                                                                         name={manufacturerCardData.name}
                                                                         urlPageParams={propsExtend.urlPageParams}
                                                                         fold={manufacturerCardData.fold}
                    />)
                    previousSeparatorBrands.push(manufacturerCardData.id)
                    break
                }
                case IProductList_ItemType.FoldedBrandingSeparator: {
                    const brandingCardData = item as IProductListItem_FoldedBrandingSeparator;
                    previousSeparators.push(<FoldedBrandingSeparator key={i + '-mf' + brandingCardData.id}
                                                                     withLink={false}
                                                                     id={brandingCardData.id}
                                                                     name={brandingCardData.name}
                                                                     urlPageParams={propsExtend.urlPageParams}
                                                                     fold={brandingCardData.fold}
                    />)
                    previousSeparatorBrands.push(brandingCardData.id)
                    break
                }
                case IProductList_ItemType.FoldedManufacturer: {
                    const manufacturerCardData = item as IProductListItem_FoldedManufacturer;
                    currentCard =
                        <FoldedManufacturerCard key={i + '-mf' + manufacturerCardData.id}
                                                name={manufacturerCardData.name}
                                                exampleImages={manufacturerCardData.exampleProducts}
                                                count={manufacturerCardData.count}
                                                urlPageParams={propsExtend.urlPageParams}
                                                fold={manufacturerCardData.fold}
                        />
                    break
                }
                case IProductList_ItemType.FoldedBranding: {
                    const brandingCardData = item as IProductListItem_FoldedBranding;
                    currentCard =
                        <FoldedBrandingCard key={i + '-mf' + brandingCardData.id}
                                            name={brandingCardData.name}
                                            exampleImages={brandingCardData.exampleProducts}
                                            count={brandingCardData.count}
                                            urlPageParams={propsExtend.urlPageParams}
                                            fold={brandingCardData.fold}
                        />
                    break
                }

                case IProductList_ItemType.FamilyChainSeparator: {
                    const familyChainCardData = (item as IProductListItem_FamilyChainSeparator).chain;

                    // do not show 'Other' families separator, if there is manufacturer separator right before that
                    // that means manufacturer have no specified families and family one have no any information
                    if (previousSeparators.length == 1 && familyChainCardData.length == 0)
                        break;

                    if (familyChainCardData.length) {
                        const brand = familyChainCardData[familyChainCardData.length - 1].brand
                        if (brand) {
                            if (!previousSeparatorBrands.includes(brand.id)) {
                                previousSeparators.push(<ManufacturerContextSeparator
                                    key={i + '-fb' + brand.id}
                                    id={brand.id}
                                    name={brand.name}
                                />)
                            }
                        }
                    }

                    previousSeparators.push(<FamilyChainSeparator
                        key={i + '-f' + (familyChainCardData.length ? familyChainCardData[familyChainCardData.length - 1].id : 'empty-chain')}
                        urlPageParams={propsExtend.urlPageParams}
                        isDebugEnabled={propsExtend.isDebugEnabled}
                        chain={familyChainCardData}/>)
                    break
                }
                case IProductList_ItemType.TopLevelSeparator: {
                    const topLevelCardData = (item as IProductListItem_TopLevelSeparator);
                    if (propsExtend.topLevelAsBreadcrumbs) {
                        currentCard = <TopLevelBreadcrumbs key={i + '-next'}
                                                           categoryId={topLevelCardData.categoryId}
                                                           categoryName={topLevelCardData.categoryName}
                                                           path={topLevelCardData.path}/>
                    } else {
                        previousSeparators.push(<TopLevelSeparator
                            key={i + '-f'}
                            categoryId={topLevelCardData.categoryId}
                            categoryName={topLevelCardData.categoryName}
                            urlPageParams={undefined}
                            fold={undefined}/>)
                    }
                    break
                }
                case IProductList_ItemType.SeparatorUniqueNamePath: {
                    const separatorUniqueNamePathData = (item as IProductListItem_SeparatorUniqueNamePath);

                    currentCard =
                        <div key={i + '-next'} className="unique-name-path-header-separator">
                            {`${separatorUniqueNamePathData.prefix} `}
                            {separatorUniqueNamePathData.filters && separatorUniqueNamePathData.filters.length > 0
                                ? separatorUniqueNamePathData.filters
                                    .map(({name}, i) => [
                                        i > 0 ? ", " : null,
                                        <span
                                            className="unique-name-path-header-separator-option">{name.toLocaleLowerCase()}</span>,
                                        separatorUniqueNamePathData.filters && (separatorUniqueNamePathData.filters.length - 1 == i) ? " " : null
                                    ])
                                : null}
                            {`${getTranslation('for')} `}
                            <span className="text-shopit-darkest-blue"> {separatorUniqueNamePathData.name}</span>
                        </div>
                    break
                }
                case IProductList_ItemType.SeparatorCategory: {
                    const categoryCardData = (item as IProductListItem_SeparatorCategory);
                    previousSeparators.push(<CategorySeparator
                        key={i + '-f'}
                        name={categoryCardData.categoryName}
                        url={buildSearchCategoryUrl(categoryCardData.categoryId, categoryCardData.categoryName)}/>)
                    break
                }
                case IProductList_ItemType.SeparatorVendor: {
                    const categoryCardData = (item as IProductListItem_VendorSeparator);
                    previousSeparators.push(<VendorSeparator
                        key={i + '-v'}
                        name={categoryCardData.name}/>)
                    break
                }

                case IProductList_ItemType.ShowSecondary: {
                    if (propsExtend.isDebugEnabled) {
                        const secondaryCardData = item as IProductListItem_ShowSecondary;
                        currentCard = <ShowSecondaryCard key={i + '-next'}
                                                         count={secondaryCardData.count}
                                                         exampleImages={secondaryCardData.exampleProducts}
                                                         filters={secondaryCardData.url_info.filter}
                                                         urlParams={convertSearchParamsToUrlResolveParams(secondaryCardData.url_info)}
                        />
                    }
                    break
                }
                case IProductList_ItemType.ShowMorePrimary: {
                    const modeSecondaryCardData = item as IProductListItem_ShowMorePrimary;
                    currentCard = <ShowMorePrimaryCard key={i + '-next'}
                                                       count={modeSecondaryCardData.count}
                                                       title={modeSecondaryCardData.title}
                                                       exampleImages={modeSecondaryCardData.exampleProducts}
                                                       filters={modeSecondaryCardData.url_info.filter}
                                                       urlParams={convertSearchParamsToUrlResolveParams({
                                                           ...modeSecondaryCardData.url_info,
                                                           ...propsExtend.urlPageAdditionalParams
                                                       })}
                    />
                    break
                }
                case IProductList_ItemType.ShowMoreSecondary: {
                    const modeSecondaryCardData = item as IProductListItem_ShowMoreSecondary;
                    currentCard = <ShowMoreSecondaryCard key={i + '-next'}
                                                         count={modeSecondaryCardData.count}
                                                         title={modeSecondaryCardData.title}
                                                         exampleImages={modeSecondaryCardData.exampleProducts}
                                                         filters={modeSecondaryCardData.url_info.filter}
                                                         urlParams={convertSearchParamsToUrlResolveParams({
                                                             ...modeSecondaryCardData.url_info,
                                                             ...propsExtend.urlPageAdditionalParams
                                                         })}
                    />
                    break
                }
                case IProductList_ItemType.Collection: {
                    const collectionCardData = item as IProductListItem_Collection;
                    if (collectionCardData.count > 0 || propsExtend.isDebugEnabled) {
                        currentCard = <CollectionCard key={i + '-c'}
                                                      category={{
                                                          id: collectionCardData.id,
                                                          name: collectionCardData.name,
                                                          path: collectionCardData.path,
                                                          exampleImages: collectionCardData.exampleProducts,
                                                          count: collectionCardData.count,
                                                          countSecondary: collectionCardData.countSecondary,
                                                      }}
                                                      appearance={{
                                                          filters: collectionCardData.url_info.filter,
                                                          showPath: categoryItems.title?.titleType != IProductList_TitleType.Category
                                                      }}
                                                      context={{
                                                          lang: propsExtend.lang,
                                                          debug: propsExtend.isDebugEnabled
                                                      }}
                                                      link={{
                                                          urlParams: convertSearchParamsToUrlResolveParams(collectionCardData.url_info),
                                                      }}/>
                    }
                    break
                }
                case IProductList_ItemType.Category: {
                    const categoryCardData = item as IProductListItem_Category;
                    if (categoryCardData.count > 0 || propsExtend.isDebugEnabled) {
                        currentCard = <CategoryCard key={i + '-c'}
                                                    category={{
                                                        id: categoryCardData.id,
                                                        name: categoryCardData.name,
                                                        path: categoryCardData.path,
                                                        exampleImages: categoryCardData.exampleProducts,
                                                        count: categoryCardData.count,
                                                        countSecondary: categoryCardData.countSecondary,
                                                    }}
                                                    appearance={{
                                                        filters: categoryCardData.url_info.filter,
                                                        showPath: categoryItems.title?.titleType != IProductList_TitleType.Category
                                                    }}
                                                    context={{
                                                        lang: propsExtend.lang,
                                                        debug: propsExtend.isDebugEnabled
                                                    }}
                                                    link={{
                                                        urlParams: convertSearchParamsToUrlResolveParams(categoryCardData.url_info),
                                                    }}/>
                    }
                    break
                }
                case IProductList_ItemType.Manufacturer: {
                    const manufacturerCardData = item as IProductListItem_Manufacturer;
                    currentCard = <ManufacturerCard key={i + '-c'}
                                                    name={manufacturerCardData.name}
                                                    imageId={manufacturerCardData.screenshot}/>
                    break
                }
                case IProductList_ItemType.Branding: {
                    const brandingCardData = item as IProductListItem_Branding;
                    currentCard = <BrandingCard key={i + '-c'}
                                                name={brandingCardData.name}
                                                imageId={brandingCardData.screenshot}/>
                    break
                }

                case IProductList_ItemType.Error: {
                    const errorCardData = item as IProductListItem_Error;
                    currentCard = <ErrorCard key={i}
                                             error={errorCardData.error}
                                             value={errorCardData.value}
                                             text={errorCardData.text}/>
                    break
                }
                case IProductList_ItemType.Unknown: {
                    const unknownCardData = item as IProductListItem_Unknown;
                    currentCard = <UnknownCard key={i}
                                               type={unknownCardData.type}
                                               subType={unknownCardData.subType}/>
                    break
                }

                //
                case IProductList_ItemType.ManufacturerSeparatorNew: {
                    const manufacturerCardData = item as IProductListItem_ManufacturerSeparator_New;
                    previousSeparators.push(<ManufacturerSeparatorNew key={i + '-m' + manufacturerCardData.id}
                                                                      name={manufacturerCardData.name}
                                                                      url={manufacturerCardData.url}
                    />)
                    previousSeparatorBrands.push(manufacturerCardData.id)
                    break
                }
                case IProductList_ItemType.FoldedManufacturerSeparatorNew: {
                    const manufacturerCardData = item as IProductListItem_FoldedManufacturerSeparator_New;
                    previousSeparators.push(<FoldedManufacturerSeparatorNew key={i + '-m' + manufacturerCardData.id}
                                                                            name={manufacturerCardData.name}
                                                                            url={manufacturerCardData.url}
                    />)
                    previousSeparatorBrands.push(manufacturerCardData.id)
                    break
                }
                case IProductList_ItemType.ExpandManufacturerSeparatorNew: {
                    const manufacturerCardData = item as IProductListItem_ExpandManufacturerSeparator_New;
                    currentCard = <ExpandManufacturerSeparatorNew key={i + '-m' + manufacturerCardData.id}
                                                                  name={manufacturerCardData.name}
                                                                  url={manufacturerCardData.url}
                    />
                    break
                }
                case IProductList_ItemType.FamilyChainSeparatorNew: {
                    const manufacturerCardData = item as IProductListItem_FamilyChainSeparatorNew;
                    previousSeparators.push(<FamilyChainSeparatorNew key={i + '-f'}
                                                                     name={manufacturerCardData.name}
                    />)
                    break
                }
                //

                default: {
                    currentCard = <div key={i}>Unknown_CategoryItemType</div>;
                    break
                }
            }

            if (currentCard !== undefined) {
                if (previousSeparators.length > 0) {
                    listCards.push(
                        <div className="category-item-decorable-card" key={"d" + listCards.length}>
                            <div className="category-item-separator-wrapper" key="sep">
                                <div className="flex-1"></div>
                                {previousSeparators}
                                <div className="flex-1"></div>
                            </div>
                            <div className="category-item-card-wrapper" key="card">{currentCard}</div>
                        </div>)
                    previousSeparators = [];
                    previousSeparatorBrands = [];
                } else {
                    listCards.push(currentCard)
                }
            }
        }
    }

    return (
        <div>

            <div
                className={`cards-list-container no-compensate-overlay-padding-small-screen mx-auto ${!propsExtend.useCardStyleBorders ? `no-card-coloring` : ``}`}>
                <div className={``}>

                    {listCards.length > 0
                        ? <div className={`flex flex-wrap gap-[18px]`}>{listCards}</div>
                        : propsExtend.showEmptyMessage
                            ? propsExtend.urlPageParams.bridgeParams.filters
                                ? <SearchListNoFilterResult/>
                                : <SearchListNoDataResult/>
                            : null}

                </div>
            </div>
        </div>
    );
}
