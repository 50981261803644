import React, {FunctionComponent} from 'react';
import {useTranslation} from "react-i18next";
import SearchRouteParamLink from "../../App/Links/SearchRouteParamLink";

type SearchListPaginationProps = {
    itemCount: number,
    itemPerPage: number,
    itemOffset: number,
    urlPageParams: IUrlResolvedParams
}

const SearchListPagination: FunctionComponent<SearchListPaginationProps> = function (props: SearchListPaginationProps) {

    const {t} = useTranslation("ListPagination");

    const pageLink = function (page: number) {
        return <SearchRouteParamLink
            className="cursor-pointer"
            key={`page${page}`}
            urlPageParams={{
                ...props.urlPageParams,
                bridgeParams: {
                    ...props.urlPageParams.bridgeParams,
                    page: page
                }
            }}
        >
            {page}
        </SearchRouteParamLink>
    }

    const pageCount = Math.ceil(props.itemCount / props.itemPerPage)
    if (pageCount <= 1)
        return <div className="pagination pagination-placeholder"></div>;

    const currentPage = props.itemOffset ? props.itemOffset / props.itemPerPage + 1 : 1;
    const showPages = 9;
    const diff = pageCount - currentPage;

    let before = Math.ceil(showPages / 2)
    let after = Math.ceil(showPages / 2)

    // Below middle point
    if (currentPage < Math.ceil(showPages / 2)) {
        before = currentPage;
        after = showPages - currentPage + 1
    }

    // At the end
    if (diff < Math.ceil(showPages / 2)) {
        before = before + (after - diff)
        after = diff
    }

    const pages = Array.from({length: pageCount}, (v, k) => {
        const i = k + 1;

        if (i === currentPage) {
            return <span key="current-page"
                         className="active"> {i} </span>
        }

        if (i > currentPage + after || i <= currentPage - before) {
            return null
        }

        return pageLink(i);
    })

    const previousPage = currentPage <= 1 ? (
        <span key={'previous-page'} className='btn btn-green previous inactive'>
            {t('previous')}
            </span>
    ) : (
        <SearchRouteParamLink
            key={'previous-page'}
            className='btn btn-green previous cursor-pointer'
            urlPageParams={{
                ...props.urlPageParams,
                bridgeParams: {
                    ...props.urlPageParams.bridgeParams,
                    page: currentPage - 1
                }
            }}
        >
            {t('previous')}
        </SearchRouteParamLink>
    )

    const nextPage = currentPage >= pageCount ? (
        <span key={'next-page'} className='btn btn-green next inactive'>
               {t('next')}
            </span>
    ) : (
        <SearchRouteParamLink
            key={'next-page'}
            className='btn btn-green next cursor-pointer'
            urlPageParams={{
                ...props.urlPageParams,
                bridgeParams: {
                    ...props.urlPageParams.bridgeParams,
                    page: currentPage + 1
                }
            }}
        >
            {t('next')}
        </SearchRouteParamLink>
    )

    let minPage = currentPage - before + 1
    let maxPage = currentPage + after

    const firstPage = minPage > 1
        ? pageLink(1)
        : null

    const secondPage = minPage > 3
        ? <span>...</span>
        : minPage == 3
            ? pageLink(2)
            : null

    const lastPage = maxPage < pageCount
        ? pageLink(pageCount)
        : null

    const preLastPage = maxPage < pageCount - 2
        ? <span>...</span>
        : maxPage == pageCount - 2
            ? pageLink(pageCount - 1)
            : null

    if (pageCount > 1)
        return (
            <div className="pagination">
                {previousPage}
                {firstPage}
                {secondPage}
                {pages}
                {preLastPage}
                {lastPage}
                {nextPage}
            </div>
        );

    return <div className="pagination pagination-placeholder"></div>;
}

export default SearchListPagination;