import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";
import {MetaTags} from "../../Components/App/Tags/Meta";
import {triggerLoadSayt} from "../../Store/Action/sayt";
import {getDefaultLang} from "../../Store/Action/interactive";
import ProductCard from "../../Components/ProductsGrid/Card_Product";
import UnknownCard from "../../Components/ProductsGrid/Card_Unknown";
import SaytCard from "../../Components/ProductsGrid/Card_Sayt";
import SaytSeparator from "../../Components/ProductsGrid/Separator_Sayt";
import {formatNumber} from "../../Helpers/StringFormatter.Number";
import {
    getAccessoryAndPartsAllLinkData,
    getAccessoryPartsLinkData,
    getBrandLinkData,
    getCategoryLinkData,
    getCategoryPathLinkData,
    getFamilyLinkData,
    getFreetextLinkData,
    getProductModelLinkData,
    getBrandHtml,
    getAccessoryPartsAllHtml,
    getAccessoryPartsHtml,
    getCategoryHtml,
    getCategoryPathHtml,
    getFamilyHtml,
    getFreeTextHtml,
    getProductModelHtml
} from "../../Components/SearchInput/SearchInputResult";

const ComponentTranslationKey = "SearchAsYouType"

export default function SearchDetailsRoute() {

    // globals
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    const propsExtend = useSelector((state: IAppState) => ({
        lang: state.config.lang ? state.config.lang : getDefaultLang(),
        isDebugEnabled: state.config.debug,
    }))

    const [state, setState] = useState<{ list: ISaytItems | undefined }>({
        list: undefined
    })

    let q = searchParams.get("q")
    const searchString = q ? q : ''

    useEffect(() => {
        if (searchString.length > 0) {
            window.scrollTo(0, 0)
            dispatch(triggerLoadSayt(searchString, propsExtend.lang, propsExtend.isDebugEnabled, true, (data) => {
                setState({
                    list: data
                })
            }));
        }
    }, [searchString]);

    const renderProduct = function (product: SaytUrlData & SaytProduct, key: string) {
        return <ProductCard key={'p' + key}
                            product={{
                                id: product.id,
                                gtinId: product.gtinId,
                                name: product.name || "",
                                description: undefined,
                                image_id: product.imageId || 0,
                                image_ids: [],
                                category_name: product.category?.name,
                                category_id: product.category?.id || 0,
                                category_not_listed: false,
                                featureAttributeMaxCount: product.featureAttributeMaxCount,
                                features: product.features,
                                manufacturer: product.manufacturer,
                                family_chain: product.families,
                                productOffersCountTotal: 0,
                                productOffersCountBuyable: 0,
                                count_videos: 0,
                                count_images: 0,
                                priceMinInclVat: product.price?.price_max_incl_vat,
                                priceCurrency: product.price?.price_currency
                            }}
                            debug={undefined}/>
    }

    const renderNonProduct = function (item:
                                           SaytGroupItem_Product |
                                           SaytGroupItem_Category |
                                           SaytGroupItem_CategoryPath |
                                           SaytGroupItem_Brand |
                                           SaytGroupItem_Family |
                                           SaytGroupItem_ProductModel |
                                           SaytGroupItem_AccessoryAndParts |
                                           SaytGroupItem_AccessoryAndPartsAll |
                                           SaytGroupItem_FreeText, key: string, groupDomKey: string) {


        let linkPathname: string = '';
        let linkSearch: string | undefined = undefined;

        switch (item.type) {
            case "PRODUCT": {
                return <UnknownCard key={key + '-p'}
                                    type={item.type}
                                    subType={""}/>
            }
            case "CATEGORY": {
                const categoryItem = {...item, level: 0} as SaytGroupItem_Category
                [linkPathname, linkSearch] = getCategoryLinkData(categoryItem, dispatch)
                return <SaytCard key={key + '-c'}
                                 to={{pathname: linkPathname, search: linkSearch}}
                                 text={categoryItem.categoryName}
                                 imageIds={categoryItem.product ? [categoryItem.product.imageId] : []}>
                    {getCategoryHtml(key + '-c', categoryItem, searchString, getTranslation)}
                </SaytCard>
            }
            case "CATEGORY_PATH": {
                const categoryItem = item as SaytGroupItem_CategoryPath
                [linkPathname, linkSearch] = getCategoryPathLinkData(categoryItem, dispatch)
                return <SaytCard key={key + '-cp'}
                                 to={{pathname: linkPathname, search: linkSearch}}
                                 text={categoryItem.categoryName}
                                 imageIds={[]}>
                    {getCategoryPathHtml(key + '-cp', categoryItem)}
                </SaytCard>
            }
            case "BRAND": {
                const brandItem = item as SaytGroupItem_Brand
                [linkPathname, linkSearch] = getBrandLinkData(brandItem, dispatch)
                return <SaytCard key={key + '-b'}
                                 to={{pathname: linkPathname, search: linkSearch}}
                                 text={brandItem.brandName}
                                 imageIds={brandItem.product ? [brandItem.product.imageId] : []}>
                    {getBrandHtml(key + '-b', brandItem, searchString, getTranslation)}
                </SaytCard>
            }
            case "FAMILY": {
                const brandItem = item as SaytGroupItem_Family
                [linkPathname, linkSearch] = getFamilyLinkData(brandItem, dispatch)
                return <SaytCard key={key + '-f'}
                                 to={{pathname: linkPathname, search: linkSearch}}
                                 text={brandItem.brandName ? brandItem.brandName : ''}
                                 imageIds={brandItem.product ? [brandItem.product.imageId] : []}>
                    {getFamilyHtml(key + '-f', brandItem, searchString, getTranslation)}
                </SaytCard>
            }
            case "PRODUCT_MODEL": {
                const unpItem = item as SaytGroupItem_ProductModel
                [linkPathname, linkSearch] = getProductModelLinkData(unpItem, dispatch)
                return <SaytCard key={key + '-pm'}
                                 to={{pathname: linkPathname, search: linkSearch}}
                                 text={unpItem.text}
                                 imageIds={unpItem.product ? [unpItem.product.imageId] : []}>
                    {getProductModelHtml(key + '-pm', unpItem, searchString, getTranslation)}
                </SaytCard>
            }
            case "ACCESSORY_PARTS": {
                const partsItem = item as SaytGroupItem_AccessoryAndParts
                [linkPathname, linkSearch] = getAccessoryPartsLinkData(partsItem, dispatch)
                return <SaytCard key={key + '-ap'}
                                 to={{pathname: linkPathname, search: linkSearch}}
                                 text={partsItem.text}
                                 imageIds={partsItem.product ? [partsItem.product.imageId] : []}>
                    {getAccessoryPartsHtml(key + '-ap', partsItem, searchString, getTranslation)}
                </SaytCard>
            }
            case "ACCESSORY_PARTS_ALL": {
                const partsItem = item as SaytGroupItem_AccessoryAndPartsAll
                [linkPathname, linkSearch] = getAccessoryAndPartsAllLinkData(partsItem, dispatch)
                return <SaytCard key={key + '-apa'}
                                 to={{pathname: linkPathname, search: linkSearch}}
                                 text={partsItem.text}
                                 imageIds={partsItem.product ? [partsItem.product.imageId] : []}>
                    {getAccessoryPartsAllHtml(key + '-apa', partsItem, searchString, getTranslation)}
                </SaytCard>
            }
            case "FREETEXT": {
                const freeItem = item as SaytGroupItem_FreeText
                [linkPathname, linkSearch] = getFreetextLinkData(freeItem, dispatch)
                return <SaytCard key={key + '-f'}
                                 to={{pathname: linkPathname, search: linkSearch}}
                                 text={freeItem.text}
                                 imageIds={[]}>
                    {getFreeTextHtml(key + '-f', freeItem)}
                </SaytCard>
            }
            default:
                return <UnknownCard key={key + '-u'}
                                    type={"Not implemented"}
                                    subType={""}/>
        }
    }

    const renderGrid = function () {

        if (!state.list) {
            return null;
        }

        let productRender = [];
        if (state.list) {
            for (const [key, valueRaw] of Object.entries(state.list.products)) {
                const itemSet = valueRaw as SaytProduct[];
                if (itemSet.length > 0) {
                    const header =
                        key == "product" ? getTranslation('product')
                            : key == "productSecondary" ? getTranslation('productSecondary')
                                : getTranslation('unknown')

                    productRender.push(
                        <div key={`${key}.prod-wrap`}>
                            <div className="list-sub-header mb-2" key={`${key}.header`}>
                                {header}
                            </div>
                            <div className={`flex flex-wrap gap-[18px]`}>
                                {itemSet.map((value, valueKey) => {
                                    return renderProduct(value as SaytUrlData & SaytProduct, `${key}.${valueKey}`)
                                })}
                            </div>
                        </div>);

                }
            }
        }

        let nonProductRender: (null | JSX.Element)[] = [];

        if (state.list) {

            state.list.nonProducts.forEach((group, key) => {
                const header =
                    group.type == "CATEGORY" ? getTranslation('category')
                        : group.type == "PRODUCT_MODEL" ? getTranslation('uniqueNamePath')
                            : group.type == "ACCESSORIES_PARTS" ? getTranslation('relatedUniqueNamePathCollection')
                                : group.type == "MANUFACTURERS" ? getTranslation('manufacturer')
                                    : group.type == "BRANDS" ? getTranslation('branding')
                                        : group.type == "STORES" ? getTranslation('vendor')
                                            : group.type == "FREETEXT" ? getTranslation('other')
                                                : group.type == "UNKNOWN" ? getTranslation('unknown')
                                                    : getTranslation('unknown')

                let previousSeparators: React.ReactElement[] = [];
                let currentCard: React.ReactElement | undefined = undefined;

                let listCards: React.ReactElement[] = [];
                for (let i = 0; i < group.items.length; i++) {
                    currentCard = undefined;
                    if (group.items[i].type == "CATEGORY_PATH") {
                        previousSeparators = [];
                        previousSeparators.push(<SaytSeparator key={i} url={group.items[i].url}
                                                               name={(group.items[i] as SaytGroupItem_CategoryPath).categoryName}/>)
                    } else {
                        currentCard = renderNonProduct(group.items[i], `${key.toString()}.${i}`, `${key}.group`)
                    }

                    if (currentCard !== undefined) {
                        if (previousSeparators.length > 0) {
                            listCards.push(
                                <div className="category-item-decorable-card" key={"d" + listCards.length}>
                                    <div className="category-item-separator-wrapper" key="sep">
                                        <div className="flex-1"></div>
                                        {previousSeparators}
                                        <div className="flex-1"></div>
                                    </div>
                                    <div className="category-item-card-wrapper" key="card">{currentCard}</div>
                                </div>)
                            previousSeparators = [];
                        } else {
                            listCards.push(currentCard)
                        }
                    }
                }

                nonProductRender.push(
                    <div key={`${key}.wrap`}>
                        <div className="list-sub-header" key={`${key}.header`}>
                            {header}
                        </div>
                        <div className={`flex flex-non-product flex-wrap gap-[18px]`}>
                            {listCards}
                        </div>
                    </div>);
            })
        }

        if (nonProductRender.length == 0 && productRender.length == 0) {
            return <div className="text-gray-500">
                {getTranslation('no_result')}
            </div>
        }

        return (<div
            className={`cards-list-container no-compensate-overlay-padding-small-screen mx-auto sayt-result-detail-list`}>

            {searchString
                && <div className={`filter-overlay-padding-animation`}>
                    <div
                        className="cards-list-container cards-list-container-small-fullscreen no-compensate-overlay-padding-small-screen mx-auto">
                        <div>
                            <div className="mt-8 flex justify-between">
                                <h2 className={`inline-block text-gray-500`}>
                                    {getTranslation('searchResultsFor')} "{searchString}"
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            }


            <div className={``}>
                {state.list && state.list.logData.timer
                    ? <div className={`text-gray-400 text-right -mb-2 pr-[5px] leading-3`}>
                        ({formatNumber(Math.round(state.list.logData.timer * 1000) / 1000, 3, 'en')} {getTranslation('seconds')})
                    </div>
                    : null}
                <div>{nonProductRender}</div>
            </div>

            <div className={``}>
                {state.list && state.list.logData.timerTitleMatch
                    ? <div className={`text-gray-400 text-right -mb-2 pr-[5px] leading-3`}>
                        ({formatNumber(Math.round(state.list.logData.timerTitleMatch * 1000) / 1000, 3, 'en')} {getTranslation('seconds')})
                    </div>
                    : null}
                <div>{productRender}</div>
            </div>
        </div>)
    }

    return (
        <div className={``}>

            <MetaTags title={`Shopit`}
                      canonicalPageUrl={`default`}
                      robots={`noindex`}/>

            <div>
                {renderGrid()}
            </div>
        </div>)
}