import React from "react";
import {CategoryPath} from "../CategoryPath";

export default function CategoryBreadcrumbs(props: { category: ICategory }) {
    return (
        <div>
            <div className="mt-8 justify-between pb-4">
                <CategoryPath category={props.category} uncategorized={false}/>
            </div>

        </div>
    );
}
