import {all, fork} from 'redux-saga/effects';

import {watchUserAction} from './user.saga';
import {watchSearchAction} from './search.saga';
import {watchSaytAction} from './sayt.saga';
import {watchProductAction} from './product.saga';
import {watchErrorAction} from './error.saga';
import {watchInteractiveAction} from "./interactive.saga";
import {watchLocalizationAction} from "./serverSettings.saga";
import {watchCacheAction} from "./cache.request.saga";
import {watchVerificationAction} from "./verification.saga";
import {watchLikeListAction} from "./productPreview.saga";
import {watchTrackingAction} from "./tracking.saga";
import {watchStoresAction} from "./stores.saga";

// wiki: saga is pattern what say to combine few different requests into single action

export const rootSaga = function* root() {
    yield all([
        fork(watchUserAction),
        fork(watchProductAction),
        fork(watchSearchAction),
        fork(watchCacheAction),
        fork(watchSaytAction),
        fork(watchInteractiveAction),
        fork(watchLocalizationAction),
        fork(watchErrorAction),
        fork(watchVerificationAction),
        fork(watchLikeListAction),
        fork(watchTrackingAction),
        fork(watchStoresAction),
    ]);
}