import {call, select, takeEvery} from 'redux-saga/effects'
import axios, {AxiosResponse} from "axios";
import {handleShopitApi, handleShopitApiRequestError} from "./helper"
import {CONFIG_DEBUG_SET_COOKIE, DebugUpdateActions} from "../Action/config.debug";
import {getAxiosApiConfigs, serializePostParams} from "../../Helpers/ApiHelper";

function* debugUpdate(action: DebugUpdateActions) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axios.post,
        `/api/preference/debug`,
        serializePostParams<IApiPreferenceDebugRequest>({
            debug: action.debugEnable ? "1" : "0",       // do not use boolean here. it like as a string...
            _logPageToken: logToken || null,
        }),
        getAxiosApiConfigs(undefined, "IS_DEBUG_TIMEOUT, notify developers"))

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        yield* handleShopitApi(res)     // todo: this call do nothing... need a processing, check how handleShopitApi using
    }
}

export function* watchInteractiveAction() {
    yield takeEvery(CONFIG_DEBUG_SET_COOKIE, debugUpdate);
}

