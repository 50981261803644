import React, {MouseEventHandler} from "react";
import {triggerCacheAccessProductsWithCallback} from "../../../Store/Action/cache.product";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {useNavigate} from "react-router-dom";

export default function ProductsCompareButton(props: { productIds: number[] }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {t} = useTranslation("CompareProducts")
    const getTranslation = function (key: keyof TranslationSet["CompareProducts"]) {
        return t(key);
    }

    const onCompareClick: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault()
        e.stopPropagation()

        if (props.productIds.length) {
            dispatch(triggerCacheAccessProductsWithCallback(props.productIds, () => {
                navigate({
                    pathname: '/compare/' + props.productIds.join(','),
                })
            }))
        }
    }

    return <div
        className={`btn btn-compare-blue ${props.productIds.length ? '' : 'no-products'} cursor-pointer uppercase`}
        onClick={onCompareClick}
        key="compare"
        title={props.productIds.length ? getTranslation('hint_compare_active') : getTranslation('hint_compare_inactive')}>
        {props.productIds.length
            ? <img src="/resources/product-compare-icon.png" alt="" className="mr-1 inline w-4"/>
            : <img src="/resources/product-compare-icon-gray.png" alt="" className="mr-1 inline w-4 opacity-50"/>}
        <div className={`hidden search-three-card:inline`}>
            {getTranslation('compare')}
        </div>
        {props.productIds.length
            ? ` (${props.productIds.length}) `
            : null}
    </div>
}