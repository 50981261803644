import CategoryBreadcrumbs from "../../Components/ProductList/ProductList_Breadcrumbs/CategoryBreadcrumbs";
import SpecificationsTable from "../../Components/Product/Specifications";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDefaultLang} from "../../Store/Action/interactive";
import {getCategoryCacheKey} from "../../Store/StoreHelper";
import {getProductCacheKey, triggerCacheRequestProductsWithCallback} from "../../Store/Action/cache.product";
import {useParams} from "react-router-dom";
import {staticCast} from "../../Helpers/TypeHelper";
import {MetaTags} from "../../Components/App/Tags/Meta";

export default function CompareRoute() {

    // global
    const dispatch = useDispatch()
    const params: Readonly<Partial<{ product_ids: string }>> = useParams();

    // extract global state
    const propsExtension = useSelector((state: IAppState) => {
        const productIds = params.product_ids ? params.product_ids.split(',').map((a: string) => parseInt(a)) : [];
        const productKeys = productIds.map((a: number) => {
            return getProductCacheKey(
                a,
                state.config.lang ? state.config.lang : getDefaultLang(),
                state.config.debug)
        })

        let products: (IProduct | IMissingProduct)[] = []
        let productsCacheState: (CacheState | undefined)[] = []
        let debugData: { [productId: number]: IProductDebugData } = {}

        for (let i = 0; i < productKeys.length; i++) {
            const cacheObject = state.cache.product[productKeys[i]] ? state.cache.product[productKeys[i]] : undefined;

            if (cacheObject && cacheObject.object) {
                products.push(cacheObject.object.product)
            } else {
                products.push(staticCast<IMissingProduct>({
                    id: parseInt(productKeys[i]),
                    gtinId: parseInt(productKeys[i]),
                    missing: true
                }))
            }

            if (cacheObject) {
                productsCacheState.push(cacheObject.cacheState)
            } else {
                productsCacheState.push(undefined)
            }

            const productDebugData = cacheObject?.object?.productDetails.object?.debugData;
            if (productDebugData) {
                debugData[parseInt(productKeys[i])] = productDebugData;
            }
        }

        const categoryId = (products.find(a => (a as IProduct).category_id) as IProduct)?.category_id;
        const categoryCacheKey = categoryId
            ? getCategoryCacheKey(
                categoryId,
                state.config.lang ? state.config.lang : getDefaultLang(),
                state.config.debug)
            : "";

        return {
            productIds: productIds,
            productsCacheState: productsCacheState,
            products: products,
            debugData: debugData,
            category: categoryId ? state.cache.category[categoryCacheKey]?.object : undefined,
        }
    })

    // lifecycle functions
    useEffect(() => {
        const products = propsExtension.products
        const productsCacheState = propsExtension.productsCacheState

        const missingProductIds = propsExtension.productIds.filter((id, index) => ((products[index] as IMissingProduct).missing && (productsCacheState[index] == undefined || productsCacheState[index] == CacheState.Unknown || productsCacheState[index] == CacheState.ServerNotResponse)))
        if (missingProductIds.length > 0) {
            dispatch(triggerCacheRequestProductsWithCallback(missingProductIds));
        }
        if (propsExtension.category?.id) {      // here need some check for category was changed
            window.scrollTo(0, 0)
        }
    }, [propsExtension.productIds]);

    // render
    return <div className="compare-page-container">

        <MetaTags title={`Shopit`}
                  canonicalPageUrl={`default`}
                  robots={`noindex`}/>

        {propsExtension.category
            ? <CategoryBreadcrumbs category={propsExtension.category}/>
            : null}

        <SpecificationsTable products={propsExtension.products}
                             debugData={propsExtension.debugData}
                             includeProductImage={true}/>
    </div>
}