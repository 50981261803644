import React from "react";

import {ProductImage} from "../../App/Common/Image";
import NotificationPanel from "../../App/Common/NotificationPanel";
import {PlaceholderText} from "../../App/Common/Placeholder";
import {SpinLoadingIndicator} from "../../App/Common/SpinLoadingIndicator";

/** @see DelayApiCallMessage */
export function DelayApiCallMessageDemo() {
    return <div className="delay-api-call-message">
        Delay API Call (Placeholder when something is loading)
    </div>
}

// ---

/** @see ProductImage */
export function ProductImageXsDemo() {
    return <div className={`inline-block border`}><ProductImage imageId={0} size="xs" alt={`Product Demo`}/></div>
}

/** @see ProductImage */
export function ProductImageSDemo() {
    return <div className={`inline-block border`}><ProductImage imageId={0} size="s" alt={`Product Demo`}/></div>
}

/** @see ProductImage */
export function ProductImageMDemo() {
    return <div className={`inline-block border`}><ProductImage imageId={0} size="m" alt={`Product Demo`}/></div>
}

/** @see ProductImage */
export function ProductImageLDemo() {
    return <div className={`inline-block border`}><ProductImage imageId={0} size="l" alt={`Product Demo`}/></div>
}

// ---

/** @see NotificationPanel */
export function NotificationPanelDemo() {
    return <NotificationPanel relative={true} messages={[
        {
            type: INotificationMessage_Type.Information,
            title: undefined,
            message: "Informative notification",
            key: "in",
            code: "ic",
        },
        {
            type: INotificationMessage_Type.Warning,
            title: undefined,
            message: "Warning notification",
            key: "wn",
            code: "wc",
        },
        {
            type: INotificationMessage_Type.Error,
            title: "Error",
            message: "Error notification",
            key: "en",
            code: "ec",
        }
    ]}/>
}

// ---

/** @see PlaceholderText */
export function PlaceholderTextDemo() {
    return <PlaceholderText seed={1234} wordsCount={20}/>
}

// ---

/** @see SpinLoadingIndicator */
export function SpinLoadingIndicatorDemo() {
    return <SpinLoadingIndicator message={`Loading indicator...`}/>
}
