import {getPageParamsFromSearchUrl, getUrlFromPageParams} from "./SearchHelper";
import {latinizeAndSimplify} from "./UrlFormatter";

export function buildSearchCategoryUrl(categoryId: number, categoryName: string) {
    return `/${latinizeAndSimplify(categoryName)}-c${categoryId}`
}

export function buildSearchUrl(params: IUrlResolvedParams, verbose: IUrlInfoContext): [string, string, boolean] {

    // category and collection format
    if (params.bridgeParams.categoryId) {
        const categoryId = params.bridgeParams.categoryId;
        const categoryName = (verbose.category ? latinizeAndSimplify(verbose.category[params.bridgeParams.categoryId]) : undefined) || `-`

        const filtersPart = getUrlFromPageParams({
            ...params,
            bridgeParams: {
                ...params.bridgeParams,
                categoryId: undefined,
            }
        })
        const filtersUrl = filtersPart.length ? `${filtersPart}` : ``;

        const actualLink = categoryName != '-';
        return [`/${categoryName}-c${categoryId}`, `${filtersUrl}`, actualLink];
    }

    if (params.bridgeParams.filters) {
        const filters = params.bridgeParams.filters.split(',').map(f => f.split(':', 2))

        const familyFilter = filters.find(f => f[0] == 's11')
        const manufacturerFilter = filters.find(f => f[0] == 's3')
        const brandFilter = filters.find(f => f[0] == 's4')
        const modelCategoryRelated = filters.find(f => f[0] == 's24')

        // family format
        if (familyFilter && (manufacturerFilter || brandFilter)) {
            const filtersStringPart = filters.filter(f => f[0] != 's11').map(f => f.join(':')).join(',')

            const familyId = parseInt(familyFilter[1]);
            const familyName = (verbose.family ? latinizeAndSimplify(verbose.family[familyId]) : undefined) || `-`

            const brandId = brandFilter ? parseInt(brandFilter[1]) : undefined;
            const manufacturerId = manufacturerFilter ? parseInt(manufacturerFilter[1]) : undefined;

            const brandName = verbose.brand && brandId ? verbose.brand[brandId] : undefined
            const manufacturerName = verbose.manufacturer && manufacturerId ? verbose.manufacturer[manufacturerId] : undefined

            const brandOrManufacturerName = (manufacturerName ? latinizeAndSimplify(manufacturerName) : latinizeAndSimplify(brandName)) || '-'

            const filtersPart = getUrlFromPageParams({
                ...params,
                bridgeParams: {
                    ...params.bridgeParams,
                    filters: filtersStringPart,
                }
            })
            const filtersUrl = filtersPart.length ? `${filtersPart}` : ``;

            const actualLink = brandOrManufacturerName != '-' && familyName != '-';
            return [`/${brandOrManufacturerName}-${familyName}-f${familyId}`, `${filtersUrl}`, actualLink];
        }

        // manufacturer format
        if (manufacturerFilter) {
            const filtersStringPart = filters.filter(f => f[0] != 's3').map(f => f.join(':')).join(',')

            const manufacturerId = parseInt(manufacturerFilter[1]);
            const manufacturerName = (verbose.manufacturer ? latinizeAndSimplify(verbose.manufacturer[manufacturerId]) : undefined) || `-`

            const filtersPart = getUrlFromPageParams({
                ...params,
                bridgeParams: {
                    ...params.bridgeParams,
                    filters: filtersStringPart,
                }
            })
            const filtersUrl = filtersPart.length ? `${filtersPart}` : ``;

            const actualLink = manufacturerName != '-';
            return [`/${manufacturerName}-m${manufacturerId}`, `${filtersUrl}`, actualLink];
        }

        // branding format
        if (brandFilter) {
            const filtersStringPart = filters.filter(f => f[0] != 's4').map(f => f.join(':')).join(',')

            const brandId = parseInt(brandFilter[1]);
            const brandName = (verbose.brand ? latinizeAndSimplify(verbose.brand[brandId]) : undefined) || `-`

            const filtersPart = getUrlFromPageParams({
                ...params,
                bridgeParams: {
                    ...params.bridgeParams,
                    filters: filtersStringPart,
                }
            })
            const filtersUrl = filtersPart.length ? `${filtersPart}` : ``;

            const actualLink = brandName != '-';
            return [`/${brandName}-b${brandId}`, `${filtersUrl}`, actualLink];
        }

        // unp format
        if (modelCategoryRelated) {
            const filtersStringPart = filters.filter(f => f[0] != 's24').map(f => f.join(':')).join(',')

            const unpId = parseInt(modelCategoryRelated[1]);
            const unpName = (verbose.unp ? latinizeAndSimplify(verbose.unp[unpId]) : undefined) || `-`

            const filtersPart = getUrlFromPageParams({
                ...params,
                bridgeParams: {
                    ...params.bridgeParams,
                    filters: filtersStringPart,
                }
            })
            const filtersUrl = filtersPart.length ? `${filtersPart}` : ``;

            const actualLink = unpName != '-';
            return [`/${unpName}-pm${unpId}`, `${filtersUrl}`, actualLink];
        }
    }

    // freetext
    if (params.bridgeParams.text) {
        const filtersPart = getUrlFromPageParams({
            ...params,
            bridgeParams: {
                ...params.bridgeParams,
                text: undefined,
            }
        })
        const filtersUrl = filtersPart.length ? `${filtersPart}` : ``;

        return [`/free/${encodeURI(params.bridgeParams.text)}`, `${filtersUrl}`, true];
    }

    const urlSearchPart = getUrlFromPageParams(params)
    const urlPathnamePart = '/search'
    return [urlPathnamePart, urlSearchPart, false]
}

function mergeFilters(query: string[]): string[] {
    const filtersOnly = query.filter(q => q.startsWith('f=')).map(f => f.substring(2))
    if (filtersOnly.length) {
        const nonFiltersOnly = query.filter(q => !q.startsWith('f='))
        nonFiltersOnly.push(`f=${filtersOnly.join(',')}`)
        return nonFiltersOnly
    }
    return query
}

export function parseUrlToSearchParams(pathname: string, search: string): IUrlResolvedParams {

    // url covert to legacy form
    const categoryMatch = pathname.match(/\/[\w-]+[\/-]c(?<category_id>\d+)(\/page(?<page>\d+))?/);
    if (categoryMatch && categoryMatch.groups) {
        let queryParts: string[] = [];

        if (categoryMatch.groups.category_id)
            queryParts.push(`c=${categoryMatch.groups.category_id}`)
        if (categoryMatch.groups.page)
            queryParts.push(`page=${categoryMatch.groups.page}`)

        if (search.indexOf('?') !== -1)
            queryParts.push(search.substring(1))
        else
            queryParts.push(search)

        return getPageParamsFromSearchUrl(queryParts.join('&'))
    }

    // manufacturer format
    const manufacturerMatch = pathname.match(/\/[\w-]+[\/-]m(?<manufacturer_id>\d+)(\/page(?<page>\d+))?/);
    if (manufacturerMatch && manufacturerMatch.groups) {
        let queryParts: string[] = [];

        if (manufacturerMatch.groups.manufacturer_id)
            queryParts.push(`f=s3:${manufacturerMatch.groups.manufacturer_id}`)
        if (manufacturerMatch.groups.page)
            queryParts.push(`page=${manufacturerMatch.groups.page}`)

        if (search.indexOf('?') !== -1)
            queryParts.push(search.substring(1))
        else
            queryParts.push(search)

        return getPageParamsFromSearchUrl(mergeFilters(queryParts).join('&'))
    }

    // brand format
    const brandMatch = pathname.match(/\/[\w-]+[\/-]b(?<manufacturer_id>\d+)(\/page(?<page>\d+))?/);
    if (brandMatch && brandMatch.groups) {
        let queryParts: string[] = [];

        if (brandMatch.groups.manufacturer_id)
            queryParts.push(`f=s4:${brandMatch.groups.manufacturer_id}`)
        if (brandMatch.groups.page)
            queryParts.push(`page=${brandMatch.groups.page}`)

        if (search.indexOf('?') !== -1)
            queryParts.push(search.substring(1))
        else
            queryParts.push(search)

        return getPageParamsFromSearchUrl(mergeFilters(queryParts).join('&'))
    }

    // unp
    const productModelMatch = pathname.match(/\/[\w-]+[\/-]pm(?<product_model>\d+)(\/page(?<page>\d+))?/);
    if (productModelMatch && productModelMatch.groups) {
        let queryParts: string[] = [];

        if (productModelMatch.groups.product_model)
            queryParts.push(`f=s24:${productModelMatch.groups.product_model}`)
        if (productModelMatch.groups.page)
            queryParts.push(`page=${productModelMatch.groups.page}`)

        if (search.indexOf('?') !== -1)
            queryParts.push(search.substring(1))
        else
            queryParts.push(search)

        return getPageParamsFromSearchUrl(mergeFilters(queryParts).join('&'))
    }

    // manufacturer format
    const familyMatch = pathname.match(/\/[\w-]+[\/-]f(?<family_id>\d+)(\/page(?<page>\d+))?/);
    if (familyMatch && familyMatch.groups) {
        let queryParts: string[] = [];

        if (familyMatch.groups.family_id)
            queryParts.push(`f=s11:${familyMatch.groups.family_id}`)
        if (familyMatch.groups.page)
            queryParts.push(`page=${familyMatch.groups.page}`)

        if (search.indexOf('?') !== -1)
            queryParts.push(search.substring(1))
        else
            queryParts.push(search)

        return getPageParamsFromSearchUrl(mergeFilters(queryParts).join('&'))
    }

    // freetext search
    const freeMatch = pathname.match(/\/free\/(?<text>[\w\d%+]+)(\/page(?<page>\d+))?/);
    if (freeMatch && freeMatch.groups) {
        let queryParts: string[] = [];

        if (freeMatch.groups.text)
            queryParts.push(`text=${freeMatch.groups.text}`)
        if (freeMatch.groups.page)
            queryParts.push(`page=${freeMatch.groups.page}`)

        if (search.indexOf('?') !== -1)
            queryParts.push(search.substring(1))
        else
            queryParts.push(search)

        return getPageParamsFromSearchUrl(queryParts.join('&'))
    }

    const simpleMatch = pathname.match(/\/search$/);
    if (simpleMatch) {
        return getPageParamsFromSearchUrl(search);
    }

    if (search.indexOf('?') !== -1)
        return getPageParamsFromSearchUrl(pathname + search)
    return getPageParamsFromSearchUrl(pathname + '?' + search)
}
