import {
    appendSearchParamsAttributeFilter, convertSearchParamsToUrlResolveParams,
    detachSearchParamAttributeFilter,
} from "../../../Helpers/SearchHelper";
import React, {Key, MouseEventHandler, useContext} from "react";
import FiltersWrapper from "../FiltersWrapper";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {getDefaultLang, triggerDebug, triggerToggleFilterOption} from "../../../Store/Action/interactive";
import {triggerCacheAccessSearchWithCallback} from "../../../Store/Action/cache.search";
import {selectSearchCache} from "../../../Store/StoreHelper";
import SearchRouteParamLink from "../../App/Links/SearchRouteParamLink";
import {SearchRouteContext} from "../../../Store/Context/SearchRouteContext";
import {formatNumber} from "../../../Helpers/StringFormatter.Number";
import {buildSearchUrl, parseUrlToSearchParams} from "../../../Helpers/UrlFormatter.Search";

const ComponentTranslationKey = "Attribute"

export default function FilterOptionSection(props: {
    option: IFilterPanel_Option,
    level: number,
    demonstrationMode: boolean,
    detachSearchParamAttributeFilterChildren?: Function
}) {

    // globals
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const searchContext = useContext(SearchRouteContext);

    const {t} = useTranslation(ComponentTranslationKey)
    const urlContext = useSelector((state: IAppState) => state.urlContext)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    // extract global state
    const propsExtension = useSelector((state: IAppState) => {
        const optionId = props.option ? props.option.id : "-1"
        const searchData = selectSearchCache(state, searchContext.cacheKey)
        const searchQuery = searchData && searchData.object ? searchData.object.searchQuery : undefined

        return {
            optionId: optionId,
            searchData: searchData,
            searchQuery: searchQuery,
            isExpanded: state.interactive.expandFilterOptions.indexOf(optionId) !== -1,
            isDebugEnabled: state.config.debug,
        }
    })

    const onToggleClick: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()

        if (props.demonstrationMode)
            return;

        if (props.option) {
            const optionId = props.option.id

            dispatch(triggerToggleFilterOption(optionId));

            let urlParams = parseUrlToSearchParams(location.pathname, location.search);

            const optionIndex = urlParams.openFilterOption ? urlParams.openFilterOption.indexOf(optionId) : -1;
            const nextExpandOptions = urlParams.openFilterOption ? [...urlParams.openFilterOption] : [];

            if (optionIndex !== -1) {
                nextExpandOptions.splice(optionIndex);
            } else {
                nextExpandOptions.push(optionId)
            }
            urlParams.openFilterOption = nextExpandOptions;

            dispatch(triggerCacheAccessSearchWithCallback(urlParams, false, propsExtension.isDebugEnabled, () => {
                    const [toPathName, toSearch, isValidLink] = buildSearchUrl(urlParams, urlContext)
                    if (!isValidLink) {
                        console.log("Search link error");
                        console.log(toPathName, toSearch, urlParams, urlContext)
                        const trace = new Error().stack
                        console.log(trace)
                        dispatch(triggerDebug("Search link error (console)", trace))
                    }

                    navigate({
                        pathname: toPathName,
                        search: toSearch
                    })
                }
            ))

        }
    }

    // render
    const option = props.option
    if (!option) return null

    const level = props.level

    const currentFilters: IProductListSearch_Filter[] = propsExtension.searchQuery && propsExtension.searchQuery.filter ? [...propsExtension.searchQuery.filter] : []

    const isVirtual = option.attributeType == "DIMENSION_VOLUME"
        || option.attributeType == "NUMERIC_RATIO"
        || option.attributeType == "NUMERIC_DIMENSION"

    let primaryCount = option.primaryCount
    let secondaryCount = option.count - option.primaryCount

    const isBoolean = option.attributeType == "PART" || option.attributeType == "BOOLEAN";
    const booleanClass = isBoolean ? "boolean" : ""
    if (booleanClass) {
        if (booleanClass && option.id.endsWith("_2")) {
            primaryCount = option.primaryCountTrue
            secondaryCount = option.countTrue - option.primaryCountTrue
        } else {
            primaryCount = option.primaryCountFalse
            secondaryCount = option.countFalse - option.primaryCountFalse
        }
    }

    var optionFilters
    if (option.checked) {
        optionFilters = detachSearchParamAttributeFilter(currentFilters, IProductListSearch_FilterType.Attribute, option.attributeId, option.id)
    } else {
        optionFilters = appendSearchParamsAttributeFilter(currentFilters, IProductListSearch_FilterType.Attribute, option.attributeId, isVirtual, option.id)
        if (booleanClass) {
            if (props.detachSearchParamAttributeFilterChildren) {
                optionFilters = props.detachSearchParamAttributeFilterChildren(option, optionFilters)
            }
        }
    }

    return (
        <div className={`filter-panel-option`}>
            <div
                className={`filter-panel-option-name-count-pair level-${level} ${option.checked ? 'checked' : ''}`}>
                <div className={`filter-panel-option-name`}>
                    {(
                        !isBoolean && option.structures && option.structures.length > 0
                            ? <div
                                className={`filter-panel-option-toggle`}
                                onClick={onToggleClick}>
                                {(
                                    (option.checked || option.hasChecked || propsExtension.isExpanded) ?
                                        <img
                                            // src={option.checked ? "/resources/arrow-down-white.png" : "/resources/arrow-down.png"}
                                            src="/resources/arrow-down.png"
                                            className="mx-auto"
                                            alt="(Expanded)"/> :
                                        <img
                                            // src={option.checked ? "/resources/arrow-right-white.png" : "/resources/arrow-right.png"}
                                            src="/resources/arrow-right.png"
                                            className="mx-auto"
                                            alt="(Do expand)"/>
                                )}
                            </div>
                            : <div className={`w-4 flex-none`}>&nbsp;</div>
                    )}

                    <SearchRouteParamLink
                        className={`filter-panel-option-header`}
                        urlPageParams={convertSearchParamsToUrlResolveParams({
                            ...propsExtension.searchQuery,
                            filter: optionFilters,
                        })}
                    >

                        <div>
                            <div className={`filter-panel-option-option ${booleanClass}`}>
                                {option.checked
                                    ? <img className="inline-block" src="/resources/boolean-yes.png"></img>
                                    : null}
                            </div>
                        </div>

                        <div>
                            {option.name ? option.name : '[Option]'}
                        </div>
                    </SearchRouteParamLink>
                </div>

                <div className={`filter-panel-primary-secondary-count-align`}>
                        <span
                            className={`filter-panel-primary-count`}>{formatNumber(primaryCount, 0, 'sv', undefined, ' ')}</span>
                    {propsExtension.isDebugEnabled
                        ? <span
                            className={`filter-panel-secondary-count`}>({formatNumber(secondaryCount, 0, 'sv', undefined, ' ')})</span>
                        : <span className={`filter-panel-secondary-count-placeholder`}></span>}
                </div>
            </div>

            {/*BOOLEAN option's substructure output from outside*/}
            {((option.checked || option.hasChecked || propsExtension.isExpanded) && !isBoolean && option.structures && option.structures.length > 0 ?
                option.structures.map((i: IFilterPanel_ObjectWrapper, key: Key) =>
                    <FiltersWrapper key={key} item={i} level={level}
                                    demonstrationMode={props.demonstrationMode}/>)
                : null)}
        </div>
    )
}