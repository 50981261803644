import React from "react";

type ErrorCardProps = {
    error: string,
    value: number
    text: string,

    className?: string,
};

export default function ErrorCard(props: ErrorCardProps) {
    return (
        <div className={`category-item-card ${props.className}`}>
            <div className="card-section-stretch text-center font-bold p-8">
                <div className="flex items-center justify-center">
                    <div>
                        <div className="text-gray-500">Error</div>
                        <div className="text-3xl text-gray-500">{props.text}</div>
                    </div>
                </div>
            </div>
            <div className="card-section-rigid px-4 text-center pt-2 pb-2 text-gray-500">
                "{props.error}" (value = {props.value})
            </div>
        </div>
    );
}
