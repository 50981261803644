import React from "react";
import {connect} from 'react-redux';

type UnknownCardProps = {
    type: string,
    subType: string,
    className?: string,
};

/** @see UnknownCardDemo */
export default function UnknownCard(props: UnknownCardProps) {
    return (
        <div className={`category-item-card ${props.className}`}>
            <div className="card-section-stretch text-center font-bold p-8">
                <div className="flex items-center justify-center">
                    <div>
                        <div className="text-3xl text-gray-500">{props.type}</div>
                    </div>
                </div>
            </div>
            <div className="card-section-rigid px-4 text-center pt-2 pb-2 text-gray-500">
                (unknown type of card, {props.subType})
            </div>
        </div>
    );
}
