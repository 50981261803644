import React from "react";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";

const ComponentTranslationKey = "RestrictedPage"

export default function RestrictedArea() {

    // global
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = (key: keyof TranslationSet[typeof ComponentTranslationKey]) => {
        return t(key);
    }

    return <div className="text-center text-3xl pt-48">
        <div className={`text-[5em] text-gray-100`}>403</div>
        <div className={`mt-8 text-gray-300`}>{getTranslation('need_to_be_logged')}</div>
    </div>
}