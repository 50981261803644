import {call, put, select, takeEvery} from 'redux-saga/effects'
import axios, {AxiosResponse} from "axios";
import {handleShopitApi, handleShopitApiRequestError, isAbortedError} from "./helper"
import {getSearchApiFromPageParams} from "../../Helpers/SearchHelper";
import {triggerEndLoadingOverlay} from "../Action/interactive";
import {selectSearchRequestOptions} from "../StoreHelper";
import {triggerProductVerifyAddData} from "../Action/session.verifyProduct";
import {
    CACHE_REQUEST_SEARCH_WITH_CALLBACK,
    CacheRequestSearchAction,
    triggerCacheSaveSearchFound,
    triggerCacheSaveSearchLoading,
    triggerCacheSaveSearchNotFound, triggerCacheSaveSearchNotRespond
} from "../Action/cache.search";
import {getAxiosApiConfigs, serializePostParams} from "../../Helpers/ApiHelper";
import {triggerUrlContextAdd} from "../Action/urlContext";

function* loadSearchRequest(action: CacheRequestSearchAction) {

    const searchQuery = getSearchApiFromPageParams(action.params)
    const searchOptions: IApiSearchRequestPostParams = yield select(selectSearchRequestOptions)

    const req = call(
        axios.post,
        `/api/search?${searchQuery}`,
        serializePostParams<IApiSearchRequestPostParams>(searchOptions),
        getAxiosApiConfigs(undefined, "SS_SEARCH_TIMEOUT, notify developers"));

    yield put(triggerCacheSaveSearchLoading(searchQuery, searchOptions));

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }

        if (!isAbortedError(error)) {
            if (action.isDebug) {
                yield* handleShopitApiRequestError(error)
            }
            yield put(triggerCacheSaveSearchNotRespond(searchQuery, searchOptions))

            yield put(triggerEndLoadingOverlay())
            if (action.callback) action.callback();
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiSearchResponse>(res)
        if (response) {
            yield put(triggerUrlContextAdd(response.urlContext));

            yield put(triggerCacheSaveSearchFound(
                searchQuery,
                searchOptions,
                response.data.category,
                response.data.topRankProducts,
                response.data.uniqueNamePathRelatedCategories,
                response.data.list,
                response.data.filters,
                response.data.searchQuery,
                response.data.responseDetails));

            if (response.data.shophunter) {
                yield put(triggerProductVerifyAddData(response.data.shophunter));
            }
        } else {
            if (res.data.__shopitServerError) {
                yield put(triggerCacheSaveSearchNotRespond(searchQuery, searchOptions))
            } else {
                yield put(triggerCacheSaveSearchNotFound(searchQuery, searchOptions));
            }
        }

        yield put(triggerEndLoadingOverlay())
        if (action.callback) action.callback();
    }
}

export function* watchSearchAction() {
    yield takeEvery(CACHE_REQUEST_SEARCH_WITH_CALLBACK, loadSearchRequest);
}

