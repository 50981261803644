import {getImageUrl} from "./ImageHelper";
import {buildProductUrl} from "./UrlFormatter.Product";
import {BreadcrumbList, ListItem, Product, WithContext} from "schema-dts";
import {replaceAiArtifacts} from "./StringFormatter.Text";
import {buildSearchCategoryUrl} from "./UrlFormatter.Search";
import {absoluteLink} from "./UrlFormatter";

export function convertProductToJsonLd(product: IProduct | undefined): WithContext<Product> | undefined {
    if (!product) return undefined;

    const [url, isValid] = product.gtinId
        ? buildProductUrl(product.gtinId, product.name, product.category_name)
        : buildProductUrl(product.id, product.name, product.category_name)
    if (!isValid) return undefined;

    let schema: WithContext<Product> = {
        "@context": "https://schema.org",
        "@type": "Product",

        name: product.name,
        description: replaceAiArtifacts(product.description),

        image: getImageUrl(product.image_id, 'xl'),
        url: absoluteLink(url),
    }

    const skuManufCode = product.codes?.find(c => c.type == 'SKU_MANUFACTURER')?.presentation;
    if (skuManufCode) {
        schema.mpn = skuManufCode;
    }

    const brandName = product.manufacturer?.name;
    if (brandName) {
        schema.brand = {
            "@type": "Brand",
            "name": product.manufacturer?.name
        }
    }

    if (product.priceMinInclVat || (product.productOffers && product.productOffers.length > 0)) {

        let priceCurrency = product.priceCurrency
        let highPrice = product.priceMaxInclVat
        let lowPrice = product.priceMinInclVat

        if (product.productOffers && product.productOffers.length > 0) {
            if (priceCurrency === undefined) priceCurrency = product.productOffers[0].currency
            if (highPrice === undefined) {
                highPrice = product.productOffers[0].priceInclVat
                for (const productOffer of product.productOffers) {
                    if (highPrice < productOffer.priceInclVat) {
                        highPrice = productOffer.priceInclVat
                    }
                }
            }
            if (lowPrice === undefined) {
                lowPrice = product.productOffers[0].priceInclVat
                for (const productOffer of product.productOffers) {
                    if (lowPrice > productOffer.priceInclVat) {
                        lowPrice = productOffer.priceInclVat
                    }
                }
            }
        }

        if (priceCurrency !== undefined && highPrice !== undefined && lowPrice !== undefined) {
            if (product.productOffers) {
                const urls: string[] = []
                for (const productOffer of product.productOffers) {
                    if (productOffer.productUrl) {
                        urls.push(productOffer.productUrl)
                    }
                }
                if (urls.length > 0) {
                    schema.offers = {
                        "@type": "AggregateOffer",
                        priceCurrency: priceCurrency,
                        highPrice: highPrice,
                        lowPrice: lowPrice,
                        offerCount: urls.length,
                        offers: urls.map(url => ({
                            "@type": "Offer",
                            url
                        }))
                    }
                }
            }
        } else {
            // log error
        }
    } else {
        // log error
    }

    if (!schema.offers)
        return undefined;

    return schema
}

export function convertCategoryPathToJsonLd(
    path: Array<{ id: number, name: string }> | undefined,
    lastElement: { name: string, url: string } | undefined): WithContext<BreadcrumbList> | undefined {

    if (!path) return undefined;

    let elements: ListItem[] = path.map((p, index): ListItem => ({
        "@type": "ListItem",
        position: index + 1,
        name: p.name,
        item: absoluteLink(buildSearchCategoryUrl(p.id, p.name))
    }))

    if (lastElement) {
        elements.push({
            "@type": "ListItem",
            position: elements.length + 1,
            name: lastElement.name,
            item: absoluteLink(lastElement.url)
        })
    }

    let schema: WithContext<BreadcrumbList> = {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": elements
    }

    return schema;
}