import React, {useEffect, useRef} from "react";
import {ProductImage} from "../App/Common/Image";
import {getMenuPositionForRect} from "../App/Layout/MenuContainer";
import {
    checkNodeParent,
    EVENT_MENU_CLOSE_CHECK,
    getEventMenuCloseCheck
} from "../../Helpers/EventHelper";

type MenuImagePreviewProps = {
    imageId: number,
    alt: string,
    size: 'xs' | 's' | 'm' | 'l' | 'xl',
    sourceX: number,
    sourceY: number,
    sourceW: number,
    sourceH: number,
    closeCallback: () => void | undefined
}

export function MenuImagePreview(props: MenuImagePreviewProps) {

    const ref = useRef<HTMLDivElement>(null)

    const onContextMenuCloseCheck = function (e: Event) {
        const clickNode: HTMLElement | undefined = (e as CustomEvent).detail;
        const menuNode: HTMLElement | null = ref.current;
        if (clickNode && menuNode) {
            if (!checkNodeParent(clickNode, menuNode)) {
                if (props.closeCallback !== undefined)
                    props.closeCallback();
            }
        }
    }

    useEffect(() => {
        // attach menu closer
        window.addEventListener(EVENT_MENU_CLOSE_CHECK, onContextMenuCloseCheck)

        return () => {
            // detach menu closer when component delete
            window.removeEventListener(EVENT_MENU_CLOSE_CHECK, onContextMenuCloseCheck)
        }
    }, [])

    const estimateMenuHeight = 280
    const estimateMenuWidth = 340
    const menuLocation = getMenuPositionForRect(props.sourceX, props.sourceY, props.sourceW, props.sourceH, estimateMenuWidth, estimateMenuHeight)

    return <div className={`bg-white p-2 js-verification-menu border border-gray-400 shadow-lg`}
                ref={ref}
                style={{
                    position: "fixed",
                    top: `${menuLocation.y}px`,
                    left: `${menuLocation.x}px`,
                    zIndex: 60
                }} onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        window.dispatchEvent(getEventMenuCloseCheck(e.target as HTMLElement))
    }}>
        <div className={``}>
            <ProductImage size={props.size}
                          alt={props.alt}
                          imageId={props.imageId}/>
        </div>

    </div>
}