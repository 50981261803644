import React from "react";
import {useLocation} from "react-router-dom";
import {emptyUrlParams} from "../Helpers/SearchHelper";
import SearchRoute from "./Search";
import {MetaTags} from "../Components/App/Tags/Meta";
import ProductRoute from "./Product";
import {parseProductUrl} from "../Helpers/UrlFormatter.Product";
import {parseUrlToSearchParams} from "../Helpers/UrlFormatter.Search";

export default function CustomRoute() {

    // globals
    const location = useLocation()

    // try to found route alias
    const productId = parseProductUrl(location.pathname, location.search)
    if (productId[0]) {
        return <ProductRoute/>;
    }

    const searchParams = parseUrlToSearchParams(location.pathname, location.search)
    if (!emptyUrlParams(searchParams.bridgeParams)) {
        return <SearchRoute/>;
    }

    // render
    return (<div className="404-page-not-found text-center text-3xl pt-48">

        <MetaTags title={`Shopit`}
                  canonicalPageUrl={`default`}
                  robots={`noindex`}/>

        <div className={`text-[5em] text-gray-100`}>404</div>
        <div className={`mt-8 text-gray-300`}>Page not found</div>
    </div>);
}