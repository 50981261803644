import React, {MouseEventHandler} from "react";
import {connect, DispatchProp} from 'react-redux';
import {IconTriChevronDown, IconTriChevronLeft} from "../../App/Icons";
import {FILTER_MANUFACTURER_KEY} from "../../../Helpers/Constants";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {useTranslation, withTranslation, WithTranslation} from "react-i18next";
import SearchRouteParamLink from "../../App/Links/SearchRouteParamLink";
import {getDebugProductToolUrl} from "../../../Helpers/BackendHelper";
import {Link} from "react-router-dom";
import SearchSilentRouteParamLink from "../../App/Links/SearchSilentRouteParamLink";
import {FourProductImages, ProductImage} from "../../App/Common/Image";

type ManufacturerSeparatorProps = {
    name: string
    url: string
};

const ComponentTranslationKey = "Separator"

/** @see ManufacturerSeparatorDemo */
export function ManufacturerSeparatorNew(props: ManufacturerSeparatorProps) {
    // globals
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    return (
        <Link
            className="category-item-separator flex flex-col text-shopit-blue"
            to={{pathname: '/search', search: props.url}}>
            <div className="whitespace-nowrap card-separator-text-rotation flex-none flex justify-center">
                <div className="inline-block">
                    <IconTriChevronLeft
                        style={{width: '1.3em', display: 'inline-block', marginRight: '6px', marginBottom: '3px',}}
                        color={`#3b7ec2`}/>
                    {props.name
                        ? props.name
                        : getTranslation('other_manufacturers')}
                </div>
            </div>
        </Link>
    );
}

type FoldedManufacturerSeparatorProps = {
    name: string
    url: string
};

/** @see FoldedManufacturerSeparatorDemo */
export function FoldedManufacturerSeparatorNew(props: FoldedManufacturerSeparatorProps) {
    // globals
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    return (
        <Link
            className="category-item-separator flex flex-col text-shopit-blue"
            to={{pathname: '/search', search: props.url}}>
            <div className="whitespace-nowrap card-separator-text-rotation flex-none flex justify-center">
                <div className="inline-block">
                    <IconTriChevronDown
                        style={{width: '1.3em', display: 'inline-block', marginRight: '6px', marginBottom: '3px',}}
                        color={`#3b7ec2`}/>
                    {props.name
                        ? props.name
                        : getTranslation('other_manufacturers')}
                </div>
            </div>
        </Link>
    );
}

type FamilyChainSeparatorProps = {
    name: string
};

/** @see FamilyChainSeparatorDemo */
export function FamilyChainSeparatorNew(props: FamilyChainSeparatorProps) {
    // globals
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    return (
        <div className="category-item-separator flex flex-col">
            <div
                className="whitespace-nowrap card-separator-text-rotation flex-none flex justify-center text-gray-500">
                {(
                    props.name
                        ? props.name
                        : getTranslation('no_product_family')
                )}
            </div>
        </div>
    );
}

type ExpandManufacturerSeparatorProps = {
    name: string
    url: string
};

const ComponentTranslationKey2 = "ShowMore"

export function ExpandManufacturerSeparatorNew(props: ExpandManufacturerSeparatorProps) {
    // globals
    const {t} = useTranslation(ComponentTranslationKey2)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey2]) {
        return t(key);
    }

    return (
        <Link
            to={{pathname: '/search', search: props.url}}
            className={`category-item-card category-item-card-secondary category-item-card-secondary-round`}>

            <div className="p-2 card-section-rigid">
                <div className="card-section-image">
                    {/*{props.exampleImages.length == 1*/}
                    {/*    ? <ProductImage imageId={props.exampleImages[0].image_id}*/}
                    {/*                    size="m"*/}
                    {/*                    imageClass={`image-aspect-ratio-square mix-blend-darken`}/>*/}
                    {/*    : <FourProductImages imageIds={props.exampleImages.map((i) => i.image_id)}*/}
                    {/*                         size="m" squareSize={true}*/}
                    {/*                         imageSize="s" imageClass="image-aspect-ratio-square mix-blend-darken"/>*/}
                    {/*}*/}
                    <ProductImage imageId={0}
                                  size="m"
                                  alt={props.name}
                                  imageClass={`image-aspect-ratio-square mix-blend-darken`}/>

                </div>
            </div>

            <div className="card-section-stretch flex flex-col justify-between">
                <div className="px-4 flex-none text-gray-500 mt-[10px] text-center">
                    {getTranslation("show_additional")}
                    {getTranslation("products")}{' '}
                    {getTranslation("from")}
                    <div>{props.name}</div>

                    {/* issue #38060 - ship filters on product list. this card only on product list */}
                    {/*{props.filters.length*/}
                    {/*    ? <div><Filters filters={props.filters}/></div>*/}
                    {/*    : null}*/}
                </div>
                <div className="px-4 flex-none text-gray-500 pb-4">
                </div>
            </div>

        </Link>
    );
}
