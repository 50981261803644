import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";
import {MetaTags} from "../../Components/App/Tags/Meta";

// ---

const ComponentTranslationKey = "Meta"

export default function AboutRoute(props: {}) {

    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className={`service-page-container pt-16`}>

            <MetaTags title={getTranslation('aboutTitle')}
                      canonicalPageUrl={`default`}
                      robots={undefined}/>

            <h1 className={`service-page-h1`}>Om Shopit</h1>

            <p className={`service-page-block`}>
                Shopit är en shoppingsökmotor för konsumenter som hjälper dig hitta precis den produkt du söker och
                att göra smartare köp. Vi har en av världens mest omfattande produktkategorisering och låter dig
                söka bland tiotals miljoner produkter för att hitta just den produkt som passar dina behov.
            </p>

            <p className={`service-page-block`}>
                Med Shopit kan du också jämföra priser, tillgänglighet och leveranstid från de nätbutiker som är anslutna.
            </p>

            <p className={`service-page-block`}>
                Genom att registrera dig som medlem hos Shopit får du dessutom fler fördelar. Du kan t ex spara
                produkter som du är intresserad av eller som du redan har köpt.
            </p>

            <p className={`service-page-block`}>
                Shopit är helt gratis för dig som konsument och vårt mål är att vägleda dig till rätt köp när du är
                redo att handla. Shopit får sina inkomster genom att ta en liten avgift från butikerna när du klickar
                dig vidare till deras webbplats. Men vi visar alltid alla priser vi känner till och vi sorterar dem efter pris.
            </p>

            <p className={`service-page-block`}>
                Välkommen att använda Shopit för en bättre shoppingupplevelse och att göra smartare köp!
            </p>
        </div>
    );
}
