import {createStore, applyMiddleware, compose} from 'redux'
import createSagaMiddleware from 'redux-saga'
import {Request, Response} from 'express'

import createRootReducers from './Reducer';
import {rootSaga} from './Saga'

import {saytInitialState} from "./Reducer/sayt.reducer";
import {serverSettingsInitialState} from "./Reducer/config.reducer";
import {userInitialState} from "./Reducer/session.reducer";
import {cacheInitialState} from "./Reducer/cache.reducer";
import {interactiveInitialState} from "./Reducer/interactive.reducer";
import {urlConfigInitialState} from "./Reducer/urlContext.reducer";

const sagaMiddleware = createSagaMiddleware()

const configureStore = async function (
    req: Request | undefined,
    res: Response | undefined,
    ...stateCreators: Array<(req: Request | undefined, res: Response | undefined, previousState: Partial<IAppState>) => Promise<Partial<IAppState> | undefined>>) {

    const composeEnhancer: typeof compose = (typeof window !== 'undefined' ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : undefined) || compose

    let initialState: IAppState = {
        cache: cacheInitialState,
        urlContext: urlConfigInitialState,

        interactive: interactiveInitialState,
        interactiveSayt: saytInitialState,

        session: userInitialState,
        config: serverSettingsInitialState,
    };

    for (let i = 0; i < stateCreators.length; i++) {
        const extendState = await stateCreators[i](req, res, initialState)
        if (extendState) {
            // non recursive
            initialState = {
                ...initialState,
                ...extendState
            }
        }
    }

    const store = createStore(
        createRootReducers(),
        initialState,
        composeEnhancer(
            applyMiddleware(sagaMiddleware)
        ));

    sagaMiddleware.run(rootSaga);

    // hot reloading
    if (module.hot) {
        // hot reloading
        module.hot.accept('./Reducer', () => {
            store.replaceReducer(createRootReducers());
        });
    }

    return store;
}

export default configureStore;
