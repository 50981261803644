import React, {useEffect} from "react";
import {MetaTags} from "../../Components/App/Tags/Meta";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";

// ---

const ComponentTranslationKey = "Meta"

export default function ToShopitRoute(props: {}) {

    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={`service-page-container pt-16`}>

            <MetaTags title={getTranslation(`addYourStoreTitle`)}
                      canonicalPageUrl={`default`}
                      robots={undefined}/>

            <h1 className={`service-page-h1`}>Att synas som butik på Shopit</h1>
            <h2 className={`service-page-h2`}>Så här funkar det</h2>

            <div className={`service-page-block`}>
                Som butik anger du en produkt-feed med dina priser och produkter som Shopit sedan läser in dagligen.
                Om du redan är med på andra sajter som t ex Google Shopping, Pricerunner eller Prisjakt kan man ofta
                använda någon av dessa feeder. Vi föredrar dock Google Shopping XML-feeds då dessa oftast innehåller
                all information som behövs.
            </div>

            <h3 className={`service-page-h3`}> Hur kommer min butik med på Shopit?</h3>
            <div className={`service-page-block`}>
                Du kan enkelt registrera dig och ange din produkt-feed via länken här nedan.
                Vi kontrollerar din butiks uppgifter, och sedan visar vi dina produkter och priser på Shopit.
            </div>
            <div className={`service-page-block`}>
                <a className={`btn btn-green uppercase`} href={`https://business.shopit.com/store/register`} target={`_blank`}>
                    Lägg till din butik
                </a>
            </div>
            <div className={`service-page-block service-page-block-double-spacing`}>
                Är du intresserad av att synas på Shopit eller har några frågor? Då går det bra att kontakta vår
                försäljningsansvarige Henrik Kjellberg via telefon <br/>+46 (0)70 615 05 41 eller mejl <a
                href="mailto:sales@shopit.com" className={`text-shopit-green`}>sales@shopit.com</a>
            </div>

            <h3 className={`service-page-h3`}> JUST NU - Prova kostnadsfritt i tre månader eller upp till 4 000 klick</h3>
            <div className={`service-page-block`}>
                Eftersom Shopit är en ny tjänst och det kommer att ta lite tid för oss att bygga upp trafik,
                samt att det är också viktigt för oss att ni som butik ska kunna testa vår tjänst i lugn och ro,
                så får man som ny butik vara med i tre månader helt kostnadsfritt, eller upp till 4 000 klick.
            </div>
            <div className={`service-page-block`}>
                Det enda vi önskar är att du lägger en länk till oss någonstans på er webbplats, vilket hjälper
                oss att snabbare bygga upp trafik till vår Shopit.se och därmed också mer trafik till er.
            </div>

            <h3 className={`service-page-h3`}>Hur mycket kostar det sedan att vara med på Shopit?</h3>
            <div className={`service-page-block`}>
                När testperioden är över väljer ni själva om ni vill börja betala för att fortsätta ha alla
                era produkter länkade. Ca 15-20% av era produkter kommer alltid att fortsätta att länkas utan kostnad
                så länge ni har en länk till vår sida på er sajt.
            </div>
            <div className={`service-page-block`}>
                Som kund på Shopit betalar du bara för den trafik vi skickar vidare till er butik, och du bestämmer
                dina kostnader på Shopit själv genom att sätta en budget vilket gör att du alltid har koll på dina utgifter.
            </div>
            <div className={`service-page-block`}>
                Vi har ett dynamiskt pris-per-klick (CPC) för besökare som skickas till er sajt, och priset varierar
                mellan olika produktkategorier. I jämförelse t ex de andra aktörerna på marknaden så ligger vi
                generellt 30-40% billigare per klick. Kontakta oss gärna för att få mer specifik information om våra priser.
            </div>
            <div className={`service-page-block`}>
                Du kan avsluta när du vill med endast en dags uppsägningstid.
            </div>

            <h3 className={`service-page-h3`}>Admin-sida för befintliga kunder</h3>
            <div className={`service-page-block`}>
                På er egen admin-sida kan ni logga in och bland annat se information om er feed,
                produkter, klickstatistik, samt sätta budget.
            </div>

            <h3 className={`service-page-h3`}>Vad är en produktfeed?</h3>
            <div className={`service-page-block`}>
                En produkt-feed är en fil som lagras på er butiks webbplats och som andra system kan hämta med hjälp
                av en länk. Den innehåller all information om produkterna och priserna i din butik.
                Vi läser in filen minst en gång per dag så dina erbjudanden alltid är aktuella på Shopit.
            </div>

        </div>
    );
}
