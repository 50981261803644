import React from "react";
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {useLocation} from "react-router-dom";

/** @type TranslationKeys, keep this variable without TS type, for keep type check magic works*/
const ComponentTranslationKey = "User.Settings.Tab.Description"

export default function DescriptionSettings() {

    // globals
    const dispatch = useDispatch()
    const location = useLocation()
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet["User"]["Settings"]["Tab"]["Description"]) {
        // i18next have problem with process '.' separated path to key as default
        // so we force a path with ':'
        // i tried to debug, but not understand how it works... but works
        // (copy comment for copy+paste multilevel translations functions)
        return t('User:Settings:Tab:Description:' + key)
    }

    // extract global state
    const user = useSelector((state: IAppState) => state.session.user)

    const onSaveSubmit = function (e: React.SyntheticEvent) {
        e.preventDefault();

        if (!user) return;

        // const target = e.target as typeof e.target & {
        //     address: { value: string }
        //     code: { value: string }
        //     city: { value: string }
        //     country: { value: string }
        //     currency: { value: string }
        //     language: { value: string }
        // };
        //
        // const address = target.address.value;
        // const code = target.code.value;
        // const city = target.city.value;
        // const country = target.country.value;
        // const currency = target.currency.value;
        // const language = target.language.value;
        //
        // this.props.dispatch(triggerUserSettings({
        //     ...getUserSettingsParams(user),
        //     address: address !== "" ? address : undefined,
        //     zipCode: code !== "" ? code : undefined,
        //     city: city !== "" ? city : undefined,
        //     country: country !== "" ? country : undefined,
        //     currency: currency !== "" ? currency : undefined,
        //     language: language !== "" ? language : undefined
        // }))
    }

    return <div>
        <div className="header">{getTranslation("title")}</div>
        <div className="sub-header">{getTranslation("sub_title")}</div>

        <form onSubmit={onSaveSubmit}>

            <div className="settings-row mt-8">
                <div className="settings-row-label">{getTranslation("avatar")}</div>
                <div className="settings-row-input"><input name="code" type="password"/>
                </div>
            </div>

            <div className="settings-row mt-4">
                <div className="settings-row-label">{getTranslation("description")}</div>
                <div className="settings-row-input">
                    <textarea/>
                </div>
            </div>

            <div className="settings-row mt-4">
                <div className="settings-row-label">{getTranslation("background")}</div>
                <div className="settings-row-input"><input name="code" type="password"/>
                </div>
            </div>

            <div className="mt-4">
                <button className="btn btn-green text-sm w-full py-2" type="submit">Save</button>
            </div>

        </form>
    </div>
}
