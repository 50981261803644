import {call, put, select} from 'redux-saga/effects'
import {AxiosResponse} from "axios";
import {triggerError, triggerMessage} from "../Action/interactive";
import {getAxiosErrorDetails} from "../../Helpers/ApiHelper";

// for call generator function from generator function, use yield* handleShopitApi()
export const handleShopitApi = function* <T = any>(res: AxiosResponse, callback?: IApiMessageHandler) {
    const data: IApiResponse = res.data;

    if (data && data.__shopitErrors) {

        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            for (let i = 0; i < data.__shopitErrors.length; i++) {
                if (callback) {
                    callback(data.__shopitErrors[i])
                } else {
                    yield put(triggerError(data.__shopitErrors[i].code, data.__shopitErrors[i].name, data.__shopitErrors[i].message))
                }
            }
        }
    }
    if (data && data.__shopitMessages) {
        for (let i = 0; i < data.__shopitMessages.length; i++) {
            if (callback) {
                callback(data.__shopitMessages[i])
            } else {
                yield put(triggerMessage(data.__shopitMessages[i].code, data.__shopitMessages[i].message))
            }
        }
    }
    if (data && data.__shopitData) {
        return data.__shopitData as T
    }
    return undefined;
}

export const handleShopitApiRequestError = function* (error: any, callback?: IApiMessageHandler) {
    if (error.isAxiosError) {
        // code ECONNABORTED for timeout or for CANCEL mode
        // message contains timeout text or "Request aborted"
        if (callback) {
            callback({
                code: error.code,
                name: error.name,
                message: error.message + "\n" + getAxiosErrorDetails(error),
            })
        } else {
            yield put(triggerError(
                error.code,
                error.name,
                error.message + "\n" + getAxiosErrorDetails(error)))
        }
    } else {
        if (callback) {
            callback({
                code: error.code,
                name: error.name,
                message: error.message,
            })
        } else {
            yield put(triggerError(
                error.code,
                error.name,
                error.message))
        }
    }
}

export const isAbortedError = function (error: any) {
    if (error.isAxiosError && error.code == "ECONNABORTED" && error.message == "Request aborted") {
        return true;
    }
    return false
}