import {Link, useLocation} from "react-router-dom";
import React from "react";
import {triggerUserLoginRecoverAccept} from "../../../Store/Action/session.login";
import {getConfirmPageParamsFromUrl} from "../../../Helpers/RegistrationHelper";
import {useDispatch} from "react-redux";
import {MetaTags} from "../../App/Tags/Meta";

export default function FiltersBar() {

    // globals
    const dispatch = useDispatch()
    const location = useLocation()

    // extract global state
    const searchQuery = getConfirmPageParamsFromUrl(location.search);

    const code = searchQuery.code
    const userId = searchQuery.userId

    // render
    const onPasswordRecoverySubmit = (e: React.SyntheticEvent) => {
        e.preventDefault();

        if (userId && code) {
            const target = e.target as typeof e.target & {
                password: { value: string },
                password_repeat: { value: string },
            };

            const password = target.password.value;
            const password_repeat = target.password_repeat.value;

            dispatch(triggerUserLoginRecoverAccept(userId, code, password, password_repeat));
        }
    }

    return <div className="relative z-10">

        <MetaTags title={`Shopit`}
                  canonicalPageUrl={`default`}
                  robots={`noindex`}/>

        <div className="modal-shade-overlay"/>
        <div className="modal-wrapper-space">
            <div className="modal-wrapper-vertical-align text-center">

                <div className="password-reset-new-password-wrapper">

                    <div className="flex-1 p-6 flex items-center">
                        <div className="w-full">
                            <h3 className="text-xl font-medium text-gray-900">
                                Password Recovery
                            </h3>

                            <div className="text-base mt-8">
                                <form onSubmit={onPasswordRecoverySubmit}>
                                    <div>
                                        <div className="text-sm">New password</div>
                                        <input type="password" name="password" placeholder="*********"
                                               className="p-1 mt-1 w-full border focus:ring-shopit-green focus:border-shopit-green focus:ring-1 focus:outline-none"/>
                                    </div>

                                    <div className="mt-4">
                                        <div className="flex justify-between">
                                            <div className="text-sm">Repeat new password</div>
                                        </div>
                                        <input type="password" name="password_repeat" placeholder="*********"
                                               className="p-1 mt-1 w-full border focus:ring-shopit-green focus:border-shopit-green focus:ring-1 focus:outline-none"/>
                                    </div>

                                    <div className="mt-8">
                                        <button className="btn btn-green text-sm w-full py-2"
                                                type="submit">Set new password
                                        </button>
                                    </div>

                                    <div className="mt-2 text-right">
                                        <Link
                                            to={'/recover/accept?u=' + userId + '&u=' + code}
                                            className="text-sm text-shopit-green cursor-pointer">Decline password
                                            recovery
                                        </Link>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>

                    <div className="flex-1 flex-grow-[1.6] modal-password-recovery-image-bg relative">
                        <div className="absolute top-0 right-0 left-0 bottom-0 bg-shopit-green/50">
                            <div className="p-6">
                                <div className="text-2xl text-white font-bold mt-[8.5rem] tracking-wide">
                                    Back in a moment!
                                </div>
                                <div className="text-lg text-white italic mt-2">
                                    Nice you see you with the Shopit
                                </div>
                            </div>
                        </div>
                        <div className="absolute right-6 bottom-4 text-gray-700">
                            <a href="https://unsplash.com/@khloephoto" target="_blank">Photo by Khloe Arledge</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
}