import React from "react";
import SearchRouteParamLink from "../../App/Links/SearchRouteParamLink";

type TopLevelSeparatorProps = {
    categoryId: number
    categoryName: string
    path: Array<{
        id: number,
        name: string
    }>
};

export default function TopLevelBreadcrumbs(props: TopLevelSeparatorProps) {
    return <div className={`w-full border-b pb-2`}>
        <img src="/resources/icon-category-green.png" alt="" className={`inline-block mr-2`}/>
        {
            props.path.map((item, index) =>
                <React.Fragment key={item.id}>
                    {(index > 0 ? <span className="text-shopit-green text-xl font-bold"> » </span> : '')}
                    <SearchRouteParamLink
                        className="text-shopit-green text-xl font-bold"
                        urlPageParams={{
                            bridgeParams: {
                                categoryId: item.id,
                                language: undefined,
                                color: undefined,
                                filters: undefined,
                                expand: undefined,
                                expandedBrands: undefined,
                                secondaryExpand: undefined,
                                showSecondary: undefined,
                                fold: undefined,
                                foldedBrands: undefined,
                                page: undefined,
                                size: undefined,
                                text: undefined,
                                code: undefined,
                                sort: undefined,
                                group: undefined,
                            },
                            showFilters: undefined,
                            openFilterOption: [],
                            openUnpOptions: [],
                        }}
                    >
                        {item.name}
                    </SearchRouteParamLink>
                </React.Fragment>)
        }
    </div>
}
