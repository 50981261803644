import React, {useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {Dialog} from "@headlessui/react";
import {triggerUserLoginShopit} from "../../../Store/Action/session.login";
import {convertApiMessageToNotificationMessage, NotificationMessage} from "../../App/Common/NotificationMessage";

const ComponentTranslationKey = "Login"

type ModalLoginState = {
    emailError: boolean,
    emailErrorMessages: INotificationMessage[],
    passwordError: boolean,
    passwordErrorMessages: INotificationMessage[],
    otherMessages: INotificationMessage[],
    messageCounter: number
}

export default function ModalLogin(props: {
    isModalOpen: boolean
    onModalClose: () => void
    onSignUpClick: () => void
    onForgotPasswordClick: () => void
}) {

    // globals
    const dispatch = useDispatch()
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    const [state, setState] = useState<ModalLoginState>({
        emailError: false,
        emailErrorMessages: [],
        passwordError: false,
        passwordErrorMessages: [],
        otherMessages: [],
        messageCounter: 0
    })

    const propsExtend = useSelector((state: IAppState) => ({
        isModalOpen: props.isModalOpen,
        onModalClose: props.onModalClose,
        onSignUpClick: props.onSignUpClick,
        onForgotPasswordClick: props.onForgotPasswordClick,
    }))

    const onLoginError = function (e: IApiResponseError | IApiResponseMessage) {
        const message = convertApiMessageToNotificationMessage(e, 'msg-' + state.messageCounter)

        if (e.code == "7") {
            // password related error
            setState({
                ...state,
                passwordError: true,
                passwordErrorMessages: [...state.passwordErrorMessages, message],
                messageCounter: state.messageCounter
            })
        } else if (e.code == "5") {
            // email related error
            setState({
                ...state,
                emailError: true,
                emailErrorMessages: [...state.emailErrorMessages, message],
                messageCounter: state.messageCounter
            })
        }
        else {
            setState({
                ...state,
                otherMessages: [...state.otherMessages, message],
                messageCounter: state.messageCounter
            })
        }
    }

    const onLoginSubmit = function (e: React.SyntheticEvent) {
        e.preventDefault();

        const target = e.target as typeof e.target & {
            email: { value: string };
            password: { value: string };
        };

        const email = target.email.value;           // do typecheck?
        const password = target.password.value;

        dispatch(triggerUserLoginShopit(email, password, onLoginError));
    }

    return <Dialog as="div" className="relative z-50"
                   open={propsExtend.isModalOpen}
                   onClose={propsExtend.onModalClose}>
        <div className="modal-shade-overlay"/>
        <div className="modal-wrapper-space">
            <div className="modal-wrapper-vertical-align text-center">

                <Dialog.Panel className="modal-login-wrapper px-24 pb-6 pt-32 relative">

                    <div className={`absolute top-4 right-4 cursor-pointer`}
                         onClick={propsExtend.onModalClose}>
                        <img src={`/resources/close-icon.png`} alt={`Close`}/>
                    </div>

                    <Dialog.Title as="h2">
                        {getTranslation('log_in')}
                    </Dialog.Title>

                    <div className="text-base mt-8">
                        <form onSubmit={onLoginSubmit}>
                            <div>
                                <div className="text-sm">{getTranslation('email')}</div>
                                <input type="email" name="email" placeholder="name@company.com"
                                       className="p-1 mt-1 w-full border focus:ring-shopit-green focus:border-shopit-green focus:ring-1 focus:outline-none"/>
                            </div>
                            <div className="mt-4">
                                <div className="flex justify-between">
                                    <div className="text-sm">{getTranslation('password')}</div>
                                </div>
                                <input type="password" name="password" placeholder="*********"
                                       className="p-1 mt-1 w-full border focus:ring-shopit-green focus:border-shopit-green focus:ring-1 focus:outline-none"/>
                            </div>

                            <div className="mt-4 relative">
                                <button className="btn btn-green btn-bold btn-uppercase text-sm w-full py-2"
                                        type="submit">{getTranslation('login')}
                                </button>

                                <div className={`absolute -top-[30px] -right-[300px] z-50`}>
                                    {state.emailErrorMessages?.map((message, index) =>
                                        <NotificationMessage message={message} key={`ek${index}`}/>)}
                                    {state.passwordErrorMessages?.map((message, index) =>
                                        <NotificationMessage message={message} key={`pk${index}`}/>)}
                                    {state.otherMessages?.map((message, index) =>
                                        <NotificationMessage message={message} key={`ok${index}`}/>)}
                                </div>
                            </div>

                            <div className="mt-2 flex justify-between items-baseline">
                                <div></div>
                                {/*<div className="text-sm text-shopit-green cursor-pointer"*/}
                                {/*     onClick={onSignUpClick}>Sign up*/}
                                {/*</div>*/}
                                <div className="text-sm text-shopit-green cursor-pointer"
                                     onClick={propsExtend.onForgotPasswordClick}>{getTranslation('forgot_password')}
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className="flex items-center mt-8">
                        <div className="flex-1 h-1 border-t"></div>
                        <div className="flex-none px-2 text-gray-500">{getTranslation('or')}</div>
                        <div className="flex-1 h-1 border-t"></div>
                    </div>

                    <div className="mt-4">
                        <div
                            className="text-sm block btn btn-compare-blue btn-bold btn-uppercase py-2 px-2 rounded cursor-pointer text-center w-full"
                            onClick={propsExtend.onSignUpClick}>
                            {getTranslation('create_new_user')}
                        </div>
                    </div>

                </Dialog.Panel>
            </div>
        </div>
    </Dialog>
}
