import {createContext} from "react";
import {getDefaultLang} from "../Action/interactive";
import {getSearchCacheKey} from "../Action/cache.search";

export type SearchRouteContextType = {
    serializedParams: string | undefined,
    cacheKey: string | undefined
}

export const SearchRouteContext = createContext<SearchRouteContextType>({
    serializedParams: undefined,
    cacheKey: undefined,
});

export function makeSearchRouteContext(searchKey: string | undefined, lang: string, debug: boolean): SearchRouteContextType {
    if (searchKey) {
        const hash = getSearchCacheKey(searchKey, lang, debug)
        return {
            serializedParams: searchKey,
            cacheKey: hash
        }
    }
    return {
        serializedParams: undefined,
        cacheKey: undefined,
    }
}