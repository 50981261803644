import {
    FILTER_ATTRIBUTE,
    FILTER_CODE,
    FILTER_COLOR,
    FILTER_NUMERIC,
    FILTER_NUMERIC_DIMENSION,
    FILTER_NUMERIC_RANGE,
    FILTER_NUMERIC_RATIO,
    FILTER_SUB_TYPE_ID,
    FILTER_TEXT,
    STATIC_FILTER_MAP
} from "./Constants";

// ---

const searchParamsFilterToString = function (filter?: IProductListSearch_Filter[]): string | undefined {
    if (filter) {
        return filter.map((i): string => {
            if (i._type == IProductListSearch_FilterType.Attribute) {
                const attributeFilter = i as IProductListSearch_AttributeFilter
                if (attributeFilter.is_virtual) {
                    return attributeToString(attributeFilter.id, attributeFilter.data, '')
                } else {
                    return attributeToString(attributeFilter.id, attributeFilter.data)
                }

            } else if (i._type == IProductListSearch_FilterType.NumericDimension
                || i._type == IProductListSearch_FilterType.NumericRange
                || i._type == IProductListSearch_FilterType.NumericRatio) {
                const numericFilter = i as IProductListSearch_DimensionNumericFilter_Unused
                return attributeToString(numericFilter.id, numericFilter.data, '')

            } else if (i._type == IProductListSearch_FilterType.Numeric) {
                const numericFilter = i as IProductListSearch_NumericFilter
                return numericToString(`a${numericFilter.id}`, numericFilter.low, numericFilter.high)

            } else if (i._type == IProductListSearch_FilterType.Color) {
                // color have own param of search, it out from filters and have own function
                return "";

            } else if (i._type == IProductListSearch_FilterType.Text) {
                // text have own param of search, it out from filters and have own function
                return "";

            } else if (i._type == IProductListSearch_FilterType.Code) {
                // code have own param of search, it out from filters and have own function
                return "";

            } else if (i._type == IProductListSearch_FilterType.Price) {
                const priceFilter = i as IProductListSearch_PriceFilter
                return staticToString(priceFilter._type, [], priceFilter.low, priceFilter.high)
            } else if (i._type == IProductListSearch_FilterType.Brand
                || i._type == IProductListSearch_FilterType.Family
                || i._type == IProductListSearch_FilterType.Manufacturer) {

                const manuFilter = i as IProductListSearch_BrandOrFamilyFilter
                return staticToString(manuFilter._type, manuFilter.data, undefined, undefined)
            } else if (i._type == IProductListSearch_FilterType.Synset) {

                const synsetFilter = i as IProductListSearch_SynsetFilter
                return staticToString(synsetFilter._type, synsetFilter.data, undefined, undefined)
            } else if (i._type == IProductListSearch_FilterType.RelatedUniqueNamePath) {
                const uniqueNamePathFilter = i as IProductListSearch_RelatedUniqueNamePathFilter
                return staticToString(uniqueNamePathFilter._type, uniqueNamePathFilter.data, undefined, undefined)
            } else if (i._type == IProductListSearch_FilterType.UniqueNamePath) {
                const uniqueNamePathFilter = i as IProductListSearch_UniqueNamePathFilter
                return staticToString(uniqueNamePathFilter._type, uniqueNamePathFilter.data, undefined, undefined)
            } else {
                throw "Not Implemented"
            }

            return "";
        }).filter(i => i.length > 0).join(',')
    }

    return undefined;
}

const searchParamsExpandToString = function (filter?: IProductListSearch_Filter[]): string | undefined {
    if (filter) {
        return filter.map((i): string => {
            if (i._type == IProductListSearch_FilterType.Attribute) {
                const attributeFilter = i as IProductListSearch_AttributeFilter
                if (attributeFilter.is_virtual) {
                    return attributeToString(attributeFilter.id, attributeFilter.data, '')
                } else {
                    return attributeToString(attributeFilter.id, attributeFilter.data)
                }

            } else if (i._type == IProductListSearch_FilterType.NumericDimension
                || i._type == IProductListSearch_FilterType.NumericRange
                || i._type == IProductListSearch_FilterType.NumericRatio) {
                const numericFilter = i as IProductListSearch_DimensionNumericFilter_Unused
                return attributeToString(numericFilter.id, numericFilter.data, '')

            } else if (i._type == IProductListSearch_FilterType.Numeric) {
                const numericFilter = i as IProductListSearch_NumericFilter
                return staticToString(numericFilter._type, [], numericFilter.low, numericFilter.high)

            } else if (i._type == IProductListSearch_FilterType.Color) {
                const colorFilter = i as IProductListSearch_ColorFilter
                return staticToString(colorFilter._type, [], undefined, undefined)

            } else if (i._type == IProductListSearch_FilterType.Text) {
                const textFilter = i as IProductListSearch_TextFilter
                return staticToString(textFilter._type, textFilter.data, undefined, undefined)

            } else if (i._type == IProductListSearch_FilterType.Code) {
                const textFilter = i as IProductListSearch_TextFilter
                return staticToString(textFilter._type, textFilter.data, undefined, undefined)

            } else if (i._type == IProductListSearch_FilterType.Price) {
                const priceFilter = i as IProductListSearch_PriceFilter
                return staticToString(priceFilter._type, [], priceFilter.low, priceFilter.high)

            } else if (i._type == IProductListSearch_FilterType.Brand
                || i._type == IProductListSearch_FilterType.Family
                || i._type == IProductListSearch_FilterType.Manufacturer) {

                const manuFilter = i as IProductListSearch_BrandOrFamilyFilter
                return staticToString(manuFilter._type, manuFilter.data, undefined, undefined)
            } else if (i._type == IProductListSearch_FilterType.Synset) {

                const synsetFilter = i as IProductListSearch_SynsetFilter
                return staticToString(synsetFilter._type, synsetFilter.data, undefined, undefined)
            } else if (i._type == IProductListSearch_FilterType.RelatedUniqueNamePath) {
                const uniqueNamePathFilter = i as IProductListSearch_RelatedUniqueNamePathFilter
                return staticToString(uniqueNamePathFilter._type, uniqueNamePathFilter.data, undefined, undefined)
            } else if (i._type == IProductListSearch_FilterType.UniqueNamePath) {
                const uniqueNamePathFilter = i as IProductListSearch_UniqueNamePathFilter
                return staticToString(uniqueNamePathFilter._type, uniqueNamePathFilter.data, undefined, undefined)
            } else {
                throw "Not Implemented"
            }

            return "";
        }).filter(i => i.length > 0).join(',')
    }

    return undefined;
}

const searchParamsColorToString = function (filter?: IProductListSearch_Filter[]): string | undefined {
    if (filter) {
        return filter.map((i): string => {
            if (i._type == IProductListSearch_FilterType.Color) {
                const colorFilter = i as IProductListSearch_ColorFilter
                const color = colorToString(colorFilter.hl, colorFilter.hh, colorFilter.sl, colorFilter.sh, colorFilter.ll, colorFilter.lh)
                if (color.length > 0)
                    return color;
            }
            return "";
        }).filter(i => i.length > 0).join(',')
    }
    return undefined
}

const searchParamsTextToString = function (filter?: IProductListSearch_Filter[]): string | undefined {
    if (filter) {
        const textParams = filter.map((i): string => {
            if (i._type == IProductListSearch_FilterType.Text) {
                const textFilter = i as IProductListSearch_TextFilter
                return textToString(textFilter.data);
            }
            return "";
        }).filter(i => i.length > 0)

        if (textParams.length) return textParams.join(',')
    }
    return undefined
}

const searchParamsCodeToString = function (filter?: IProductListSearch_Filter[]): string | undefined {
    if (filter) {
        const codeParams = filter.map((i): string => {
            if (i._type == IProductListSearch_FilterType.Code) {
                const textFilter = i as IProductListSearch_TextFilter

                // message from old frontend
                // todo, investigate correct way to handle this case
                return textFilter.data[0] && textFilter.data[0].id ? textFilter.data[0].id.toString() : "";
            }
            return "";
        }).filter(i => i.length > 0);

        if (codeParams.length) return codeParams.join(',')
    }
    return undefined
}
// ---

// const defaultUrlObject = () => ({
//     v: undefined,
//     c: undefined,
//     f: undefined,
//     expand: undefined,
//     secondary_expand: undefined,
//     show_secondary: undefined,
//     color: undefined,
//     code: undefined,
//     fold: undefined,
//     size: undefined
// })

const isDefaultSort = (sort: number) => sort == 1
const isDefaultSize = (size: string) => size == "m"
const isDefaultPage = (page: number) => page == 1

let lastSearchParamUrl: string = "";
let lastSearchParam: IUrlResolvedParams = {
    bridgeParams: {
        categoryId: undefined,
        expand: undefined,
        expandedBrands: undefined,
        color: undefined,
        filters: undefined,
        fold: undefined,
        foldedBrands: undefined,
        language: undefined,
        page: undefined,
        secondaryExpand: undefined,
        showSecondary: undefined,
        size: undefined,
        text: undefined,
        code: undefined,
        sort: undefined,
        group: undefined,
    },
    showFilters: undefined,
    openFilterOption: [],
    openUnpOptions: [],
};

export const emptyUrlParams = function (urlParams: Partial<IBridgeParams_Search>): boolean {
    return urlParams.categoryId === undefined &&
        urlParams.color === undefined &&
        urlParams.filters === undefined &&
        urlParams.page === undefined &&
        urlParams.showSecondary === undefined &&
        urlParams.text === undefined &&
        urlParams.code === undefined
}

export const getPageParamsFromSearchUrl = function (search: string): IUrlResolvedParams {
    let alias: Partial<IBridgeParams_Search> = {};

    const searchParamIndex = search.indexOf('?')
    if (searchParamIndex !== -1) {
        const pathName = search.substring(0, searchParamIndex)
        search = search.substring(searchParamIndex + 1);

        const categoryMatch = pathName.match(/\/[\w-]+c(?<category_id>\d+)$/);
        if (categoryMatch && categoryMatch.groups) {
            alias.categoryId = parseInt(categoryMatch.groups.category_id)
        }
    }

    if (lastSearchParamUrl == search && (!alias || (alias && emptyUrlParams(alias)))) {
        return lastSearchParam;
    }

    lastSearchParamUrl = search;
    const searchParams = new URLSearchParams(search);
    lastSearchParam = {
        bridgeParams: {
            categoryId: alias?.categoryId,
            expand: undefined,
            expandedBrands: undefined,
            color: undefined,
            filters: undefined,
            fold: undefined,
            foldedBrands: undefined,
            language: undefined,
            page: undefined,
            secondaryExpand: undefined,
            showSecondary: undefined,
            size: undefined,
            text: undefined,
            code: undefined,
            group: undefined,
            sort: undefined,
        },
        showFilters: undefined,
        openFilterOption: undefined,
        openUnpOptions: undefined,
    };

    searchParams.forEach(function (v, k) {
        switch (k) {
            case 'f':
                lastSearchParam.bridgeParams.filters = v;
                break;
            case 'color':
                lastSearchParam.bridgeParams.color = v;
                break;
            case 'expand':
                lastSearchParam.bridgeParams.expand = v;
                break;
            case 'expandedBrands':
                lastSearchParam.bridgeParams.expandedBrands = v;
                break;
            case 'secondary_expand':
                lastSearchParam.bridgeParams.secondaryExpand = v;
                break;
            case 'c':
            case 'category_id':     // key from oleksii' code
                lastSearchParam.bridgeParams.categoryId = parseInt(v);
                break;
            case 'show_secondary':
                lastSearchParam.bridgeParams.showSecondary = parseInt(v);
                break;
            case 'page':
                lastSearchParam.bridgeParams.page = parseInt(v);
                break;
            case 'fold':
                lastSearchParam.bridgeParams.fold = v;
                break;
            case 'foldedBrands':
                lastSearchParam.bridgeParams.foldedBrands = v;
                break;
            case 'size':
                lastSearchParam.bridgeParams.size = v;
                break;
            case 'lang':
                lastSearchParam.bridgeParams.language = v;
                break;
            case 'of':
                lastSearchParam.openFilterOption = v.split(',').filter(i => i && i.length > 0);
                break;
            case 'unpf':
                lastSearchParam.openUnpOptions = v.split(',').filter(i => i && i.length > 0);
                break;
            case 'text':
                lastSearchParam.bridgeParams.text = v;
                break;
            case 'code':
                lastSearchParam.bridgeParams.code = v;
                break;
            case 'group':
                lastSearchParam.bridgeParams.group = parseInt(v);
                break;
            case 'sort':
                lastSearchParam.bridgeParams.sort = parseInt(v);
                break;
            default:
                console.log('Unknown URL param: ' + k + ': ' + v);
                break;
        }
    })

    return lastSearchParam;
}

export const getUrlFromPageParams = function (params: IUrlResolvedParams): string {
    let stringBuilder = [];
    if (params.bridgeParams.categoryId !== undefined) {
        stringBuilder.push('c=' + params.bridgeParams.categoryId);
    }
    if (params.bridgeParams.text !== undefined) {
        stringBuilder.push('text=' + params.bridgeParams.text);
    }
    if (params.bridgeParams.code !== undefined) {
        stringBuilder.push('code=' + params.bridgeParams.code);
    }
    if (params.bridgeParams.showSecondary !== undefined) {
        stringBuilder.push('show_secondary=' + params.bridgeParams.showSecondary);
    }
    if (params.bridgeParams.fold !== undefined && params.bridgeParams.fold.length > 0) {
        stringBuilder.push('fold=' + params.bridgeParams.fold);
    }
    if (params.bridgeParams.foldedBrands !== undefined && params.bridgeParams.foldedBrands.length > 0) {
        stringBuilder.push('foldedBrands=' + params.bridgeParams.foldedBrands);
    }
    if (params.bridgeParams.expand !== undefined && params.bridgeParams.expand.length > 0) {
        stringBuilder.push('expand=' + params.bridgeParams.expand);
    }
    if (params.bridgeParams.expandedBrands !== undefined && params.bridgeParams.expandedBrands.length > 0) {
        stringBuilder.push('expandedBrands=' + params.bridgeParams.expandedBrands);
    }
    if (params.bridgeParams.secondaryExpand !== undefined && params.bridgeParams.secondaryExpand.length > 0) {
        stringBuilder.push('secondary_expand=' + params.bridgeParams.secondaryExpand);
    }
    if (params.bridgeParams.filters !== undefined && params.bridgeParams.filters.length > 0) {
        stringBuilder.push('f=' + params.bridgeParams.filters);
    }
    if (params.bridgeParams.color !== undefined && params.bridgeParams.color.length > 0) {
        stringBuilder.push('color=' + params.bridgeParams.color);
    }
    if (params.bridgeParams.language !== undefined) {
        stringBuilder.push('lang=' + params.bridgeParams.language);
    }
    if (params.bridgeParams.group !== undefined) {
        stringBuilder.push('group=' + params.bridgeParams.group);
    }
    if (params.bridgeParams.sort !== undefined && !isDefaultSort(params.bridgeParams.sort)) {
        stringBuilder.push('sort=' + params.bridgeParams.sort);
    }
    if (params.bridgeParams.size !== undefined && !isDefaultSize(params.bridgeParams.size)) {
        stringBuilder.push('size=' + params.bridgeParams.size);
    }
    if (params.openFilterOption !== undefined && params.openFilterOption.length > 0) {
        stringBuilder.push('of=' + params.openFilterOption.join(','));
    }
    if (params.openUnpOptions !== undefined && params.openUnpOptions.length > 0) {
        stringBuilder.push('unpf=' + params.openUnpOptions.join(','));
    }
    if (params.bridgeParams.page !== undefined && !isDefaultPage(params.bridgeParams.page)) {
        stringBuilder.push('page=' + params.bridgeParams.page);
    }
    return stringBuilder.join('&');
}

export const getSearchApiFromPageParams = function (params: IUrlResolvedParams): string {
    let stringBuilder = [];
    if (params.bridgeParams.categoryId !== undefined) {
        stringBuilder.push('c=' + params.bridgeParams.categoryId);
    }
    if (params.bridgeParams.text !== undefined) {
        stringBuilder.push('text=' + params.bridgeParams.text);
    }
    if (params.bridgeParams.code !== undefined) {
        stringBuilder.push('code=' + params.bridgeParams.code);
    }
    if (params.bridgeParams.showSecondary !== undefined) {
        stringBuilder.push('show_secondary=' + params.bridgeParams.showSecondary);
    }
    if (params.bridgeParams.page !== undefined) {
        stringBuilder.push('page=' + params.bridgeParams.page);
    }
    if (params.bridgeParams.fold !== undefined && params.bridgeParams.fold.length > 0) {
        stringBuilder.push('fold=' + params.bridgeParams.fold);
    }
    if (params.bridgeParams.foldedBrands !== undefined && params.bridgeParams.foldedBrands.length > 0) {
        stringBuilder.push('foldedBrands=' + params.bridgeParams.foldedBrands);
    }
    if (params.bridgeParams.expand !== undefined && params.bridgeParams.expand.length > 0) {
        stringBuilder.push('expand=' + params.bridgeParams.expand);
    }
    if (params.bridgeParams.expandedBrands !== undefined && params.bridgeParams.expandedBrands.length > 0) {
        stringBuilder.push('expandedBrands=' + params.bridgeParams.expandedBrands);
    }
    if (params.bridgeParams.secondaryExpand !== undefined && params.bridgeParams.secondaryExpand.length > 0) {
        stringBuilder.push('secondary_expand=' + params.bridgeParams.secondaryExpand);
    }
    if (params.bridgeParams.filters !== undefined && params.bridgeParams.filters.length > 0) {
        stringBuilder.push('f=' + params.bridgeParams.filters);
    }
    if (params.bridgeParams.color !== undefined && params.bridgeParams.color.length > 0) {
        stringBuilder.push('color=' + params.bridgeParams.color);
    }
    if (params.bridgeParams.language !== undefined) {
        stringBuilder.push('lang=' + params.bridgeParams.language);
    }
    if (params.bridgeParams.group !== undefined) {
        stringBuilder.push('group=' + params.bridgeParams.group);
    }
    if (params.bridgeParams.sort !== undefined) {
        stringBuilder.push('sort=' + params.bridgeParams.sort);
    }
    if (params.bridgeParams.size !== undefined) {
        stringBuilder.push('size=' + params.bridgeParams.size);
    }
    return stringBuilder.join('&');
}

export const convertSearchParamsToUrlResolveParams = function (params: IProductListSearch_Params): IUrlResolvedParams {
    return {
        bridgeParams: {
            categoryId: params.categoryId,
            language: params.language,
            color: params.filter && params.filter.length > 0 ? searchParamsColorToString(params.filter) : undefined,
            filters: params.filter && params.filter.length > 0 ? searchParamsFilterToString(params.filter) : undefined,
            expand: params.expand && params.expand.length > 0 ? searchParamsExpandToString(params.expand) : undefined,
            expandedBrands: undefined,
            secondaryExpand: params.secondaryExpand && params.secondaryExpand.length > 0 ? searchParamsExpandToString(params.secondaryExpand) : undefined,
            showSecondary: params.showSecondary,
            fold: params.fold,
            foldedBrands: undefined,
            page: params.page && !isDefaultPage(params.page) ? params.page : undefined,
            size: params.size && !isDefaultSize(params.size) ? params.size : undefined,
            text: params.text
                ? params.text
                : params.filter && params.filter.length > 0 ? searchParamsTextToString(params.filter) : undefined,
            code: params.code
                ? params.code
                : params.filter && params.filter.length > 0 ? searchParamsCodeToString(params.filter) : undefined,
            sort: params.sort && !isDefaultSort(params.sort) ? params.sort : undefined,
            group: params.group,
        },
        showFilters: undefined,
        openFilterOption: undefined,
        openUnpOptions: undefined,
    }
}

// ---

// convert object to filter string
const makeIdString = (key: string = '', data: { id: number | string | undefined }[] = []): string => (
    data.reduce((prev, curr) => (
        prev === '' ? (
            `${key}${curr.id}`
        ) : (
            `${prev}|${key}${curr.id}`
        )
    ), '')
)

// convert numeric object to numeric filter string
const makeNumericString = (low?: number | string, high?: number | string) => (
    low !== undefined && high !== undefined ? (
        `${low}..${high}`
    ) : low !== undefined ? (
        `${low}..`
    ) : high !== undefined ? (
        `..${high}`
    ) : (
        ''
    )
)

const attributeToString = (id: string, data: { id: string | undefined }[], key: string = 'o') => {
    let str = ''
    const val = makeIdString(key, data)
    if (val.length > 0) {
        str = `a${id}:${val}`
    }
    return str
}

const numericToString = (key: string, low?: number | string, high?: number | string) => {
    let str = ''
    const numericString = makeNumericString(low, high)
    if (numericString.length > 0) {
        str = `${key}:${numericString}`
    }
    return str
}

const colorToString = (hl?: number, hh?: number, sl?: number, sh?: number, ll?: number, lh?: number) => {
    let str = ''
    if (typeof hl != 'undefined' || typeof hh != 'undefined' ||
        typeof sl != 'undefined' || typeof sh != 'undefined' ||
        typeof ll != 'undefined' || typeof lh != 'undefined') {
        if (typeof hl == 'undefined') {
            hl = -40
        }
        if (typeof hh == 'undefined') {
            hh = 320
        }
        if (typeof sl == 'undefined') {
            sl = 0
        }
        if (typeof sh == 'undefined') {
            sh = 100
        }
        if (typeof ll == 'undefined') {
            ll = 0
        }
        if (typeof lh == 'undefined') {
            lh = 100
        }
        str = `${makeNumericString(hl, hh)},${makeNumericString(sl, sh)},${makeNumericString(ll, lh)}`
    }
    return str
}

const filterToString = (data: {
    id: string | number | undefined
}[], low: number | string | undefined, high: number | string | undefined, filter: { key: string, subType: string }) => {
    const val = filter.subType === FILTER_SUB_TYPE_ID
        ? makeIdString('', data)
        : makeNumericString(low, high)

    return val.length > 0 ? `${filter.key}:${val}` : ''
}

const staticToString = (type: string, data: {
    id: string | number | undefined
}[], low?: number | string, high?: number | string) => {
    const filter = STATIC_FILTER_MAP[type]

    if (filter === undefined) {
        return ''
    }

    return filterToString(data, low, high, filter)
}

const textToString = (data: any[]) => {
    if (!Array.isArray(data)) {
        return ''
    }

    return data.reduce((prev, curr) => {
        if (prev.id == '') return curr.id
        if (curr.id == '') return prev.id

        return `${prev.id}+${curr.id}`
    }, {id: ''})
}

export const appendUniqueStringArray = function (set: string[] | undefined, add: string): string[] {
    if (!set)
        return [add];
    if (set.length == 0)
        return [add];
    if (set.indexOf(add) === -1)
        set.push(add);
    return set;
}

export const detachUniqueStringArray = function (set: string[] | undefined, add: string): string[] {
    if (!set)
        return [add];
    if (set.length == 0)
        return [add];
    if (set.indexOf(add) === -1)
        set.push(add);
    return set;
}

const cloneSearchParamsFilter = function (set: IProductListSearch_Filter[]): IProductListSearch_Filter[] {
    // just copy items and copy arrays inside
    return set.map((i): IProductListSearch_Filter => {
        switch (i._type) {
            case IProductListSearch_FilterType.Attribute: {
                const filterAttribute = i as IProductListSearch_AttributeFilter
                return {
                    ...filterAttribute,
                    data: filterAttribute.data.map(j => ({...j}))
                } as IProductListSearch_AttributeFilter
            }
            case IProductListSearch_FilterType.NumericDimension:
            case IProductListSearch_FilterType.NumericRange:
            case IProductListSearch_FilterType.NumericRatio: {
                const filterAttribute = i as IProductListSearch_DimensionNumericFilter_Unused
                return {
                    ...filterAttribute,
                    data: filterAttribute.data.map(j => ({...j}))
                } as IProductListSearch_DimensionNumericFilter_Unused
            }
            case  IProductListSearch_FilterType.NumericFixed: {
                const filterAttribute = i as IProductListSearch_FixedNumericFilter_Unused
                return {
                    ...filterAttribute,
                } as IProductListSearch_FixedNumericFilter_Unused
            }
            case IProductListSearch_FilterType.Numeric: {
                const filterAttribute = i as IProductListSearch_NumericFilter
                return {
                    ...filterAttribute,
                } as IProductListSearch_NumericFilter
            }
            case IProductListSearch_FilterType.Price: {
                const filterPrice = i as IProductListSearch_PriceFilter
                return {
                    ...filterPrice,
                } as IProductListSearch_PriceFilter
            }
            case IProductListSearch_FilterType.Color: {
                const filterColor = i as IProductListSearch_ColorFilter
                return {
                    ...filterColor,
                } as IProductListSearch_ColorFilter
            }
            case IProductListSearch_FilterType.Manufacturer:
            case IProductListSearch_FilterType.Brand:
            case IProductListSearch_FilterType.Family: {
                const filterBrandOrFamily = i as IProductListSearch_BrandOrFamilyFilter
                return {
                    ...filterBrandOrFamily,
                    data: filterBrandOrFamily.data.map(j => ({...j}))
                } as IProductListSearch_BrandOrFamilyFilter
            }
            case IProductListSearch_FilterType.RelatedUniqueNamePath: {
                const filterUniqueNamePath = i as IProductListSearch_RelatedUniqueNamePathFilter
                return {
                    ...filterUniqueNamePath,
                    data: filterUniqueNamePath.data.map(j => ({...j}))
                } as IProductListSearch_RelatedUniqueNamePathFilter
            }
            case IProductListSearch_FilterType.UniqueNamePath: {
                const filterUniqueNamePath = i as IProductListSearch_UniqueNamePathFilter
                return {
                    ...filterUniqueNamePath,
                    data: filterUniqueNamePath.data.map(j => ({...j}))
                } as IProductListSearch_UniqueNamePathFilter
            }
            case IProductListSearch_FilterType.Text:
            case IProductListSearch_FilterType.Code: {
                const filterText = i as IProductListSearch_TextFilter
                return {
                    ...filterText,
                    data: filterText.data.map(j => ({...j}))
                } as IProductListSearch_TextFilter
            }
        }
    });
}

// ---

export const toggleStaticNumericFilter = (set: IProductListSearch_Filter[], type: IProductListSearch_FilterType.Numeric, attributeId: string | undefined, low: number | undefined, high: number | undefined): IProductListSearch_Filter[] => {
    const countFilters = set.length
    if (countFilters > 0) {
        for (let i = 0; i < countFilters; i++) {
            if (set[i]._type == type) {
                const numericFilter = set[i] as IProductListSearch_NumericFilter

                if (attributeId === undefined || numericFilter.id == attributeId) {

                    const copySet = cloneSearchParamsFilter(set);
                    const currentFilterData = copySet[i] as IProductListSearch_NumericFilter

                    if (low !== undefined) currentFilterData.low = low;
                    else currentFilterData.low = undefined;

                    if (high !== undefined) currentFilterData.high = high;
                    else currentFilterData.high = undefined;

                    return copySet
                }
            }
        }
    }

    if (low !== undefined || high !== undefined) {
        const copySet = cloneSearchParamsFilter(set);

        copySet.push({
            _type: IProductListSearch_FilterType.Numeric,
            id: attributeId,
            high: high,
            low: low,
            filter_var: "// UNKNOWN_FILTER_TYPE",
            visualDetails: {}
        } as IProductListSearch_NumericFilter)

        return copySet
    }

    return set;
}

// --- Price Filter

export const toggleStaticPriceFilter = (set: IProductListSearch_Filter[], type: IProductListSearch_FilterType.Price, low: number | undefined, high: number | undefined): IProductListSearch_Filter[] => {

    const countFilters = set.length
    if (countFilters > 0) {
        for (let i = 0; i < countFilters; i++) {
            if (set[i]._type == type) {
                const copySet = cloneSearchParamsFilter(set);
                const currentFilterData = copySet[i] as IProductListSearch_PriceFilter

                if (low !== undefined) currentFilterData.low = low;
                else currentFilterData.low = undefined;

                if (high !== undefined) currentFilterData.high = high;
                else currentFilterData.high = undefined;

                return copySet
            }
        }
    }

    if (low !== undefined || high !== undefined) {
        const copySet = cloneSearchParamsFilter(set);

        copySet.push({
            _type: IProductListSearch_FilterType.Price,
            high: high,
            low: low,
            filter_var: "// UNKNOWN_FILTER_TYPE",
            visualDetails: {}
        } as IProductListSearch_PriceFilter)

        return copySet
    }

    return set;
}

// --- Color Filter

export const toggleColorFilter = (set: IProductListSearch_Filter[], hl: number | undefined, hh: number | undefined, sl: number | undefined, sh: number | undefined, ll: number | undefined, lh: number | undefined): IProductListSearch_Filter[] => {
    const countFilters = set.length
    if (countFilters > 0) {
        for (let i = 0; i < countFilters; i++) {
            if (set[i]._type == IProductListSearch_FilterType.Color) {

                const copySet = cloneSearchParamsFilter(set);
                const colorFilter = copySet[i] as IProductListSearch_ColorFilter

                if (hl !== undefined) colorFilter.hl = hl;
                else colorFilter.hl = undefined;
                if (hh !== undefined) colorFilter.hh = hh;
                else colorFilter.hh = undefined;

                if (sl !== undefined) colorFilter.sl = sl;
                else colorFilter.sl = undefined;
                if (sh !== undefined) colorFilter.sh = sh;
                else colorFilter.sh = undefined;

                if (ll !== undefined) colorFilter.ll = ll;
                else colorFilter.ll = undefined;
                if (lh !== undefined) colorFilter.lh = lh;
                else colorFilter.lh = undefined;

                copySet[i] = colorFilter
                return copySet
            }
        }
    }

    if (hl !== undefined || hh !== undefined ||
        sl !== undefined || sh !== undefined ||
        ll !== undefined || lh !== undefined) {
        const copySet = cloneSearchParamsFilter(set);

        copySet.push({
            _type: IProductListSearch_FilterType.Color,
            hh: hh,
            hl: hl,
            sh: sh,
            sl: sl,
            lh: lh,
            ll: ll,
        })
        return copySet
    }

    return set;
}

// --- Attribute Filters

export const appendSearchParamsAttributeFilter = function (set: IProductListSearch_Filter[], type: IProductListSearch_FilterType.Attribute, attributeId: string, isVirtual: boolean, id: string): IProductListSearch_Filter[] {

    if (set.length > 0) {
        for (const [i, filter] of set.entries()) {
            if (filter._type == type) {
                const attributeFilter = filter as IProductListSearch_AttributeFilter
                if (attributeFilter.id === attributeId) {
                    if (attributeFilter.data === undefined) {
                        continue
                    }

                    for (const [j, data] of attributeFilter.data.entries()) {
                        if (id != null && data.id == id) {
                            return set;     // already have a filter
                        }
                    }

                    const copySet = cloneSearchParamsFilter(set);
                    const currentFilterData = (copySet[i] as IProductListSearch_AttributeFilter).data
                    currentFilterData.push({id: id})
                    return copySet;
                }
            }
        }
    }

    const copySet = cloneSearchParamsFilter(set);
    copySet.push({
        _type: type,
        filter_var: "// UNKNOWN FILTER VAR",
        id: attributeId,
        is_virtual: isVirtual,
        data: [{id: id}],
        visualDetails: {}
    } as IProductListSearch_AttributeFilter)

    return copySet;
}

export const detachSearchParamAttributeFilter = function (set: IProductListSearch_Filter[], type: IProductListSearch_FilterType.Attribute, attributeId: string, id: string): IProductListSearch_Filter[] {

    if (set.length > 0) {
        for (const [i, filter] of set.entries()) {
            if (filter._type == type) {
                const attributeFilter = filter as IProductListSearch_AttributeFilter
                if (attributeFilter.id === attributeId) {
                    if (attributeFilter.data === undefined) {
                        continue
                    }

                    for (const [j, data] of attributeFilter.data.entries()) {
                        if (data.id !== id) {
                            continue
                        }

                        if (attributeFilter.data.length <= 1) {
                            // remove whole filter
                            const copySet = cloneSearchParamsFilter(set);
                            return [
                                ...copySet.slice(0, i),
                                ...copySet.slice(i + 1)
                            ]
                        }

                        const copySet = cloneSearchParamsFilter(set);
                        const currentFilterData = (copySet[i] as IProductListSearch_AttributeFilter).data

                        const newData = [
                            ...currentFilterData.slice(0, j),
                            ...currentFilterData.slice(j + 1)
                        ]

                        // remove value from filter
                        return [
                            ...copySet.slice(0, i),
                            {
                                ...attributeFilter,
                                data: newData
                            } as IProductListSearch_AttributeFilter,
                            ...copySet.slice(i + 1)
                        ]
                    }
                }
            }
        }
    }

    return set;
}

// --- Text Filters

export const detachSearchParamTextFilter = function (set: IProductListSearch_Filter[], type: IProductListSearch_FilterType.Text | IProductListSearch_FilterType.Code, id: string | undefined): IProductListSearch_Filter[] {

    if (set.length > 0) {
        for (const [i, filter] of set.entries()) {
            if (filter._type === type) {
                const textFilter = filter as IProductListSearch_TextFilter
                if (textFilter.data === undefined) {
                    continue
                }

                for (const [j, data] of textFilter.data.entries()) {
                    if (id === undefined || data.id !== id) {
                        continue
                    }

                    if (textFilter.data.length <= 1) {
                        // remove whole filter
                        const copySet = cloneSearchParamsFilter(set);
                        return [
                            ...copySet.slice(0, i),
                            ...copySet.slice(i + 1)
                        ]
                    }

                    const copySet = cloneSearchParamsFilter(set);
                    const currentFilterData = (copySet[i] as IProductListSearch_TextFilter).data

                    const newData = [
                        ...currentFilterData.slice(0, j),
                        ...currentFilterData.slice(j + 1)
                    ]

                    // remove value from filter
                    return [
                        ...copySet.slice(0, i),
                        {
                            ...textFilter,
                            data: newData
                        } as IProductListSearch_TextFilter,
                        ...copySet.slice(i + 1)
                    ]
                }
            }
        }
    }

    return set;
}

// --- Brand / Manufacturer / Family Filters

const attachSearchParamFamilyOrBrandFilter = function (set: IProductListSearch_Filter[], type: IProductListSearch_FilterType.Family | IProductListSearch_FilterType.Brand | IProductListSearch_FilterType.Manufacturer, id: number): IProductListSearch_Filter[] {

    if (set.length > 0) {
        for (const [i, filter] of set.entries()) {
            if (filter._type === type) {
                const brandFilter = filter as IProductListSearch_BrandOrFamilyFilter
                if (brandFilter.data === undefined) {
                    continue
                }

                for (const [j, data] of brandFilter.data.entries()) {
                    if (id != null && data.id == id) {
                        return set;     // already have a filter
                    }
                }

                const copySet = cloneSearchParamsFilter(set);
                const currentFilterData = (copySet[i] as IProductListSearch_BrandOrFamilyFilter).data
                currentFilterData.push({id: id})
                return copySet;
            }
        }
    }

    const copySet = cloneSearchParamsFilter(set);
    copySet.push({
        _type: type,
        filter_var: "// UNKNOWN FILTER VAL",
        visualDetails: {},
        data: [{id: id}]
    } as IProductListSearch_BrandOrFamilyFilter)

    return copySet;
}

export const detachSearchParamFamilyOrBrandFilter = function (set: IProductListSearch_Filter[], type: IProductListSearch_FilterType.Family | IProductListSearch_FilterType.Brand | IProductListSearch_FilterType.Manufacturer, id: number): IProductListSearch_Filter[] {

    if (set.length > 0) {
        for (const [i, filter] of set.entries()) {
            if (filter._type === type) {
                const brandFilter = filter as IProductListSearch_BrandOrFamilyFilter
                if (brandFilter.data === undefined) {
                    continue
                }

                for (const [j, data] of brandFilter.data.entries()) {
                    if (id === undefined || data.id !== id) {
                        continue
                    }

                    if (brandFilter.data.length <= 1) {
                        // remove whole filter
                        const copySet = cloneSearchParamsFilter(set);
                        return [
                            ...copySet.slice(0, i),
                            ...copySet.slice(i + 1)
                        ]
                    }

                    const copySet = cloneSearchParamsFilter(set);
                    const currentFilterData = (copySet[i] as IProductListSearch_BrandOrFamilyFilter).data

                    const newData = [
                        ...currentFilterData.slice(0, j),
                        ...currentFilterData.slice(j + 1)
                    ]

                    // remove value from filter
                    return [
                        ...copySet.slice(0, i),
                        {
                            ...brandFilter,
                            data: newData
                        } as IProductListSearch_BrandOrFamilyFilter,
                        ...copySet.slice(i + 1)
                    ]
                }
            }
        }
    }

    return set;
}

const checkSearchParamsFamilyOrBrandFilter = function (set: IProductListSearch_Filter[], type: IProductListSearch_FilterType.Family | IProductListSearch_FilterType.Brand | IProductListSearch_FilterType.Manufacturer, id: number): boolean {
    if (set.length > 0) {
        for (const [i, filter] of set.entries()) {
            if (filter._type === type) {
                const brandFilter = filter as IProductListSearch_BrandOrFamilyFilter
                if (brandFilter.data === undefined) {
                    continue
                }

                for (const [j, data] of brandFilter.data.entries()) {
                    if (id == null || data.id !== id) {
                        continue
                    }

                    return true;
                }
            }
        }
    }

    return false;
}

export const toggleSearchParamsFamilyOrBrandFilter = (set: IProductListSearch_Filter[], type: IProductListSearch_FilterType.Family | IProductListSearch_FilterType.Brand | IProductListSearch_FilterType.Manufacturer, id: number): IProductListSearch_Filter[] => {
    if (checkSearchParamsFamilyOrBrandFilter(set, type, id)) {
        return detachSearchParamFamilyOrBrandFilter(set, type, id)
    } else {
        return attachSearchParamFamilyOrBrandFilter(set, type, id)
    }
}

// --- Related Unique Name Path

const checkSearchParamsUniqueNamePath = function (set: IProductListSearch_Filter[], id: number): boolean {
    if (set.length > 0) {
        for (const [i, filter] of set.entries()) {
            if (filter._type === IProductListSearch_FilterType.RelatedUniqueNamePath) {
                const uniqueNamePathFilter = filter as IProductListSearch_RelatedUniqueNamePathFilter
                if (uniqueNamePathFilter.data === undefined) {
                    continue
                }

                for (const [j, data] of uniqueNamePathFilter.data.entries()) {
                    if (id == null || data.id !== id) {
                        continue
                    }

                    return true;
                }
            }
        }
    }

    return false;
}

const attachSearchParamUniqueNamePathFilter = function (set: IProductListSearch_Filter[], id: number, type: IProductListSearch_FilterType): IProductListSearch_Filter[] {

    if (set.length > 0) {
        for (const [i, filter] of set.entries()) {
            if (filter._type === type) {
                const uniqueNameFilter = filter as IProductListSearch_RelatedUniqueNamePathFilter
                if (uniqueNameFilter.data === undefined) {
                    continue
                }

                for (const [j, data] of uniqueNameFilter.data.entries()) {
                    if (id != null && data.id == id) {
                        return set;     // already have a filter
                    }
                }

                const copySet = cloneSearchParamsFilter(set);
                const currentFilterData = (copySet[i] as IProductListSearch_RelatedUniqueNamePathFilter).data
                currentFilterData.push({id: id})
                return copySet;
            }
        }
    }

    const copySet = cloneSearchParamsFilter(set);
    copySet.push({
        _type: IProductListSearch_FilterType.RelatedUniqueNamePath,
        filter_var: "// UNKNOWN FILTER VAL",
        visualDetails: {},
        data: [{id: id}]
    } as IProductListSearch_RelatedUniqueNamePathFilter)


    return copySet;
}

export const detachSearchParamUniqueNamePathFilter = function (set: IProductListSearch_Filter[], id: number, type: IProductListSearch_FilterType): IProductListSearch_Filter[] {

    if (set.length > 0) {
        for (const [i, filter] of set.entries()) {
            if (filter._type === type) {
                const uniqueNamePathFilter = filter as IProductListSearch_RelatedUniqueNamePathFilter | IProductListSearch_UniqueNamePathFilter
                if (uniqueNamePathFilter.data === undefined) {
                    continue
                }

                for (const [j, data] of uniqueNamePathFilter.data.entries()) {
                    if (id === undefined || data.id !== id) {
                        continue
                    }

                    if (uniqueNamePathFilter.data.length <= 1) {
                        // remove whole filter
                        const copySet = cloneSearchParamsFilter(set);
                        return [
                            ...copySet.slice(0, i),
                            ...copySet.slice(i + 1)
                        ]
                    }

                    const copySet = cloneSearchParamsFilter(set);
                    const currentFilterData = (copySet[i] as IProductListSearch_RelatedUniqueNamePathFilter | IProductListSearch_UniqueNamePathFilter).data

                    const newData = [
                        ...currentFilterData.slice(0, j),
                        ...currentFilterData.slice(j + 1)
                    ]

                    // remove value from filter
                    return [
                        ...copySet.slice(0, i),
                        {
                            ...uniqueNamePathFilter,
                            data: newData
                        } as IProductListSearch_RelatedUniqueNamePathFilter,
                        ...copySet.slice(i + 1)
                    ]
                }
            }
        }
    }

    return set;
}

export const toggleSearchParamsRelatedUniqueNamePathFilter = (set: IProductListSearch_Filter[], id: number): IProductListSearch_Filter[] => {
    if (checkSearchParamsUniqueNamePath(set, id)) {
        return detachSearchParamUniqueNamePathFilter(set, id, IProductListSearch_FilterType.RelatedUniqueNamePath)
    } else {
        return attachSearchParamUniqueNamePathFilter(set, id, IProductListSearch_FilterType.RelatedUniqueNamePath)
    }
}

export const toggleSearchParamsUniqueNamePathFilter = (set: IProductListSearch_Filter[], id: number): IProductListSearch_Filter[] => {
    if (checkSearchParamsUniqueNamePath(set, id)) {
        return detachSearchParamUniqueNamePathFilter(set, id, IProductListSearch_FilterType.UniqueNamePath)
    } else {
        return attachSearchParamUniqueNamePathFilter(set, id, IProductListSearch_FilterType.UniqueNamePath)
    }
}

// --- Synset

export const detachSearchParamSynsetFilter = function (set: IProductListSearch_Filter[], type: IProductListSearch_FilterType.Synset, id: number): IProductListSearch_Filter[] {

    if (set.length > 0) {
        for (const [i, filter] of set.entries()) {
            if (filter._type === type) {
                const brandFilter = filter as IProductListSearch_BrandOrFamilyFilter
                if (brandFilter.data === undefined) {
                    continue
                }

                for (const [j, data] of brandFilter.data.entries()) {
                    if (id === undefined || data.id !== id) {
                        continue
                    }

                    if (brandFilter.data.length <= 1) {
                        // remove whole filter
                        const copySet = cloneSearchParamsFilter(set);
                        return [
                            ...copySet.slice(0, i),
                            ...copySet.slice(i + 1)
                        ]
                    }

                    const copySet = cloneSearchParamsFilter(set);
                    const currentFilterData = (copySet[i] as IProductListSearch_BrandOrFamilyFilter).data

                    const newData = [
                        ...currentFilterData.slice(0, j),
                        ...currentFilterData.slice(j + 1)
                    ]

                    // remove value from filter
                    return [
                        ...copySet.slice(0, i),
                        {
                            ...brandFilter,
                            data: newData
                        } as IProductListSearch_BrandOrFamilyFilter,
                        ...copySet.slice(i + 1)
                    ]
                }
            }
        }
    }

    return set;
}
