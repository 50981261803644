import React, {MouseEventHandler} from "react";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {FILTER_KEY_FAMILY} from "../../../Helpers/Constants";
import {getDebugProductToolUrl} from "../../../Helpers/BackendHelper";
import SeparatorLink from "../SeparatorLink";

type FamilyChainSeparatorProps = {
    urlPageParams: IUrlResolvedParams
    chain: Array<{
        id: number
        name: string
    }>
    isDebugEnabled: boolean,
};

const ComponentTranslationKey = "Separator"

/** @see FamilyChainSeparatorDemo */
export default function FamilyChainSeparator(props: FamilyChainSeparatorProps) {
    // globals
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    const onBackendClick: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const manualCfs = FILTER_KEY_FAMILY + ':' + (props.chain.length > 0 ? props.chain[props.chain.length - 1].id : 0)

        window.open(getDebugProductToolUrl(
            props.urlPageParams,
            undefined,
            undefined,
            props.urlPageParams.bridgeParams.categoryId,
            undefined,
            props.urlPageParams.bridgeParams.text,
            undefined,
            manualCfs,
            undefined,
            undefined,
            undefined,
            undefined), 'backend');
    }

    return <div className="category-item-separator flex flex-col">
        <div
            className="whitespace-nowrap card-separator-text-rotation flex-none flex justify-center text-gray-500">
            {(
                props.chain.length > 0
                    ? props.chain.map(a => a.name).join(' » ')
                    : getTranslation('no_product_family')
            )}
        </div>
        {props.isDebugEnabled
            ? <div className="whitespace-nowrap flex-none">
                <div onClick={onBackendClick}
                     className="backend-btn backend-btn-separator cursor-pointer">F
                </div>
            </div>
            : null}
    </div>
}
