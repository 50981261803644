export function replaceSpecialUnicodeCharacters(text: string | undefined) {
    if (text) {
        const replaceable = ['�']
        for (const r of replaceable) {
            while (text.indexOf(r) !== -1) {
                text = text.replace('�', '');
            }
        }
    }
    return text;
}

export function limitStringLength(text: string, len: number, ellipsis: string = '...') {
    if (text.length > len - ellipsis.length) {
        text = text.substring(0, len - ellipsis.length) + ellipsis;
    }
    return text;
}

export function replaceAiArtifacts(text: string | undefined) {
    if (text) {
        while (text.indexOf("\"") !== -1) {
            text = text.replace("\"", '');
        }
        while (text.indexOf("\n") !== -1) {
            text = text.replace("\n", ' ');
        }
    }
    return text;
}