import React, {FunctionComponent} from "react";
import {useTranslation} from "react-i18next";

const SearchListNoDataResult: FunctionComponent = function () {
    const {t} = useTranslation("Messages");

    return <div className={`no-items-message-wrapper`}>
        <div className={`no-items-message`}>
            <img className={`no-items-filter-icon`} src="/resources/filter-icon.svg" alt={``}/>
            <div className={`no-items-message-first`}>{t('sorry_empty_category')}</div>
            <div className={`no-items-message-second`}>{t('sorry_come_back_later')}</div>
        </div>
    </div>
}

export default SearchListNoDataResult;