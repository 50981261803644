import React, {JSX, MouseEventHandler, ReactElement, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CategoryPath} from "../../ProductList/CategoryPath";
import SpecificationsTable from "../Specifications";
import {ProductImage} from "../../App/Common/Image";
import {
    triggerProductStateAddCompare,
    triggerProductStateRemoveCompare
} from "../../../Store/Action/session.product";
import {SpinLoadingIndicator} from "../../App/Common/SpinLoadingIndicator";
import {Price} from "../Price";
import {ProductVariantSelect} from "./VariantSelect";
import {getDefaultLang} from "../../../Store/Action/interactive";
import {getCategoryCacheKey} from "../../../Store/StoreHelper";
import {PlaceholderText} from "../../App/Common/Placeholder";
import {getProductCacheKey} from "../../../Store/Action/cache.product";
import ModalImagePreview from "../ModalImagePreview";
import {addCompareProduct, removeCompareProduct} from "../../../Helpers/LocalStorageHelper";
import {
    triggerOpenVerifyProduct,
    triggerProductVerifyCategoryDelete,
    triggerProductVerifyCategorySave
} from "../../../Store/Action/session.verifyProduct";
import {IconChevronLeft, IconChevronRight} from "../../App/Icons";
import {MenuImageVerification} from "../MenuImageVerification";
import {triggerImageVerification_MenuOpen} from "../../../Store/Action/session.verifyImage";
import {CardControls} from "../../ProductsGrid/Card_Product/CardControls";
import {createPortal} from "react-dom";
import {getMenuContainerNode} from "../../App/Layout/MenuContainer";
import CategoryCard from "../../ProductsGrid/Card_Category";
import {getDebugProductUrl} from "../../../Helpers/BackendHelper";
import {replaceSpecialUnicodeCharacters} from "../../../Helpers/StringFormatter.Text";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import ProductOffersTable from "../ProductOffers";
import {buildProductUrl} from "../../../Helpers/UrlFormatter.Product";
import ProductRouteLink from "../../App/Links/ProductRouteLink";

type ProductProps = {
    product?: IProduct,
    productVariants?: IProductVariants,
    productDebugData?: IProductDebugData,
    productCacheState?: CacheState,
    category?: ICategory,

    verifications?: { [imageKey: number]: IShophunterImageData[] }
    userSessionId: string | undefined,

    isProductLiked: boolean,
    isProductOwn: boolean,
    isProductLikedPending: boolean,
    isProductOwnPending: boolean,

    isProductCompare: boolean,
    showVerify: boolean
    isProductVerified: boolean,
    isProductMoved: boolean,

    lang: string,
    isDebugEnabled: boolean
}

type ProductState = {
    imagePreviewId: number
    isImagePreviewOpen: boolean

    imageVerifyMenuVisible: boolean,
    imageVerifyMenuImageId: number,
    imageVerifyMenuX: number,
    imageVerifyMenuY: number
}

const smoothScroll = function (targetEl: string, duration: number) {
    const headerElHeight = document.getElementsByClassName("header-wrapper")[0].clientHeight;
    let target = document.querySelector(targetEl);
    if (target) {
        let targetPosition = target.getBoundingClientRect().top - headerElHeight
        let startPosition = window.pageYOffset;
        let startTime: number | null = null;

        const ease = function (t: number, b: number, c: number, d: number) {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        };

        const animation = function (currentTime: number) {
            if (startTime === null) startTime = currentTime;
            const timeElapsed = currentTime - startTime;
            const run = ease(timeElapsed, startPosition, targetPosition, duration);
            window.scrollTo(0, run);
            if (timeElapsed < duration) requestAnimationFrame(animation);
        };

        requestAnimationFrame(animation);
    }
};

const smoothScrollLinkClick: MouseEventHandler<HTMLElement> = (e) => {
    let target = e.currentTarget.getAttribute("href");
    if (target && target.length && target[0] == "#") {
        e.preventDefault()
        e.stopPropagation()

        smoothScroll(target, 900)
    }
}

/** @type TranslationKeys, keep this variable without TS type, for keep type check magic works*/
const ComponentTranslationKey = "Product"

/** @see ProductTitleDemo */
/** @see ProductDescriptionDemo */
export default function Product(props: { productId: number }) {

    // globals
    const dispatch = useDispatch()
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    const getTranslationTabsSection = function (key: keyof TranslationSet["Product"]["TabsSection"]) {
        return t('Product:TabsSection:' + key)
    }

    const [state, setState] = useState<ProductState>({
        imagePreviewId: 0,
        isImagePreviewOpen: false,

        imageVerifyMenuVisible: false,
        imageVerifyMenuImageId: 0,
        imageVerifyMenuX: 0,
        imageVerifyMenuY: 0
    })

    const propsExtend = useSelector(function (state: IAppState): ProductProps {
        const productKey = getProductCacheKey(
            props.productId,
            state.config.lang ? state.config.lang : getDefaultLang(),
            state.config.debug)

        const cacheObject = state.cache.product[productKey] ? state.cache.product[productKey] : undefined;
        const product = cacheObject && cacheObject.object ? cacheObject.object.product : undefined

        const cacheDataObject = cacheObject && cacheObject.object ? cacheObject.object.productDetails : undefined;

        const categoryCacheKey = cacheObject && cacheObject.object
            ? getCategoryCacheKey(
                cacheObject.object.product.category_id,
                state.config.lang ? state.config.lang : getDefaultLang(),
                state.config.debug)
            : "";

        const category = cacheObject && state.cache.category[categoryCacheKey] ? state.cache.category[categoryCacheKey].object : undefined

        return {
            product: product,
            productVariants: cacheDataObject && cacheDataObject.object ? cacheDataObject.object.variants : undefined,
            productDebugData: cacheDataObject && cacheDataObject.object ? cacheDataObject.object.debugData : undefined,
            productCacheState: cacheObject ? cacheObject.cacheState : undefined,
            category: category,

            lang: state.config.lang ? state.config.lang : getDefaultLang(),
            isDebugEnabled: state.config.debug,

            isProductLiked: state.session.likedProducts.productIds.includes(props.productId),
            isProductLikedPending: state.session.likedProducts.pendingProductIds.includes(props.productId),
            isProductOwn: state.session.ownerProducts.productIds.includes(props.productId),
            isProductOwnPending: state.session.ownerProducts.pendingProductIds.includes(props.productId),

            isProductCompare: product?.category_id && state.session.compareProducts[product.category_id]
                ? state.session.compareProducts[product.category_id].includes(props.productId)
                : false,

            showVerify: state.config.debug,
            isProductVerified: state.session.shophunter.shophunterData?.verifiedProducts[props.productId] && product
                ? state.session.shophunter.shophunterData?.verifiedProducts[props.productId].verifiedCategoryId == product.category_id
                : false,
            isProductMoved: state.session.shophunter.shophunterData?.verifiedProducts[props.productId] && product
                ? state.session.shophunter.shophunterData?.verifiedProducts[props.productId].verifiedCategoryId != product.category_id
                : false,

            verifications: state.session.shophunter.shophunterData?.verifiedImages,
            userSessionId: state.session.userSessionId
        };
    })

    const onContextMenuClose = function () {
        setState({
            ...state,
            imageVerifyMenuVisible: false,
        })
    }

    const onContextMenu = function (e: React.MouseEvent, imageId: number) {
        if (propsExtend.isDebugEnabled) {
            e.preventDefault()
            e.stopPropagation()
            setState({
                ...state,
                imageVerifyMenuVisible: true,
                imageVerifyMenuImageId: imageId,
                imageVerifyMenuX: e.clientX,
                imageVerifyMenuY: e.clientY
            })
            dispatch(triggerImageVerification_MenuOpen());
        }
    }

    const onCloseImagePreview = () => {
        setState({
            ...state,
            isImagePreviewOpen: false,
            imagePreviewId: 0
        })
    }

    const openImagePreview = function (productImageId: number) {
        setState({
            ...state,
            isImagePreviewOpen: true,
            imagePreviewId: productImageId
        })
    }

    const moveSecondaryImagesRight: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.currentTarget.parentElement) {
            e.currentTarget.parentElement.scrollLeft += 50;
        }
    }

    const moveSecondaryImagesLeft: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.currentTarget.parentElement) {
            e.currentTarget.parentElement.scrollLeft -= 50;
        }
    }

    const onToggleCompare: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (propsExtend.product) {
            if (propsExtend.isProductCompare) {
                removeCompareProduct(propsExtend.product.category_id, propsExtend.product.id)
                dispatch(triggerProductStateRemoveCompare(propsExtend.product.category_id, propsExtend.product.id));
            } else {
                addCompareProduct(propsExtend.product.category_id, propsExtend.product.id)
                dispatch(triggerProductStateAddCompare(propsExtend.product.category_id, propsExtend.product.id));
            }
        }
    }

    const onVerifyCategory: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (propsExtend.product && propsExtend.userSessionId) {
            if (propsExtend.isProductVerified) {
                dispatch(triggerProductVerifyCategoryDelete(propsExtend.userSessionId, propsExtend.product.id, propsExtend.product.category_id));
            } else {
                dispatch(triggerProductVerifyCategorySave(propsExtend.userSessionId, propsExtend.product.id, propsExtend.product.category_id));
            }
        }
    }

    const onVerifyModalOpen: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (propsExtend.product) {
            dispatch(triggerOpenVerifyProduct(propsExtend.product));
        }
    }

    const product = propsExtend.product;
    const productDebugData = propsExtend.productDebugData;
    const productVariants = propsExtend.productVariants;
    const category = propsExtend.category;
    const productCacheState = propsExtend.productCacheState;

    const productName = product
        ? (propsExtend.isDebugEnabled
            ? product.name
            : replaceSpecialUnicodeCharacters(product.name))
        : undefined;

    let specificationCount = 0
    if (product?.specifications) {
        for (const specification of product?.specifications) {
            for (const attributeValue of specification.attributeValues) {
                if (attributeValue.value) {
                    specificationCount++;
                }
            }
        }
    }

    const accessoryCategories: React.ReactElement[] = []
    const sparepartCategories: React.ReactElement[] = []
    let uniqueNamePath = "";
    if (product && product.uniqueNamePathRelatedCategories) {
        for (const unpCategory of product.uniqueNamePathRelatedCategories) {
            if (!propsExtend.isDebugEnabled && unpCategory.primaryCount === 0) {
                continue;
            }
            uniqueNamePath = unpCategory.name;
            const categoryCard = <CategoryCard key={unpCategory.category.id + '-rc'}
                                               className={"mr-2 mb-2 float-left"}
                                               category={{
                                                   id: unpCategory.category.id,
                                                   name: unpCategory.category.name,
                                                   path: unpCategory.category.path,
                                                   exampleImages: unpCategory.category.sampleProducts
                                                       ? unpCategory.category.sampleProducts.map(i => ({image_id: i.imageId})) : [],
                                                   count: unpCategory.primaryCount,
                                                   countSecondary: unpCategory.count - unpCategory.primaryCount,
                                               }}
                                               appearance={{
                                                   filters: [],
                                                   showPath: true
                                               }}
                                               context={{
                                                   lang: propsExtend.lang,
                                                   debug: propsExtend.isDebugEnabled
                                               }}
                                               link={{
                                                   urlParams: {
                                                       bridgeParams: {
                                                           categoryId: unpCategory.category.id,
                                                           language: undefined,
                                                           color: undefined,
                                                           filters: unpCategory.filterVar + ":" + unpCategory.id,
                                                           expand: undefined,
                                                           expandedBrands: undefined,
                                                           secondaryExpand: undefined,
                                                           showSecondary: undefined,
                                                           fold: undefined,
                                                           foldedBrands: undefined,
                                                           page: undefined,
                                                           size: undefined,
                                                           text: undefined,
                                                           code: undefined,
                                                           sort: undefined,
                                                           group: undefined,
                                                       },
                                                       showFilters: undefined,
                                                       openFilterOption: [],
                                                       openUnpOptions: [],
                                                   }
                                               }}/>
            if (unpCategory.type == 'accessory') {
                accessoryCategories.push(categoryCard)
            } else {
                sparepartCategories.push(categoryCard)
            }
        }
    }

    const productSpecificationName: (string | JSX.Element | undefined)[] = uniqueNamePath != ""
        ? [uniqueNamePath]
        : product ? [
            product.manufacturer?.name
                // ? <span className={`text-manufacturer-blue`}>{product.manufacturer?.name}</span>
                ? <span className={``}>{product.manufacturer?.name}</span>
                : undefined,
            product.family_chain?.length
                // ? <span className={`text-family-blue`}>{product.family_chain?.map(i => i.name).join(' ')}</span>
                ? <span className={``}>{product.family_chain?.map(i => i.name).join(' ')}</span>
                : undefined,
            product.codes?.filter(i => i.type == 'MODEL_CODE').map(i => i.presentation).join(' '),
            // TODO: add postfix
            product.codes?.filter(i => i.type == 'MODEL_VERSION').map(i => i.presentation).join(' '),         // TODO: this is name_data now
            product.codes?.filter(i => i.type == 'MODEL_GENERATION').map(i => i.presentation).join(' '),      // TODO: this is name_data now
            product.codes?.filter(i => i.type == 'MODEL_YEAR').map(i => i.presentation).join(' '),            // TODO: this is name_data now
        ].filter(i => i && ((typeof i === "string" && i.length) || typeof i !== "string")) : [];

    let previousProduct: ReactElement | undefined = undefined
    let nextProduct: ReactElement | undefined = undefined
    if (product) {
        if (product.previousProduct) {
            const [previousProductUrl, isValidPreviousProductUrl] = product.previousProduct.gtinId
                ? buildProductUrl(product.previousProduct.gtinId, product.previousProduct.name, product.previousProduct.categoryName)
                : buildProductUrl(product.previousProduct.id, product.previousProduct.name, product.previousProduct.categoryName)
            if (isValidPreviousProductUrl) {
                previousProduct = <ProductRouteLink className={`product-page-previous-product`}
                                                    to={previousProductUrl}
                                                    title={product.previousProduct.name ? product.previousProduct.name : ''}>
                    <IconChevronLeft style={{width: '2.5em', display: 'inline'}} fill={`#aaa`}/>
                    <div className={`product-page-previous-product-title`}>
                        {product.previousProduct.name ? product.previousProduct.name : ''}
                    </div>
                </ProductRouteLink>
            }
        }
        if (product.nextProduct) {
            const [nextProductUrl, isValidNextProductUrl] = product.nextProduct.gtinId
                ? buildProductUrl(product.nextProduct.gtinId, product.nextProduct.name, product.nextProduct.categoryName)
                : buildProductUrl(product.nextProduct.id, product.nextProduct.name, product.nextProduct.categoryName)
            if (isValidNextProductUrl) {
                nextProduct = <ProductRouteLink className={`product-page-next-product`}
                                                to={nextProductUrl}
                                                title={product.nextProduct.name ? product.nextProduct.name : ''}>
                    <div className={`product-page-previous-product-title`}>
                        {product.nextProduct.name ? product.nextProduct.name : ''}
                    </div>
                    <IconChevronRight style={{width: '2.5em', display: 'inline'}} fill={`#aaa`}/>
                </ProductRouteLink>
            }
        }
    }

    if (productCacheState == CacheState.NotFound) {
        return (
            <div className="404-page-not-found text-center text-3xl pt-48">
                <div className={`text-[5em] text-gray-100`}>404</div>
                <div className={`mt-8 text-gray-300`}>Page not found</div>
            </div>
        )
    }

    return (
        <div>

            <div className="product-page-category-path">
                {category ? <CategoryPath category={category} uncategorized={product?.category_not_listed}/> : null}
            </div>

            {productCacheState == CacheState.Loading ?
                <div className="product-page-message">
                    <SpinLoadingIndicator message="Loading..."/>
                </div> : null}

            {!product ? null :
                <div>
                    <h1 className="product-title">{productName}</h1>

                    <div className={`product-image-description-block ` +
                        (propsExtend.isProductOwn ? ` product-own` : ``) +
                        (propsExtend.isProductLiked ? ` product-like` : ``) +
                        (propsExtend.isProductCompare ? ` product-compare` : ``) +
                        (propsExtend.isProductVerified ? ` product-verified` : ``) +
                        (propsExtend.isProductMoved ? ` product-moved` : ``)}>

                        <div className={`product-image-description-block-inner`}>

                            <div className="product-image-description-columns">
                                <div className="product-image-description-images-column">
                                    <div className="product-image-description-images-main-column">
                                        <div
                                            className="product-image-description-images-main-wrapper cursor-pointer"
                                            onClick={() => {
                                                openImagePreview(product.image_id)
                                            }}
                                            onContextMenu={(e) => {
                                                onContextMenu(e, product.image_id)
                                            }}>
                                            <ProductImage imageId={product.image_id} alt={product.name} size={'xl'}/>
                                        </div>
                                    </div>
                                    <div className="product-image-description-images-secondary-column">
                                        <div className="product-image-description-images-secondary-column-content">
                                            {product.image_ids.map((i, index) =>
                                                <div
                                                    className={(index > 0 ? `ml-1 ` : ``) + `product-image-description-images-secondary-wrapper cursor-pointer`}
                                                    key={'detailed-' + index}
                                                    onClick={() => {
                                                        openImagePreview(i)
                                                    }}
                                                    onContextMenu={(e) => {
                                                        onContextMenu(e, i)
                                                    }}>
                                                    <ProductImage imageId={i} alt={product.name} size={'xs'}/>
                                                </div>)}

                                            <div
                                                className={`absolute left-0 top-5 hover:bg-white rounded-md cursor-pointer`}
                                                onClick={moveSecondaryImagesLeft}>
                                                <IconChevronLeft style={{width: '2.5em', display: 'inline'}}
                                                                 fill={`#aaa`}/>
                                            </div>
                                            <div
                                                className={`absolute right-0 top-5 hover:bg-white rounded-md cursor-pointer`}
                                                onClick={moveSecondaryImagesRight}>
                                                <IconChevronRight style={{width: '2.5em', display: 'inline'}}
                                                                  fill={`#aaa`}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="product-image-description-details-column category-item-card-controls-wrapper">
                                    <div className="product-image-description-details-wrapper">

                                        <div className={`flex justify-between`}>
                                            <h3 className={`product-image-description-header`}>
                                                {productSpecificationName.map((i, index) => (
                                                    <span key={`i${index}`}>
                                                            {index > 0 ? ' ' : null}
                                                        {i}
                                                        </span>
                                                ))}
                                            </h3>

                                            {propsExtend.isDebugEnabled ? (
                                                <div className="mt-2 inline-block relative">
                                                    <a href={getDebugProductUrl(product.id)} target='backend'
                                                       className="backend-btn cursor-pointer absolute top-0 right-0">FIX</a>
                                                </div>
                                            ) : undefined}
                                        </div>

                                        <div className="flex-1 mt-2 mb-2">
                                            {product.description
                                                ? <div className="leading-4 text-gray-500">
                                                    {product.description.split("\n").map(
                                                        (s, i) =>
                                                            s.length
                                                                ? <div key={i}>{s}</div>
                                                                : <div key={i} className={`mt-2`}></div>)}
                                                </div>
                                                : <PlaceholderText wordsCount={95}
                                                                   seed={propsExtend.product?.id || 1234}/>
                                            }
                                        </div>

                                        <div className="flex justify-between items-end">
                                            <div>
                                                {product?.priceMinInclVat && product?.priceCurrency
                                                    ? <div className="product-image-description-details-price">
                                                        <div className={`product-image-description-details-price-text`}>
                                                            {getTranslation('price_from')}
                                                        </div>
                                                        <Price price={product?.priceMinInclVat}
                                                               currency={product.priceCurrency}/>
                                                    </div>
                                                    : null}
                                            </div>

                                            <div>
                                                {productVariants && productVariants.variant_group_id
                                                    ? <ProductVariantSelect variants={productVariants} categoryName={product.category_name}/>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>

                                    {propsExtend.product
                                        ? <div className={`absolute -top-[12px] left-0 right-0`}>
                                            <CardControls productId={propsExtend.product?.id}
                                                          productIsMissing={false}
                                                          isLike={propsExtend.isProductLiked}
                                                          isLikePending={propsExtend.isProductLikedPending}
                                                          isOwn={propsExtend.isProductOwn}
                                                          isOwnPending={propsExtend.isProductOwnPending}
                                                          isCompare={propsExtend.isProductCompare}
                                                          onCompare={onToggleCompare}
                                                          isVerify={propsExtend.isProductVerified}
                                                          showVerify={propsExtend.showVerify}
                                                          onVerifyCategory={onVerifyCategory}
                                                          onVerifyModalOpen={onVerifyModalOpen}/>
                                        </div>
                                        : null}

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="product-image-tables-block">

                        <div className="ml-2">
                            <a href="#retail" className="product-tab active" onClick={smoothScrollLinkClick}>
                                {getTranslationTabsSection("retailers")}{product.productOffersCountBuyable && ` (${product.productOffersCountTotal})`}
                            </a>
                            {specificationCount > 0
                                ? <a href="#spec" className="product-tab ml-2" onClick={smoothScrollLinkClick}>
                                    {getTranslationTabsSection("specifications")}{` (${specificationCount})`}
                                </a>
                                : <span className="product-tab ml-2 text-gray-500">
                                        {getTranslationTabsSection("specifications")}
                                    </span>}

                            {accessoryCategories.length > 0
                                ? <a href="#acc" className="product-tab ml-2" onClick={smoothScrollLinkClick}>
                                    {getTranslationTabsSection("accessories")}{` (${accessoryCategories.length})`}
                                </a>
                                : <span className="product-tab ml-2 text-gray-500">
                                        {getTranslationTabsSection("accessories")}
                                    </span>
                            }
                            {sparepartCategories.length > 0
                                ? <a href="#parts" className="product-tab ml-2" onClick={smoothScrollLinkClick}>
                                    {getTranslationTabsSection("parts")}{` (${sparepartCategories.length})`}
                                </a>
                                : <span className="product-tab ml-2 text-gray-500">
                                        {getTranslationTabsSection("parts")}
                                    </span>
                            }
                            <span className="product-tab ml-2 text-gray-500">
                                    {getTranslationTabsSection("reviews")}
                                </span>
                            <span className="product-tab ml-2 text-gray-500">
                                    {getTranslationTabsSection("videos")}
                                </span>
                            {/*<a href="#debug" className="product-tab ml-2 hidden">*/}
                            {/*    Debug*/}
                            {/*</a>*/}
                        </div>

                        <div className="mt-2">
                            <div id="retail" className="bg-white border rounded-md relative">
                                <ProductOffersTable product={product} scrollTop={() => {smoothScroll("#retail", 100)}}/>
                                {propsExtend.isDebugEnabled ? (
                                    <div className={`absolute top-1 right-1`}>
                                            <span
                                                className={`ml-2`}>Total Count: {product.productOffersCountTotal}</span>
                                        <span
                                            className={`ml-2`}>Buyable Count: {product.productOffersCountBuyable}</span>
                                    </div>
                                ) : undefined}
                            </div>

                            <div className="mt-6">
                                <div id="spec" className="bg-white border p-2 rounded-md relative">
                                    <h2 className={`specs-instances-header pt-2 pb-2`}>
                                        {getTranslationTabsSection("specifications")}
                                    </h2>
                                    <SpecificationsTable
                                        products={[product]}
                                        debugData={product.id && propsExtend.productDebugData ? {
                                            [product.id]: propsExtend.productDebugData
                                        } : undefined}
                                        includeProductImage={false}/>
                                </div>
                            </div>

                            <div id="review"></div>
                            <div id="video"></div>

                            {accessoryCategories.length > 0 &&
                                <div className="mt-6">
                                    <div id="acc" className="bg-white border p-2 rounded-md relative">
                                        <h2 className={`specs-instances-header pt-2 pb-2`}>
                                            {`${getTranslationTabsSection("accessories")} ${getTranslationTabsSection("for")}`}
                                            {<span className="text-gray-500"> {uniqueNamePath}</span>}
                                        </h2>
                                        <div className="overflow-auto flex flex-wrap gap-[18px]">
                                            {accessoryCategories}
                                        </div>
                                    </div>
                                </div>
                            }

                            {sparepartCategories.length > 0 &&
                                <div className="mt-6">
                                    <div id="parts" className="bg-white border p-2 rounded-md relative">
                                        <h2 className={`specs-instances-header pt-2 pb-2`}>
                                            {`${getTranslationTabsSection("parts")} ${getTranslationTabsSection("for")}`}
                                            {<span className="text-gray-500"> {uniqueNamePath}</span>}
                                        </h2>
                                        <div className="overflow-auto flex flex-wrap gap-[18px]">
                                            {sparepartCategories}
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>

                </div>
            }

            {previousProduct}
            {nextProduct}

            <ModalImagePreview isModalOpen={state.isImagePreviewOpen}
                               productImageId={state.imagePreviewId}
                               productName={product?.name || ""}
                               productImageIds={product ? product.image_ids : []}
                               onModalClose={onCloseImagePreview}
                               onImageSwitch={openImagePreview}
                               isDebugEnabled={propsExtend.isDebugEnabled}
                               verifications={propsExtend.verifications}
                               productId={propsExtend.product?.id}
                               userSessionId={propsExtend.userSessionId}/>

            {state.imageVerifyMenuVisible && createPortal(
                <MenuImageVerification
                    imageId={state.imageVerifyMenuImageId}
                    productId={product?.id}
                    userSessionId={propsExtend.userSessionId}
                    verifications={propsExtend.verifications}
                    x={state.imageVerifyMenuX}
                    y={state.imageVerifyMenuY}
                    closeCallback={onContextMenuClose}/>,
                getMenuContainerNode()
            )}
        </div>
    );
}
