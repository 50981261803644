export const CACHE_ACCESS_PRODUCT_WITH_CALLBACK = "CACHE_ACCESS_PRODUCT_WITH_CALLBACK"
export const CACHE_ACCESS_PRODUCTS_WITH_CALLBACK = "CACHE_ACCESS_PRODUCTS_WITH_CALLBACK"
export const CACHE_REQUEST_PRODUCT_WITH_CALLBACK = "CACHE_REQUEST_PRODUCT_WITH_CALLBACK";
export const CACHE_REQUEST_PRODUCTS_WITH_CALLBACK = "CACHE_REQUEST_PRODUCTS_WITH_CALLBACK";
export const CACHE_SAVE_PRODUCT_LOADING = "CACHE_SAVE_PRODUCT_LOADING";
export const CACHE_SAVE_PRODUCT_FOUND = "CACHE_SAVE_PRODUCT_FOUND";
export const CACHE_SAVE_PRODUCT_NOT_FOUND = "CACHE_SAVE_PRODUCT_NOT_FOUND";

// ---

export function getProductCacheKey(productId: number, lang: string, debug: boolean): string {
    return `${productId}.${lang}.${debug ? 1 : 0}`
}

// ---

export type CacheAccessProductAction = {
    type: string,
    productId: string | number | undefined,
    callback: () => void
}

export const triggerCacheAccessProductWithCallback = (productId: string | number | undefined, callback: () => void): CacheAccessProductAction => ({
    type: CACHE_ACCESS_PRODUCT_WITH_CALLBACK,
    productId,
    callback
})

// ---

export type CacheAccessProductsAction = {
    type: string,
    productIds: (string | number | undefined)[],
    callback: () => void
}

export const triggerCacheAccessProductsWithCallback = (productIds: (string | number | undefined)[], callback: () => void): CacheAccessProductsAction => ({
    type: CACHE_ACCESS_PRODUCTS_WITH_CALLBACK,
    productIds,
    callback
})



// ----

export type CacheRequestProductAction = {
    type: string,
    productId: number,
    callback?: () => void
}

export const triggerCacheRequestProductWithCallback = (productId: number, callback?: () => void): CacheRequestProductAction => ({
    type: CACHE_REQUEST_PRODUCT_WITH_CALLBACK,
    productId,
    callback,
});

// ---

export type CacheRequestProductsAction = {
    type: string,
    productIds: number[],
    callback?: () => void
}

export const triggerCacheRequestProductsWithCallback = (productIds: number[], callback?: () => void): CacheRequestProductsAction => ({
    type: CACHE_REQUEST_PRODUCTS_WITH_CALLBACK,
    productIds,
    callback,
});

export type CacheSaveProductLoadingAction = {
    type: string,
    productIds: number[],
    options: IApiProductRequestPostParams
}

export const triggerCacheSaveProductLoading = (productIds: number[], options: IApiProductRequestPostParams): CacheSaveProductLoadingAction => ({
    type: CACHE_SAVE_PRODUCT_LOADING,
    productIds,
    options
});

// ---

export type CacheSaveProductFoundAction = {
    type: string,
    product: IProduct,
    category: ICategory | undefined
    data: {
        variants: IProductVariants | undefined,
        debugData: IProductDebugData | undefined
    }
    options: IApiProductRequestPostParams
}

export const triggerCacheSaveProductFound = (
    product: IProduct,
    category: ICategory | undefined,
    variants: IProductVariants | undefined,
    debugData: IProductDebugData | undefined,
    options: IApiProductRequestPostParams): CacheSaveProductFoundAction => ({
    type: CACHE_SAVE_PRODUCT_FOUND,
    product: product,
    category: category,
    data: {
        variants: variants,
        debugData: debugData
    },
    options
});

// ---

export type CacheSaveProductNotFoundAction = {
    type: string,
    productIds: number[],
    options: IApiProductRequestPostParams
}

export const triggerCacheSaveProductNotFound = (productIds: number[], options: IApiProductRequestPostParams): CacheSaveProductNotFoundAction => ({
    type: CACHE_SAVE_PRODUCT_NOT_FOUND,
    productIds,
    options,
});