import React, {Fragment, MouseEventHandler, ReactElement, useEffect, useState} from "react";
import {useDispatch} from 'react-redux';
import {Dialog, Transition} from "@headlessui/react";
import {ProductImage} from "../App/Common/Image";
import {IconChevronLeft, IconChevronRight} from "../App/Icons";
import {ImageVerificationList} from "./ImageVerificationList";
import {triggerImageVerification_Load} from "../../Store/Action/session.verifyImage";

type ModalImagePreviewProps = {
    isModalOpen: boolean
    productImageId: number,
    productImageIds: number[],
    productName: string,
    onModalClose: () => void
    onImageSwitch: (imageId: number) => void
    isDebugEnabled: boolean,
    productId?: number,
    userSessionId?: string,
    verifications?: { [imageKey: number]: IShophunterImageData[] }
};

export default function ModalImagePreview(props: ModalImagePreviewProps) {

    // globals
    const dispatch = useDispatch()

    const [state, setState] = useState({isVerificationListOpen: false});

    const fetchImages = function () {
        const missingImageIds = props.productImageIds.filter(i => props.verifications === undefined || props.verifications[i] === undefined);

        if (missingImageIds.length) {
            dispatch(triggerImageVerification_Load(missingImageIds));
        }
    }

    useEffect(() => {
        if (props.isDebugEnabled) {
            fetchImages()
        }
    }, []);

    const selectNextImage: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const imageIndex = props.productImageIds.findIndex(i => i == props.productImageId)
        const nextIndex = imageIndex < 0 ? 0 : ((imageIndex + 1) % props.productImageIds.length)
        props.onImageSwitch(props.productImageIds[nextIndex])
    }

    const selectPrevImage: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault()
        e.stopPropagation()
        const imageIndex = props.productImageIds.findIndex(i => i == props.productImageId)
        const nextIndex = imageIndex < 0 ? 0 : ((imageIndex + props.productImageIds.length - 1) % props.productImageIds.length)
        props.onImageSwitch(props.productImageIds[nextIndex])
    }

    const openImageVerification: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault()
        e.stopPropagation()

        setState({
            ...state,
            isVerificationListOpen: !state.isVerificationListOpen
        })
    }

    const getImageVerification = function (imageId: number, className: string): ReactElement | null {
        if (!props.isModalOpen) {
            return null
        }
        if (!props.isDebugEnabled) {
            return null
        }
        if (!props.verifications || !props.verifications[imageId]) {
            return null
        }

        let imageType
        let imageTypePending
        let imageContentType
        let imageContentTypePending
        let imageOverlay
        let imageOverlayPending
        let imageWatermark
        let imageWatermarkPending
        let imageStatus
        let imageStatusPending
        let imageVariants
        let imageVariantsPending

        for (const imageVerification of props.verifications[imageId]) {
            switch (imageVerification.verificationType) {
                case VERIFICATION_VERIFICATION_TYPE.TYPE:
                    if (imageVerification.value.imageType) {
                        imageType = imageVerification.value.imageType
                    }
                    if (imageVerification.pending.imageType) {
                        imageTypePending = imageVerification.pending.imageType
                    }
                    break
                case VERIFICATION_VERIFICATION_TYPE.CONTENT_TYPE:
                    if (imageVerification.value.imageContentType) {
                        imageContentType = imageVerification.value.imageContentType
                    }
                    if (imageVerification.pending.imageContentType) {
                        imageContentTypePending = imageVerification.pending.imageContentType
                    }
                    break
                case VERIFICATION_VERIFICATION_TYPE.OVERLAY:
                    if (imageVerification.value.imageOverlay) {
                        imageOverlay = imageVerification.value.imageOverlay
                    }
                    if (imageVerification.pending.imageOverlay) {
                        imageOverlayPending = imageVerification.pending.imageOverlay
                    }
                    break
                case VERIFICATION_VERIFICATION_TYPE.WATERMARK:
                    if (imageVerification.value.imageWatermark) {
                        imageWatermark = imageVerification.value.imageWatermark
                    }
                    if (imageVerification.pending.imageWatermark) {
                        imageWatermarkPending = imageVerification.pending.imageWatermark
                    }
                    break
                case VERIFICATION_VERIFICATION_TYPE.STATUS:
                    if (imageVerification.value.imageStatus) {
                        imageStatus = imageVerification.value.imageStatus
                    }
                    if (imageVerification.pending.imageStatus) {
                        imageStatusPending = imageVerification.pending.imageStatus
                    }
                    break
                case VERIFICATION_VERIFICATION_TYPE.VARIANTS:
                    if (imageVerification.value.imageVariants) {
                        imageVariants = "VARIANTS " + imageVerification.value.imageVariants
                    }
                    if (imageVerification.pending.imageVariants) {
                        imageVariantsPending = "VARIANTS " + imageVerification.pending.imageVariants
                    }
                    break
            }
        }

        if (imageType || imageContentType || imageOverlay || imageWatermark || imageStatus || imageVariants ||
            imageTypePending || imageContentTypePending || imageOverlayPending || imageWatermarkPending || imageStatusPending || imageVariantsPending) {
            const verifications: ReactElement[] = []
            if (imageType || imageTypePending) {
                if (imageTypePending) {
                    verifications.push(<span key="image-type image-type-pending">{imageTypePending}</span>)
                } else {
                    verifications.push(<span key="image-type">{imageType}</span>)
                }
            }
            if (imageContentType || imageContentTypePending) {
                if (imageContentTypePending) {
                    verifications.push(<span
                        key="image-content-type image-content-type-pending">{imageContentTypePending}</span>)
                } else {
                    verifications.push(<span key="image-content-type">{imageContentType}</span>)
                }
            }
            if (imageOverlay || imageOverlayPending) {
                if (imageOverlayPending) {
                    verifications.push(<span key="image-overlauy image-overlauy-pending">{imageOverlayPending}</span>)
                } else {
                    verifications.push(<span key="image-overlauy">{imageOverlay}</span>)
                }
            }
            if (imageWatermark || imageWatermarkPending) {
                if (imageWatermarkPending) {
                    verifications.push(<span
                        key="image-watermark image-watermark-pending">{imageWatermarkPending}</span>)
                } else {
                    verifications.push(<span key="image-watermark">{imageWatermark}</span>)
                }
            }
            if (imageStatus || imageStatusPending) {
                if (imageStatusPending) {
                    verifications.push(<span key="image-status image-status-pending">{imageStatusPending}</span>)
                } else {
                    verifications.push(<span key="image-status">{imageStatus}</span>)
                }
            }
            if (imageVariants || imageVariantsPending) {
                if (imageVariantsPending) {
                    verifications.push(<span key="image-variants image-variants-pending">{imageVariantsPending}</span>)
                } else {
                    verifications.push(<span key="image-variants">{imageVariants}</span>)
                }
            }
            return <div className={className}>{verifications}</div>
        }
        return null
    }

    return <Transition appear show={props.isModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50"
                onClose={props.onModalClose}>
            <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0">
                <div className="modal-shade-overlay"/>
            </Transition.Child>

            <div className="modal-wrapper-space">
                <div className="modal-wrapper-vertical-align text-center">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95">

                        <Dialog.Panel className="modal-image-preview-wrapper">

                            <div className={`flex flex-col`}>
                                <div className={`w-full max-w-4xl h-[580px] flex text-left align-middle`}>
                                    <div className={`flex align-middle cursor-pointer`}
                                         onClick={selectPrevImage}>
                                        <IconChevronLeft style={{width: '3.5em', display: 'inline'}}
                                                         fill={`#aaa`}/>
                                    </div>
                                    <div className={`w-[812px] relative`}>
                                        <ProductImage imageId={props.productImageId}
                                                      alt={props.productName}
                                                      size={`xl`}/>
                                        {getImageVerification(props.productImageId, `absolute top-0 right-0 p-4 border bg-white`)}
                                    </div>
                                    <div className={`flex align-middle cursor-pointer`}
                                         onClick={selectNextImage}>
                                        <IconChevronRight style={{width: '3.5em', display: 'inline'}}
                                                          fill={`#aaa`}/>
                                    </div>
                                    {state.isVerificationListOpen
                                        ? <div className={`mt-8 overflow-y-scroll`}>
                                            <ImageVerificationList
                                                productId={props.productId}
                                                imageId={props.productImageId}
                                                userSessionId={props.userSessionId}
                                                verifications={props.verifications}/>
                                        </div>
                                        : null}
                                </div>

                                <div className={`py-4 whitespace-nowrap overflow-x-scroll`}>
                                    {props.productImageIds.map((productImageId, index) => (
                                        <div
                                            className={`inline-block mx-4 cursor-pointer relative ${props.productImageId == productImageId ? `border` : ``}`}
                                            key={`i${index}`} onClick={() => {
                                            props.onImageSwitch(productImageId)
                                        }}>
                                            <ProductImage imageId={productImageId}
                                                          alt={props.productName}
                                                          size={'xs'}/>
                                            {getImageVerification(productImageId, `absolute top-0 right-0 p-1 border bg-white`)}
                                        </div>))}
                                </div>
                            </div>

                            {props.isDebugEnabled
                                ? <div className={`modal-image-preview-verification`}>
                                    <div className={`modal-image-preview-verification-image`}
                                         onClick={openImageVerification}/>
                                </div>
                                : null}

                            <div className={`modal-image-preview-close`} onClick={props.onModalClose}>
                                <img src={`/resources/close-icon.png`} alt={`Close`}/>
                            </div>
                        </Dialog.Panel>

                    </Transition.Child>
                </div>
            </div>
        </Dialog>
    </Transition>
}
