import React, {ReactElement, useEffect, useRef, useState} from "react";
import {getLogoImageUrl} from "../../../../Helpers/ImageHelper";
import {useSelector} from "react-redux";
import {staticCast} from "../../../../Helpers/TypeHelper";
import {fetchStoreLogoData} from "../../../../Helpers/ApiHelper";

export default function StoreLogo() {

    const ref = useRef<HTMLDivElement>(null)

    const apiTail = useSelector((state: IAppState) => (staticCast<IApiTail>({_logPageToken: state.session.tracking.pageToken || null})))

    const [storeLogos, setStoreLogos] = useState<IStoreLogo[] | undefined>(undefined);
    useEffect(() => {
        if (storeLogos == undefined) {
            fetchStoreLogoData(apiTail).then((storeLogos) => {
                setStoreLogos(storeLogos)
            })
        }
    }, [])


    let style = {}
    const images1: ReactElement[] = []
    const images2: ReactElement[] = []
    if (storeLogos) {
        let totalLogoWidth = 0
        for (const storeLogo of storeLogos) {
            let logoWidth = 88
            if (storeLogo.websiteStoreLogoWidth) {
                logoWidth = Math.round(storeLogo.websiteStoreLogoWidth / 2)
            }
            totalLogoWidth += logoWidth + 40
            images1.push(<img alt={`${storeLogo.websiteStoreName} logo`}
                              key={`1-${storeLogo.websiteStoreId}`}
                              src={getLogoImageUrl(storeLogo.websiteStoreId, "m")}
                              style={{width: logoWidth + "px"}}/>)
            images2.push(<img alt={`${storeLogo.websiteStoreName} logo`}
                              key={`2-${storeLogo.websiteStoreId}`}
                              src={getLogoImageUrl(storeLogo.websiteStoreId, "m")}
                              style={{width: logoWidth + "px"}}/>)
        }
        style = {
            width: (totalLogoWidth * 2) + "px",
            animation: "scroll " + (storeLogos.length * 5) + "s linear infinite"
        }
    }

    return <div className={`store-logo-bar`} ref={ref}>
        <div className={`store-logo-bar-track`} style={style}>
            {images1}
            {images2}
        </div>
    </div>
}