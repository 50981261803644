export function parseBool(str: string | number | boolean | null) {
    if (typeof str === "string") {
        if (str === "true") return true;
        if (str === "false") return false;
    }
    return !!str;
}

export function parseNullable<T>(str: T): T | null {
    if (typeof str === "string") {
        if (str === "null") return null;
    }
    return str;
}

// need this for check passed object to exactly a type
export const staticCast = <T>(obj: T) => (obj)