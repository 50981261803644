import React from "react";

function generateWord(seed: number) {
    const wordLengthChance = [3, 16, 21, 15, 10, 8, 7, 5, 4, 2, 1];     // frequency distribution of word length on english (from some random research)
    const wordLengthSum = 92;   // sum of above

    let random = seed
    random ^= random << 13;
    random ^= random >> 17;
    random ^= random << 5;

    random = random % wordLengthSum;
    if (random < 0) random += wordLengthSum;
    let wordLengthIndex = 0;
    while (random >= wordLengthChance[wordLengthIndex]) {
        random -= wordLengthChance[wordLengthIndex];
        wordLengthIndex++;
        if (wordLengthIndex >= wordLengthChance.length) {
            wordLengthIndex--;
            break;
        }
    }

    return ' '.repeat(wordLengthChance[wordLengthIndex]);
}

/** @see PlaceholderTextDemo */
export function PlaceholderText(props: {
    seed: number,
    wordsCount: number,
    tag?: keyof JSX.IntrinsicElements,
    class?: string
}) {

    let words: Array<JSX.Element | string> = [];
    for (let i = 0; i < props.wordsCount; i++) {
        if (i > 0) words.push(' ');
        words.push(<span key={`ph` + i}>{generateWord(props.seed + i)}</span>);
    }

    if (props.tag) return <props.tag className={`placeholder-text ${props.class}`}>{words}</props.tag>
    return <div className={`placeholder-text ${props.class}`}>{words}</div>
}
