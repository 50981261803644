import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import {
    convertSearchParamsToUrlResolveParams,
    getPageParamsFromSearchUrl, getSearchApiFromPageParams,
} from "../../Helpers/SearchHelper";
import {getSearchCacheKey, triggerCacheRequestSearchWithCallback} from "../../Store/Action/cache.search";
import {getDefaultLang, triggerDebug} from "../../Store/Action/interactive";
import {
    getCategoryCacheKey,
    isFilterPanelAvailable,
    selectErrorCache,
    selectSearchCache
} from "../../Store/StoreHelper";
import FiltersPanel from "../../Components/ProductList_FiltersPanel/FiltersPanel";
import CategoryPanel from "../../Components/ProductList_CategoryPanel/CategoryPanel";
import SearchListPagination from "../../Components/ProductList/ProductList_Pagination";
import React, {useEffect, useState} from "react";
import CategoryCard from "../../Components/ProductsGrid/Card_Category";
import ProductGrid from "../../Components/ProductsGrid";
import {SpinLoadingIndicator} from "../../Components/App/Common/SpinLoadingIndicator";
import SearchListNoFilterResult from "../../Components/ProductList/ProductList_NoFilterResults";
import SearchListServerNotResponse from "../../Components/ProductList/ProductList_ServerNotResponse";
import ProductsTable from "../../Components/ProductsTable";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";
import FiltersBarFiltersList from "../../Components/ProductList_FiltersBar/FiltersBar/FiltersBarFiltersList";
import {limitStringLength} from "../../Helpers/StringFormatter.Text";
import {formatNumberCustomDecimals} from "../../Helpers/StringFormatter.Number";
import {buildSearchUrl, parseUrlToSearchParams} from "../../Helpers/UrlFormatter.Search";
import {MetaTags} from "../../Components/App/Tags/Meta";
import {convertCategoryPathToJsonLd} from "../../Helpers/JsonLdHelper";
import SearchBreadcrumbs from "./SearchBreadcrumbs";
import SearchTitle from "./SearchTitle";

const ComponentTranslationKey = "Meta"

export default function SearchRoute() {

    // globals
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    // extract global state
    const propsExtension = useSelector((state: IAppState) => {
        const urlPageParams = parseUrlToSearchParams(location.pathname, location.search);
        const urlRequestKey = getSearchApiFromPageParams(urlPageParams);

        const searchKey = getSearchCacheKey(
            urlRequestKey,
            state.config.lang ? state.config.lang : getDefaultLang(),
            state.config.debug)

        const categoryCacheKey = urlPageParams.bridgeParams.categoryId
            ? getCategoryCacheKey(
                urlPageParams.bridgeParams.categoryId,
                state.config.lang ? state.config.lang : getDefaultLang(),
                state.config.debug)
            : "";

        const searchData = selectSearchCache(state, searchKey);
        const error = selectErrorCache(state, searchKey);
        const skipFilterPanel = searchData && searchData.object ? searchData.object.items.title.titleType == IProductList_TitleType.Category : false

        return {
            category: urlPageParams.bridgeParams.categoryId && state.cache.category[categoryCacheKey] ? state.cache.category[categoryCacheKey].object : undefined,
            categoryItems: searchData && searchData.object ? searchData.object.items : undefined,

            topRankProducts: searchData && searchData.object ? searchData.object.topRankProducts : undefined,
            uniqueNamePathRelatedCategories: searchData && searchData.object ? searchData.object.uniqueNamePathRelatedCategories : undefined,

            title: searchData && searchData.object
                ? searchData.object.items.title
                : undefined,

            searchQuery: searchData && searchData.object ? searchData.object.searchQuery : undefined,
            responseDetails: searchData && searchData.object ? searchData.object.responseDetails : undefined,
            urlPageParams: urlPageParams,
            urlContext: state.urlContext,

            urlSearchKey: urlRequestKey,
            searchCacheState: searchData ? searchData.cacheState : undefined,

            searchCacheKey: searchKey,
            searchLang: state.config.lang ? state.config.lang : getDefaultLang(),
            searchIsDebug: state.config.debug,

            compareKey: urlPageParams.bridgeParams.categoryId,

            visibleFilters: state.interactive.showFilters,
            visibleCategories: state.interactive.showCategories,

            showFilterPanel: isFilterPanelAvailable(state, location.pathname) && skipFilterPanel,
            error : error
        }
    })

    const [prevPropsExtension, setPrevPropsExtension] = useState({
        searchQuery: propsExtension.searchQuery ? {...propsExtension.searchQuery} : undefined,
        searchCacheKey: propsExtension.searchCacheKey,
        urlSearchKey: propsExtension.urlSearchKey,
    })

    const equalFilters = (currentFilters: string | undefined, prevFilters: string | undefined): boolean => {
        if (!currentFilters && prevFilters) return false;
        if (currentFilters && !prevFilters) return false;
        if (!currentFilters && !prevFilters) return true;
        if (currentFilters && prevFilters) {

            const filterKeys: { [k: string]: true } = {};

            const currentFilterMap: { [k: string]: string[] } = {};
            for (const f of currentFilters.split(',')) {
                const fParts = f.split(':', 2)
                currentFilterMap[fParts[0]] = fParts[1].split('|').sort()
                filterKeys[fParts[0]] = true;
            }

            const prevFilterMap: { [k: string]: string[] } = {};
            for (const f of prevFilters.split(',')) {
                const fParts = f.split(':', 2)
                prevFilterMap[fParts[0]] = fParts[1].split('|').sort()
                filterKeys[fParts[0]] = true;
            }

            for (const fk in filterKeys) {
                const currentSet: string[] | undefined = currentFilterMap[fk];
                const prevSet: string[] | undefined = prevFilterMap[fk];

                if (!currentSet && prevSet) return false;
                if (currentSet && !prevSet) return false;
                if (!currentSet && !prevSet) continue;
                if (currentSet && prevSet) {
                    if (currentSet.length != prevSet.length) return false;
                    for (let i = 0; i < currentSet.length; i++)
                        if (currentSet[i] != prevSet[i]) return false
                }
            }

            return true;
        }
        return false;
    }

    const equalSort = (currentSort: number | undefined, prevSort: number | undefined): boolean => {
        return (currentSort || 1) == (prevSort || 1)
    }

    const equalSize = (currentSize: string | undefined, prevSize: string | undefined): boolean => {
        return (currentSize || "m") == (prevSize || "m")
    }

    // internal functions
    const checkRedirect = () => {
        if (!propsExtension.urlPageParams) return
        const [searchPath, searchQuery, isValidLink] = buildSearchUrl(propsExtension.urlPageParams, propsExtension.urlContext)
        if (!isValidLink) {
            console.log("Search link error");
            console.log(searchPath, searchQuery, urlPageParams, propsExtension.urlContext)
            const trace = new Error().stack
            console.log(trace)
            dispatch(triggerDebug("Search link error (console)", trace))
        }

        if (searchPath != location.pathname) {
            navigate({
                pathname: searchPath,
                search: searchQuery
            })
        }
    }

    // lifecycle functions
    useEffect(() => {
        const searchParams = getPageParamsFromSearchUrl(propsExtension.urlSearchKey)
        const prevSearchParams = getPageParamsFromSearchUrl(prevPropsExtension.urlSearchKey);

        const bridgeParams = searchParams.bridgeParams
        let prevBridgeParams: IBridgeParams_Search =
            prevPropsExtension.searchQuery ? {
                    ...convertSearchParamsToUrlResolveParams(prevPropsExtension.searchQuery).bridgeParams,
                    ...(prevSearchParams.bridgeParams ? {page: prevSearchParams.bridgeParams.page} : {})
                }
                : prevSearchParams.bridgeParams

        // this condition not include: language, expand, secondaryExpand, showSecondary, fold
        // all else trigger scroll

        if (bridgeParams.categoryId != prevBridgeParams.categoryId
            || !equalFilters(bridgeParams.filters, prevBridgeParams.filters)
            || bridgeParams.color != prevBridgeParams.color
            || bridgeParams.page != prevBridgeParams.page
            || !equalSize(bridgeParams.size, prevBridgeParams.size)
            || bridgeParams.text != prevBridgeParams.text
            || bridgeParams.code != prevBridgeParams.code
            || bridgeParams.group != prevBridgeParams.group
            || !equalSort(bridgeParams.sort, prevBridgeParams.sort)
        ) {
            checkRedirect()
            window.scrollTo(0, 0)
            setPrevPropsExtension({
                searchCacheKey: propsExtension.searchCacheKey,
                searchQuery: propsExtension.searchQuery,
                urlSearchKey: propsExtension.urlSearchKey,
            })
        }
    }, [propsExtension.searchCacheKey]);

    useEffect(() => {
        if (propsExtension.categoryItems === undefined && (propsExtension.searchCacheState == undefined || propsExtension.searchCacheState == CacheState.Unknown || propsExtension.searchCacheState == CacheState.ServerNotResponse)) {
            dispatch(triggerCacheRequestSearchWithCallback(propsExtension.urlPageParams, propsExtension.searchIsDebug));
        }
    }, [propsExtension.searchCacheKey]);

    // render
    const categoryItems = propsExtension.categoryItems;
    const urlPageParams = propsExtension.urlPageParams;
    // warning: this fallback use on other places too
    const size = (urlPageParams.bridgeParams.size) ? (urlPageParams.bridgeParams.size) : "medium";

    const renderGrid = () => {
        const categoryItems = propsExtension.categoryItems;
        const category = propsExtension.category;
        const searchCacheState = propsExtension.searchCacheState;
        const urlPageParams = propsExtension.urlPageParams;
        const topRankProducts = propsExtension.topRankProducts
        const uniqueNamePathRelatedCategories = propsExtension.uniqueNamePathRelatedCategories;

        if (uniqueNamePathRelatedCategories && uniqueNamePathRelatedCategories.length > 0) {
            const accessoryCategories: React.ReactElement[] = []
            const sparepartCategories: React.ReactElement[] = []
            let uniqueNamePath = "";
            for (const unpCategory of uniqueNamePathRelatedCategories) {
                if (!propsExtension.searchIsDebug && unpCategory.primaryCount === 0) {
                    continue;
                }
                uniqueNamePath = unpCategory.name;
                const categoryCard = <CategoryCard key={unpCategory.category.id + '-rc'}
                                                   className={"mr-2 mb-2 float-left"}
                                                   category={{
                                                       id: unpCategory.category.id,
                                                       name: unpCategory.category.name,
                                                       path: unpCategory.category.path,
                                                       exampleImages: unpCategory.category.sampleProducts
                                                           ? unpCategory.category.sampleProducts.map((im: {
                                                               imageId: number
                                                           }) => ({image_id: im.imageId})) : [],
                                                       count: unpCategory.primaryCount,
                                                       countSecondary: unpCategory.count - unpCategory.primaryCount
                                                   }}
                                                   appearance={{
                                                       filters: [],
                                                       showPath: propsExtension.title?.titleType != IProductList_TitleType.Category
                                                   }}
                                                   context={{
                                                       lang: propsExtension.searchLang,
                                                       debug: propsExtension.searchIsDebug
                                                   }}
                                                   link={{
                                                       urlParams: {
                                                           bridgeParams: {
                                                               categoryId: unpCategory.category.id,
                                                               language: undefined,
                                                               color: undefined,
                                                               filters: unpCategory.filterVar + ":" + unpCategory.id,
                                                               expand: undefined,
                                                               expandedBrands: undefined,
                                                               secondaryExpand: undefined,
                                                               showSecondary: undefined,
                                                               fold: undefined,
                                                               foldedBrands: undefined,
                                                               page: undefined,
                                                               size: undefined,
                                                               text: undefined,
                                                               code: undefined,
                                                               sort: undefined,
                                                               group: undefined,
                                                           },
                                                           showFilters: undefined,
                                                           openFilterOption: [],
                                                           openUnpOptions: [],
                                                       },
                                                   }}/>
                if (unpCategory.type == 'accessory') {
                    accessoryCategories.push(categoryCard)
                } else {
                    sparepartCategories.push(categoryCard)
                }
            }

            return (
                <div>
                    <div
                        className={`cards-list-container no-compensate-overlay-padding-small-screen mx-auto no-card-coloring`}>
                        <div className={``}>
                            {accessoryCategories.length > 0 &&
                                <div className="text-xl pb-4 font-bold text-gray-400 clear-both">
                                    Tillbehör<span
                                    className="text-shopit-darkest-blue"> {propsExtension.title?.details.searchString}</span>
                                </div>}
                            {accessoryCategories}
                            {sparepartCategories.length > 0 &&
                                <div className="text-xl pt-4 pb-4 font-bold text-gray-400 clear-both">
                                    Reservdelar<span
                                    className="text-shopit-darkest-blue"> {propsExtension.title?.details.searchString}</span>
                                </div>}
                            {sparepartCategories}
                        </div>
                    </div>
                </div>
            )


        }

        return <>
            <ProductGrid key={`grid`}
                         urlPageParams={urlPageParams}
                         topLevelAsBreadcrumbs={false}
                         useCardStyleBorders={true}
                         categoryItems={categoryItems}
                         showEmptyMessage={searchCacheState == CacheState.Cache}/>

            {propsExtension.error
                ? <div key={`404`} className="404-page-not-found text-center text-3xl pt-48">
                    <div className={`text-[5em] text-gray-100`}>404</div>
                    <div className={`mt-8 text-gray-300`}>Page not found</div>
                </div>
                : searchCacheState == CacheState.Loading
                    ? <div key={`loading`}
                           className="absolute inset-0 bg-white/50 border text-center p-4 pt-[30vh] pb-[30vh]">
                        <SpinLoadingIndicator message="Loading..." className={`text-3xl`}/>
                    </div>
                    : searchCacheState == CacheState.NotFound
                        ? <div key={`empty`} className="">
                            <SearchListNoFilterResult/>
                        </div>
                        : searchCacheState == CacheState.ServerNotResponse
                            ? <div key={`empty`} className="">
                                <SearchListServerNotResponse/>
                            </div>
                            : null
            }
        </>
    }

    const renderSpec = () => {
        const categoryItems = propsExtension.categoryItems;
        const urlPageParams = propsExtension.urlPageParams;

        return <>
            <ProductsTable key={`grid`}
                           urlPageParams={urlPageParams}
                           categoryItems={categoryItems}/>
        </>
    }

    const renderMeta = () => {

        const filters = propsExtension.searchQuery?.filter.map(a => {
            switch (a._type) {
                case IProductListSearch_FilterType.Manufacturer: {
                    const manufacturerItem = a as IProductListSearch_BrandOrFamilyFilter
                    return manufacturerItem.data.map((d) => {
                            const manufacturerId = d.id.toString()
                            return manufacturerItem.visualDetails.dataName && manufacturerItem.visualDetails.dataName[manufacturerId]
                                ? manufacturerItem.visualDetails.dataName[manufacturerId]
                                : ('#' + manufacturerId)
                        }
                    ).join(', ')
                }
                case IProductListSearch_FilterType.Brand: {
                    const brandItem = a as IProductListSearch_BrandOrFamilyFilter
                    return brandItem.data.map((d) => {
                            const manufacturerId = d.id.toString()
                            return brandItem.visualDetails.dataName && brandItem.visualDetails.dataName[manufacturerId]
                                ? brandItem.visualDetails.dataName[manufacturerId]
                                : ('#' + manufacturerId)
                        }
                    ).join(', ')
                }
                case IProductListSearch_FilterType.Family: {
                    const familyItem = a as IProductListSearch_BrandOrFamilyFilter
                    return familyItem.data.map((d) => {
                            const brandId = d.id.toString()
                            return familyItem.visualDetails.dataName && familyItem.visualDetails.dataName[brandId]
                                ? familyItem.visualDetails.dataName[brandId]
                                : ('#' + brandId)
                        }
                    ).join(', ')
                }
                case IProductListSearch_FilterType.Text: {
                    const textItem = a as IProductListSearch_TextFilter
                    return textItem.data.map((d) => d.id).join(', ')
                }
                case IProductListSearch_FilterType.Code: {
                    const textItem = a as IProductListSearch_TextFilter
                    return textItem.data.map((d) => d.id).join(', ')
                }
                case IProductListSearch_FilterType.RelatedUniqueNamePath: {
                    const uniqueNamePathItem = a as IProductListSearch_RelatedUniqueNamePathFilter
                    return uniqueNamePathItem.data.map((d) => {
                            const uniqueNamePathId = d.id.toString()
                            return uniqueNamePathItem.visualDetails.dataName && uniqueNamePathItem.visualDetails.dataName[uniqueNamePathId]
                                ? uniqueNamePathItem.visualDetails.dataName[uniqueNamePathId]
                                : ('#' + uniqueNamePathId)
                        }
                    ).join(', ')
                }
                case IProductListSearch_FilterType.UniqueNamePath: {
                    const uniqueNamePathItem = a as IProductListSearch_UniqueNamePathFilter
                    return uniqueNamePathItem.data.map((d) => {
                            const uniqueNamePathId = d.id.toString()
                            return uniqueNamePathItem.visualDetails.dataName && uniqueNamePathItem.visualDetails.dataName[uniqueNamePathId]
                                ? uniqueNamePathItem.visualDetails.dataName[uniqueNamePathId]
                                : ('#' + uniqueNamePathId)
                        }
                    ).join(', ')
                }

                case IProductListSearch_FilterType.Attribute: {
                    const attributeItem = a as IProductListSearch_AttributeFilter
                    return attributeItem.data.map((d, index) => {
                            const attributeId = d.id ? d.id.toString() : "-1";
                            return (index > 0 ? ', ' : '')
                                + (attributeItem.visualDetails.dataName && attributeItem.visualDetails.dataName[attributeId]
                                    ? attributeItem.visualDetails.dataName[attributeId]
                                    : ('#' + attributeId))
                        }
                    ).join(', ')
                }

                case IProductListSearch_FilterType.Numeric: {
                    const numericItem = a as IProductListSearch_NumericFilter
                    const name = numericItem.visualDetails ? numericItem.visualDetails.name : numericItem.filter_var;
                    const low = ("low" in numericItem) && numericItem.low !== undefined
                        ? formatNumberCustomDecimals(numericItem.low, 'sv', undefined, ' ')
                        : '...';
                    const high = ("high" in numericItem) && numericItem.high !== undefined
                        ? formatNumberCustomDecimals(numericItem.high, 'sv', undefined, ' ')
                        : '...';
                    return `${name} ${low} - ${high}`
                }

                case IProductListSearch_FilterType.Price: {
                    const numericItem = a as IProductListSearch_PriceFilter
                    const name = numericItem.visualDetails ? numericItem.visualDetails.name : numericItem.filter_var;
                    const low = ("low" in numericItem) && numericItem.low !== undefined
                        ? formatNumberCustomDecimals(numericItem.low, 'sv', undefined, ' ')
                        : '...';
                    const high = ("high" in numericItem) && numericItem.high !== undefined
                        ? formatNumberCustomDecimals(numericItem.high, 'sv', undefined, ' ')
                        : '...';
                    return `${name} ${low} - ${high}`
                }

                case IProductListSearch_FilterType.Color: {
                    const colorItem = a as IProductListSearch_ColorFilter

                    const hl: number = colorItem.hl !== undefined ? colorItem.hl : -40;
                    const hh: number = colorItem.hh !== undefined ? colorItem.hh : 320;
                    const sl: number = colorItem.sl !== undefined ? colorItem.sl : 0;
                    const sh: number = colorItem.sh !== undefined ? colorItem.sh : 100;
                    const ll: number = colorItem.ll !== undefined ? colorItem.ll : 0;
                    const lh: number = colorItem.lh !== undefined ? colorItem.lh : 100;

                    const h = ((hh - hl) / 2) + hl
                    const s = ((sh - sl) / 2) + sl
                    const l = ((lh - ll) / 2) + ll

                    return `h${h}/s${s}/l${l}`
                }

                case IProductListSearch_FilterType.Synset: {
                    const synsetItem = a as IProductListSearch_SynsetFilter
                    return synsetItem.data.map((d) => {
                            const synsetId = d.id ? d.id.toString() : "-1";
                            return synsetItem.visualDetails.dataName && synsetItem.visualDetails.dataName[synsetId]
                                ? synsetItem.visualDetails.dataName[synsetId]
                                : ('#' + synsetId)
                        }
                    ).join(', ')
                }

                default:
                    return `Unknown filter: ${a._type}`
            }
        }).filter(a => a).join(', ')

        const haveNoindexParam =
            (propsExtension.searchQuery?.filter && propsExtension.searchQuery?.filter.length > 0) ||
            (!!propsExtension.searchQuery?.showSecondary) ||
            (!!propsExtension.searchQuery?.fold) ||
            (propsExtension.searchQuery?.expand && propsExtension.searchQuery?.expand.length > 0) ||
            (propsExtension.searchQuery?.secondaryExpand && propsExtension.searchQuery?.secondaryExpand.length > 0) ||
            false

        if (propsExtension.category) {
            const categoryName = propsExtension.category.name;
            const categoryDescription = getTranslation('categoryDescription').replace("<category_name>", categoryName);

            const urlPageParams = propsExtension.urlPageParams;
            const [canonicalToPathname, canonicalToSearch, isValidLink] = buildSearchUrl({
                bridgeParams: {
                    categoryId: urlPageParams.bridgeParams.categoryId,
                    language: undefined,
                    color: undefined,
                    filters: undefined,
                    expand: undefined,
                    expandedBrands: undefined,
                    secondaryExpand: undefined,
                    showSecondary: undefined,
                    fold: undefined,
                    foldedBrands: undefined,
                    page: urlPageParams.bridgeParams.page,
                    size: undefined,
                    text: undefined,
                    code: undefined,
                    group: undefined,
                    sort: undefined,
                },
                showFilters: undefined,
                openFilterOption: undefined,
                openUnpOptions: undefined
            }, propsExtension.urlContext);
            const canonicalUrl = canonicalToSearch && canonicalToSearch.length
                ? `${canonicalToPathname}?${canonicalToSearch}`
                : `${canonicalToPathname}`

            const breadcrumbsSchema = convertCategoryPathToJsonLd(
                propsExtension.category.path.length > 1
                    ? propsExtension.category.path.slice(0, propsExtension.category.path.length - 1)
                    : [],
                {
                    url: canonicalUrl,
                    name: categoryName
                }
            )

            if (propsExtension.category.isCollection) {

                return <MetaTags title={
                    getTranslation('searchCollectionCompare') +
                    ' ' + categoryName + ' ' + (filters ? (filters + ' ') : '')
                }
                                 description={categoryDescription}
                                 robots={haveNoindexParam ? `noindex` : undefined}
                                 canonicalPageUrl={canonicalUrl}
                                 structuredData={breadcrumbsSchema}
                />
            } else {
                const productsCount = propsExtension.categoryItems?.pagination.itemCount;
                const title = productsCount
                    ? (getTranslation('searchCategoryCompare') +
                        ' ' + (productsCount || 0) + getTranslation('searchCategoryCompareCounterSuffix') +
                        ' ' + categoryName + ' ' + (filters ? (filters + ' ') : '') +
                        getTranslation('searchCategoryAtShopit'))
                    : (getTranslation('searchCategoryCompare') +
                        ' ' + categoryName + ' ' + (filters ? (filters + ' ') : '') +
                        getTranslation('searchCategoryAtShopit'));

                return <MetaTags title={title}
                                 description={categoryDescription}
                                 robots={haveNoindexParam ? `noindex` : undefined}
                                 canonicalPageUrl={canonicalUrl}
                                 structuredData={breadcrumbsSchema}
                />
            }
        } else {
            return <MetaTags title={getTranslation("indexTitle")}
                             description={getTranslation("indexDescription")}
                             robots={haveNoindexParam ? `noindex` : undefined}
                             canonicalPageUrl={"default"}
            />
        }
    }

    return (
        <div className={``}>

            {renderMeta()}

            <div className={`header-third-row search-three-card:hidden`}>
                {propsExtension.showFilterPanel && propsExtension.searchQuery && propsExtension.searchQuery.filter
                    ? <FiltersBarFiltersList filters={propsExtension.searchQuery.filter}/>
                    : null}
            </div>

            <div className={
                `filter-overlay-base filter-overlay ${propsExtension.visibleFilters ? "filter-overlay-visible" : ""} bg-gray-100`}>
                {propsExtension.visibleFilters ? <FiltersPanel key="filters"/> : null}
            </div>

            <div className={
                `filter-overlay-base filter-overlay ${propsExtension.visibleCategories ? "filter-overlay-visible" : ""} bg-gray-100`}>
                {/*{propsExtension.visibleCategories ? <CategoryPanel key="categories"/> : null}*/}
                <CategoryPanel key="categories"/>
            </div>

            <div className={`max-w-[100vw]`}>
                {categoryItems && categoryItems.pagination?.itemOffset == 0 && !(urlPageParams.bridgeParams.filters?.length)
                    ? <div
                        className={`bg-slate-50 border-b border-t mb-4 filter-overlay-padding-animation ${propsExtension.visibleFilters || propsExtension.visibleCategories ? `filter-overlay-padding` : `cards-list-container-padding-compensate`}`}>
                        <div
                            className="cards-list-container cards-list-container-small-fullscreen no-compensate-overlay-padding-small-screen mx-auto">
                            <SearchTitle urlPageParams={urlPageParams}/>
                        </div>
                    </div>
                    : null}

                <div
                    className={`filter-overlay-padding-animation ${propsExtension.visibleFilters || propsExtension.visibleCategories ? `filter-overlay-padding` : `cards-list-container-padding-compensate`}`}>
                    <div
                        className="cards-list-container cards-list-container-small-fullscreen no-compensate-overlay-padding-small-screen mx-auto">
                        <SearchBreadcrumbs urlPageParams={urlPageParams}/>
                    </div>
                </div>
            </div>

            <div
                className={`filter-overlay-padding-animation ${propsExtension.visibleFilters || propsExtension.visibleCategories ? `filter-overlay-padding` : `cards-list-container-padding-compensate`}`}>

                {size == "spec"
                    ? renderSpec()
                    : renderGrid()}

                <div className={`max-w-[100vw]`}>
                    {(
                        categoryItems ?
                            <SearchListPagination key={`pagination`}
                                                  itemCount={categoryItems.pagination.itemCount}
                                                  itemOffset={categoryItems.pagination.itemOffset}
                                                  itemPerPage={categoryItems.pagination.itemPerPage}
                                                  urlPageParams={urlPageParams}/>
                            : null
                    )}
                </div>

            </div>
        </div>)
}