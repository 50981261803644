import UserHeader from "../../Components/User/UserHeader";
import {Link, useParams} from "react-router-dom";
import ProductBoard from "../../Components/User/ProductBoard";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCompareProductIds} from "../../Helpers/LocalStorageHelper";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";
import {triggerLoadUser} from "../../Store/Action/cache.user";
import RestrictedArea from "../../Components/App/Common/RestrictedArea";
import {MetaTags} from "../../Components/App/Tags/Meta";

const ComponentTranslationKey = "User.Page.Boards"

export default function UserRoute() {

    // global
    const dispatch = useDispatch()
    const params: Readonly<Partial<{ user_id: string }>> = useParams();
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = (key: keyof TranslationSet["User"]["Page"]["Boards"]) => {
        return t('User:Page:Boards:' + key)
    }

    // extract global state
    const propsExtension = useSelector((state: IAppState) => {
        const userId = params.user_id ? parseInt(params.user_id) : -1;
        return {
            userId: userId,
            user: userId && state.cache.users[userId] ? state.cache.users[userId].object : undefined,
            likeProductIds: state.session.user?.id == userId ? state.session.likedProducts.productIds : [],
            ownProductIds: state.session.user?.id == userId ? state.session.ownerProducts.productIds : [],
            compareProductIds: state.session.user?.id == userId ? getCompareProductIds() : [],
            sessionUserId: state.session.user?.id,
        }
    })

    // lifecycle functions
    useEffect(() => {
        const userId = propsExtension.userId;
        if (propsExtension.user === undefined && userId) {
            dispatch(triggerLoadUser(userId));
        }
    }, []);

    if (params.user_id != propsExtension.sessionUserId) {
        return <RestrictedArea/>
    }

    // render
    return <div>

        <MetaTags title={`Shopit`}
                  canonicalPageUrl={`default`}
                  robots={`noindex`}/>

        <UserHeader user={propsExtension.user}/>

        <div className="container mx-auto">
            <div className={`user-route-boards-title`}>
                <div className={`icon-private-lock`}></div>
                {getTranslation('my_private_boards')}
            </div>

            <div className={`flex gap-4`}>
                <Link to={`/user/${propsExtension.userId}/saved_products/liked`}>
                    <ProductBoard productIds={propsExtension.likeProductIds} listType="like"/>
                </Link>
                <Link to={`/user/${propsExtension.userId}/saved_products/owned`}>
                    <ProductBoard productIds={propsExtension.ownProductIds} listType="own"/>
                </Link>
                <Link to={`/user/${propsExtension.userId}/saved_products/compare`}>
                    <ProductBoard productIds={propsExtension.compareProductIds} listType="compare"/>
                </Link>
            </div>
        </div>
    </div>;
}