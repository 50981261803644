import React from "react";
import ProductCard from "../Card_Product";

type ProductSecondaryCardProps = {
    product: IProduct,
    className?: string,
};

const ProductSecondaryCard = function (prop: ProductSecondaryCardProps) {
    return <ProductCard product={prop.product}
                        debug={undefined}
                        className={prop.className}/>
};

export default ProductSecondaryCard;