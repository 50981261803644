import React, {MouseEventHandler, useContext, useState} from "react";
import {useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {getDefaultLang} from "../../../Store/Action/interactive";
import {selectSearchCache} from "../../../Store/StoreHelper";
import FilterRelatedUniqueNamePathOption from "./FilterRelatedUniqueNamePathOption";
import {SearchRouteContext} from "../../../Store/Context/SearchRouteContext";
import {formatNumber} from "../../../Helpers/StringFormatter.Number";

/** @type TranslationKeys, keep this variable without TS type, for keep type check magic works*/
const ComponentTranslationKey = "FilterStaticUniqueNamePath"

export default function FilterRelatedUniqueNamePath(props: {
    uniqueNamePath: IFilterPanel_RelatedUniqueNamePath,
    level: number,
    demonstrationMode: boolean
}) {

    // globals
    const searchContext = useContext(SearchRouteContext);
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    // internal
    const [state, setState] = useState({
        optionsExpand: false,
        optionsUnfold: !!props.uniqueNamePath?.hasChecked
    })

    const propsExtension = useSelector((state: IAppState) => {
        const searchData = selectSearchCache(state, searchContext.cacheKey);
        return {
            searchQuery: searchData && searchData.object ? searchData.object.searchQuery : undefined,
            lang: state.config.lang ? state.config.lang : getDefaultLang(),
            isDebugEnabled: state.config.debug,
        }
    })

    const expandOptions: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        setState({...state, optionsExpand: true});
    }

    const compressOptions: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        setState({...state, optionsExpand: false});
    }

    const toggleUnfoldOptions: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        setState({...state, optionsUnfold: !state.optionsUnfold});
    }

    const uniqueNamePath = props.uniqueNamePath;
    if (!uniqueNamePath) return null;

    const actualOptions = uniqueNamePath.filterValues.length <= 15 || state.optionsExpand
        ? uniqueNamePath.filterValues
        : uniqueNamePath.filterValues.filter((unp, i) => i < 15 || unp.checked)

    const showExpand = uniqueNamePath.filterValues.length > actualOptions.length && !state.optionsExpand
    const showCompress = uniqueNamePath.filterValues.length > 15 && state.optionsExpand

    const allPrimaryCount = uniqueNamePath.filterValues.reduce((sum, f) => sum + f.primaryCount, 0)
    const allCount = uniqueNamePath.filterValues.reduce((sum, f) => sum + f.count, 0)

    return (
        <div className={`filter-panel-attribute-spacing`}>

            <div className={`filter-panel-unique-name-path-name-count-pair`}>
                <div className={`filter-panel-unique-name-path-name-align`}>
                    <div className="filter-panel-unique-name-path-header level-0"
                         onClick={toggleUnfoldOptions}>
                        <div className={`filter-panel-unique-name-path-toggle`}>
                            {(
                                (state.optionsUnfold) ?
                                    <img
                                        // src={option.checked ? "/resources/arrow-down-white.png" : "/resources/arrow-down.png"}
                                        src="/resources/arrow-down.png"
                                        className="mx-auto"
                                        alt="(Expanded)"/> :
                                    <img
                                        // src={option.checked ? "/resources/arrow-right-white.png" : "/resources/arrow-right.png"}
                                        src="/resources/arrow-right.png"
                                        className="mx-auto"
                                        alt="(Do expand)"/>
                            )}
                        </div>
                        <div className={`pl-1`}>{uniqueNamePath.name}</div>
                    </div>
                </div>

                <div className={`filter-panel-primary-secondary-count-align`}>
                        <span
                            className={`filter-panel-primary-count`}>{formatNumber(allPrimaryCount, 0, 'sv', undefined, ' ')}</span>
                    {propsExtension.isDebugEnabled
                        ? <span
                            className={`filter-panel-secondary-count`}>({formatNumber(allCount - allPrimaryCount, 0, 'sv', undefined, ' ')})</span>
                        : <span className={`filter-panel-secondary-count-placeholder`}></span>}
                </div>
            </div>

            {state.optionsUnfold
                ? <div className={`filter-panel-unique-name-path-options no-top`}>
                    {actualOptions.map((unp, key) => (
                        <div key={`unique-name-path-${key}`} className={`filter-panel-unique-name-path-option`}>
                            <FilterRelatedUniqueNamePathOption item={unp}
                                                               searchQuery={propsExtension.searchQuery}
                                                               demonstrationMode={props.demonstrationMode}
                                                               intendLeft={true}
                                                               isDebugEnabled={propsExtension.isDebugEnabled}/>
                        </div>
                    ))}

                    {showExpand
                        ? <div className="filter-panel-toggle-options intend-left"
                               onClick={expandOptions}>
                            {getTranslation('show')}{' '}{uniqueNamePath.filterValues.length - actualOptions.length}{' '}{getTranslation('more_options')}
                        </div>
                        : null}
                    {showCompress
                        ? <div className="filter-panel-toggle-options intend-left"
                               onClick={compressOptions}>{getTranslation('show_less_options')}</div>
                        : null}

                </div>
                : null
            }
        </div>
    )
}
