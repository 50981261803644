import React, {ChangeEventHandler} from "react";
import {connect, DispatchProp} from "react-redux";
import {Dialog} from "@headlessui/react";
import {triggerCloseVerifyProduct} from "../../../../Store/Action/session.verifyProduct";
import {ProductImage} from "../../../App/Common/Image";
import {getCountryFlagImageUrl} from "../../../../Helpers/ImageHelper";
import {Price} from "../../../Product/Price";
import CategoryStructure from "./CategoryStructure";
import CategoryList from "./CategoryList";
import {withTranslation, WithTranslation} from "react-i18next";
import {TranslationSet} from "../../../../Localization/i18n.constants";
import {fetchMergedProduct, fetchCategorySearch, fetchProduct} from "../../../../Helpers/ApiHelper";
import {
    triggerProductVerifyCategoryDelete,
    triggerProductVerifyCategorySave
} from "../../../../Store/Action/session.verifyProduct";
import {triggerError} from "../../../../Store/Action/interactive";

type ModalVerificationProps = {
    isModalOpen: boolean
    product: IProduct
    productCategoryIdVerified: number | undefined
    latestCategoryIds: number[] | undefined
    verificationCategory: IShophunterProductData | undefined,
    regenerateOnSave: boolean,
    userSessionId: string,
    apiTail: IApiTail,
} & DispatchProp & WithTranslation;

type ModalVerificationState = {
    activeTab: number,
    searchString: string | undefined,
    isLoading: boolean,
    selectedCategoryId: number | undefined,
    verificationCategory: IShophunterProductData | undefined,
    productData: IProduct | undefined,
    engineData: IApiDataMergedProductResponse | undefined,
    searchCategories: IApiDataCategory[] | undefined
}

const ComponentTranslationKey = "CategoryVerification"

class ModalVerification extends React.Component<ModalVerificationProps, ModalVerificationState> {

    timeout: NodeJS.Timeout | undefined

    constructor(props: ModalVerificationProps) {
        super(props);

        this.state = {
            activeTab: 1,
            isLoading: false,
            selectedCategoryId: props.verificationCategory ? props.verificationCategory.verifiedCategoryId : props.product.category_id,
            verificationCategory: props.verificationCategory,
            searchString: undefined,
            productData: undefined,
            engineData: undefined,
            searchCategories: undefined
        }

        this.timeout = undefined
    }

    static getDerivedStateFromProps = function (nextProps: Readonly<ModalVerificationProps>, lastState: ModalVerificationState): Partial<ModalVerificationState> | null {
        return {
            ...lastState,
            verificationCategory: nextProps.verificationCategory,
        }
    }

    onClose() {
        this.props.dispatch(triggerCloseVerifyProduct());
    }

    getTranslation(key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return this.props.t(key)
    }

    componentDidMount() {
        this.fetchEngineData()
        this.fetchProduct()
    }

    fetchProduct() {
        if (this.state.productData === undefined) {
            fetchProduct(this.props.product.id, this.props.apiTail)
                .then((productData) => {
                    this.setState({
                        ...this.state,
                        productData: productData?.data.product
                    })
                })
                .catch((error) => {
                    this.props.dispatch(triggerError(
                        error.code,
                        error.name,
                        error.message));
                })
        }
    }

    fetchEngineData(useCache = false) {
        if (this.state.engineData === undefined) {

            this.setState({
                ...this.state,
                isLoading: true
            })
            fetchMergedProduct(this.props.product.id, useCache, this.props.apiTail)
                .then((engineData) => {
                    if (engineData) {
                        const categories = engineData.categories;
                        const {verificationCategory, selectedCategoryId} = this.state
                        this.setState({
                            ...this.state,
                            selectedCategoryId: !verificationCategory && categories && categories.length > 0
                                ? categories[0].id
                                : selectedCategoryId,
                            engineData,
                            isLoading: false
                        })
                    } else {
                        this.setState({
                            isLoading: false
                        })
                    }
                })
                .catch((error) => {
                    this.props.dispatch(triggerError(
                        error.code,
                        error.name,
                        error.message));
                })
        }
    }

    fetchSearchCategories(searchString: string) {
        clearTimeout(this.timeout)
        this.setState({
            ...this.state,
            searchString,
            isLoading: true
        })
        this.timeout = setTimeout(() => {
            fetchCategorySearch(searchString, this.props.apiTail)
                .then((data) => {
                    if (data && data.searchString == this.state.searchString) {
                        this.setState({
                            ...this.state,
                            searchCategories: data.categories,
                            isLoading: false
                        })
                    }
                })
                .catch((error) => {
                    this.props.dispatch(triggerError(
                        error.code,
                        error.name,
                        error.message));

                    this.setState({
                        ...this.state,
                        isLoading: false
                    })
                })
        }, 500)
    }

    regenerate(useCache = false) {
        this.setState({
            engineData: undefined
        }, () => this.fetchEngineData(useCache))
    }

    onSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        let searchString = typeof e.target.value != 'undefined' ? e.target.value : ''
        if (searchString.trim().length > 2 && (!this.state.searchString || (this.state.searchString && searchString.trim() != this.state.searchString.trim()))) {
            this.fetchSearchCategories(searchString)
        } else {
            this.setState({
                ...this.state,
                searchString,
                isLoading: this.state.engineData === undefined
            })
        }
    }

    getCategoryClass() {
        // TODO: here need to add engine data. look on getCategoryClass() on legacy
        const categoryClass = this.props.productCategoryIdVerified !== undefined
            ? {id: this.props.productCategoryIdVerified, className: 'verified'}
            : undefined
        return categoryClass;
    }

    renderMessage(title: string | undefined, content: JSX.Element | JSX.Element[] | string | null, image?: boolean) {
        return (
            <div key="cmv-msg" className="cvm-message">
                {image && <img className="cvm-message-img" src='/resources/working-large.png'/>}
                {title && <div className="cvm-message-title">{title}</div>}
                {content}
            </div>
        )
    }

    renderInstanceList(product: IProduct | undefined) {
        if (!product) return null;

        return product.productOffers?.map((productOffer, index) => (
            <div className={`flex`} key={`k${index}`}>
                <div className={`flex-none`}>
                    <ProductImage size={'xs'} alt={productOffer.productTitle} imageId={productOffer.productMainImageId || 0}/>
                </div>
                <div className={`flex-1 pl-1`}>
                    <span className={`font-bold`}>{productOffer.websiteStore.name}</span>
                    {productOffer.marketplaceMerchant
                        ? <span className={`text-gray-400`}>
                                {' / '}{productOffer.marketplaceMerchant.name}
                            </span>
                        : null}
                    <div className="text-gray-700">{productOffer.productTitle}</div>
                    <div className="text-gray-700">
                        {productOffer.priceInclVat
                            ? <Price price={productOffer.priceInclVat}
                                     currency={productOffer.currency}/>
                            : null}
                    </div>
                </div>
            </div>))
    }

    renderProductPart(product: IProduct | undefined) {
        if (!product) return null;

        return <div className={`modal-verification-image-description-block`}>
            <div className={`modal-verification-image-description-columns`}>

                <div className={`modal-verification-image-main-column`}>
                    <div className={`modal-verification-image-main-column-content`}>
                        <ProductImage imageId={product.image_id} alt={product.name} size={'xl'}/>
                    </div>
                </div>

                <div className={`modal-verification-image-secondary-column`}>
                    <div className={`modal-verification-image-secondary-column-content`}>
                        {product.image_ids.map((i, index) =>
                            <div
                                className={(index > 0 ? `mt-1 ` : ``) + `product-image-description-images-secondary-wrapper cursor-pointer`}
                                key={'detailed-' + index}
                                onClick={() => {
                                    // this.openImagePreview(i)
                                }}>
                                <ProductImage imageId={i} alt={product.name} size={'xs'}/>
                            </div>)}
                    </div>
                </div>

                <div className={`modal-verification-instance-column`}>
                    <div className={`modal-verification-instance-wrapper`}>
                        {this.renderInstanceList(product)}
                    </div>
                </div>
            </div>
        </div>
    }

    _convertCategoryStructure = (categoryResponse: IApiDataCategory[]): CategoryStructure_Category[] => {
        if (categoryResponse == undefined) {
            return []
        }
        return categoryResponse.map(c => ({
            categoryId: c.id,
            name: c.name,
            isOpen: c.isOpened,
            isCollection: c.isCollection,
            imageIds: c.imageIds,
            path: this._convertCategoryStructure(c.path),
            children: this._convertCategoryStructure(c.categories)
        }));
    }

    renderSearchCategories() {
        let html: JSX.Element[] = []
        const searchString = this.state.searchString;
        const isLoading = this.state.isLoading;
        const engineData = this.state.engineData;
        const searchCategories = this.state.searchCategories;
        const verificationCategory = this.state.verificationCategory;

        if (verificationCategory) {
            const verifiedCategoryId = verificationCategory.verifiedCategoryId;
            if (verifiedCategoryId) {
                html.push(<CategoryList key="vc"
                                        categories={undefined}
                                        categoryIds={[verifiedCategoryId]}
                                        onClick={this.onCategoryClick.bind(this)}
                                        categoryClass={this.getCategoryClass()}
                                        imageSize="s"
                                        imageCount={4}/>)
            }
        }

        if (searchString && searchString.length > 2) {
            if (isLoading) {
                html.push(
                    this.renderMessage(undefined, <div><img src="/resources/spinner.gif" className={`mx-auto`}
                                                            width="32px" height="32px"/>
                    </div>))
            } else if (searchCategories && searchCategories.length > 0) {
                const categories = verificationCategory
                    ? searchCategories.filter(category => category.id != verificationCategory.verifiedCategoryId)
                    : searchCategories
                //sort categories according to engine score
                if (engineData && engineData.categories && engineData.categories.length > 0) {
                    categories.forEach(searchCategory => {
                        if (engineData.categories) {
                            const engineCategory = engineData.categories.find(engineCategory => searchCategory.id == engineCategory.id)
                            if (engineCategory) {
                                searchCategory.score = engineCategory.score
                            }
                        }
                    });
                    categories.sort((a, b) => b.score - a.score)
                }
                html.push(<CategoryList categories={this._convertCategoryStructure(categories)}
                                        categoryIds={undefined}
                                        onClick={this.onCategoryClick.bind(this)}
                                        categoryClass={this.getCategoryClass()}
                                        imageSize="s"
                                        imageCount={4}/>)
            } else {
                html.push(this.renderMessage(undefined, this.getTranslation('no_categories_were_found')))
            }
        } else {
            if (isLoading) {
                html.push(
                    this.renderMessage(
                        undefined,
                        <div>
                            <img src="/resources/spinner.gif" className={`mx-auto`} width="32px" height="32px"/>
                            <br/>{this.getTranslation('search_for_category_suggestion')}
                        </div>
                    )
                )
            } else if (engineData != null && engineData.categories && engineData.categories.length > 0) {
                const categories = verificationCategory
                    ? engineData.categories.filter((category) => category.id != verificationCategory.verifiedCategoryId)
                    : engineData.categories
                html.push(<CategoryList key="ec"
                                        categories={this._convertCategoryStructure(categories)}
                                        categoryIds={undefined}
                                        onClick={this.onCategoryClick.bind(this)}
                                        categoryClass={this.getCategoryClass()}
                                        imageSize="s"
                                        imageCount={4}/>)
            } else if (engineData != null && engineData.statusUse != 'USE') {
                html.push(this.renderMessage(
                    engineData.statusUse == 'ARCHIVED'
                        ? this.getTranslation('product_archived')
                        : engineData.statusUse == 'DUPLICATE'
                            ? this.getTranslation('product_duplicate')
                            : this.getTranslation('product_not_found'),
                    null
                ))
            } else {
                html.push(this.renderMessage(
                    this.getTranslation('we_are_sorry'),
                    [
                        <span key="span0">{this.getTranslation('nothing_to_suggest')}</span>,
                        <br key="br0"/>,
                        <br key="br1"/>,
                        <button key="button0" className="btn-grey"
                                onClick={() => this.fetchEngineData()}>{this.getTranslation('try_again')}</button>
                    ],
                    true
                ))
            }
        }
        return html
    }

    renderLatestCategories() {
        if (this.props.latestCategoryIds) {
            return <CategoryList categories={undefined}
                                 categoryIds={this.props.latestCategoryIds}
                                 onClick={this.onCategoryClick.bind(this)}
                                 categoryClass={this.getCategoryClass()}
                                 imageSize="s"
                                 imageCount={4}/>
        }
        return this.renderMessage(undefined, this.getTranslation('no_latest_categories'))
    }

    renderCategoryPart(product: IProduct | undefined) {

        const category = this.state.engineData?.mergedProduct?.category;
        const forcedCategory = this.state.engineData?.mergedProduct?.forcedCategory;
        const noCategoryMatch = this.state.engineData?.noCategoryMatch;

        return (
            <div className={`modal-verification-categories-block`}>

                <div className={`flex justify-between`}>
                    <div>
                        <span className={`modal-verification-tab ${this.state.activeTab == 1 ? `active` : ``}`}
                              onClick={() => {
                                  this.setState({activeTab: 1})
                              }}>Suggestions / Search</span>
                        <span className={`modal-verification-tab ${this.state.activeTab == 2 ? `active` : ``}`}
                              onClick={() => {
                                  this.setState({activeTab: 2})
                              }}>Last Selected</span>
                    </div>
                    <div>
                        {category
                            ? <div
                                className={`product-category ${forcedCategory ? forcedCategory.id == category.id ? 'correct' : 'incorrect' : ''} ${noCategoryMatch ? 'no-match' : ''}`}
                                onClick={() => {
                                    this.onCategoryClick({
                                        categoryId: category?.id,
                                        isCollection: category?.isCollection
                                    })
                                }}>
                                {category.name}
                            </div>
                            : null}
                        {forcedCategory && (!category || category.id != forcedCategory.id)
                            ? <div
                                className={`product-category correct`}
                                onClick={() => {
                                    this.onCategoryClick({
                                        categoryId: forcedCategory?.id,
                                        isCollection: forcedCategory?.isCollection
                                    })
                                }}>
                                {forcedCategory.name}
                            </div>
                            : null}
                        {this.state.verificationCategory
                            ? <div className={`product-category`}
                                   onClick={() => {
                                       this.onCategoryRemoveClick()
                                   }}>Remove Verification</div>
                            : null}
                        <div className={`product-category`}
                             onClick={() => {
                                 if (!this.state.isLoading) {
                                     this.regenerate()
                                 }
                             }}>Regenerate
                        </div>
                    </div>
                </div>

                <div className={`p-2 bg-white border rounded-md mt-1 flex-1 flex flex-col`}>
                    {this.state.activeTab == 1
                        ? <div><input className={`w-full p-1 border outline-none focus:border-blue-300`}
                                      placeholder={`Search Category`}
                                      value={this.state.searchString}
                                      onChange={this.onSearchChange.bind(this)}/></div>
                        : null}

                    <div className={`modal-verification-categories-columns flex-1`}>
                        <div className={`modal-verification-categories-structure-column`}>
                            <CategoryStructure
                                selectedCategoryId={this.props.productCategoryIdVerified ? this.props.productCategoryIdVerified : this.props.product.category_id}
                                onClick={this.onCategoryClick.bind(this)}
                                categoryClass={this.getCategoryClass()}/>
                        </div>
                        <div className={`modal-verification-categories-list-column`}>
                            {this.state.activeTab == 1
                                ? this.renderSearchCategories()
                                : null}
                            {this.state.activeTab == 2
                                ? this.renderLatestCategories()
                                : null}
                        </div>
                    </div>
                </div>
            </div>)
    }

    onCategoryClick(category: { categoryId: number, isCollection: boolean }) {
        const {selectedCategoryId} = this.state
        if (!category.isCollection) {
            const productId = this.props.product.id;
            const oldCategoryId = this.props.product.category_id;
            const regenerateOnSave = this.props.regenerateOnSave;

            this.setState(
                {
                    ...this.state,
                    verificationCategory: {
                        verifiedCategoryId: category.categoryId,
                        productId: productId,
                        verifiedCategoryNoMatch: false,
                        verifiedCategoryNotRegenerated: false
                    },
                    selectedCategoryId: category.categoryId
                },
                () => {
                    this.props.dispatch(triggerProductVerifyCategorySave(this.props.userSessionId, productId, category.categoryId));
                    if (regenerateOnSave) {
                        this.regenerate()
                    }
                }
            )
            // this.scrollTopCategoryLists()
        } else if (category.isCollection && selectedCategoryId != category.categoryId) {
            this.setState(
                {
                    ...this.state,
                    selectedCategoryId: category.categoryId
                }
            )
        }
    }

    onCategoryRemoveClick() {
        const productId = this.props.product.id;
        const categoryId = this.props.verificationCategory?.verifiedCategoryId;

        if (categoryId) {
            this.props.dispatch(triggerProductVerifyCategoryDelete(this.props.userSessionId, productId, categoryId));
        }
    }

    render() {
        const product = this.state.productData ? this.state.productData : this.props.product;

        return <Dialog as="div" className="relative z-50"
                       open={this.props.isModalOpen}
                       onClose={this.onClose.bind(this)}>
            <div className="modal-shade-overlay"/>
            <div className="modal-wrapper-space">
                <div className="modal-wrapper-vertical-align">
                    <div className={`modal-verification-wrapper`}>

                        <Dialog.Panel className="modal-title-content-wrapper">

                            <div className="modal-title modal-title-orange">
                                {this.getTranslation('title')}
                                <div className={`modal-title-closer`} onClick={this.onClose.bind(this)}>
                                    <img src={`/resources/icon-remove-white.png`}/>
                                </div>
                            </div>

                            <div className={`modal-content modal-verification-content`}>
                                <div className={`max-w-screen-lg h-[690px] flex flex-col`}>

                                    {this.renderProductPart(product)}
                                    {this.renderCategoryPart(product)}

                                </div>
                            </div>
                        </Dialog.Panel>
                    </div>
                </div>
            </div>
        </Dialog>
    }
}

const mapStateToProps = function (state: IAppState, props: {
    isModalOpen: boolean
    product: IProduct
    productCategoryIdVerified: number | undefined
    latestCategoryIds: number[] | undefined,
}): ModalVerificationProps {
    return {
        isModalOpen: props.isModalOpen,
        product: props.product,
        productCategoryIdVerified: props.productCategoryIdVerified,
        latestCategoryIds: props.latestCategoryIds,
        verificationCategory: state.session.shophunter.shophunterData?.verifiedProducts[props.product.id],
        regenerateOnSave: state.session.user && state.session.user.isTrusted,
        userSessionId: state.session.userSessionId,
        apiTail: {
            _logPageToken: state.session.tracking.pageToken || null
        }
    } as ModalVerificationProps
}

export default connect(mapStateToProps)(withTranslation(ComponentTranslationKey)(ModalVerification));
