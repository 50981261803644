import React, {useContext} from "react";
import {useSelector} from 'react-redux';
import {
    convertSearchParamsToUrlResolveParams,
    toggleStaticNumericFilter, toggleStaticPriceFilter
} from "../../../Helpers/SearchHelper";
import {selectSearchCache} from "../../../Store/StoreHelper";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import SearchRouteParamLink from "../../App/Links/SearchRouteParamLink";
import {SearchRouteContext} from "../../../Store/Context/SearchRouteContext";
import {formatNumberCustomDecimals} from "../../../Helpers/StringFormatter.Number";

export default function FilterBarNumericButton(props: {
    filterParam: IProductListSearch_PriceFilter
        | IProductListSearch_DimensionNumericFilter_Unused
        | IProductListSearch_FixedNumericFilter_Unused
        | IProductListSearch_NumericFilter
}) {

    const searchContext = useContext(SearchRouteContext);

    const {t} = useTranslation("TopBar");
    const getTranslation = function (key: keyof TranslationSet["TopBar"]) {
        return t(key);
    }

    const searchData = useSelector((state: IAppState) => selectSearchCache(state, searchContext.cacheKey));

    const searchQuery = searchData && searchData.object ? searchData.object.searchQuery : undefined
    const filterParam = props.filterParam;

    const currentFilters: IProductListSearch_Filter[] = searchQuery && searchQuery.filter ? [...searchQuery.filter] : [];

    if (filterParam._type === IProductListSearch_FilterType.NumericFixed ||
        filterParam._type === IProductListSearch_FilterType.NumericRange ||
        filterParam._type === IProductListSearch_FilterType.NumericRatio ||
        filterParam._type == IProductListSearch_FilterType.NumericDimension)
        throw "Type Mismatch"

    const optionFilters = filterParam._type === IProductListSearch_FilterType.Numeric
        ? toggleStaticNumericFilter(currentFilters, filterParam._type, filterParam.id, undefined, undefined)
        : toggleStaticPriceFilter(currentFilters, filterParam._type, undefined, undefined)

    const name = filterParam.visualDetails ? filterParam.visualDetails.name : filterParam.filter_var;
    const low = ("low" in filterParam) && filterParam.low !== undefined
        ? formatNumberCustomDecimals(filterParam.low, 'sv', undefined, ' ')
        : '...';
    const high = ("high" in filterParam) && filterParam.high !== undefined
        ? formatNumberCustomDecimals(filterParam.high, 'sv', undefined, ' ')
        : '...';
    const unit = '';

    return <SearchRouteParamLink
        className="btn btn-active-filter ml-1"
        title={getTranslation("hint_remove_filter")}
        urlPageParams={convertSearchParamsToUrlResolveParams({
            ...searchQuery,
            filter: optionFilters
        })}
    >
        {name} {low} - {high} {unit}
        <img src="/resources/icon-remove-white.png" className="inline-block" alt="Remove Filter"/>
    </SearchRouteParamLink>
}
