export const REQUEST_STORES = "REQUEST_STORES";
export const RESPONSE_STORES_SUCCESS = "RESPONSE_STORES_SUCCESS";
export const RESPONSE_STORES_FAILED = "RESPONSE_STORES_FAILED";

// ---

export type RequestStoresAction = {
    type: string
}

export const triggerRequestStores = (): RequestStoresAction => ({
    type: REQUEST_STORES
})

export type ResponseStoresAction = {
    type: string,
    stores: IStoresStore[] | undefined
}

export const triggerResponseStoresSuccess = (stores: IStoresStore[] | undefined): ResponseStoresAction => ({
    type: RESPONSE_STORES_SUCCESS,
    stores: stores
})

export const triggerResponseStoresFailed = (): ResponseStoresAction => ({
    type: RESPONSE_STORES_FAILED,
    stores: undefined
})