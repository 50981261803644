import React from "react";
import {formatNumberCustomDecimals} from "../../../Helpers/StringFormatter.Number";

export function Filters(props: { filters: IProductListSearch_Filter[] }) {

    return <span className={`item-filters`}>
        {props.filters.map((i: IProductListSearch_Filter, index) => {
            switch (i._type) {
                case IProductListSearch_FilterType.Manufacturer: {
                    const manufacturerItem = i as IProductListSearch_BrandOrFamilyFilter
                    return <span className={`manufacturer`} key={index}>
                        {manufacturerItem.data.map((d) => {
                                const manufacturerId = d.id.toString()
                                return manufacturerItem.visualDetails.dataName && manufacturerItem.visualDetails.dataName[manufacturerId]
                                    ? manufacturerItem.visualDetails.dataName[manufacturerId]
                                    : ('#' + manufacturerId)
                            }
                        )}
                    </span>
                }
                case IProductListSearch_FilterType.Brand: {
                    const brandItem = i as IProductListSearch_BrandOrFamilyFilter
                    return <span className={`brand`} key={index}>
                        {brandItem.data.map((d) => {
                                const brandId = d.id.toString()
                                return brandItem.visualDetails.dataName && brandItem.visualDetails.dataName[brandId]
                                    ? brandItem.visualDetails.dataName[brandId]
                                    : ('#' + brandId)
                            }
                        )}
                    </span>
                }
                case IProductListSearch_FilterType.Family: {
                    const familyItem = i as IProductListSearch_BrandOrFamilyFilter
                    return <span className={`family`} key={index}>
                        {familyItem.data.map((d) => {
                                const brandId = d.id.toString()
                                return familyItem.visualDetails.dataName && familyItem.visualDetails.dataName[brandId]
                                    ? familyItem.visualDetails.dataName[brandId]
                                    : ('#' + brandId)
                            }
                        )}
                    </span>
                }
                case IProductListSearch_FilterType.Text: {
                    const textItem = i as IProductListSearch_TextFilter
                    return <span className={`text`} key={index}>
                        {textItem.data.map((d) => d.id)}
                    </span>
                }
                case IProductListSearch_FilterType.Code: {
                    const textItem = i as IProductListSearch_TextFilter
                    return <span className={`code`} key={index}>
                        {textItem.data.map((d) => d.id)}
                    </span>
                }
                case IProductListSearch_FilterType.RelatedUniqueNamePath: {
                    const uniqueNamePathItem = i as IProductListSearch_RelatedUniqueNamePathFilter
                    return <span className={`unique-name-path`} key={index}>
                        {uniqueNamePathItem.data.map((d) => {
                                const uniqueNamePathId = d.id.toString()
                                return uniqueNamePathItem.visualDetails.dataName && uniqueNamePathItem.visualDetails.dataName[uniqueNamePathId]
                                    ? uniqueNamePathItem.visualDetails.dataName[uniqueNamePathId]
                                    : ('#' + uniqueNamePathId)
                            }
                        )}
                    </span>
                }
                case IProductListSearch_FilterType.UniqueNamePath: {
                    const uniqueNamePathItem = i as IProductListSearch_UniqueNamePathFilter
                    return <span className={`unique-name-path`} key={index}>
                        {uniqueNamePathItem.data.map((d) => {
                                const uniqueNamePathId = d.id.toString()
                                return uniqueNamePathItem.visualDetails.dataName && uniqueNamePathItem.visualDetails.dataName[uniqueNamePathId]
                                    ? uniqueNamePathItem.visualDetails.dataName[uniqueNamePathId]
                                    : ('#' + uniqueNamePathId)
                            }
                        )}
                    </span>
                }

                case IProductListSearch_FilterType.Attribute: {
                    const attributeItem = i as IProductListSearch_AttributeFilter
                    return <span className={`attribute`} key={index}>
                        {attributeItem.data.map((d, index) => {
                                const attributeId = d.id ? d.id.toString() : "-1";
                                return (index > 0 ? ', ' : '')
                                    + (attributeItem.visualDetails.dataName && attributeItem.visualDetails.dataName[attributeId]
                                        ? attributeItem.visualDetails.dataName[attributeId]
                                        : ('#' + attributeId))
                            }
                        )}
                    </span>
                }

                case IProductListSearch_FilterType.Numeric: {
                    const numericItem = i as IProductListSearch_NumericFilter
                    const name = numericItem.visualDetails ? numericItem.visualDetails.name : numericItem.filter_var;
                    const low = ("low" in numericItem) && numericItem.low !== undefined
                        ? formatNumberCustomDecimals(numericItem.low, 'sv', undefined, ' ')
                        : '...';
                    const high = ("high" in numericItem) && numericItem.high !== undefined
                        ? formatNumberCustomDecimals(numericItem.high, 'sv', undefined, ' ')
                        : '...';
                    return <span className={`numeric`} key={index}>
                        {name} {low} - {high}
                    </span>
                }

                case IProductListSearch_FilterType.Price: {
                    const numericItem = i as IProductListSearch_PriceFilter
                    const name = numericItem.visualDetails ? numericItem.visualDetails.name : numericItem.filter_var;
                    const low = ("low" in numericItem) && numericItem.low !== undefined
                        ? formatNumberCustomDecimals(numericItem.low, 'sv', undefined, ' ')
                        : '...';
                    const high = ("high" in numericItem) && numericItem.high !== undefined
                        ? formatNumberCustomDecimals(numericItem.high, 'sv', undefined, ' ')
                        : '...';
                    return <span className={`numeric`} key={index}>
                        {name} {low} - {high}
                    </span>
                }

                case IProductListSearch_FilterType.Color: {
                    const colorItem = i as IProductListSearch_ColorFilter

                    const hl: number = colorItem.hl !== undefined ? colorItem.hl : -40;
                    const hh: number = colorItem.hh !== undefined ? colorItem.hh : 320;
                    const sl: number = colorItem.sl !== undefined ? colorItem.sl : 0;
                    const sh: number = colorItem.sh !== undefined ? colorItem.sh : 100;
                    const ll: number = colorItem.ll !== undefined ? colorItem.ll : 0;
                    const lh: number = colorItem.lh !== undefined ? colorItem.lh : 100;

                    const h = ((hh - hl) / 2) + hl
                    const s = ((sh - sl) / 2) + sl
                    const l = ((lh - ll) / 2) + ll

                    return <span className="filter-color-trigger"
                                 style={h != 140 || s != 50 || l != 50 ? {background: `hsl(${h}, ${s}%, ${l}%)`} : {}}></span>
                }

                case IProductListSearch_FilterType.Synset: {
                    const synsetItem = i as IProductListSearch_SynsetFilter
                    return <span className={`synset`} key={index}>
                        {synsetItem.data.map((d) => {
                                const synsetId = d.id ? d.id.toString() : "-1";
                                return synsetItem.visualDetails.dataName && synsetItem.visualDetails.dataName[synsetId]
                                    ? synsetItem.visualDetails.dataName[synsetId]
                                    : ('#' + synsetId)
                            }
                        )}
                    </span>
                }

                default:
                    return <span key={index}>
                        [Unknown filter: {i._type}]
                    </span>
            }
        })}
    </span>
}