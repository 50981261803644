import React from "react";
import {useTranslation} from "react-i18next";
import SearchRouteParamLink from "../../App/Links/SearchRouteParamLink";
import {useSelector} from "react-redux";
import {getDefaultLang} from "../../../Store/Action/interactive";

/** @see CategoryPathDemo */
export function CategoryPath(props: { category: ICategory, uncategorized: boolean | undefined }) {

    const lang = useSelector((state: IAppState) => state.config.lang ? state.config.lang : getDefaultLang())
    const {t} = useTranslation("Product");

    return <>
        <img src="/resources/icon-category-green.png" alt="Category path" key={`i`}
             className={`inline-block mr-2 ${props.uncategorized ? `grayscale` : ``}`}/>
        {
            props.uncategorized
                ? <span className="text-gray-400" key={`uc`}>{t('Product:CategoryPathBar:uncategorized')}</span>
                : props.category ? (
                    props.category.path.map((item, index) =>
                        <React.Fragment key={item.id}>
                            {(index > 0 ? <span className="text-shopit-green text-xl font-bold"> » </span> : '')}
                            <SearchRouteParamLink
                                className="text-shopit-green text-xl font-bold"
                                urlPageParams={{
                                    bridgeParams: {
                                        categoryId: item.id,
                                        language: undefined,
                                        color: undefined,
                                        filters: undefined,
                                        expand: undefined,
                                        expandedBrands: undefined,
                                        secondaryExpand: undefined,
                                        showSecondary: undefined,
                                        fold: undefined,
                                        foldedBrands: undefined,
                                        page: undefined,
                                        size: undefined,
                                        text: undefined,
                                        code: undefined,
                                        sort: undefined,
                                        group: undefined,
                                    },
                                    showFilters: undefined,
                                    openFilterOption: [],
                                    openUnpOptions: [],
                                }}
                            >
                                {item.name}
                            </SearchRouteParamLink>
                        </React.Fragment>
                    )
                ) : null
        }

    </>
}