export const SESSION_USER_LOGIN_SHOPIT = "USER_LOGIN_SHOPIT"
export const SESSION_USER_LOGIN_RECOVER = "USER_LOGIN_RECOVER"
export const SESSION_USER_LOGIN_RECOVER_CHECK = "USER_LOGIN_RECOVER_CHECK"
export const SESSION_USER_LOGIN_RECOVER_ACCEPT = "USER_LOGIN_RECOVER_ACCEPT"
export const SESSION_USER_LOGIN_RECOVER_DECLINE = "USER_LOGIN_RECOVER_DECLINE"
export const SESSION_USER_REGISTER = "SESSION_USER_REGISTER"
export const SESSION_USER_REGISTER_CONFIRM = "USER_REGISTER_CONFIRM"

export const SESSION_USER_LOGOUT = "USER_LOGOUT";

export const SESSION_RESPONSE_USER_LOGIN = "RESPONSE_LOGIN"
export const SESSION_RESPONSE_USER_LOGOUT = "RESPONSE_LOGOUT"

export const SESSION_SET_USER_SETTINGS = "SET_USER_SETTINGS";

export const SESSION_CONTACT = "CONTACT";

// ---

export type UserLoginShopitAction = {
    type: string,
    email: string,
    password: string,
    messageCallback: IApiMessageHandler
}

export const triggerUserLoginShopit = (email: string, password: string, messageCallback: IApiMessageHandler): UserLoginShopitAction => ({
    type: SESSION_USER_LOGIN_SHOPIT,
    email: email,
    password: password,
    messageCallback: messageCallback
})

// ----

export type ResponseLoginAction = {
    type: string,
    userSession: string,
    user: IUser
}

export const triggerResponseUserLogin = (userSession: string, user: IUser): ResponseLoginAction => ({
    type: SESSION_RESPONSE_USER_LOGIN,
    userSession: userSession,
    user: user
})

// ----

export type UserLogoutAction = {
    type: string,
}

export const triggerUserLogout = (): UserLogoutAction => ({
    type: SESSION_USER_LOGOUT,
})

export const triggerUserLogoutResponse = (): UserLogoutAction => ({
    type: SESSION_RESPONSE_USER_LOGOUT,
})

// ----

export type UserLoginRecoverAction = {
    type: string,
    email: string
}

export const triggerUserLoginRecover = (email: string): UserLoginRecoverAction => ({
    type: SESSION_USER_LOGIN_RECOVER,
    email: email,
})

// ----

export type RegistrationConfirmAction = {
    type: string,
    userId: number,
    code: string
}

export const triggerUserLoginRecoverCheck = (userId: number, code: string): RegistrationConfirmAction => ({
    type: SESSION_USER_LOGIN_RECOVER_CHECK,
    userId: userId,
    code: code
})

export const triggerUserLoginRecoverDecline = (userId: number, code: string): RegistrationConfirmAction => ({
    type: SESSION_USER_LOGIN_RECOVER_DECLINE,
    userId: userId,
    code: code
})

export const triggerUserRegisterConfirm = (userId: number, code: string): RegistrationConfirmAction => ({
    type: SESSION_USER_REGISTER_CONFIRM,
    userId: userId,
    code: code
})

// ----

export type RegisterAction = {
    type: string,
    firstName: string,
    lastName: string,
    email: string,
    email2: string,
    password: string,
    messageCallback: IApiMessageHandler
}


export const triggerUserRegister = (firstName: string, lastName: string, email: string, email2: string, password: string, messageCallback: IApiMessageHandler): RegisterAction => ({
    type: SESSION_USER_REGISTER,
    firstName: firstName,
    lastName: lastName,
    email: email,
    email2: email2,
    password: password,
    messageCallback: messageCallback
})

// ----

export type RestoreApplyAction = {
    type: string,
    userId: number,
    code: string,
    password1: string,
    password2: string,
}

export const triggerUserLoginRecoverAccept = (userId: number, code: string, password: string, passwordRepeat: string): RestoreApplyAction => ({
    type: SESSION_USER_LOGIN_RECOVER_ACCEPT,
    userId: userId,
    code: code,
    password1: password,
    password2: passwordRepeat,
})

export type UserSettingsAction = {
    type: string,
    params: IUserSettingsParams
}

export const triggerUserSettings = (params: IUserSettingsParams): UserSettingsAction => ({
    type: SESSION_SET_USER_SETTINGS,
    params
});

// ----

export type ContactAction = {
    type: string,
    name: string,
    email: string,
    message: string,
}

export const triggerContact = (name: string, email: string, message: string): ContactAction => ({
    type: SESSION_CONTACT,
    name: name,
    email: email,
    message: message
});