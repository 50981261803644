import React, {EventHandler, MouseEvent, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {triggerLoadSaytFirstUrl} from "../../Store/Action/sayt";
import {getDefaultLang} from "../../Store/Action/interactive";
import {getPageParamsFromSearchUrl} from "../../Helpers/SearchHelper";
import {triggerCacheAccessSearchWithCallback} from "../../Store/Action/cache.search";
import {useNavigate} from "react-router-dom";
import {fetchTrendsData} from "../../Helpers/ApiHelper";
import {staticCast} from "../../Helpers/TypeHelper";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";

const ComponentTranslationKey = "Meta"

export default function PopularWords() {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const {t} = useTranslation(ComponentTranslationKey)

    const getTranslation = (key: keyof TranslationSet[typeof ComponentTranslationKey]) => {
        return t(key);
    }

    const propsExtend = useSelector((state: IAppState) => ({
        lang: state.config.lang ? state.config.lang : getDefaultLang(),
        isDebugEnabled: state.config.debug,
        apiTail: staticCast<IApiTail>({_logPageToken: state.session.tracking.pageToken || null})
    }))

    const [trendWords, setTrendWords] = useState<string[] | undefined>(undefined);
    useEffect(() => {
        fetchTrendsData(propsExtend.apiTail).then((trends) => {
            setTrendWords(trends)
        })
    }, [])

    if (!trendWords)
        return null;

    const separatorWord = Math.floor(trendWords.length * 6 / 10);
    const lineWords = [
        trendWords.slice(0, separatorWord),
        trendWords.slice(separatorWord),
    ]

    const onClick: EventHandler<MouseEvent<HTMLDivElement>> = (etv: MouseEvent) => {
        const target = etv.currentTarget
        const searchAttribute = target.attributes.getNamedItem('data-search')
        if (searchAttribute) {
            const searchRequest = searchAttribute.value

            dispatch(triggerLoadSaytFirstUrl(searchRequest, propsExtend.lang, propsExtend.isDebugEnabled, (href) => {

                if (!href) return

                const urlSeparator = href.indexOf('?');
                let [pathname, search] = urlSeparator !== -1 ? [href.substring(0, urlSeparator), href.substring(urlSeparator)] : [href, undefined]

                const urlPageParams = getPageParamsFromSearchUrl(href);
                dispatch(triggerCacheAccessSearchWithCallback(urlPageParams, false, propsExtend.isDebugEnabled, () => {
                    window.dispatchEvent(new CustomEvent<string>('shopit-sayt-sync', {detail: searchRequest}))
                    navigate({
                        pathname: pathname,
                        search: search
                    })
                }))
            }))
        }
    }

    return <div className={`max-w-6xl mx-auto`} style={{maxWidth: '100vw'}}>
        <h2 className={`text-center text-gray-500 text-lg pb-4`}>{getTranslation('trendingSearches')}</h2>
        {lineWords.map((wordSet, index) => (
            <div className={`flex flex-wrap justify-center gap-[14px] mb-[14px]`} key={index}>
                {wordSet.map((word, index) => <div className={`bg-gray-100 rounded-full`} key={index}>
                    <div className={`text-xl text-gray-500 font-light mt-1 px-4 pt-[4px] pb-[7px] cursor-pointer`}
                         data-search={word} onClick={onClick}>
                        {word}
                    </div>
                </div>)}
            </div>))
        }
    </div>
}