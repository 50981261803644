import React, {MouseEventHandler, useState} from "react";
import {LinkProps, To, useHref, useLinkClickHandler} from "react-router-dom";
import {getDefaultLang} from "../../../Store/Action/interactive";
import {useSelector} from "react-redux";

const LocaleLink = React.forwardRef<HTMLAnchorElement, LinkProps & {
    toLocale: { [locale: string]: To }
}>((props, ref) => {

    // extract <Link/> component props, for pass rest to <a/> tag
    const {
        replace,
        state,
        target,
        to,
        className,
        toLocale,
        ...rest
    } = props;

    const lang = useSelector((state: IAppState) => state.config.lang || getDefaultLang());
    const targetTo = toLocale[lang] ? toLocale[lang] : to;

    const href = useHref(targetTo);
    const navigate = useLinkClickHandler(targetTo, {
        replace,
        state,
        target,
    });

    const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault();
        navigate(e);
    }

    return (
        <a href={href}
           ref={ref}
           className={className + ` cursor-pointer`}
           {...rest}
           onClick={handleClick}>{props.children}</a>
    )
})

export default LocaleLink;