import React from "react";
import {connect, DispatchProp, useSelector} from "react-redux";

import {getDefaultLang} from "../../../Store/Action/interactive";
import {
    DelayApiCallMessageDemo,
    NotificationPanelDemo,
    PlaceholderTextDemo,
    SpinLoadingIndicatorDemo,
    ProductImageXsDemo,
    ProductImageSDemo,
    ProductImageMDemo,
    ProductImageLDemo
} from "../../../Components/Typography/Demostration/Demo.App.Common";
import {FooterDemo} from "../../../Components/Typography/Demostration/Demo.App.Layout";
import {
    CategoryPathDemo,
    ProductCardListHeaderDemo
} from "../../../Components/Typography/Demostration/Demo.ProductList.Common";
import {
    BrandingCardDemo,
    BrandingSeparatorDemo,
    CategoryCardDemo,
    CollectionCardDemo,
    FamilyChainSeparatorDemo,
    FoldedBrandingCardDemo,
    FoldedBrandingSeparatorDemo,
    FoldedManufacturerCardDemo,
    FoldedManufacturerSeparatorDemo,
    ManufacturerCardDemo, ManufacturerSeparatorDemo,
    PlaceholderCardDemo,
    PlaceholderCardWrapperDemo,
    ProductCardDemo,
    ShowMorePrimaryCardDemo,
    ShowMoreSecondaryCardDemo,
    ShowSecondaryCardDemo, TopLevelSeparatorDemo,
    UnknownCardDemo
} from "../../../Components/Typography/Demostration/Demo.ProductList.Card";
import {
    DemonstrationGroup,
    DemonstrationGroupFullscreen,
    DemonstrationMenu
} from "../../../Components/Typography/Demostration/Demo.DemonstrationGroup";
import {
    FilterAttributeDemo,
    FilterAttributeGroupDemo, FilterAttributeSectionDemo,
    FilterBrandingDemo, FilterColorDemo, FilterManufacturerDemo, FilterNumericDemo, FilterStaticDemo
} from "../../../Components/Typography/Demostration/Demo.ProductList.FilterPanel";
import {
    ProductDescriptionDemo,
    ProductSpecificationsDemo,
    ProductTitleDemo,
    ProductVendorsDemo
} from "../../../Components/Typography/Demostration/Demo.Product";
import {SearchInputDemo} from "../../../Components/Typography/Demostration/Demo.SearchInput";
import {MetaTags} from "../../../Components/App/Tags/Meta";

// ---

export default function Layout(props: {}) {

    const propsExtend = useSelector((state: IAppState) => ({
        lang: state.config.lang ? state.config.lang : getDefaultLang(),
        isDebugEnabled: state.config.debug,
    }))

    return (
        <div>

            <MetaTags title={`Shopit`}
                      canonicalPageUrl={`default`}
                      robots={`noindex`}/>

            <DemonstrationMenu/>
            <DemonstrationGroup header={`Buttons`}>
                <div className={`overflow-y-scroll h-96`}>
                    <table className={`example-table`}>
                        <tr className={`header-row`}>
                            <td className={`w-48`}>Class</td>
                            <td>Usage</td>
                        </tr>

                        <tr>
                            <td>btn btn-green</td>
                            <td>
                                <div className={`btn btn-green`}>Button</div>
                            </td>
                        </tr>

                        <tr>
                            <td>btn btn-red</td>
                            <td>
                                <div className={`btn btn-red`}>Button</div>
                            </td>
                        </tr>

                        <tr>
                            <td>btn btn-grey</td>
                            <td>
                                <div className={`btn btn-grey`}>Button</div>
                            </td>
                        </tr>

                        <tr>
                            <td>btn btn-dark-grey</td>
                            <td>
                                <div className={`btn btn-dark-grey`}>Button</div>
                            </td>
                        </tr>

                        <tr>
                            <td>btn btn-blue</td>
                            <td>
                                <div className={`btn btn-blue`}>Button</div>
                            </td>
                        </tr>

                        <tr>
                            <td>btn btn-dark-blue</td>
                            <td>
                                <div className={`btn btn-dark-blue`}>Button</div>
                            </td>
                        </tr>

                        <tr>
                            <td>backend-btn</td>
                            <td>
                                <div className={`backend-btn`}>Button</div>
                            </td>
                        </tr>
                    </table>
                </div>
            </DemonstrationGroup>
            <DemonstrationGroup header={`Images`}>
                <div className={`overflow-y-scroll h-96`}>
                    <table className={`example-table`}>
                        <tr className={`header-row`}>
                            <td className={`w-48`}>Element</td>
                            <td>Example</td>
                        </tr>

                        <tr>
                            <td>{`<ProductImage size="xs">`}<br/>{`<DomainImage size="xs">`}</td>
                            <td><ProductImageXsDemo/></td>
                        </tr>
                        <tr>
                            <td>{`<ProductImage size="s">`}<br/>{`<DomainImage size="s">`}</td>
                            <td><ProductImageSDemo/></td>
                        </tr>
                        <tr>
                            <td>{`<ProductImage size="m">`}<br/>{`<DomainImage size="m">`}</td>
                            <td><ProductImageMDemo/></td>
                        </tr>
                        <tr>
                            <td>{`<ProductImage size="l">`}<br/>{`<DomainImage size="l">`}</td>
                            <td><ProductImageLDemo/></td>
                        </tr>
                    </table>
                </div>
            </DemonstrationGroup>
            <DemonstrationGroup header={`Elements`}>
                <table className={`example-table`}>
                    <tr className={`header-row`}>
                        <td className={`w-48`}>Element</td>
                        <td>Example</td>
                    </tr>

                    <tr>
                        <td>{`<Footer/>`}</td>
                        <td><FooterDemo/></td>
                    </tr>

                    <tr>
                        <td>{`<CategoryPath/>`}</td>
                        <td><CategoryPathDemo/></td>
                    </tr>

                    <tr>
                        <td>{`.delay-api-call-message`}</td>
                        <td><DelayApiCallMessageDemo/></td>
                    </tr>

                    <tr>
                        <td>{`<NotificationPanel/>`}</td>
                        <td><NotificationPanelDemo/></td>
                    </tr>

                    <tr>
                        <td>{`<PlaceholderText/> (for long texts)`}</td>
                        <td><PlaceholderTextDemo/></td>
                    </tr>

                    <tr>
                        <td>{`<SpinLoadingIndicator/>`}</td>
                        <td><SpinLoadingIndicatorDemo/></td>
                    </tr>

                </table>
            </DemonstrationGroup>
            <DemonstrationGroupFullscreen header={`Search Header for Category`}>
                <ProductCardListHeaderDemo/>
            </DemonstrationGroupFullscreen>
            <DemonstrationGroupFullscreen header={`Search Grid + Cards`}>
                <div className={`flex flex-wrap gap-[18px]`}>

                    <div className={`flex flex-col`}>
                        <div className={`flex-none text-center`}>{`<ProductCard/>`}</div>
                        <ProductCardDemo className={`flex-1`}/>
                    </div>

                    <div className={`flex flex-col`}>
                        <div className={`flex-none text-center`}>{`<FoldedManufacturerCard/>`}</div>
                        <FoldedManufacturerCardDemo className={`flex-1`}/>
                    </div>

                    <div className={`flex flex-col`}>
                        <div className={`flex-none text-center`}>{`<FoldedBrandingCard/>`}</div>
                        <FoldedBrandingCardDemo className={`flex-1`}/>
                    </div>

                    <div className={`flex flex-col`}>
                        <div className={`flex-none text-center`}>{`<ShowSecondaryCard/>`}</div>
                        <ShowSecondaryCardDemo className={`flex-1`}/>
                    </div>

                    <div className={`flex flex-col`}>
                        <div className={`flex-none text-center`}>{`<ShowMorePrimaryCard/>`}</div>
                        <ShowMorePrimaryCardDemo className={`flex-1`}/>
                    </div>

                    <div className={`flex flex-col`}>
                        <div className={`flex-none text-center`}>{`<ShowMoreSecondaryCard/>`}</div>
                        <ShowMoreSecondaryCardDemo className={`flex-1`}/>
                    </div>

                    <div className={`flex flex-col`}>
                        <div className={`flex-none text-center`}>{`<CollectionCard/>`}</div>
                        <CollectionCardDemo className={`flex-1`}/>
                    </div>

                    <div className={`flex flex-col`}>
                        <div className={`flex-none text-center`}>{`<CategoryCard/>`}</div>
                        <CategoryCardDemo className={`flex-1`}/>
                    </div>

                    <div className={`flex flex-col`}>
                        <div className={`flex-none text-center`}>{`<ManufacturerCard/>`}</div>
                        <ManufacturerCardDemo className={`flex-1`}/>
                    </div>

                    <div className={`flex flex-col`}>
                        <div className={`flex-none text-center`}>{`<BrandingCard/>`}</div>
                        <BrandingCardDemo className={`flex-1`}/>
                    </div>

                    <div className={`flex flex-col`}>
                        <div className={`flex-none text-center`}>{`<UnknownCard/>`}</div>
                        <UnknownCardDemo className={`flex-1`}/>
                    </div>

                    <div className={`flex flex-col`}>
                        <div className={`flex-none text-center`}>{`<PlaceholderCard/>`}</div>
                        <PlaceholderCardDemo className={`flex-1`}/>
                    </div>

                    <div className={`flex flex-col`}>
                        <div className={`flex-none text-center`}>{`<BrandingSeparator/>`}</div>
                        <PlaceholderCardWrapperDemo className={`flex-1`}>
                            <BrandingSeparatorDemo/>
                        </PlaceholderCardWrapperDemo>
                    </div>

                    <div className={`flex flex-col`}>
                        <div className={`flex-none text-center`}>{`<FamilyChainSeparator/>`}</div>
                        <PlaceholderCardWrapperDemo className={`flex-1`}>
                            <FamilyChainSeparatorDemo/>
                        </PlaceholderCardWrapperDemo>
                    </div>

                    <div className={`flex flex-col`}>
                        <div className={`flex-none text-center`}>{`<FoldedBrandingSeparator/>`}</div>
                        <PlaceholderCardWrapperDemo className={`flex-1`}>
                            <FoldedBrandingSeparatorDemo/>
                        </PlaceholderCardWrapperDemo>
                    </div>

                    <div className={`flex flex-col`}>
                        <div className={`flex-none text-center`}>{`<FoldedManufacturerSeparator/>`}</div>
                        <PlaceholderCardWrapperDemo className={`flex-1`}>
                            <FoldedManufacturerSeparatorDemo/>
                        </PlaceholderCardWrapperDemo>
                    </div>

                    <div className={`flex flex-col`}>
                        <div className={`flex-none text-center`}>{`<ManufacturerSeparator/>`}</div>
                        <PlaceholderCardWrapperDemo className={`flex-1`}>
                            <ManufacturerSeparatorDemo/>
                        </PlaceholderCardWrapperDemo>
                    </div>

                    <div className={`flex flex-col`}>
                        <div className={`flex-none text-center`}>{`<TopLevelSeparator/>`}</div>
                        <PlaceholderCardWrapperDemo className={`flex-1`}>
                            <TopLevelSeparatorDemo/>
                        </PlaceholderCardWrapperDemo>
                    </div>

                </div>
            </DemonstrationGroupFullscreen>

            <DemonstrationGroup header={`Filter Panel`}>
                <table className={`example-table`}>
                    <tr className={`header-row`}>
                        <td className={`w-48`}>Element</td>
                        <td>Example</td>
                    </tr>
                    <tr>
                        <td>{`<FilterAttribute/>`}</td>
                        <td><FilterAttributeDemo/></td>
                    </tr>
                    <tr>
                        <td>{`<FilterAttributeGroup/>`}</td>
                        <td><FilterAttributeGroupDemo/></td>
                    </tr>
                    <tr>
                        <td>{`<FilterAttributeSection/>`}</td>
                        <td><FilterAttributeSectionDemo/></td>
                    </tr>
                    <tr>
                        <td>{`<FilterBranding/>`}</td>
                        <td><FilterBrandingDemo/></td>
                    </tr>
                    <tr>
                        <td>{`<FilterColor/>`}</td>
                        <td><FilterColorDemo/></td>
                    </tr>
                    <tr>
                        <td>{`<FilterManufacturer/>`}</td>
                        <td><FilterManufacturerDemo/></td>
                    </tr>
                    <tr>
                        <td>{`<FilterNumeric/>`}</td>
                        <td><FilterNumericDemo/></td>
                    </tr>
                    <tr>
                        <td>{`<FilterStatic/>`}</td>
                        <td><FilterStaticDemo/></td>
                    </tr>
                </table>
            </DemonstrationGroup>

            <DemonstrationGroup header={`Product Description`}>
                <ProductTitleDemo/>
                <ProductDescriptionDemo/>
                <div className={`bg-white border p-2 rounded-md mt-2`}>
                    <ProductVendorsDemo/>
                </div>
                <div className={`bg-white border p-2 rounded-md relative mt-2`}>
                    <ProductSpecificationsDemo/>
                </div>
            </DemonstrationGroup>

            <DemonstrationGroup header={`Search Input`}>
                <table className={`example-table`}>
                    <tr className={`header-row`}>
                        <td className={`w-48`}>Element</td>
                        <td>Example</td>
                    </tr>

                    <tr>
                        <td>{`<SearchInput/>`}</td>
                        <td><SearchInputDemo/></td>
                    </tr>
                </table>
            </DemonstrationGroup>
        </div>
    );
}
