import React from "react"
import {connect, DispatchProp} from 'react-redux'
import {useTranslation, withTranslation, WithTranslation} from "react-i18next"
import {TranslationSet} from "../../../Localization/i18n.constants"
import FilterOptions from "../FilterOptions"
import {detachSearchParamAttributeFilter, toggleStaticNumericFilter} from "../../../Helpers/SearchHelper"

type FilterAttributeProps = {
    attribute?: IFilterPanel_Attribute,
    level: number,
    demonstrationMode: boolean
}

const ComponentTranslationKey = "Attribute"

/** @see FilterAttributeDemo */
export default function FilterAttribute(props: FilterAttributeProps) {

    // globals
    const {t} = useTranslation(ComponentTranslationKey)
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key)
    }

    const detachSearchParamAttributeFilterChildren = function (ignoreOption: IFilterPanel_Option, filters: IProductListSearch_Filter[]): IProductListSearch_Filter[] {
        if (props.attribute && props.attribute.filterValues) {
            for (const objectWrapper of props.attribute.filterValues) {
                const option = objectWrapper as IFilterPanel_Option
                if (option.id == ignoreOption.id) {
                    continue
                }
                filters = detachSearchParamAttributeFilterChild(objectWrapper, filters)
            }
        }
        return filters
    }

    const detachSearchParamAttributeFilterChild = function (objectWrapper: IFilterPanel_ObjectWrapper, filters: IProductListSearch_Filter[]): IProductListSearch_Filter[] {
        if (objectWrapper.objectType == IFilterPanel_ObjectType.Option) {
            const option = objectWrapper as IFilterPanel_Option
            if (option.checked) {
                filters = detachSearchParamAttributeFilter(filters, IProductListSearch_FilterType.Attribute, option.attributeId, option.id)
            }
        }
        let structures: IFilterPanel_ObjectWrapper[] = []
        if (objectWrapper.objectType == IFilterPanel_ObjectType.Section) {
            const section = objectWrapper as IFilterPanel_Section
            structures = section.structures
        } else if (objectWrapper.objectType == IFilterPanel_ObjectType.AttributeGroup) {
            const attributeGroup = objectWrapper as IFilterPanel_AttributeGroup
            structures = attributeGroup.structures
        } else if (objectWrapper.objectType == IFilterPanel_ObjectType.Attribute) {
            const attribute = objectWrapper as IFilterPanel_Attribute
            structures = attribute.filterValues
        } else if (objectWrapper.objectType == IFilterPanel_ObjectType.Option) {
            const option = objectWrapper as IFilterPanel_Option
            structures = option.structures
        } else if (objectWrapper.objectType == IFilterPanel_ObjectType.Numeric) {
            const numeric = objectWrapper as IFilterPanel_Numeric
            if (numeric.filterValues) {
                structures = numeric.filterValues
            }
            filters = toggleStaticNumericFilter(filters, IProductListSearch_FilterType.Numeric, numeric.id, undefined, undefined)
        }
        for (const structure of structures) {
            filters = detachSearchParamAttributeFilterChild(structure, filters)
        }
        return filters
    }

    const attribute = props.attribute

    if (!attribute || !attribute.filterValues || attribute.filterValues.length == 0) return null

    const level = props.level

    const html: React.ReactElement[] = []
    if (!attribute.suppressName) {
        html.push(
            <div key={`a-${attribute.id}`} className={level == 0 ? `filter-panel-attribute-spacing` : ``}>
                <div className={`filter-panel-attribute-header level-${level}`}>
                    {attribute.name}
                </div>
            </div>
        )
    }
    html.push(
        <div key={`a-${attribute.id}-op`}
             className={(level == 0 ? `filter-panel-attribute-spacing` : ``) + ` bg-white filter-panel-brand-options`}>
            <FilterOptions attribute={attribute}
                           level={level + 1}
                           detachSearchParamAttributeFilterChildren={detachSearchParamAttributeFilterChildren}
                           demonstrationMode={props.demonstrationMode}/>
        </div>
    )
    if (level == 0) {
        html.push(
            <div key={`a-separator-${attribute.id}`} className="filter-panel-attribute-separator"/>
        )
    }
    return <>{html}</>
}
