export const SET_IMAGE_VERIFICATION_LOAD = "SET_IMAGE_VERIFICATION_LOAD";
export const SET_IMAGE_VERIFICATION_SAVE = "SET_IMAGE_VERIFICATION_SAVE";
export const SET_IMAGE_VERIFICATION_DELETE = "SET_IMAGE_VERIFICATION_DELETE";
export const SET_IMAGE_VERIFICATION_APPLY = "SET_IMAGE_VERIFICATION_APPLY";

export const SET_IMAGE_VERIFICATION_SET_PENDING = "SET_IMAGE_VERIFICATION_SET_PENDING";
export const SET_IMAGE_VERIFICATION_REMOVE_PENDING = "SET_IMAGE_VERIFICATION_REMOVE_PENDING";

export const SHOPHUNTER_OPEN_MENU = 'SHOPHUNTER_OPEN_MENU';
export const SHOPHUNTER_MARK_PRODUCT_IMAGE_VERIFIED = "SHOPHUNTER_MARK_PRODUCT_IMAGE_VERIFIED";
export const SHOPHUNTER_UNMARK_PRODUCT_IMAGE_VERIFIED = "SHOPHUNTER_UNMARK_PRODUCT_IMAGE_VERIFIED";

// ----

export type ImageVerificationSaveAction = {
    type: string,
    userSessionId: string,
    verificationType: (keyof typeof VERIFICATION_VERIFICATION_TYPE),
    imageId: number,
    productId: number | undefined,
    productType: (keyof typeof VERIFICATION_IMAGE_STATUS) | undefined,
    imageType: (keyof typeof VERIFICATION_IMAGE_TYPE) | undefined,
    imageContentType: (keyof typeof VERIFICATION_IMAGE_CONTENT_TYPE) | undefined,
    imageVariants: number | undefined,
    imageOverlay: (keyof typeof VERIFICATION_IMAGE_OVERLAY) | undefined,
    imageWatermark: (keyof typeof VERIFICATION_IMAGE_WATERMARK) | undefined
}

export const triggerImageVerification_Save_Status = (userSession: string,
                                                     imageId: number,
                                                     productId: number,
                                                     status: keyof typeof VERIFICATION_IMAGE_STATUS): ImageVerificationSaveAction => ({
    type: SET_IMAGE_VERIFICATION_SAVE,
    userSessionId: userSession,
    imageId: imageId,
    verificationType: "STATUS",
    productId: productId,
    productType: status,
    imageOverlay: undefined,
    imageVariants: undefined,
    imageType: undefined,
    imageContentType: undefined,
    imageWatermark: undefined
});

export const triggerImageVerification_Save_ImageType = (userSession: string,
                                                        imageId: number,
                                                        imageType: keyof typeof VERIFICATION_IMAGE_TYPE): ImageVerificationSaveAction => ({
    type: SET_IMAGE_VERIFICATION_SAVE,
    userSessionId: userSession,
    imageId: imageId,
    verificationType: "TYPE",
    imageType: imageType,
    imageContentType: undefined,
    productId: undefined,
    productType: undefined,
    imageOverlay: undefined,
    imageVariants: undefined,
    imageWatermark: undefined
});

export const triggerImageVerification_Save_ImageContentType = (userSession: string,
                                                               imageId: number,
                                                               imageContentType: keyof typeof VERIFICATION_IMAGE_CONTENT_TYPE): ImageVerificationSaveAction => ({
    type: SET_IMAGE_VERIFICATION_SAVE,
    userSessionId: userSession,
    imageId: imageId,
    verificationType: "CONTENT_TYPE",
    imageContentType: imageContentType,
    imageType: undefined,
    productId: undefined,
    productType: undefined,
    imageOverlay: undefined,
    imageVariants: undefined,
    imageWatermark: undefined
});

export const triggerImageVerification_Save_ImageVariants = (userSession: string,
                                                            imageId: number,
                                                            variant: number): ImageVerificationSaveAction => ({
    type: SET_IMAGE_VERIFICATION_SAVE,
    userSessionId: userSession,
    imageId: imageId,
    verificationType: "VARIANTS",
    imageVariants: variant,
    imageType: undefined,
    imageContentType: undefined,
    productId: undefined,
    productType: undefined,
    imageOverlay: undefined,
    imageWatermark: undefined
});

export const triggerImageVerification_Save_ImageOverlay = (userSession: string,
                                                           imageId: number,
                                                           overlay: keyof typeof VERIFICATION_IMAGE_OVERLAY): ImageVerificationSaveAction => ({
    type: SET_IMAGE_VERIFICATION_SAVE,
    userSessionId: userSession,
    imageId: imageId,
    verificationType: "OVERLAY",
    imageVariants: undefined,
    imageType: undefined,
    imageContentType: undefined,
    productId: undefined,
    productType: undefined,
    imageOverlay: overlay,
    imageWatermark: undefined
});

export const triggerImageVerification_Save_ImageWatermark = (userSession: string,
                                                             imageId: number,
                                                             watermark: keyof typeof VERIFICATION_IMAGE_WATERMARK): ImageVerificationSaveAction => ({
    type: SET_IMAGE_VERIFICATION_SAVE,
    userSessionId: userSession,
    imageId: imageId,
    verificationType: "WATERMARK",
    imageVariants: undefined,
    imageType: undefined,
    imageContentType: undefined,
    productId: undefined,
    productType: undefined,
    imageOverlay: undefined,
    imageWatermark: watermark
});

// ---

// ---
export type ImageVerificationDeleteAction = {
    type: string,
    userSessionId: string,
    imageId: number,
    productId: number | undefined,
}

export const triggerImageVerification_Delete = (userSession: string, imageId: number, productId: number | undefined): ImageVerificationDeleteAction => ({
    type: SET_IMAGE_VERIFICATION_DELETE,
    userSessionId: userSession,
    imageId: imageId,
    productId: productId
});

export type ImageVerificationApplyAction = {
    type: string,
    imageIds: number[],
    verifications: {
        [key: number]: IShophunterImageData[]
    }
}

export const triggerImageVerification_Apply = (imageIds: number[], verifications: {
    [key: number]: IShophunterImageData[]
}): ImageVerificationApplyAction => ({
    type: SET_IMAGE_VERIFICATION_APPLY,
    imageIds: imageIds,
    verifications: verifications
})

// ---

export type ImageVerificationLoadAction = {
    type: string,
    imageIds: number[],
}

export const triggerImageVerification_Load = (imageIds: number[]): ImageVerificationLoadAction => ({
    type: SET_IMAGE_VERIFICATION_LOAD,
    imageIds: imageIds,
})

// ---

export type ShophunterMenuOpenAction = {
    type: string,
}

export const triggerImageVerification_MenuOpen = (): ShophunterMenuOpenAction => ({
    type: SHOPHUNTER_OPEN_MENU,
})

// ---

export type ShophunterMarkMergedProductImageVerifiedAction = {
    type: string,
    mergedProductId: number | number[]
}

export const triggerImageVerification_MarkImageVerified = (mergedProductId: number | number[]): ShophunterMarkMergedProductImageVerifiedAction => ({
    type: SHOPHUNTER_MARK_PRODUCT_IMAGE_VERIFIED,
    mergedProductId: mergedProductId
})

export type ShophunterUnmarkMergedProductImageVerifiedAction = {
    type: string,
    mergedProductId: number
}
export const triggerImageVerification_UnmarkImageVerified = (mergedProductId: number): ShophunterUnmarkMergedProductImageVerifiedAction => ({
    type: SHOPHUNTER_UNMARK_PRODUCT_IMAGE_VERIFIED,
    mergedProductId: mergedProductId
})

// ---

export type ImageVerificationSetPendingAction = {
    type: string,
    verificationType: (keyof typeof VERIFICATION_VERIFICATION_TYPE),
    imageId: number,
    productId: number | undefined,
    productType: (keyof typeof VERIFICATION_IMAGE_STATUS) | undefined,
    imageType: (keyof typeof VERIFICATION_IMAGE_TYPE) | undefined,
    imageContentType: (keyof typeof VERIFICATION_IMAGE_CONTENT_TYPE) | undefined,
    imageVariants: number | undefined,
    imageOverlay: (keyof typeof VERIFICATION_IMAGE_OVERLAY) | undefined,
    imageWatermark: (keyof typeof VERIFICATION_IMAGE_WATERMARK) | undefined
}

export const triggerImageVerification_SetPending = (verificationType: (keyof typeof VERIFICATION_VERIFICATION_TYPE),
                                                    imageId: number,
                                                    productId: number | undefined,
                                                    productType: (keyof typeof VERIFICATION_IMAGE_STATUS) | undefined,
                                                    imageType: (keyof typeof VERIFICATION_IMAGE_TYPE) | undefined,
                                                    imageContentType: (keyof typeof VERIFICATION_IMAGE_CONTENT_TYPE) | undefined,
                                                    imageVariants: number | undefined,
                                                    imageOverlay: (keyof typeof VERIFICATION_IMAGE_OVERLAY) | undefined,
                                                    imageWatermark: (keyof typeof VERIFICATION_IMAGE_WATERMARK) | undefined): ImageVerificationSetPendingAction => ({
    type: SET_IMAGE_VERIFICATION_SET_PENDING,
    imageId: imageId,
    verificationType: verificationType,
    productId: productId,
    productType: productType,
    imageOverlay: imageOverlay,
    imageVariants: imageVariants,
    imageType: imageType,
    imageContentType: imageContentType,
    imageWatermark: imageWatermark
});

// ---

export type ImageVerificationRemovePendingAction = {
    type: string,
    verificationType: (keyof typeof VERIFICATION_VERIFICATION_TYPE),
    imageId: number,
    productId: number | undefined,
    productType: (keyof typeof VERIFICATION_IMAGE_STATUS) | undefined,
    imageType: (keyof typeof VERIFICATION_IMAGE_TYPE) | undefined,
    imageContentType: (keyof typeof VERIFICATION_IMAGE_CONTENT_TYPE) | undefined,
    imageVariants: number | undefined,
    imageOverlay: (keyof typeof VERIFICATION_IMAGE_OVERLAY) | undefined,
    imageWatermark: (keyof typeof VERIFICATION_IMAGE_WATERMARK) | undefined
}

export const triggerImageVerification_RemovePending = (verificationType: (keyof typeof VERIFICATION_VERIFICATION_TYPE),
                                                       imageId: number,
                                                       productId: number | undefined,
                                                       productType: (keyof typeof VERIFICATION_IMAGE_STATUS) | undefined,
                                                       imageType: (keyof typeof VERIFICATION_IMAGE_TYPE) | undefined,
                                                       imageContentType: (keyof typeof VERIFICATION_IMAGE_CONTENT_TYPE) | undefined,
                                                       imageVariants: number | undefined,
                                                       imageOverlay: (keyof typeof VERIFICATION_IMAGE_OVERLAY) | undefined,
                                                       imageWatermark: (keyof typeof VERIFICATION_IMAGE_WATERMARK) | undefined): ImageVerificationRemovePendingAction => ({
    type: SET_IMAGE_VERIFICATION_REMOVE_PENDING,
    imageId: imageId,
    verificationType: verificationType,
    productId: productId,
    productType: productType,
    imageOverlay: imageOverlay,
    imageVariants: imageVariants,
    imageType: imageType,
    imageContentType: imageContentType,
    imageWatermark: imageWatermark
});
