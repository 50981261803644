function getMetaTagsEndNode() {
    const nodes = document.getElementsByTagName('head')[0].childNodes
    for (let i = 0; i < nodes.length; i++)
        if (nodes[i].nodeType == 8 && nodes[i].textContent && (nodes[i].textContent as string).trim() == 'Dynamic metadata end')
            return nodes[i]
    return null;
}

function getStructureContentEndNode() {
    const nodes = document.getElementsByTagName('head')[0].childNodes
    for (let i = 0; i < nodes.length; i++)
        if (nodes[i].nodeType == 8 && nodes[i].textContent && (nodes[i].textContent as string).trim() == 'Dynamic content metadata end')
            return nodes[i]
    return null;
}

export function setDocumentTitle(title: string) {
    if (document && document.title != title) {
        document.title = title;
    }
}

export function setMetaNameTag(tag: string, value: string) {
    if (document) {
        const element = document.querySelector('meta[name="' + tag + '"]')
        if (element) {
            element.setAttribute('content', value);
        } else {
            const element = document.createElement('meta')
            element.setAttribute('name', tag);
            element.setAttribute('content', value);

            const metaComment = getMetaTagsEndNode()
            if (metaComment) {
                document.head.insertBefore(element, metaComment)
            } else {
                document.head.appendChild(element)
            }
        }
    }
}

export function deleteMetaNameTag(tag: string) {
    if (document) {
        const element = document.querySelector('meta[name="' + tag + '"]')
        if (element)
            element.remove()
    }
}

export function setMetaPropertyTag(tag: string, value: string) {
    if (document) {
        const element = document.querySelector('meta[property="' + tag + '"]')
        if (element) {
            element.setAttribute('content', value);
        } else {
            const element = document.createElement('meta')
            element.setAttribute('property', tag);
            element.setAttribute('content', value);

            const metaComment = getMetaTagsEndNode()
            if (metaComment) {
                document.head.insertBefore(element, metaComment)
            } else {
                document.head.appendChild(element)
            }
        }
    }
}

export function deleteMetaPropertyTag(tag: string) {
    if (document) {
        const element = document.querySelector('meta[property="' + tag + '"]')
        if (element)
            element.remove()
    }
}

export function setLinkCanonicalTag(value: string) {
    if (document) {
        const element = document.querySelector('link[rel="canonical"]')
        if (element) {
            element.setAttribute('href', value);
        } else {
            const element = document.createElement('link')
            element.setAttribute('href', value);
            element.setAttribute('rel', "canonical");

            const metaComment = getMetaTagsEndNode()
            if (metaComment) {
                document.head.insertBefore(element, metaComment)
            } else {
                document.head.appendChild(element)
            }
        }
    }
}

export function deleteLinkCanonicalTag() {
    if (document) {
        const element = document.querySelector('link[rel="canonical"]')
        if (element)
            element.remove()
    }
}

// https://developers.google.com/search/docs/appearance/structured-data/intro-structured-data
export function setMetaStructuredDataTag(value: string) {
    if (document) {
        const element = document.querySelector('script[type="application/ld+json"]')
        if (element) {
            element.textContent = value;
        } else {
            const element = document.createElement('script')
            element.setAttribute('type', 'application/ld+json');
            element.textContent = value;

            const metaComment = getStructureContentEndNode()
            if (metaComment) {
                document.head.insertBefore(element, metaComment)
            } else {
                document.head.appendChild(element)
            }
        }
    }
}

export function deleteMetaStructuredDataTag() {
    if (document) {
        const element = document.querySelector('script[type="application/ld+json"]')
        if (element)
            element.remove()
    }
}