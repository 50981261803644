const set = (key: string, value: string) => {
    try {
        localStorage.setItem(key, JSON.stringify(value))

        return true
    } catch (e) {
        return false
    }
}

const get = (key: string) => {
    try {
        return JSON.parse(localStorage.getItem(key) || "")
    } catch (e) {
        return null
    }
}

const remove = (key: string) => {
    try {
        localStorage.removeItem(key)

        return true
    } catch (e) {
        return false
    }
}

const clear = () => {
    try {
        localStorage.clear()

        return true
    } catch (e) {
        return false
    }
}

// ---

const compareProductKey = 'product-compare';

export const getCompareProducts = (): { [searchKey: number]: number[] } => {
    return get(compareProductKey) || {};
}

export const getCompareProductIds = (): number[] => {
    const compareProducts = getCompareProducts();
    const compareProductsKeys = Object.keys(compareProducts);
    let productIds = [];
    for (let i = 0; i < compareProductsKeys.length; i++) {
        let categoryId = parseInt(compareProductsKeys[i]);

        if (!compareProducts[categoryId]) {
            delete compareProducts[compareProductsKeys[i] as any]
            set(compareProductKey, compareProducts as any)
            continue;
        }

        for (let j = 0; j < compareProducts[categoryId].length; j++) {
            productIds.push(compareProducts[categoryId][j]);
        }
    }
    return productIds
}

export const addCompareProduct = (searchKey: number, productId: number) => {
    const categories = get(compareProductKey) || {}

    if (Array.isArray(categories[searchKey])) {
        categories[searchKey].push(productId)
    } else {
        categories[searchKey] = [productId]
    }

    set(compareProductKey, categories)
}

export const removeCompareProduct = (searchKey: number| undefined, productId: number) => {
    const categories = get(compareProductKey) || {}

    if (searchKey) {

        if (!Array.isArray(categories[searchKey])) {
            return null
        }

        const i = categories[searchKey].indexOf(productId)

        if (i !== -1) {
            categories[searchKey].splice(i, 1)
        }

        if (categories[searchKey].length === 0) {
            delete categories[searchKey]
        }

        set(compareProductKey, categories)

    } else {
        // dunno why need this branch

        let removed = false
        let targetSearchKey = null

        for (const key of Object.keys(categories)) {
            if (!Array.isArray(categories[key])) {
                continue
            }

            for (let i = 0; i < categories[key].length; ++i) {
                if (categories[key][i] === productId) {
                    categories[key].splice(i, 1)

                    if (categories[key].length === 0) {
                        delete categories[key]
                    }

                    removed = true
                    targetSearchKey = key

                    break
                }
            }

            if (removed) break
        }

        if (removed) {
            set(compareProductKey, categories)
        }
    }
}

export const getKeyCompareProduct = (productIds: number[]) => {
    let candidateKeyValue: { [key: string]: number } = {}

    const categories = get(compareProductKey) || {}

    for (const key of Object.keys(categories)) {
        if (!Array.isArray(categories[key])) {
            continue
        }

        for (let i = 0; i < categories[key].length; ++i) {
            for (let j = 0; j < productIds.length; ++j) {
                if (categories[key][i] === productIds[j]) {

                    if (candidateKeyValue[key] === undefined)
                        candidateKeyValue[key] = 1
                    else
                        candidateKeyValue[key] = candidateKeyValue[key] + 1
                }
            }
        }
    }

    const candidateKeys = Object.keys(candidateKeyValue)
    if (candidateKeys.length > 0) {
        let candidateKey = candidateKeys[0];
        for (let i = 1; i < candidateKeys.length; ++i) {
            if (candidateKeyValue[candidateKey] < candidateKeyValue[candidateKeys[i]])
                candidateKey = candidateKeys[i];
        }
        return candidateKey;
    }

    return undefined
}

// ---

const getTimestamp = () => Math.floor(Date.now() / 1000)

const mergedProductWithVerifiedImageKey = 'verified-merged-product-image';

export const getMergedProductsWithVerifiedImageKeyData = (): { [key: number]: number } => {
    return get(mergedProductWithVerifiedImageKey) || {};
}

export const getMergedProductsWithVerifiedImageKey = (): number[] => {
    const timestamp = getTimestamp()
    const outdatedTime = 60 * 60 * 24 * 7     // 7 days

    const mpData = getMergedProductsWithVerifiedImageKeyData();
    const mpKeys = Object.keys(mpData);
    let productIds = [];
    for (let i = 0; i < mpKeys.length; i++) {
        let productId = parseInt(mpKeys[i]);
        const productVerifyTime = mpData[productId];
        if (timestamp - productVerifyTime < outdatedTime)
            productIds.push(productId);
    }
    return productIds
}

export const addMergedProductsWithVerifiedImageKey = (productId: number) => {
    const mpData = get(mergedProductWithVerifiedImageKey) || {}
    mpData[productId] = getTimestamp()
    set(mergedProductWithVerifiedImageKey, mpData)
}

export const removeMergedProductsWithVerifiedImageKey = (productId: number) => {
    const mpData = get(mergedProductWithVerifiedImageKey) || {}
    if(mpData[productId])
        delete mpData[productId]
    set(mergedProductWithVerifiedImageKey, mpData)
}
