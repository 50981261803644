import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";
import {triggerLoadProductOfferData} from "../../Store/Action/external";
import {useDispatch} from "react-redux";
import {ProductImage} from "../../Components/App/Common/Image";
import {useLocation} from "react-router-dom";
import {MetaTags} from "../../Components/App/Tags/Meta";

const ComponentTranslationKey = "External"

export default function ExternalRoute() {

    // globals
    const dispatch = useDispatch()
    const location = useLocation()
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }
    const parseUrl = (url: string): {
        id: number | undefined,
        mi: number | undefined,
        notFound: number | undefined
    } => {
        if (url.indexOf('?') !== -1) {
            url = url.substring(url.indexOf('?'));
        }
        const urlParams = new URLSearchParams(url);
        let id = undefined;
        let mi = undefined;
        let notFound = undefined;
        urlParams.forEach(function (v, k) {
            switch (k) {
                case 'id':
                    id = parseInt(v);
                    break;
                case 'mi':
                    mi = parseInt(v);
                    break;
                case 'not_found':
                    notFound = parseInt(v);
                    break;
            }
        })
        return {
            id: id,
            mi: mi,
            notFound: notFound
        }
    }

    // extract global state
    const params = parseUrl(location.search)

    // lifecycle functions
    useEffect(() => {
        const params = parseUrl(location.search)

        if (params.id && !params.notFound) {
            dispatch(triggerLoadProductOfferData(params.id, (product_url) => {
                if (product_url && product_url.length > 0) {
                    setTimeout(function () {
                        window.location.replace(product_url)
                    }, 200)
                } else {
                    setTimeout(function () {
                        window.location.replace(`go-to-store?id=${params.id}&mi=${params.mi}&not_found=1`)
                    }, 200)
                }
            }))
        }
    }, []);

    // render
    return <div className="external-route">

        <MetaTags title={`Shopit`}
                  canonicalPageUrl={`default`}
                  robots={`noindex`}/>

        <div className="external-outer-wrap">
            <div className="external-inner-wrap">
                <div className="external-content">
                    {params.mi
                        ? <ProductImage imageId={params.mi} alt={`Product preview`} size='m' imageClass={`rounded-xl`}/>
                        : null}
                    <div className="middle-content">
                        <div className="forward-arrow"/>
                        <div className={`wait-text ${params.mi ? '' : 'mt-8'}`}>
                            {!params.notFound
                                ? getTranslation('wait')
                                : 'Not Found...'}
                        </div>
                        <div className="redirect-text">
                            {!params.notFound
                                ? getTranslation('redirecting')
                                : '...product url is missing'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
