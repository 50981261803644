import React, {MouseEventHandler} from "react";
import {triggerProductState} from "../../../Store/Action/session.product";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";

export function CardControls(props: {
    productId: number,
    productIsMissing: boolean,

    isLike: boolean,
    isLikePending: boolean,

    isOwn: boolean,
    isOwnPending: boolean,

    isCompare: boolean,
    onCompare: MouseEventHandler<HTMLElement>,

    isVerify: boolean,
    showVerify: boolean,
    onVerifyCategory: MouseEventHandler<HTMLElement>,
    onVerifyModalOpen: MouseEventHandler<HTMLElement>,
}) {

    const {t} = useTranslation("LegendControls");
    const dispatch = useDispatch();
    const getTranslation = function (key: keyof TranslationSet["LegendControls"]) {
        return t(key);
    }

    const onLike: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.isLike) {
            dispatch(triggerProductState(props.productId, "none", "like"));
        } else {
            dispatch(triggerProductState(props.productId, "like", props.isOwn ? "own" : "none"));
        }
    }

    const onOwn: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (props.isOwn) {
            dispatch(triggerProductState(props.productId, "none", "own"));
        } else {
            dispatch(triggerProductState(props.productId, "own", props.isLike ? "like" : "none"));
        }
    }

    return <>
        <div className="category-item-card-controls">
            {!props.productIsMissing ? <div
                className={`control-like ${props.isLike ? 'active' : ''} ${props.isLikePending ? 'animate-pulse' : ''}`}
                title={getTranslation(props.isLike ? "like_active" : "like")}
                onClick={onLike}></div> : null}
            {!props.productIsMissing ? <div
                className={`control-own ${props.isOwn ? 'active' : ''} ${props.isOwnPending ? 'animate-pulse' : ''}`}
                title={getTranslation(props.isLike ? "own_active" : "own")}
                onClick={onOwn}></div> : null}
            <div className={`control-compare ${props.isCompare ? 'active' : ''}`}
                 title={getTranslation(props.isLike ? "compare_active" : "compare")}
                 onClick={props.onCompare}></div>
        </div>

        <div className="category-item-card-controls category-item-card-controls-left">
            {props.showVerify
                ? <div className={`control-category ${props.isVerify ? `active` : ``}`} onClick={props.onVerifyCategory}
                       title={getTranslation("verify_category")}></div>
                : null}
            {props.showVerify
                ? <div className="control-verify" onClick={props.onVerifyModalOpen}
                       title={getTranslation("verification_modal_title")}></div>
                : null}
        </div>
    </>
}