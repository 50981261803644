import {
    ProductPreviewLoadSuccessAction,
    RequestProductStateAction,
    SET_PRODUCT_STATE_ADD_COMPARE,
    SET_PRODUCT_STATE_REMOVE_COMPARE,
    RequestSearchAndProductStateAction,
    RequestDataAction,
    SET_PRODUCT_STATE_SET_COMPARE,
    RequestProductStateSuccessAction,
    SET_PRODUCT_STATE_PENDING,
    SET_PRODUCT_STATE_SUCCESS
} from "../Action/session.product";
import {
    CloseVerifyProductAction,
    OpenVerifyProductAction,
    ProductVerifyAction,
    ProductVerifyAddAction,
    ProductVerifyAddData,
    SET_PRODUCT_VERIFY_ADD_DATA,
    SET_PRODUCT_VERIFY_ADD_PRODUCT,
    SET_PRODUCT_VERIFY_REMOVE_PRODUCT,
    SHOPHUNTER_CLOSE_VERIFY_PRODUCT,
    SHOPHUNTER_OPEN_VERIFY_PRODUCT
} from "../Action/session.verifyProduct";
import {SESSION_RESPONSE_USER_LOGIN, SESSION_RESPONSE_USER_LOGOUT, ResponseLoginAction} from "../Action/session.login";
import {
    ImageVerificationApplyAction, ImageVerificationRemovePendingAction, ImageVerificationSetPendingAction,
    SET_IMAGE_VERIFICATION_APPLY, SET_IMAGE_VERIFICATION_REMOVE_PENDING, SET_IMAGE_VERIFICATION_SET_PENDING,
    SHOPHUNTER_MARK_PRODUCT_IMAGE_VERIFIED,
    SHOPHUNTER_OPEN_MENU,
    SHOPHUNTER_UNMARK_PRODUCT_IMAGE_VERIFIED,
    ShophunterMarkMergedProductImageVerifiedAction,
    ShophunterUnmarkMergedProductImageVerifiedAction,
} from "../Action/session.verifyImage";
import {
    MergedProductImageVerificationApplyAction,
    MergedProductImageVerificationRemovePendingAction,
    MergedProductImageVerificationSetPendingAction,
    SET_MERGED_PRODUCT_IMAGE_VERIFICATION_APPLY,
    SET_MERGED_PRODUCT_IMAGE_VERIFICATION_REMOVE_PENDING,
    SET_MERGED_PRODUCT_IMAGE_VERIFICATION_SET_PENDING
} from "../Action/session.verifyMergedProductImage";
import {LOAD_TRACKING_VISITED, TrackingVisitedAction} from "../Action/tracking";

export const userInitialState: ISessionState = {
    userSessionId: undefined,
    user: undefined,
    likedProducts: {
        pendingProductIds: [],
        productIds: [],
    },
    ownerProducts: {
        pendingProductIds: [],
        productIds: [],
    },
    compareProducts: {},
    shophunter: {
        shophunterData: undefined,
        activeProduct: undefined,

        haveVerificationMenu: true,
        verifiedMergedProductImageMergedProductIds: []
    },
    tracking: {
        pageToken: undefined,
        pageUrl: undefined
    }
};

// ---

function sessionResponseUserLogin(state: ISessionState, action: ResponseLoginAction): ISessionState {
    return {
        ...state,
        user: action.user,
        userSessionId: action.userSession
    };
}

function sessionResponseUserLogout(state: ISessionState): ISessionState {
    return {
        ...state,
        user: undefined,
        userSessionId: undefined,
        likedProducts: {
            pendingProductIds: [],
            productIds: []
        },
        ownerProducts: {
            pendingProductIds: [],
            productIds: [],
        },
        shophunter: {
            shophunterData: undefined,
            activeProduct: undefined,

            haveVerificationMenu: true,
            verifiedMergedProductImageMergedProductIds: []
        }
    };
}

function setProductStatePending(state: ISessionState, action: RequestProductStateAction): ISessionState {
    return {
        ...state,
        ownerProducts: {
            ...state.ownerProducts,
            pendingProductIds: [...state.ownerProducts.pendingProductIds, action.productId]
        },
        likedProducts: {
            ...state.likedProducts,
            pendingProductIds: [...state.likedProducts.pendingProductIds, action.productId]
        }
    }
}

function setProductStateSuccess(state: ISessionState, action: RequestProductStateSuccessAction): ISessionState {
    const nextOwnProduct =
        action.setOwn
            ? [...state.ownerProducts.productIds, action.productId]
            : action.removeOwn
                ? state.ownerProducts.productIds.filter(id => id != action.productId)
                : state.ownerProducts.productIds;

    const nextLikeProduct =
        action.setLike
            ? [...state.likedProducts.productIds, action.productId]
            : action.removeLike
                ? state.likedProducts.productIds.filter(id => id != action.productId)
                : state.likedProducts.productIds;

    return {
        ...state,
        ownerProducts: {
            ...state.ownerProducts,
            pendingProductIds: state.ownerProducts.pendingProductIds.filter(id => id != action.productId),
            productIds: nextOwnProduct
        },
        likedProducts: {
            ...state.likedProducts,
            pendingProductIds: state.likedProducts.pendingProductIds.filter(id => id != action.productId),
            productIds: nextLikeProduct
        }
    }
}

function setProductStateAddCompare(state: ISessionState, action: RequestSearchAndProductStateAction): ISessionState {
    if (action.categoryId) {
        return {
            ...state,
            compareProducts: {
                ...state.compareProducts,
                [action.categoryId]: state.compareProducts[action.categoryId]
                    ? [...state.compareProducts[action.categoryId], action.productId]
                    : [action.productId]
            }
        }
    }
    return state;
}

function setProductStateRemoveCompare(state: ISessionState, action: RequestSearchAndProductStateAction): ISessionState {
    if (action.categoryId) {
        return {
            ...state,
            compareProducts: {
                ...state.compareProducts,
                [action.categoryId]: state.compareProducts[action.categoryId]
                    ? state.compareProducts[action.categoryId].filter(id => id != action.productId)
                    : state.compareProducts[action.categoryId]
            }
        }
    } else {
        let compareProducts = {...state.compareProducts}
        for (const p in compareProducts) {
            const key = parseInt(p)
            compareProducts[key] = state.compareProducts[key].filter(id => id != action.productId)
        }
        return {...state, compareProducts: compareProducts}
    }
}

function setProductStateSetCompare(state: ISessionState, action: RequestDataAction): ISessionState {
    return {
        ...state,
        compareProducts: action.data
    }
}

function setProductVerifyAddProduct(state: ISessionState, action: ProductVerifyAddAction): ISessionState {

    if (state.shophunter.shophunterData) {
        return {
            ...state,
            shophunter: {
                ...state.shophunter,
                shophunterData: {
                    ...state.shophunter.shophunterData,
                    verifiedProducts: {
                        ...state.shophunter.shophunterData.verifiedProducts,
                        [action.productId]: {
                            productId: action.productId,
                            verifiedCategoryId: action.categoryId,
                            verifiedCategoryNoMatch: action.noCategoryMatch,
                            verifiedCategoryNotRegenerated: action.notRegenerated,
                        }
                    }
                }
            }
        }
    }

    return state;
}

function setProductVerifyRemoveProduct(state: ISessionState, action: ProductVerifyAction): ISessionState {

    if (state.shophunter.shophunterData) {

        let nextShophunterData: IShophunterData = {
            ...state.shophunter.shophunterData,
            verifiedProducts: {...state.shophunter.shophunterData.verifiedProducts}
        }
        delete nextShophunterData.verifiedProducts[action.productId];

        return {
            ...state,
            shophunter: {
                ...state.shophunter,
                shophunterData: nextShophunterData,
            }
        }
    }

    return state;
}

function setProductVerifyAddData(state: ISessionState, action: ProductVerifyAddData): ISessionState {

    return {
        ...state,
        shophunter: {
            ...state.shophunter,
            shophunterData: {
                ...state.shophunter.shophunterData,
                ...action.shophunterData
            }
        }
    }
}

function shophunterOpenVerifyProduct(state: ISessionState, action: OpenVerifyProductAction): ISessionState {
    return {
        ...state,
        shophunter: {
            ...state.shophunter,
            activeProduct: action.product
        }
    }
}

function shophunterCloseVerifyProduct(state: ISessionState, action: CloseVerifyProductAction): ISessionState {
    return {
        ...state,
        shophunter: {
            ...state.shophunter,
            activeProduct: undefined
        }
    }
}

function setImageVerificationApply(state: ISessionState, action: ImageVerificationApplyAction): ISessionState {
    let nextShophunterData: IShophunterData = state.shophunter.shophunterData
        ? {
            ...state.shophunter.shophunterData,
            verifiedImages: {...state.shophunter.shophunterData.verifiedImages}
        } : {
            verifiedImages: {},
            verifiedProducts: {},
            verifiedMergedProductImages: {},
            latestVerifyCategoryIds: []
        };

    for (let i = 0; i < action.imageIds.length; i++) {
        if (nextShophunterData.verifiedImages[action.imageIds[i]] !== undefined)
            delete nextShophunterData.verifiedImages[action.imageIds[i]]
    }

    return {
        ...state,
        shophunter: {
            ...state.shophunter,
            shophunterData: {
                ...nextShophunterData,
                verifiedImages: {...nextShophunterData.verifiedImages, ...action.verifications}
            }
        }
    }
}

function setImageVerificationSetPending(state: ISessionState, action: ImageVerificationSetPendingAction): ISessionState {
    let nextShophunterData: IShophunterData = state.shophunter.shophunterData
        ? {
            ...state.shophunter.shophunterData,
            verifiedImages: {...state.shophunter.shophunterData.verifiedImages}
        } : {
            verifiedImages: {},
            verifiedProducts: {},
            verifiedMergedProductImages: {},
            latestVerifyCategoryIds: []
        };

    if (nextShophunterData.verifiedImages[action.imageId] === undefined) {
        nextShophunterData.verifiedImages[action.imageId] = [{
            imageId: action.imageId,
            verificationType: action.verificationType,
            value: {
                imageStatus: undefined,
                imageType: undefined,
                imageContentType: undefined,
                imageWatermark: undefined,
                imageOverlay: undefined,
                imageVariants: undefined,
                status: undefined,
            },
            pending: {
                imageStatus: action.productType,
                imageType: action.imageType,
                imageContentType: action.imageContentType,
                imageWatermark: action.imageWatermark,
                imageOverlay: action.imageOverlay,
                imageVariants: action.imageVariants,
            }
        }];
    } else {
        const imageIndex = nextShophunterData.verifiedImages[action.imageId].findIndex(a => a.verificationType == action.verificationType)
        if (imageIndex != -1) {
            nextShophunterData.verifiedImages[action.imageId][imageIndex].pending = {
                imageStatus: action.productType,
                imageType: action.imageType,
                imageContentType: action.imageContentType,
                imageWatermark: action.imageWatermark,
                imageOverlay: action.imageOverlay,
                imageVariants: action.imageVariants,
            }
        } else {
            nextShophunterData.verifiedImages[action.imageId].push({
                imageId: action.imageId,
                verificationType: action.verificationType,
                value: {
                    imageStatus: undefined,
                    imageType: undefined,
                    imageContentType: undefined,
                    imageWatermark: undefined,
                    imageOverlay: undefined,
                    imageVariants: undefined,
                    status: undefined,
                },
                pending: {
                    imageStatus: action.productType,
                    imageType: action.imageType,
                    imageContentType: action.imageContentType,
                    imageWatermark: action.imageWatermark,
                    imageOverlay: action.imageOverlay,
                    imageVariants: action.imageVariants,
                }
            })
        }
    }

    return {
        ...state,
        shophunter: {
            ...state.shophunter,
            shophunterData: {
                ...nextShophunterData
            }
        }
    }
}

function setImageVerificationRemovePending(state: ISessionState, action: ImageVerificationRemovePendingAction): ISessionState {
    let nextShophunterData: IShophunterData = state.shophunter.shophunterData
        ? {
            ...state.shophunter.shophunterData,
            verifiedImages: {...state.shophunter.shophunterData.verifiedImages}
        } : {
            verifiedImages: {},
            verifiedProducts: {},
            verifiedMergedProductImages: {},
            latestVerifyCategoryIds: []
        };

    if (nextShophunterData.verifiedImages[action.imageId] != undefined) {
        const imageIndex = nextShophunterData.verifiedImages[action.imageId].findIndex(a => a.verificationType == action.verificationType)
        if (imageIndex != -1) {
            nextShophunterData.verifiedImages[action.imageId][imageIndex].pending = {
                imageStatus: undefined,
                imageType: undefined,
                imageContentType: undefined,
                imageWatermark: undefined,
                imageOverlay: undefined,
                imageVariants: undefined,
            }
        }
    }

    return {
        ...state,
        shophunter: {
            ...state.shophunter,
            shophunterData: {
                ...nextShophunterData
            }
        }
    }
}

function setMergedProductImageVerificationApply(state: ISessionState, action: MergedProductImageVerificationApplyAction): ISessionState {
    let nextShophunterData: IShophunterData = state.shophunter.shophunterData
        ? {
            ...state.shophunter.shophunterData,
            verifiedMergedProductImages: {...state.shophunter.shophunterData.verifiedMergedProductImages}
        } : {
            verifiedImages: {},
            verifiedProducts: {},
            verifiedMergedProductImages: {},
            latestVerifyCategoryIds: []
        };

    for (let i = 0; i < action.mergedProductIds.length; i++) {
        if (nextShophunterData.verifiedMergedProductImages[action.mergedProductIds[i]] !== undefined)
            delete nextShophunterData.verifiedMergedProductImages[action.mergedProductIds[i]]
    }

    return {
        ...state,
        shophunter: {
            ...state.shophunter,
            shophunterData: {
                ...nextShophunterData,
                verifiedMergedProductImages: {...nextShophunterData.verifiedMergedProductImages, ...action.verifications}
            }
        }
    }
}

function setMergedProductImageVerificationSetPending(state: ISessionState, action: MergedProductImageVerificationSetPendingAction): ISessionState {
    let nextShophunterData: IShophunterData = state.shophunter.shophunterData
        ? {
            ...state.shophunter.shophunterData,
            verifiedMergedProductImages: {...state.shophunter.shophunterData.verifiedMergedProductImages}
        } : {
            verifiedImages: {},
            verifiedProducts: {},
            verifiedMergedProductImages: {},
            latestVerifyCategoryIds: []
        };

    if (nextShophunterData.verifiedMergedProductImages[action.mergedProductId] === undefined) {
        nextShophunterData.verifiedMergedProductImages[action.mergedProductId] = [{
            mergedProductId: action.mergedProductId,
            imageId: action.imageId,
            value: {
                imageType: undefined,
                status: undefined,
                isMainImage: undefined,
            },
            pending: {
                imageType: action.imageType,
                isMainImage: action.isMainImage
            }
        }];
    } else {
        const imageIndex = nextShophunterData.verifiedMergedProductImages[action.mergedProductId].findIndex(a => a.imageId == action.imageId)
        if (imageIndex != -1) {
            nextShophunterData.verifiedMergedProductImages[action.mergedProductId][imageIndex].pending = {
                imageType: action.imageType,
                isMainImage: action.isMainImage
            }
        } else {
            nextShophunterData.verifiedMergedProductImages[action.mergedProductId].push({
                mergedProductId: action.mergedProductId,
                imageId: action.imageId,
                value: {
                    imageType: undefined,
                    status: undefined,
                    isMainImage: undefined,
                },
                pending: {
                    imageType: action.imageType,
                    isMainImage: action.isMainImage
                }
            })
        }
    }

    return {
        ...state,
        shophunter: {
            ...state.shophunter,
            shophunterData: {
                ...nextShophunterData
            }
        }
    }
}

function setMergedProductImageVerificationRemovePending(state: ISessionState, action: MergedProductImageVerificationSetPendingAction): ISessionState {
    let nextShophunterData: IShophunterData = state.shophunter.shophunterData
        ? {
            ...state.shophunter.shophunterData,
            verifiedMergedProductImages: {...state.shophunter.shophunterData.verifiedMergedProductImages}
        } : {
            verifiedImages: {},
            verifiedProducts: {},
            verifiedMergedProductImages: {},
            latestVerifyCategoryIds: []
        };

    if (nextShophunterData.verifiedMergedProductImages[action.mergedProductId] != undefined) {
        const imageIndex = nextShophunterData.verifiedMergedProductImages[action.mergedProductId].findIndex(a => a.imageId == action.imageId)
        if (imageIndex != -1) {
            nextShophunterData.verifiedMergedProductImages[action.mergedProductId][imageIndex].pending = {
                imageType: undefined,
                isMainImage: undefined
            }
        }
    }

    return {
        ...state,
        shophunter: {
            ...state.shophunter,
            shophunterData: {
                ...nextShophunterData
            }
        }
    }
}

function shophunterMarkProductImageVerified(state: ISessionState, action: ShophunterMarkMergedProductImageVerifiedAction): ISessionState {
    const nextMergedProductIds = typeof (action.mergedProductId) == "number"
        ? [
            ...state.shophunter.verifiedMergedProductImageMergedProductIds,
            action.mergedProductId
        ]
        : [
            ...state.shophunter.verifiedMergedProductImageMergedProductIds,
            ...action.mergedProductId
        ]

    return {
        ...state,
        shophunter: {
            ...state.shophunter,
            verifiedMergedProductImageMergedProductIds: nextMergedProductIds
        }
    }
}

function shophunterUnmarkProductImageVerified(state: ISessionState, action: ShophunterUnmarkMergedProductImageVerifiedAction): ISessionState {
    return {
        ...state,
        shophunter: {
            ...state.shophunter,
            verifiedMergedProductImageMergedProductIds:
                state.shophunter.verifiedMergedProductImageMergedProductIds.filter(a => a != action.mergedProductId)
        }
    }
}

function shophunterOpenMenu(state: ISessionState): ISessionState {
    return {
        ...state,
        shophunter: {
            ...state.shophunter,
            haveVerificationMenu: true
        }
    }
}

// ---

const sessionReducer = (state: ISessionState = userInitialState,
                        action: ResponseLoginAction
                            | ProductPreviewLoadSuccessAction
                            | RequestDataAction
                            | RequestProductStateAction
                            | RequestProductStateSuccessAction
                            | ProductVerifyAction
                            | ProductVerifyAddAction
                            | ProductVerifyAddData
                            | OpenVerifyProductAction
                            | CloseVerifyProductAction
                            | ImageVerificationApplyAction
                            | ImageVerificationSetPendingAction
                            | ImageVerificationRemovePendingAction
                            | MergedProductImageVerificationApplyAction
                            | MergedProductImageVerificationSetPendingAction
                            | MergedProductImageVerificationRemovePendingAction
                            | ShophunterMarkMergedProductImageVerifiedAction
                            | ShophunterUnmarkMergedProductImageVerifiedAction): ISessionState => {
    switch (action.type) {
        case SESSION_RESPONSE_USER_LOGIN: {
            return sessionResponseUserLogin(state, action as ResponseLoginAction)
        }
        case SESSION_RESPONSE_USER_LOGOUT: {
            return sessionResponseUserLogout(state)
        }

        case SET_PRODUCT_STATE_PENDING: {
            return setProductStatePending(state, action as RequestProductStateAction)
        }
        case SET_PRODUCT_STATE_SUCCESS: {
            return setProductStateSuccess(state, action as RequestProductStateSuccessAction)
        }
        case SET_PRODUCT_STATE_ADD_COMPARE: {
            return setProductStateAddCompare(state, action as RequestSearchAndProductStateAction)
        }
        case SET_PRODUCT_STATE_REMOVE_COMPARE: {
            return setProductStateRemoveCompare(state, action as RequestSearchAndProductStateAction)
        }
        case SET_PRODUCT_STATE_SET_COMPARE: {
            return setProductStateSetCompare(state, action as RequestDataAction)
        }

        case SET_PRODUCT_VERIFY_ADD_PRODUCT: {
            return setProductVerifyAddProduct(state, action as ProductVerifyAddAction)
        }
        case SET_PRODUCT_VERIFY_REMOVE_PRODUCT: {
            return setProductVerifyRemoveProduct(state, action as ProductVerifyAction);
        }
        case SET_PRODUCT_VERIFY_ADD_DATA: {
            return setProductVerifyAddData(state, action as ProductVerifyAddData)
        }
        case SHOPHUNTER_OPEN_VERIFY_PRODUCT: {
            return shophunterOpenVerifyProduct(state, action as OpenVerifyProductAction)
        }
        case SHOPHUNTER_CLOSE_VERIFY_PRODUCT: {
            return shophunterCloseVerifyProduct(state, action as CloseVerifyProductAction)
        }

        case SET_IMAGE_VERIFICATION_APPLY: {
            return setImageVerificationApply(state, action as ImageVerificationApplyAction)
        }
        case SET_IMAGE_VERIFICATION_SET_PENDING: {
            return setImageVerificationSetPending(state, action as ImageVerificationSetPendingAction)
        }
        case SET_IMAGE_VERIFICATION_REMOVE_PENDING: {
            return setImageVerificationRemovePending(state, action as ImageVerificationRemovePendingAction)
        }

        case SET_MERGED_PRODUCT_IMAGE_VERIFICATION_APPLY: {
            return setMergedProductImageVerificationApply(state, action as MergedProductImageVerificationApplyAction)
        }
        case SET_MERGED_PRODUCT_IMAGE_VERIFICATION_SET_PENDING: {
            return setMergedProductImageVerificationSetPending(state, action as MergedProductImageVerificationSetPendingAction)
        }
        case SET_MERGED_PRODUCT_IMAGE_VERIFICATION_REMOVE_PENDING: {
            return setMergedProductImageVerificationRemovePending(state, action as MergedProductImageVerificationSetPendingAction)
        }

        case SHOPHUNTER_MARK_PRODUCT_IMAGE_VERIFIED: {
            return shophunterMarkProductImageVerified(state, action as ShophunterMarkMergedProductImageVerifiedAction)
        }
        case SHOPHUNTER_UNMARK_PRODUCT_IMAGE_VERIFIED: {
            return shophunterUnmarkProductImageVerified(state, action as ShophunterUnmarkMergedProductImageVerifiedAction)
        }

        case SHOPHUNTER_OPEN_MENU: {
            return shophunterOpenMenu(state)
        }

        case LOAD_TRACKING_VISITED: {
            const trackAction = action as TrackingVisitedAction
            return {
                ...state,
                tracking: {
                    ...state.tracking,
                    pageToken: trackAction.newToken,
                    pageUrl: trackAction.newUrl
                }
            }
        }
    }
    return state;
};

export default sessionReducer;