import React from "react";
import {getImageUrl} from "../../../Helpers/ImageHelper";
import {formatPrice} from "../../../Helpers/StringFormatter.Price";

/** @see SearchInput */
export function SearchInputDemo() {
    return <div className="sayt-container mx-auto">
        <div className="sayt-input sayt-input-demonstration">Placeholder Search</div>

        <div className="sayt-result-wrapper demonstration">
            <div className="sayt-result">

                <div className="sayt-result-list">
                    <div className="sayt-result-non-product-list">
                        <div className="sayt-header">Non Product Search Result</div>
                        <div className={`sayt-non-product link-demonstration`}>
                            <div className="sayt-non-product-text">
                                <span className={`manufacturer`}>Manufacturer Search Result</span>
                            </div>
                            <div className="sayt-count">10</div>
                        </div>
                        <div className={`sayt-non-product link-demonstration`}>
                            <div className="sayt-non-product-text">
                                <span className={`branding`}>Brand Search Result</span>
                            </div>
                            <div className="sayt-count">10</div>
                        </div>
                        <div className={`sayt-non-product link-demonstration`}>
                            <div className="sayt-non-product-text">
                                <span className={`branding family`}>Brand Family Search Result</span>
                            </div>
                            <div className="sayt-count">10</div>
                        </div>
                        <div className={`sayt-non-product link-demonstration`}>
                            <div className="sayt-non-product-text">
                                <span className={`collection`}>Collection Search Result</span>
                            </div>
                            <div className="sayt-count">10</div>
                        </div>
                        <div className={`sayt-non-product link-demonstration`}>
                            <div className="sayt-non-product-text">
                                <span className={`category`}>Category Search Result</span>
                            </div>
                            <div className="sayt-count">10</div>
                        </div>
                        <div className={`sayt-non-product link-demonstration`}>
                            <div className="sayt-non-product-text">
                                <span className={`option`}>Option Search Result</span>
                            </div>
                            <div className="sayt-count">10</div>
                        </div>
                        <div className={`sayt-non-product link-demonstration`}>
                            <div className="sayt-non-product-text">
                                <span className={`vendor`}>Vendor Search Result</span>
                            </div>
                            <div className="sayt-count">10</div>
                        </div>
                        <div className={`sayt-non-product link-demonstration`}>
                            <div className="sayt-non-product-text">
                                <span className={`sayt-vendor-domain`}>Vendor Domain Search Result</span>
                            </div>
                            <div className="sayt-count">10</div>
                        </div>
                        <div className={`sayt-non-product link-demonstration`}>
                            <div className="sayt-non-product-text">
                                <span className={`text`}>Text Search Result</span>
                            </div>
                            <div className="sayt-count">10</div>
                        </div>
                        <div className={`sayt-non-product link-demonstration`}>
                            <div className="sayt-non-product-text">
                                <span className={`code`}>Code Search Result</span>
                            </div>
                            <div className="sayt-count">10</div>
                        </div>
                        <div className={`sayt-non-product link-demonstration`}>
                            <div className="sayt-non-product-text">
                                <span className={`synset`}>Synset Search Result</span>
                            </div>
                            <div className="sayt-count">10</div>
                        </div>
                    </div>
                    <div className="sayt-result-product-list">
                        <div className="sayt-header">
                            Products
                        </div>
                        <div className="sayt-product link-demonstration">
                            <div className="sayt-product-image">
                                <img src={getImageUrl(275965264, 'xs')}/>
                            </div>
                            <div className="sayt-product-name">
                                <div className="sayt-product-product-name">
                                    Product Title
                                </div>
                                <div className="sayt-product-category-name">
                                    Product Category
                                </div>
                            </div>
                            <div className="sayt-product-price">
                                <span>{`${formatPrice(1234, 0)} Unit`}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
}