import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ProductImage, ProductImageStateOverlay} from "../App/Common/Image";
import {
    triggerImageVerification_Load,
    triggerImageVerification_MenuOpen
} from "../../Store/Action/session.verifyImage";
import {createPortal} from "react-dom";
import {MenuImageVerification} from "./MenuImageVerification";
import {getMenuContainerNode, getMenuPositionForPoint} from "../App/Layout/MenuContainer";
import {MenuImagePreview} from "./MenuImagePreview";
import {
    triggerMergedProductImageVerification_Load,
    triggerMergedProductImageVerification_Delete,
    triggerMergedProductImageVerification_Save
} from "../../Store/Action/session.verifyMergedProductImage";
import {
    checkNodeParent,
    EVENT_MENU_CLOSE,
    EVENT_MENU_CLOSE_CHECK,
    getEventMenuCloseCheck
} from "../../Helpers/EventHelper";

type MenuMergedProductImageVerificationProps = {
    productId?: number,
    categoryId?: number,
    imageIds: number[],
    productName: string,
    userSessionId?: string,
    x: number,
    y: number,
    closeCallback: () => void | undefined
}

type MenuMergedProductImageVerificationState = {
    estimateWidth: number,
    estimateHeight: number,

    imageVerifyMenuVisible: boolean,
    imageVerifyMenuImageId: number,
    imageVerifyMenuX: number,
    imageVerifyMenuY: number,

    imagePreviewVisible: boolean,
    imagePreviewImageId: number,
    imagePreviewSourceY: number,
    imagePreviewSourceX: number,
    imagePreviewSourceW: number,
    imagePreviewSourceH: number,
}

export function MenuMergedProductImageVerification(props: MenuMergedProductImageVerificationProps) {

    const estimateMenuHeight = 350
    const estimateMenuWidth = 345

    const [state, setState] = useState<MenuMergedProductImageVerificationState>({
        estimateWidth: estimateMenuWidth,
        estimateHeight: estimateMenuHeight,

        imageVerifyMenuVisible: false,
        imageVerifyMenuImageId: 0,
        imageVerifyMenuX: 0,
        imageVerifyMenuY: 0,

        imagePreviewVisible: false,
        imagePreviewImageId: 0,
        imagePreviewSourceX: 0,
        imagePreviewSourceY: 0,
        imagePreviewSourceW: 0,
        imagePreviewSourceH: 0,
    });

    const onContextMenuClose = function () {
        setState({
            estimateWidth: state.estimateWidth,
            estimateHeight: state.estimateHeight,

            imageVerifyMenuVisible: false,
            imageVerifyMenuImageId: 0,
            imageVerifyMenuY: 0,
            imageVerifyMenuX: 0,

            imagePreviewVisible: false,
            imagePreviewImageId: 0,
            imagePreviewSourceX: 0,
            imagePreviewSourceY: 0,
            imagePreviewSourceW: 0,
            imagePreviewSourceH: 0,
        })
    }

    const dispatch = useDispatch();

    const isDebugEnabled = useSelector((state: IAppState) => state.config.debug);
    const verifiedMergedProductImages = useSelector((state: IAppState) => state.session.shophunter.shophunterData?.verifiedMergedProductImages);
    const verifiedImages = useSelector((state: IAppState) => state.session.shophunter.shophunterData?.verifiedImages);
    const currentVerifiedMergedProductImages =
        verifiedMergedProductImages && props.productId && verifiedMergedProductImages[props.productId]
            ? verifiedMergedProductImages[props.productId] : []

    const ref = useRef<HTMLDivElement>(null)

    const onContextMenuCloseCheck = function (e: Event) {
        const clickNode: HTMLElement | undefined = (e as CustomEvent).detail;
        const menuNode: HTMLElement | null = ref.current;
        if (clickNode !== undefined && menuNode) {
            if (!checkNodeParent(clickNode, menuNode)) {
                if (props.closeCallback !== undefined) {
                    props.closeCallback();
                }
            }
        }
    }

    useEffect(() => {

        // attach menu closer
        window.addEventListener(EVENT_MENU_CLOSE, onContextMenuClose)
        window.addEventListener(EVENT_MENU_CLOSE_CHECK, onContextMenuCloseCheck)

        // load missing image verifications
        const missingImageIds = props.imageIds.filter(i => verifiedImages === undefined || verifiedImages[i] === undefined);
        if (missingImageIds.length) {
            dispatch(triggerImageVerification_Load(missingImageIds));
        }

        // load missing merged product image verifications
        if (props.productId) {
            if (verifiedMergedProductImages === undefined || verifiedMergedProductImages[props.productId] === undefined) {
                dispatch(triggerMergedProductImageVerification_Load([props.productId]));
            }
        }

        return () => {
            // detach menu closer when component delete
            window.removeEventListener(EVENT_MENU_CLOSE, onContextMenuClose)
            window.removeEventListener(EVENT_MENU_CLOSE_CHECK, onContextMenuCloseCheck)
        }
    }, [])

    useLayoutEffect(() => {
        // check for size
        if (ref && ref.current) {
            const size = ref.current.getBoundingClientRect();
            setState({
                ...state,
                estimateHeight: size.height,
            })
        }
    }, []);

    const menuLocation = getMenuPositionForPoint(props.x, props.y, state.estimateWidth, state.estimateHeight)

    const triggerVerification = function (imageId: number,
                                          imageType: keyof typeof VERIFICATION_MERGED_PRODUCT_IMAGE_TYPE,
                                          isMainImage: boolean,
                                          isActive: boolean) {
        if (!props.userSessionId || !props.productId) return;

        if (isActive) {
            dispatch(
                triggerMergedProductImageVerification_Delete(
                    props.userSessionId,
                    props.productId,
                    imageId,
                    imageType,
                    isMainImage,
                )
            );
        } else {
            dispatch(
                triggerMergedProductImageVerification_Save(
                    props.userSessionId,
                    props.productId,
                    imageId,
                    imageType,
                    isMainImage,
                    props.categoryId ? props.categoryId : null
                )
            );
        }
    }

    return <div className={`bg-backend-control-gray p-2 js-verification-menu border border-gray-400 shadow-lg`}
                ref={ref}
                style={{
                    position: "fixed",
                    top: `${menuLocation.y}px`,
                    left: `${menuLocation.x}px`,
                    width: `${state.estimateWidth}px`,
                    minHeight: `290px`,
                    maxHeight: `590`,
                    overflowY: "scroll",
                    zIndex: 40
                }} onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        window.dispatchEvent(getEventMenuCloseCheck(e.target as HTMLElement))
    }}>
        <div className={``}>
            {props.imageIds.map((imageId, index) => {

                    const verifiedMergedProductImage = currentVerifiedMergedProductImages
                        .find(v => v.imageId == imageId);
                    const imageValue = verifiedMergedProductImage ? verifiedMergedProductImage.value : undefined;
                    const imagePending = verifiedMergedProductImage ? verifiedMergedProductImage.pending : undefined;

                    const btnClassMain = imagePending && imagePending.isMainImage
                        ? 'btn-ok-to-use-active-pending'
                        : (imageValue && imageValue.isMainImage
                            ? 'btn-ok-to-use-active'
                            : '');

                    const btnClassOkToUse = imagePending && !imagePending.isMainImage && imagePending.imageType === VERIFICATION_MERGED_PRODUCT_IMAGE_TYPE.OK_TO_USE
                        ? 'btn-ok-to-use-active-pending'
                        : (imageValue && !imageValue.isMainImage && imageValue.imageType === VERIFICATION_MERGED_PRODUCT_IMAGE_TYPE.OK_TO_USE
                            ? 'btn-ok-to-use-active'
                            : '');
                    const btnClassDoNotUse = imagePending && imagePending.imageType === VERIFICATION_MERGED_PRODUCT_IMAGE_TYPE.DO_NOT_USE
                        ? 'btn-do-not-use-active-pending'
                        : (imageValue && imageValue.imageType === VERIFICATION_MERGED_PRODUCT_IMAGE_TYPE.DO_NOT_USE
                            ? 'btn-do-not-use-active'
                            : '');

                    const cardClass = imageValue && imageValue.imageType === VERIFICATION_MERGED_PRODUCT_IMAGE_TYPE.OK_TO_USE
                        ? 'green-cell'
                        : imageValue && imageValue.imageType === VERIFICATION_MERGED_PRODUCT_IMAGE_TYPE.DO_NOT_USE
                            ? 'red-cell'
                            : '';

                    return (
                        <div
                            className={`merged-product-image-verification-cell ${index == 0 ? `main-cell` : ``} ${cardClass}`}
                            key={index}>
                            <div className={`flex justify-between border-b`}>
                                <div className={``}>
                                    <div
                                        className={`btn backend-btn backend-btn-mini ${btnClassMain}`}
                                        onClick={(e) => triggerVerification(
                                            imageId,
                                            VERIFICATION_MERGED_PRODUCT_IMAGE_TYPE.OK_TO_USE,
                                            true,
                                            !!btnClassMain
                                        )
                                        }>MAIN
                                    </div>
                                </div>
                                <div>
                                    <div className={`btn backend-btn backend-btn-mini w-4 ${btnClassOkToUse}`}
                                         onClick={(e) => triggerVerification(
                                             imageId,
                                             VERIFICATION_MERGED_PRODUCT_IMAGE_TYPE.OK_TO_USE,
                                             false,
                                             !!btnClassOkToUse
                                         )
                                         }>v
                                    </div>
                                    <div
                                        className={`btn backend-btn backend-btn-mini w-4 ${btnClassDoNotUse}`}
                                        onClick={(e) => triggerVerification(
                                            imageId,
                                            VERIFICATION_MERGED_PRODUCT_IMAGE_TYPE.DO_NOT_USE,
                                            false,
                                            !!btnClassDoNotUse
                                        )
                                        }>x
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`bg-white border relative ${index == 0 ? `w-[198px] h-[175px]` : `w-[97px] h-[73px]`}`}
                                onContextMenu={(e) => {
                                    if (isDebugEnabled && props.productId) {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        setState({
                                            estimateWidth: state.estimateWidth,
                                            estimateHeight: state.estimateHeight,

                                            imageVerifyMenuVisible: true,
                                            imageVerifyMenuImageId: imageId,
                                            imageVerifyMenuX: e.clientX,
                                            imageVerifyMenuY: e.clientY,

                                            imagePreviewVisible: false,
                                            imagePreviewImageId: 0,
                                            imagePreviewSourceX: 0,
                                            imagePreviewSourceY: 0,
                                            imagePreviewSourceW: 0,
                                            imagePreviewSourceH: 0,
                                        })
                                        dispatch(triggerImageVerification_MenuOpen());
                                    }
                                }}
                                onClick={(e) => {
                                    if (isDebugEnabled) {
                                        e.preventDefault()
                                        e.stopPropagation()

                                        const size = e.currentTarget.getBoundingClientRect()

                                        setState({
                                            estimateWidth: state.estimateWidth,
                                            estimateHeight: state.estimateHeight,

                                            imageVerifyMenuVisible: false,
                                            imageVerifyMenuImageId: 0,
                                            imageVerifyMenuX: 0,
                                            imageVerifyMenuY: 0,

                                            imagePreviewVisible: true,
                                            imagePreviewImageId: imageId,
                                            imagePreviewSourceX: size.x,
                                            imagePreviewSourceY: size.y,
                                            imagePreviewSourceW: size.width,
                                            imagePreviewSourceH: size.height,
                                        })
                                        dispatch(triggerImageVerification_MenuOpen());
                                    }
                                }}>
                                <ProductImage size={index == 0 ? 'm' : 's'}
                                              alt={props.productName}
                                              imageClass={`max-w-full max-h-full mx-auto`}
                                              imageId={imageId}/>
                                {verifiedImages && verifiedImages[imageId]
                                    ? <ProductImageStateOverlay verification={verifiedImages[imageId]}/>
                                    : null}
                            </div>
                            <div className={`${index == 0 ? `h-[13px]` : `h-[10px]`}`}></div>
                        </div>
                    )
                }
            )}
        </div>

        {state.imageVerifyMenuVisible && createPortal(
            <MenuImageVerification
                imageId={state.imageVerifyMenuImageId}
                productId={props.productId}
                userSessionId={props.userSessionId}
                verifications={verifiedImages}
                x={state.imageVerifyMenuX}
                y={state.imageVerifyMenuY}
                closeCallback={onContextMenuClose}/>,
            getMenuContainerNode()
        )}

        {state.imagePreviewVisible && createPortal(
            <MenuImagePreview
                imageId={state.imagePreviewImageId}
                alt={props.productName}
                size={`l`}
                sourceX={state.imagePreviewSourceX}
                sourceY={state.imagePreviewSourceY}
                sourceW={state.imagePreviewSourceW}
                sourceH={state.imagePreviewSourceH}
                closeCallback={onContextMenuClose}/>,
            getMenuContainerNode()
        )}

    </div>
}