export const REQUEST_CATEGORY_STRUCTURE = "REQUEST_CATEGORY_STRUCTURE";
export const RESPONSE_CATEGORY_STRUCTURE_SUCCESS = "RESPONSE_CATEGORY_STRUCTURE_SUCCESS";
export const RESPONSE_CATEGORY_STRUCTURE_NOT_FOUND = "RESPONSE_CATEGORY_STRUCTURE_NOT_FOUND";

// ---

export function getCategoryStructureCacheKey(categoryId: number, lang: string): string {
    return `${categoryId}.${lang}`
}

// ---

export type RequestCategoryStructureAction = {
    type: string,
    categoryId: number | undefined
    lang: string
}

export const triggerRequestCategoryStructure = (categoryId: number | undefined, lang: string): RequestCategoryStructureAction => ({
    type: REQUEST_CATEGORY_STRUCTURE,
    categoryId: categoryId,
    lang: lang
})

export type ResponseCategoryStructureAction = {
    type: string,
    categoryId: number | undefined
    lang: string,
    children: IServerSettings_CategoryTreeNode[] | undefined
}

export const triggerResponseCategoryStructureSuccess = (categoryId: number | undefined, lang: string, children: IServerSettings_CategoryTreeNode[] | undefined): ResponseCategoryStructureAction => ({
    type: RESPONSE_CATEGORY_STRUCTURE_SUCCESS,
    categoryId: categoryId,
    lang: lang,
    children: children
})

export const triggerResponseCategoryStructureNotFound = (categoryId: number | undefined, lang: string): ResponseCategoryStructureAction => ({
    type: RESPONSE_CATEGORY_STRUCTURE_NOT_FOUND,
    categoryId: categoryId,
    lang: lang,
    children: undefined
})