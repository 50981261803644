import React from "react";
import {connect, DispatchProp, useSelector} from "react-redux";
import {getDefaultLang} from "../../../Store/Action/interactive";

export default function LoadingOverlayPanel(props: {}) {

    const propsExtend = useSelector((state: IAppState) => ({
        isLoading: state.interactive.showLoadingOverlay,
    }))

    return <div className={`loading-overlay-global-wrapper ${propsExtend.isLoading ? `on-loading` : ``}`}></div>;
}
