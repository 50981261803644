export const LOAD_SAYT = "LOAD_SAYT";
export const LOAD_SAYT_CLEANUP = "LOAD_SAYT_CLEANUP";
export const LOAD_SAYT_SUCCESS = "LOAD_SAYT_SUCCESS";
export const LOAD_SAYT_FAILED = "LOAD_SAYT_FAILED";

export const LOAD_SAYT_FIRST_URL = "LOAD_SAYT_FIRST_URL";

export type SaytAction = {
    type: string,
    search: string,
    lang: string,
    isDebug: boolean,
    detailed?: boolean,
    callback?: (data: ISaytItems) => void
}

export const triggerLoadSayt = (request: string, lang: string, isDebug: boolean, detailed?: boolean, callback?: (data: ISaytItems) => void): SaytAction => ({
    type: LOAD_SAYT,
    search: request,
    lang: lang,
    isDebug: isDebug,
    detailed: detailed,
    callback: callback
});

export type SaytSuccessAction = {
    type: string,
    data: ISaytItems
}

export const triggerLoadSaytSuccess = (data: ISaytItems): SaytSuccessAction => ({
    type: LOAD_SAYT_SUCCESS,
    data: data,
});

export type SaytFailAction = {
    type: string,
}

export const triggerLoadSaytFail = (): SaytFailAction => ({
    type: LOAD_SAYT_FAILED,
});

export type SaytCleanupAction = {
    type: string,
}

export const triggerLoadSaytCleanup = (): SaytCleanupAction => ({
    type: LOAD_SAYT_CLEANUP,
});

export type SaytFirstUrlAction = {
    type: string,
    search: string,
    lang: string,
    isDebug: boolean,
    callback: (data: string | undefined) => void
}

export const triggerLoadSaytFirstUrl = (request: string, lang: string, isDebug: boolean, callback: (data: string | undefined) => void): SaytFirstUrlAction => ({
    type: LOAD_SAYT_FIRST_URL,
    search: request,
    lang: lang,
    isDebug: isDebug,
    callback: callback
});