import {isHeaderSearchVisible} from "../../../Store/StoreHelper";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import SearchInput from "../../SearchInput/SearchInput";
import {getAvatarImageUrl, getLanguageFlagImageUrl} from "../../../Helpers/ImageHelper";
import {Menu} from "@headlessui/react";
import ModalUserSettings from "../../User/ModalUserSettings";
import ModalLogin from "../../Login/ModalLogin";
import ModalRegister from "../../Login/ModalRegister";
import ModalPasswordReset from "../../Login/ModelPasswordReset";
import FiltersBar from "../../ProductList_FiltersBar/FiltersBar";
import React, {MouseEventHandler, useState} from "react";
import {triggerUserLogout} from "../../../Store/Action/session.login";
import {triggerConfigDebugSetCookie, triggerConfigDebugToggle} from "../../../Store/Action/config.debug";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import ModalDebug from "../../ProductList/ModalDebug";
import {triggerConfigLanguageCookieSet, triggerConfigLanguageSet} from "../../../Store/Action/config.localization";

const ComponentTranslationKey = "NavigationBox"

export default function Header() {

    //globals
    const dispatch = useDispatch()
    const location = useLocation()
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = (key: keyof TranslationSet[typeof ComponentTranslationKey]) => {
        return t(key);
    }

    const [state, setState] = useState({
        isLoginOpen: false,
        isRegisterOpen: false,
        isPasswordRecoveryOpen: false,
        isUserSettingsOpen: false,
        isSearchDebugOpen: false,
    })

    // extract global state
    const user = useSelector((state: IAppState) => state.session.user)
    const debugEnabled = useSelector((state: IAppState) => state.config.debug)
    const showHeaderSearch = useSelector((state: IAppState) => isHeaderSearchVisible(state, location.pathname))

    // internal functions
    const onOpenLoginClick: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        if (!user) {
            setState({...state, isLoginOpen: true})
        }
    }

    const onLogoutClick: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        dispatch(triggerUserLogout());
    }

    const onOpenLogin = () => {
        if (!user) {
            setState({...state, isLoginOpen: true, isRegisterOpen: false, isPasswordRecoveryOpen: false})
        }
    }

    const onCloseLogin = () => {
        if (!user) {
            setState({...state, isLoginOpen: false})
        }
    }

    const onOpenSignUp = () => {
        if (!user) {
            setState({...state, isLoginOpen: false, isRegisterOpen: true, isPasswordRecoveryOpen: false})
        }
    }

    const onCloseSignUp = () => {
        if (!user) {
            setState({...state, isRegisterOpen: false})
        }
    }

    const onOpenPasswordRecovery = () => {
        if (!user) {
            setState({...state, isLoginOpen: false, isRegisterOpen: false, isPasswordRecoveryOpen: true})
        }
    }

    const onPasswordRecoverySignUp = () => {
        if (!user) {
            setState({...state, isPasswordRecoveryOpen: false})
        }
    }

    const onToggleDebug: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        dispatch(triggerConfigDebugSetCookie(!debugEnabled));
        dispatch(triggerConfigDebugToggle());
    }

    const onSetLanguage = (language: string) => {
        dispatch(triggerConfigLanguageSet(language));
        dispatch(triggerConfigLanguageCookieSet(language));
    }

    const onOpenUserSettingsClick: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        if (user) {
            setState({...state, isUserSettingsOpen: true})
        }
    }

    const onCloseUserSettings = () => {
        if (user) {
            setState({...state, isUserSettingsOpen: false})
        }
    }

    const imageOnErrorHandler = (e: React.SyntheticEvent) => {
        (e.currentTarget as any).src = getAvatarImageUrl(undefined, '');
    }

    const onSearchDebugModalOpen = () => {
        setState({...state, isSearchDebugOpen: true})
    }
    const onSearchDebugModalClose = () => {
        setState({...state, isSearchDebugOpen: false})
    }

    // render
    const haveUser = !!user

    return <div className="header-wrapper">
        <div className="header-first-row">
            <div className={`relative flex items-center`}>
                <div className="flex-none flex items-center">
                    <div>
                        <Link to="/">
                            <div className="header-logo">Shopit.se</div>
                        </Link>
                    </div>
                </div>

                <div className="flex-1 px-2">
                    <div className={showHeaderSearch ? 'block' : 'block search-three-card:hidden'}>
                        <SearchInput bigHeightSearch={true} preload={undefined}/>
                    </div>
                </div>

                <div className="flex-none">

                    <div className="flex">

                        {haveUser ?
                            <div className={`hidden search-three-card:block`}>
                                <Link to={"/user/" + user?.id}
                                      className="relative inline-block text-left">
                                            <span
                                                className="text-gray-500">{user?.firstName + ' ' + user?.lastName}</span>
                                    <img src={getAvatarImageUrl(user?.id)}
                                         className="w-12 h-12 inline-block rounded-full border ml-2"
                                         onError={imageOnErrorHandler}
                                         alt={user?.firstName || ""}/>
                                </Link>
                            </div> : null}

                        {haveUser ?
                            <div>
                                <Menu as="div" className="relative inline-block text-left">
                                    <Menu.Button>
                                        <div className="w-12 h-12 inline-block ml-2 p-2">
                                            <img src="/resources/menu-icon.png" alt="Menu"/>
                                        </div>
                                    </Menu.Button>
                                    <Menu.Items
                                        className="z-50 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="px-1 py-1">

                                            <div className={`block mb-4 search-three-card:hidden`}>
                                                <Menu.Item>
                                                    {({active}) => (
                                                        <Link to={"/user/" + user?.id}
                                                              className={`block text-shopit-green ${active && 'bg-shopit-green text-white'} rounded py-1 px-2 cursor-pointer`}>
                                                            {user?.firstName + ' ' + user?.lastName}
                                                        </Link>
                                                    )}
                                                </Menu.Item>
                                            </div>

                                            <Menu.Item>
                                                {({active}) => (
                                                    <div onClick={onOpenUserSettingsClick}
                                                         className={`block text-shopit-green ${active && 'bg-shopit-green text-white'} rounded py-1 px-2 cursor-pointer`}>
                                                        {getTranslation("settings")}
                                                    </div>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({active}) => (
                                                    <div
                                                        className={`block text-gray-200 ${active && 'bg-shopit-green text-white'} rounded py-1 px-2 cursor-pointer`}>
                                                        {getTranslation("manager_settings")}
                                                    </div>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({active}) => (
                                                    <div
                                                        className={`block text-shopit-green ${active && 'bg-shopit-green text-white'} rounded py-1 px-2 cursor-pointer`}
                                                        onClick={onLogoutClick}>
                                                        {getTranslation("logout")}
                                                    </div>
                                                )}
                                            </Menu.Item>
                                            <div className={`m-1 border-t border-gray-300`}></div>
                                            <Menu.Item>
                                                {({active}) => (

                                                    <div
                                                        className={`block text-shopit-green ${active && 'bg-shopit-green text-white'} rounded py-1 px-2 cursor-pointer`}
                                                        onClick={() => onSetLanguage('sv')}>
                                                        <img className={`inline-block`}
                                                             src={getLanguageFlagImageUrl('sv')} alt="Svenska"/> Svenska
                                                    </div>
                                                )}
                                            </Menu.Item>
                                            <Menu.Item>
                                                {({active}) => (
                                                    <div
                                                        className={`block text-shopit-green ${active && 'bg-shopit-green text-white'} rounded py-1 px-2 cursor-pointer`}
                                                        onClick={() => onSetLanguage('en')}>
                                                        <img className={`inline-block`}
                                                             src={getLanguageFlagImageUrl('en')} alt="English"/> English
                                                    </div>
                                                )}
                                            </Menu.Item>

                                            {user?.isTrusted
                                                ? <Menu.Item>
                                                    {({active}) => (
                                                        <div
                                                            className={`block mt-4 text-shopit-green ${active && 'bg-shopit-green text-white'} rounded py-1 px-2 cursor-pointer`}
                                                            onClick={onToggleDebug}>
                                                            {debugEnabled ? '[v] ' : '[ ] '}{getTranslation("show_debug")}
                                                        </div>
                                                    )}
                                                </Menu.Item>
                                                : null}
                                        </div>
                                    </Menu.Items>
                                </Menu>
                            </div> : null}

                        {!haveUser ?
                            <div>
                                <div className="pl-2 cursor-pointer" onClick={onOpenLoginClick}>

                                        <span className="text-gray-500 hidden search-three-card:inline-block">
                                            {getTranslation("login")}
                                        </span>
                                    <img src="/resources/no-profile-img.png"
                                         className="w-12 h-12 inline-block rounded-full border ml-2"
                                         alt="Login user"/>
                                </div>
                            </div> : null}

                    </div>

                    {haveUser ?
                        <ModalUserSettings isModalOpen={state.isUserSettingsOpen}
                                           onModalClose={onCloseUserSettings}/>
                        : null}

                    {!haveUser ?
                        <ModalLogin isModalOpen={state.isLoginOpen}
                                    onModalClose={onCloseLogin}
                                    onSignUpClick={onOpenSignUp}
                                    onForgotPasswordClick={onOpenPasswordRecovery}
                        /> : null}

                    {!haveUser ?
                        <ModalRegister isModalOpen={state.isRegisterOpen}
                                       onModalClose={onCloseSignUp}
                                       onBackToLogin={onOpenLogin}/>
                        : null}

                    {!haveUser ?
                        <ModalPasswordReset isModalOpen={state.isPasswordRecoveryOpen}
                                            onModalClose={onPasswordRecoverySignUp}
                                            onSignInClick={onOpenLogin}
                                            onSignUpClick={onOpenSignUp}/>
                        : null}

                    {state.isSearchDebugOpen
                        ? <ModalDebug isModalOpen={true} onClose={onSearchDebugModalClose}/>
                        : null}

                </div>
            </div>
        </div>

        <div className={`header-second-row`}>
            <FiltersBar onSearchDebugClick={onSearchDebugModalOpen}/>
        </div>
    </div>
}