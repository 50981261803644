import React, {PropsWithChildren} from "react";
import {Link} from "react-router-dom";
import {Disclosure} from "@headlessui/react";
import {IconChevronDown, IconChevronRight} from "../../App/Icons";

export function DemonstrationGroupFullscreen(props: PropsWithChildren<{ header: string }>) {
    return <div>
        <Disclosure defaultOpen={true}>
            {({open}) => (
                <>
                    <div className="container max-w-screen-lg mx-auto" key={`btn`}>
                        <Disclosure.Button className="mt-4 text-xl">
                            {open
                                ? <IconChevronDown style={{width: '1.5em', display: 'inline'}}/>
                                : <IconChevronRight style={{width: '1.5em', display: 'inline'}}/>
                            }
                            {props.header}
                        </Disclosure.Button>
                    </div>
                    <Disclosure.Panel className="mt-4 container mx-auto" key={`pnl`}>
                        {props.children}
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    </div>
}

export function DemonstrationGroup(props: PropsWithChildren<{ header: string }>) {
    return (
        <div>
            <div className="container max-w-screen-lg mx-auto">
                <Disclosure defaultOpen={true}>
                    {({open}) => (
                        <>
                            <Disclosure.Button className="mt-4 text-xl" key={`btn`}>
                                {open
                                    ? <IconChevronDown style={{width: '1.5em', display: 'inline'}}/>
                                    : <IconChevronRight style={{width: '1.5em', display: 'inline'}}/>
                                }
                                {props.header}
                            </Disclosure.Button>
                            <Disclosure.Panel className="mt-4" key={`pnl`}>
                                {props.children}
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            </div>
        </div>);
}

export function DemonstrationMenu() {
    return <div>
        <div className="container max-w-screen-lg mx-auto relative">
            <div className={`absolute top-0 -left-64 w-64 pl-4`}>
                <div className={`mt-4`}>Related Links</div>
                <div>
                    <Link to={"/service/typography"} className={`text-shopit-green`}>Typography</Link>
                </div>
                <div>
                    <Link to={"/service/layout"} className={`text-shopit-green`}>Layout</Link>
                </div>
                <div className={`mt-8`}>
                    <a href="https://tailwindcss.com/docs/font-size" target={"_blank"}
                       className={`text-shopit-green`}>Framework Font Size</a>
                </div>
                <div>
                    <a href="https://tailwindcss.com/docs/customizing-colors" target={"_blank"}
                       className={`text-shopit-green`}>Framework Colors</a>
                </div>
                <div>
                    <a href="https://tailwindcss.com/docs/customizing-spacing" target={"_blank"}
                       className={`text-shopit-green`}>Framework Sizes</a>
                </div>
            </div>
        </div>
    </div>
}