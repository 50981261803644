import React from "react";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {useTranslation} from "react-i18next";

type ManufacturerSeparatorProps = {
    id: number,
    name: string,
};

const ComponentTranslationKey = "Separator"

export default function ManufacturerContextSeparator(props: ManufacturerSeparatorProps) {
    // globals
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    return (
        <span className="category-item-separator flex flex-col text-shopit-blue">
            <div className="whitespace-nowrap card-separator-text-rotation flex-none flex justify-center">
                <div className="inline-block">
                    {props.id
                        ? props.name
                        : getTranslation('other_manufacturers')}
                </div>
            </div>
        </span>
    );
}
