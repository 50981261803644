import React, {ReactElement} from "react";
import {FourProductImages, ProductImage} from "../../App/Common/Image";
import SearchRouteLink from "../../App/Links/SearchRouteLink";
import {To} from "react-router-dom";

type SaytCardProps = {
    children: ReactElement | ReactElement[],
    text: string,
    className?: string,
    to: To,
    imageIds: number[],
};

/** @see CollectionCardDemo */
export default function SaytCard(props: SaytCardProps) {

    return (
        <div className={`category-item-card category-item-card-category ${props.className}`}>

            <SearchRouteLink
                to={props.to}
                className="card-section-rigid p-2">
                <div className="card-section-image">
                    {props.imageIds.length == 1
                        ? <ProductImage imageId={props.imageIds[0]}
                                        alt={props.text}
                                        size="m"
                                        imageClass={`image-aspect-ratio-square mix-blend-darken`}/>
                        : <FourProductImages imageIds={props.imageIds}
                                             alt={props.text}
                                             size="m" squareSize={true}
                                             imageSize="s" imageClass="image-aspect-ratio-square mix-blend-darken"/>
                    }
                </div>
            </SearchRouteLink>

            <SearchRouteLink to={props.to} className="card-section-rigid flex flex-col">
                {props.children}
            </SearchRouteLink>

        </div>
    );
}

