import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";
import {MetaTags} from "../../Components/App/Tags/Meta";

// ---

const ComponentTranslationKey = "Technology"

export default function TechnologyRoute(props: {}) {

    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={`service-page-container pt-16`}>

            <MetaTags title={getTranslation('technologyTitle')}
                      canonicalPageUrl={`default`}
                      robots={undefined}/>

            <h1 className={`service-page-h1`}>{getTranslation('ourUniqueTechnology')}</h1>

            <p className={`service-page-block`}>
                {getTranslation("readMore")} <a href={`https://shopedia.com`} target={`_blank`} className={`text-shopit-green`}>shopedia.com</a>
            </p>


        </div>
    );
}
