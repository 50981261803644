import React, {CSSProperties} from "react";

export function IconShoppingCard(props: { style: CSSProperties }) {
    return <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#000000"
                style={props.style}>
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier"><title>Shopping-cart</title>
            <g id="shop-Landing" stroke="none" strokeWidth="1" fill="none"
               fillRule="evenodd">
                <g id="Artboard" transform="translate(-74.000000, -239.000000)">
                    <g id="Shopping-cart" transform="translate(74.000000, 239.000000)">
                        <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                        <path
                            d="M2.5,3.5 L4.57364,3.5 C4.81929,3.5 5.02855,3.67844 5.06736,3.921 L6.73058,14.3158 C6.88582,15.286 7.72287,15.9998 8.70546,15.9998 L17.3957,15.9998 C18.3331,15.9998 19.1447,15.3487 19.3481,14.4337 L20.7296,8.21674 C20.8684,7.59222 20.3932,6.9998 19.7534,6.9998 L5.83997,6.9998"
                            id="Path" stroke="#0C0310" strokeWidth="2"
                            strokeLinecap="round"></path>
                        <circle id="Oval" stroke="#0C0310" strokeWidth="2"
                                strokeLinecap="round" cx="9.5" cy="21" r="1"></circle>
                        <circle id="Oval2" stroke="#0C0310" strokeWidth="2"
                                strokeLinecap="round" cx="16.5" cy="21" r="1"></circle>
                    </g>
                </g>
            </g>
        </g>
    </svg>
}

export function IconDebug(props: { style: CSSProperties }) {
    return <svg fill="#000000" viewBox="0 0 32 32" id="icon" xmlns="http://www.w3.org/2000/svg"
                style={props.style}>
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <title>debug</title>
            <path
                d="M29.83,20l.34-2L25,17.15V13c0-.08,0-.15,0-.23l5.06-1.36-.51-1.93-4.83,1.29A9,9,0,0,0,20,5V2H18V4.23a8.81,8.81,0,0,0-4,0V2H12V5a9,9,0,0,0-4.71,5.82L2.46,9.48,2,11.41,7,12.77c0,.08,0,.15,0,.23v4.15L1.84,18l.32,2L7,19.18a8.9,8.9,0,0,0,.82,3.57L3.29,27.29l1.42,1.42,4.19-4.2a9,9,0,0,0,14.2,0l4.19,4.2,1.42-1.42-4.54-4.54A8.9,8.9,0,0,0,25,19.18ZM15,25.92A7,7,0,0,1,9,19V13h6ZM9.29,11a7,7,0,0,1,13.42,0ZM23,19a7,7,0,0,1-6,6.92V13h6Z"></path>
            <rect fill="none" width="32" height="32"></rect>
        </g>
    </svg>
}

// https://www.svgrepo.com/svg/491582/caret-down
export function IconChevronDown(props: { style: CSSProperties, fill?: string }) {
    return <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                style={props.style}>
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M6.29289 8.79289C6.68342 8.40237 7.31658 8.40237 7.70711 8.79289L12 13.0858L16.2929 8.79289C16.6834 8.40237 17.3166 8.40237 17.7071 8.79289C18.0976 9.18342 18.0976 9.81658 17.7071 10.2071L12.7071 15.2071C12.3166 15.5976 11.6834 15.5976 11.2929 15.2071L6.29289 10.2071C5.90237 9.81658 5.90237 9.18342 6.29289 8.79289Z"
                  fill={props.fill ? props.fill : `#000000`}></path>
        </g>
    </svg>
}

// https://www.svgrepo.com/svg/491582/caret-right
export function IconChevronRight(props: { style: CSSProperties, fill?: string }) {
    return <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                style={props.style}>
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.79289 6.29289C9.18342 5.90237 9.81658 5.90237 10.2071 6.29289L15.2071 11.2929C15.5976 11.6834 15.5976 12.3166 15.2071 12.7071L10.2071 17.7071C9.81658 18.0976 9.18342 18.0976 8.79289 17.7071C8.40237 17.3166 8.40237 16.6834 8.79289 16.2929L13.0858 12L8.79289 7.70711C8.40237 7.31658 8.40237 6.68342 8.79289 6.29289Z"
                  fill={props.fill ? props.fill : `#000000`}></path>
        </g>
    </svg>
}

// https://www.svgrepo.com/svg/491582/caret-left
export function IconChevronLeft(props: { style: CSSProperties, fill?: string }) {
    return <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                style={props.style}>
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15.2071 6.29289C15.5976 6.68342 15.5976 7.31658 15.2071 7.70711L10.9142 12L15.2071 16.2929C15.5976 16.6834 15.5976 17.3166 15.2071 17.7071C14.8166 18.0976 14.1834 18.0976 13.7929 17.7071L8.79289 12.7071C8.40237 12.3166 8.40237 11.6834 8.79289 11.2929L13.7929 6.29289C14.1834 5.90237 14.8166 5.90237 15.2071 6.29289Z"
                  fill={props.fill ? props.fill : `#000000`}></path>
        </g>
    </svg>
}

// https://www.svgrepo.com/svg/491582/caret-left
export function IconChevronUp(props: { style: CSSProperties }) {
    return <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                style={props.style}>
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M11.2929 8.79289C11.6834 8.40237 12.3166 8.40237 12.7071 8.79289L17.7071 13.7929C18.0976 14.1834 18.0976 14.8166 17.7071 15.2071C17.3166 15.5976 16.6834 15.5976 16.2929 15.2071L12 10.9142L7.70711 15.2071C7.31658 15.5976 6.68342 15.5976 6.29289 15.2071C5.90237 14.8166 5.90237 14.1834 6.29289 13.7929L11.2929 8.79289Z"
                  fill="#000000"></path>
        </g>
    </svg>
}

// https://www.svgrepo.com/svg/347875/unfold
export function IconUnfold(props: { style: CSSProperties, rotate: number | undefined, color: string }) {
    return <svg fill={props.color} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                style={props.style} transform={props.rotate ? "rotate(" + props.rotate + ")" : undefined}>
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <path fillRule="evenodd"
                  d="M12 23a.75.75 0 01-.53-.22l-3.25-3.25a.75.75 0 111.06-1.06L12 21.19l2.72-2.72a.75.75 0 111.06 1.06l-3.25 3.25A.75.75 0 0112 23z"></path>
            <path fillRule="evenodd"
                  d="M12 22.25a.75.75 0 01-.75-.75v-5.75a.75.75 0 011.5 0v5.75a.75.75 0 01-.75.75zM10.75 12a.75.75 0 01.75-.75h1a.75.75 0 110 1.5h-1a.75.75 0 01-.75-.75zm-8 0a.75.75 0 01.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.75zm12 0a.75.75 0 01.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.75zm-8 0a.75.75 0 01.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.75zm12 0a.75.75 0 01.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.75zM11.47 1.22a.75.75 0 011.06 0l3.25 3.25a.75.75 0 01-1.06 1.06L12 2.81 9.28 5.53a.75.75 0 01-1.06-1.06l3.25-3.25z"></path>
            <path fillRule="evenodd" d="M12 1.5a.75.75 0 01.75.75v6a.75.75 0 01-1.5 0v-6A.75.75 0 0112 1.5z"></path>
        </g>
    </svg>
}

// https://www.svgrepo.com/svg/347747/fold
export function IconFold(props: { style: CSSProperties, rotate: number | undefined, color: string }) {
    return <svg fill={props.color} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                style={props.style} transform={props.rotate ? "rotate(" + props.rotate + ")" : undefined}>
        <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
        <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <path fillRule="evenodd"
                  d="M12 15a.75.75 0 01.53.22l3.25 3.25a.75.75 0 11-1.06 1.06L12 16.81l-2.72 2.72a.75.75 0 01-1.06-1.06l3.25-3.25A.75.75 0 0112 15z"></path>
            <path fillRule="evenodd"
                  d="M12 15.75a.75.75 0 01.75.75v5.75a.75.75 0 01-1.5 0V16.5a.75.75 0 01.75-.75zm.53-6.97a.75.75 0 01-1.06 0L8.22 5.53a.75.75 0 011.06-1.06L12 7.19l2.72-2.72a.75.75 0 111.06 1.06l-3.25 3.25z"></path>
            <path fillRule="evenodd"
                  d="M12 8.5a.75.75 0 01-.75-.75v-6a.75.75 0 011.5 0v6a.75.75 0 01-.75.75zM10.75 12a.75.75 0 01.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.75zm-8 0a.75.75 0 01.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.75zm12 0a.75.75 0 01.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.75zm-8 0a.75.75 0 01.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.75zm12 0a.75.75 0 01.75-.75h1a.75.75 0 010 1.5h-1a.75.75 0 01-.75-.75z"></path>
        </g>
    </svg>
}

// https://www.svgrepo.com/svg/361580/radiobutton
export function IconRadioButton(props: { style: CSSProperties, color: string }) {
    return <svg style={props.style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.49985 0.877045C3.84216 0.877045 0.877014 3.84219 0.877014 7.49988C0.877014 11.1575 3.84216 14.1227 7.49985 14.1227C11.1575 14.1227 14.1227 11.1575 14.1227 7.49988C14.1227 3.84219 11.1575 0.877045 7.49985 0.877045ZM1.82701 7.49988C1.82701 4.36686 4.36683 1.82704 7.49985 1.82704C10.6328 1.82704 13.1727 4.36686 13.1727 7.49988C13.1727 10.6329 10.6328 13.1727 7.49985 13.1727C4.36683 13.1727 1.82701 10.6329 1.82701 7.49988ZM7.49999 9.49999C8.60456 9.49999 9.49999 8.60456 9.49999 7.49999C9.49999 6.39542 8.60456 5.49999 7.49999 5.49999C6.39542 5.49999 5.49999 6.39542 5.49999 7.49999C5.49999 8.60456 6.39542 9.49999 7.49999 9.49999Z"
            fill={props.color}
        />
    </svg>
}

export function IconRadioButtonOff(props: { style: CSSProperties, color: string }) {
    return <svg style={props.style} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.877075 7.49991C0.877075 3.84222 3.84222 0.877075 7.49991 0.877075C11.1576 0.877075 14.1227 3.84222 14.1227 7.49991C14.1227 11.1576 11.1576 14.1227 7.49991 14.1227C3.84222 14.1227 0.877075 11.1576 0.877075 7.49991ZM7.49991 1.82708C4.36689 1.82708 1.82708 4.36689 1.82708 7.49991C1.82708 10.6329 4.36689 13.1727 7.49991 13.1727C10.6329 13.1727 13.1727 10.6329 13.1727 7.49991C13.1727 4.36689 10.6329 1.82708 7.49991 1.82708Z"
            fill={props.color}
        />
    </svg>
}

// https://www.svgrepo.com/svg/393208/triangle-up-filled
export function IconTriChevronUp(props: { style: CSSProperties, color: string }) {
    return <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
        <path
            d="M11.272 5.205L16.272 13.205C16.8964 14.2041 16.1782 15.5 15 15.5H5.00002C3.82186 15.5 3.1036 14.2041 3.72802 13.205L8.72802 5.205C9.31552 4.265 10.6845 4.265 11.272 5.205Z"
            fill={props.color}/>
    </svg>
}

// https://www.svgrepo.com/svg/393200/triangle-down-filled
export function IconTriChevronDown(props: { style: CSSProperties, color: string }) {
    return <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
        <path
            d="M8.72798 15.795L3.72798 7.795C3.10356 6.79593 3.82183 5.5 4.99998 5.5L15 5.5C16.1781 5.5 16.8964 6.79593 16.272 7.795L11.272 15.795C10.6845 16.735 9.31549 16.735 8.72798 15.795Z"
            fill={props.color}/>
    </svg>
}

// https://www.svgrepo.com/svg/393204/triangle-right-filled
export function IconTriChevronRight(props: { style: CSSProperties, color: string }) {
    return <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
        <path
            d="M15.795 11.272L7.795 16.272C6.79593 16.8964 5.5 16.1782 5.5 15L5.5 5.00002C5.5 3.82186 6.79593 3.1036 7.795 3.72802L15.795 8.72802C16.735 9.31552 16.735 10.6845 15.795 11.272Z"
            fill={props.color}/>
    </svg>
}

// https://www.svgrepo.com/svg/393204/triangle-right-filled
export function IconTriChevronLeft(props: { style: CSSProperties, color: string }) {
    return <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={props.style}>
        <path
            d="M4.205 8.72805L12.205 3.72805C13.2041 3.10363 14.5 3.82189 14.5 5.00004V15C14.5 16.1782 13.2041 16.8965 12.205 16.272L4.205 11.272C3.265 10.6845 3.265 9.31555 4.205 8.72805Z"
            fill={props.color}/>
    </svg>
}