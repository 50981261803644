import React from "react";
import {connect, DispatchProp} from 'react-redux';
import {DomainImage} from "../../App/Common/Image";
import {useTranslation, withTranslation, WithTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";

type ManufacturerCardProps = {
    name: string
    imageId: number | undefined,

    className?: string,
};

const ComponentTranslationKey = "Item"

/** @see ManufacturerCardDemo */

export default function ManufacturerCard(props: ManufacturerCardProps) {
    // globals
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    return (
        <div className={`category-item-card category-item-card-manufacturer ${props.className}`}>
            <div className="card-section-rigid p-2 text-gray-500">
                <div className="flex justify-between text-manufacturer-blue">
                    <div>{props.name}</div>
                    <div>{getTranslation('manufacturer')}</div>
                </div>
            </div>

            <div className="card-section-rigid p-2">
                <div className="card-section-image">
                    <DomainImage size="m" alt={props.name} domainId={props.imageId}/>
                </div>
            </div>

            <div className="card-section-stretch px-4">
            </div>
        </div>
    );
}