import React from "react";
import {useSearchParams} from "react-router-dom";
import SearchDetailsRoute from "../SearchDetails";
import SearchRoute from "../Search";

export default function SearchSelectRoute() {
    const [searchParams] = useSearchParams()
    if (searchParams.has("q")) {
        return <SearchDetailsRoute/>
    }
    return <SearchRoute/>
}