import UserHeader from "./UserHeader";
import React, {useEffect} from "react";
import {triggerLoadUser} from "../../Store/Action/cache.user";
import {getCompareProductIds} from "../../Helpers/LocalStorageHelper";
import {
    triggerLoadProductsComparePreview,
    triggerLoadProductsLikePreview,
    triggerLoadProductsOwnPreview
} from "../../Store/Action/session.product";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {TranslationSet} from "../../Localization/i18n.constants";
import {staticCast} from "../../Helpers/TypeHelper";
import ProductGrid from "../ProductsGrid";
import SearchRouteLink from "../App/Links/SearchRouteLink";
import ProductsCompareButton from "../ProductList_FiltersBar/FiltersBar/CompareButton";
import {MetaTags} from "../App/Tags/Meta";
import {buildSearchCategoryUrl, buildSearchUrl} from "../../Helpers/UrlFormatter.Search";

const ComponentTranslationKey = "User.Page.Boards"

export default function UserProductListRoute() {

    // global
    const dispatch = useDispatch()
    const params: Readonly<Partial<{ user_id: string, type: "liked" | "owned" | "compare" }>> = useParams();
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = (key: keyof TranslationSet["User"]["Page"]["Boards"]) => {
        return t('User:Page:Boards:' + key)
    }

    // extract global state
    const propsExtension = useSelector((state: IAppState) => {
        const userId = params.user_id ? parseInt(params.user_id) : -1;
        const isCurrentUser = state.session.user?.id == userId

        const targetProductIds = isCurrentUser ? (
            params.type == "liked"
                ? state.session.likedProducts.productIds
                : params.type == "owned"
                    ? state.session.ownerProducts.productIds
                    : getCompareProductIds()
        ) : undefined

        const targetList = isCurrentUser ? (
            params.type == "liked"
                ? state.cache.personalProducts.likeProductsPreview
                : params.type == "owned"
                    ? state.cache.personalProducts.ownProductsPreview
                    : state.cache.personalProducts.compareProductsPreview
        ) : undefined

        const productsList: IProductList | undefined = targetList && targetList.object
            ? staticCast<IProductList>({
                list: targetList.object,
                pagination: {itemCount: 0, itemPerPage: 0, itemOffset: 0},
                title: {
                    name: undefined,
                    titleType: IProductList_TitleType.Category,
                    details: {
                        searchString: "",
                        category: undefined
                    }
                }
            })
            : undefined;

        return {
            userId: params.user_id,
            type: params.type,

            user: state.cache.users[userId] ? state.cache.users[userId].object : undefined,
            productIds: targetProductIds,
            list: productsList,
            isCurrentUser: state.session.user?.id == userId
        }
    })

    // lifecycle functions
    useEffect(() => {
        if (propsExtension.isCurrentUser) {
            if (propsExtension.user === undefined && propsExtension.userId) {
                const user_id = parseInt(propsExtension.userId);
                dispatch(triggerLoadUser(user_id));
            }

            if (propsExtension.type == "compare" && !propsExtension.list) {
                let productIds = getCompareProductIds();
                if (productIds.length) {
                    dispatch(triggerLoadProductsComparePreview(productIds, 'sv'));
                }
            }

            if (propsExtension.type == "liked" && !propsExtension.list) {
                if (propsExtension.productIds && propsExtension.productIds.length) {
                    dispatch(triggerLoadProductsLikePreview(propsExtension.productIds, 'sv'));
                }
            }

            if (propsExtension.type == "owned" && !propsExtension.list) {
                if (propsExtension.productIds && propsExtension.productIds.length) {
                    dispatch(triggerLoadProductsOwnPreview(propsExtension.productIds, 'sv'));
                }
            }
        }
    }, []);

    // render
    const renderList = () => {
        return <ProductGrid categoryItems={propsExtension.list}
                            topLevelAsBreadcrumbs={true}
                            showEmptyMessage={false}
                            useCardStyleBorders={false}
                            urlPageParams={{
                                bridgeParams: {
                                    categoryId: undefined,
                                    language: undefined,
                                    color: undefined,
                                    filters: undefined,
                                    expand: undefined,
                                    expandedBrands: undefined,
                                    secondaryExpand: undefined,
                                    showSecondary: undefined,
                                    fold: undefined,
                                    foldedBrands: undefined,
                                    page: undefined,
                                    size: undefined,
                                    text: undefined,
                                    code: undefined,
                                    group: undefined,
                                    sort: undefined,
                                },
                                showFilters: undefined,
                                openFilterOption: undefined,
                                openUnpOptions: undefined,
                            }}/>
    }

    const renderCategory = (categoryId: number, categoryName: string | undefined, url: string | undefined, productIds: number[] | undefined) => {
        return <div className={`cards-list-container mt-8 flex justify-between pb-4`}>
            <div className={`w-full border-b pb-2`}>
                <img src="/resources/icon-category-green.png" alt={categoryName || ""} className={`inline-block mr-2`}/>
                {url
                    ? <SearchRouteLink
                        className="text-shopit-green text-xl font-bold"
                        to={url}>
                        {categoryName}
                    </SearchRouteLink>
                    : <span className="text-shopit-green text-xl font-bold">{categoryName}</span>
                }
            </div>

            <div className={`ml-2`}>
                {productIds
                    ? <ProductsCompareButton productIds={productIds}/>
                    : null}
            </div>
        </div>
    }

    const renderCompareList = () => {
        if (!propsExtension.list)
            return null;

        let categoryData: { [categoryId: number]: { name: string, url: string, productIds: number[] } } = {};
        let compareLists: { [categoryId: number]: IProductList } = {};
        for (let i = 0; i < propsExtension.list.list.length; i++) {

            if (propsExtension.list.list[i].itemType == IProductList_ItemType.SeparatorCategory) {
                const categorySeparator = propsExtension.list.list[i] as IProductListItem_SeparatorCategory;
                categoryData[categorySeparator.categoryId] = {
                    name: categorySeparator.categoryName,
                    url: buildSearchCategoryUrl(categorySeparator.categoryId, categorySeparator.categoryName),
                    productIds: []
                }
            } else if (propsExtension.list.list[i].itemType == IProductList_ItemType.TopLevelSeparator) {
                const categorySeparator = propsExtension.list.list[i] as IProductListItem_TopLevelSeparator;
                categoryData[categorySeparator.categoryId] = {
                    name: categorySeparator.categoryName,
                    url: buildSearchCategoryUrl(categorySeparator.categoryId, categorySeparator.categoryName),
                    productIds: []
                }
            }

            if (propsExtension.list.list[i].itemType == IProductList_ItemType.Product) {
                const productCard = propsExtension.list.list[i] as IProductListItem_Product;

                if (compareLists[productCard.product.category_id] === undefined) {
                    compareLists[productCard.product.category_id] = {
                        list: [],
                        title: {
                            name: "Category #" + productCard.product.category_id,
                            titleType: IProductList_TitleType.Category,
                            details: {
                                searchString: "",
                                category: undefined,
                            }
                        },
                        pagination: {itemCount: 0, itemPerPage: 0, itemOffset: 0},
                    };
                }

                compareLists[productCard.product.category_id].list.push(productCard)
                if (categoryData[productCard.product.category_id]) {
                    categoryData[productCard.product.category_id].productIds.push(productCard.product.id)
                }
            }
        }

        let activeCategoryIds = Object.keys(compareLists);

        return activeCategoryIds.map((idString, index) => {
            const categoryId = parseInt(idString);

            return <div key={`key-${index}`}>

                {renderCategory(categoryId, categoryData[categoryId]?.name, categoryData[categoryId]?.url, categoryData[categoryId]?.productIds)}

                <ProductGrid categoryItems={compareLists[categoryId]}
                             topLevelAsBreadcrumbs={true}
                             showEmptyMessage={false}
                             useCardStyleBorders={false}
                             urlPageParams={{
                                 bridgeParams: {
                                     categoryId: undefined,
                                     language: undefined,
                                     color: undefined,
                                     filters: undefined,
                                     expand: undefined,
                                     expandedBrands: undefined,
                                     secondaryExpand: undefined,
                                     showSecondary: undefined,
                                     fold: undefined,
                                     foldedBrands: undefined,
                                     page: undefined,
                                     size: undefined,
                                     text: undefined,
                                     code: undefined,
                                     group: undefined,
                                     sort: undefined,
                                 },
                                 showFilters: undefined,
                                 openFilterOption: undefined,
                                 openUnpOptions: undefined,
                             }}/>

            </div>
        });
    }

    return <div>

        <MetaTags title={`Shopit`}
                  canonicalPageUrl={`default`}
                  robots={`noindex`}/>

        <UserHeader user={propsExtension.user}/>

        <div className="container mx-auto">
            {propsExtension.list
                ? propsExtension.type == "compare"
                    ? renderCompareList()
                    : renderList()
                : null}
        </div>
    </div>;
}