import FilterBarNumericButton from "../FilterBarNumericButton";
import FilterBarAttributeButton from "../FilterBarAttributeButton";
import FilterBarColorButton from "../FilterBarColorButton";
import FilterBarTextButton from "../FilterBarTextButton";
import FilterBarCodeButton from "../FilterBarCodeButton";
import FilterBarBrandButton from "../FilterBarBrandButton";
import FilterBarManufacturerButton from "../FilterBarManufacturerButton";
import FilterBarFamilyButton from "../FilterBarFamilyButton";
import FilterBarSynsetButton from "../FilterBarSynsetButton";
import FilterBarUniqueNamePathButton from "../FilterBarUniqueNamePathButton";
import FilterBarRelatedUniqueNamePathButton from "../FilterBarRelatedUniqueNamePathButton";
import React from "react";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";

const ComponentTranslationKey = "TopBar"

export default function FiltersBarFiltersList(props: { filters: IProductListSearch_Filter[] }) {
    // globals
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    return <>
        {props.filters.map((i: IProductListSearch_Filter, key: number) => {
            switch (i._type) {
                case IProductListSearch_FilterType.Price:
                    return <FilterBarNumericButton filterParam={i as IProductListSearch_PriceFilter}
                                                   key={"f" + key}/>;
                case IProductListSearch_FilterType.Numeric:
                    return <FilterBarNumericButton
                        filterParam={i as IProductListSearch_NumericFilter}
                        key={"f" + key}/>;
                case IProductListSearch_FilterType.Attribute:
                    return <FilterBarAttributeButton
                        filterParam={i as IProductListSearch_AttributeFilter}
                        key={"f" + key}/>
                case IProductListSearch_FilterType.Color:
                    return <FilterBarColorButton filterParam={i as IProductListSearch_ColorFilter}
                                                 key={"f" + key}/>
                case IProductListSearch_FilterType.Text:
                    return <FilterBarTextButton filterParam={i as IProductListSearch_TextFilter}
                                                key={"f" + key}/>
                case IProductListSearch_FilterType.Code:
                    return <FilterBarCodeButton filterParam={i as IProductListSearch_TextFilter}
                                                key={"f" + key}/>
                case IProductListSearch_FilterType.Brand:
                    return <FilterBarBrandButton
                        filterParam={i as IProductListSearch_BrandOrFamilyFilter}
                        key={"f" + key}/>
                case IProductListSearch_FilterType.Manufacturer:
                    return <FilterBarManufacturerButton
                        filterParam={i as IProductListSearch_BrandOrFamilyFilter}
                        key={"f" + key}/>
                case IProductListSearch_FilterType.Family:
                    return <FilterBarFamilyButton
                        filterParam={i as IProductListSearch_BrandOrFamilyFilter}
                        key={"f" + key}/>
                case IProductListSearch_FilterType.Synset:
                    return <FilterBarSynsetButton
                        filterParam={i as IProductListSearch_SynsetFilter}
                        key={"f" + key}/>
                case IProductListSearch_FilterType.UniqueNamePath:
                    return <FilterBarUniqueNamePathButton
                        filterParam={i as IProductListSearch_UniqueNamePathFilter}
                        key={"f" + key}/>
                case IProductListSearch_FilterType.RelatedUniqueNamePath:
                    return <FilterBarRelatedUniqueNamePathButton
                        filterParam={i as IProductListSearch_RelatedUniqueNamePathFilter}
                        key={"f" + key}/>
                default:
                    return <div className="inline-block ml-2"
                                key={"f" + key}
                                title={getTranslation('hint_remove_filter')}>
                        {i._type} / unknown type</div>
            }
        })}
    </>
}