export function parseGoToStore(search: string): number | undefined {

    const redirectParams = new URLSearchParams(search);

    let productId: undefined | number = undefined
    redirectParams.forEach(function (v, k) {
        switch (k) {
            case 'id':
                productId = parseInt(v);
                break;
        }
    })

    return productId;
}