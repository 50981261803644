import React from "react";
import {IconTriChevronDown} from "../../App/Icons";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import SearchRouteParamLink from "../../App/Links/SearchRouteParamLink";
import SeparatorLink from "../SeparatorLink";

type FoldedManufacturerSeparatorProps = {
    id: number
    withLink: boolean
    name: string
    urlPageParams: IUrlResolvedParams
    fold: string | undefined
};

const ComponentTranslationKey = "Separator"

/** @see FoldedManufacturerSeparatorDemo */
export default function FoldedManufacturerSeparator(props: FoldedManufacturerSeparatorProps) {
    // globals
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    return (
        <SeparatorLink
            urlPageParams={props.withLink ? {
                ...props.urlPageParams,
                bridgeParams: {
                    ...props.urlPageParams.bridgeParams,
                    fold: props.fold
                }
            } : undefined}
        >
            <div className="whitespace-nowrap card-separator-text-rotation flex-none flex justify-center">
                <div className="inline-block">
                    {props.withLink
                        ? <IconTriChevronDown
                            style={{width: '1.3em', display: 'inline-block', marginRight: '6px', marginBottom: '3px',}}
                            color={`#3b7ec2`}/>
                        : null}
                    {props.id
                        ? props.name
                        : getTranslation('other_manufacturers')}
                </div>
            </div>
        </SeparatorLink>
    );
}
