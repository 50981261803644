import {combineReducers} from 'redux';

import sessionReducer from './session.reducer';
import saytReducer from "./sayt.reducer"
import configReducer from "./config.reducer";
import cacheReducer from "./cache.reducer";
import interactiveReducer from "./interactive.reducer";
import urlContextReducer from "./urlContext.reducer";

const createRootReducers = () => combineReducers<IAppState>({
    cache: cacheReducer,
    urlContext: urlContextReducer,

    interactive: interactiveReducer,
    interactiveSayt: saytReducer,

    session: sessionReducer,
    config: configReducer,
});

export default createRootReducers;