import React, {
    ChangeEventHandler,
    ClipboardEventHandler,
    FocusEventHandler,
    KeyboardEventHandler,
    MouseEventHandler, useEffect, useRef, useState
} from "react";
import {connect, DispatchProp, useDispatch, useSelector} from 'react-redux';

import SearchInputResult from "../SearchInputResult";
import {triggerLoadSayt} from "../../../Store/Action/sayt";
import {useTranslation, WithTranslation, withTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {getDefaultLang} from "../../../Store/Action/interactive";
import {createSearchParams, useNavigate} from "react-router-dom";

/** @type TranslationKeys, keep this variable without TS type, for keep type check magic works*/
const ComponentTranslationKey = "SearchBox"

/** @see SearchInputDemo */
export default function SearchInput(props: {
    bigHeightSearch: boolean
    preload: string | undefined
}) {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {t} = useTranslation(ComponentTranslationKey)
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key)
    }

    const propsExtend = useSelector((state: IAppState) => ({
        saytResult: state.interactiveSayt.list,
        saytError: state.interactiveSayt.searchError,
        lang: state.config.lang ? state.config.lang : getDefaultLang(),
        isDebugEnabled: state.config.debug,
        bigHeightSearch: props.bigHeightSearch
    }))

    const [state, setState] = useState({
        //searchString: '',
        loadingResults: false,
        showResults: false
    })

    const preSearchTimeout = useRef<NodeJS.Timeout | null>(null);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const doSearch = function (search: string) {
        dispatch(triggerLoadSayt(search, propsExtend.lang, propsExtend.isDebugEnabled));

        window.dispatchEvent(new CustomEvent<string>('shopit-sayt-sync', {detail: search}))
    }

    useEffect(() => {
        const handleSearch = function (evt: Event) {
            if (propsExtend.bigHeightSearch) {
                const cEvt = evt as CustomEvent<string>
                if (cEvt.detail) {
                    const input = inputRef.current
                    if (input) {

                        const isVisible = !!(input.offsetWidth || input.offsetHeight || input.getClientRects().length);

                        if (!isVisible) {
                            input.value = cEvt.detail
                            input.focus()
                        }
                    }
                }
            }
        }

        window.addEventListener('shopit-sayt-sync', handleSearch);
        return () => {
            window.removeEventListener('shopit-sayt-sync', handleSearch);
        };
    }, []);

    const hideSearch = function () {
        setState({
            ...state,
            showResults: false,
            loadingResults: false
        })
    }

    const timeoutSearch = function (search: string) {
        if (preSearchTimeout.current) {
            clearTimeout(preSearchTimeout.current);
        }

        if (!search || search.length < 2) {
            hideSearch();
        } else {
            setState({
                //searchString: search,
                loadingResults: true,
                showResults: true
            })

            preSearchTimeout.current = setTimeout(() => {
                doSearch(search)
            }, 300)
        }
    }

    const stopSearch = function () {
        if (preSearchTimeout.current) {
            clearTimeout(preSearchTimeout.current);
        }
    }

    const onSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        timeoutSearch(e.target.value);
    }

    const onSearchPaste: ClipboardEventHandler<HTMLInputElement> = (e) => {
        timeoutSearch(e.currentTarget.value);
    }

    const onSearchKeyUp: KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (propsExtend.isDebugEnabled) {
            if (e.key == "Enter") {
                hideSearch()
                navigate({
                    pathname: '/search',
                    search : createSearchParams({
                        q: e.currentTarget.value
                    }).toString()
                })
            }
        }
    }

    const onSearchFocus: ChangeEventHandler<HTMLInputElement> = (e) => {
        // on route change one of input' parents can get focused, and this also trigger focus on input
        // i have no idea why, but this is a fix
        if (e.eventPhase != Event.BUBBLING_PHASE) {
            timeoutSearch(e.target.value);
        }
    }

    const onSearchClick: MouseEventHandler<HTMLInputElement> = (e) => {
        if (!state.showResults) {
            timeoutSearch(e.currentTarget.value);
        }
    }

    const onSearchBlur: FocusEventHandler<HTMLInputElement> = (e) => {
        stopSearch();
        hideSearch();
    }

    useEffect(() => {
        if (props.preload) {
            timeoutSearch(props.preload);
            if (inputRef.current) {
                inputRef.current.value = props.preload;
            }
        }
    }, []);

    // ---

    let searchResults;
    const placeholderText = getTranslation('placeholder');

    if (state.showResults) {
        searchResults =
            <div className="sayt-result-wrapper">
                <div className={`sayt-result ${propsExtend.bigHeightSearch ? `big-height` : ``}`}>
                    <SearchInputResult searchString={inputRef.current?.value || ''}
                                       isLoading={(propsExtend.saytResult || propsExtend.saytError) ? false : state.loadingResults}
                                       isError={propsExtend.saytResult ? false : propsExtend.saytError}
                                       saytResult={propsExtend.saytResult}/>
                </div>
            </div>;
    }

    return (
        <div className="sayt-container mx-auto">

            <input className="sayt-input" type="text" placeholder={placeholderText}
                   ref={inputRef}
                   tabIndex={-1}
                   onChange={onSearchChange}
                   onPaste={onSearchPaste}
                   onFocus={onSearchFocus}
                   onKeyUp={onSearchKeyUp}
                   onBlur={onSearchBlur}
                   onClick={onSearchClick}
                   autoComplete="off"/>

            <img src={`/resources/icon-search-gray1.png`} className={`sayt-input-icon`} alt={``}/>

            {propsExtend.isDebugEnabled && inputRef.current?.value
                ? <a key={'backend'}
                     target='backend-sayt'
                     className="btn btn-grey sayt-debug"
                     href={`https://backend.shopit.com/application/Backend/ContentTools/?route=sdt&activeTab=3&searchAsYouTypeSearchString=${inputRef.current?.value}`}>
                    Debug
                </a>
                : null}

            {searchResults}
        </div>
    );
}
