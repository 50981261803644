import React from "react";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {ProductImage} from "../../App/Common/Image";
import {PlaceholderText} from "../../App/Common/Placeholder";
import {formatNumber} from "../../../Helpers/StringFormatter.Number";

type PlaceholderCardProps = {
    message: string,

    className?: string,
};

/** @type TranslationKeys, keep this variable without TS type, for keep type check magic works*/
const ComponentTranslationKey = "SearchRoute"

/** @see PlaceholderCardDemo */
export default function PlaceholderCard(props: PlaceholderCardProps) {
    // globals
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    return (
        <div className={`category-item-card ${props.className}`}>
            <div className="card-section-rigid p-2 relative">
                <ProductImage imageId={0} size={'m'} alt={""}/>
            </div>
            <div className="card-section-stretch flex flex-col">
                <div className="px-4 flex-none text-gray-500">
                    {getTranslation('placeholder_card')}
                </div>
            </div>
            <div className="px-4 flex-1">
                <div className="font-bold product-title-two-rows">
                    {getTranslation('placeholder_product')}
                </div>
                <ul className="list-disc list-inside mb-4 mt-2 text-gray-300">
                    <li><PlaceholderText seed={1223} wordsCount={2} tag={"span"}/></li>
                    <li><PlaceholderText seed={1244} wordsCount={3} tag={"span"}/></li>
                    <li><PlaceholderText seed={1254} wordsCount={4} tag={"span"}/></li>
                    <li><PlaceholderText seed={1264} wordsCount={2} tag={"span"}/></li>
                </ul>
            </div>

            <div className="px-4 flex-none text-gray-500 pb-4">
                <div className="flex justify-between">
                    <div className="price-panel">
                        {formatNumber(123)}
                        <span> SEK</span>
                    </div>

                    <div className="flex">
                        <div className="text-gray-500 self-center">
                                <span className="text-gray-500">
                                    2 <div className="counter-pair counter-retailers"/>
                                </span>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
