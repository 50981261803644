import {useDispatch, useSelector} from "react-redux";
import React, {ReactElement, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";
import {MetaTags} from "../../Components/App/Tags/Meta";
import {triggerRequestStores} from "../../Store/Action/cache.stores";
import StoreLogo from "../../Components/App/Store/StoreLogo";

const ComponentTranslationKey = "AllStores"

export default function AllStoresRoute() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // globals
    const dispatch = useDispatch()
    const {t} = useTranslation(ComponentTranslationKey)

    const [state, setState] = useState({
        selectedLetter: ""
    })

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key)
    }

    // extract global state
    const appState = useSelector((state: IAppState) => {
        return {
            stores: state.cache.stores,
            isDebugEnabled: state.config.debug
        }
    })

    useEffect(() => {
        if (appState.stores === undefined || appState.stores.length === 0) {
            dispatch(triggerRequestStores())
            window.scrollTo(0, 0)
        }
    }, [])

    const content: { [key: string]: ReactElement[] } = {
        "All": [],
        "0-9": [],
        "A": [],
        "B": [],
        "C": [],
        "D": [],
        "E": [],
        "F": [],
        "G": [],
        "H": [],
        "I": [],
        "J": [],
        "K": [],
        "L": [],
        "M": [],
        "N": [],
        "O": [],
        "P": [],
        "Q": [],
        "R": [],
        "S": [],
        "T": [],
        "U": [],
        "V": [],
        "W": [],
        "X": [],
        "Y": [],
        "Z": [],
        "Å": [],
        "Ä": [],
        "Ö": [],
        "Other": []
    }

    const websiteStoreIds: { [key: number]: number } = {}
    const marketplaceMerchantIds: { [key: number]: number } = {}

    if (appState.stores) {
        for (const store of appState.stores) {
            if (store.marketplaceMerchantId && store.marketplaceMerchantName) {
                marketplaceMerchantIds[store.marketplaceMerchantId] = store.marketplaceMerchantId
                let contentKey = store.marketplaceMerchantName.substring(0, 1).toUpperCase()
                if (content[contentKey] === undefined) {
                    contentKey = contentKey >= '0' && contentKey <= '9' ? "0-9" : "Other"
                }
                content[contentKey].push(
                    <div className={`row`} key={`s${store.websiteStoreId}-m${store.marketplaceMerchantId}`}>
                        <span className={`merchant`}>{store.marketplaceMerchantName}</span>
                        <span className={`at`}> {getTranslation("at")} </span>
                        <span className={`merchant`}>{store.websiteStoreName}</span>
                        {appState.isDebugEnabled ?
                            <span className="product-offers-count"> ({store.productOfferCount})</span> : ''}
                    </div>
                )
            } else {
                websiteStoreIds[store.websiteStoreId] = store.websiteStoreId
                let contentKey = store.websiteStoreName.substring(0, 1).toUpperCase()
                if (content[contentKey] === undefined) {
                    contentKey = contentKey >= '0' && contentKey <= '9' ? "0-9" : "Other"
                }
                content[contentKey].push(
                    <div className={`row`} key={`s${store.websiteStoreId}`}>
                        <span className={`store`}>{store.websiteStoreName}</span>
                        {appState.isDebugEnabled ?
                            <span className="product-offers-count"> ({store.productOfferCount})</span> : ''}
                    </div>
                )
            }
        }
    }

    const navigation: ReactElement[] = []
    const cards: ReactElement[] = []
    for (const [letter, elements] of Object.entries(content)) {
        if (letter === "All") {
            navigation.push(
                <div className={`navigation-letter ${state.selectedLetter === "" ? "active" : ""}`}
                     key={"All"}
                     onClick={() => {
                         setState({...state, selectedLetter: ""})
                         window.scrollTo(0, 0)
                     }}>
                    {getTranslation("all")}
                </div>
            )
        } else if (letter === "Other") {
            if (elements.length > 0) {
                navigation.push(
                    <div
                        className={`navigation-letter ${state.selectedLetter === "Other" ? "active" : ""}`}
                        key={"Other"}
                        onClick={() => {
                            setState({...state, selectedLetter: "Other"})
                            window.scrollTo(0, 0)
                        }}>
                        {getTranslation("other")}
                    </div>
                )
            }
        } else {
            if (elements.length > 0) {
                navigation.push(
                    <div
                        className={`navigation-letter ${state.selectedLetter === letter ? "active" : ""}`}
                        key={letter}
                        onClick={() => {
                            setState({...state, selectedLetter: letter})
                            window.scrollTo(0, 0)
                        }}>
                        {letter}
                    </div>
                )
            } else {
                navigation.push(
                    <div
                        className={`navigation-letter inactive`}
                        key={letter}>
                        {letter}
                    </div>
                )
            }
        }

        if (state.selectedLetter === "" || state.selectedLetter == letter) {
            if (elements.length > 0) {
                cards.push(
                    <div key={`c-${letter}`} className={"card"}>
                        <h2 className={`letter`}>{letter == "Other" ? getTranslation("other") : letter}</h2>
                        {elements}
                    </div>
                )
            }
        }
    }

    // render
    return (
        <div className={`all-stores`}>
            <StoreLogo/>
            <div className={`service-page-container pt-4 `}>

                <MetaTags title={getTranslation('allStoresTitle')}
                          canonicalPageUrl={`default`}
                          robots={undefined}/>

                <h1 className={`service-page-h1`}>{getTranslation('allStores')}</h1>
                <div className={`service-page-block`}>{getTranslation('allStoresDesc')}</div>

                <div className="navigation">{navigation}</div>
                {appState.isDebugEnabled ?
                    <span className="debug">Stores: {Object.keys(websiteStoreIds).length}.
                        Merchants: {Object.keys(marketplaceMerchantIds).length}.
                        Total: {Object.keys(websiteStoreIds).length + Object.keys(marketplaceMerchantIds).length}.
                    </span> : ''}
                <div className="content">{cards}</div>
            </div>
        </div>
    )
}