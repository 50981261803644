import React from "react";
import ProductCard from "../../ProductsGrid/Card_Product";

export default function ProductHeaderBody(props: {
    products: (IProduct | IMissingProduct)[],
    debugData: { [productId: number]: IProductDebugData } | undefined,
}) {

    const products = props.products;
    if (!products) return null;

    return <tbody className="specification-subtable">
    <tr>
        <td></td>
        {products.map((product, index) => (<td key={`key-${index}`} className={`false-height`}>
            {product
                ? <ProductCard product={product}
                               className={`h-full`}
                               debug={props.debugData ? props.debugData[product.id] : undefined}/>
                : null}
        </td>))}
    </tr>
    </tbody>
}
