import React, {useEffect, useState} from "react";
import {connect, DispatchProp, useDispatch, useSelector} from 'react-redux';
import {useTranslation, WithTranslation, withTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {triggerRequestLocalization} from "../../../Store/Action/config.localization";
import {triggerUserSettings} from "../../../Store/Action/session.login";
import {getUserSettingsPlaceholderParams} from "../../../Helpers/UserHelper";
import {useLocation} from "react-router-dom";
import {triggerCacheRequestSearchWithCallback} from "../../../Store/Action/cache.search";

type ModalLanguageSettingsState = {
    countryOverride: number | undefined
    currencyOverride: number | undefined
    languageOverride: number | undefined
}

/** @type TranslationKeys, keep this variable without TS type, for keep type check magic works*/
const ComponentTranslationKey = "User.Settings.Tab.Localization"

export default function LocalizationSettings() {

    // globals
    const dispatch = useDispatch()
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet["User"]["Settings"]["Tab"]["Localization"]) {
        // i18next have problem with process '.' separated path to key as default
        // so we force a path with ':'
        // i tried to debug, but not understand how it works... but works
        // (copy comment for copy+paste multilevel translations functions)
        return t('User:Settings:Tab:Localization:' + key)
    }

    // get state
    const [state, setState] = useState<ModalLanguageSettingsState>({
        countryOverride: undefined,
        currencyOverride: undefined,
        languageOverride: undefined,
    });

    // extract global state
    const propsExtend = useSelector((state: IAppState) => ({
        user: state.session.user,

        countries: state.config.countries,
        languages: state.config.languages,
        currencies: state.config.currencies,

        countriesState: state.config.countriesState,
        languagesState: state.config.languagesState,
        currenciesState: state.config.currenciesState,
    }))

    useEffect(() => {
        if (propsExtend.countries.length == 0 || (propsExtend.countriesState !== CacheState.Cache && propsExtend.countriesState !== CacheState.Loading)) {
            dispatch(triggerRequestLocalization("countries"));
        }
        if (propsExtend.currencies.length == 0 || (propsExtend.currenciesState !== CacheState.Cache && propsExtend.currenciesState !== CacheState.Loading)) {
            dispatch(triggerRequestLocalization("currencies"));
        }
        if (propsExtend.languages.length == 0 || (propsExtend.languagesState !== CacheState.Cache && propsExtend.languagesState !== CacheState.Loading)) {
            dispatch(triggerRequestLocalization("languages"));
        }
    }, []);

    const onSelectCountryChange = function (e: React.SyntheticEvent) {
        const target = e.target as typeof e.target & { value: number }
        setState({
            ...state,
            countryOverride: target.value
        })
    }

    const onSelectCurrencyChange = function (e: React.SyntheticEvent) {
        const target = e.target as typeof e.target & { value: number }
        setState({
            ...state,
            currencyOverride: target.value
        })
    }

    const onSelectLanguageChange = function (e: React.SyntheticEvent) {
        const target = e.target as typeof e.target & { value: number }
        setState({
            ...state,
            languageOverride: target.value
        })
    }

    const onSaveSubmit = function (e: React.SyntheticEvent) {
        e.preventDefault();

        if (!propsExtend.user) return;

        const target = e.target as typeof e.target & {
            address: { value: string }
            code: { value: string }
            city: { value: string }
            country: { value: string }
            currency: { value: string }
            language: { value: string }
        };

        const address = target.address.value;
        const code = target.code.value;
        const city = target.city.value;
        const country = target.country.value;
        const currency = target.currency.value;
        const language = target.language.value;

        dispatch(triggerUserSettings({
            ...getUserSettingsPlaceholderParams(),

            address: address !== "" ? address : undefined,
            zipCode: code !== "" ? code : undefined,
            city: city !== "" ? city : undefined,
            country: country !== "" ? parseInt(country) : undefined,
            currency: currency !== "" ? parseInt(currency) : undefined,
            language: language !== "" ? parseInt(language) : undefined
        }))
    }

    const user = propsExtend.user;

    const selectedCountryIndex = state.countryOverride
        ? state.countryOverride
        : user?.delivery.countryIndex
            ? user?.delivery.countryIndex
            : "";

    const selectedCurrencyIndex = state.currencyOverride
        ? state.currencyOverride
        : user?.localization.currencyIndex
            ? user?.localization.currencyIndex
            : "";

    const selectedLanguageIndex = state.languageOverride
        ? state.languageOverride
        : (propsExtend.languages.find(i => i.code.toLowerCase() == user?.localization.language.toLowerCase()) || {id: 0}).id

    return <div>
        <div className="header">{getTranslation("title")}</div>
        <div className="sub-header">{getTranslation("sub_title")}</div>

        <form onSubmit={onSaveSubmit}>
            <div className="settings-row mt-8">
                <div className="settings-row-label">{getTranslation("address")}</div>
                <div className="settings-row-input"><input name="address" type="text"
                                                           value={user?.delivery.postAddress ? user?.delivery.postAddress : ""}/>
                </div>
            </div>
            <div className="settings-row">
                <div className="settings-row-label">{getTranslation("zip_code")}</div>
                <div className="settings-row-input"><input name="code" type="text"
                                                           value={user?.delivery.postCode ? user?.delivery.postCode : ""}/>
                </div>
            </div>
            <div className="settings-row">
                <div className="settings-row-label">{getTranslation("city")}</div>
                <div className="settings-row-input"><input name="city" type="text"
                                                           value={user?.delivery.postCity ? user?.delivery.postCity : ""}/>
                </div>
            </div>
            <div className="settings-row">
                <div className="settings-row-label">{getTranslation("country")}</div>
                <div className="settings-row-input">
                    <select value={selectedCountryIndex}
                            name="country"
                            onChange={onSelectCountryChange}>
                        <option value="" disabled></option>
                        {propsExtend.countries.map((i, index) => {
                            return <option key={`co${index}`} value={i.id}>{i.text}</option>
                        })}
                    </select>
                </div>
            </div>

            <div className="settings-row mt-4">
                <div className="settings-row-label">{getTranslation("currency")}</div>
                <div className="settings-row-input">
                    <select value={selectedCurrencyIndex}
                            name="currency"
                            onChange={onSelectCurrencyChange}>
                        <option value="" disabled></option>
                        {propsExtend.currencies.map((i, index) => {
                            return <option key={`cu${index}`} value={i.id}>{i.code}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className="settings-row">
                <div className="settings-row-label">{getTranslation("language")}</div>
                <div className="settings-row-input">
                    <select
                        value={selectedLanguageIndex}
                        name="language"
                        onChange={onSelectLanguageChange}>
                        {propsExtend.languages.map((i, index) => {
                            return <option key={`la${index}`} value={i.id}>{i.code}</option>
                        })}
                    </select>
                </div>
            </div>

            <div className="mt-4">
                <button className="btn btn-green text-sm w-full py-2" type="submit">Save</button>
            </div>
        </form>

    </div>
}
