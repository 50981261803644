
export const LOAD_PRODUCT_LIKE_PREVIEW = "LOAD_PRODUCT_LIKE_PREVIEW";
export const LOAD_PRODUCT_LIKE_PREVIEW_LOADING = "LOAD_PRODUCT_LIKE_PREVIEW_LOADING";
export const LOAD_PRODUCT_LIKE_PREVIEW_SUCCESS = "LOAD_PRODUCT_LIKE_PREVIEW_SUCCESS";
export const LOAD_PRODUCT_LIKE_PREVIEW_FAILED = "LOAD_PRODUCT_LIKE_PREVIEW_FAILED";
export const LOAD_PRODUCT_OWN_PREVIEW = "LOAD_PRODUCT_OWN_PREVIEW";
export const LOAD_PRODUCT_OWN_PREVIEW_LOADING = "LOAD_PRODUCT_OWN_PREVIEW_LOADING";
export const LOAD_PRODUCT_OWN_PREVIEW_SUCCESS = "LOAD_PRODUCT_OWN_PREVIEW_SUCCESS";
export const LOAD_PRODUCT_OWN_PREVIEW_FAILED = "LOAD_PRODUCT_OWN_PREVIEW_FAILED";
export const LOAD_PRODUCT_COMPARE_PREVIEW = "LOAD_PRODUCT_COMPARE_PREVIEW";
export const LOAD_PRODUCT_COMPARE_PREVIEW_LOADING = "LOAD_PRODUCT_COMPARE_PREVIEW_LOADING";
export const LOAD_PRODUCT_COMPARE_PREVIEW_SUCCESS = "LOAD_PRODUCT_COMPARE_PREVIEW_SUCCESS";
export const LOAD_PRODUCT_COMPARE_PREVIEW_FAILED = "LOAD_PRODUCT_COMPARE_PREVIEW_FAILED";

export const SET_PRODUCT_STATE = "SET_PRODUCT_STATE";
export const SET_PRODUCT_STATE_PENDING = "SET_PRODUCT_STATE_PENDING";
export const SET_PRODUCT_STATE_SUCCESS = "SET_PRODUCT_STATE_SUCCESS";

export const SET_PRODUCT_STATE_SET_COMPARE = "SET_PRODUCT_STATE_SET_COMPARE";
export const SET_PRODUCT_STATE_ADD_COMPARE = "SET_PRODUCT_STATE_ADD_COMPARE";
export const SET_PRODUCT_STATE_REMOVE_COMPARE = "SET_PRODUCT_STATE_REMOVE_COMPARE";

// ----

type _ProductNumericAction = {
    type: string,
    productId: number
}

// ---

export type RequestProductStateAction = _ProductNumericAction
export type RequestProductStateSuccessAction = _ProductNumericAction & {
    setLike: boolean,
    removeLike: boolean,
    setOwn: boolean,
    removeOwn: boolean
}
export type RequestSearchAndProductStateAction = _ProductNumericAction & { categoryId: number | undefined }
export type RequestDataAction = {
    type: string,
    data: { [searchKey: string]: number[] }
}

export const triggerProductStatePending = (productId: number): RequestProductStateAction => ({
    type: SET_PRODUCT_STATE_PENDING,
    productId
});

export const triggerProductStateSuccess = (productId: number, setLike: boolean, removeLike: boolean, setOwn: boolean, removeOwn: boolean): RequestProductStateSuccessAction => ({
    type: SET_PRODUCT_STATE_SUCCESS,
    productId,
    setLike,
    removeLike,
    setOwn,
    removeOwn
});

export const triggerProductStateAddCompare = (categoryId: number, productId: number): RequestSearchAndProductStateAction => ({
    type: SET_PRODUCT_STATE_ADD_COMPARE,
    productId,
    categoryId,
});

export const triggerProductStateRemoveCompare = (categoryId: number| undefined, productId: number): RequestSearchAndProductStateAction => ({
    type: SET_PRODUCT_STATE_REMOVE_COMPARE,
    productId,
    categoryId,
});

export const triggerProductStateSetCompare = (data: { [searchKey: string]: number[] }): RequestDataAction => ({
    type: SET_PRODUCT_STATE_SET_COMPARE,
    data,
});

// ----

export type ProductsAction = {
    type: string,
    productIds: number[],
    lang: string
}

export const triggerLoadProductsLikePreview = (productIds: number[], lang: string): ProductsAction => ({
    type: LOAD_PRODUCT_LIKE_PREVIEW,
    productIds,
    lang
});

export const triggerLoadProductsOwnPreview = (productIds: number[], lang: string): ProductsAction => ({
    type: LOAD_PRODUCT_OWN_PREVIEW,
    productIds,
    lang
});

export const triggerLoadProductsComparePreview = (productIds: number[], lang: string): ProductsAction => ({
    type: LOAD_PRODUCT_COMPARE_PREVIEW,
    productIds,
    lang
});

// ----

export type ProductStateAction = {
    type: string,
    productId: number,
    state: "like" | "own" | "none",
    oldState: "like" | "own" | "none"
}

export const triggerProductState = (productId: number, state: "like" | "own" | "none", oldState: "like" | "own" | "none"): ProductStateAction => ({
    type: SET_PRODUCT_STATE,
    productId,
    state,
    oldState
});

// ----

export type ProductPreviewLoadSuccessAction = {
    type: string,
    productsKey: string,
    products: IProductList_ItemWrapper[]
}

export const triggerLoadProductsLikePreviewSuccess = (products: IProductList_ItemWrapper[], productsKey:string): ProductPreviewLoadSuccessAction => ({
    type: LOAD_PRODUCT_LIKE_PREVIEW_SUCCESS,
    products,
    productsKey
});

export const triggerLoadProductsOwnPreviewSuccess = (products: IProductList_ItemWrapper[], productsKey:string): ProductPreviewLoadSuccessAction => ({
    type: LOAD_PRODUCT_OWN_PREVIEW_SUCCESS,
    products,
    productsKey
});

export const triggerLoadProductsComparePreviewSuccess = (products: IProductList_ItemWrapper[], productsKey:string): ProductPreviewLoadSuccessAction => ({
    type: LOAD_PRODUCT_COMPARE_PREVIEW_SUCCESS,
    products,
    productsKey
});

export type ProductPreviewLoadFailedAction = {
    type: string,
    productsKey: string,
}

export const triggerLoadProductsLikePreviewFailed = (productsKey:string): ProductPreviewLoadFailedAction => ({
    type: LOAD_PRODUCT_LIKE_PREVIEW_FAILED,
    productsKey
});

export const triggerLoadProductsOwnPreviewFailed = (productsKey:string): ProductPreviewLoadFailedAction => ({
    type: LOAD_PRODUCT_OWN_PREVIEW_FAILED,
    productsKey
});

export const triggerLoadProductsComparePreviewFailed = (productsKey:string): ProductPreviewLoadFailedAction => ({
    type: LOAD_PRODUCT_COMPARE_PREVIEW_FAILED,
    productsKey
});

export const triggerLoadProductsLikePreviewLoading = (productsKey:string): ProductPreviewLoadFailedAction => ({
    type: LOAD_PRODUCT_LIKE_PREVIEW_LOADING,
    productsKey
});

export const triggerLoadProductsOwnPreviewLoading = (productsKey:string): ProductPreviewLoadFailedAction => ({
    type: LOAD_PRODUCT_OWN_PREVIEW_LOADING,
    productsKey
});

export const triggerLoadProductsComparePreviewLoading = (productsKey:string): ProductPreviewLoadFailedAction => ({
    type: LOAD_PRODUCT_COMPARE_PREVIEW_LOADING,
    productsKey
});

