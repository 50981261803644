import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {Dialog} from "@headlessui/react";
import UserSettings from "./UserSettings";
import LocalizationSettings from "./LocalizationSettings";
import DescriptionSettings from "./DescriptionSettings";

/** @type TranslationKeys, keep this variable without TS type, for keep type check magic works*/
const ComponentTranslationKey = "User.Settings"

export default function ModalUserSettings(props: {
    isModalOpen: boolean
    onModalClose: () => void
}) {

    const {t} = useTranslation(ComponentTranslationKey)

    const [state, setState] = useState({activeTab: 1})

    const getTranslationUserTab = function (key: keyof TranslationSet["User"]["Settings"]["Tab"]["User"]) {
        // i18next have problem with process '.' separated path to key as default
        // so we force a path with ':'
        // i tried to debug, but not understand how it works... but works
        // (copy comment for copy+paste multilevel translations functions)
        return t('User:Settings:Tab:User:' + key)
    }

    const getTranslationDescriptionTab = function (key: keyof TranslationSet["User"]["Settings"]["Tab"]["Description"]) {
        return t('User:Settings:Tab:Description:' + key)
    }

    const getTranslationLocalizationTab = function (key: keyof TranslationSet["User"]["Settings"]["Tab"]["Localization"]) {
        return t('User:Settings:Tab:Localization:' + key)
    }

    const getTranslationShoppingTab = function (key: keyof TranslationSet["User"]["Settings"]["Tab"]["Shopping"]) {
        return t('User:Settings:Tab:Shopping:' + key)
    }

    const getTranslationInterestsTab = function (key: keyof TranslationSet["User"]["Settings"]["Tab"]["Interests"]) {
        return t('User:Settings:Tab:Interests:' + key)
    }

    const getTranslationPrivacyTab = function (key: keyof TranslationSet["User"]["Settings"]["Tab"]["Privacy"]) {
        return t('User:Settings:Tab:Privacy:' + key)
    }

    return <Dialog as="div" className="relative z-50"
                   open={props.isModalOpen}
                   onClose={props.onModalClose}>
        <div className="modal-shade-overlay"/>
        <div className="modal-wrapper-space">
            <div className="modal-wrapper-vertical-align text-center">
                <div className="user-settings-modal-wrapper">
                    <Dialog.Panel className="modal-title-content-wrapper">

                        <div className="modal-title">
                            Settings
                            <div className={`modal-title-closer`} onClick={props.onModalClose}>
                                <img src={`/resources/icon-remove-white.png`} alt={`Close`}/>
                            </div>
                        </div>

                        <div className={`modal-content user-settings-panel`}>
                            <div className="user-settings-tabs">
                                <div className={`user-settings-tab ${state.activeTab == 1 ? `active` : ``}`}
                                     onClick={() => {
                                         setState({activeTab: 1})
                                     }}>
                                    {getTranslationUserTab("title")}
                                </div>
                                <div className={`user-settings-tab ${state.activeTab == 2 ? `active` : ``}`}
                                     onClick={() => {
                                         setState({activeTab: 2})
                                     }}>
                                    {getTranslationDescriptionTab("title")}
                                </div>
                                <div className={`user-settings-tab ${state.activeTab == 3 ? `active` : ``}`}
                                     onClick={() => {
                                         setState({activeTab: 3})
                                     }}>
                                    {getTranslationLocalizationTab("title")}
                                </div>
                                <div className={`user-settings-tab ${state.activeTab == 4 ? `active` : ``}`}
                                     onClick={() => {
                                         setState({activeTab: 4})
                                     }}>
                                    {getTranslationShoppingTab("title")}
                                </div>
                                <div className={`user-settings-tab ${state.activeTab == 5 ? `active` : ``}`}
                                     onClick={() => {
                                         setState({activeTab: 5})
                                     }}>
                                    {getTranslationInterestsTab("title")}
                                </div>
                                <div className={`user-settings-tab ${state.activeTab == 6 ? `active` : ``}`}
                                     onClick={() => {
                                         setState({activeTab: 6})
                                     }}>
                                    {getTranslationPrivacyTab("title")}
                                </div>
                            </div>

                            <div className="user-settings-tabs-content">
                                <div
                                    className={`user-settings-tabs-panel ${state.activeTab == 1 ? `active` : ``}`}>
                                    <UserSettings/>
                                </div>
                                <div
                                    className={`user-settings-tabs-panel ${state.activeTab == 2 ? `active` : ``}`}>
                                    <DescriptionSettings/>
                                </div>
                                <div
                                    className={`user-settings-tabs-panel ${state.activeTab == 3 ? `active` : ``}`}>
                                    <LocalizationSettings/>
                                </div>
                                <div
                                    className={`user-settings-tabs-panel ${state.activeTab == 4 ? `active` : ``}`}>
                                    <div>
                                        <div className="header">{getTranslationShoppingTab("title")}</div>
                                        <div
                                            className="sub-header">{getTranslationShoppingTab("sub_title")}</div>
                                    </div>
                                </div>
                                <div
                                    className={`user-settings-tabs-panel ${state.activeTab == 5 ? `active` : ``}`}>
                                    <div>
                                        <div className="header">{getTranslationInterestsTab("title")}</div>
                                        <div
                                            className="sub-header">{getTranslationInterestsTab("sub_title")}</div>
                                    </div>
                                </div>
                                <div
                                    className={`user-settings-tabs-panel ${state.activeTab == 6 ? `active` : ``}`}>
                                    <div>
                                        <div className="header">{getTranslationPrivacyTab("title")}</div>
                                        <div
                                            className="sub-header">{getTranslationPrivacyTab("sub_title")}</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Dialog.Panel>
                </div>
            </div>
        </div>
    </Dialog>
}
