import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../Localization/i18n.constants";
import {MetaTags} from "../../Components/App/Tags/Meta";

// ---

const ComponentTranslationKey = "Meta"

export default function DisclaimerRoute(props: {}) {

    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={`service-page-container pt-16`}>

            <MetaTags title={getTranslation('disclaimerTitle')}
                      canonicalPageUrl={`default`}
                      robots={undefined}/>

            <h1 className={`service-page-h1`}>Friskrivningar och ansvarsbegränsning</h1>

            <h2 className={`service-page-h2`}>Friskrivning från garantier och ansvarsbegränsning</h2>
            <p className={`service-page-block`}>
                Vi får information om produkter och annat från leverantörer, tillverkare, återförsäljare och media. Vi
                hämtar också den från andra webbplatser som finns tillgängliga. Vårt mål är att all information på
                webbplatsen ska vara riktig och uppdaterad. Vi kan dock inte garantera att den alltid är pålitlig och
                korrekt.
            </p>
            <p className={`service-page-block`}>
                Varje användare är ansvarig för sina egna handlingar som görs på webbplatsen. Shopit försöker, så långt
                det går, se till att informationen om produkter och priser som visas på vår webbplats är riktig. Vi
                uppmanar ändå våra användare att alltid själva kontrollera att priset i butiken är det samma innan ett
                köp görs. Shopit kan inte garantera att någon information är riktig eller hållas ansvarig för handlingar
                som användare har gjort baserat på den information om produkter eller priser som vi ger. Shopit kan inte
                heller hållas ansvarig för förlust eller skada som har skett i samband med avtal som ingåtts med företag
                som Shopit listar.
            </p>
            <p className={`service-page-block`}>
                Om du hittar fel är vi tacksamma om du rapporterar dem till oss så att vi kan ändra dem eller ta bort
                dem.
            </p>
            <p className={`service-page-block`}>
                A. Du godkänner uttryckligen att: (i) du använder denna webbplats på egen risk; (ii) produkterna och
                tjänsterna levereras som de är och i mån av tillgång; (iii) utom där det uttryckligen anges här
                friskriver vi oss uttryckligen från alla garantier, uttryckliga eller underförstådda, inklusive men inte
                begränsat till underförstådda garantier om säljbarhet, lämplighet för ett visst syfte, yrkesmässig
                utförande, äganderätt och frihet från intrång; (iv) vi ger ingen garanti för de resultat som kan uppnås
                från denna webbplats, annonserade eller erbjudna produkter eller tjänster eller involverade
                återförsäljare; (v) allt material som har laddats ner eller på annat sätt erhållits genom användning av
                webbplatsen sker på eget gottfinnande och på egen risk; (vi) du bär hela ansvaret för eventuella skador
                på din datorutrustning eller för eventuell dataförlust som följd av nedladdning av sådant material; och
                (vi) inget råd eller information, muntligt eller skriftligt, som du har fått från oss eller genom eller
                från webbplatsen ska utgöra någon garanti som inte uttryckligen anges i dessa regler och villkor.
            </p>
            <p className={`service-page-block`}>
                B. Utom där det uttryckligen anges här ger vi ingen garanti för att: (i) någon produkt eller tjänst
                kommer att motsvara dina krav; (ii) någon produkt eller tjänst kommer att vara kontinuerlig, punktlig,
                säker eller felfri; (iii) resultaten som kan uppnås av användningen av någon produkt eller tjänst kommer
                att vara korrekta eller pålitliga; (iv) kvaliteten på någon produkt, tjänst, information eller annat
                material som du har köpt eller fått genom någon produkt eller tjänst kommer att uppfylla dina
                förväntningar; eller (v) några fel i programvaran kommer att åtgärdas.
            </p>
            <p className={`service-page-block`}>
                C. Du förstår och godtar att utom där det uttryckligen anges här ska inte tjänstemän, chefer, anställda,
                företrädare, leverantörer eller någon annan part som är inblandad i att skapa, producera, överföra eller
                distribuera webbplatsen eller relaterade produkter eller tjänster hållas ansvariga för några direkta,
                indirekta, tillfälliga eller särskilda skador, följdskador eller skador som påförs med skadestånd i
                avskräckande syfte, inklusive men inte begränsat till skador för förlorad vinst, goodwill, användning,
                data eller andra immateriella skador (oavsett om vi har informerats om möjligheten av sådana skador) som
                härrör från: (i) din användning eller oförmåga att använda tjänsten; (ii) kostnaden för anskaffningen av
                ersättningsvaror eller -tjänster som följd av några varor, data, uppgifter eller tjänster som har köpts
                eller erhållits eller meddelanden som har tagits emot eller transaktioner som har genomförts genom eller
                från tjänsten; (iii) obehörig åtkomst till eller ändring av dina överföringar eller data; (iv)
                uttalanden eller handlingar av någon tredje part på webbplatsen; (v) eller naturfenomen, krafter eller
                orsaker utanför vår rimliga kontroll, inklusive utan begränsning, fel på internet, fel på
                datorutrustning, fel på telekommunikationsutrustning eller annan utrustning, strömavbrott, strejker,
                arbetskonflikter, upplopp, uppror, samhälleliga oroligheter, brist på arbetskraft eller material,
                bränder, översvämningar, stormar, explosioner, force majeure, krig, regeringsingripanden, beslut av
                inhemsk eller utländsk domstol eller tribunal, utebliven prestation av tredje part eller störningar
                eller variationer i uppvärmning, belysning eller luftkonditionering eller (vi) någon annan fråga som rör
                webbplatsen oavsett orsak och enligt varje teori om ansvar (inklusive oaktsamhet). under inga
                omständigheter ska vårt totala ansvar gentemot dig för alla skador, förluster och grunder för talan vare
                sig enligt avtal, utomobligatoriskt (inklusive men inte begränsat till oaktsamhet) eller annars
                överstiga det eventuella belopp du har betalat till Shopit.
            </p>
            <p className={`service-page-block`}>
                Ovanstående begränsningar ska gälla oavsett om grundläggande syfte med varje begränsat rättsmedel har
                misslyckats.
            </p>

            <h3 className={`service-page-h3`}>Undantag och begränsningar</h3>
            <p className={`service-page-block`}>
                Vissa lagstiftare tillåter inte att man utesluter vissa garantier eller begränsar eller utesluter ansvar
                för relaterade skador eller följdskador. I sådana fall ska vårt ansvar begränsas i så stor grad som
                lagen tillåter.
            </p>

            <h3 className={`service-page-h3`}>Länkar</h3>
            <p className={`service-page-block`}>
                Denna webbplats innehåller länkar till andra webbplatser som endast tillhandahålls för din bekvämlighet
                och inte som ett godkännande av Shopit eller dess tredje parts leverantörer eller distributörer av
                innehållet på dessa webbplatser. Shopit har inte granskat alla de webbplatser som är länkade till
                webbplatsen och tar inget ansvar för deras innehåll. De länkade webbplatserna tillhandahålls endast för
                din bekvämlighet och du använder dem på egen risk. Shopit gör inga utfästelser och ger inga garantier
                för några andra webbplatser eller innehållet eller materialet på dessa webbplatser. Om du väljer att
                besöka andra webbplatser gör du det på egen risk.
            </p>

            <h3 className={`service-page-h3`}>Inget godkännande</h3>
            <p className={`service-page-block`}>
                Vi nämner produkter och tjänster från tredje parter, inklusive varumärken och länkar till butikers
                webbplatser, bara för att informera. Det ska inte ses som ett godkännande eller en rekommendation av
                dessa. Att vi använder varumärken och länkar till butiker ska inte tolkas som att vi antyder, direkt
                eller indirekt, att dessa godkänner, rekommenderar eller har något samband med Shopit.
            </p>

            <h3 className={`service-page-h3`}>Skadeslöshet och befrielse</h3>
            <p className={`service-page-block`}>
                Materialet som du läser på denna webbplats tillhandahålls endast för information. Genom att använda
                webbplatsens sidor godtar du att hålla Shopit och närstående enheter (tillsammans kallade "de
                skadeslösa") skadeslösa och att hålla dem skadeslösa för alla eventuella anspråk och kostnader,
                inklusive (utan begränsning) advokatarvoden, som uppstår av din användning av webbplatsens sidor, din
                användning av produkterna och tjänsterna eller ditt inskickande av idéer och/eller material till Shopit
                eller av någon persons användning av varje ID, medlemskap eller lösenord som du har med någon del av
                webbplatsen, oavsett om denna användning sker med ditt medgivande.
            </p>
            <p className={`service-page-block`}>
                Shopit förbehåller sig rätten att på egen bekostnad ta det exklusiva försvaret och den exklusiva
                kontrollen över varje fråga som annars är föremål för ditt medgivande av skadeslöshet och i detta fall
                samtycker du till att samarbeta i Shopits försvar mot sådant anspråk.
            </p>

            <h3 className={`service-page-h3`}>Friskrivning från garantier och ansvarsbegränsning</h3>
            <p className={`service-page-block`}>
                I den utsträckning som tillåts enligt lag är vi inte ansvariga för några direkta, indirekta, relaterade
                eller särskilda skador eller följdskador som uppstår på grund av eller i samband med att du använder
                eller inte kan använda webbplatsen eller produkter eller tjänster som du har köpt eller fått eller
                meddelanden som du har fått eller transaktioner som gjorts via tjänsten, inklusive, men inte begränsat
                till, skador på grund av förlust av vinst, användning, data eller andra immateriella tillgångar, även om
                en sådan part har informerats om risken för sådana skador. Om du är missnöjd med våra tjänster eller
                erbjudanden som tillhandahålls genom webbplatsen, är din enda lösning att sluta använda webbplatsen.
            </p>
            <p className={`service-page-block`}>
                Genom att använda webbplatsen, produkterna och tjänsterna eller skicka in idéer och/eller relaterat
                material till oss godkänner du att befria de skadeslösa från alla eventuella anspråk, krav, skulder,
                skyldigheter, skador (faktiska skador eller följdskador), kostnader och utgifter av varje slag, vare sig
                kända eller okända, misstänkta eller inte misstänkta, avslöjade eller inte avslöjade som du kan ha mot
                dem som beror på eller har något samband med sådana tvister och/eller med produkterna och tjänsterna
                eller med någon tvist angående användningen av idéer och/eller relaterat material som du har lämnat till
                de skadeslösa.
            </p>
            <p className={`service-page-block`}>
                Du samtycker till att i största möjliga utsträckning enligt lagen avsäga dig rätten att åberopa någon
                lag som kan begränsa sådana skadeslöshållandens eller befrielses giltighet.
            </p>
        </div>
    );
}
