import React, {FunctionComponent} from "react";

type SearchHighlightProps = {
    text?: string,
    highlight?: string,
    textClassName?: string,
    textClassNameOnHighlight?: string
}

const getIndicesOf = (str?: string, searchStr?: string, caseSensitive = true) => {
    if (str === undefined || searchStr === undefined) {
        return []
    }

    const {length} = searchStr

    if (length === 0) {
        return []
    }

    if (!caseSensitive) {
        str = str.toLowerCase()
        searchStr = searchStr.toLowerCase()
    }

    let startIndex = 0, index, indices = []

    while ((index = str.indexOf(searchStr, startIndex)) > -1) {
        indices.push(index)
        startIndex = index + length
    }

    return indices
}

const splitAtOccurences = (str?: string, searchStr?: string, caseSensitive?: boolean): (string | undefined)[] => {
    if (str === undefined || searchStr === undefined) {
        return [str]
    }

    const indices = getIndicesOf(str, searchStr, caseSensitive)

    if (indices.length === 0) {
        return [str]
    }

    const parts = []

    let lastIndex = 0;
    let searchStrLength = searchStr.length;

    for (const index of indices) {
        if (index - lastIndex > 0) {
            parts.push(str.substr(lastIndex, index - lastIndex))
        }
        if (searchStrLength > 0) {
            parts.push(str.substr(index, searchStrLength))
        }
        lastIndex = index + searchStrLength;
    }
    if (str.length - lastIndex > 0) {
        parts.push(str.substr(lastIndex, str.length - lastIndex))
    }

    return parts
}

const SearchHighlight: FunctionComponent<SearchHighlightProps> = function (props: SearchHighlightProps) {

    if (props.text === undefined) return null
    if (props.highlight === undefined) {
        return (<span className={props.textClassName}>{props.text}</span>)
    }

    const matchedTextSections = splitAtOccurences(props.text, props.highlight, false)

    if (matchedTextSections.length === 0) {
        return (<span className={props.textClassName}>{props.text}</span>)
    }

    let haveMatchedText = matchedTextSections.findIndex((mt: string | undefined, i) => (
        mt && props.highlight && mt.toUpperCase() === props.highlight.toUpperCase()
    )) !== -1

    return (
        <span
            className={`${props.textClassName} ${haveMatchedText && props.textClassNameOnHighlight !== undefined ? props.textClassNameOnHighlight : ''}`}>
            {matchedTextSections.map((mt, i) => {
                return (
                    <span key={`${mt}.${i}`}
                          className={mt && props.highlight && mt.toUpperCase() === props.highlight.toUpperCase() ? 'matched-section' : ''}>
                         {mt}
                     </span>
                )
            })}
        </span>
    )
}

export default SearchHighlight;