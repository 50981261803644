import {IconChevronDown, IconChevronRight} from "../../App/Icons";
import React, {MouseEventHandler, useContext, useEffect, useState} from "react";
import {getCategoryStructureCacheKey, triggerRequestCategoryStructure} from "../../../Store/Action/cache.categoryTree";
import {triggerCacheAccessSearchWithCallback} from "../../../Store/Action/cache.search";
import {getCategoryCacheKey, selectSearchCache} from "../../../Store/StoreHelper";
import {getDefaultLang, triggerDebug} from "../../../Store/Action/interactive";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import SearchRouteParamLink from "../../App/Links/SearchRouteParamLink";
import {SearchRouteContext} from "../../../Store/Context/SearchRouteContext";
import {fetchCategorySubstructure} from "../../../Helpers/ApiHelper";
import {staticCast} from "../../../Helpers/TypeHelper";
import {buildSearchUrl} from "../../../Helpers/UrlFormatter.Search";
import {put} from "redux-saga/effects";
import {triggerUrlContextAdd} from "../../../Store/Action/urlContext";

type CategoryPanelState = {
    collapsedCategoryIds: number[],
    loadingCategoryIds: number[],

    rootTree: IServerSettings_CategoryTreeNode | undefined,
    categoryStructures: { [key: number]: IServerSettings_CategoryTreeNode }
}

export default function CategoryPanel() {

    // globals
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const searchContext = useContext(SearchRouteContext);

    const lang = useSelector((state: IAppState) => state.config.lang ? state.config.lang : getDefaultLang())

    // extract global state
    const [showDropdownTopCategories, setShowDropdownTopCategories] = useState(false)

    const apiTail: IApiTail = useSelector((state: IAppState) => ({
        _logPageToken: state.session.tracking.pageToken || null
    }));

    const propsExtend = useSelector((state: IAppState) => {
        const searchData = selectSearchCache(state, searchContext.cacheKey);

        const categoryId = searchData && searchData.object
            ? searchData.object.searchQuery?.categoryId
            : undefined

        const categoryCacheKey = categoryId
            ? getCategoryCacheKey(
                categoryId,
                state.config.lang ? state.config.lang : getDefaultLang(),
                state.config.debug)
            : "";

        const categoryData = state.cache.category[categoryCacheKey] && state.cache.category[categoryCacheKey].object ? state.cache.category[categoryCacheKey].object : undefined;
        const topCategoryId = categoryData ? categoryData.path[0].id : undefined

        const categoryStructureKey = getCategoryStructureCacheKey(categoryId ? categoryId : 0, state.config.lang ? state.config.lang : getDefaultLang())

        return {
            categoryId: categoryId,
            topCategoryId: topCategoryId,
            categoryTree: state.cache.categoryTree[categoryStructureKey]
                ? state.cache.categoryTree[categoryStructureKey].object
                : undefined,
            cacheState: state.cache.categoryTree[categoryStructureKey]
                ? state.cache.categoryTree[categoryStructureKey].cacheState
                : undefined,
            lang: state.config.lang ? state.config.lang : getDefaultLang(),
            isDebugEnabled: state.config.debug,
        }
    })

    const [panelState, setPanelState] = useState<CategoryPanelState>({
        collapsedCategoryIds: [],
        loadingCategoryIds: [],
        rootTree: propsExtend.categoryTree,
        categoryStructures: {}
    })

    // lifecycle functions
    useEffect(() => {
        if (propsExtend.categoryTree === undefined && (propsExtend.cacheState == undefined || propsExtend.cacheState == CacheState.Unknown || propsExtend.cacheState == CacheState.ServerNotResponse)) {
            dispatch(triggerRequestCategoryStructure(propsExtend.categoryId, propsExtend.lang));
        }
        if (propsExtend.categoryTree && !panelState.rootTree) {
            setPanelState({
                ...panelState,
                rootTree: propsExtend.categoryTree
            })
        }
    }, [propsExtend.categoryTree, propsExtend.cacheState]);

    useEffect(() => {
        if (propsExtend.categoryId) {
            const selectedCategory = document.getElementsByClassName("category-item selected");
            if (selectedCategory.length) {
                selectedCategory[0].scrollIntoView();
                const tree = document.getElementsByClassName("category-panel-category-tree");
                if (tree.length) {
                    tree[0].scrollTop = tree[0].scrollTop - 150;
                }
            }
        }
    }, []);

    const _fetchCategorySubstructure = function (categoryId: number) {
        fetchCategorySubstructure(categoryId, apiTail).then((r) => {
            if (r && r.data) {
                dispatch(triggerUrlContextAdd(r.urlContext))
                if (r.data.id) {
                    setPanelState({
                        ...panelState,
                        categoryStructures: {
                            ...panelState.categoryStructures,
                            [r.data.id]: staticCast<IServerSettings_CategoryTreeNode>({
                                categoryId: r.data.id,
                                name: r.data.name || "",
                                isCollection: r.data.is_collection ? !!r.data.is_collection : false,
                                children: r.data.children
                            })
                        }
                    })
                }
            }
        })
    }

    const onCategoryExpand: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        e.preventDefault()

        const categoryAttr = e.currentTarget.attributes.getNamedItem('data-category-id')
        if (categoryAttr) {
            const categoryId = parseInt(categoryAttr.value)

            if (panelState.collapsedCategoryIds.indexOf(categoryId) != -1) {
                setPanelState({
                    ...panelState,
                    collapsedCategoryIds: panelState.collapsedCategoryIds.filter(i => i != categoryId)
                })
            } else {
                const categorySubstructure = panelState.categoryStructures[categoryId]
                if (categorySubstructure == undefined && panelState.loadingCategoryIds.indexOf(categoryId) == -1) {
                    _fetchCategorySubstructure(categoryId)

                    setPanelState({
                        ...panelState,
                        loadingCategoryIds: [...panelState.loadingCategoryIds, categoryId]
                    })
                }
            }
        }
    }

    const onCategoryCollapse: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        e.preventDefault()

        const categoryAttr = e.currentTarget.attributes.getNamedItem('data-category-id')
        if (categoryAttr) {
            const categoryId = parseInt(categoryAttr.value)

            if (panelState.collapsedCategoryIds.indexOf(categoryId) == -1) {
                setPanelState({
                    ...panelState,
                    collapsedCategoryIds: [...panelState.collapsedCategoryIds, categoryId]
                })
            }
        }
    }

    const toggleTopCategoryDropdown: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        e.preventDefault()

        setShowDropdownTopCategories(!showDropdownTopCategories)
    }

    const onParentCategoryChange = (categoryId: number, categoryName: string) => {
        const urlPageParams: IUrlResolvedParams = {
            bridgeParams: {
                categoryId: categoryId,
                language: undefined,
                color: undefined,
                filters: undefined,
                expand: undefined,
                expandedBrands: undefined,
                secondaryExpand: undefined,
                showSecondary: undefined,
                fold: undefined,
                foldedBrands: undefined,
                page: undefined,
                size: undefined,
                text: undefined,
                code: undefined,
                group: undefined,
                sort: undefined,
            },
            showFilters: undefined,
            openFilterOption: [],
            openUnpOptions: [],
        }

        setShowDropdownTopCategories(false)

        dispatch(triggerCacheAccessSearchWithCallback(
            urlPageParams, false, propsExtend.isDebugEnabled, () => {
                const [toPathname, toSearch, isValidLink] = buildSearchUrl(urlPageParams, {
                    category: {[categoryId]: categoryName},
                    brand: {},
                    manufacturer: {},
                    family: {},
                    unp: {},
                });
                if (!isValidLink) {
                    console.log("Search link error");
                    console.log(toPathname, toSearch, urlPageParams, {
                        category: {[categoryId]: categoryName},
                        brand: {},
                        manufacturer: {},
                        family: {},
                        unp: {},
                    })
                    const trace = new Error().stack
                    console.log(trace)
                    dispatch(triggerDebug("Search link error (console)", trace))
                }

                navigate({
                    pathname: toPathname,
                    search: toSearch
                })
            }
        ))
    }

    const renderList = (children: IServerSettings_CategoryTreeNode[], level: number = 0, showChevron: boolean = true) => {

        return <div className={`pt-2`}>
            {children.map((i, index) => {
                const children = i.children
                    ? i.children
                    : panelState.categoryStructures[i.categoryId]
                        ? panelState.categoryStructures[i.categoryId].children
                        : undefined
                const haveChildren = children && !!children.length

                const expandable = i.isCollection
                const isLoading = panelState.loadingCategoryIds.indexOf(i.categoryId) != -1
                const isForceCollapsed = panelState.collapsedCategoryIds.indexOf(i.categoryId) != -1
                const isCollapsed = expandable && (isForceCollapsed || !haveChildren)
                const isExpanded = expandable && (haveChildren || isLoading) && !isForceCollapsed

                return <div key={`c${index}`} style={{paddingLeft: '8px'}}>
                    <SearchRouteParamLink
                        urlPageParams={{
                            bridgeParams: {
                                categoryId: i.categoryId,
                                language: undefined,
                                color: undefined,
                                filters: undefined,
                                expand: undefined,
                                expandedBrands: undefined,
                                secondaryExpand: undefined,
                                showSecondary: undefined,
                                fold: undefined,
                                foldedBrands: undefined,
                                page: undefined,
                                size: undefined,
                                text: undefined,
                                code: undefined,
                                sort: undefined,
                                group: undefined,
                            },
                            showFilters: undefined,
                            openFilterOption: [],
                            openUnpOptions: [],
                        }}
                    >
                        <div
                            className={`${i.isCollection ? `collection-item` : `category-item`} ${propsExtend.categoryId === i.categoryId ? `selected` : ``}`}>
                            {showChevron
                                ? (isExpanded
                                    ? <span onClick={onCategoryCollapse} data-category-id={i.categoryId}>
                                        <IconChevronDown style={{width: '1.5em', display: 'inline'}}
                                                         fill={propsExtend.categoryId === i.categoryId ? `white` : undefined}/>
                                      </span>
                                    : isCollapsed
                                        ? <span onClick={onCategoryExpand} data-category-id={i.categoryId}>
                                            <IconChevronRight style={{width: '1.5em', display: 'inline'}}
                                                              fill={propsExtend.categoryId === i.categoryId ? `white` : undefined}/>
                                          </span>
                                        : <div style={{width: '1.5em', display: 'inline-block'}}></div>)
                                : <div className={`bg-gray-100 rounded-full mx-auto mr-2`}
                                       style={{
                                           width: '1.75rem',
                                           height: '1.75rem',
                                           display: 'inline-block',
                                           verticalAlign: 'bottom'
                                       }}>
                                    <img className={`mix-blend-darken opacity-70`}
                                         src={`/resources/categories/${i.categoryId}.webp`}
                                         alt={`Category icon`}/>
                                </div>}

                            <span style={{
                                height: '1.75rem',
                                lineHeight: '1.75rem'
                            }}>{i.name}</span>
                        </div>
                    </SearchRouteParamLink>
                    {isExpanded
                        ? children
                            ? renderList(children, level + 1)
                            : panelState.loadingCategoryIds.indexOf(i.categoryId) !== -1
                                ? <div>Loading...</div>
                                : null
                        : null}
                </div>
            })}
        </div>
    }

    const categories = panelState.rootTree ? panelState.rootTree.children : undefined;

    if (propsExtend.categoryId) {

        const topCategory = categories
            ? categories.filter((i: IServerSettings_CategoryTreeNode) => (i.children && i.children.length))
            : undefined

        return (
            <div className="category-panel-category-wrapper js-sidebar">
                <div className="category-panel-category-select relative">
                    <div className={`flex justify-between p-2 pl-4 cursor-pointer`}
                         onClick={toggleTopCategoryDropdown}>
                        <div>{topCategory && topCategory[0] && topCategory[0].name}</div>
                        <div><IconChevronDown style={{width: '1.5em', display: 'inline'}}/></div>
                    </div>

                    {showDropdownTopCategories
                        ? <div className={`category-panel-dropdown-wrapper`}>
                            <div className={`category-panel-dropdown`}>
                                {categories
                                    ? categories.map((i: IServerSettings_CategoryTreeNode, index: number) => (
                                        <div key={`o${index}`}
                                             className={`category-panel-dropdown-item cursor-pointer ${propsExtend.topCategoryId == i.categoryId ? `selected` : ``}`}
                                             onClick={() => {
                                                 onParentCategoryChange(i.categoryId, i.name)
                                             }}>{i.name}</div>
                                    ))
                                    : null}
                            </div>
                        </div>
                        : null}

                    {/*<select className="w-full p-2" value={this.props.topCategoryId}*/}
                    {/*        onChange={this.onParentCategorySelect.bind(this)}>*/}
                    {/*    {categories*/}
                    {/*        ? categories.map((i, index) => (*/}
                    {/*            <option key={`o${index}`} value={i.categoryId}>{i.name}</option>*/}
                    {/*        ))*/}
                    {/*        : null}*/}
                    {/*</select>*/}
                </div>
                <div className="category-panel-category-tree">
                    {topCategory && topCategory[0] && topCategory[0].children
                        ? renderList(topCategory[0].children, 0)
                        : null}
                </div>
            </div>
        )
    }

    return (
        <div className="category-panel-root-list js-sidebar">
            {categories
                ? renderList(categories, 0, false)
                : null}
        </div>
    )
}