import React, {PropsWithChildren} from "react";
import SearchRouteParamLink from "../App/Links/SearchRouteParamLink";

export default function SeparatorLink(props: PropsWithChildren<{ urlPageParams: IUrlResolvedParams | undefined }>) {
    if (props.urlPageParams) {
        return <SearchRouteParamLink
            className="category-item-separator flex flex-col text-shopit-blue"
            urlPageParams={props.urlPageParams}>
            {props.children}
        </SearchRouteParamLink>
    } else {
        return <div className="category-item-separator flex flex-col text-shopit-blue">
            {props.children}
        </div>;
    }
}