import React, {MouseEventHandler} from "react";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";

export default function SearchDebugButton(props: { onClick: () => void }) {

    const {t} = useTranslation("TopBar")
    const getTranslation = function (key: keyof TranslationSet["TopBar"]) {
        return t(key);
    }

    const onDebugClick: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault()
        e.stopPropagation()
        props.onClick()
    }

    return <div
        className={`btn btn-compare-blue cursor-pointer uppercase`}
        onClick={onDebugClick}
        key="debug">
        <img src="/resources/settings-icon.png" alt="" className="mr-1 inline w-4"/>
        {getTranslation('show_debug')}
    </div>
}