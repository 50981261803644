import React from "react";
import {CategoryPath} from "../../ProductList/CategoryPath";
import {IconShoppingCard} from "../../App/Icons";
import {PlaceholderText} from "../../App/Common/Placeholder";

/** @see CategoryPath */
export function CategoryPathDemo() {
    return <CategoryPath category={
        {
            id: 0,
            name: "Category",
            path: [{id: 0, name: "Path1"}, {id: 0, name: "Path2"}, {id: 0, name: "Path3"}, {
                id: 0,
                name: "Path4"
            }],
            description: undefined,
            isCollection: false,
            sampleProducts: []
        }
    } uncategorized={false}/>
}

// ---

/** @see ProductCardListHeader */
export function ProductCardListHeaderDemo() {
    return <div className="product-list-header-wrapper">
        <div className={`product-list-header-columns`}>
            <h1 className="product-list-header-column-left">
                {/*<div className="product-list-header-column-left-icon-wrapper">*/}
                {/*    <IconShoppingCard style={{*/}
                {/*        width: '2rem',*/}
                {/*        display: 'inline',*/}
                {/*        marginRight: '6px',*/}
                {/*        marginTop: '-8px',*/}
                {/*    }}/>*/}
                {/*</div>*/}
                Category Name
            </h1>
            <div className="product-list-header-column-right">
                <div>
                    <PlaceholderText wordsCount={96} class={`bg-category-header`} seed={1234}/>
                </div>
            </div>
        </div>
    </div>
}
