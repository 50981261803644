import React, {
    ChangeEventHandler,
    ClipboardEventHandler,
    FocusEventHandler,
    MouseEventHandler,
    useContext,
    useState
} from "react"
import {connect, DispatchProp, useDispatch, useSelector} from 'react-redux'
import FiltersWrapper from "../FiltersWrapper"
import {TranslationSet} from "../../../Localization/i18n.constants";
import {useTranslation, withTranslation, WithTranslation} from "react-i18next";
import {selectSearchCache} from "../../../Store/StoreHelper";
import FilterRelatedUniqueNamePathOption from "../FilterRelatedUniqueNamePath/FilterRelatedUniqueNamePathOption";
import {useLocation} from "react-router-dom";
import {SearchRouteContext} from "../../../Store/Context/SearchRouteContext";

/** @type TranslationKeys, keep this variable without TS type, for keep type check magic works*/
const ComponentTranslationKey = "FilterStaticUniqueNamePath"

export default function FilterRelatedUniqueNamePathSection(props: {
    section: IFilterPanel_SectionUniqueNamePath,
    level: number,
    demonstrationMode: boolean
}) {

    // globals
    const searchContext = useContext(SearchRouteContext);
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    // internal
    const [state, setState] = useState({
        sectionFolded: (props.section && props.section.defaultFold) ? true : false,
        unpExpand: false,
        inputAutocompleteShow: false,
        inputAutocompleteText: ""
    })

    const propsExtension = useSelector((state: IAppState) => {
        const searchData = selectSearchCache(state, searchContext.cacheKey);
        return {
            searchQuery: searchData && searchData.object ? searchData.object.searchQuery : undefined,
            isDebugEnabled: state.config.debug,
        };
    })

    const onSearchChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        setState({
            ...state,
            inputAutocompleteShow: true,
            inputAutocompleteText: e.target.value.toLowerCase()
        });
    }

    const onSearchPaste: ClipboardEventHandler<HTMLInputElement> = (e) => {
        setState({
            ...state,
            inputAutocompleteShow: true,
            inputAutocompleteText: e.currentTarget.value.toLowerCase()
        });
    }

    const onSearchFocus: ChangeEventHandler<HTMLInputElement> = (e) => {
        setState({
            ...state,
            inputAutocompleteShow: true,
            inputAutocompleteText: e.target.value.toLowerCase()
        });
    }

    const onSearchBlur: FocusEventHandler<HTMLInputElement> = (e) => {
        setTimeout(function () {
            setState({...state, inputAutocompleteShow: false});
        }, 150)
    }

    const expandSectionFold: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        setState({...state, sectionFolded: !state.sectionFolded})
    }

    const expandOptions: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        setState({...state, unpExpand: true});
    }

    const compressOptions: MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation()
        setState({...state, unpExpand: false});
    }

    const section = props.section
    if (!section) return null

    const actualOptions = section.structures.length <= 15 || state.unpExpand
        ? section.structures
        : section.structures.filter((i, index) => {
            return index < 15 || ((i as IFilterPanel_RelatedUniqueNamePath).filterValues.findIndex(f => f.checked) != -1)
        })

    const showExpand = section.structures.length > 15 && !state.unpExpand
    const showCollapse = section.structures.length > 15 && state.unpExpand

    const autocompleteUnp: IFilterPanel_UniqueNamePathValue[] = state.inputAutocompleteShow && state.inputAutocompleteText.length > 0
        ? section.structures
            .reduce((set, s) => {
                if (s.objectType == IFilterPanel_ObjectType.RelatedUniqueNamePath) {
                    const unp = (s as IFilterPanel_RelatedUniqueNamePath)
                    const filterValues = unp.filterValues
                        .filter((i) => i.name.toLowerCase().indexOf(state.inputAutocompleteText) !== -1)
                        .sort((a, b) => b.primaryCount - a.primaryCount)
                        .slice(0, 10)
                    return [...set, ...filterValues];
                }
                return set;
            }, [] as IFilterPanel_UniqueNamePathValue[])
            .sort((a, b) => b.primaryCount - a.primaryCount)
        : []

    const unpCount = section.structures.reduce((sum, s) => {
        if (s.objectType == IFilterPanel_ObjectType.RelatedUniqueNamePath) {
            const unp = (s as IFilterPanel_RelatedUniqueNamePath)
            return sum + unp.filterValues.length
        }
        return sum;
    }, 0)

    return <>
        <div key={`head`} className="filter-panel-attribute-section-header"
             onClick={expandSectionFold}>
            {state.sectionFolded
                ? <img src="/resources/arrow-right.png" className="filter-panel-toggle-section" alt="(Expand)"/>
                : <img src="/resources/arrow-down.png" className="filter-panel-toggle-section open"
                       alt="(Collapse)"/>}
            {section.name}
        </div>
        {!state.sectionFolded && section.structures
            ? <div key={`content`} className="filter-panel-attribute-section-content">

                {unpCount > 10
                    ? <div className={`filter-panel-attribute-spacing`}>
                        <div className={`filter-panel-unique-name-path-search-block`}>
                            <input className={`filter-panel-unique-name-path-search-input`}
                                   onChange={onSearchChange}
                                   onPaste={onSearchPaste}
                                   onFocus={onSearchFocus}
                                   onBlur={onSearchBlur}
                                   placeholder={getTranslation('search')}/>

                            {state.inputAutocompleteShow && state.inputAutocompleteText.length > 0 && autocompleteUnp.length == 0
                                ? <div className={`filter-panel-unique-name-path-search-no-result-wrapper`}>
                                    <div className={`filter-panel-unique-name-path-search-no-result`}>
                                        <div>{getTranslation('no_result')}</div>
                                    </div>
                                </div>
                                : null}

                            {state.inputAutocompleteShow && autocompleteUnp.length > 0
                                ? <div className={`filter-panel-unique-name-path-search-result-wrapper`}>

                                    <div className={`filter-panel-unique-name-path-search-result`}>
                                        <div className={`filter-panel-unique-name-path-options`}>
                                            {autocompleteUnp.map((unp, key) => (
                                                <div key={`unique-name-path-${key}`}
                                                     className={`filter-panel-unique-name-path-option`}>
                                                    <FilterRelatedUniqueNamePathOption item={unp}
                                                                                       searchQuery={propsExtension.searchQuery}
                                                                                       demonstrationMode={props.demonstrationMode}
                                                                                       isDebugEnabled={propsExtension.isDebugEnabled}
                                                                                       intendLeft={false}
                                                                                       toggleCallback={(() => {
                                                                                           setState({...state, inputAutocompleteShow: false})
                                                                                       })}/>
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                                : null}
                        </div>
                    </div>
                    : null}

                <div className={`filter-panel-unique-name-path-options`}>
                    {actualOptions.map((structure, index) =>
                        <FiltersWrapper key={index}
                                        item={structure}
                                        level={props.level}
                                        demonstrationMode={props.demonstrationMode}/>
                    )}

                    {showExpand || showCollapse
                        ? <div className={`filter-panel-attribute-spacing`}>
                            {showExpand
                                ? <div className="filter-panel-toggle-options"
                                       onClick={expandOptions}>
                                    {getTranslation('show')}{' '}{section.structures.length - actualOptions.length}{' '}{getTranslation('more_options')}
                                </div>
                                : null}
                            {showCollapse
                                ? <div className="filter-panel-toggle-options"
                                       onClick={compressOptions}>{getTranslation('show_less_options')}</div>
                                : null}
                        </div>
                        : null}
                </div>
            </div>
            : null}
    </>;
}
