import axios, {AxiosResponse} from "axios";
import {call, delay, fork, put, select, takeEvery} from "redux-saga/effects";
import {Task} from "redux-saga";

import {handleShopitApi, handleShopitApiRequestError} from "./helper";
import {getAxiosApiConfigs, serializePostParams} from "../../Helpers/ApiHelper";
import {triggerMessage} from "../Action/interactive";
import {
    addMergedProductsWithVerifiedImageKey,
    removeMergedProductsWithVerifiedImageKey
} from "../../Helpers/LocalStorageHelper";

import {
    ImageVerificationSaveAction,
    ImageVerificationDeleteAction,
    ImageVerificationLoadAction,
    SET_IMAGE_VERIFICATION_SAVE,
    SET_IMAGE_VERIFICATION_DELETE,
    SET_IMAGE_VERIFICATION_LOAD,
    triggerImageVerification_Apply,
    triggerImageVerification_Load,
    triggerImageVerification_MarkImageVerified,
    triggerImageVerification_UnmarkImageVerified,
    triggerImageVerification_SetPending,
    triggerImageVerification_RemovePending,
} from "../Action/session.verifyImage";
import {
    ProductVerifyAction,
    SET_PRODUCT_VERIFY_DELETE,
    SET_PRODUCT_VERIFY_SAVE,
    triggerProductVerifyAddProduct,
    triggerProductVerifyRemoveProduct
} from "../Action/session.verifyProduct";
import {
    MergedProductImageVerificationSaveAction,
    MergedProductImageVerificationLoadAction,
    SET_MERGED_PRODUCT_IMAGE_VERIFICATION_SAVE,
    SET_MERGED_PRODUCT_IMAGE_VERIFICATION_LOAD,
    triggerMergedProductImageVerification_Load,
    triggerMergedProductImageVerification_Apply,
    SET_MERGED_PRODUCT_IMAGE_VERIFICATION_DELETE,
    MergedProductImageVerificationDeleteAction,
    triggerMergedProductImageVerification_SetPending,
    triggerMergedProductImageVerification_RemovePending
} from "../Action/session.verifyMergedProductImage";
import {log} from "node:util";

function* doProductVerificationCategorySave({userSessionId, productId, categoryId}: ProductVerifyAction) {
    const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axios.post,
        `/api/verification/product/save`,
        serializePostParams<IApiVerificationProductSaveRequest>({
            productId: productId,
            categoryListId: categoryId,
            valueId: categoryId,
            debug: isDebug,

            _logPageToken: logToken || null
        }),
        getAxiosApiConfigs(undefined, "VS_CATEGORY_SAVE_TIMEOUT, notify developers"));

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiVerificationProductSaveResponse>(res)
        if (response) {
            if (response.success) {
                // TODO: add no_category_match from response
                yield put(triggerProductVerifyAddProduct(userSessionId, productId, categoryId, response.noCategoryMatch, false))
            }
        }
    }
}

function* doProductVerificationCategoryDelete({userSessionId, productId, categoryId}: ProductVerifyAction) {
    const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axios.post,
        `/api/verification/product/delete`,
        serializePostParams<IApiVerificationProductDeleteRequest>({
            productId: productId,
            valueId: categoryId,
            debug: isDebug,

            _logPageToken: logToken || null
        }),
        getAxiosApiConfigs(undefined, "VS_CATEGORY_DELETE_TIMEOUT, notify developers"));

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiVerificationProductDeleteResponse>(res)
        if (response) {
            if (response.success) {
                yield put(triggerProductVerifyRemoveProduct(userSessionId, productId, categoryId))
            }
        }
    }
}

function* doImageVerificationLoad(action: ImageVerificationLoadAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axios.post,
        `/api/user/data`,
        serializePostParams<IApiUserDataRequest>(
            {
                userId: null,
                imageIds: action.imageIds,
                productIds: null,

                _logPageToken: logToken || null
            }),
        getAxiosApiConfigs(undefined, "VS_IMAGE_LOAD_TIMEOUT, notify developers"));

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiUserDataResponse>(res)

        if (response) {
            yield put(triggerImageVerification_Apply(action.imageIds, response.shophunterData.verifiedImages))
        }
    }
}

function* doTimeoutRequest(ms: number, message: string) {
    yield delay(ms)
    yield put(triggerMessage(
        '0',
        message))
}

function* doImageVerificationSave(action: ImageVerificationSaveAction) {

    const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    let delayTask: Task = yield fork(doTimeoutRequest, 2000, 'Image Verification takes more than 2 seconds. Waiting for end')

    yield put(triggerImageVerification_SetPending(
        action.verificationType,
        action.imageId,
        action.productId,
        action.productType,
        action.imageType,
        action.imageContentType,
        action.imageVariants,
        action.imageOverlay,
        action.imageWatermark
    ))

    const req = call(
        axios.post,
        `/api/verification/image/save`,
        serializePostParams<IApiVerificationImageSaveRequest>({
            productId: action.productId !== undefined ? action.productId : null,
            imageId: action.imageId,
            imageStatus: action.productType !== undefined ? action.productType : null,
            imageWatermark: action.imageWatermark !== undefined ? action.imageWatermark : null,
            imageType: action.imageType !== undefined ? action.imageType : null,
            imageContentType: action.imageContentType !== undefined ? action.imageContentType : null,
            imageVariants: action.imageVariants !== undefined ? action.imageVariants : null,
            imageOverlay: action.imageOverlay !== undefined ? action.imageOverlay : null,
            listCategoryId: null,
            debug: isDebug,

            _logPageToken: logToken || null
        }));

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiVerificationImageResponse>(res)
        if (response) {
            if (response.success) {
                yield put(triggerImageVerification_Load([action.imageId]))

                // need to add some other system, for images on product. can't reuse current one
                // addMergedProductsWithVerifiedImageKey(mergedProductId);
                // yield put(triggerImageVerification_MarkImageVerified(mergedProductId))

            } else {
                yield put(triggerImageVerification_RemovePending(
                    action.verificationType,
                    action.imageId,
                    action.productId,
                    action.productType,
                    action.imageType,
                    action.imageContentType,
                    action.imageVariants,
                    action.imageOverlay,
                    action.imageWatermark
                ))
            }

            if (!delayTask.isRunning() && response.timeoutDetails && response.timeoutDetails.length > 0) {
                yield put(triggerMessage(
                    '0',
                    'Verification Image Save Debug\n' + response.timeoutDetails))
            }
        } else {
            yield put(triggerImageVerification_RemovePending(
                action.verificationType,
                action.imageId,
                action.productId,
                action.productType,
                action.imageType,
                action.imageContentType,
                action.imageVariants,
                action.imageOverlay,
                action.imageWatermark
            ))
        }
    }

    if (delayTask.isRunning()) {
        delayTask.cancel();
    }
}

function* doImageVerificationDelete(action: ImageVerificationDeleteAction) {
    const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    let delayTask: Task = yield fork(doTimeoutRequest, 2000, 'Image Verification takes more than 2 seconds. Waiting for end')

    const req = call(
        axios.post,
        `/api/verification/image/delete`,
        serializePostParams<IApiVerificationImageDeleteRequest>({
            productId: action.productId !== undefined ? action.productId : null,
            imageId: action.imageId,
            debug: isDebug,

            _logPageToken: logToken || null
        }),
        getAxiosApiConfigs(undefined, "VS_IMAGE_DELETE_TIMEOUT, notify developers"));

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiVerificationImageResponse>(res)
        if (response) {
            if (response.success) {
                yield put(triggerImageVerification_Load([action.imageId]))

                // i not sure how to remove it exactly
                // removeMergedProductsWithVerifiedImageKey(mergedProductId);
                // yield triggerImageVerification_UnmarkImageVerified(mergedProductId);
            }

            if (!delayTask.isRunning() && response.timeoutDetails && response.timeoutDetails.length > 0) {
                yield put(triggerMessage(
                    '0',
                    'Verification Image Clear Debug\n' + response.timeoutDetails))
            }
        }
    }

    if (delayTask.isRunning()) {
        delayTask.cancel();
    }
}

function* doMergedProductImageVerificationLoad({mergedProductIds}: MergedProductImageVerificationLoadAction) {
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    const req = call(
        axios.post,
        `/api/user/data`,
        serializePostParams<IApiUserDataRequest>(
            {
                userId: null,
                imageIds: null,
                productIds: mergedProductIds,

                _logPageToken: logToken || null
            }),
        getAxiosApiConfigs(undefined, "VS_PRODUCT_LOAD_TIMEOUT, notify developers"));

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiUserDataResponse>(res)

        if (response) {
            yield put(triggerMergedProductImageVerification_Apply(mergedProductIds, response.shophunterData.verifiedMergedProductImages))
        }
    }
}

function* doMergedProductImageVerificationSave(action: MergedProductImageVerificationSaveAction) {

    const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    let delayTask: Task = yield fork(doTimeoutRequest, 2000, 'MergedProduct Image Verification takes more than 2 seconds. Waiting for end')

    yield put(triggerMergedProductImageVerification_SetPending(action.mergedProductId, action.imageId, action.imageType, action.isMainImage))

    const req = call(
        axios.post,
        `/api/verification/merged_product_image/save`,
        serializePostParams<IApiVerificationMergedProductImageSaveRequest>({
            mergedProductId: action.mergedProductId,
            imageId: action.imageId,
            imageType: action.imageType,
            isMainImage: action.isMainImage,
            listCategoryId: action.listCategoryId,
            debug: isDebug,

            _logPageToken: logToken || null
        }),
        getAxiosApiConfigs(undefined, "VS_MP_IMAGE_SAVE_TIMEOUT, notify developers"));

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiVerificationMergedProductImageResponse>(res)
        if (response) {
            if (response.success) {
                yield put(triggerMergedProductImageVerification_Load([action.mergedProductId]))

                addMergedProductsWithVerifiedImageKey(action.mergedProductId);
                yield put(triggerImageVerification_MarkImageVerified(action.mergedProductId))
            } else {
                yield put(triggerMergedProductImageVerification_RemovePending(action.mergedProductId, action.imageId))
            }

            if (!delayTask.isRunning() && response.timeoutDetails && response.timeoutDetails.length > 0) {
                yield put(triggerMessage(
                    '0',
                    'MergedProduct Image Verification Debug\n' + response.timeoutDetails))
            }
        } else {
            yield put(triggerMergedProductImageVerification_RemovePending(action.mergedProductId, action.imageId))
        }
    }

    if (delayTask.isRunning()) {
        delayTask.cancel();
    }
}

function* doMergedProductImageVerificationDelete(action: MergedProductImageVerificationDeleteAction) {

    const isDebug: boolean = yield select((state: IAppState) => state.config.debug)
    const logToken: string | undefined = yield select((state: IAppState) => state.session.tracking.pageToken)

    let delayTask: Task = yield fork(doTimeoutRequest, 2000, 'MergedProduct Image Verification takes more than 2 seconds. Waiting for end')

    yield put(triggerMergedProductImageVerification_SetPending(action.mergedProductId, action.imageId, action.prevImageType, action.prevIsMainImage))

    const req = call(
        axios.post,
        `/api/verification/merged_product_image/delete`,
        serializePostParams<IApiVerificationMergedProductImageDeleteRequest>({
            mergedProductId: action.mergedProductId,
            imageId: action.imageId,
            debug: isDebug,

            _logPageToken: logToken || null
        }),
        getAxiosApiConfigs(undefined, "VS_MP_IMAGE_DELETE_TIMEOUT, notify developers"));

    let res: AxiosResponse<IApiResponse> | null = null;
    try {
        res = yield req
    } catch (error) {
        if (isDebug) {
            yield* handleShopitApiRequestError(error)
        }
    }

    if (res) {
        const response = yield* handleShopitApi<IApiVerificationMergedProductImageResponse>(res)
        if (response) {
            if (response.success) {
                if (action.prevIsMainImage) {
                    removeMergedProductsWithVerifiedImageKey(action.mergedProductId);
                    yield triggerImageVerification_UnmarkImageVerified(action.mergedProductId);
                }

                yield put(triggerMergedProductImageVerification_Load([action.mergedProductId]))
            } else {
                yield put(triggerMergedProductImageVerification_RemovePending(action.mergedProductId, action.imageId))
            }

            if (!delayTask.isRunning() && response.timeoutDetails && response.timeoutDetails.length > 0) {
                yield put(triggerMessage(
                    '0',
                    'MergedProduct Image Verification Debug\n' + response.timeoutDetails))
            }
        } else {
            yield put(triggerMergedProductImageVerification_RemovePending(action.mergedProductId, action.imageId))
        }
    }

    if (delayTask.isRunning()) {
        delayTask.cancel();
    }
}

export function* watchVerificationAction() {
    yield takeEvery(SET_PRODUCT_VERIFY_SAVE, doProductVerificationCategorySave);
    yield takeEvery(SET_PRODUCT_VERIFY_DELETE, doProductVerificationCategoryDelete);

    yield takeEvery(SET_MERGED_PRODUCT_IMAGE_VERIFICATION_SAVE, doMergedProductImageVerificationSave);
    yield takeEvery(SET_MERGED_PRODUCT_IMAGE_VERIFICATION_LOAD, doMergedProductImageVerificationLoad);
    yield takeEvery(SET_MERGED_PRODUCT_IMAGE_VERIFICATION_DELETE, doMergedProductImageVerificationDelete);

    yield takeEvery(SET_IMAGE_VERIFICATION_SAVE, doImageVerificationSave);
    yield takeEvery(SET_IMAGE_VERIFICATION_DELETE, doImageVerificationDelete);
    yield takeEvery(SET_IMAGE_VERIFICATION_LOAD, doImageVerificationLoad);
}