export const CACHE_ACCESS_SEARCH_WITH_CALLBACK = "CACHE_ACCESS_SEARCH_WITH_CALLBACK"
export const CACHE_REQUEST_SEARCH_WITH_CALLBACK = "CACHE_REQUEST_SEARCH_WITH_CALLBACK";
export const CACHE_SAVE_SEARCH_LOADING = "CACHE_SAVE_SEARCH_LOADING";
export const CACHE_SAVE_SEARCH_FOUND = "CACHE_SAVE_SEARCH_FOUND";
export const CACHE_SAVE_SEARCH_NOT_FOUND = "CACHE_SAVE_SEARCH_NOT_FOUND";
export const CACHE_SAVE_SEARCH_NOT_RESPOND = "CACHE_SAVE_SEARCH_NOT_RESPOND";

// ---

export function getSearchCacheKey(searchKey: string, lang: string, debug: boolean): string {
    return `${searchKey}.${lang}.${debug ? 1 : 0}`
}

// ---

export type CacheAccessSelectAction = {
    type: string,
    params: IUrlResolvedParams,
    callback: () => void,
    silentLoading: boolean,
    isDebug: boolean
}

export const triggerCacheAccessSearchWithCallback = (params: IUrlResolvedParams, silentLoading: boolean, isDebug: boolean, callback: () => void): CacheAccessSelectAction => ({
    type: CACHE_ACCESS_SEARCH_WITH_CALLBACK,
    params,
    silentLoading,
    callback,
    isDebug
})

// ----

export type CacheRequestSearchAction = {
    type: string,
    params: IUrlResolvedParams,
    isDebug: boolean,
    callback?: () => void
}

export const triggerCacheRequestSearchWithCallback = (params: IUrlResolvedParams, isDebug: boolean, callback?: () => void): CacheRequestSearchAction => ({
    type: CACHE_REQUEST_SEARCH_WITH_CALLBACK,
    params: params,
    isDebug: isDebug,
    callback: callback
});

// ---

export type ResponseSearchServiceSuccessDetailsAction = {
    primaryProducts: number,
    secondaryProducts: number,
    requestDuration: number | undefined,
    datafileCreateTime: string,
    notAvailable: boolean | undefined
}

export type CacheSaveSearchFoundAction = {
    type: string,
    searchKey: string,
    options: IApiSearchRequestPostParams,
    category: ICategory | undefined,
    topRankProducts: IProductListItem_Product[] | undefined,
    uniqueNamePathRelatedCategories: IUniqueNamePathRelatedCategory[] | undefined,
    list: IProductList,
    filters: IFilterPanel_ObjectWrapper[] | undefined,
    searchParams: IProductListSearch_Params | undefined,
    responseDetails: ResponseSearchServiceSuccessDetailsAction | undefined,
}

export const triggerCacheSaveSearchFound = (
    searchKey: string,
    options: IApiSearchRequestPostParams,
    category: ICategory | undefined,
    topRankProducts: IProductListItem_Product[] | undefined,
    uniqueNamePathRelatedCategories: IUniqueNamePathRelatedCategory[] | undefined,
    list: IProductList,
    filters: IFilterPanel_ObjectWrapper[] | undefined,
    searchParams: IProductListSearch_Params | undefined,
    responseDetails: ResponseSearchServiceSuccessDetailsAction | undefined,
): CacheSaveSearchFoundAction => ({
    type: CACHE_SAVE_SEARCH_FOUND,
    searchKey,
    category,
    topRankProducts,
    uniqueNamePathRelatedCategories,
    list,
    filters,
    searchParams,
    responseDetails,
    options,
});

// ----

export type CacheSaveSearchLoadingAction = {
    type: string,
    searchKey: string,
    options: IApiSearchRequestPostParams
}

export const triggerCacheSaveSearchLoading = (searchKey: string, options: IApiSearchRequestPostParams): CacheSaveSearchLoadingAction => ({
    type: CACHE_SAVE_SEARCH_LOADING,
    searchKey,
    options,
});

// ----

export type CacheSaveSearchNotFoundAction = {
    type: string,
    searchKey: string,
    options: IApiSearchRequestPostParams
}

export const triggerCacheSaveSearchNotFound = (searchKey: string, options: IApiSearchRequestPostParams): CacheSaveSearchNotFoundAction => ({
    type: CACHE_SAVE_SEARCH_NOT_FOUND,
    searchKey,
    options,
});

// ----

export type CacheSaveSearchNotRespondAction = {
    type: string,
    searchKey: string,
    options: IApiSearchRequestPostParams
}

export const triggerCacheSaveSearchNotRespond = (searchKey: string, options: IApiSearchRequestPostParams): CacheSaveSearchNotRespondAction => ({
    type: CACHE_SAVE_SEARCH_NOT_RESPOND,
    searchKey,
    options,
});

