import {MetaTags} from "../../Components/App/Tags/Meta";
import React, {useEffect} from "react";

export default function BusinessRegisterRedirectRoute() {

    useEffect(() => {
        window.location.href = 'https://business.shopit.com/store/register'
    }, []);

    // render
    return (<div className="text-center text-3xl pt-48">

        <MetaTags title={`Shopit`}
                  canonicalPageUrl={`default`}
                  robots={`noindex`}/>

        <div className={`mt-8 text-gray-300`}>Redirecting...</div>
        <div className={`text-lg text-gray-300`}>
            or click <a className={`text-shopit-green`} href={`https://business.shopit.com/store/register`} target={`_blank`}>here</a>
        </div>
    </div>);
}