import {
    LOAD_SAYT_CLEANUP,
    LOAD_SAYT_FAILED,
    LOAD_SAYT_SUCCESS, SaytCleanupAction,
    SaytFailAction,
    SaytSuccessAction
} from "../Action/sayt";

export const saytInitialState: ISaytState = {
    searchError: false,
    list: undefined
};

const saytReducer = (state: ISaytState = saytInitialState,
                     action: SaytSuccessAction
                         | SaytFailAction
                         | SaytCleanupAction): ISaytState => {
    switch (action.type) {
        case LOAD_SAYT_SUCCESS: {
            const successAction = action as SaytSuccessAction
            return {
                ...state,
                list: successAction.data,
                searchError: false,
            };
        }
        case LOAD_SAYT_FAILED: {
            return {
                ...state,
                list: undefined,
                searchError: true,
            };
        }
        case LOAD_SAYT_CLEANUP: {
            return {
                ...state,
                searchError: false,
            };
        }
    }
    return state;
};

export default saytReducer;