import React from "react";
import SearchRouteLink from "../../App/Links/SearchRouteLink";

type CategorySeparatorProps = {
    name: string
    url: string
};

export default function CategorySeparator(props: CategorySeparatorProps) {
    return (
        <SearchRouteLink
            className="category-item-separator flex flex-col text-shopit-green"
            to={`${props.url}`}>
            <div className="whitespace-nowrap card-separator-text-rotation flex-none flex justify-center">
                <div className="inline-block">
                    {props.name}
                </div>
            </div>
        </SearchRouteLink>
    );
}
