import {put, select, takeEvery} from "redux-saga/effects";

import {
    CACHE_ACCESS_PRODUCT_WITH_CALLBACK,
    CACHE_ACCESS_PRODUCTS_WITH_CALLBACK,
    CacheAccessProductAction,
    CacheAccessProductsAction, triggerCacheRequestProductsWithCallback, triggerCacheRequestProductWithCallback,
} from "../Action/cache.product";
import {triggerStartLoadingOverlay} from "../Action/interactive";
import {getSearchApiFromPageParams, getUrlFromPageParams} from "../../Helpers/SearchHelper";
import {
    selectProductCacheState,
    selectProductRequestOptions,
    selectSearchCacheState,
    selectSearchRequestOptions
} from "../StoreHelper";
import {getProductCacheKey} from "../Action/cache.product";
import {
    CACHE_ACCESS_SEARCH_WITH_CALLBACK,
    CacheAccessSelectAction, getSearchCacheKey,
    triggerCacheRequestSearchWithCallback
} from "../Action/cache.search";

function* cacheSearchRequestCallback(action: CacheAccessSelectAction) {

    const urlRequestKey = getSearchApiFromPageParams(action.params)

    const searchOptions: IApiSearchRequestPostParams = yield select(selectSearchRequestOptions)
    const searchKey = getSearchCacheKey(urlRequestKey, searchOptions.lang, searchOptions.debug)

    const cacheState: CacheState | undefined = yield select(selectSearchCacheState, searchKey);

    switch (cacheState) {
        case undefined:
        case CacheState.ServerNotResponse:
        case CacheState.Unknown: {
            if (!action.silentLoading) {
                yield put(triggerStartLoadingOverlay())
            }
            yield put(triggerCacheRequestSearchWithCallback(action.params, action.isDebug, action.callback))
            break;
        }
        case CacheState.Cache:
        case CacheState.NotFound: {
            if (action.callback) action.callback();
            break;
        }
        default:
            break;
    }
}

function* cacheProductRequestCallback(action: CacheAccessProductAction) {

    const productId =
        typeof action.productId == "number"
            ? action.productId
            : typeof action.productId == "string"
                ? parseInt(action.productId)
                : undefined;

    if (productId) {
        const productOptions: IApiProductRequestPostParams = yield select(selectProductRequestOptions)
        const productKey = getProductCacheKey(productId, productOptions.lang, productOptions.debug)

        const cacheState: CacheState | undefined = yield select(selectProductCacheState, productKey);

        switch (cacheState) {
            case undefined:
            case CacheState.ServerNotResponse:
            case CacheState.Unknown: {
                yield put(triggerStartLoadingOverlay())
                yield put(triggerCacheRequestProductWithCallback(productId, action.callback))
                break;
            }
            case CacheState.Cache:
            case CacheState.NotFound: {
                if (action.callback) action.callback();
                break;
            }
            default:
                break;
        }
    }
}

function* cacheProductsRequestCallback(action: CacheAccessProductsAction) {

    const productIdsRaw = action.productIds
        .map(productId =>
            typeof productId == "number"
                ? productId
                : typeof productId == "string"
                    ? parseInt(productId)
                    : undefined)

    const productIds = productIdsRaw.filter(a => a) as number[]

    if (productIds.length) {
        yield put(triggerStartLoadingOverlay())
        yield put(triggerCacheRequestProductsWithCallback(productIds, action.callback))
    }
}

export function* watchCacheAction() {
    yield takeEvery(CACHE_ACCESS_SEARCH_WITH_CALLBACK, cacheSearchRequestCallback);
    yield takeEvery(CACHE_ACCESS_PRODUCT_WITH_CALLBACK, cacheProductRequestCallback);
    yield takeEvery(CACHE_ACCESS_PRODUCTS_WITH_CALLBACK, cacheProductsRequestCallback);
}

