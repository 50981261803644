import React, {MouseEventHandler, useEffect} from "react";
import {LinkProps, To, useHref, useLinkClickHandler} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {triggerCacheAccessSearchWithCallback} from "../../../Store/Action/cache.search";
import {buildSearchUrl} from "../../../Helpers/UrlFormatter.Search";
import {triggerDebug} from "../../../Store/Action/interactive";

type SearchRouteParamLinkProp = Omit<LinkProps, "to"> & {
    urlPageParams: IUrlResolvedParams
}

const SearchRouteParamLink = React.forwardRef<HTMLAnchorElement, SearchRouteParamLinkProp>((props, ref) => {

    const dispatch = useDispatch();

    // extract <Link/> component props, for pass rest to <a/> tag
    const {
        replace,
        state,
        target,
        className,
        urlPageParams,
        ...rest
    } = props;

    const urlContext = useSelector((state: IAppState) => state.urlContext )

    const [toPathname, toSearch, isValidLink] = buildSearchUrl(urlPageParams, urlContext)

    useEffect(() => {
        if (!isValidLink) {
            console.log("Search link error");
            console.log(toPathname, toSearch, urlPageParams, urlContext)
            const trace = new Error().stack
            console.log(trace)
            dispatch(triggerDebug("Search link error (console)", trace))
        }
    },[])

    const to: To = {
        pathname: toPathname,
        search: toSearch
    }

    const isDebugEnabled = useSelector((state: IAppState) => state.config.debug)
    const href = useHref(to);

    const navigate = useLinkClickHandler(to, {
        replace,
        state,
        target,
    });

    const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault();

        dispatch(triggerCacheAccessSearchWithCallback(urlPageParams, false, isDebugEnabled, () => {
            navigate(e);
        }))
    }

    return (
        <a href={href}
           ref={ref}
           className={className + ` cursor-pointer`}
           {...rest}
           onClick={handleClick}> {props.children}</a>
    )
})

export default SearchRouteParamLink;