import React, {useContext} from "react";
import {useSelector} from 'react-redux';
import {
    convertSearchParamsToUrlResolveParams,
    toggleColorFilter
} from "../../../Helpers/SearchHelper";
import {selectSearchCache} from "../../../Store/StoreHelper";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import SearchRouteParamLink from "../../App/Links/SearchRouteParamLink";
import {SearchRouteContext} from "../../../Store/Context/SearchRouteContext";

export default function FilterBarColorButton(props: { filterParam: IProductListSearch_ColorFilter }) {

    const {t} = useTranslation("TopBar");
    const getTranslation = function (key: keyof TranslationSet["TopBar"]) {
        return t(key);
    }

    const searchContext = useContext(SearchRouteContext);
    const searchData = useSelector((state: IAppState) => selectSearchCache(state, searchContext.cacheKey));

    const searchQuery = searchData && searchData.object ? searchData.object.searchQuery : undefined
    const filterParam = props.filterParam;

    let activeColor: string | undefined = undefined;
    if (filterParam.hh !== undefined && filterParam.hl !== undefined &&
        filterParam.sh !== undefined && filterParam.sl !== undefined &&
        filterParam.lh !== undefined && filterParam.ll !== undefined) {

        const h = ((filterParam.hh - filterParam.hl) / 2) + filterParam.hl
        const s = ((filterParam.sh - filterParam.sl) / 2) + filterParam.sl
        const l = ((filterParam.lh - filterParam.ll) / 2) + filterParam.ll

        activeColor = `hsl(${h}, ${s}%, ${l}%)`;
    }

    const currentFilters: IProductListSearch_Filter[] = searchQuery && searchQuery.filter ? [...searchQuery.filter] : [];
    const nextFilters = toggleColorFilter(currentFilters, undefined, undefined, undefined, undefined, undefined, undefined)

    return <SearchRouteParamLink
        key="c"
        className="btn btn-active-filter ml-1"
        title={getTranslation("hint_remove_filter")}
        urlPageParams={convertSearchParamsToUrlResolveParams({
            ...searchQuery,
            filter: nextFilters,
        })}
    >
        <div className="inline-block w-4 h-4 border rounded-sm align-middle" style={{background: activeColor}}></div>
        <img src="/resources/icon-remove-white.png" className="inline-block" alt="Remove Filter"/>
    </SearchRouteParamLink>
}
