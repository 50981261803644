import React, {MouseEventHandler} from "react";
import {LinkProps, useHref, useLinkClickHandler} from "react-router-dom";
import {useDispatch} from "react-redux";
import {triggerCacheAccessProductWithCallback} from "../../../Store/Action/cache.product";
import {parseProductUrl} from "../../../Helpers/UrlFormatter.Product";

const ProductRouteLink = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {

    // extract <Link/> component props, for pass rest to <a/> tag
    const {
        replace,
        state,
        target,
        to,
        className,
        ...rest
    } = props;

    const dispatch = useDispatch();
    const href = useHref(to);
    const navigate = useLinkClickHandler(to, {
        replace,
        state,
        target,
    });

    const handleClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
        e.preventDefault();

        const productMatch = parseProductUrl(href as string, "");
        if (productMatch[0]) {
            const productId = productMatch[0]
            dispatch(triggerCacheAccessProductWithCallback(productId, () => {
                navigate(e);
            }))
        }
    }

    return (
        <a href={href} ref={ref} className={className + ` cursor-pointer`} {...rest}
           onClick={handleClick}>{props.children}</a>
    )
})

export default ProductRouteLink;