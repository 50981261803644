export const LOAD_TRACKING_PAGE_CHANGE = "LOAD_TRACKING_PAGE_CHANGE";
export const LOAD_TRACKING_VISITED = "LOAD_TRACKING_VISITED";
export const LOAD_TRACKING_ACTION = "LOAD_TRACKING_ACTION";

export type TrackingPageChangeAction = {
    type: string,
    newUrl: string,
}

export const triggerPageChange = (newUrl: string): TrackingPageChangeAction => ({
    type: LOAD_TRACKING_PAGE_CHANGE,
    newUrl: newUrl,
});

export type TrackingVisitedAction = {
    type: string,
    newToken: string,
    newUrl: string,
}

export const triggerVisited = (newToken: string, newUrl: string): TrackingVisitedAction => ({
    type: LOAD_TRACKING_VISITED,
    newToken: newToken,
    newUrl: newUrl,
});

export type TrackingActionAction = {
    type: string,
    action: string,
    param: string,
}

export const triggerAction = (action: string, param: string): TrackingActionAction => ({
    type: LOAD_TRACKING_ACTION,
    action: action,
    param: param
});