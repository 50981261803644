import React, {MouseEventHandler, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';

import {timeToNow} from "../../../Helpers/TimeHelper";
import {ProductImage} from "../../App/Common/Image";
import {
    triggerProductStateAddCompare,
    triggerProductStateRemoveCompare,
} from "../../../Store/Action/session.product";
import ProductRouteLink from "../../App/Links/ProductRouteLink";
import {addCompareProduct, removeCompareProduct} from "../../../Helpers/LocalStorageHelper";
import {CardControls} from "./CardControls";
import {
    triggerOpenVerifyProduct,
    triggerProductVerifyCategoryDelete, triggerProductVerifyCategorySave
} from "../../../Store/Action/session.verifyProduct";
import {MenuMergedProductImageVerification} from "../../Product/MenuMergedProductImageVerification";
import {createPortal} from "react-dom";
import {getMenuContainerNode} from "../../App/Layout/MenuContainer";
import {triggerImageVerification_MenuOpen} from "../../../Store/Action/session.verifyImage";
import {getEventMenuCloseCheck} from "../../../Helpers/EventHelper";
import {getDebugProductUrl} from "../../../Helpers/BackendHelper";
import {useTranslation} from "react-i18next";
import {TranslationSet} from "../../../Localization/i18n.constants";
import {formatNumber} from "../../../Helpers/StringFormatter.Number";
import {replaceSpecialUnicodeCharacters} from "../../../Helpers/StringFormatter.Text";
import {getDefaultLang, triggerDebug} from "../../../Store/Action/interactive";
import {buildProductUrl} from "../../../Helpers/UrlFormatter.Product";

/** @type TranslationKeys, keep this variable without TS type, for keep type check magic works*/
const ComponentTranslationKey = "SearchRoute"

/** @see ProductCardDemo */
export default function ProductCard(props: {
    product: IProduct | IMissingProduct,
    debug: IProductDebugData | undefined,
    className?: string
}) {

    // globals
    const dispatch = useDispatch()
    const {t} = useTranslation(ComponentTranslationKey)

    // internal functions
    const getTranslation = function (key: keyof TranslationSet[typeof ComponentTranslationKey]) {
        return t(key);
    }

    const [state, setState] = useState({
        mergedProductMenuVisible: false,
        mergedProductMenuX: 0,
        mergedProductMenuY: 0
    })

    const propsExtend = useSelector((state: IAppState) => {

        const product = props.product as ({
            id: number,
            gtinId: number | undefined
        } & Partial<IProduct & IMissingProduct>)
        return {
            product: product,
            productDebug: props.debug,

            userSessionId: state.session.userSessionId,

            isDebugEnabled: state.config.debug,
            isProductLiked: state.session.likedProducts.productIds.includes(props.product.id),
            isProductLikedPending: state.session.likedProducts.pendingProductIds.includes(props.product.id),
            isProductOwn: state.session.ownerProducts.productIds.includes(props.product.id),
            isProductOwnPending: state.session.ownerProducts.pendingProductIds.includes(props.product.id),

            isProductCompare: product.category_id && state.session.compareProducts[product.category_id]
                ? state.session.compareProducts[product.category_id].includes(props.product.id)
                : false,

            showVerify: state.config.debug,
            isProductVerified: state.session.shophunter.shophunterData?.verifiedProducts[props.product.id]
                ? state.session.shophunter.shophunterData?.verifiedProducts[props.product.id].verifiedCategoryId == product.category_id
                : false,
            isProductMoved: state.session.shophunter.shophunterData?.verifiedProducts[props.product.id]
                ? state.session.shophunter.shophunterData?.verifiedProducts[props.product.id].verifiedCategoryId != product.category_id
                : false,

            isProductVerifiedMergedProductImage: state.session.shophunter.verifiedMergedProductImageMergedProductIds.findIndex(a => a == props.product.id) !== -1,

            className: props.className,
            lang: state.config.lang || getDefaultLang()
        }
    })

    const onContextMenuClose = function () {
        setState({
            ...state,
            mergedProductMenuVisible: false,
        })
    }

    const onContextMenu: MouseEventHandler<HTMLElement> = (e) => {
        if (propsExtend.isDebugEnabled) {
            e.preventDefault()
            e.stopPropagation()

            window.dispatchEvent(getEventMenuCloseCheck(e.target as HTMLElement))

            setState({
                mergedProductMenuVisible: true,
                mergedProductMenuX: e.clientX,
                mergedProductMenuY: e.clientY
            })
            dispatch(triggerImageVerification_MenuOpen());
        }
    }

    const onToggleCompare: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (propsExtend.product.category_id) {
            if (propsExtend.isProductCompare) {
                removeCompareProduct(propsExtend.product.category_id, propsExtend.product.id)
                dispatch(triggerProductStateRemoveCompare(propsExtend.product.category_id, propsExtend.product.id));
            } else {
                addCompareProduct(propsExtend.product.category_id, propsExtend.product.id)
                dispatch(triggerProductStateAddCompare(propsExtend.product.category_id, propsExtend.product.id));
            }
        } else {
            removeCompareProduct(undefined, propsExtend.product.id)
            dispatch(triggerProductStateRemoveCompare(undefined, propsExtend.product.id));
        }
    }

    const onVerifyCategory: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (propsExtend.userSessionId && propsExtend.product.category_id) {
            if (propsExtend.isProductVerified) {
                dispatch(triggerProductVerifyCategoryDelete(propsExtend.userSessionId, propsExtend.product.id, propsExtend.product.category_id));
            } else {
                dispatch(triggerProductVerifyCategorySave(propsExtend.userSessionId, propsExtend.product.id, propsExtend.product.category_id));
            }
        }
    }

    const onVerifyModalOpen: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (propsExtend.product.name) {
            const product = propsExtend.product as IProduct
            dispatch(triggerOpenVerifyProduct(product));
        }
    }

    const onBackendFixClick: MouseEventHandler<HTMLElement> = (e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(getDebugProductUrl(propsExtend.product.id), 'backend');
    }

    const debug = propsExtend.isDebugEnabled ? propsExtend.productDebug : undefined;

    const uniqueNamePathTitleParts: string[] = [];
    const uniqueNamePath: React.ReactElement[] = []
    if (propsExtend.product.uniqueNamePath) {
        for (const [i, item] of propsExtend.product.uniqueNamePath.entries()) {
            uniqueNamePath.push(
                <span className={item.type} key={`${item.type}-${i}`}>{item.name}</span>
            )
            uniqueNamePathTitleParts.push(item.name);
        }
    } else {
        if (propsExtend.product.manufacturer) {
            uniqueNamePath.push(
                <span className="manufacturer" key="manufacturer">{propsExtend.product.manufacturer.name}</span>
            )
            uniqueNamePathTitleParts.push(propsExtend.product.manufacturer.name);
        }
        if (propsExtend.product.family_chain && propsExtend.product.family_chain.length > 0) {
            for (const [i, family] of propsExtend.product.family_chain.entries()) {
                uniqueNamePath.push(
                    <span className="family" key={`family-${i}`}>{family.name}</span>
                )
                uniqueNamePathTitleParts.push(family.name);
            }
        }
    }

    const htmlFeatures: React.ReactElement[] = []
    if (propsExtend.product.features) {
        for (const [i, feature] of propsExtend.product.features.entries()) {
            htmlFeatures.push(
                <li className={`color-affected-px-4 flex-none ${feature.checked ? "checked" : "text-gray-500"}`}
                    title={feature.attribute.replace('"', "'")}
                    key={`feature` + i}>
                    <span>
                    {feature.value}
                        </span>
                </li>
            )
        }
    }
    //add empty features to fill the space
    if (propsExtend.product.featureAttributeMaxCount) {
        const emptyFeatureCount = propsExtend.product.featureAttributeMaxCount - htmlFeatures.length;
        for (let i = 0; i < emptyFeatureCount; i++) {
            htmlFeatures.push(
                <li className="`color-affected-px-4 flex-none text-white" key={`empty-feature` + i}>
                    &nbsp;
                </li>
            )
        }
    }

    const [productUrl, isValidLink] = propsExtend.product.gtinId
        ? buildProductUrl(propsExtend.product.gtinId, propsExtend.product.name, propsExtend.product.category_name)
        : buildProductUrl(propsExtend.product.id, propsExtend.product.name, propsExtend.product.category_name);
    if (!isValidLink) {
        console.log("Product link error");
        console.log([propsExtend.product.name, propsExtend.product.category_name])
        const trace = new Error().stack
        console.log(trace)
        dispatch(triggerDebug("Product link error (console)", trace))
    }

    return (
        <ProductRouteLink
            to={productUrl}
            className={
                `category-item-card color-affection ${propsExtend.className} category-item-card-controls-wrapper` +
                (propsExtend.isProductOwn ? ` card-own` : ``) +
                (propsExtend.isProductLiked ? ` card-like` : ``) +
                (propsExtend.isProductCompare ? ` card-compare` : ``) +
                (propsExtend.isProductVerified ? ` card-verified` : ``) +
                (propsExtend.isProductMoved ? ` card-moved` : ``)}>
            <div
                className={`card-section-rigid color-affected-p-2-top relative bg-white ${propsExtend.isProductVerifiedMergedProductImage ? `card-image-green-border` : ``}`}
                onContextMenu={onContextMenu}>

                <ProductImage imageId={propsExtend.product.image_id || 0}
                              alt={propsExtend.product.name || ""}
                              size={'m'}/>

                {debug ? (
                    <div className="absolute top-0 right-2 text-right p-2">
                        <div><span className="bg-white/50">{debug.rank}</span></div>
                        <div><span className="bg-white/50">MP# {propsExtend.product.id}</span></div>
                        <div><span className="bg-white/50">{debug.original_status}</span></div>
                        <div><span className="bg-white/50">
                                R: {debug.created_at ? timeToNow(debug.created_at) : '???'}
                            </span></div>
                        <div><span className="bg-white/50">
                                SC: {debug.last_successful_crawl ? timeToNow(debug.last_successful_crawl) : '???'}
                            </span></div>
                        <div><span className="bg-white/50">
                                VarGrp: {debug.var_group_id !== undefined ? debug.var_group_id : 'NULL'}
                            </span></div>
                        <div><span className="bg-white/50">
                                VarSizeGrp: {debug.var_size_group_id !== undefined ? debug.var_size_group_id : 'NULL'}
                            </span></div>
                    </div>
                ) : undefined}

                {debug ? (
                    <div className="absolute top-0 left-2 p-2">
                        {propsExtend.product.description &&
                            <div><span className="bg-blue-700/50 text-white px-0.5">DESC</span></div>}
                    </div>
                ) : undefined}

                {propsExtend.isDebugEnabled ? (
                    <div className="absolute bottom-0 right-2 text-right p-2">

                        {propsExtend.product.color1 ? (
                            <div><span className="bg-white/50">
                                    Color: <div className="color-preview-wrapper">
                                    <div className="color-preview" style={{
                                        backgroundColor: `hsl(${propsExtend.product.color1.h}, ${propsExtend.product.color1.s}%, ${propsExtend.product.color1.l}%)`
                                    }}></div>
                                </div></span></div>
                        ) : undefined}

                        {propsExtend.product.color2 ? (
                            <div><span className="bg-white/50">
                                    Color 2: <div className="color-preview-wrapper">
                                    <div className="color-preview" style={{
                                        backgroundColor: `hsl(${propsExtend.product.color2.h}, ${propsExtend.product.color2.s}%, ${propsExtend.product.color2.l}%)`
                                    }}></div>
                                </div></span></div>
                        ) : undefined}

                    </div>
                ) : undefined}

                {propsExtend.isDebugEnabled ? (
                    <div className="absolute bottom-0 left-2 p-2">
                            <span onClick={onBackendFixClick}
                                  onContextMenu={(e) => {
                                      e.stopPropagation();
                                  }}
                                  className="backend-btn cursor-pointer">FIX</span>
                    </div>
                ) : undefined}

            </div>

            <div className="card-section-stretch flex flex-col bg-white">

                {
                    uniqueNamePath.length > 0
                        ? <div className="color-affected-px-4 flex-none text-gray-500 unique-name-path"
                               title={uniqueNamePathTitleParts.join(' ')}>
                            {uniqueNamePath}
                        </div>
                        : <div className="color-affected-px-4 flex-none text-gray-500 unique-name-path">
                            {` `}
                        </div>
                }

                <div className="color-affected-px-4 flex-1">
                    {!propsExtend.product.name ?
                        <div className={`text-center text-base text-gray-400`}>
                            {getTranslation('product_not_found_anymore')}
                        </div>
                        : null}

                    <div className="font-bold product-title-two-rows">
                        {propsExtend.isDebugEnabled && propsExtend.product.name
                            ? propsExtend.product.name
                            : replaceSpecialUnicodeCharacters(propsExtend.product.name)}
                    </div>

                    <ul className="list-disc list-inside feature-list mb-4 mt-2">{htmlFeatures}</ul>

                </div>

                {(
                    (propsExtend.product.priceMinInclVat) ? (
                        <div className="color-affected-px-4 color-affected-pb-4 flex-none text-gray-500 ">
                            <div className="flex justify-between">
                                {<div className="price-panel">
                                    {formatNumber(propsExtend.product.priceMinInclVat)}
                                    <span>{' '}{propsExtend.product.priceCurrency}</span>
                                </div>}

                                <div className="flex">
                                    <div className="text-gray-500 self-center">
                                            <span className="text-gray-500">
                                                {propsExtend.product.productOffersCountBuyable && propsExtend.product.productOffersCountBuyable > 0
                                                    ? propsExtend.product.productOffersCountBuyable
                                                    : undefined}
                                                {propsExtend.product.productOffersCountTotal && propsExtend.product.productOffersCountTotal > 0
                                                    ? ` (` + propsExtend.product.productOffersCountTotal + `)`
                                                    : undefined}
                                                <div className="counter-pair counter-retailers"/>
                                            </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    ) : ''
                )}
            </div>

            <CardControls productId={propsExtend.product.id}
                          productIsMissing={!propsExtend.product.name}
                          isLike={propsExtend.isProductLiked}
                          isLikePending={propsExtend.isProductLikedPending}
                          isOwn={propsExtend.isProductOwn}
                          isOwnPending={propsExtend.isProductOwnPending}
                          isCompare={propsExtend.isProductCompare}
                          onCompare={onToggleCompare}
                          isVerify={propsExtend.isProductVerified}
                          showVerify={propsExtend.showVerify}
                          onVerifyCategory={onVerifyCategory}
                          onVerifyModalOpen={onVerifyModalOpen}/>

            {state.mergedProductMenuVisible && createPortal(
                <MenuMergedProductImageVerification
                    imageIds={propsExtend.product.image_ids || []}
                    productName={propsExtend.product.name || ""}
                    productId={propsExtend.product.id}
                    categoryId={propsExtend.product.category_id}
                    userSessionId={propsExtend.userSessionId}
                    x={state.mergedProductMenuX}
                    y={state.mergedProductMenuY}
                    closeCallback={onContextMenuClose}/>,
                getMenuContainerNode()
            )}
        </ProductRouteLink>
    );
}
