import React from "react";
import {
    convertSearchParamsToUrlResolveParams,
    toggleSearchParamsRelatedUniqueNamePathFilter
} from "../../../Helpers/SearchHelper";
import {triggerCacheAccessSearchWithCallback} from "../../../Store/Action/cache.search";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {getDefaultLang, triggerDebug} from "../../../Store/Action/interactive";
import {formatNumber} from "../../../Helpers/StringFormatter.Number";
import {buildSearchUrl} from "../../../Helpers/UrlFormatter.Search";

export default function FilterRelatedUniqueNamePathOption(props: {
    item: IFilterPanel_UniqueNamePathValue,
    searchQuery: IProductListSearch_Params | undefined,
    isDebugEnabled: boolean,
    demonstrationMode: boolean,
    intendLeft: boolean,
    toggleCallback?: () => void
}) {
    const item = props.item;

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const urlContext = useSelector((state: IAppState) => state.urlContext)

    const toggleFilter = (uniqueNamePathId: number) => {

        if (props.demonstrationMode)
            return;

        const currentFilters: IProductListSearch_Filter[] = props.searchQuery && props.searchQuery.filter ? [...props.searchQuery.filter] : [];

        const urlPageParams = convertSearchParamsToUrlResolveParams({
            ...props.searchQuery,
            filter: toggleSearchParamsRelatedUniqueNamePathFilter(currentFilters, uniqueNamePathId),
        })

        dispatch(triggerCacheAccessSearchWithCallback(urlPageParams, false, props.isDebugEnabled, () => {
                const [toPathname, toSearch, isValidLink] = buildSearchUrl(urlPageParams, urlContext);
                if (!isValidLink) {
                    console.log("Search link error");
                    console.log(toPathname, toSearch, urlPageParams, urlContext)
                    const trace = new Error().stack
                    console.log(trace)
                    dispatch(triggerDebug("Search link error (console)", trace))
                }

                navigate({
                    pathname: toPathname,
                    search: toSearch
                })
                if (props.toggleCallback)
                    props.toggleCallback()
            }
        ))
    }

    return <div className={`filter-panel-unique-name-path-name-count-pair ${item.checked ? 'checked' : ''}`}>
        <div className={`filter-panel-unique-name-path-name-align`}>

            <div className={`filter-panel-unique-name-path-header`}
                 onClick={() => {
                     toggleFilter(item.id)
                 }}>

                <div>
                    <div className={`filter-panel-unique-name-path-checkbox ${props.intendLeft ? `intend-left` : ``}`}>
                        {item.checked
                            ? <img className="inline-block"
                                   src="/resources/boolean-yes.png"
                                   alt={`Selected Unique Name Path`}></img>
                            : null}
                    </div>
                </div>

                <div>{item.name}</div>
            </div>
        </div>

        <div className={`filter-panel-primary-secondary-count-align`}>
                <span
                    className={`filter-panel-primary-count`}>{formatNumber(item.primaryCount, 0, 'sv', undefined, ' ')}</span>
            {props.isDebugEnabled
                ? <span
                    className={`filter-panel-secondary-count`}>({formatNumber(item.count - item.primaryCount, 0, 'sv', undefined, ' ')})</span>
                : <span className={`filter-panel-secondary-count-placeholder`}></span>}
        </div>
    </div>
}