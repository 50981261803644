import React from "react";

export const getMenuPositionForPoint = function (x: number, y: number, estimateMenuWidth: number, estimateMenuHeight: number) {

    const screenHeight = document.body.clientHeight
    const screenWidth = document.body.clientWidth

    const edgePadding = 16

    const menuLocationY = Math.min(y, screenHeight - estimateMenuHeight - edgePadding)

    let menuLocationX = 0
    if (x + estimateMenuWidth + edgePadding < screenWidth)
        menuLocationX = x
    else
        menuLocationX = x - estimateMenuWidth

    return {x: menuLocationX, y: menuLocationY}
}

export const getMenuPositionForRect = function (x: number, y: number, w: number, h: number, estimateMenuWidth: number, estimateMenuHeight: number) {

    const screenHeight = document.body.clientHeight
    const screenWidth = document.body.clientWidth

    const edgePadding = 16

    const menuLocationY = Math.min(y, screenHeight - estimateMenuHeight - edgePadding)

    let menuLocationX = 0
    if (x + w + estimateMenuWidth + edgePadding < screenWidth)
        menuLocationX = x + w
    else
        menuLocationX = x - estimateMenuWidth


    return {x: menuLocationX, y: menuLocationY}
}

export const getMenuContainerNode = function () {
    const elements = document.getElementsByClassName('js-target-menu-container');
    if (elements.length)
        return elements[0];
    return document.body;
}

export default function MenuContainer(props: {}) {
    return <div className={`fixed top-0 left-0 w-0 h-0 overflow-visible`}>
        <div className={`relative overflow-visible js-target-menu-container`}></div>
    </div>
}