export const enTranslation = {
    Footer: {
        add_store_brand: 'Add store / brand',

        about_us: 'About us',
        about_shopit: 'About Shopit',
        contact: 'Contact',
        work_at_shopit: 'Work at Shopit',
        news: 'News',
        disclaimer_and_limitations: 'Disclaimer & Limitation of Liability',
        our_unique_technology: 'Our unique technology',
        personal_data_policy: 'Personal data policy',
        cookie_policy: 'Cookie policy',

        stores: 'Stores',
        to_on_shopit: 'To on Shopit',
        all_stores: 'All Stores',
        add_your_store: 'Add your stores',
        login_for_stores: 'Login for stores',

        consumers: 'Consumers',
        how_it_works: 'This is how it works',

        shopit_desc: 'Shopit is Sweden\'s new AI-based shopping search engine where you, as a consumer, can search and compare millions of products in over 100,000 categories to find the right product and store to shop from.'
    },
    FilterPanel: {
        manufacturer: 'Manufacturers/Brands',
        price: 'Price',
        color: 'Visual Colour',
        extra: 'Extra',
        mainType: 'Main Type'
    },
    FilterColor: {
        selectColor: 'Select Colour'
    },
    FilterStaticManufacturer: {
        manufacturer: 'Manufacturers/Brands',
        search: 'Search',
        show: 'Show',
        more_options: 'more options',
        show_less_options: 'Show less options',
        no_result: 'Nothing was found',
    },
    FilterStaticBranding: {
        brands: 'Brands',
        search: 'Search',
        show: 'Show',
        more_options: 'more options',
        show_less_options: 'Show less options',
        no_result: 'Nothing was found',
    },
    FilterStaticUniqueNamePath: {
        show: 'Show',
        more_options: 'more options',
        show_less_options: 'Show less options',
        search: 'Search',
        no_result: 'Nothing was found',
    },
    FilterStaticStat: {
        image: 'Images',
        video: 'Videos',
        comment: 'Comments',
        share: 'Shares'
    },
    SearchBox: {
        placeholder: 'What are you looking for?',
        search: 'Search',
        discover: 'Discover'
    },
    SearchAsYouType: {
        product: 'Products',
        productSecondary: 'Products without price / image etc',
        category: 'Categories',
        manufacturer: 'Manufacturer / product families',
        branding: 'Branding',
        vendor: 'Vendors',
        code: 'Model / EAN codes',
        other: 'Free search',
        unknown: 'Other',
        error: 'Something went wrong, please try again later.',
        no_result: 'Nothing was found',
        seconds: 'seconds',
        uncategorized: "Uncategorized",
        in: "in",
        from: "from",
        uniqueNamePath: "Product Model",
        relatedUniqueNamePathCollection: "Accessories & Spare Parts",
        searchResultsFor: 'Search results for'
    },
    TopBar: {
        show_debug: 'Show Debug',
        list_debug_info: 'List debug information',
        categories: 'Categories',
        filters: 'Filters',
        hint_filters: 'Show filter panel',
        hint_categories: 'Show category tree',
        hint_remove_filter: 'Remove filter',
        other_manufacturers: 'Other manufacturers',
        FilterBarNumericButton: {
            price: 'Price'
        }
    },
    Grouping: {
        sort_by: 'Sort by',
        manufacturer: {
            rank: 'Manufacturer - Rank',
            price_lowest: 'Manufacturer - Lowest Price',
            price_highest: 'Manufacturer - Highest Price'
        },
        branding: {
            rank: 'Branding - Rank',
            price_lowest: 'Branding - Lowest Price',
            price_highest: 'Branding - Highest Price'
        },
        retailer: {
            rank: 'Retailer - Rank',
            price_lowest: 'Retailer - Lowest Price',
            price_highest: 'Retailer - Highest Price'
        },
        price: {
            lowest: 'Lowest Price',
            highest: 'Highest Price'
        }
    },
    LoginBox: {
        login_button_text: 'login'
    },
    StoreBox: {
        store_name: 'Store Name',
        store_website_url: 'Store Website URL',
        feed_url: 'Store Feed URL',
        login_email_for_admin: 'E-mail (for admin)',

        login_email: 'E-mail',
        password: 'Password',
        forgot_password: 'Forgot password',
        log_in: 'Log in',

        restore_password: 'Restore password',
        submit: 'Submit',

        company_name: 'Company Name',
        post_address: 'Address',
        post_code: 'Post code',
        post_city: 'Post city',
        organization_number: 'Organization Number',

        contact_name: 'Contact person name',
        contact_phone: 'Contact phone',

        next: 'Next Step',
        message: 'Ett e-postmeddelande har skickats till "<email>". Klicka på länken i meddelandet för att slutföra registreringen'
    },
    NavigationBox: {
        login: 'Login',
        logout: 'Logout',
        settings: 'Settings',
        manager_settings: 'Manage',
        show_debug: 'Show debug'
    },
    ListPagination: {
        previous: 'previous',
        next: 'next'
    },
    Item: {
        merge: 'Is this the same product?',
        follow: 'Follow',
        branding: 'Branding',
        manufacturer: 'Manufacturer',
        vendor: 'Vendor',
        show: 'Show',
        products: 'products'
    },
    LegendControls: {
        verification_modal_title: 'Select The Correct Category For This Product',
        own: 'Add to owned products',
        own_active: 'Remove from owned products',
        like: 'Add to liked products',
        like_active: 'Remove from liked products',
        compare: 'Compare this product',
        compare_active: 'Remove from compare products',
        verify: 'Product is in the WRONG category',
        verify_active: 'Product is in the WRONG category',
        verify_category: 'Product is in the CORRECT category',
        verify_category_active: 'Product is in the CORRECT category',
    },
    ShowMore: {
        show_additional: 'Show additional',
        without_price: 'without image/price',
        products: 'products',
        other_manufacturers: 'Other manufacturers',
        other_retailers: 'Other retailers',
        other_brands: 'Other brands',
        from: 'from'
    },
    TableList: {
        product: 'Product',
        name: 'Name',
        price: 'Price'
    },
    Separator: {
        other_manufacturers: 'Other manufacturers',
        other_retailers: 'Other retailers',
        other_brands: 'Other brands',
        other: 'Other',
        fold: 'Hide',
        unfold: 'Show',
        branding: 'Branding',
        manufacturer: 'Manufacturer',
        vendor: 'Vendor',
        family: 'Family',
        family_chain: 'Family chain',
        no_product_family: 'Others',
    },
    ProductsGrid: {
        for: 'for',
    },
    Product: {
        edit_image: 'edit',
        image_feedback: 'What do you think of the picture?',
        price_from: 'Price from',
        CategoryPathBar: {
            uncategorized: "Uncategorized",
        },
        TabsSection: {
            retailers: 'Vendors',
            prices: 'Prices',
            specifications: 'Specifications',
            reviews: 'Reviews',
            videos: 'Videos',
            accessories: 'Accessories',
            parts: 'Parts',
            for: 'for',
            no_instances: 'Sorry, we dont have any retailers selling this product at this time'
        },
        Tab: {
            ProductOffers: {
                discontinued: 'Discontinued',
                too_old: 'Too old data',
                in_stock: 'In stock',
                backorder: 'Backorder',
                out_of_stock: 'Out of stock',
                manufactured_on_order: 'Manufactured on order',
                pre_order: 'Pre order',
                stock_unknown: 'Unknown',
                visit_the_store: 'Go to store',
                store: 'Store',
                product: 'Product',
                price: 'Price',
                with_cashback: 'With Cashback',
                including_VAT: 'incl VAT',
                availability: 'Availability',
                link: 'Link',
                international_products: 'International retailers',
                available_vendors: 'Available vendors',
                duplicate_vendors: '[D] duplicates',
                only_international_vendors: 'International retailers',
                previous_vendors: 'Previous vendors',
                price_out_of_range: '[D] Price out of range',
                status_not_buyable: '[D] Not Buyable',
                no_available_primary_products: 'We have no stores selling this product at the moment',
                show_more_used_refurbished_products_part_1: 'Show',
                show_more_used_refurbished_products_part_2: 'used / refurbished products',
                retailers: 'Retailers',
                user_or_refurbished: 'Used / Refurbished',
                day : "day",
                days : "days",
                from : "from",
                up_too: "up to",
                not_paid: "This store is not a paying customer at Shopit and therefore have no direct link to the product.",
                stats: "We have found this product in <total_offers_count> stores in total, of which  <active_offers_count> are paying customers at Shopit and have a direct link to the store.",
                we_have_hidden_offers: "We have hidden <count> less good offers to make the list more comprehensible.",
                show_hidden_offers: "Show hidden offers.",
                hide_offers: "Hide <count> less good offers.",
            },
            Specifications: {
                product_removed: 'Product removed from Shopit',
                Attributes: {
                    attributes: 'Product Specifications',
                    yes: 'Yes',
                    no: 'No',
                    generic: 'Additional Attributes',
                    mainType: 'Main Type'
                },
                Brands: {
                    manufacturer_model: 'Manufacturer / Model',
                    manufacturer: 'Manufacturer',
                    model: 'Model',
                    branding_person: 'Trademarks / Persons',
                    branding: 'Branding',
                    person: 'Person / Designer',
                },
                Colors: {
                    color: 'Colour',
                    pattern: 'Pattern',
                    colors: 'Visual Colour'
                },
                Codes: {
                    model_codes: 'Model Codes',
                    sku: 'SKU-code',
                    MODEL_CODE: 'Model',
                    GTIN_SKU_UNKNOWN: 'Art.nr',
                    MODEL_VERSION: 'Version',
                    MODEL_YEAR: 'Model year',
                    GTIN_EAN_GS1: 'GS1/EAN-code',
                    GTIN_ISBN: 'ISBN-code',
                    GTIN_ISSN: 'ISSN-code',
                    GTIN_ISAN: 'ISAN-code',
                    GTIN_UPC: 'UPC-code',
                    GTIN_JPN: 'JPN-code',
                    GTIN_RSK: 'RSK-code',
                    SKU_RETAILER: 'Retailer art.nr',
                    SKU_MANUFACTURER: 'Manufacturer art.nr',
                    SKU_ASIN: 'ASIN-code'
                }
            }
        }
    },
    CompareProducts: {
        title: 'Comparing products in',
        compare: 'Compare',
        add_to_compare: 'Add products to compare',
        hint_compare_active: 'Show product comparisons',
        hint_compare_inactive: 'First mark the products you want to compare in the list',
    },
    MergeProducts: {
        title: 'Same product',
        same: 'This is the same product',
        merge: 'Merge',
        dont_merge: 'Don\'t merge'
    },
    CategoryVerification: {
        suggest_search_category: 'Suggestions / Search',
        search_category: 'Search Category',
        last_selected_categories: 'Last Selected',
        we_are_sorry: 'We are sorry...',
        nothing_to_suggest: 'Category suggestions are not available at the moment. Please try again a bit later.',
        try_again: 'Try Again',
        no_categories_were_found: 'No categories were found',
        no_latest_categories: 'You do not have latest categories',
        search_for_category_suggestion: 'Searching for category suggestions...',
        product_archived: 'Product Archived',
        product_duplicate: 'Product Duplicate',
        product_not_found: 'Product Not Found',
        cancel: 'Cancel',
        cancel_modal_title: '',
        cancel_modal_message: 'Do you want to CANCEL the move to another category?',
        title: 'Specify the correct category of the product'
    },
    CategoryVerificationMenu: {
        no_latest_categories: 'You do not have latest categories',
    },
    DetailsPage: {
        follow: 'Follow',
        map: 'Map',
        map_discloser: 'Currently we do not provide any map data, but we are working hard to be able to in the near future.',
        are_you_responsible: 'Are you responsible for this brand?',
        be_responsible: 'Click here to be responsible for',
        verify_as_manager_title: 'Be responsible for'
    },
    Brand: {
        followers: 'Followers',
        promotions: 'Promotions',
        products: 'Products',
        local_stores: 'Local Stores'
    },
    Retailer: {
        followers: 'Followers',
        promotions: 'Promotions',
        products: 'Products'
    },
    TitleBar: {
        na: 'This category is still under development. Please help us make it better!',
        of: 'of',
        results: 'results',
        active: 'active',
        seconds: 'seconds',
        search_for: 'The search for',
        yielded_many_results: 'yielded many results',
        fix_category_modal_title: 'You can help us make this category better',
        vendor: 'Retailer',
        branding: 'Brand',
        manufacturer: 'Manufacturer',
        has_products_in_category: 'has products in the following category',
        folowing_categories_contains_products_with_text: 'Following categories contain products with the text',
        search_results_for: 'Search results for',
    },
    FixCategory: {},
    Login: {
        one_click_away: 'You are only one click away!',
        almost_there: 'Almost there',
        unlimited_shopping: 'a world of unlimited shopping',
        create_account: 'Create Shopit account',
        facebook_login: 'Login with Facebook',
        feed_disclaimer: 'We will never post anything in your feed unless you share it yourself.',
        existing_account: 'Login with an existing account',
        forgot_password: 'Forgot your password?',
        unable_to_login: 'Unable to log in, try again in a few minutes',
        email: 'Email',
        password: 'Password',
        logged_in: 'Logged in',
        log_in: 'Log in',
        login: 'Login',

        or: 'or',

        new_user: 'New user',
        create_new_user: 'Create new user',
        first_name: 'First name',
        last_name: 'Surname',

        validation: {
            wrong_email_or_password: 'Wrong email or password.',
            cannot_log_in: 'Cannot log in at the moment. Please try again in a few moments.'
        }
    },
    Registration: {
        welcome: 'Welcome to Shopit!',
        full_power_of_shopit: 'Get ready to experience the full power of Shopit!',
        create_account: 'Create Account',
        unable_to_register: 'Could not register, try again in a few minutes',
        first_name: 'First name',
        last_name: 'Surname',
        email: 'Email',
        email_repeated: 'Repeat Email',
        password: 'Password',
        registered: 'Account created!',
        validation: {
            password_length: 'Password should be between 6-72 characters.',
            email_taken: 'There is already an account registered with this email.',
            emails_mismatch: 'The supplied emails does not match'
        }
    },
    RestrictedPage: {
        need_to_be_logged: 'You need to be logged in to access this page'
    },
    User: {
        Page: {
            Boards: {
                my_private_boards: 'My private boards',
                my_public_boards: 'My public boards',
                my_owned_products: 'Products I already own',
                my_liked_products: 'Products I like / would like to buy',
                my_compare_products: 'Products I am comparing',
                new_board: 'New board',
                new_public_board: 'New public board',
                create: 'Create'
            }
        },
        Settings: {
            Tab: {
                User: {
                    title: 'Name & User',
                    sub_title: 'Update your password and change other information.',
                    first_name: 'First name',
                    last_name: 'Last name',
                    email: 'Email',
                    old_password: 'Old Password',
                    new_password: 'New Password',
                    repeat_new_password: 'Password Repeated',
                    year: 'Year',
                    month: 'Month',
                    day: 'Day',
                    born: 'Born',
                    gender: 'Gender',
                    male: 'Male',
                    female: 'Female',
                    missing_password: 'To change password enter new password (repeated)',
                    password_missmatch: 'New password and repeated password does not match',
                    wrong_password: 'Wrong password',
                },
                Description: {
                    title: 'Picture & Description',
                    sub_title: 'Update your profile picture and background image. You may also add a description of yourself.',
                    avatar: 'Avatar',
                    description: 'Description',
                    background: 'Background image'
                },
                Localization: {
                    title: 'Place & Language',
                    sub_title: 'Update your language and locality. By submitting this information we can give you information about products that are for sale in close to you!',
                    address: 'Address',
                    zip_code: 'Zip code',
                    city: 'City',
                    country: 'Country',
                    currency: 'Currency',
                    language: 'Language',
                    language_code: {
                        SV: 'Swedish',
                        EN: 'English',
                        INT: 'International'
                    }
                },
                Shopping: {
                    title: 'Shopping DNA',
                    sub_title: 'We want to know a bit more about you. Tell us who you are by sliding the sliders in the direction you identify with.'
                },
                Interests: {
                    title: 'Interests',
                    sub_title: 'Tell us what you are interested in! By doing so, we can give you more precise search results and find products you might like.'
                },
                Privacy: {
                    title: 'Privacy & Filtering',
                    sub_title: 'Set your privacy level and products you want to exclude from showing when searching.'
                }
            }
        }
    },
    Form: {
        success_message: 'Saved',
        drag_and_drop: 'Drag image to upload',
        or_click_to_select: 'or click to select',
        save: 'Save',
        submit: 'Submit'
    },
    Image: {
        no_image: 'no image',
        not_found: 'not found',
        fullsize: 'Show in full size',
        feedback: 'What do you think of the picture?',
        edit: 'edit'
    },
    ImageVerificationMenu: {
        status_main: "Main Product Image",
        status_unrelated: "Unrelated For Product",
        status_ok_image: "OK for Product",
        status_accessory: "Accessory for Product",

        type_isolated: 'Isolated',
        type_light_gradient: 'Light Gradient',
        type_gradient: 'Gradient',
        type_solid: 'Solid',
        type_homogen: 'Homogen',
        type_drawing: 'B&W Drawing',
        type_bad_soft_pixels: 'Bad Soft Pixels',
        type_bad_uncropped: 'Bad Uncropped',

        content_type_product_one: 'Product (One)',
        content_type_product_in_usage: 'Product in Use',
        content_type_product_multiple_variants: 'Multiple Variants',
        content_type_product_multiple_same: 'Multiple Same',
        content_type_product_one_accessory_or_part: 'One Accessory / Part',
        content_type_product_set_or_collection: 'Set or Collection',
        content_type_product_screenshot: 'Screenshot',
        content_type_product_and_cover: 'Product with Cover',
        content_type_product_cover_only: 'Product Cover Only',
        content_type_package_unique: 'Package (Unique)',
        content_type_package_back: 'Package Back',
        content_type_package_generic: 'Package Generic',
        content_type_package_and_product: 'Package and Product',
        content_type_perspective_closed: 'Persp_ Closeup',
        content_type_perspective_front: 'Persp_ Front',
        content_type_perspective_side: 'Persp_ Side',
        content_type_perspective_top: 'Persp_ Top',
        content_type_perspective_bottom: 'Persp_ Bottom',
        content_type_perspective_back: 'Persp_ Back',
        content_type_perspective_open: 'Persp_ Inside / Open',
        content_type_perspective_closed_folded: 'Persp_ Folded / Closed',
        content_type_perspective_multiple: 'Persp_ Multiple',
        content_type_perspective_environment: 'Persp_ Environment',
        content_type_icon_no_image: 'Icon No Image',
        content_type_icon_campaign: 'Icon Campaign',
        content_type_icon_symbol_or_function: 'Symbol / Function',
        content_type_icon_website_design: 'Website Design',
        content_type_logo_brand: 'Brand Logo',
        content_type_logo_vendor: 'Vendor Logo',
        content_type_logo_product: 'Product Logo',
        content_type_logo_tech: 'Tech Logo',
        content_type_multiple_logos: 'Multiple Logos',
        content_type_text: 'Text',
        content_type_collage_text_image: 'Collage (Text / Image)',
        content_type_drawing_blueprint: 'Drawing Blueprint',
        content_type_drawing_3d: 'Drawing 3D',
        content_type_drawing_illustration: 'Drawing Illustration',
        content_type_chart_graph: 'Chart Graph',
        content_type_chart_size: 'Chart Sizes',
        content_type_chart_energy_certificate: 'Chart Energy Certificate',
        content_type_human_model_focus_top: 'Human Focus Top',
        content_type_human_model_focus_bottom: 'Human Focus Bottom',
        content_type_human_model_full: 'Human Full',
        content_type_human_model_closeup: 'Human Closeup',
        content_type_human_model_side: 'Human Side',
        content_type_human_model_back: 'Human Back',
        content_type_human_model_head: 'Human Head',
        content_type_human_model_hands: 'Human Hands',
        content_type_human_model_feet: 'Human Feet/Legs',
        content_type_human_model_sitting: 'Human Sitting',
        content_type_human_model_multi_perspective: 'Human Multi-Persp_',
        content_type_human_model_multi_variants: 'Human Multi-Variant',
        content_type_human_model_environment: 'Human Environment',
        content_type_book_media_cover: 'Book / Media Cover',
        content_type_book_media_cover_back: 'Book / Media Cover Back',
        content_type_example_palette_color_material: 'Multi Colors / Materials',
        content_type_example_one_material_color: 'One Color / Material',
        content_type_example_one_pattern: 'One Pattern',
        content_type_bad_unrelated: 'Unrelated',

        overlay_overlay_text: 'Overlay Text',
        overlay_overlay_symbol: 'Overlay Symbol',
        overlay_overlay_logo: 'Overlay Logo',
        overlay_overlay_campaign: 'Overlay Campaign',
        overlay_overlay_measurement: 'Overlay Measurement',
        overlay_overlay_closeup: 'Overlay Closeup',
        overlay_overlay_graphics: 'Overlay Graphics',
        overlay_overlay_false: 'No Overlay',

        watermark_watermark_true: 'Watermark',
        watermark_watermark_false: 'Not Watermark',

        variants_0: 'No Variants',
        variants_1: '1 Variants',
        variants_2: '2 Variants',
        variants_3: '3 Variants',
        variants_4: '4 Variants',
        variants_5: '5+ Variants',

        delete: 'Reset',
        view_more: 'Show More Types',
        edit_image_type: 'Edit Image Type'
    },
    Recover: {
        recover_your_account: 'Recover Your Account',
        email: 'Email',
        email_sent: 'Instructions has been sent to your email',
        email_not_found: 'We did not recognize your email, please try again',
        email_invalid: 'Email you have entered is invalid',
        not_able_to_process_request: 'We are not able to process request, please try again in a few moments.',
        send: 'Send',
        cancel: 'Cancel',
        RecoverAccept: {
            enter_new_password: 'Enter New Password',
            password: 'Password',
            password_repeat: 'Repeat Password',
            password_error_does_not_match: 'Passwords do not match',
            password_error_too_long_or_short: 'Password must be at between 6 and 72 symbols',
            password_has_been_changed: 'Your password has been successfully changed.',
            redirect_in_10_seconds: 'You will be redirected to main page in 10 seconds.',
            not_able_to_process_request: 'We are not able to process request, please try again in a few moments.',
            url_invalid: 'It seems like url you are using is not valid anymore, please request a new one',
            change: 'Change',
            cancel: 'Cancel'
        }
    },
    FeedbackWithNavigation: {
        content_is_not_available: 'content_is_not_available',
        go_back_to_the_previous_page: 'go_back_to_the_previous_page',
        go_to_main_page: 'go_to_main_page',
        the_link_expired_or_unavailable: 'The link you followed have expired or unavailable',
        not_able_to_process_request: 'We are not able to process request, please try again in a few moments.',
        email_confirmation: 'Email confirmation',
        email_already_registered: 'Email you provided already in use'
    },
    FeedbackWithRedirect: {
        cancel_account_recover: 'Cancel Account Recover',
        cancel_account_success: 'Your request for the new password has been canceled.',
        redirect_in_10_seconds: 'You will be redirected to main page in 10 seconds.',
        email_confirmation: 'Email confirmation',
        email_confirmed: 'Email successfully confirmed.'
    },
    Messages: {
        taking_a_break: 'The search engine seems to be taking a break, try again in a little while...',
        filter_title: 'Filtering returned no results...',
        too_many_filters: '...remove some filters and try again.',
        sorry_title: 'We are sorry...',
        sorry: 'Sorry...',
        no_results: '...but unfortunatley your search didn\'t match any results.\nTry to change your search a bit to find more matches.',
        product: '...but this product was not found on Shopit anymore.',
        retailer: '...but this vendor was not found on Shopit anymore.',
        brand: '...but this brand was not found on Shopit anymore.',
        unknown_error: 'Something went wrong, please try again in a couple of minutes',
        category_not_available: 'This category is currently unavailable',
        sorry_empty_category: 'We are sorry, but there is no products in this category right now...',
        sorry_come_back_later: '...please feel free to come back later',
    },
    Manage: {
        add: 'Add',
        domain: 'www.domain.com',
        missing_a_vendor: 'Are you missing a store och brand in Shopit?',
        enter_a_website: 'Enter its webpage below, and we will add it!',
        Domain: {
            email: 'Your email',
            send_request: 'Send request',
            request_sent: 'A verification email has been sent. Follow the instructions in it to proceed with the request.',
            not_logged_in: 'Log in to request domain access.',
            invalid_email: 'Invalid email.',
            something_went_wrong: 'Something went wrong, please try again later.',
            confirm_domain_request: 'Confirm domain request',
            confirm_domain_request_for: 'Confirm domain request for',
            finish_request: 'Finish request',
            domain_already_managed: 'This domain is already managed by someone.',
            request_accepted: 'Request accepted.',
            request_rejected: 'Request canceled.',
            code_not_found: 'Invalid verification code. The code might have expired, request a new code and try again.',
            unauthorized: 'You are not authorized to request access to the domain.',
            cancel: 'Cancel'
        },
        Tab: {
            Feed: {
                title: 'Feed',
                sub_title: 'Settings for your feed.'
            },
            Statistics: {
                title: 'Statistics',
                sub_title: 'Here you can see statistics of your domains and products.'
            },
            PaidLinks: {
                title: 'Paid links',
                sub_title: 'Configure your paid links.'
            }
        }
    },
    External: {
        wait: "Hold on...",
        redirecting: "...we are now redirecting you to the retailer"
    },
    Attribute: {
        yes: 'Yes',
        no: 'No',
        show: 'Show',
        more_options: 'more options',
        show_less_options: 'Show less options'
    },
    Price: {
        uncertain: 'Uncertain',
        discontinued: 'Discontinued'
    },
    ConfirmModal: {
        yes: 'Yes',
        no: 'No'
    },
    ProductVariants: {
        select_variant: 'Select variant',
        select_size: 'Select size',
        select_color: 'Select color',
    },
    SearchRoute: {
        placeholder_card: 'Placeholder Card',
        placeholder_product: 'Placeholder Product',
        most_popular_in: 'Most popular products in',
        product_not_found_anymore: 'The product was not found anymore',
    },
    CookiePolicy: {
        title: 'Cookies och liknande lagringsteknik',
        policy: 'Shopit använder cookies och liknande tekniker för att samla in och behandla uppgifter såsom din IP-adress och information om hur du använder webbplatsen för analys och statistik, för att ge dig en bättre kundupplevelse och för relevant marknadsföring. Genom att klicka ”Acceptera” bekräftar du att du har läst vår cookiepolicy och att du samtycker till vår användning av cookies och liknande tekniker. Du kan anpassa dina inställningar genom att klicka ”Inställningar”, där du också hittar mer information om för vilka ändamål vi använder oss av cookies. Här kan du läsa mer om vår användning av cookies:',
        policy_link: 'Cookiepolicy',
        accept_all: 'Acceptera alla cookies',
        accept_neccesary: 'Accept only neccesary cookies',
        settings: 'Cookie-inställningar',

        allow_all: 'Tillåt alla',
        settings_title: 'Hantera inställningar för samtycke',

        required: 'Absolut nödvändiga cookies',
        required_status: 'Alltid aktiv',
        required_details: 'Dessa cookies är nödvändiga för att webbplatsen ska fungera och kan inte stängas av i våra system. De är vanligtvis bara inställda som svar på åtgärder som du gjort som utgör en begäran om tjänster, till exempel inställning av dina personliga preferenser, inloggning eller fyllning av formulär. Du kan ställa in din webbläsare för att blockera eller varna dig om dessa cookies, men vissa delar av webbplatsen fungerar inte då. Dessa cookies lagrar inte någon personligt identifierbar information.',

        analyse: 'Statistik & Analys',
        analyse_details: 'Dessa cookies tillåter oss att räkna besök och trafikkällor så att vi kan mäta och förbättra prestandan på vår webbplats. De hjälper oss att veta vilka sidor som är mest och minst populära och se hur besökare flyttar runt på webbplatsen. All information som dessa cookies samlar är aggregerade och därför anonyma. Om du inte tillåter dessa cookies vet vi inte när du har besökt vår webbplats.',

        functional: 'Funktionella cookies',
        functional_details: 'Dessa cookies gör det möjligt för webbplatsen att tillhandahålla förbättrad funktionalitet och personlig anpassning. De kan fastställas av oss eller av tredjepartsleverantörer vars tjänster vi har lagt till på våra sidor. Om du inte tillåter dessa cookies kanske vissa eller alla av dessa tjänster inte fungerar som de ska.',

        targeted: 'Riktade cookies',
        targeted_details: 'Dessa cookies ställs in på vår webbplats av våra annonseringspartners. De kan användas av dessa företag för att skapa en profil av dina intressen och visa dig relevanta annonser på andra webbplatser. De lagrar inte direkt personlig information, men är baserade på unikt identifierande av din webbläsare och internet-enhet. Om du inte tillåter dessa cookies kommer du att erfara mindre riktade annonser.',

        accept_selected: 'Bekräfta mina val',
        warn: 'Avvisa alla',

        cookie_info: 'Information om cookies',
        cookie_list: 'Cookie-lista',
        see_cookies: 'Se cookies',

        first_party_cookies: 'Förstapartscookies',

        name: 'Namn',
        duration: 'Varaktighet',
        duration_session: 'Session',
        duration_second: 'Några sekunder',
        duration_year: '365 Dagar',
        duration_week: '7 Dagar',
        duration_season: '92 Dagar',
        duration_month: '31 Dagar',
        type: 'Typ',
        type_first_party: '1:a part',
        type_third_party: '3:e part',
        category: 'Kategori',
        category_required: 'Absolut nödvändiga cookies',
        description: 'Beskrivning'
    },

    Meta: {
        indexTitle: 'Shopit - The new shopping and price comparison search engine',
        indexDescription: 'Shopit is Sweden\'s new AI-based search engine for shopping and price comparison. Compare 20 million products, in over 100,000 categories, from 2,000 merchants.',
        indexSearchHint: 'Search among 20 million products in over 100,000 categories from more then 2,000 merchants',

        searchCategoryCompare: 'Compare',
        searchCategoryCompareCounterSuffix: '',
        searchCategoryAtShopit: 'at Shopit.se',

        searchCollectionCompare: 'Compare prices on',

        productIn: ' in ',
        productSuffix: ' | Shopit.se',
        productFrom: 'from ',

        storeRegisterTitle: 'Add your store | Shopit.se',

        howItWorksTitle: 'How it works | Shopit - The shopping search engine',
        contactTitle: 'Contact Shopit | Shopit - The shopping search engine',
        aboutTitle: 'About | Shopit - The shopping search engine',
        cookiePolicyTitle: 'Cookie policy | Shopit - The shopping search engine',
        personalPolicyTitle: 'Personal policy | Shopit - The shopping search engine',
        disclaimerTitle: 'Disclaimer | Shopit - The shopping search engine',
        addYourStoreTitle: 'Add your store | Shopit - The shopping search engine',
        trendingSearches : 'Trending searches',

        categoryDescription : "Compare prices for <category_name> from many stores and find the best offer on Shopit.se. Save time and money on your purchase!"
    },

    Technology: {
        technologyTitle: 'Our unique technology | Shopit - The shopping search engine',
        ourUniqueTechnology : 'Our unique technology',
        readMore : 'Read more about our unique technologies at'
    },

    AllStores: {
        allStoresTitle: 'All Stores | Shopit - The shopping search engine',
        allStores: 'All stores on Shopit.se',
        allStoresDesc: 'Below you find all stores and merchants currently listing their products on Shopit. You can click on the letters below to filter the list on the first letter in name.',
        all: 'All',
        at : 'at',
        other : 'OTHER'
    }
};