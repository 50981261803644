export const CONFIG_DEBUG_TOGGLE = "TOGGLE_DEBUG";
export const CONFIG_DEBUG_SET_COOKIE = "UPDATE_DEBUG";

// ----

export type DebugActions = {
    type: string
}

export const triggerConfigDebugToggle = (): DebugActions => ({
    type: CONFIG_DEBUG_TOGGLE
})

export type DebugUpdateActions = {
    type: string,
    debugEnable: boolean
}

export const triggerConfigDebugSetCookie = (debugEnable: boolean): DebugUpdateActions => ({
    type: CONFIG_DEBUG_SET_COOKIE,
    debugEnable: debugEnable
})