import {useEffect} from "react";
import {
    deleteLinkCanonicalTag,
    deleteMetaNameTag,
    deleteMetaPropertyTag, deleteMetaStructuredDataTag,
    setDocumentTitle, setLinkCanonicalTag,
    setMetaNameTag,
    setMetaPropertyTag, setMetaStructuredDataTag
} from "./MetaHelper";
import {useLocation} from "react-router-dom";
import {getImageUrl} from "../../../Helpers/ImageHelper";

const activeMetaTags: { [key: string]: string | undefined } = {}

export function getActiveMetaTags() {
    return activeMetaTags
}

function applyTitle(title: string) {
    setDocumentTitle(title)
    setMetaPropertyTag('og:title', title)
    setMetaPropertyTag('og:image:alt', title)
    setMetaNameTag('twitter:title', title)
}

function deleteTitle() {
    setDocumentTitle('Shopit')
    deleteMetaPropertyTag('og:title')
    deleteMetaPropertyTag('og:image:alt')
    deleteMetaNameTag('twitter:title')
}

function applyImage(image: string) {
    setMetaPropertyTag('og:image', image)
    setMetaNameTag('twitter:image', image)
}

function deleteImage() {
    deleteMetaPropertyTag('og:image')
    deleteMetaNameTag('twitter:image')
}

function applyDescription(description: string) {
    setMetaNameTag('description', description)
    setMetaPropertyTag('og:description', description)
    setMetaNameTag('twitter:description', description)
}

function deleteDescription() {
    deleteMetaNameTag('description')
    deleteMetaPropertyTag('og:description')
    deleteMetaNameTag('twitter:description')
}

type MetaProps = {
    title?: string | undefined
    description?: string | undefined
    canonicalPageUrl: string | "default"
    structuredData?: any | undefined,
    robots?: "noindex" | undefined,
    imageId?: number | undefined,
}

export function MetaTags(props: MetaProps) {

    const location = useLocation()

    const title = props.title?.replace(/"/g, '\\x22').replace(/'/g, '\\x27')
    activeMetaTags['title'] = title

    useEffect(() => {
        if (title) applyTitle(title)
        else deleteTitle()
        return () => {
            deleteTitle()
        }
    }, [title])

    // ---

    const description = props.description?.replace(/"/g, '\\x22').replace(/'/g, '\\x27')
    activeMetaTags['description'] = description

    useEffect(() => {
        if (description) applyDescription(description)
        else deleteDescription()
        return () => {
            deleteDescription()
        }
    }, [description])

    // ---

    const image = props.imageId ? getImageUrl(props.imageId, "XL") : undefined
    activeMetaTags['image'] = image

    useEffect(() => {
        if (image) applyImage(image)
        else deleteImage()
        return () => {
            deleteImage()
        }
    }, [image])

    // ---

    const canonicalPageUrl = props.canonicalPageUrl
        ? props.canonicalPageUrl == "default"
            ? location.pathname
            : props.canonicalPageUrl
        : undefined
    activeMetaTags['canonicalPageUrl'] = canonicalPageUrl
    useEffect(() => {
        if (canonicalPageUrl) {
            const website = document.location.protocol + '//' + document.location.host
            setLinkCanonicalTag(website + canonicalPageUrl)
        } else deleteLinkCanonicalTag()
        return () => {
            deleteLinkCanonicalTag()
        }
    }, [canonicalPageUrl])

    // ---

    const url = location.pathname + location.search
    activeMetaTags['pageUrl'] = url

    useEffect(() => {
        const website = document.location.protocol + '//' + document.location.host
        setMetaPropertyTag('og:url', website + url)
        return () => {
            deleteMetaPropertyTag('og:url')
        }
    }, [url])

    // ---

    const structuredData = props.structuredData ? JSON.stringify(props.structuredData, null, 4) : undefined
    activeMetaTags['structuredData'] = structuredData

    useEffect(() => {
        if (structuredData) setMetaStructuredDataTag(structuredData)
        else deleteMetaStructuredDataTag()
        return () => {
            deleteMetaStructuredDataTag()
        }
    }, [structuredData])

    // ---

    activeMetaTags['robots'] = props.robots

    useEffect(() => {
        if (props.robots) setMetaNameTag('robots', props.robots)
        else deleteMetaNameTag('robots')
        return () => {
            deleteMetaNameTag('robots')
        }
    }, [props.robots])

    return null;
}

export function MetaLocale(locale: string) {
    activeMetaTags['locale'] = locale
}
