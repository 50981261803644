import * as React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {Request, Response} from 'express'

import App from "../Shared/Components/App";

// localization
import "../Shared/Localization/i18n";
import configureStore from "../Shared/Store";
import {i18n} from "../Shared/Localization/i18n";
import {
    setAvatarImageUrl,
    setBoardImageUrl,
    setDomainImageBaseUrl,
    setProductImageBaseUrl
} from "../Shared/Helpers/ImageHelper";
import {getCompareProducts, getMergedProductsWithVerifiedImageKey} from "../Shared/Helpers/LocalStorageHelper";
import {triggerProductStateSetCompare} from "../Shared/Store/Action/session.product";
import {
    triggerImageVerification_MarkImageVerified
} from "../Shared/Store/Action/session.verifyImage";
import {BrowserRouter} from "react-router-dom";

declare global {
    interface Window {
        shopitInitialState: IAppState;
    }
}

const serverRestoreState = (req: Request | undefined, res: Response | undefined, previousState: Partial<IAppState>): Promise<Partial<IAppState>> => {
    return new Promise<Partial<IAppState>>((a) => a(window.shopitInitialState));
}

const productSetStorePromise = configureStore(undefined, undefined, serverRestoreState);
productSetStorePromise.then((productSetStore) => {

    const state = productSetStore.getState();
    if (state.config?.lang) {
        i18n.changeLanguage(state.config?.lang)
    }

    setDomainImageBaseUrl(state.config.domainImageUrl)
    setAvatarImageUrl(state.config.avatarImageUrl)
    setBoardImageUrl(state.config.boardImageUrl)
    setProductImageBaseUrl(state.config.productImageUrl)

    ReactDOM.hydrate(
        <React.StrictMode>
            <Provider store={productSetStore}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </Provider>
        </React.StrictMode>,
        document.querySelector('[data-target="app"]'),

        () => {
            productSetStore.dispatch(triggerProductStateSetCompare(getCompareProducts()));
            productSetStore.dispatch(triggerImageVerification_MarkImageVerified(getMergedProductsWithVerifiedImageKey()));
        });
})
