import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {findDOMNode} from "react-dom";
import CategoryStructurePart from "./CategoryStructurePart";
import {fetchCategoryStructure, fetchSelectedCategoryStructure} from "../../../../Helpers/ApiHelper";
import {triggerError} from "../../../../Store/Action/interactive";

type CategoryStructureState = {
    categories: CategoryStructure_Category[] | undefined
    selectedCategoryId: number
    isLoading: boolean
}

export default function CategoryStructure(props: {
    categoryClass: { id: number, className: string } | undefined
    selectedCategoryId: number
    onClick: (category: any) => void
}) {

    const dispatch = useDispatch();

    const [state, setState] = useState<CategoryStructureState>({
        categories: undefined,
        selectedCategoryId: props.selectedCategoryId,
        isLoading: false
    })

    const apiTail = useSelector((state: IAppState) => ({
        _logPageToken: state.session.tracking.pageToken || null
    }))

    const scrollRef = useRef<HTMLDivElement>(null)

    const _convertCategoryStructure = (categoryResponse: IApiDataCategory[]): CategoryStructure_Category[] => {
        return categoryResponse.map(c => ({
            categoryId: c.id,
            name: c.name,
            isOpen: c.isOpened,
            isCollection: c.isCollection,
            imageIds: c.imageIds,
            path: c.path !== undefined ? _convertCategoryStructure(c.path) : [],
            children: c.categories != undefined ? _convertCategoryStructure(c.categories) : []
        }));
    }

    const _fetchSelectedCategoryStructure = function (selectedCategoryId: number) {
        setState({
            ...state,
            selectedCategoryId,
            isLoading: true
        })

        fetchSelectedCategoryStructure(selectedCategoryId, apiTail)
            .then((categoriesApiResponse) => {
                if (categoriesApiResponse) {
                    const categories = _convertCategoryStructure(categoriesApiResponse)
                    setState({
                        ...state,
                        categories: categories,
                        isLoading: false
                    })
                }
            })
            .catch((error) => {
                dispatch(triggerError(
                    error.code,
                    error.name,
                    error.message));

                setState({
                    ...state,
                    isLoading: false
                })
            })
    }

    useEffect(() => {
        if (props.selectedCategoryId) {
            _fetchSelectedCategoryStructure(props.selectedCategoryId)
        }
    }, [props.selectedCategoryId]);

    const onCategoryChange = function (e: React.SyntheticEvent) {
        const target = e.target as typeof e.target & { value: number }
        if (target.value > 0) {
            _fetchSelectedCategoryStructure(target.value)
        }
    }

    const onIClick = function (category: CategoryStructure_Category) {
        let newState: CategoryStructureState = {
            ...state,
            categories: state.categories ? [...state.categories] : undefined,
        }
        let newCategory = findCategory(newState.categories, category)
        newState.selectedCategoryId = category.categoryId
        if (category.isCollection && newCategory) {
            if (newCategory.isOpen) {
                newCategory.isOpen = false
                closeChildren(newCategory.children)
                setState(newState)
            } else if (newCategory.children.length > 0) {
                newCategory.isOpen = true
                setState(newState)
            } else {
                fetchCategoryStructure(category.categoryId, apiTail)
                    .then((categoriesApiResponse) => {
                        if (categoriesApiResponse) {
                            const categories = _convertCategoryStructure(categoriesApiResponse)
                            if (newCategory) {
                                newCategory.isOpen = true
                                newCategory.children = categories
                            }
                            setState(newState)
                        }
                    })
                    .catch((error) => {
                        dispatch(triggerError(
                            error.code,
                            error.name,
                            error.message));
                    })
            }
        }
        if (props.onClick) {
            props.onClick(category)
        }
    }

    const onLiClick = function (category: CategoryStructure_Category) {
        onIClick(category)
    }

    const findCategory = function (categories: CategoryStructure_Category[] | undefined, category: CategoryStructure_Category): CategoryStructure_Category | undefined {
        if (categories) {
            let cats: CategoryStructure_Category[] | undefined = categories
            let parentIds = category.path ? category.path.map((parent) => parent.categoryId) : undefined
            while (parentIds && parentIds.length > 0) {
                let parentCategoryId = parentIds.shift()
                if (cats) {
                    cats = cats.find((cat) => cat.categoryId == parentCategoryId)?.children
                }
            }
            if (cats) {
                return cats.find((cat) => cat.categoryId == category.categoryId)
            }
        }
        return undefined
    }

    const closeChildren = function (categories: CategoryStructure_Category[]) {
        categories.forEach((category) => {
            if (category.isOpen) {
                category.isOpen = false
                closeChildren(category.children)
            }
        })
    }

    const scrollToSelectedCategory = function (offsetTop: number) {
        const node = findDOMNode(scrollRef.current) as HTMLElement
        if (node) {
            node.scrollTop = 0
            const position = Math.floor((node.offsetHeight / 2) - 18)
            if (offsetTop > position) {
                node.scrollTop = offsetTop - position
            }
        }
    }

    const {categories, isLoading, selectedCategoryId} = state
    const {categoryClass} = props
    let categorySelect, categoryTree
    if (categories) {

        let selectedParentCategory: CategoryStructure_Category | undefined = categories.find(c => c.isOpen)
        categorySelect = categories.map((category) => {
            return <option key={category.categoryId} value={category.categoryId}>{category.name}</option>
        })

        if (selectedParentCategory) {
            categorySelect =
                <select className="category-structure-select" value={selectedParentCategory.categoryId}
                        onChange={onCategoryChange}>
                    {categorySelect}
                </select>
            if (selectedParentCategory.children) {
                categoryTree =
                    <ul>
                        {selectedParentCategory.children.map((category: CategoryStructure_Category) => {
                            return <CategoryStructurePart key={`${category.categoryId}`}
                                                          category={category}
                                                          selectedCategoryId={selectedCategoryId}
                                                          path={[{
                                                              categoryId: (selectedParentCategory as CategoryStructure_Category).categoryId,
                                                              name: (selectedParentCategory as CategoryStructure_Category).name,
                                                              isCollection: (selectedParentCategory as CategoryStructure_Category).isCollection,
                                                              imageIds: (selectedParentCategory as CategoryStructure_Category).imageIds,
                                                              path: [],
                                                              isOpen: (selectedParentCategory as CategoryStructure_Category).isOpen,
                                                              children: []
                                                          }]}
                                                          onIClick={onIClick}
                                                          onLiClick={onLiClick}
                                                          scrollToSelectedCategory={scrollToSelectedCategory}
                                                          categoryClass={categoryClass}/>
                        })}
                    </ul>
            }
        } else {
            categorySelect =
                <select className="category-structure-select" value={''}
                        onChange={onCategoryChange}>
                    <option/>
                    {categorySelect}
                </select>
        }
    } else {
        categorySelect =
            <select className="category-structure-select" value={''} onChange={onCategoryChange}/>
    }

    return (
        <div className="category-structure">
            {categorySelect}
            <div className="category-structure-body" ref={scrollRef}>
                {categoryTree}
            </div>
            <div className={`category-structure-loading${isLoading ? ' active' : ''}`}>
                <div/>
            </div>
        </div>
    )
}
